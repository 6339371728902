import {cloneDeep} from "lodash";
import {useEffect} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {FieldValues} from "react-hook-form";
import {isGridId} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoPermissionMatrix} from "../../../../api/meta/base/dto/DefnDtoPermissionMatrix";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSetOfRole} from "../../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildPermissionMatrix} from "../../../../api/meta/base/dto/DefnStudioBuildPermissionMatrix";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioCompositeMap} from "../../../../api/meta/base/dto/StudioCompositeMap";
import {StudioDtoPermissionMatrix} from "../../../../api/meta/base/dto/StudioDtoPermissionMatrix";
import {StudioFieldEditable} from "../../../../api/meta/base/dto/StudioFieldEditable";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioMapOfFormula} from "../../../../api/meta/base/dto/StudioMapOfFormula";
import {StudioMapOfLayoutFormContent} from "../../../../api/meta/base/dto/StudioMapOfLayoutFormContent";
import {StudioVisibilityRuleMap} from "../../../../api/meta/base/dto/StudioVisibilityRuleMap";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {EnumStudioCompType} from "../../../../api/meta/base/Types";
import {MetaIdLayoutFormContent} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPermission} from "../../../../api/meta/base/Types";
import {MetaIdFormula} from "../../../../api/meta/base/Types";
import {MetaIdVisibilityRule} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumDefnPermission} from "../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {removeItem} from "../../../../base/plus/JsPlus";
import {removeItems} from "../../../../base/plus/JsPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {FN_NOOP} from "../../../../base/plus/SysPlus";
import {listSetSelectedItemId} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItem} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IMainPropsStudioFormBuilder} from "../../../../base/types/TypesMain";
import {EnumStudioEntAside} from "../../../../base/types/TypesStudio";
import {IStudioJumpPath} from "../../../../base/types/TypesStudio";
import {useAppSelector} from "../../../app/AppHooks";
import {DEFAULT_PERMISSION_WRITE} from "../../../atom/assets/HelperTextStudio";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {useAppCtx} from "../../../ctx/CtxApp";
import {getDefnFormBuilderEditForm} from "../aside/FormBuilderEditForm";
import {TypeActionSortFormList} from "./TypesFormBuilder";
import {fieldGap2} from "./TypesFormBuilder";
import {IFormBuilderItemUserField} from "./TypesFormBuilder";
import {fieldGap1} from "./TypesFormBuilder";
import {getFieldGap} from "./TypesFormBuilder";

export const propKeyPermissionDefault = "permissionDefault";
export const fieldPermissionMatrixMap = "permissionMatrix";
export const fieldLogReadRoleSet = "logReadRoleSet";

export function getCompPermissionMatrix(
  sectionId: MetaIdField,
  fieldType?: EnumStudioCompType): Record<MetaIdField, DefnField>
{
  const isFieldTypeIdentifier = fieldType === "identifier";
  const isFieldTypeLog = fieldType === "logNumber"
    || fieldType === "logDecimal"
    || fieldType === "logCounter";

  return {
    [fieldLogReadRoleSet]: {
      type: "setOfRole",
      metaId: fieldLogReadRoleSet,
      name: fieldLogReadRoleSet,
      label: "Log read roles",
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    ...getFieldGap(fieldGap1, "thick"),

    [propKeyPermissionDefault]: {
      type: "enumPermission",
      placeHolderVar: {value: ["Select"]},
      metaId: propKeyPermissionDefault,
      helperTextVar: isFieldTypeIdentifier
        ? undefined
        : stringToDefnDtoText(DEFAULT_PERMISSION_WRITE),
      filterOptionSet: isFieldTypeIdentifier
        ? EnumArrayDefnPermission.filter(x => x !== "write")
        : undefined
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldPermissionMatrixMap]: {
      type: "studioBuildPermissionMatrix",
      metaId: fieldPermissionMatrixMap,
      name: fieldPermissionMatrixMap
    } as DefnStudioBuildPermissionMatrix,

    [sectionId]: {
      type: "section",
      name: "Permission",
      label: "Permission",
      fieldIdSet: [
        ...isFieldTypeLog
          ? [fieldLogReadRoleSet, fieldGap1]
          : [],
        propKeyPermissionDefault,
        fieldGap2,
        fieldPermissionMatrixMap
      ],
      metaId: sectionId
    } as DefnSection
  };
}

export function permissionMatrixValueToDto(values: FieldValues): StudioDtoPermissionMatrix
{
  const defaultPermission = fnFieldValueToRawValue("enumPermission",
    values[propKeyPermissionDefault]
  ) as EnumDefnPermission | undefined;
  const permissionMatrixMap = values[fieldPermissionMatrixMap];

  return {
    defaultPermission: defaultPermission,
    keys: permissionMatrixMap?.keys ?? [],
    map: permissionMatrixMap?.map ?? {}
  };
}

export function permissionMatrixDtoToValue(permissionMatrix?: DefnDtoPermissionMatrix): FieldValues
{
  const _defaultPermission = permissionMatrix?.defaultPermission;

  return {
    [propKeyPermissionDefault]: fnRawValueToFieldValue("enumPermission", _defaultPermission),
    [fieldPermissionMatrixMap]: permissionMatrix ?? {
      map: {},
      keys: []
    }
  };
}

export function createMenuPropsStudioForm(item: IListItem): IMenuProps
{
  const menuProps = {} as IMenuProps;
  const userField = item.userField as unknown as IFormBuilderItemUserField;

  const isRefFieldChild = (userField?.item as StudioFieldEditable)?.refFieldId;
  const field = (userField?.item as StudioFieldEditable);
  const variant = userField.variant;
  const isSection = Boolean((variant && ["header", "footer"].includes(variant)) || field.type === "section");
  const isGrid = Boolean(field && field.type === "grid");

  menuProps["Edit"] = {
    onClick: FN_NOOP
  };

  menuProps["gap1"] = undefined;

  menuProps["Copy"] = {
    disabled: isRefFieldChild,
    onClick: FN_NOOP
  };

  menuProps["Paste"] = {
    disabled: isRefFieldChild,
    onClick: FN_NOOP
  };

  menuProps["Get CLI code"] = {
    disabled: isRefFieldChild,
    onClick: FN_NOOP
  };

  menuProps["Duplicate"] = {
    disabled: isRefFieldChild,
    onClick: FN_NOOP
  };

  menuProps["Remove"] = {
    onClick: FN_NOOP
  };

  menuProps["gap2"] = undefined;

  menuProps["Move up"] = {
    disabled: !userField?.showMoveUp,
    onClick: FN_NOOP
  };

  menuProps["Move down"] = {
    disabled: !userField?.showMoveDown,
    onClick: FN_NOOP
  };

  menuProps["Move top"] = {
    disabled: !userField?.showMoveUp,
    onClick: FN_NOOP
  };

  menuProps["Move bottom"] = {
    disabled: !userField?.showMoveDown,
    onClick: FN_NOOP
  };

  menuProps["Move to"] = {
    onClick: FN_NOOP
  };

  menuProps["gap3"] = undefined;

  menuProps["Find usages"] = {
    onClick: FN_NOOP
  };

  if(isSection || isGrid)
  {
    menuProps["Copy fields"] = {
      onClick: FN_NOOP,
      disabled: true
    };
  }
  if(isSection || isGrid)
  {
    menuProps["Paste fields"] = {
      disabled: true,
      onClick: FN_NOOP
    };
  }

  if(isSection || isGrid)
  {
    menuProps["Add field"] = {
      onClick: FN_NOOP
    };
  }

  return menuProps;
}

export function useJumpConfigFormBuilder(
  onClickEditForm: (type: EnumStudioEntAside, jumpPath?: IStudioJumpPath[]) => void,
  onClickEditFormVisibilityRules: (visibilityId?: MetaIdVisibilityRule, jumpPath?: IStudioJumpPath[]) => void,
  onClickEditFormFormula: (formulaId?: MetaIdFormula, jumpPath?: IStudioJumpPath[]) => void,
  onClickEditFormContentLayout: (contentLayoutId?: MetaIdLayoutFormContent, jumpPath?: IStudioJumpPath[]) => void,
  onClickPaymentConfig: (jumpPath?: IStudioJumpPath[]) => void
)
{
  const appCtx = useAppCtx();
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const jumpPath = cloneDeep(mainProps.jumpPath);

  const resetMainPropsJumpPath = useCallback((clear?: boolean) =>
  {
    const jumpPath = mainProps?.jumpPath;
    if(!clear && jumpPath)
    {
      removeItem(jumpPath, jumpPath[0]);
    }
    if(jumpPath)
    {
      if(jumpPath?.length > 0 || clear)
      {
        appCtx.setMainProps({
          ...mainProps,
          jumpPath: clear
            ? undefined
            : jumpPath
        });
      }
    }
  }, [mainProps]);

  useEffect(() =>
  {
    const formEditProperties = Object.keys(getDefnFormBuilderEditForm().compMap) as string[];
    formEditProperties.push("Details");
    if(jumpPath && jumpPath.length > 0)
    {
      const jumpStep = jumpPath[0];
      const jumpStep2 = jumpPath[1];
      if(jumpStep)
      {
        if(formEditProperties.includes(jumpStep.step as string))
        {
          resetMainPropsJumpPath(true);
          onClickEditForm("Forms", jumpPath);
        }
        else if(jumpStep.step === "PaymentConfig")
        {
          resetMainPropsJumpPath(true);
          onClickPaymentConfig(jumpPath);
        }
        else if(jumpStep2 && jumpStep2.step !== "FormContentLayout")
        {
          resetMainPropsJumpPath(true);
          onClickEditForm(jumpStep.step as EnumStudioEntAside, jumpPath);
        }
        else if(jumpStep.step === "FormFormulas" && jumpStep.itemId)
        {
          resetMainPropsJumpPath();
          onClickEditFormFormula(jumpStep.itemId, jumpPath);
        }
        else if(jumpStep.step === "FormVisibilityRules" && jumpStep.itemId)
        {
          resetMainPropsJumpPath();
          onClickEditFormVisibilityRules(jumpStep.itemId, jumpPath);
        }
        else if(jumpStep2 && jumpStep2.step === "FormContentLayout" && jumpStep2.itemId)
        {
          removeItem(jumpPath, jumpPath[0]);
          resetMainPropsJumpPath();
          onClickEditFormContentLayout(jumpStep2.itemId, jumpPath);
        }
      }
    }
  }, [mainProps]);
}

export function useJumpConfigFormBuilderMain(
  selectList: SelectList,
  onClickListItem: (itemId: TypeListItemId, item: IListItem, jumpPath?: IStudioJumpPath[]) => void)
{
  const appCtx = useAppCtx();
  const itemsById = useAppSelector(state => selectList(state).itemsById);
  const loaded = useAppSelector(state => selectList(state).userField?.loaded);
  const listName = useAppSelector(state => selectList(state).listName);
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const jumpPath = useMemo(() => cloneDeep(mainProps?.jumpPath), [mainProps.jumpPath]);

  const jump = useCallback((itemId: TypeListItemId, jumpPath: IStudioJumpPath[]) =>
  {
    if(loaded)
    {
      const item = itemsById[itemId];
      onClickListItem(itemId, item, jumpPath);
      appCtx.setMainProps({
        ...mainProps,
        jumpPath: undefined
      } as IMainPropsStudioFormBuilder);
      dispatchList(listName, listSetSelectedItemId(itemId));
    }

  }, [mainProps, itemsById, loaded, onClickListItem]);

  const jumpComposite = useCallback(() =>
  {
    if(jumpPath)
    {
      const jumpStep1 = jumpPath[0];
      const jumpStep2 = jumpPath[1];

      if(jumpStep1.step === "FormComposite"
        && jumpStep1.itemId
        && jumpStep2.step !== "Field"
        && jumpStep2.step !== "headerMap"
        && jumpStep2.step !== "footerMap")
      {
        removeItems(jumpPath, [jumpStep1]);
        jump(jumpStep1.itemId, jumpPath);
        return true;
      }
    }

  }, [mainProps, itemsById, loaded, jump]);

  const jumpHeaderFooter = useCallback(() =>
  {
    if(jumpPath)
    {
      const jumpStep1 = jumpPath[0];
      const jumpStep2 = jumpPath[1];

      if(jumpStep1.step === "FormComposite" && jumpStep1.itemId)
      {
        if(jumpStep2.step === "headerMap" || jumpStep2.step === "footerMap")
        {
          if(isGridId(jumpStep1.itemId) && jumpStep2.itemId)
          {
            removeItems(jumpPath, [jumpStep1, jumpStep2]);

            jump(jumpStep2.itemId, jumpPath);
            return true;
          }
        }
      }
    }

  }, [mainProps, itemsById, loaded, jump]);

  const jumpField = useCallback(() =>
  {
    if(jumpPath)
    {
      const jumpStep1 = jumpPath[0];
      const jumpStep2 = jumpPath[1];
      const jumpStep3 = jumpPath[2];

      if(jumpStep1.step === "FormComposite" && jumpStep1.itemId)
      {
        if(jumpStep2.step === "Field" && jumpStep2.itemId)
        {
          removeItems(jumpPath, [jumpStep1, jumpStep2]);
          jump(jumpStep2.itemId, jumpPath);
          return true;

        }
        if(jumpStep2.step === "headerMap" || jumpStep2.step === "footerMap")
        {
          if(isGridId(jumpStep1.itemId) && jumpStep2.itemId)
          {
            if(jumpStep3.step === "Field" && jumpStep3.itemId)
            {
              removeItems(jumpPath, [jumpStep1, jumpStep2, jumpStep3]);
              jump(jumpStep3.itemId, jumpPath);
              return true;
            }
          }
        }
      }
    }

  }, [jumpPath, itemsById, loaded, jump]);

  useEffect(() =>
  {
    if(loaded && itemsById && jumpPath)
    {
      if(jumpField())
      {
        return;
      }
      if(jumpComposite())
      {
        return;
      }
      if(jumpHeaderFooter())
      {
        return;
      }
    }
  }, [jumpPath, itemsById, loaded]);

}

export function sortFormItemList(action: TypeActionSortFormList, studioForm?: StudioForm): StudioForm | undefined
{
  const {
    setKey,
    sortType
  } = action;
  if(studioForm)
  {
    switch(setKey)
    {
      case "layoutContentList":
        if(studioForm?.layoutMap && studioForm.layoutMap.keys.length > 1)
        {
          const shortedContentLayoutKeys = [...studioForm.layoutMap.keys];
          shortedContentLayoutKeys.sort((a, b) =>
          {
            const nameA = studioForm.layoutMap?.map[a].name ?? "";
            const nameB = studioForm.layoutMap?.map[b].name ?? "";
            return sortType === "sortDesc"
              ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0)
              : (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
          });

          if(shortedContentLayoutKeys.length > 0)
          {
            const preFormLayoutForm = studioForm.layoutMap;

            const layoutForm = {
              ...preFormLayoutForm,
              keys: shortedContentLayoutKeys
            } as StudioMapOfLayoutFormContent;

            return {
              ...studioForm,
              layoutMap: layoutForm
            } as StudioForm;
          }
        }
        break;
      case "visibilityList":
        if(studioForm && studioForm.visibilityRuleMap.keys.length > 1)
        {
          const shortedVisibilityKeys = [...studioForm?.visibilityRuleMap.keys];
          shortedVisibilityKeys.sort((a, b) =>
          {
            const nameA = studioForm.visibilityRuleMap.map[a].name.toLowerCase();
            const nameB = studioForm.visibilityRuleMap.map[b].name.toLowerCase();
            return sortType === "sortDesc"
              ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0)
              : (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
          });

          if(shortedVisibilityKeys.length > 0)
          {
            const preFormVisibility = studioForm.visibilityRuleMap;
            const visibilityMap = {
              ...preFormVisibility,
              keys: shortedVisibilityKeys
            } as StudioVisibilityRuleMap;

            return {
              ...studioForm,
              visibilityRuleMap: visibilityMap
            } as StudioForm;
          }
        }
        break;
      case "compositeList":
        if(studioForm && studioForm.compositeMap.keys.length > 1)
        {
          const shortedCompositeKeys = [...studioForm?.compositeMap.keys];
          shortedCompositeKeys.sort((a, b) =>
          {
            const nameA = studioForm.compositeMap.map[a].details.name.toLowerCase();
            const nameB = studioForm.compositeMap.map[b].details.name.toLowerCase();
            return sortType === "sortDesc"
              ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0)
              : (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
          });

          if(shortedCompositeKeys.length > 0)
          {
            const preFormComposite = studioForm.compositeMap;
            const compositeMap = {
              ...preFormComposite,
              keys: shortedCompositeKeys
            } as StudioCompositeMap;

            return {
              ...studioForm,
              compositeMap: compositeMap
            } as StudioForm;
          }
        }
        break;
      case "formulaList":
        if(studioForm)
        {
          const formulaKeys = studioForm.formulaMap.keys ?? [];
          const shortedFormulaKeys = [...formulaKeys];
          shortedFormulaKeys.sort((a, b) =>
          {
            const formulaFieldA = studioForm.formulaMap.map[a].assignToFieldId;
            const formulaFieldB = studioForm.formulaMap.map[b].assignToFieldId;

            const compositeMap = getCompositeMap(studioForm);
            const compositeIdA = Object.keys(compositeMap).filter(id => compositeMap[id].includes(formulaFieldA));
            const compositeIdB = Object.keys(compositeMap).filter(id => compositeMap[id].includes(formulaFieldB));

            const nameA = studioForm.compositeMap.map[compositeIdA[0]]?.fieldMap.map[formulaFieldA].details.name;
            const nameB = studioForm.compositeMap.map[compositeIdB[0]]?.fieldMap.map[formulaFieldB].details.name;

            return sortType === "sortDesc"
              ? (nameA > nameB ? -1 : nameA < nameB ? 1 : 0)
              : (nameA < nameB ? -1 : nameA > nameB ? 1 : 0);
          });

          if(shortedFormulaKeys.length > 0)
          {
            const preFormFormula = studioForm.formulaMap;
            const formulaMap = {
              ...preFormFormula,
              keys: shortedFormulaKeys
            } as StudioMapOfFormula;

            return {
              ...studioForm,
              formulaMap: formulaMap
            } as StudioForm;
          }
        }
        break;
    }
  }
}

function getCompositeMap(studioForm: StudioForm): Record<MetaIdComposite, MetaIdField[]>
{
  const compositeMap: Record<MetaIdComposite, MetaIdField[]> = {};
  Object.keys(studioForm.compositeMap.map).forEach((compositeKey: MetaIdComposite) =>
  {
    const composite = studioForm.compositeMap.map[compositeKey] as StudioComposite;
    if(composite.fieldMap && composite.fieldMap.keys)
    {
      compositeMap[compositeKey] = composite.fieldMap.keys;
    }
  });
  return compositeMap;
}

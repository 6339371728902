import {isStoreItemId} from "../../../api/meta/base/ApiPlus";
import {EnumStoreItemArtifact} from "../../../api/meta/base/Types";
import {EnumStoreLabel} from "../../../api/meta/base/Types";
import {StoreItemId} from "../../../api/meta/base/Types";
import {MsgStoreFilters} from "../../../api/store/store/msg/MsgStoreFilters";
import {RpcStore} from "../../../api/store/store/RpcStore";
import {SigStoreItemAvatar} from "../../../api/store/store/sig/SigStoreItemAvatar";
import {SigStoreItemListGet} from "../../../api/store/store/sig/SigStoreItemListGet";
import ISrvc from "../../../base/ISrvc";
import {SelectList} from "../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {dispatchListItemStore} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListData} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigStudioItemAvatar = SigStoreItemAvatar | undefined;

export default class SrvcStoreDrawerRecentList extends ISrvc
{
  private readonly subscriberId = "SrvcStoreDrawerRecentList";

  constructor(readonly selectList: SelectList)
  {
    super();
  }

  getStoreDrawerListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onBindStoreDrawerSourceItem.bind(this)
    } as IListBinder<TypeSigStudioItemAvatar>;
  }

  getStoreItemPickerListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.onBindStoreItemPickerSourceItem.bind(this)
    } as IListBinder<TypeSigStudioItemAvatar>;
  }

  subscribe(storeItemId: StoreItemId, unsubscribe?: boolean): void
  {
    Srvc.app.pubsub.storeAvtar(this.subscriberId, storeItemId, unsubscribe);
  }

  // TODO Neel/Aditya move this in app package

  rpcStoreItemListGetForPicker(
    listName: string,
    selectList: SelectList,
    artifactKind?: EnumStoreItemArtifact,
    categoryKindSet?: EnumStoreLabel[])
  {
    const msg = {
      artifactKind: artifactKind,
      storeLabelSet: categoryKindSet
    } as MsgStoreFilters;

    RpcStore.storeItemListGet(msg, envSig =>
      listRecursiveCalls<SigStoreItemListGet>(
        envSig,
        selectList.bind(this),
        () => this.rpcStoreItemListGetForPicker(listName, selectList, artifactKind, categoryKindSet),
        this.doLoad.bind(this)
      )
    );
  }

  rpcStoreItemListGet(
    listName: string,
    artifactKind?: EnumStoreItemArtifact,
    categoryKindSet?: EnumStoreLabel[])
  {
    const msg = {
      artifactKind: artifactKind,
      storeLabelSet: categoryKindSet
    } as MsgStoreFilters;

    RpcStore.storeItemListGet(msg, envSig =>
      listRecursiveCalls<SigStoreItemListGet>(
        envSig,
        this.selectList,
        () => this.rpcStoreItemListGet(listName, artifactKind, categoryKindSet),
        this.doLoad.bind(this)
      )
    );
  }

  private doLoad(listName: string, sig: SigStoreItemListGet)
  {
    const listData = {
      itemIds: sig.storeItemIdSet,
      itemsById: {},
      version: random()
    } as IListData;

    dispatchList(listName, listRefresh(listData));
  }

  private selectSourceItem(state: RootState, itemId: TypeListItemId)
  {
    if(isStoreItemId(itemId))
    {
      return state.cache.app.caller.storeItemIdAdminAvatarMap[itemId];
    }
  }

  private onBindStoreDrawerSourceItem(listName: string, itemId: TypeListItemId, avatar?: TypeSigStudioItemAvatar): void
  {
    this.onBindSourceItem(listName, itemId, avatar);
  }

  private onBindStoreItemPickerSourceItem(
    listName: string,
    itemId: TypeListItemId,
    avatar?: TypeSigStudioItemAvatar): void
  {
    this.onBindSourceItem(listName, itemId, avatar, true);
  }

  private onBindSourceItem(
    listName: string,
    itemId: TypeListItemId,
    avatar?: TypeSigStudioItemAvatar,
    hideMenu?: boolean): void
  {
    const rootState = store.getState();
    const storeItemIdAdminSet = rootState.cache.app.caller.storeItemIdAdminSet;

    const isAdmin = storeItemIdAdminSet.includes(itemId);

    if(isStoreItemId(itemId))
    {
      dispatchListItemStore(listName, itemId, avatar, undefined, hideMenu, isAdmin);
    }
  }
}

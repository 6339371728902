import {useTheme} from "@mui/material";
import {cloneDeep} from "lodash";
import React, {useCallback, useEffect, useState} from "react";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioDetails} from "../../../../api/meta/base/dto/StudioDetails";
import {StudioDtoFormula} from "../../../../api/meta/base/dto/StudioDtoFormula";
import {StudioDtoLayoutFormContentReport} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentReport";
import {StudioDtoPermissionMatrix} from "../../../../api/meta/base/dto/StudioDtoPermissionMatrix";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioVisibilityRule} from "../../../../api/meta/base/dto/StudioVisibilityRule";
import {ValidationResult} from "../../../../api/meta/base/dto/ValidationResult";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {getValidationErrorMessage} from "../../../../api/nucleus/base/Protocol";
import {px} from "../../../../base/plus/StringPlus";
import {IAsidePropsStudioFormEdit} from "../../../../base/types/TypesAside";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {IMainPropsStudioFormBuilder} from "../../../../base/types/TypesMain";
import {EnumStudioSearchPathKeys} from "../../../../base/types/TypesStudio";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import {EnumIconFormBuilder} from "../../../atom/icon/IconForm";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {TypeFormBuilderError} from "../../../atom/raw/RawFormIconStrip";
import RawFormIconStrip from "../../../atom/raw/RawFormIconStrip";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import DialogNewFormContentLayout from "../../../dialog/DialogNewFormContentLayout";
import DialogNewFormFormula from "../../../dialog/DialogNewFormFormula";
import DialogNewFormVisibilityRule from "../../../dialog/DialogNewFormVisibilityRule";
import DialogNewSectionOrGrid from "../../../dialog/DialogNewSectionOrGrid";
import {getStudioFormPropertiesSection} from "../aside/FormBuilderEditForm";
import useCopyPasteStudioFormItem from "../base/StudioFormCopyPaste";

export default function FormBuilderFooter(props: {
  formStore: FormStore,
  validationResult?: ValidationResult;
  footerLeftIcons?: EnumIconFormBuilder[],
  footerRightIcons?: EnumIconButton[],
  readOnly?: boolean,
  form?: StudioForm,
  cbOnClickAddStudioComposite?: (formComposite: StudioComposite) => void,
  cbOnClickAddStudioVisibilityRule?: (studioVisibilityRule: StudioVisibilityRule) => void,
  cbOnClickAddStudioFormula?: (studioFormFormula: StudioDtoFormula) => void,
  cbOnClickAddStudioContentLayout?: (studioFormContentLayout: StudioDtoLayoutFormContentReport) => void,
  cbOnClickEditForm?: () => void,
  cbOnClickVisibility?: () => void,
  cbOnClickFormula?: () => void,
  cbOnClickPreview?: () => void,
  cbOnClickContentLayout?: () => void,
  cbOnClickPaymentConfig?: () => void
})
{
  const theme = useTheme();
  const pageCtx = usePageCtx();
  const appCtx = useAppCtx();

  const readOnly = props.readOnly;
  const formStore = props.formStore;
  const validationResult = props.validationResult;
  const cbOnClickAddStudioVisibilityRule = props.cbOnClickAddStudioVisibilityRule;
  const cbOnClickAddStudioFormula = props.cbOnClickAddStudioFormula;
  const cbOnClickAddStudioComposite = props.cbOnClickAddStudioComposite;
  const cbOnClickAddStudioContentLayout = props.cbOnClickAddStudioContentLayout;

  const [selectedIcon, setSelectedIcon] = useState<EnumIconFormBuilder>();

  const gapStd = theme.common.gapStd;
  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilder;
  const asideProps = appCtx.getAsideProps() as IAsidePropsStudioFormEdit;
  const jumpStepFormItem = mainProps.jumpStepItem;
  const formId = mainProps.formId;
  const formIconBadgeMap = getErrorCountMap(formId, validationResult);

  const {
    pasteStudioComposite,
    pasteStudioVisibilityRules,
    pasteStudioFormFormulas,
    pasteStudioContentLayout
  } = useCopyPasteStudioFormItem();

  const onClickAdd = useCallback(() =>
  {
    switch(jumpStepFormItem)
    {
      case "FormDetails":
        pageCtx.showDialog(
          <DialogNewSectionOrGrid
            onClickOk={(values) =>
            {
              cbOnClickAddStudioComposite &&
              cbOnClickAddStudioComposite({
                type: values.sectionType,
                details: {
                  name: values.name,
                  modules: {
                    moduleIdSet: []
                  }
                } as StudioDetails,
                fieldMap: {
                  keys: [],
                  map: {}
                },
                permissionMatrix: {
                  map: {},
                  keys: []
                } as StudioDtoPermissionMatrix
              } as StudioComposite);
            }}
          />);
        break;
      case "FormVisibilityRules":
        pageCtx.showDialog(
          <DialogNewFormVisibilityRule
            onClickOk={(value) =>
            {
              cbOnClickAddStudioVisibilityRule &&
              cbOnClickAddStudioVisibilityRule(value);
            }}
          />
        );
        break;
      case "FormContentLayout":
        pageCtx.showDialog(
          <DialogNewFormContentLayout
            onClickOk={(value) =>
            {
              cbOnClickAddStudioContentLayout &&
              cbOnClickAddStudioContentLayout(value);
            }}
          />
        );
        break;
      case "FormFormulas":
        pageCtx.showDialog(
          <DialogNewFormFormula
            metaIdForm={formId}
            formStore={formStore}
            isFormReadOnly={readOnly}
            onClickOk={(value) =>
            {
              cbOnClickAddStudioFormula &&
              cbOnClickAddStudioFormula(value);
            }}
          />
        );
        break;
    }
  }, [
    pageCtx,
    jumpStepFormItem,
    cbOnClickAddStudioComposite,
    cbOnClickAddStudioVisibilityRule,
    cbOnClickAddStudioContentLayout
  ]);

  const onClickPaste = useCallback(() =>
    {
      switch(jumpStepFormItem)
      {
        case "FormDetails":
          pasteStudioComposite(
            studioComposite =>
            {
              cbOnClickAddStudioComposite &&
              cbOnClickAddStudioComposite(studioComposite);
            }
          );
          break;
        case "FormVisibilityRules":
          pasteStudioVisibilityRules(
            studioVisibilityRule =>
            {
              cbOnClickAddStudioVisibilityRule &&
              cbOnClickAddStudioVisibilityRule(studioVisibilityRule);
            });
          break;

        case "FormFormulas":
          pasteStudioFormFormulas(
            studioFormula =>
            {
              cbOnClickAddStudioFormula &&
              cbOnClickAddStudioFormula(studioFormula);
            });
          break;

        case "FormContentLayout":
          pasteStudioContentLayout(
            studioContentLayout =>
            {
              cbOnClickAddStudioContentLayout &&
              cbOnClickAddStudioContentLayout(studioContentLayout);
            });
          break;
      }
    },
    [
      jumpStepFormItem,
      pasteStudioComposite,
      pasteStudioVisibilityRules,
      pasteStudioFormFormulas,
      pasteStudioContentLayout,
      cbOnClickAddStudioComposite,
      cbOnClickAddStudioVisibilityRule,
      cbOnClickAddStudioFormula,
      cbOnClickAddStudioContentLayout
    ]
  );

  useEffect(() =>
  {
    const type = asideProps?.type;
    switch(type)
    {
      case "FormFormulas":
        setSelectedIcon("formula");
        break;
      case "FormVisibilityRules":
        setSelectedIcon("visibility");
        break;
      case "FormContentLayout":
        setSelectedIcon("contentLayout");
        break;
      case "FormPreview":
        setSelectedIcon("preview");
        break;
      case "Forms":
        setSelectedIcon("edit");
        break;
      case "PaymentConfig":
        setSelectedIcon("payment");
        break;
      default:
        setSelectedIcon(undefined);
    }
    if(mainProps?.jumpStepItem === "FormVisibilityRules")
    {
      setSelectedIcon("visibility");
    }
    else if(mainProps?.jumpStepItem === "FormContentLayout")
    {
      setSelectedIcon("contentLayout");
    }
    else if(mainProps?.jumpStepItem === "FormFormulas")
    {
      setSelectedIcon("formula");
    }
    else if(!asideProps)
    {
      setSelectedIcon(undefined);
    }
  }, [mainProps, asideProps]);

  const tooltipMap = {
    add: (jumpStepFormItem === "FormDetails")
      ? "New section or grid"
      : (jumpStepFormItem === "FormVisibilityRules")
        ? "New visibility rule"
        : (jumpStepFormItem === "FormContentLayout")
          ? "New content layout"
          : "New",
    paste: (jumpStepFormItem === "FormDetails")
      ? "Paste section or grid"
      : (jumpStepFormItem === "FormVisibilityRules")
        ? "Paste visibility rule"
        : (jumpStepFormItem === "FormContentLayout")
          ? "Paste content layout"
          : "Paste"
  } as Record<EnumIconButton, string>;

  const fixedFooterHeight = "62.75px";

  const cbOnClick = (iconName: EnumIconFormBuilder) =>
  {
    if(iconName === "edit")
    {
      props.cbOnClickEditForm &&
      props.cbOnClickEditForm();
    }
    else if(iconName === "formula")
    {
      props.cbOnClickFormula &&
      props.cbOnClickFormula();
    }
    else if(iconName === "visibility")
    {
      props.cbOnClickVisibility &&
      props.cbOnClickVisibility();
    }
    else if(iconName === "contentLayout")
    {
      props.cbOnClickContentLayout &&
      props.cbOnClickContentLayout();
    }
    else if(iconName === "preview")
    {
      props.cbOnClickPreview &&
      props.cbOnClickPreview();
    }
    else if(iconName === "payment")
    {
      props.cbOnClickPaymentConfig &&
      props.cbOnClickPaymentConfig();
    }
  };

  return (
    <LayoutFlexRow
      width={"100%"}
      pl={px(gapStd)}
      pr={px(gapStd)}
      height={fixedFooterHeight}
      justifyContent={"space-between"}
    >
      <RawFormIconStrip
        form={props.form}
        formIconBadgeMap={formIconBadgeMap}
        iconStripList={props.footerLeftIcons || [
          "edit",
          "formula",
          "visibility",
          "pipe",
          "contentLayout",
          "payment"
          // "preview"  //TODO preview button disabled for now
        ]}
        toolTipMap={{
          edit: "Edit form",
          formula: "Formulas",
          visibility: "Visibility rules",
          preview: "Preview form",
          contentLayout: "Form layout",
          payment: "Payment"
        } as Record<EnumIconFormBuilder, string>}
        colorMap={selectedIcon
          ? {
            [selectedIcon]: "primary"
          } as Record<EnumIconFormBuilder, TypeTextColor>
          : undefined}
        onClick={cbOnClick}
      />

      <RawButtonStrip
        iconButtonList={props.footerRightIcons || ["paste", "add"]}
        toolTipMap={tooltipMap}
        labelMap={{
          add: "Add..."
        } as Record<EnumIconButton, string>}
        onClick={(iconName) =>
        {
          if(iconName === "add")
          {
            onClickAdd();
          }
          else if(iconName === "paste")
          {
            onClickPaste();
          }
        }}
        iconButtonDisable={readOnly
          ? ["paste", "add"]
          : []}
      />

    </LayoutFlexRow>
  );
}

function getErrorCountMap(
  formId: MetaIdForm,
  validationResult?: ValidationResult
): TypeFormBuilderError | undefined
{
  const formMapKey: EnumStudioSearchPathKeys = "formMap";
  const formulaKey: EnumStudioSearchPathKeys = "formulaMap";
  const layoutKey: EnumStudioSearchPathKeys = "layoutMap";
  const visibilityRuleKey: EnumStudioSearchPathKeys = "visibilityRuleMap";
  const permissionMatrixKey: EnumStudioSearchPathKeys = "permissionMatrix";
  const formEditDetailsKey: EnumStudioSearchPathKeys = "details";
  const paymentConfigKey: EnumStudioSearchPathKeys = "payment";

  const errorMap = {} as Record<EnumIconFormBuilder, IFormFieldError[]>;
  const errorCountMap = validationResult?.errorCountMap;
  let errorMsgMap = {} as TypeFormBuilderError | undefined;

  if(validationResult?.errorMap && errorCountMap)
  {
    const prefix = `${formMapKey}/map/${formId}/`;
    const _formulaKey = `${prefix}${formulaKey}`;
    const _layoutKey = `${prefix}${layoutKey}`;
    const _visibilityRuleKey = `${prefix}${visibilityRuleKey}`;
    const _paymentKey = `${prefix}${paymentConfigKey}`;
    const _editKeySet = [] as string[];

    const formulaErrorCount = errorCountMap[_formulaKey];
    const layoutErrorCount = errorCountMap[_layoutKey];
    const visibilityErrorCount = errorCountMap[_visibilityRuleKey];
    const permissionMatrixErrorCount = errorCountMap[`${prefix}${permissionMatrixKey}`];
    const paymentConfigErrorCount = errorCountMap[_paymentKey];
    const formEditDetailsPrefix = `${prefix}${formEditDetailsKey}`;
    let formEditErrorCount: number | undefined;

    Object.keys(getStudioFormPropertiesSection(formId)).forEach(key =>
    {
      const formEditPrefix = `${prefix}${key}`;
      if(errorCountMap[formEditPrefix])
      {
        _editKeySet.push(formEditPrefix);
        formEditErrorCount = (formEditErrorCount ?? 0) + errorCountMap[formEditPrefix];
      }
    });

    if(errorCountMap[formEditDetailsPrefix])
    {
      _editKeySet.push(formEditDetailsPrefix);
      formEditErrorCount = (formEditErrorCount ?? 0) + errorCountMap[formEditDetailsPrefix];
    }

    Object.entries(validationResult.errorMap).forEach(([key, value]) =>
    {
      let errorKey: EnumIconFormBuilder | undefined;

      if(key.startsWith(_formulaKey))
      {
        errorKey = "formula";
      }
      else if(key.startsWith(_layoutKey))
      {
        errorKey = "contentLayout";
      }
      else if(key.startsWith(_visibilityRuleKey))
      {
        errorKey = "visibility";
      }
      else if(key.startsWith(_paymentKey))
      {
        errorKey = "payment";
      }
      else if(_editKeySet.filter(editKey => key.startsWith(editKey)).length)
      {
        errorKey = "edit";
      }

      if(errorKey)
      {
        if(!errorMap[errorKey])
        {
          errorMap[errorKey] = [];
        }

        errorMap[errorKey].push({
          key: errorKey,
          errorOption: {
            type: "error",
            message: getValidationErrorMessage(cloneDeep(value))
          }
        });
      }
    });

    errorMsgMap = {
      edit: {
        count: formEditErrorCount,
        errorList: errorMap["edit"]
      },
      lock: {
        count: permissionMatrixErrorCount,
        errorList: errorMap["lock"]
      },
      formula: {
        count: formulaErrorCount,
        errorList: errorMap["formula"]
      },
      visibility: {
        count: visibilityErrorCount,
        errorList: errorMap["visibility"]
      },
      contentLayout: {
        count: layoutErrorCount,
        errorList: errorMap["contentLayout"]
      },
      payment: {
        count: paymentConfigErrorCount,
        errorList: errorMap["payment"]
      }
    } as TypeFormBuilderError;
  }

  return errorMsgMap;
}

import {StudioEnt} from "../../../api/meta/base/dto/StudioEnt";
import {EntId, MetaId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {MsgStudioEntPartMerge} from "../../../api/studio/studioDrawer/msg/MsgStudioEntPartMerge";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {MsgStudioEnt} from "../../../api/studio/studioMain/msg/MsgStudioEnt";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigStudioEnt} from "../../../api/studio/studioMain/sig/SigStudioEnt";
import ISrvc from "../../../base/ISrvc";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {TypeListSortType} from "../../../base/types/TypesStudio";
import {EnumListItemDirection} from "../../../base/types/TypesStudio";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {dispatchEnt} from "../SrvcCacheStudio";
import SrvcCacheStudioEntAbout from "./about/SrvcCacheStudioEntAbout";
import SrvcCacheStudioEntActions from "./actions/SrvcCacheStudioEntActions";
import SrvcCacheStudioEntAdmins from "./Admins/SrvcCacheStudioEntAdmins";
import SrvcCacheStudioEntAutomations from "./automations/SrvcCacheStudioEntAutomations";
import SrvcCacheStudioEntDeeplinks from "./deeplinks/SrvcCacheStudioEntDeeplinks";
import SrvcCacheStudioEntDeploy from "./deploy/SrvcCacheStudioEntDeploy";
import SrvcCacheStudioEntDrives from "./drive/SrvcCacheStudioEntDrives";
import SrvcCacheStudioEntForms from "./forms/SrvcCacheStudioEntForms";
import SrvcCacheStudioEntGroups from "./groups/SrvcCacheStudioEntGroups";
import SrvcCacheStudioEntImports from "./import/SrvcCacheStudioEntImports";
import SrvcCacheStudioEntDeployManage from "./manage/SrvcCacheStudioEntDeployManage";
import SrvcCacheStudioEntDeployPayment from "./payment/SrvcCacheStudioEntDeployPayment";
import SrvcCacheStudioEntPrompts from "./prompt/SrvcCacheStudioEntPrompts";
import SrvcCacheStudioEntReports from "./reports/SrvcCacheStudioEntReports";
import SrvcCacheStudioEntRoles from "./roles/SrvcCacheStudioEntRoles";
import {setEntUsageMap} from "./SliceCacheStudioEnt";
import {moveEntItem} from "./SliceCacheStudioEnt";
import {sortEntList} from "./SliceCacheStudioEnt";
import {setIsSavingEnt} from "./SliceCacheStudioEnt";
import {removeDeltaEntLocalState} from "./SliceCacheStudioEnt";
import {mergeEnt} from "./SliceCacheStudioEnt";
import {removeEntDeployStatus, setEnt, setEntCurrentTabs, setEntDeployStatus, setEntDirty} from "./SliceCacheStudioEnt";
import SrvcCacheStudioEntSpreadsheets from "./spreadsheets/SrvcCacheStudioEntSpreadsheets";
import SrvcCacheStudioEntTranslations from "./translations/SrvcCacheStudioEntTranslations";
import {ActionEntStateMap} from "./TypesCacheStudioEnt";
import {ActionEntDeployStatus} from "./TypesCacheStudioEnt";
import {TypeDtoEntDataSetKey} from "./TypesCacheStudioEnt";
import SrvcCacheStudioEntUsers from "./user/SrvcCacheStudioEntUsers";
import SrvcCacheStudioEntVariables from "./variables/SrvcCacheStudioEntVariables";

export type CbEntGetSuccess = (ent: StudioEnt) => void;

export default class SrvcCacheStudioEnt extends ISrvc
{
  public readonly roles = new SrvcCacheStudioEntRoles();
  public readonly imports = new SrvcCacheStudioEntImports();
  public readonly forms = new SrvcCacheStudioEntForms();
  public readonly spreadsheets = new SrvcCacheStudioEntSpreadsheets();
  public readonly reports = new SrvcCacheStudioEntReports();
  public readonly automations = new SrvcCacheStudioEntAutomations();
  public readonly actions = new SrvcCacheStudioEntActions();
  public readonly groups = new SrvcCacheStudioEntGroups();
  public readonly deeplinks = new SrvcCacheStudioEntDeeplinks();
  public readonly drives = new SrvcCacheStudioEntDrives();
  public readonly about = new SrvcCacheStudioEntAbout();
  public readonly deploy = new SrvcCacheStudioEntDeploy();
  public readonly prompts = new SrvcCacheStudioEntPrompts();
  public readonly variables = new SrvcCacheStudioEntVariables();
  public readonly translations = new SrvcCacheStudioEntTranslations();
  public readonly users = new SrvcCacheStudioEntUsers();
  public readonly admins = new SrvcCacheStudioEntAdmins();
  public readonly manage = new SrvcCacheStudioEntDeployManage();
  public readonly payment = new SrvcCacheStudioEntDeployPayment();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.roles,
      this.imports,
      this.forms,
      this.spreadsheets,
      this.reports,
      this.automations,
      this.actions,
      this.groups,
      this.deeplinks,
      this.drives,
      this.about,
      this.deploy,
      this.prompts,
      this.variables,
      this.translations,
      this.users,
      this.admins,
      this.manage,
      this.payment
    );

  }

  rpcStudioEntGet(entId: EntId, replace?: boolean, onSuccess?: CbEntGetSuccess)
  {
    const rootState = store.getState();
    const version = rootState.cache.studio.ent.entMap[entId]?.ent.version;
    const msg = {
      version
    };
    RpcStudioDrawer.studioEntGet(entId, msg, envSig =>
    {
      if(envSig.error)
      {
        const notChanged = envSig.error.validationErrors?.find(error => error.errorCode === "notChanged");
        if(notChanged)
        {
          return;
        }
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;
      if(sig)
      {
        if(replace)
        {
          store.dispatch(setEnt(sig));
        }
        else
        {
          this.mergeEnt(sig);
        }
        this.setStudioEntItemUsage(sig.ent);
        onSuccess && onSuccess(sig.ent);
      }
    });
  }

  rpcStudioEntPartMerge(
    entId: EntId,
    srcPartId: MetaId,
    tgtPartId: MetaId,
    cbSuccess?: CbSuccess)
  {
    const msg = {
      srcPartId: srcPartId,
      tgtPartId: tgtPartId
    } as MsgStudioEntPartMerge;

    RpcStudioDrawer.studioEntPartMerge(entId, msg, (envSig) =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  mergeEnt(ent: SigStudioEnt)
  {
    store.dispatch(mergeEnt(ent));
  }

  studioEntMerge(entId: EntId, ent: StudioEnt, cbSuccess?: CbSuccess)
  {
    const msg = {
      studioEnt: ent
    } as MsgStudioEnt;

    RpcStudioMain.studioEntMerge(entId, msg, (envSig) =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }
      else
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  setEntDirty(entId: EntId, isDirty: boolean)
  {
    store.dispatch(setEntDirty({
      entId,
      isDirty
    }));
  }

  setEntCurrentTabs(payload: ActionEntStateMap)
  {
    dispatchEnt(payload.entId, setEntCurrentTabs(payload));
  }

  setEntDeployStatus(payload: ActionEntDeployStatus)
  {
    dispatchEnt(payload.entId, setEntDeployStatus(payload));
  }

  removeEntDeployStatus(entId: EntId)
  {
    dispatchEnt(entId, removeEntDeployStatus(entId));
  }

  moveItem(entId: EntId, metaId: MetaId, moveDirection: EnumListItemDirection, setKey: TypeDtoEntDataSetKey)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey,
      moveDirection
    }));
  }

  moveItemIndex(entId: EntId, metaId: MetaId, setKey: TypeDtoEntDataSetKey, startIndex: number, endIndex: number)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey,
      moveDirection: undefined,
      startIndex,
      endIndex
    }));
  }

  sortList(entId: EntId, setKey: TypeDtoEntDataSetKey, sortType: TypeListSortType)
  {
    dispatchEnt(entId, sortEntList({
      entId,
      setKey,
      sortType
    }));
  }

  removeDeltaLocalState(entId: EntId)
  {
    store.dispatch(removeDeltaEntLocalState(entId));
  }

  setIsSavingEnt(entId: EntId, isSaving: boolean)
  {
    store.dispatch(setIsSavingEnt({
      entId,
      isSaving
    }));
  }

  setStudioEntItemUsage(ent: StudioEnt)
  {
    const entId = ent.entId;
    const usage = Srvc.cache.studio.findStudioItemUsage(ent);

    store.dispatch(setEntUsageMap({
      entId,
      ...usage
    }));
  }

}


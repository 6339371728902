import {nextPluginId} from "../../../api/meta/base/ApiPlus";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {StudioPluginBundle} from "../../../api/meta/base/dto/StudioPluginBundle";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {PluginId} from "../../../api/meta/base/Types";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {SigStudioPluginBundle} from "../../../api/studio/studioDrawer/sig/SigStudioPluginBundle";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigPluginAdminCaller} from "../../../api/studio/studioMain/sig/SigPluginAdminCaller";
import ISrvc from "../../../base/ISrvc";
import {selectCacheStudioPluginBundle} from "../../../base/plus/StudioPlus";
import {Srvc} from "../../../srvc/Srvc";
import {store} from "../../../Store";
import {dispatchPlugin} from "../SrvcCacheStudio";
import SrvcCacheStudioPluginApis from "./apis/SrvcCacheStudioPluginApis";
import SrvcCacheStudioPluginDeploy from "./deploy/SrvcCacheStudioPluginDeploy";
import SrvcCacheStudioPluginDetails from "./details/SrvcCacheStudioPluginDetails";
import SrvcCacheStudioPluginForms from "./forms/SrvcCacheStudioPluginForms";
import SrvcCacheStudioPluginResource from "./resource/SrvcCacheStudioPluginResource";
import {mergePlugin} from "./SliceCacheStudioPlugin";
import {removeDeltaPluginLocalState} from "./SliceCacheStudioPlugin";
import {setPluginSelectedModules} from "./SliceCacheStudioPlugin";
import {setCurrentPluginVersion} from "./SliceCacheStudioPlugin";
import {setSearchPluginId} from "./SliceCacheStudioPlugin";
import {setPluginAdminCaller} from "./SliceCacheStudioPlugin";
import {setIsSavingPlugin} from "./SliceCacheStudioPlugin";
import {updatePlugin} from "./SliceCacheStudioPlugin";
import {ActionPluginStateMap} from "./SliceCacheStudioPlugin";
import {setPluginStateMap} from "./SliceCacheStudioPlugin";
import {duplicatePluginVersion} from "./SliceCacheStudioPlugin";
import {setPluginDirty} from "./SliceCacheStudioPlugin";
import {setPlugin} from "./SliceCacheStudioPlugin";
import SrvcCacheStudioPluginVariables from "./variables/SrvcCacheStudioPluginVariables";

export type CbPluginGetSuccess = (plugin: StudioPluginBundle) => void;

export default class SrvcCacheStudioPlugin extends ISrvc
{
  public readonly details = new SrvcCacheStudioPluginDetails();
  public readonly deploy = new SrvcCacheStudioPluginDeploy();
  public readonly variables = new SrvcCacheStudioPluginVariables();
  public readonly forms = new SrvcCacheStudioPluginForms();
  public readonly apis = new SrvcCacheStudioPluginApis();
  public readonly resource = new SrvcCacheStudioPluginResource();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.details,
      this.deploy,
      this.variables,
      this.forms,
      this.apis,
      this.resource
    );
  }

  //region RPC

  rpcPluginGet(pluginBundleId: PluginBundleId, replace?: boolean, onSuccess?: CbPluginGetSuccess)
  {
    const rootState = store.getState();
    const version = rootState.cache.studio.plugin.pluginMap[pluginBundleId]?.studioPluginBundle?.version;
    const msg = {
      version
    };
    RpcStudioDrawer.studioPluginBundleGet(pluginBundleId, msg, (envSig) =>
    {
      if(envSig.error)
      {
        const notChanged = envSig.error.validationErrors?.find(error => error.errorCode === "notChanged");
        if(notChanged)
        {
          return;
        }

        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }
      const sig = envSig.sig;
      if(sig)
      {
        if(replace)
        {
          store.dispatch(setPlugin(sig));
        }
        else
        {
          store.dispatch(mergePlugin(sig));
        }
        onSuccess && onSuccess(sig.studioPluginBundle);
      }
    });
  }

  //endregion

  updatePlugin(plugin: SigStudioPluginBundle)
  {
    store.dispatch(updatePlugin(plugin));
  }

  setSearchPluginId(pluginBundleId: PluginBundleId | undefined)
  {
    store.dispatch(setSearchPluginId(pluginBundleId));
  }

  setCurrentPluginVersion(pluginBundleId: PluginBundleId, pluginVersionId?: PluginId)
  {
    dispatchPlugin(pluginBundleId, setCurrentPluginVersion({pluginVersionId}));
  }

  setPluginSelectedModules(pluginBundleId: PluginBundleId, pluginId: PluginId, selectedModules: StudioModuleSelection)
  {
    dispatchPlugin(pluginBundleId, setPluginSelectedModules({
      pluginBundleId,
      pluginId,
      selectedModulesSet: selectedModules
    }));
  }

  setPluginDirty(pluginBundleId: PluginBundleId, isDirty: boolean)
  {
    dispatchPlugin(pluginBundleId, setPluginDirty({
      pluginBundleId,
      isDirty
    }));
  }

  duplicatePluginVersion(pluginBundleId: PluginBundleId, pluginId: PluginId)
  {
    const rootState = store.getState();
    const deployMap = selectCacheStudioPluginBundle(rootState, pluginBundleId)?.deployMap;
    const selectedVersion = deployMap?.map[pluginId];

    if(!selectedVersion)
    {
      return;
    }

    const newVersionName = selectedVersion.deploy?.pluginVersion + " copy";

    dispatchPlugin(pluginBundleId, duplicatePluginVersion({
      pluginBundleId: pluginBundleId,
      sig: {
        studioPlugin: {
          ...selectedVersion,
          metaId: nextPluginId(),
          deploy: {
            ...selectedVersion.deploy,
            pluginVersion: newVersionName
          }
        }
      }
    }));
  }

  setPluginStateMap(payload: ActionPluginStateMap)
  {
    dispatchPlugin(payload.pluginBundleId, setPluginStateMap(payload));
  }

  setPluginAdminCallerGet(pluginBundleId: PluginBundleId)
  {
    RpcStudioMain.pluginAdminCallerGet(pluginBundleId, adminSig =>
    {
      if(adminSig.error)
      {
        return;
      }

      const adminCaller = adminSig.sig as SigPluginAdminCaller;
      if(adminCaller)
      {
        store.dispatch(setPluginAdminCaller({
          pluginBundleId: pluginBundleId,
          sigPluginAdminCaller: adminCaller
        }));
      }
    });
  }

  removeDeltaLocalState(pluginBundleId: PluginBundleId)
  {
    store.dispatch(removeDeltaPluginLocalState(pluginBundleId));
  }

  setIsSavingPlugin(pluginBundleId: PluginBundleId, isSaving: boolean)
  {
    store.dispatch(setIsSavingPlugin({
      pluginBundleId,
      isSaving
    }));
  }

}

import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {TextField} from "@mui/material";
import {isEmpty} from "lodash";
import {useEffect} from "react";
import {forwardRef} from "react";
import {ReactElement} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {RefCallBack} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {FORM_FIELD_SMALL_PADDING} from "../../../../base/plus/ConstantsPlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {EnumIconStrip} from "../../../../base/types/TypesIcon";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "./FieldRawRefButton";

interface FieldRawTextFieldProps
{
  defn: DefnFieldEditable,
  value?: FieldValueText,
  rows?: number,
  error?: FieldError,
  onBlur?: () => void,
  onClick?: () => void,
  onChange?: (value?: FieldValueText | null, pressedKey?: string) => void,
  errorHelperText?: string,
  maxRows?: number,
  minRows?: number,
  inputRef?: React.MutableRefObject<HTMLInputElement | null>,
  searchWords?: string[],
  clearButton?: boolean,
  cbOnclickPlusButton?: () => void,
  flexHeight?: boolean,
  helperText?: string,
  endAdornment?: ReactElement;
}

const FieldRawTextField = forwardRef<RefCallBack, FieldRawTextFieldProps>((props: FieldRawTextFieldProps, ref) =>
{
  const defn = props.defn;
  const rows = props.rows;
  const error = props.error;
  const value = props.value?.value;
  const maxRows = props.maxRows;
  const minRows = props.minRows;
  const searchWords = props.searchWords;
  const flexGrow = props.flexHeight;
  const inputRef = props.inputRef;

  const fieldValue = value && !isEmpty(value)
    ? value
    : "";

  const theme = useTheme();
  const fieldId = getFieldKey(defn);
  const isError = Boolean(error) || Boolean(props.errorHelperText);
  const label = getCompLabel(defn);
  const hideLabel = defn.hideLabel;

  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const icon = getFieldIcon();
  const defnTheme = formCtx.getDefnTheme();
  const formSection = formSectionCtx.getParent();
  const fieldVariant = defnTheme.fieldVariant;
  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
  const sectionVariant = formSection.sectionVariant;
  const isReport = defnTheme.formVariant === "report";
  const isFieldStandard = fieldVariant === "standard";
  const filledInputPadding = defnTheme.fieldSize === "small"
    ? FORM_FIELD_SMALL_PADDING
    : FORM_FIELD_MEDIUM_PADDING;
  const readOnly = formCtx.isFieldReadonly(defn);
  const required = getFieldRequired();
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const editable = (!readOnly && !disabled);

  const clearButton = editable
    ? props.clearButton
    : undefined;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick && !disabled
    ? () =>
    {
      getOnClick(fieldId, "field");
      props.onClick && props.onClick();
    }
    : undefined;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
  {
    const value = event.target.value;

    if(!value)
    {
      props.onChange && props.onChange(null);
    }
    else
    {
      props.onChange && props.onChange(
        fnRawValueToFieldValue("text", value) as FieldValueText | undefined);
    }
  };

  function getHelperText()
  {
    if(isError)
    {
      return (error?.message ?? props.errorHelperText);
    }
    else if(getFieldHelperText())
    {
      return getFieldHelperText();
    }
    else
    {
      return props.helperText;
    }
  }

  const helperText = getHelperText();

  useEffect(() =>
  {
    if(inputRef?.current?.click)
    {
      formCtx.setCbFieldRemoteClick(fieldId, inputRef.current?.click);
    }
  }, [inputRef?.current?.click]);

  return (
    <LayoutFlexRow
      overflowY={"visible"}
      overflowX={"visible"}
      flexGrow={1}
      height={"100%"}
      alignItems={"flex-start"}
    >
      <TextField
        id={fieldId}
        inputRef={ref}
        inputProps={{
          ref: inputRef,
          ...defn.type === "symbol" && {
            style: {
              textTransform: "capitalize"
            }
          }
        }}
        fullWidth={true}
        type={"text"}
        rows={rows}
        maxRows={maxRows}
        minRows={minRows}
        multiline={flexGrow ? flexGrow :
          ((rows !== undefined && rows > 1) || (maxRows !== undefined && maxRows > 1))}
        size={defnTheme.fieldSize}
        margin={defnTheme.fieldMargin}
        helperText={helperText}
        variant={fieldVariant}
        sx={{
          height: "100%",
          position: "relative",
          ...sectionVariant && {
            ...isFieldStandard && {
              pt: isReport ? 0 : filledInputPadding,
              pb: isReport ? 0 : filledInputPadding,
              pl: "14px"
            },
            ".MuiInputBase-input": {
              textOverflow: "ellipsis"
            },
            ".MuiFilledInput-input": {
              pt: filledInputPadding,
              pb: filledInputPadding
            },
            ...fieldVariant === "filled" && {
              ".MuiInputBase-root": {
                pt: 0,
                pb: 0
              }
            }
          },
          ...borderColor && {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: borderColor
              }
            }
          }
        }}
        autoComplete={Boolean(defn.autoFill) ? "on" : "off"}
        autoFocus={Boolean(defn.autoFocus)}
        disabled={disabled}
        hiddenLabel={true}
        InputLabelProps={{
          sx: {
            display: "flex"
          }
        }}
        label={!hideLabel
          ? (searchWords?.length
            ? <RawHighlighter
              searchWords={searchWords}
              value={label}
              variant={"body1"}
              flexGrow={1}
              color={theme.palette.text.secondary}
            />
            : label)
          : undefined
        }
        name={fieldId}
        placeholder={getFieldPlaceHolder()}
        InputProps={{
          endAdornment: clearButton
            ? <ClearButton
              onChange={props.onChange}
              readOnly={readOnly}
              disabled={disabled}
            />
            : !icon
              ? (
                props.endAdornment
              )
              : (
                <Box
                  position={"relative"}
                  left={px(gapHalf)}
                  pt={defnTheme.fieldSize === "medium" ? px(12) : px(3)}
                >
                  <RawIconButton
                    name={icon}
                    icon={icon}
                  />
                </Box>
              ),
          readOnly: readOnly,
          sx: {
            alignItems: "start",
            flexGrow: flexGrow ? 1 : 0,
            height: flexGrow ? "100%" : undefined
          }
        }}
        required={Boolean(required)}
        value={fieldValue}
        onBlur={props.onBlur}
        onChange={onChange}
        onClick={(event) =>
        {
          onClick && onClick();
        }}
        onKeyDown={(event) =>
        {
          if(event.key === "Enter")
          {
            onClick?.();
          }
        }}
        error={isError}
      />
      <LayoutFlexRow
        overflowY={"visible"}
        overflowX={"visible"}
        pt={px(gapQuarter)}
      >
        <FieldRawRefButton defn={defn} />
      </LayoutFlexRow>
    </LayoutFlexRow>
  );
});

function ClearButton(props: {
  onChange?: (value?: FieldValueText | null) => void,
  readOnly?: boolean,
  disabled?: boolean
})
{
  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const onChange = props.onChange;

  return (
    <Box
      position={"absolute"}
      right={gapHalf}
      height={"100%"}
      onClick={(e) =>
      {
        e.stopPropagation();
      }}
    >
      <RawIconStrip
        iconStrip={["close"]}
        onClick={(icon) =>
        {
          if(icon === "close")
          {
            onChange && onChange(null);
          }
        }}
        iconStripDisable={(readOnly || disabled) ? ["close"] : undefined}
        toolTipMap={{
          close: "Clear"
        } as Record<EnumIconStrip, string>}
      />
    </Box>
  );
}

export default FieldRawTextField;

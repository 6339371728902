import {Typography} from "@mui/material";
import {Button} from "@mui/material";
import type {ExcelExportParams} from "ag-grid-community/dist/types/core/interfaces/iExcelCreator";
import {exportMultipleSheetsAsExcel} from "ag-grid-enterprise";
import {Property} from "csstype";
import {isArray} from "lodash";
import {isEmpty} from "lodash";
import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {useReducer} from "react";
import {useCallback} from "react";
import {useMemo} from "react";
import {useFormContext} from "react-hook-form";
import {UseFormWatch} from "react-hook-form";
import {UseFormTrigger} from "react-hook-form";
import {UseFormGetFieldState} from "react-hook-form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {isGridId} from "../../../api/meta/base/ApiPlus";
import {isSectionId} from "../../../api/meta/base/ApiPlus";
import {isFieldId} from "../../../api/meta/base/ApiPlus";
import {DefnDtoDynamicCondition} from "../../../api/meta/base/dto/DefnDtoDynamicCondition";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldButton} from "../../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldCounter} from "../../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldDecimal} from "../../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldDynamic} from "../../../api/meta/base/dto/DefnFieldDynamic";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldNumber} from "../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldParagraph} from "../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldText} from "../../../api/meta/base/dto/DefnFieldText";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../api/meta/base/dto/DefnGrid";
import {DefnMapOfDynamicCondition} from "../../../api/meta/base/dto/DefnMapOfDynamicCondition";
import {DefnSection} from "../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnTab} from "../../../api/meta/base/dto/DefnTab";
import {DefnVisibilityActionMap} from "../../../api/meta/base/dto/DefnVisibilityActionMap";
import {DefnVisibilityCondition} from "../../../api/meta/base/dto/DefnVisibilityCondition";
import {DefnVisibilityConditionMap} from "../../../api/meta/base/dto/DefnVisibilityConditionMap";
import {FieldDtoArg} from "../../../api/meta/base/dto/FieldDtoArg";
import {FieldValueSwitch} from "../../../api/meta/base/dto/FieldValueSwitch";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {ValidationResult} from "../../../api/meta/base/dto/ValidationResult";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MetaIdGroup} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {MetaIdAction} from "../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {MetaIdComp} from "../../../api/meta/base/Types";
import {MetaIdVisibilityRule} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {MetaIdVisibilityCondition} from "../../../api/meta/base/Types";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {Sig} from "../../../api/nucleus/base/sig/Sig";
import {resolveSymbolicText} from "../../../base/plus/ArgBinderPlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {ensureInitValues} from "../../../base/plus/FieldValuePlus";
import {loopDefnForm} from "../../../base/plus/FormPlus";
import {getCombinedFieldId} from "../../../base/plus/FormPlus";
import {isLastFieldInDisplay} from "../../../base/plus/FormPlus";
import {getFieldKey} from "../../../base/plus/FormPlus";
import {fnResolveFieldPropShowAsDropdown} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropDefaultValue} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropJustifyText} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropColor} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropDisableElevation} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMax} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMin} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropStepCounter} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropStepSlider} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropShowSeconds} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMaxSlider} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMinSlider} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropOpacity} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropTextSize} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropUnderline} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropStrikeThrough} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMaxDateTime} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMinDateTime} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMaxSize} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropLineCount} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropTextPattern} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropItalic} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropBold} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropShowSize} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropShowPreview} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMaxDate} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropShowLabel} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMinDate} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropIcon} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropDigitsAfterPeriod} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropShowAsCheckbox} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropHelperText} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropRequired} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropPlaceholder} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropDisabled} from "../../../base/plus/FormYupPlus";
import {px} from "../../../base/plus/StringPlus";
import {getFormFieldsErrorList} from "../../../base/plus/StudioPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {getValidURL} from "../../../base/plus/UrlPlus";
import {IAsidePropsStudio} from "../../../base/types/TypesAside";
import {IDefnDynamicFieldArgs} from "../../../base/types/TypesForm";
import {IDefnFormVisibilityOptions} from "../../../base/types/TypesForm";
import {CbFieldRemoteClick} from "../../../base/types/TypesForm";
import {DefnFormUi} from "../../../base/types/TypesForm";
import {IFormRef} from "../../../base/types/TypesForm";
import {IStudioJumpPath} from "../../../base/types/TypesStudio";
import {EnumStudioSearchPathKeys} from "../../../base/types/TypesStudio";
import {ICallerEnt} from "../../../cache/app/callerEnt/TypesCacheCallerEnt";
import {FIELD_INVOICE_ID} from "../../../srvc/app/payment/TypesPayment";
import {Srvc} from "../../../srvc/Srvc";
import IconButtonStrip from "../../atom/icon/IconButtonStrip";
import LayoutFlexRow from "../../atom/layout/LayoutFlexRow";
import {IPaneFooterButton} from "../../atom/pane/PaneFooter";
import {useAppCtx} from "../../ctx/CtxApp";
import {IFormFieldListApis} from "../viewer/base/CtxForm";
import IFormCtx from "../viewer/base/CtxForm";
import {getHyperLinkRowFieldIdMap} from "../viewer/base/FormViewerPlus";
import FieldRawButton from "../viewer/raw/FieldRawButton";

interface CustomButtonProps
{
  defnForm: DefnForm,
  entId?: EntId,
  formValue?: FormValue
  commentCount?: number,
  showComments?: boolean,
  unreadCommentCount?: number,
  cbOnClickButton: (fieldId: MetaIdField) => void,
  cbOnClickComments?: () => void
}

export function useStepStudioAsideDefaultConfig<T>(
  stepName: EnumStudioSearchPathKeys,
  cbFormRef: IFormRef,
  item?: T,
  validationResult?: ValidationResult,
  preventSetFormError?: boolean,
  preventJumpToField?: boolean)
{
  const appCtx = useAppCtx();
  const asideProps = appCtx.getAsideProps() as IAsidePropsStudio;
  const metaId = asideProps.metaId;
  const reset = asideProps.reset;
  const jumpPath = asideProps.jumpPath;
  const readOnly = asideProps.readOnly;
  const errorList = useMemo(() =>
  {
    // @ts-ignore
    const _metaId = item && Object.hasOwn(item, "metaId") && item.metaId === metaId ? metaId : undefined;
    return getFormFieldsErrorList(stepName, _metaId, validationResult);
  }, [item, metaId, stepName, validationResult]);

  const timeOutId = useRef<NodeJS.Timeout | undefined>();

  useEffect(() =>
  {
    if(errorList && !preventSetFormError)
    {
      timeOutId.current = setTimeout(() => cbFormRef.setErrors(errorList));
    }
    return () =>
    {
      if(timeOutId.current)
      {
        clearTimeout(timeOutId.current);
      }
    };
  }, [cbFormRef, item, errorList, preventSetFormError, reset]);

  useEffect(() =>
  {
    if(!item && !reset)
    {
      appCtx.setAsideProps(undefined);
    }
  }, [appCtx, item, reset]);

  useEffect(() =>
  {
    if(reset)
    {
      cbFormRef.remoteReset();
      cbFormRef.checkSubmit();
      appCtx.setAsideProps({
        ...asideProps,
        reset: false
      });
    }
  }, [reset, item, asideProps, cbFormRef, appCtx]);

  useEffect(() =>
  {
    return () =>
    {
      if(cbFormRef.isDirty() && !readOnly)
      {
        cbFormRef.remoteSubmit(true);
      }
    };
  }, [cbFormRef, metaId]);

  useEffect(() =>
  {
    if(!reset && !preventJumpToField)
    {
      if(jumpPath)
      {
        const newJumpPath = prepareFormFieldJumpPath(jumpPath);
        cbFormRef.jumpToField(newJumpPath);
      }
    }
  }, [cbFormRef, jumpPath, reset]);
}

function prepareFormFieldJumpPath(jumpPath?: IStudioJumpPath[])
{
  if(jumpPath)
  {
    const newJumpPath = [] as IStudioJumpPath[];

    let i = 3;
    const len = jumpPath.length;

    while(i > 1 && i <= len)
    {
      getGroupedJumpPath(jumpPath, i, newJumpPath);
      i--;
    }
    return [...newJumpPath, ...jumpPath];
  }

}

function getGroupedJumpPath(jumpPath: IStudioJumpPath[], len: number, newJumpPath: IStudioJumpPath[])
{
  let start = 0;
  while(start + len <= jumpPath.length)
  {
    let end = start + len;
    const _errorList = [] as IStudioJumpPath[];
    for(let j = start; j < end; j++)
    {
      const errorItem = jumpPath[j];
      _errorList.push(errorItem);
    }
    const errorItem = combineJumpPathItem(_errorList);
    if(errorItem)
    {
      newJumpPath.push(errorItem);
    }
    start++;
  }

}

function combineJumpPathItem(jumpPath: IStudioJumpPath[])
{
  if(jumpPath.length > 1)
  {
    let sameMsg = true;
    let key: string | undefined = jumpPath[0].step;
    for(let i = 1; i < jumpPath.length; i++)
    {
      if(key)
      {
        const item = jumpPath[i];
        if(item.step)
        {
          key = getCombinedFieldId([key, item.step]);
        }
      }
    }
    if(sameMsg && key)
    {
      return {
        ...jumpPath[0],
        step: key
      } as IStudioJumpPath;
    }
  }
}

export function useFormVisibilityRule(props: {
  defnForm?: DefnForm,
  formRef?: IFormRef,
  formValue?: FormValue,
  cbCallPluginApi?: (key: MetaIdField, cb?: (sigAcceptor: EnvSignal<Sig>) => void) => void,
  cbExecuteAction?: (actionId: MetaIdAction, mappingVarId?: MetaIdVar, groupIdSet?: MetaIdGroup[]) => void,
  cbGetCallerEnt?: () => ICallerEnt | undefined,
  cbExecuteBtnAction?: (actionId: MetaIdAction, mappingVarId?: MetaIdVar, groupIdSet?: MetaIdGroup[]) => void,
})
{
  const actionDurationMs = 1000;
  const defnForm = props.defnForm;
  const formValue = props.formValue;
  const formRef = props.formRef;
  const cbExecuteAction = props.cbExecuteAction;
  const cbGetCallerEnt = props.cbGetCallerEnt;

  const initValueMap = defnForm
    ? ensureInitValues(defnForm, formValue?.valueMap || {})
    : undefined;

  const [isSendButtonHidden, setIsSendButtonHidden] = useState<boolean>(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState<boolean>(false);

  const remoteCbButtonClick = useFormButtonAction({
    defnForm,
    cbCallTargetApi: props.cbCallPluginApi,
    remoteCbClick: formRef?.remoteCbClick,
    getValue: formRef?.getValue,
    setValue: formRef?.setValue,
    setFieldDisable: formRef?.setFieldDisable,
    setFieldVisibilityOption: formRef?.setFieldVisibilityOption,
    remoteReset: formRef?.remoteReset,
    initValues: initValueMap,
    cbExecuteBtnAction: props.cbExecuteBtnAction
  });

  const {
    resolveConditionTree,
    fnGetArgBinderValue
  } = useResolveConditions({
    defnForm,
    formValue,
    cbGetCallerEnt
  });

  const visibilityRuleMap = defnForm?.visibilityRuleMap;
  const visibilityRuleFieldMap = useRef<Record<MetaIdField, Set<MetaIdVisibilityRule>>>({});

  const resolveVisibilityActionOnForm = useCallback((
    actionMap: DefnVisibilityActionMap,
    fieldVisibilityOptionMap: Record<MetaIdComp, IDefnFormVisibilityOptions>) =>
  {
    actionMap?.keys?.forEach((actionId) =>
    {
      const action = actionMap.map[actionId];
      const compId = action.compId;
      const compositeId = isSectionId(compId) || isGridId(compId)
        ? compId
        : undefined;
      const fieldId = isFieldId(compId)
        ? compId
        : undefined;

      if(action.visibilityAction === "executeAction")
      {
        const actionId = action.actionId;

        if(actionId)
        {
          cbExecuteAction && cbExecuteAction(actionId, action.mappingVarId, action.groupIdSet);
        }
      }
      else if(action.visibilityActionOn === "sendButton")
      {
        switch(action.visibilityAction)
        {
          case "visible":
            setIsSendButtonHidden(false);
            break;
          case "invisible":
            setIsSendButtonHidden(true);
            break;
          case "hidden":
            setIsSendButtonHidden(true);
            break;
          case "enable":
            setIsSendButtonDisabled(false);
            break;
          case "disable":
            setIsSendButtonDisabled(true);
            break;
        }
      }
      else
      {
        if(fieldId)
        {
          const fieldVisibilityOption = {
            ...fieldVisibilityOptionMap[fieldId]
          } as IDefnFormVisibilityOptions;

          switch(action.visibilityAction)
          {
            case "click":
              remoteCbButtonClick(fieldId);
              break;
            case "disable":
              formRef?.setFieldDisable(fieldId, true);
              break;
            case "enable":
              formRef?.setFieldDisable(fieldId, false);
              break;
            case "visible":
              fieldVisibilityOption.invisible = false;
              fieldVisibilityOption.hidden = false;
              break;
            case "invisible":
              fieldVisibilityOption.invisible = true;
              break;
            case "hidden":
              fieldVisibilityOption.hidden = true;
              break;
            case "shake":
              fieldVisibilityOption.shakeDurationMs = actionDurationMs;
              break;
            case "blink":
              fieldVisibilityOption.blinkDurationMs = actionDurationMs;
              break;
            case "highlight":
              fieldVisibilityOption.highlightDurationMs = actionDurationMs;
              break;
            case "clear":
              formRef?.setValue(fieldId, null);
              break;
            case "setValue":
              if(action.source)
              {
                const resolvedValue = fnGetArgBinderValue(action.source, formRef?.getValues());
                const fieldType = defnForm?.compMap[fieldId].type;

                if(fieldType)
                {
                  formRef?.setValue(fieldId, fnRawValueToFieldValue(fieldType, resolvedValue));
                }
              }
              break;
          }

          fieldVisibilityOptionMap[fieldId] = fieldVisibilityOption;
        }

        if(compositeId)
        {
          const fieldVisibilityOption = {
            ...fieldVisibilityOptionMap[compositeId]
          } as IDefnFormVisibilityOptions;

          switch(action.visibilityAction)
          {
            case "visible":
              fieldVisibilityOption.invisible = false;
              fieldVisibilityOption.hidden = false;
              break;
            case "hidden":
              fieldVisibilityOption.hidden = true;
              break;
            case "disable":
              formRef?.setFieldDisable(compositeId, true);
              break;
            case "enable":
              formRef?.setFieldDisable(compositeId, false);
              break;
          }

          fieldVisibilityOptionMap[compositeId] = fieldVisibilityOption;
        }
      }
    });
  }, [formRef, remoteCbButtonClick, cbExecuteAction]);

  const resolveRules = useCallback((compId: MetaIdComp, valueMap?: FieldValues) =>
  {
    if(visibilityRuleMap && valueMap)
    {
      const rules = visibilityRuleFieldMap.current[compId];
      if(rules)
      {
        const fieldVisibilityOption = {
          ...formRef?.getFieldVisibilityOption(compId)
        } as Record<MetaIdComp, IDefnFormVisibilityOptions>;

        rules.forEach((ruleId) =>
        {
          const rule = visibilityRuleMap.map[ruleId];
          if(rule)
          {
            if(formRef)
            {
              if(resolveConditionTree(rule.conditionNode, valueMap))
              {
                resolveVisibilityActionOnForm(rule.actionMapIfTrue, fieldVisibilityOption);
              }
              else
              {
                resolveVisibilityActionOnForm(rule.actionMapIfFalse, fieldVisibilityOption);
              }
            }
          }
        });

        if(formRef && formRef?.setFieldVisibilityOption)
        {
          Object.entries(fieldVisibilityOption).forEach(([key, value]) =>
          {
            formRef?.setFieldVisibilityOption(key, value);
          });
        }
      }
    }
  }, [formRef, resolveConditionTree, resolveVisibilityActionOnForm, visibilityRuleMap]);

  const executeVisibilityAction = useCallback((compId: MetaIdComp, valueMap: FieldValues) =>
  {
    resolveRules(compId, valueMap);
  }, [resolveRules]);

  const calcVisibilityRuleFieldMap = (
    visibilityRuleId: MetaIdVisibilityRule,
    condition: DefnVisibilityConditionMap) =>
  {
    if(condition?.statement)
    {
      const lhs = condition.statement.lhs;
      const rhs = condition.statement.rhs;
      const fieldIdSet = [lhs] as MetaIdField[];
      if(rhs?.valueFieldId)
      {
        fieldIdSet.push(rhs.valueFieldId);
      }

      fieldIdSet.forEach((fieldId) =>
      {
        if(visibilityRuleFieldMap.current[fieldId])
        {
          visibilityRuleFieldMap.current[fieldId].add(visibilityRuleId);
        }
        else
        {
          visibilityRuleFieldMap.current[fieldId] = new Set<MetaIdVisibilityRule>([visibilityRuleId]);
        }
      });
    }
    else if(condition?.andOr !== undefined)
    {
      condition.keys?.forEach((conditionId) =>
      {
        const _condition = condition.map?.[conditionId];
        if(_condition)
        {
          calcVisibilityRuleFieldMap(visibilityRuleId, _condition);
        }
      });
    }

  };

  useEffect(() =>
  {
    if(visibilityRuleMap)
    {
      visibilityRuleMap.keys.forEach((key) =>
      {
        const rule = visibilityRuleMap.map[key];

        if(rule)
        {
          calcVisibilityRuleFieldMap(key, rule.conditionNode);
        }
      });
    }

  }, [visibilityRuleMap]);

  return {
    executeVisibilityAction: executeVisibilityAction,
    isSendButtonHidden: isSendButtonHidden,
    isSendButtonDisabled: isSendButtonDisabled
  };
}

export function useFormButtonAction(props: {
  defnForm?: DefnForm,
  initValues?: FieldValues
  cbCallTargetApi?: (key: MetaIdField, cb?: (sigAcceptor: EnvSignal<Sig>) => void) => void,
  remoteCbClick?: (key: MetaIdField) => void,
  setFieldDisable?: (key: MetaIdField, disable?: boolean) => void,
  setValue?: (key: MetaIdField, value: any) => void,
  getValue?: (key: MetaIdField) => any,
  remoteReset?: (values?: FieldValues) => void;
  setFieldVisibilityOption?: (key: MetaIdField, options?: IDefnFormVisibilityOptions) => void,
  cbExecuteBtnAction?: (actionId: MetaIdAction, mappingVarId?: MetaIdVar, groupIdSet?: MetaIdGroup[]) => void,
})
{
  const {
    defnForm,
    cbCallTargetApi,
    remoteCbClick,
    setFieldVisibilityOption,
    setFieldDisable,
    setValue,
    getValue,
    initValues,
    remoteReset,
    cbExecuteBtnAction
  } = props;

  const appCtx = useAppCtx();

  const compMap = defnForm?.compMap;

  const cbExecuteFieldButtonActions = useCallback((fieldId: MetaIdField) =>
    {
      const defn: DefnFieldButton | undefined = compMap ? compMap[fieldId] as DefnFieldButton : undefined;
      if(defn)
      {
        const executeActionsAfterTargetSuccess = defn.executeActionsAfterTargetSuccess;

        if(executeActionsAfterTargetSuccess)
        {
          if(defn.actionInvokePluginApi || defn.spreadsheetId || defn.reportId)
          {
            cbCallTargetApi && cbCallTargetApi(fieldId, sigAcceptor =>
            {
              if(!sigAcceptor.error)
              {
                executeFieldButtonActions(fieldId, true);
              }
            });
          }
        }
        else
        {
          executeFieldButtonActions(fieldId);
        }
      }

    }, [
      appCtx,
      cbCallTargetApi,
      compMap,
      defnForm,
      getValue,
      initValues,
      remoteCbClick,
      remoteReset,
      setFieldDisable,
      setFieldVisibilityOption,
      setValue
    ]
  );

  const executeFieldButtonActions = useCallback((fieldId: MetaIdField, skipPluginAction?: boolean) =>
    {
      const defn: DefnFieldButton | undefined = compMap
        ? compMap[fieldId] as DefnFieldButton
        : undefined;

      if(defn)
      {
        if(defn.type === "button"
          && !defn.disabled
          && defn.buttonKind === "normal")
        {
          if(defn.actionCloseAside)
          {
            if(appCtx.setAsideProps)
            {
              appCtx.setAsideProps(undefined);
            }
          }

          if(defn.actionSetDefaultFieldIdArray && setValue)
          {
            const fieldValues = defnForm
              ? ensureInitValues(defnForm, {})
              : {} as FieldValues;
            defn.actionSetDefaultFieldIdArray.forEach((fieldId) =>
            {
              setValue && setValue(fieldId, fieldValues[fieldId]);
            });
          }

          if(defn.actionToggleBooleanFieldIdArray && setValue && getValue)
          {
            defn.actionToggleBooleanFieldIdArray.forEach((fieldId) =>
            {
              const fieldValue = getValue(fieldId);
              setValue(fieldId, {value: !Boolean(fieldValue?.value)});
            });
          }

          if(defn.actionVisibleFieldIdArray && setFieldVisibilityOption)
          {
            defn.actionVisibleFieldIdArray.forEach((fieldId) =>
            {
              setFieldVisibilityOption(fieldId,
                {
                  invisible: false,
                  hidden: false
                }
              );
            });
          }

          if(defn.actionInvisibleFieldIdArray && setFieldVisibilityOption)
          {
            defn.actionInvisibleFieldIdArray.forEach((fieldId) =>
            {
              setFieldVisibilityOption(fieldId,
                {
                  invisible: true,
                  hidden: true
                }
              );
            });
          }

          if(defn.actionEnableFieldIdArray && setFieldDisable)
          {
            defn.actionEnableFieldIdArray.forEach((fieldId) =>
            {
              setFieldDisable(fieldId, false);
            });
          }

          if(defn.actionDisableFieldIdArray && setFieldDisable)
          {
            defn.actionDisableFieldIdArray.forEach((fieldId) =>
            {
              setFieldDisable(fieldId, true);
            });
          }

          if(defn.actionClickButtonFieldIdArray && remoteCbClick)
          {
            defn.actionClickButtonFieldIdArray.forEach((fieldId) =>
            {
              if(remoteCbClick)
              {
                remoteCbClick(fieldId);
              }
              else
              {
                cbExecuteFieldButtonActions(fieldId);
              }
            });
          }

          if(defn.actionOpenLinkInSameTabVar?.value)
          {
            window.location.href = getValidURL(defn.actionOpenLinkInSameTabVar?.value);
          }

          if(defn.actionOpenLinkInNewTabVar?.value)
          {
            window.open(getValidURL(defn.actionOpenLinkInNewTabVar?.value));
          }

          if(getValue && defn.actionOpenLinkInSameTabFieldId)
          {
            const value = getValue(defn.actionOpenLinkInSameTabFieldId);
            if(value?.value)
            {
              window.location.href = getValidURL(value.value);
            }
          }

          if(getValue && defn.actionOpenLinkInNewTabFieldId)
          {
            const value = getValue(defn.actionOpenLinkInNewTabFieldId);
            if(value?.value)
            {
              window.open(getValidURL(value.value));
            }
          }

          if(defn.targetType === "triggerAction"
            && defn.actionId
            && cbExecuteBtnAction)
          {
            cbExecuteBtnAction(
              defn.actionId,
              defn.actionMappingVarId,
              defn.actionGroupIdSet
            );
          }

          if(!skipPluginAction)
          {
            if(defn.actionInvokePluginApi || defn.spreadsheetId || defn.reportId)
            {
              cbCallTargetApi && cbCallTargetApi(fieldId);
            }
          }
        }
        if(defnForm && defn.buttonKind === "reset" && remoteReset)
        {
          const fieldValues = defnForm
            ? ensureInitValues(defnForm, initValues ?? {})
            : {} as FieldValues;

          if(getValue)
          {
            fieldValues[defnForm.displayCompositeId] = getValue(defnForm.displayCompositeId);
          }
          remoteReset(fieldValues);
        }

      }

    },
    [
      appCtx,
      cbCallTargetApi,
      cbExecuteFieldButtonActions,
      compMap,
      defnForm,
      getValue,
      initValues,
      remoteCbClick,
      remoteReset,
      setFieldDisable,
      setFieldVisibilityOption,
      setValue,
      cbExecuteBtnAction
    ]
  );

  return cbExecuteFieldButtonActions;
}

export function useCustomFieldButton(props: CustomButtonProps)
{
  const {
    cbOnClickButton,
    cbOnClickComments,
    entId,
    defnForm,
    formValue,
    commentCount,
    unreadCommentCount,
    showComments
  } = props;

  const createButton = useCallback((defn: DefnFieldButton, position: "toolbar" | "footer") =>
  {
    const handleClick = () =>
    {
      cbOnClickButton(defn.metaId as MetaIdField);
    };

    return <FieldRawButton
      defn={defn}
      cbOnClickBtn={handleClick}
      size={"small"}
      pr={position === "footer" ? gapHalf : undefined}
      toolbar={position === "toolbar"}
      footerBtn={position === "footer"}
    />;
  }, [cbOnClickButton]);

  const [toolbarIconButtons, setToolbarIconButtons] = useState<React.ReactNode[]>([]);
  const [footerNodeButtons, setFooterNodeButtons] = useState<IPaneFooterButton[]>([]);

  useEffect(() =>
  {
    const toolbarIconButtons: React.ReactNode[] = [];
    const footerNodeButtons: IPaneFooterButton[] = [];
    const showCommentLabel = commentCount && commentCount > 0;

    if(showComments)
    {
      const node = <Button
        disableFocusRipple={true}
        size="small"
        onClick={cbOnClickComments}
        sx={{
          mr: px(gapHalf),
          minWidth: px(45),
          textTransform: "none",
          justifyContent: "center"
        }}
      >
        <LayoutFlexRow>
          <IconButtonStrip
            iconButton={"comment"}
            color={unreadCommentCount ? "success" : undefined}
          />
          {
            showCommentLabel
              ? <Typography
                sx={{
                  marginLeft: px(gapQuarter),
                  color: unreadCommentCount ? "success" : "black"
                }}
              >
                {commentCount}
              </Typography>
              : null
          }
        </LayoutFlexRow>
      </Button>;

      footerNodeButtons.push({
        node: node,
        position: "right"
      });
    }

    loopDefnForm(defnForm, (parent, comp) =>
    {
      if(comp?.type === "button")
      {
        const defn = comp as DefnFieldButton;

        if(defn.btnPosToolbar)
        {
          toolbarIconButtons.push(createButton(defn, "toolbar"));
        }

        if(defn.btnPosFooter)
        {
          footerNodeButtons.push({
            node: createButton(defn, "footer"),
            position: "left"
          });
        }
      }
    });

    setToolbarIconButtons(toolbarIconButtons);
    setFooterNodeButtons(footerNodeButtons);

  }, [defnForm, createButton]);

  useEffect(() =>
  {
    const invoiceId = fnFieldValueToRawValue("text", formValue?.valueMap[FIELD_INVOICE_ID]) as string;

    if(entId && formValue && invoiceId)
    {
      Srvc.app.payment.rpcEntPaymentStatusGet(
        entId,
        invoiceId,
        (sig) =>
        {
          const paymentStatus = sig.paymentStatus;
          if(paymentStatus === "pending" || paymentStatus === "failed")
          {
            const paymentButton = {
              position: "left",
              icon: "payment",
              label: "Retry Payment"
            } as IPaneFooterButton;

            setFooterNodeButtons([paymentButton, ...footerNodeButtons]);
          }
        }
      );
    }
  }, [entId, formValue]);

  return {
    toolbarIconButtons,
    footerNodeButtons
  };
}

export function useDynamicFieldRule(props: {
  defnForm?: DefnForm,
  formRef?: IFormRef,
  formValue?: FormValue,
  cbGetCallerEnt?: () => ICallerEnt | undefined
})
{
  const defnForm = props.defnForm;
  const formRef = props.formRef;
  const formValue = props.formValue;
  const cbGetCallerEnt = props.cbGetCallerEnt;

  const {resolveConditionTree} = useResolveConditions({
    defnForm,
    formValue,
    cbGetCallerEnt
  });

  const dynamicRuleFieldMap = useRef<Record<MetaIdField, MetaIdField[]>>({});

  const resolveRules = useCallback((fieldId: MetaIdField, valueMap?: FieldValues) =>
  {
    if(valueMap)
    {
      const dynamicIdSet = dynamicRuleFieldMap.current[fieldId];

      if(dynamicIdSet && dynamicIdSet.length > 0)
      {
        dynamicIdSet.forEach((dynamicId) =>
        {
          const defnDynamicField = defnForm?.compMap[dynamicId] as DefnFieldDynamic | undefined;
          const ruleMap = defnDynamicField?.ruleMap;
          const length = ruleMap?.keys.length;
          if(length)
          {
            for(let i = 0; i < length; i++)
            {
              const ruleId = ruleMap?.keys[i];
              const rule = ruleMap?.map[ruleId];

              if(rule && resolveConditionTree(rule.conditionNode, valueMap))
              {
                if(formRef?.setFieldDynamicFieldArgs)
                {
                  formRef?.setFieldDynamicFieldArgs(dynamicId, {
                    fieldType: rule?.fieldType,
                    optionMap: rule?.optionMap
                  });
                }
                return;
              }
            }
          }
        });
      }
    }
  }, [dynamicRuleFieldMap.current]);

  const onWatchDynamicFieldRule = useCallback((fieldId: MetaIdField, valueMap: FieldValues) =>
  {
    resolveRules(fieldId, valueMap);
  }, [resolveRules]);

  const cbFieldDynamicLoopDefnForm = (comp: DefnSection | DefnGrid | DefnTab | DefnField) =>
  {
    if(comp.type === "dynamic")
    {
      const fieldDynamic = comp as DefnFieldDynamic;
      const ruleMap = fieldDynamic?.ruleMap;
      const dynamicId = fieldDynamic.metaId;

      if(ruleMap)
      {
        ruleMap.keys.forEach((ruleId) =>
        {
          const rule = ruleMap.map[ruleId];
          if(rule)
          {
            const conditionMap = rule.conditionNode;
            if(conditionMap?.keys && conditionMap?.map)
            {
              const lhs = conditionMap.map[conditionMap.keys[0]].statement?.lhs;
              const rhs = conditionMap.map[conditionMap.keys[0]].statement?.rhs;
              const valueFieldId = rhs?.valueFieldId;
              const fieldIdSet: MetaIdField[] = lhs
                ? [lhs]
                : [];

              if(valueFieldId)
              {
                fieldIdSet.push(valueFieldId);
              }

              fieldIdSet.forEach((fieldId) =>
              {
                if(dynamicRuleFieldMap.current[fieldId]?.length)
                {
                  dynamicRuleFieldMap.current[fieldId].push(dynamicId);
                }
                else
                {
                  dynamicRuleFieldMap.current[fieldId] = [dynamicId];
                }
              });
            }
          }
        });
      }
    }
  };

  return {
    onWatchDynamicFieldRule,
    cbFieldDynamicLoopDefnForm
  };
}

function useResolveConditions(props: {
  defnForm?: DefnForm,
  formValue?: FormValue,
  cbGetCallerEnt?: () => ICallerEnt | undefined
})
{
  const defnForm = props.defnForm;
  const formValue = props.formValue;
  const cbGetCallerEnt = props.cbGetCallerEnt;

  const initValueMap = formValue?.valueMap;
  const compMap = defnForm?.compMap;

  const resolvedFieldValue = useCallback((fieldId: MetaIdField, fieldValues: FieldValues) =>
  {
    const compType = compMap ? compMap?.[fieldId]?.type : undefined;
    return compType
      // special case for bool
      ? compType !== "bool"
        ? fnFieldValueToRawValue(compType, fieldValues[fieldId])
        : Boolean((fieldValues[fieldId] as FieldValueSwitch)?.value)
      : undefined;

  }, [compMap]);

  const getFilteredValue = (
    defnForm?: DefnForm,
    initValueMap?: FieldValues) =>
  {
    const values = {} as FieldValues;

    if(defnForm && initValueMap)
    {
      Object.keys(initValueMap).forEach((key) =>
      {
        const field = defnForm.compMap[key] as DefnField | undefined;
        if(field)
        {
          values[field.metaId] = resolvedFieldValue(key, initValueMap);
        }
      });
    }

    return values;
  };

  const previousValueMap = useRef<FieldValues>(getFilteredValue(defnForm, initValueMap));

  const fnGetConditionRHSValue = useCallback((
    argBinder?: FieldDtoArg,
    fieldValues?: FieldValues) =>
  {
    let rhsValue = argBinder;

    if(rhsValue?.valueFieldId)
    {
      return resolvedFieldValue(rhsValue?.valueFieldId, fieldValues ?? {});
    }
    else if(rhsValue?.valueDate)
    {
      return rhsValue.valueDate;
    }
    else if(rhsValue?.valueDouble !== undefined)
    {
      return rhsValue.valueDouble;
    }
    else if(rhsValue?.valueLong !== undefined)
    {
      return rhsValue.valueLong;
    }
    else if(rhsValue?.valueText !== undefined && defnForm)
    {
      return resolveSymbolicText(
        defnForm,
        rhsValue.valueText,
        formValue,
        cbGetCallerEnt && cbGetCallerEnt()
      );
    }
    else if(rhsValue?.valueSysId !== undefined)
    {
      return rhsValue.valueSysId;
    }
    else if(rhsValue?.valueSysIdSet !== undefined)
    {
      return rhsValue.valueSysIdSet;
    }
    else if(rhsValue?.valueBoolean !== undefined)
    {
      return rhsValue.valueBoolean;
    }
  }, [resolvedFieldValue]);

  const resolveStatement = useCallback((
    statement: DefnVisibilityCondition | DefnDtoDynamicCondition,
    fieldValues: FieldValues) =>
  {
    const lhs = statement.lhs;
    const lhsValue = resolvedFieldValue(lhs, fieldValues);

    const rhsValue = fnGetConditionRHSValue(statement.rhs, fieldValues);

    const operator = (statement as DefnVisibilityCondition | DefnDtoDynamicCondition)?.operator;

    switch(operator)
    {
      case "hasNoValue":
        return !lhsValue;
      case "hasValue":
        return !!lhsValue;
      case "equalTo":
        return (isArray(rhsValue) && typeof lhsValue === "string")
          ? rhsValue.includes(lhsValue)
          : lhsValue === rhsValue;
      case "notEqualTo":
        return (isArray(rhsValue) && typeof lhsValue === "string")
          ? !rhsValue.includes(lhsValue)
          : lhsValue !== rhsValue;
      case "hasChanged":
        const hasChanged = lhsValue !== previousValueMap.current[lhs];
        previousValueMap.current[lhs] = lhsValue;
        return hasChanged;
      default:
        return false;
    }
  }, [fnGetConditionRHSValue, resolvedFieldValue, cbGetCallerEnt]);

  const resolveCondition = useCallback((
    condition: DefnVisibilityConditionMap | DefnMapOfDynamicCondition,
    fieldValues: FieldValues) =>
  {
    if(condition.andOr !== undefined)
    {
      const conditionResult = {} as Record<MetaIdVisibilityCondition, boolean>;
      const conjunction = condition.andOr ? "and" : "or";

      condition.keys?.forEach((conditionId) =>
      {
        const _condition = condition.map?.[conditionId];
        if(_condition)
        {
          const statement = _condition?.statement;
          if(statement)
          {
            conditionResult[conditionId] = resolveStatement(statement, fieldValues);
          }
          else
          {
            conditionResult[conditionId] = resolveCondition(_condition, fieldValues);
          }
        }
      });

      if(conjunction === "and")
      {
        return Object.values(conditionResult).every((value) => value);
      }
      else
      {
        return Object.values(conditionResult).some((value) => value);
      }
    }
    else if(condition.statement)
    {
      return resolveStatement(condition.statement, fieldValues);
    }
    return false;
  }, [resolveStatement]);

  const resolveConditionTree = useCallback((
    conditionMap: DefnVisibilityConditionMap | DefnMapOfDynamicCondition,
    fieldValues: FieldValues) =>
  {
    return resolveCondition(conditionMap, fieldValues);
  }, [resolveCondition]);

  return {
    resolveConditionTree,
    fnGetArgBinderValue: fnGetConditionRHSValue
  };
}

//region FormRef

interface IFormRefStore
{
  fieldVisibilityOptionMap: Record<MetaIdField, IDefnFormVisibilityOptions>;
  fieldDisableMap: Record<MetaIdField, boolean>;
  fieldReadOnlyMap: Record<MetaIdField, boolean>;
  fieldLoadingMap: Record<MetaIdField, boolean>;
  fieldDynamicFieldTypeMap: Record<MetaIdField, IDefnDynamicFieldArgs>;
  fieldBorderColorMap: Record<MetaIdField, Property.BorderColor>;
  fieldRefChildParentMap: Record<MetaIdField, MetaIdField>;
}

interface IFormRefAction
{
  type:
    | "setFieldVisibilityOption"
    | "setFieldDisableMap"
    | "setFieldDisable"
    | "setFieldReadOnlyMap"
    | "setFieldReadOnly"
    | "setFieldDynamicFieldType"
    | "setFieldBorderColorMap"
    | "setFieldBorderColor"
    | "setFieldLoading";
}

interface IFormRefActionSetFieldDynamicFieldArgs extends IFormRefAction
{
  payload: {
    key: MetaIdField,
    fieldType?: EnumDefnCompType,
    optionMap?: DefnStudioMapOfDtoOption
  };
}

interface IFormRefActionSetFieldVisibilityOptionMap extends IFormRefAction
{
  payload: {
    key: MetaIdField,
    options?: IDefnFormVisibilityOptions
  };
}

interface IFormRefActionSetFieldDisableMap extends IFormRefAction
{
  payload: Record<MetaIdField, boolean>;
}

interface IFormRefActionSetFieldDisable extends IFormRefAction
{
  payload: {
    key: MetaIdField,
    disable?: boolean
  };
}

interface IFormRefActionSetFieldLoading extends IFormRefAction
{
  payload: {
    key: MetaIdField,
    loading: boolean
  };
}

interface IFormRefActionSetFieldReadOnlyMap extends IFormRefAction
{
  payload: Record<MetaIdField, boolean>;
}

interface IFormRefActionSetFieldReadOnly extends IFormRefAction
{
  payload: {
    key: MetaIdField,
    readOny?: boolean
  };
}

interface IFormRefActionSetFieldBorderColorMap extends IFormRefAction
{
  payload: Record<MetaIdField, Property.BorderColor>;
}

interface IFormRefActionSetFieldBorderColor extends IFormRefAction
{
  payload: {
    key: MetaIdField,
    borderColor?: Property.BorderColor
  };
}

export interface IFieldTooltip
{
  plusButton?: string;
  field?: string;
}

// region reducer setter functions
function fnSetFieldVisibilityOption(
  state: IFormRefStore,
  action: IFormRefActionSetFieldVisibilityOptionMap): IFormRefStore
{
  const {
    key,
    options
  } = action.payload;

  const fieldVisibilityOptionMap = state.fieldVisibilityOptionMap;

  if(fieldVisibilityOptionMap[key] !== options)
  {
    if(options === undefined)
    {
      delete fieldVisibilityOptionMap[key];
    }
    else
    {
      fieldVisibilityOptionMap[key] = options;
    }
  }
  return {
    ...state,
    fieldVisibilityOptionMap: {
      ...state.fieldVisibilityOptionMap
    }
  };
}

function fnSetFieldDynamicFieldType(
  state: IFormRefStore,
  action: IFormRefActionSetFieldDynamicFieldArgs): IFormRefStore
{
  const {
    key,
    fieldType,
    optionMap
  } = action.payload;
  const fieldDynamicFieldTypeMap = state.fieldDynamicFieldTypeMap;

  if(fieldDynamicFieldTypeMap[key]?.fieldType !== fieldType)
  {
    if(fieldType === undefined)
    {
      delete fieldDynamicFieldTypeMap[key];
    }
    else
    {
      fieldDynamicFieldTypeMap[key] = {
        fieldType,
        optionMap
      };
    }
  }

  return {
    ...state,
    fieldDynamicFieldTypeMap: {
      ...state.fieldDynamicFieldTypeMap
    }
  };
}

function fnSetFieldLoading(
  state: IFormRefStore,
  action: IFormRefActionSetFieldLoading): IFormRefStore
{
  const {
    key,
    loading
  } = action.payload;

  const fieldLoadingMap = state.fieldLoadingMap;

  fieldLoadingMap[key] = loading;

  return {
    ...state,
    fieldLoadingMap: fieldLoadingMap
  };
}

function fnSetFieldDisable(
  state: IFormRefStore,
  action: IFormRefActionSetFieldDisable): IFormRefStore
{
  const {
    key,
    disable
  } = action.payload;
  const fieldDisableMap = state.fieldDisableMap;
  if(fieldDisableMap[key] !== disable)
  {
    if(disable === undefined)
    {
      delete fieldDisableMap[key];
    }
    else
    {
      fieldDisableMap[key] = disable;
    }
  }
  return {
    ...state,
    fieldDisableMap: fieldDisableMap
  };
}

function fnSetFieldDisableMap(
  state: IFormRefStore,
  action: IFormRefActionSetFieldDisableMap): IFormRefStore
{
  return {
    ...state,
    fieldDisableMap: action.payload
  };
}

function fnSetFieldReadOnly(
  state: IFormRefStore,
  action: IFormRefActionSetFieldReadOnly): IFormRefStore
{
  const {
    key,
    readOny
  } = action.payload;
  const fieldReadOnlyMap = state.fieldReadOnlyMap;
  if(fieldReadOnlyMap[key] !== readOny)
  {
    if(readOny === undefined)
    {
      delete fieldReadOnlyMap[key];
    }
    else
    {
      fieldReadOnlyMap[key] = readOny;
    }
  }
  return {
    ...state,
    fieldReadOnlyMap: fieldReadOnlyMap
  };
}

function fnSetFieldReadOnlyMap(
  state: IFormRefStore,
  action: IFormRefActionSetFieldReadOnlyMap): IFormRefStore
{
  return {
    ...state,
    fieldReadOnlyMap: action.payload
  };
}

function fnSetFieldBorderColor(
  state: IFormRefStore,
  action: IFormRefActionSetFieldBorderColor): IFormRefStore
{
  const {
    key,
    borderColor
  } = action.payload;
  const fieldBorderColorMap = state.fieldBorderColorMap;
  if(fieldBorderColorMap[key] !== borderColor)
  {
    if(borderColor === undefined)
    {
      delete fieldBorderColorMap[key];
    }
    else
    {
      fieldBorderColorMap[key] = borderColor;
    }
  }
  return {
    ...state,
    fieldBorderColorMap: fieldBorderColorMap
  };
}

function fnSetFieldBorderColorMap(
  state: IFormRefStore,
  action: IFormRefActionSetFieldBorderColorMap): IFormRefStore
{
  return {
    ...state,
    fieldBorderColorMap: action.payload
  };
}

function fnFormRefReducer(
  state: IFormRefStore,
  action: IFormRefAction)
{
  switch(action.type)
  {
    case "setFieldReadOnlyMap":
      return fnSetFieldReadOnlyMap(state, action as IFormRefActionSetFieldReadOnlyMap);
    case "setFieldReadOnly":
      return fnSetFieldReadOnly(state, action as IFormRefActionSetFieldReadOnly);
    case "setFieldBorderColorMap":
      return fnSetFieldBorderColorMap(state, action as IFormRefActionSetFieldBorderColorMap);
    case "setFieldBorderColor":
      return fnSetFieldBorderColor(state, action as IFormRefActionSetFieldBorderColor);
    case "setFieldVisibilityOption":
      return fnSetFieldVisibilityOption(state, action as IFormRefActionSetFieldVisibilityOptionMap);
    case "setFieldDynamicFieldType":
      return fnSetFieldDynamicFieldType(state, action as IFormRefActionSetFieldDynamicFieldArgs);
    case "setFieldDisable":
      return fnSetFieldDisable(state, action as IFormRefActionSetFieldDisable);
    case "setFieldLoading":
      return fnSetFieldLoading(state, action as IFormRefActionSetFieldLoading);
    case "setFieldDisableMap":
      return fnSetFieldDisableMap(state, action as IFormRefActionSetFieldDisableMap);
  }

  return state;
}

// endregion

export function useFormRefAndCtxSetter(props: {
  defnForm: DefnFormUi,
  formCtx: IFormCtx,
  cbRef?: IFormRef,
  getValues: () => FieldValues,
  formDisable?: boolean,
  formReadonly?: boolean,
})
{
  const formCtx = props.formCtx;
  const defnForm = props.defnForm;
  const cbRef = props.cbRef;
  const formReadonly = props.formReadonly;
  const getValues = props.getValues;

  // region state

  const store = useRef<IFormRefStore>({
    fieldVisibilityOptionMap: {},
    fieldDisableMap: {},
    fieldDynamicFieldTypeMap: {},
    fieldBorderColorMap: {},
    fieldReadOnlyMap: {},
    fieldRefChildParentMap: {},
    fieldLoadingMap: {}
  });
  const [state, dispatch] = useReducer(fnFormRefReducer, store.current);
  const [searchText, setSearchText] = useState<string>();
  const [formDisable, setFormDisable] = useState(Boolean(props.formDisable));
  const [tooltipMap, setTooltipMap] = useState({} as Record<MetaIdField, IFieldTooltip>);
  const fieldFormListApisMap = useRef({} as Record<MetaIdField, IFormFieldListApis>);
  const fieldRefChildParentMap = useRef({} as Record<MetaIdField, MetaIdField>);
  const [remoteCbClickMap, setRemoteCbClickMap] = useState(new Map<MetaIdField, CbFieldRemoteClick>());
  const parentMap = useRef({} as Record<MetaIdField, MetaIdField>);
  const hyperlinkRowFieldMap = useRef({} as Record<MetaIdField, MetaIdField>);

  const gridAsExcelMap = useRef({} as Record<MetaIdGrid, ((params?: ExcelExportParams) => string | undefined) | undefined>);

  //endregion

  // region cbRef

  const refSetSearch = useCallback((searchText: string) =>
  {
    setSearchText(searchText);
  }, []);

  const refGetTooltipMap = useCallback((key: MetaIdField) => tooltipMap[key], [tooltipMap]);

  const refGetParentMap = useCallback(() => parentMap.current, []);

  const refGetFieldVisibilityOption = useCallback((key: MetaIdField) => state.fieldVisibilityOptionMap[key],
    [state.fieldVisibilityOptionMap]
  );

  const refSetTooltipMap = useCallback((tooltipMap: Record<MetaIdField, IFieldTooltip>) =>
  {
    setTooltipMap(tooltipMap);
  }, []);

  const refSetRefChildParentMap = useCallback((refChildParentMap: Record<MetaIdField, MetaIdField>) =>
  {
    fieldRefChildParentMap.current = refChildParentMap;
  }, []);

  // disable form /field

  const refSetFormDisable = useCallback((disable: boolean) =>
  {
    setFormDisable(disable);
  }, []);

  const refIsFieldDisable = useCallback((compKey: MetaIdField): boolean =>
  {
    if(formDisable)
    {
      return true;
    }

    const disable = state.fieldDisableMap[compKey];
    if(disable === undefined)
    {
      const defn = defnForm.compMap ? defnForm.compMap[compKey] as DefnFieldLabel : undefined;
      return Boolean(defn?.disabled);
    }

    return disable;
  }, [defnForm.compMap, formDisable, state.fieldDisableMap]);

  const refSetFieldDisable = useCallback((key: MetaIdField, disable?: boolean) =>
  {
    dispatch({
      type: "setFieldDisable",
      payload: {
        key,
        disable
      }
    } as IFormRefActionSetFieldDisable);
  }, []);

  const refSetFieldsDisable = useCallback((compMap: Record<MetaIdField, boolean>) =>
  {
    dispatch({
      type: "setFieldDisableMap",
      payload: compMap
    } as IFormRefActionSetFieldDisableMap);

  }, []);

  const refSetFieldLoading = useCallback((key: MetaIdField, loading: boolean) =>
  {
    dispatch({
      type: "setFieldLoading",
      payload: {
        key: key,
        loading: loading
      }
    } as IFormRefActionSetFieldLoading);

  }, []);

  const refRemoteCbClick = useCallback((key: MetaIdField) =>
  {
    const cb = remoteCbClickMap.get(key);
    if(cb)
    {
      cb();
    }
  }, [remoteCbClickMap]);

  const refSetFieldVisibilityOption = useCallback((key: MetaIdField, options?: IDefnFormVisibilityOptions) =>
  {
    dispatch({
      type: "setFieldVisibilityOption",
      payload: {
        key,
        options
      }
    } as IFormRefActionSetFieldVisibilityOptionMap);
  }, []);

  const refSetFieldDynamicFieldType = useCallback((key: MetaIdField, args?: IDefnDynamicFieldArgs) =>
  {
    dispatch({
      type: "setFieldDynamicFieldType",
      payload: {
        key,
        fieldType: args?.fieldType,
        optionMap: args?.optionMap
      }
    } as IFormRefActionSetFieldDynamicFieldArgs);
  }, []);

  const refSetFieldsBorderColor = useCallback((compMap: Record<MetaIdField, Property.BorderColor>) =>
  {
    dispatch({
      type: "setFieldBorderColorMap",
      payload: compMap
    } as IFormRefActionSetFieldBorderColorMap);
  }, []);

  const refSetFieldBorderColor = useCallback((key: MetaIdField, borderColor?: Property.BorderColor) =>
  {
    dispatch({
      type: "setFieldBorderColor",
      payload: {
        key: key,
        borderColor: borderColor
      }
    } as IFormRefActionSetFieldBorderColor);
  }, []);

  const refSetFieldsReadonly = useCallback((compMap: Record<MetaIdField, boolean>) =>
  {
    dispatch({
      type: "setFieldReadOnlyMap",
      payload: compMap
    } as IFormRefActionSetFieldReadOnlyMap);
  }, []);

  const refSetFieldReadonly = useCallback((key: MetaIdField, readOnly?: boolean) =>
  {
    dispatch({
      type: "setFieldReadOnly",
      payload: {
        key: key,
        readOny: readOnly
      }
    } as IFormRefActionSetFieldReadOnly);
  }, []);

  const isFieldHyperlinkRow = useCallback((key: MetaIdField): MetaIdField | undefined =>
  {
    if(!isEmpty(hyperlinkRowFieldMap.current))
    {
      const fieldHyperlink = hyperlinkRowFieldMap.current[key];
      if(fieldHyperlink)
      {
        return fieldHyperlink;
      }
    }
    return;
  }, [defnForm]);

  const refGetGridDataAsExcel = useCallback(() =>
  {
    const data = [] as string[];

    Object.values(gridAsExcelMap.current ?? {}).forEach(fn =>
    {
      const value = fn && fn();
      if(value)
      {
        data.push(value);
      }
    });

    exportMultipleSheetsAsExcel({
      data: data
    });

  }, []);

  if(cbRef)
  {
    cbRef.getTooltipMap = refGetTooltipMap;
    cbRef.getParentMap = refGetParentMap;

    cbRef.setTooltipMap = refSetTooltipMap;
    cbRef.setRefChildParentMap = refSetRefChildParentMap;
    cbRef.setFieldVisibilityOption = refSetFieldVisibilityOption;
    cbRef.getFieldVisibilityOption = refGetFieldVisibilityOption;
    cbRef.setFieldDynamicFieldArgs = refSetFieldDynamicFieldType;
    cbRef.setDisable = refSetFormDisable;
    cbRef.setFieldDisable = refSetFieldDisable;
    cbRef.setFieldsDisable = refSetFieldsDisable;
    cbRef.setFieldLoading = refSetFieldLoading;

    cbRef.isFieldDisable = refIsFieldDisable;
    cbRef.remoteCbClick = refRemoteCbClick;
    cbRef.getFieldFormListApis = (key) => fieldFormListApisMap.current[key];
    cbRef.setFieldBorderColor = refSetFieldBorderColor;
    cbRef.setFieldsBorderColor = refSetFieldsBorderColor;
    cbRef.setSearch = refSetSearch;
    cbRef.setFieldsReadonly = refSetFieldsReadonly;
    cbRef.setFieldReadonly = refSetFieldReadonly;
    cbRef.getGridDataAsExcel = refGetGridDataAsExcel;
  }

  //endregion

  // region ctx
  formCtx.getTooltipMap = (key: MetaIdField) => tooltipMap[key];

  formCtx.isFieldDisable = (defnEditable) =>
  {
    const parentCompId = parentMap.current[defnEditable.metaId];
    const parentComp = state.fieldDisableMap[parentCompId];
    if(formDisable || parentComp)
    {
      return true;
    }

    const disable = state.fieldDisableMap[getFieldKey(defnEditable)];
    if(disable === undefined)
    {
      const values = getValues();
      return Boolean(fnResolveFieldPropDisabled(defnEditable, values));
    }

    return disable;
  };

  formCtx.isLastFieldInDisplay = (key: MetaIdField) => isLastFieldInDisplay(key, defnForm);

  formCtx.isLastField = (key, parentKey) =>
  {
    const displayCompositeId = defnForm.displayCompositeId;
    const displayComposite = defnForm.compMap[displayCompositeId] as DefnSection | DefnGrid;
    const lastFieldId = displayComposite?.fieldIdSet?.at(-1);
    if(!lastFieldId)
    {
      return false;
    }
    const lastField = defnForm.compMap[lastFieldId] as DefnField;
    if(parentKey && defnForm.compMap)
    {
      const parentSection = (defnForm.compMap[parentKey] as DefnSection)?.fieldIdSet;
      if(parentSection)
      {
        return (key === parentSection.at(-1) || (key === lastField?.metaId));
      }
    }
    return key === lastField?.metaId;
  };

  formCtx.isFirstField = (key, parentKey) =>
  {
    const displayCompositeId = defnForm.displayCompositeId;
    const displayComposite = defnForm.compMap[displayCompositeId] as DefnSection | DefnGrid;
    const firstFieldId = displayComposite?.fieldIdSet?.[0];
    if(!firstFieldId)
    {
      return false;
    }
    const firstField = defnForm.compMap[firstFieldId] as DefnField;
    if(parentKey && defnForm.compMap)
    {
      const parentSection = (defnForm.compMap[parentKey] as DefnSection)?.fieldIdSet;
      if(parentSection)
      {
        return (key === parentSection[0] || (key === firstField?.metaId));
      }
    }
    return key === firstField?.metaId;
  };

  formCtx.setCbFieldRemoteClick = (key, cb) =>
  {
    setRemoteCbClickMap((prevState) =>
    {
      prevState.set(key, cb);
      return prevState;
    });
  };

  formCtx.getCbFieldRemoteClick = (key) => remoteCbClickMap.get(key);

  formCtx.setFieldVisibilityOption = refSetFieldVisibilityOption;

  formCtx.setFieldDisable = refSetFieldDisable;

  formCtx.setFieldFormListApis = (key, props) => fieldFormListApisMap.current[key] = props;

  formCtx.getFieldVisibilityOption = useCallback((key) => state.fieldVisibilityOptionMap[key],
    [state.fieldVisibilityOptionMap]
  );

  formCtx.getFieldDynamicFieldArgs = useCallback((key) => state.fieldDynamicFieldTypeMap[key],
    [state.fieldDynamicFieldTypeMap]
  );
  formCtx.getFieldBorderColor = (key: MetaIdField) => state.fieldBorderColorMap[key];
  formCtx.getSearchText = () => searchText;

  formCtx.isFieldReadonly = useCallback((defnLabeled) =>
  {
    if(formReadonly)
    {
      return true;
    }

    const readonly = state.fieldReadOnlyMap[getFieldKey(defnLabeled)];
    if(readonly === undefined)
    {
      return Boolean(defnLabeled.disabled);
    }
    return readonly;
  }, [formReadonly, state.fieldReadOnlyMap]);

  formCtx.isFieldLoading = useCallback((key: MetaIdField) =>
  {
    return Boolean(state.fieldLoadingMap[key]);

  }, [state.fieldLoadingMap]);

  formCtx.setRefChildParentId = (childId, parentId) =>
  {
    fieldRefChildParentMap.current[childId] = parentId;
  };

  formCtx.getRefChildParentId = useCallback((fieldId) =>
  {
    return fieldRefChildParentMap.current[fieldId];

  }, [fieldRefChildParentMap]);

  formCtx.getParentMap = () => parentMap.current;

  formCtx.getFieldIdHyperlinkRow = isFieldHyperlinkRow;

  formCtx.setGridAsExcel = (key: MetaIdGrid, fn?: (params?: ExcelExportParams) => string | undefined) =>
  {
    gridAsExcelMap.current[key] = fn;
  };
  //endregion

  useEffect(() =>
  {
    loopDefnForm(defnForm, (parent, comp) =>
    {
      parentMap.current[comp.metaId] = (parent as DefnSection | DefnGrid)?.metaId;
    });

    hyperlinkRowFieldMap.current = getHyperLinkRowFieldIdMap(defnForm);
  }, [defnForm]);

}

// endregion

export function useFormPropertiesResolver(props: {
  defnForm: DefnForm,
  getFieldState: UseFormGetFieldState<any>,
  trigger: UseFormTrigger<any>,
  watch: UseFormWatch<any>
})
{
  const watch = props.watch;
  const defnForm = props.defnForm;
  const compMap = defnForm.compMap;
  const derivedFieldPropMap = useRef(new Map<MetaIdField, Set<MetaIdField>>());

  const insertFieldPropMap = (fieldId: MetaIdField, propId: MetaIdField) =>
  {
    if(derivedFieldPropMap.current.has(fieldId))
    {
      if(!derivedFieldPropMap.current.get(fieldId)?.has(propId))
      {
        derivedFieldPropMap.current.get(fieldId)?.add(propId);
      }
    }
    else
    {
      derivedFieldPropMap.current.set(fieldId, new Set<MetaIdField>([propId]));
    }
  };

  const onWatch = useCallback((fieldId: MetaIdField, value: any, valueMap: FieldValues) =>
  {
    const defn = compMap[fieldId] as DefnFieldEditable | undefined;
    if(defn)
    {
      if(derivedFieldPropMap.current.has(fieldId))
      {
        derivedFieldPropMap.current.get(fieldId)?.forEach((_fieldId) =>
        {
          if(props.getFieldState(_fieldId).isTouched)
          {
            props.trigger(_fieldId);
          }
        });
      }
    }

  }, [compMap]);

  useEffect(() =>
  {
    let subscription = watch((values, {name}) =>
    {
      const key = name as MetaIdField;
      const value = values[key];
      onWatch(key, value, values);
    });

    return () =>
    {
      if(subscription !== undefined)
      {
        subscription.unsubscribe();
      }
    };
  }, [watch]);

  useEffect(() =>
  {
    loopDefnForm(defnForm, (_, comp) =>
    {
      const metaId = comp.metaId;
      const type = comp.type;
      const requiredFieldId = (comp as DefnFieldEditable).requiredFieldId;
      const helperTextFieldId = (comp as DefnFieldEditable).helperTextFieldId;
      const placeHolderFieldId = (comp as DefnFieldEditable).placeHolderFieldId;

      if(requiredFieldId)
      {
        insertFieldPropMap(requiredFieldId, metaId);
      }
      if(helperTextFieldId)
      {
        insertFieldPropMap(helperTextFieldId, metaId);
      }
      if(placeHolderFieldId)
      {
        insertFieldPropMap(placeHolderFieldId, metaId);
      }
      if(type === "text" || type === "paragraph")
      {
        const minCharCountFieldId = (comp as DefnFieldText | DefnFieldParagraph).minCharCountFieldId;
        const maxCharCountFieldId = (comp as DefnFieldText | DefnFieldParagraph).maxCharCountFieldId;
        if(minCharCountFieldId)
        {
          insertFieldPropMap(minCharCountFieldId, metaId);
        }
        if(maxCharCountFieldId)
        {
          insertFieldPropMap(maxCharCountFieldId, metaId);
        }
      }

      if(type === "number" || type === "counter" || type === "decimal"
        || type === "logNumber" || type === "logCounter" || type === "logDecimal")
      {
        const minFieldId = (comp as DefnFieldNumber | DefnFieldCounter | DefnFieldDecimal).minFieldId;
        const maxFieldId = (comp as DefnFieldNumber | DefnFieldCounter | DefnFieldDecimal).maxFieldId;
        if(minFieldId)
        {
          insertFieldPropMap(minFieldId, metaId);
        }
        if(maxFieldId)
        {
          insertFieldPropMap(maxFieldId, metaId);
        }
      }

    });
  }, [defnForm]);
}

export function useFieldPropertiesResolver(defn: DefnField)
{
  const hookFormCtx = useFormContext();
  const valueMap = hookFormCtx.getValues();

  const getFieldPlaceHolder = () => fnResolveFieldPropPlaceholder(defn, valueMap);

  const getFieldHelperText = () => fnResolveFieldPropHelperText(defn, valueMap);

  const getFieldRequired = () => fnResolveFieldPropRequired(defn, valueMap);

  const getFieldShowAsCheckbox = () => fnResolveFieldPropShowAsCheckbox(defn, valueMap);

  const getFieldDigitsAfterPeriod = () => fnResolveFieldPropDigitsAfterPeriod(defn, valueMap);

  const getFieldIcon = () => fnResolveFieldPropIcon(defn);

  const getFieldMinDate = () => fnResolveFieldPropMinDate(defn, valueMap);

  const getFieldMaxDate = () => fnResolveFieldPropMaxDate(defn, valueMap);

  const getFieldShowLabel = () => fnResolveFieldPropShowLabel(defn, valueMap);

  const getFieldShowPreview = () => fnResolveFieldPropShowPreview(defn, valueMap);

  const getFieldShowSize = () => fnResolveFieldPropShowSize(defn, valueMap);

  const getFieldBold = () => fnResolveFieldPropBold(defn, valueMap);

  const getFieldItalic = () => fnResolveFieldPropItalic(defn, valueMap);

  const getFieldUnderline = () => fnResolveFieldPropUnderline(defn, valueMap);

  const getFieldStrikeThrough = () => fnResolveFieldPropStrikeThrough(defn, valueMap);

  const getFieldTextPattern = () => fnResolveFieldPropTextPattern(defn, valueMap);

  const getFieldLineCount = () => fnResolveFieldPropLineCount(defn, valueMap);

  const getFieldMaxSize = () => fnResolveFieldPropMaxSize(defn, valueMap);

  const getFieldMinDateTime = () => fnResolveFieldPropMinDateTime(defn, valueMap);

  const getFieldMaxDateTime = () => fnResolveFieldPropMaxDateTime(defn, valueMap);

  const getFieldTextSize = () => fnResolveFieldPropTextSize(defn, valueMap);

  const getFieldOpacity = () => fnResolveFieldPropOpacity(defn, valueMap);

  const getFieldMinSlider = () => fnResolveFieldPropMinSlider(defn, valueMap);

  const getFieldMaxSlider = () => fnResolveFieldPropMaxSlider(defn, valueMap);

  const getFieldStepSlider = () => fnResolveFieldPropStepSlider(defn, valueMap);

  const getFieldShowSeconds = () => fnResolveFieldPropShowSeconds(defn, valueMap);

  const getFieldMinCounter = () => fnResolveFieldPropMin(defn, valueMap);

  const getFieldMaxCounter = () => fnResolveFieldPropMax(defn, valueMap);

  const getFieldStepCounter = () => fnResolveFieldPropStepCounter(defn, valueMap);

  const getFieldDisableElevation = () => fnResolveFieldPropDisableElevation(defn, valueMap);

  const getFieldColor = () => fnResolveFieldPropColor(defn, valueMap);

  const getJustifyText = () => fnResolveFieldPropJustifyText(defn, valueMap);
  const getFieldDefaultValue = () => fnResolveFieldPropDefaultValue(defn, valueMap);
  const getFieldShowAsDropdown = () => fnResolveFieldPropShowAsDropdown(defn, valueMap);

  return {
    getFieldRequired,
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldShowAsCheckbox,
    getFieldDigitsAfterPeriod,
    getFieldIcon,
    getFieldMinDate,
    getFieldMaxDate,
    getFieldShowLabel,
    getFieldShowPreview,
    getFieldShowSize,
    getFieldBold,
    getFieldItalic,
    getFieldStrikeThrough,
    getFieldUnderline,
    getFieldTextPattern,
    getFieldLineCount,
    getFieldMaxSize,
    getFieldMinDateTime,
    getFieldMaxDateTime,
    getFieldTextSize,
    getFieldOpacity,
    getFieldMaxSlider,
    getFieldMinSlider,
    getFieldStepSlider,
    getFieldShowSeconds,
    getFieldMinCounter,
    getFieldMaxCounter,
    getFieldStepCounter,
    getFieldDisableElevation,
    getFieldColor,
    getJustifyText,
    getFieldDefaultValue,
    getFieldShowAsDropdown
  };

}

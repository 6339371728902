import {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdLayoutGrid} from "../../../../../api/meta/base/ApiPlus";
import {isGridId} from "../../../../../api/meta/base/ApiPlus";
import {DefnFieldButton} from "../../../../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioMapOfDtoOption} from "../../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutSpreadsheetId} from "../../../../../api/meta/base/dto/DefnStudioPickLayoutSpreadsheetId";
import {DefnStudioPickSpreadsheetId} from "../../../../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioSetOfFieldRefId} from "../../../../../api/meta/base/dto/DefnStudioSetOfFieldRefId";
import {StudioDtoLayoutOverlaySpreadsheet} from "../../../../../api/meta/base/dto/StudioDtoLayoutOverlaySpreadsheet";
import {StudioFieldRef} from "../../../../../api/meta/base/dto/StudioFieldRef";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {isSystemField} from "../../../../../base/plus/StudioFormPlus";
import {FormStore} from "../../../../../base/types/TypesForm";
import {FORMS_REF_OVERLAY_SPREADSHEET_LAYOUT} from "../../../../atom/assets/HelperTextStudio";
import {FORMS_FIELD_ONLY_LIST_CARD_LAYOUT} from "../../../../atom/assets/HelperTextStudio";
import {usePageCtx} from "../../../../ctx/CtxPage";
import DialogNewLayoutSpreadsheetCard from "../../../../dialog/DialogNewLayoutSpreadsheetCard";
import DialogNewLayoutSpreadsheetList from "../../../../dialog/DialogNewLayoutSpreadsheetList";
import {fieldGap5} from "../../base/TypesFormBuilder";
import {propKeyLookupFieldId} from "../../base/TypesFormBuilder";
import {propKeyForceOpenOnGridRowCreate} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyShowRefreshButtonOn} from "../../base/TypesFormBuilder";
import {propKeyShowRefreshInMenu} from "../../base/TypesFormBuilder";
import {propKeyFieldRefreshOn} from "../../base/TypesFormBuilder";
import {propKeyFieldKeySet} from "../../base/TypesFormBuilder";
import {propKeyCategoryFilterDisplayFieldId} from "../../base/TypesFormBuilder";
import {propKeyCopyFields} from "../../base/TypesFormBuilder";
import {propKeyCanCreateRefRecord} from "../../base/TypesFormBuilder";
import {propKeyForceOpenOnFormCreate} from "../../base/TypesFormBuilder";
import {propKeyEditableFieldIdSet} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyConditionVarId} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";
import {fieldSpreadsheet} from "../../base/TypesFormBuilder";
import {propKeyOverlayLayoutSpreadsheet} from "../../base/TypesFormBuilder";
import {propKeySpreadsheetLayout} from "../../base/TypesFormBuilder";

export function getDefnFieldRef(
  spreadsheetId?: MetaIdSpreadsheet,
  helperTextSpreadsheetFormName?: string,
  selectedRefFieldIdSet?: MetaIdField[],
  sourceFormId?: MetaIdForm,
  hideRefreshInMenu?: boolean,
  compositeId?: MetaIdComposite,
  categoryDisplayFields?: DefnStudioMapOfDtoOption,
  lookupFieldIdSet?: MetaIdField[]
)
{
  const systemFieldOptions = selectedRefFieldIdSet
    ? selectedRefFieldIdSet.reduce((previousValue, currentValue) =>
    {
      if(isSystemField(currentValue))
      {
        previousValue.map[currentValue] = {
          metaId: currentValue,
          value: currentValue
        };
        previousValue.keys.push(currentValue);
      }
      return previousValue;
    }, {
      map: {},
      keys: []
    } as DefnStudioMapOfDtoOption)
    : undefined;

  const isCopyFieldHasRowId = selectedRefFieldIdSet
    ? selectedRefFieldIdSet.includes("$RowId")
    : false;

  return {
    [fieldSpreadsheet]: {
      type: "pickSpreadsheetId",
      label: "Spreadsheet",
      name: fieldSpreadsheet,
      metaId: fieldSpreadsheet,
      disabled: true,
      helperTextVar: stringToDefnDtoText(helperTextSpreadsheetFormName
        ? `Spreadsheet form: ${helperTextSpreadsheetFormName}`
        : "")
    } as DefnStudioPickSpreadsheetId,

    ...getFieldGap(fieldGap1, "thick"),

    [propKeyForceOpenOnFormCreate]: {
      type: "bool",
      name: propKeyForceOpenOnFormCreate,
      metaId: propKeyForceOpenOnFormCreate,
      label: "Force open on form create",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    ...isGridId(compositeId) && {
      [propKeyForceOpenOnGridRowCreate]: {
        type: "bool",
        name: propKeyForceOpenOnGridRowCreate,
        metaId: propKeyForceOpenOnGridRowCreate,
        label: "Force open on grid row create",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    [propKeyCanCreateRefRecord]: {
      type: "bool",
      name: propKeyCanCreateRefRecord,
      metaId: propKeyCanCreateRefRecord,
      label: "Can create ref record",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap2, "thick"),

    [propKeyCopyFields]: {
      type: "studioSetOfFieldRefId",
      name: propKeyCopyFields,
      metaId: propKeyCopyFields,
      label: "Copy fields",
      spreadsheetId: spreadsheetId,
      showCompositeName: true
    } as DefnStudioSetOfFieldRefId,

    [propKeyEditableFieldIdSet]: {
      type: "studioSetOfFieldId",
      name: propKeyEditableFieldIdSet,
      metaId: propKeyEditableFieldIdSet,
      label: "Editable fields",
      showCompositeName: true,
      formId: sourceFormId,
      includeFieldIdSet: selectedRefFieldIdSet && selectedRefFieldIdSet.length > 1
        ? selectedRefFieldIdSet
        : []
    } as DefnStudioPickFieldId,

    [propKeyFieldKeySet]: {
      type: "studioSetOfFieldId",
      name: propKeyFieldKeySet,
      metaId: propKeyFieldKeySet,
      label: "Key fields",
      showCompositeName: true,
      formId: sourceFormId,
      includeFieldIdSet: selectedRefFieldIdSet && selectedRefFieldIdSet?.length > 0
        ? selectedRefFieldIdSet
        : [],
      includeOptionMap: systemFieldOptions
    } as DefnStudioPickFieldId,

    ...getFieldGap(fieldGap3, "thick"),

    [propKeyCategoryFilterDisplayFieldId]: {
      type: "pickFieldId",
      name: propKeyCategoryFilterDisplayFieldId,
      metaId: propKeyCategoryFilterDisplayFieldId,
      label: "Category filter display field",
      showCompositeName: true,
      includeOptionMap: categoryDisplayFields
    } as DefnStudioPickFieldId,

    [propKeyConditionVarId]: {
      type: "pickVarId",
      name: propKeyConditionVarId,
      metaId: propKeyConditionVarId,
      label: "Condition variable",
      showAsEdit: true,
      formId: sourceFormId,
      varKind: "condition"
    } as DefnStudioPickVarId,

    ...getFieldGap(fieldGap4, "thick"),

    [propKeyFieldRefreshOn]: {
      type: "enumRefreshOn",
      name: propKeyFieldRefreshOn,
      metaId: propKeyFieldRefreshOn,
      disabled: !isCopyFieldHasRowId,
      label: "Refresh on"
    } as DefnFieldPickEnum,

    ...!hideRefreshInMenu && {
      [propKeyShowRefreshInMenu]: {
        type: "bool",
        name: propKeyShowRefreshInMenu,
        metaId: propKeyShowRefreshInMenu,
        label: "Show refresh in menu",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...!hideRefreshInMenu && {
      [propKeyShowRefreshButtonOn]: {
        type: "studioSetOfFieldId",
        name: propKeyShowRefreshButtonOn,
        metaId: propKeyShowRefreshButtonOn,
        label: "Show refresh button on",
        showCompositeName: true,
        formId: sourceFormId,
        includeFieldIdSet: selectedRefFieldIdSet && selectedRefFieldIdSet.length > 1
          ? selectedRefFieldIdSet
          : []
      } as DefnStudioPickFieldId
    },

    ...getFieldGap(fieldGap5, "thick"),

    [propKeyLookupFieldId]: {
      type: "pickFieldId",
      metaId: propKeyLookupFieldId,
      name: propKeyLookupFieldId,
      label: "Lookup field",
      formId: sourceFormId,
      includeFieldIdSet: lookupFieldIdSet,
      helperText: "Only searchable and queryable fields are allowed"
    } as DefnStudioPickFieldId
  };
}

export function getDefnLayoutTabRef(
  spreadsheetId?: MetaIdSpreadsheet,
  isFieldRefOverlayLayoutDisabled?: boolean,
  isFieldRefOverlayLayoutFilled?: boolean
)
{
  return {
    [propKeySpreadsheetLayout]: {
      type: "pickLayoutSpreadsheetId",
      name: propKeySpreadsheetLayout,
      metaId: propKeySpreadsheetLayout,
      label: "Spreadsheet layout",
      spreadsheetId: spreadsheetId,
      filterLayoutKindSet: ["card", "list"],
      required: true,
      showAlias: true,
      helperTextVar: stringToDefnDtoText(FORMS_FIELD_ONLY_LIST_CARD_LAYOUT)
    } as DefnStudioPickLayoutSpreadsheetId,

    ...getFieldGap(fieldGap4, "thick"),

    [propKeyOverlayLayoutSpreadsheet]: {
      type: "button",
      name: propKeyOverlayLayoutSpreadsheet,
      metaId: propKeyOverlayLayoutSpreadsheet,
      label: "Overlay spreadsheet layout",
      disabled: isFieldRefOverlayLayoutDisabled,
      buttonVariantVar: "text",
      iconPositionVar: "end",
      justifyContent: "spaceBetween",
      buttonPositionVar: "flexCenter",
      iconVar: isFieldRefOverlayLayoutFilled
        ? "CheckBoxRounded"
        : "CheckBoxOutlineBlankRounded",
      helperTextVar: stringToDefnDtoText(FORMS_REF_OVERLAY_SPREADSHEET_LAYOUT)
    } as DefnFieldButton

  };
}

export function defnValueToStudioFieldRef(values: FieldValues, hideRefreshInMenu?: boolean): StudioFieldRef
{
  const refFieldChildren = fnFieldValueToRawValue("studioSetOfFieldRefId", values[propKeyCopyFields]) as MetaIdField[];
  const copyFieldMap = {} as Record<string, string>;
  if(refFieldChildren && Array.isArray(refFieldChildren))
  {
    refFieldChildren.forEach((refFieldChildId, index) =>
    {
      const newCopyFieldId = "copyField" + index;
      copyFieldMap[newCopyFieldId] = refFieldChildId;
    });
  }

  return {
    type: "ref",
    layoutSpreadsheetId: fnFieldValueToRawValue("pickLayoutSpreadsheetId", values[propKeySpreadsheetLayout]),
    forceOpenOnFormCreate: fnFieldValueToRawValue("bool", values[propKeyForceOpenOnFormCreate]),
    forceOpenOnGridRowCreate: fnFieldValueToRawValue("bool", values[propKeyForceOpenOnGridRowCreate]),
    canCreateRefRecord: fnFieldValueToRawValue("bool", values[propKeyCanCreateRefRecord]),
    copyFieldMap: copyFieldMap,
    editableFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[propKeyEditableFieldIdSet]),
    categoryFilterDisplayFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyCategoryFilterDisplayFieldId]),
    spreadsheetId: fnFieldValueToRawValue("pickSpreadsheetId", values[fieldSpreadsheet]),
    filterConditionVarId: fnFieldValueToRawValue("pickVarId", values[propKeyConditionVarId]),
    keyFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[propKeyFieldKeySet]),
    refreshOn: fnFieldValueToRawValue("enumRefreshOn", values[propKeyFieldRefreshOn]),
    showRefreshInMenu: !hideRefreshInMenu ? fnFieldValueToRawValue("bool", values[propKeyShowRefreshInMenu]) :
      undefined,
    showRefreshOnFieldIdSet: !hideRefreshInMenu ? fnFieldValueToRawValue("studioSetOfFieldId",
      values[propKeyShowRefreshButtonOn]
    ) : undefined,
    lookupFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyLookupFieldId])
  } as StudioFieldRef;
}

export function studioFieldRefToDefnValue(studioField: StudioFieldRef)
{
  return {
    [propKeyForceOpenOnFormCreate]: fnRawValueToFieldValue("bool", studioField.forceOpenOnFormCreate),
    [propKeyForceOpenOnGridRowCreate]: fnRawValueToFieldValue("bool", studioField.forceOpenOnGridRowCreate),
    [propKeyCanCreateRefRecord]: fnRawValueToFieldValue("bool", studioField.canCreateRefRecord),
    [propKeyCopyFields]: Object.values(studioField.copyFieldMap ?? {}),
    [propKeyEditableFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.editableFieldIdSet),
    [propKeySpreadsheetLayout]: fnRawValueToFieldValue("pickLayoutSpreadsheetId", studioField.layoutSpreadsheetId),
    [propKeyCategoryFilterDisplayFieldId]: fnRawValueToFieldValue("pickFieldId",
      studioField.categoryFilterDisplayFieldId
    ),
    [fieldSpreadsheet]: fnRawValueToFieldValue("pickSpreadsheetId", studioField.spreadsheetId),
    [propKeyConditionVarId]: fnRawValueToFieldValue("pickVarId", studioField.filterConditionVarId),
    [propKeyFieldKeySet]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.keyFieldIdSet),
    [propKeyFieldRefreshOn]: fnRawValueToFieldValue("enumRefreshOn", studioField.refreshOn),
    [propKeyShowRefreshInMenu]: fnRawValueToFieldValue("bool", studioField.showRefreshInMenu),
    [propKeyShowRefreshButtonOn]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.showRefreshOnFieldIdSet),
    [propKeyLookupFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.lookupFieldId)
  };
}

export function useRefOverlayLayoutSpreadsheet()
{
  const pageCtx = usePageCtx();

  return useCallback((
    kind: EnumDefnLayoutGridKind,
    formStore: FormStore,
    spreadsheetId: MetaIdSpreadsheet,
    metaIdForm?: MetaIdForm,
    oldValueSpreadsheet?: StudioDtoLayoutOverlaySpreadsheet,
    excludeFieldIdSet?: MetaIdField[],
    overRideCompositeIdSet?: MetaIdComposite[],
    cbSuccess?: (overlayLayoutSpreadsheet?: StudioDtoLayoutOverlaySpreadsheet) => void
  ) =>
  {
    switch(kind)
    {
      case "card":
        pageCtx.showDialog(
          <DialogNewLayoutSpreadsheetCard
            formStore={formStore}
            spreadsheetId={spreadsheetId}
            metaIdForm={metaIdForm}
            values={{
              item: oldValueSpreadsheet?.item,
              kind: "card",
              metaId: nextMetaIdLayoutGrid(),
              name: "card"
            }}
            onClickOk={(value) =>
            {
              cbSuccess && cbSuccess({
                item: value.item
              });
            }}
            isOverride={true}
            excludeFieldIdSet={excludeFieldIdSet}
            overRideCompositeIdSet={overRideCompositeIdSet}
          />
        );
        break;
      case "list":
        pageCtx.showDialog(
          <DialogNewLayoutSpreadsheetList
            formStore={formStore}
            spreadsheetId={spreadsheetId}
            metaIdForm={metaIdForm}
            values={{
              item: oldValueSpreadsheet?.item,
              kind: "list",
              metaId: nextMetaIdLayoutGrid(),
              name: "list"
            }}
            onClickOk={(value) =>
            {
              cbSuccess && cbSuccess({
                item: value.item
              });
            }}
            isOverride={true}
            excludeFieldIdSet={excludeFieldIdSet}
            overRideCompositeIdSet={overRideCompositeIdSet}
          />
        );
    }
  }, []);
}



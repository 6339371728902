import {Action} from "redux";
import {EnumMessageType} from "../../api/home/base/Types";
import {MsgMessageList} from "../../api/home/main/msg/MsgMessageList";
import {MsgMessageListJump} from "../../api/home/main/msg/MsgMessageListJump";
import {MsgMessageListOffset} from "../../api/home/main/msg/MsgMessageListOffset";
import {SigMessageList} from "../../api/home/main/sig/SigMessageList";
import {WsocMain} from "../../api/home/main/WsocMain";
import {newGuid} from "../../api/meta/base/NanoId";
import {ChatId} from "../../api/meta/base/Types";
import {EntId} from "../../api/meta/base/Types";
import {EnvSignal} from "../../api/nucleus/base/dto/EnvSignal";
import {store} from "../../Store";
import {fnCreateChatSlice} from "../slices/chat/SliceChat";
import {getChatNameFake} from "../slices/chat/SliceChat";
import {chatSetPageOffset} from "../slices/chat/SliceChatSharedActions";
import {chatInit} from "../slices/chat/SliceChatSharedActions";
import {chatSetAppending} from "../slices/chat/SliceChatSharedActions";
import {chatAppend} from "../slices/chat/SliceChatSharedActions";
import {chatPrepend} from "../slices/chat/SliceChatSharedActions";
import {chatSetPrepending} from "../slices/chat/SliceChatSharedActions";
import {chatLoad} from "../slices/chat/SliceChatSharedActions";
import {IBubbleHeader} from "../types/TypesBubble";
import {SelectChat} from "../types/TypesChat";
import {IChat} from "../types/TypesChat";
import {RPC_RETRY_DELAY} from "./ConstantsPlus";

export function getBubbleHeader(bubble: IBubbleHeader)
{
  const sep = " > ";
  const strs = [] as string[];
  if(bubble.headerTextLeft1)
  {
    strs.push(bubble.headerTextLeft1);
  }
  if(bubble.headerTextLeft2)
  {
    strs.push(bubble.headerTextLeft2);
  }
  if(bubble.headerTextLeft3)
  {
    strs.push(bubble.headerTextLeft3);
  }
  return strs.length > 0
    ? strs.join(sep)
    : undefined;
}

export const chatMenu = {
  "reply": "Reply",
  // "React to message",
  "download": "Download",
  "messageInfo": "Info",
  "forwardMessage": "Forward",
  "react": "React",
  "starMessage": "Star",
  "unStarMessage": "Unstar",
  "share": "Share",
  "copy": "Copy",
  "copyId": "Copy Id",
  "removeMessage": "Remove"
};

export function isRestrictReactionFormMsgType(messageType: EnumMessageType)
{
  switch(messageType)
  {
    case "spreadsheetRowDeleted":
    case "messageDeleted":
      return false;
    default:
      return true;
  }
}

export function createChatSlice(name: string)
{
  if(name === getChatNameFake())
  {
    throw new Error(`Chat name, ${name}, not allowed`);
  }
  else
  {
    return fnCreateChatSlice(name);
  }
}

export function dispatchChat(chat: IChat | string, action: Action<string>)
{
  action.type = action.type.replace(getChatNameFake(), typeof chat === "string" ? chat : chat.chatName);
  return store.dispatch(action);
}

export function loadChat(
  selectChat: SelectChat,
  entId: EntId,
  chatId: ChatId,
  pageSize: number,
  jumpOffset?: number,
  jumpVersion?: string,
  jumpMsgId?: string
)
{
  const loadedVersion = newGuid();
  const chatName = selectChat(store.getState()).chatName;
  Promise.resolve(dispatchChat(chatName, chatInit({
    entId: entId,
    chatId: chatId,
    loadedVersion: loadedVersion
  }))).then(() => doLoadChat(
    selectChat,
    entId,
    chatId,
    loadedVersion,
    pageSize,
    jumpOffset,
    jumpVersion,
    jumpMsgId
  ));
}

export function prependChat(selectChat: SelectChat, loadedVersion: string, pageSize: number)
{
  const chat = selectChat(store.getState());
  const chatName = chat.chatName;
  if(chat.loadedVersion !== loadedVersion)
  {
    return;
  }

  let offset = chat.bottomOffset - 1;
  if(offset <= chat.sigBottomOffset)
  {
    return dispatchChat(chatName, chatSetPrepending({
      mode: "skip",
      loadedVersion: loadedVersion
    }));
  }

  const entId = chat.sigEntId as EntId;
  const chatId = chat.sigChatId as ChatId;

  const msg = {
    chatId: chatId,
    pageSize: Math.floor(pageSize),
    offset: offset
  } as MsgMessageListOffset;

  dispatchChat(chatName, chatSetPrepending({
    mode: "busy",
    loadedVersion: loadedVersion
  }));
  WsocMain.messageListPrev(entId, msg, (envSig) =>
  {
    const sig = envSig.sig;
    if(sig)
    {
      dispatchChat(chatName,
        chatSetPageOffset({
          pageTopOffset: sig.pageTopOffset,
          pageBottomOffset: sig.pageBottomOffset
        })
      );
      if(sig.messageList.length > 0)
      {
        dispatchChat(chatName, chatPrepend({
          sig: sig,
          loadedVersion: loadedVersion
        }));
      }
      else
      {
        dispatchChat(chatName, chatSetPrepending({
          mode: "invoke",
          loadedVersion: loadedVersion
        }));
      }
    }
    else
    {
      setTimeout(() =>
      {
        dispatchChat(chatName, chatSetPrepending({
          mode: "invoke",
          loadedVersion: loadedVersion
        }));
      }, RPC_RETRY_DELAY);
    }
  });
}

export function appendChat(selectChat: SelectChat, loadedVersion: string, pageSize: number, isNewMessage?: boolean)
{
  const chat = selectChat(store.getState());
  const chatName = chat.chatName;
  if(chat.loadedVersion !== loadedVersion)
  {
    return;
  }

  let offset = chat.topOffset + 1;

  if(offset >= chat.sigTopOffset && !isNewMessage)
  {
    dispatchChat(chatName, chatSetAppending({
      mode: "skip",
      loadedVersion: loadedVersion
    }));
    return;
  }

  const entId = chat.sigEntId as EntId;
  const chatId = chat.sigChatId as ChatId;

  const msg = {
    chatId: chatId,
    pageSize: Math.floor(pageSize),
    offset: offset
  } as MsgMessageListOffset;

  dispatchChat(chatName, chatSetAppending({
    mode: "busy",
    loadedVersion: loadedVersion
  }));
  WsocMain.messageListNext(entId, msg, (envSig) =>
  {
    const sig = envSig.sig;
    if(sig)
    {
      if(sig.pageTopOffset === 0 && sig.pageTopOffset === 0 && sig.messageList.length === 0)
      {
        dispatchChat(chatName, chatSetAppending({
          mode: "skip",
          loadedVersion: loadedVersion
        }));
        return;
      }
      dispatchChat(chatName, chatSetPageOffset({
          pageTopOffset: sig.pageTopOffset,
          pageBottomOffset: sig.pageBottomOffset
        })
      );
      if(sig.messageList.length > 0)
      {
        dispatchChat(chatName, chatAppend({
          sig: sig,
          loadedVersion: loadedVersion
        }));
      }
      else
      {
        dispatchChat(chatName, chatSetAppending({
          mode: "invoke",
          loadedVersion: loadedVersion
        }));
      }
    }
    else
    {
      setTimeout(() =>
      {
        dispatchChat(chatName, chatSetAppending({
          mode: "invoke",
          loadedVersion: loadedVersion
        }));
      }, RPC_RETRY_DELAY);
    }
  });
}

function doLoadChat(
  selectChat: SelectChat,
  entId: EntId,
  chatId: ChatId,
  loadedVersion: string,
  pageSize: number,
  jumpOffset?: number,
  jumpVersion?: string,
  jumpMsgId?: string
)
{
  const rootState = store.getState();
  const chat = selectChat(rootState);
  const chatName = chat.chatName;
  if(entId === chat.sigEntId && chatId === chat.sigChatId && loadedVersion === chat.loadedVersion)
  {
    const sigAcceptor = (envSig: EnvSignal<SigMessageList>) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        dispatchChat(chatName, chatLoad({
          sig: sig,
          loadedVersion: loadedVersion,
          jumpOffset: jumpOffset,
          jumpVersion: jumpVersion,
          jumpMsgId: jumpMsgId
        }));

        if(sig.messageList.length <= 0)
        {
          const rootState = store.getState();
          if(selectChat(rootState).bottomOffset > 0)
          {
            prependChat(selectChat, loadedVersion, pageSize);
          }
        }
      }
      else
      {
        setTimeout(() =>
        {
          doLoadChat(
            selectChat,
            entId,
            chatId,
            loadedVersion,
            pageSize,
            jumpOffset,
            jumpVersion,
            jumpMsgId
          );
        }, RPC_RETRY_DELAY);
      }
    };

    if(jumpOffset || jumpMsgId)
    {
      const msg = {
        chatId: chatId,
        pageSize: pageSize,
        offset: jumpOffset,
        messageId: jumpMsgId
      } as MsgMessageListJump;

      WsocMain.messageListJump(entId, msg, sigAcceptor);
    }
    else
    {
      const msg = {
        chatId: chatId,
        pageSize: pageSize
      } as MsgMessageList;

      WsocMain.messageListGet(entId, msg, sigAcceptor);
    }
  }
}

import {isEmpty} from "lodash";
import {useState} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import React from "react";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnLayoutGridLocmap} from "../../../../api/meta/base/dto/DefnLayoutGridLocmap";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getLatLngFromGeoPoint} from "../../../../base/plus/LocationPlus";
import {getMarkerValueFromValueMap} from "../../../../base/plus/MapLayoutPlus";
import {IMapRef} from "../../../../base/types/TypeMap";
import {ILocationMarkers} from "../../../../base/types/TypeMap";
import helperTextData from "../../../atom/assets/PlaceholderTextHome.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import RawGoogleMap from "../../../googleMap/RawGoogleMap";
import {useFormCtx} from "../base/CtxForm";
import {IFieldGridRawProps} from "../composite/FieldGrid";

export default function FieldRawFieldGridMap(props: IFieldGridRawProps)
{
  const formCtx = useFormCtx();

  const gridMapLayout = props.layout as DefnLayoutGridLocmap;
  const defnForm = props.defnForm;
  const fieldValue = props.fieldValue;
  const compMap = defnForm.compMap;
  const defnGrid = props.defnGrid;
  const gridId = defnGrid.metaId;

  const getOnClick = formCtx.getOnClick();

  const cbMapRef = useRef<IMapRef>({} as IMapRef);

  const [markersValue, setMarkerValues] = useState<ILocationMarkers | undefined>();

  useEffect(() =>
  {
    if(!isEmpty(fieldValue))
    {
      const _markersValue = getMarkersValue(gridMapLayout, fieldValue, compMap);
      setMarkerValues(_markersValue);
    }
  }, [gridMapLayout, fieldValue, compMap]);

  if(isEmpty(fieldValue) || !gridMapLayout)
  {
    return (<LayoutFlexCol
        alignItems={"center"}
        justifyContent={"center"}
      >
        <RawNothingHere helperTextData={helperTextData.nothingToShow} />
      </LayoutFlexCol>
    );
  }

  if(isEmpty(markersValue))
  {
    return (
      <LayoutFlexCol
        alignItems={"center"}
        justifyContent={"center"}
      >
        <RawNothingHere helperTextData={helperTextData.loadingData} />
      </LayoutFlexCol>
    );
  }

  return (
    <RawGoogleMap
      cbRef={cbMapRef.current}
      initialDataMap={markersValue}
      onClickMarker={(markerId, eventTarget) =>
      {
        getOnClick && getOnClick(gridId, "mapMarker", fieldValue?.map[markerId], eventTarget as Element);
      }}
    />
  );

}

function getMarkersValue(
  layoutGrid: DefnLayoutGridLocmap,
  fieldValueGrid: FieldValueGrid,
  compMap: Record<MetaIdField, DefnComp>
): ILocationMarkers | undefined
{
  const groupByFieldId = layoutGrid?.groupByFieldId;
  const toolTipFieldId = layoutGrid.toolTipFieldId;

  const markerSet = {} as ILocationMarkers;

  if(groupByFieldId)
  {
    Object.keys(fieldValueGrid.map).forEach((key) =>
    {
      const value = fieldValueGrid.map[key];
      const valueMap = fieldValueGrid.map[key].valueMap;
      const rowId = value.rowId;

      const {
        locationFieldValue,
        groupByFieldValue,
        colorFieldValue,
        toolTipFieldValue,
        shapeFieldValue,
        strokeFieldValue
      } = getMarkerValueFromValueMap(compMap, valueMap, layoutGrid, toolTipFieldId);

      const locationAddress = locationFieldValue?.value.address;

      const coordinates = (locationFieldValue?.value.geoPoint)
        ? getLatLngFromGeoPoint(locationFieldValue?.value.geoPoint)
        : undefined;

      if(coordinates)
      {
        if(!markerSet[groupByFieldValue])
        {
          markerSet[groupByFieldValue] = [
            {
              markerId: rowId,
              markerColor: colorFieldValue?.value,
              markerToolTip: toolTipFieldValue,
              markerShape: shapeFieldValue,
              polyLineColor: colorFieldValue?.value,
              polyLineStroke: strokeFieldValue,
              locations:
                {
                  ...coordinates,
                  description: locationAddress
                }
            }
          ];
        }
        else
        {
          markerSet[groupByFieldValue] = [
            ...markerSet[groupByFieldValue],
            {
              markerId: rowId,
              markerColor: colorFieldValue?.value,
              markerToolTip: toolTipFieldValue,
              markerShape: shapeFieldValue,
              polyLineColor: colorFieldValue?.value,
              polyLineStroke: strokeFieldValue,
              locations:
                {
                  ...coordinates,
                  description: locationAddress
                }
            }
          ];
        }
      }

    });
  }
  else
  {
    (fieldValueGrid.keys).forEach((key) =>
    {
      const value = fieldValueGrid.map[key];
      const valueMap = fieldValueGrid.map[key].valueMap;
      const rowId = value.rowId;

      const {
        locationFieldValue,
        colorFieldValue,
        toolTipFieldValue,
        shapeFieldValue,
        strokeFieldValue
      } = getMarkerValueFromValueMap(compMap, valueMap, layoutGrid, toolTipFieldId);

      const locationAddress = locationFieldValue?.value.address;

      const coordinates = locationFieldValue?.value.geoPoint
        ? getLatLngFromGeoPoint(locationFieldValue?.value.geoPoint)
        : undefined;

      if(coordinates)
      {
        markerSet[rowId] = [
          {
            markerId: rowId,
            markerColor: colorFieldValue?.value,
            markerShape: shapeFieldValue,
            markerToolTip: toolTipFieldValue,
            polyLineStroke: strokeFieldValue,
            locations:
              {
                ...coordinates,
                description: locationAddress
              }
          }
        ];
      }
    });
  }

  return markerSet;
}



import {createSelector} from "@reduxjs/toolkit";
import {isEmpty} from "lodash";
import {cloneDeep} from "lodash";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../api/meta/base/dto/DefnGrid";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {FieldDtoTreeNode} from "../../api/meta/base/dto/FieldDtoTreeNode";
import {StudioComposite} from "../../api/meta/base/dto/StudioComposite";
import {StudioCompositeMap} from "../../api/meta/base/dto/StudioCompositeMap";
import {StudioEntAction} from "../../api/meta/base/dto/StudioEntAction";
import {StudioEntActionReport} from "../../api/meta/base/dto/StudioEntActionReport";
import {StudioEntActionRowInsert} from "../../api/meta/base/dto/StudioEntActionRowInsert";
import {StudioEntActionSpreadsheetEditor} from "../../api/meta/base/dto/StudioEntActionSpreadsheetEditor";
import {StudioEntDeeplink} from "../../api/meta/base/dto/StudioEntDeeplink";
import {StudioEntGroup} from "../../api/meta/base/dto/StudioEntGroup";
import {StudioEntPlugin} from "../../api/meta/base/dto/StudioEntPlugin";
import {StudioEntPluginApi} from "../../api/meta/base/dto/StudioEntPluginApi";
import {StudioEntReportComposite} from "../../api/meta/base/dto/StudioEntReportComposite";
import {StudioEntReportMapper} from "../../api/meta/base/dto/StudioEntReportMapper";
import {StudioEntReportPlugin} from "../../api/meta/base/dto/StudioEntReportPlugin";
import {StudioEntReportQuery} from "../../api/meta/base/dto/StudioEntReportQuery";
import {StudioEntReportSpreadsheet} from "../../api/meta/base/dto/StudioEntReportSpreadsheet";
import {StudioEntRole} from "../../api/meta/base/dto/StudioEntRole";
import {StudioEntRoleMap} from "../../api/meta/base/dto/StudioEntRoleMap";
import {StudioEntSpreadsheet} from "../../api/meta/base/dto/StudioEntSpreadsheet";
import {StudioField} from "../../api/meta/base/dto/StudioField";
import {StudioFieldMap} from "../../api/meta/base/dto/StudioFieldMap";
import {StudioFieldPickText} from "../../api/meta/base/dto/StudioFieldPickText";
import {StudioFieldPickTree} from "../../api/meta/base/dto/StudioFieldPickTree";
import {StudioForm} from "../../api/meta/base/dto/StudioForm";
import {StudioFormMap} from "../../api/meta/base/dto/StudioFormMap";
import {StudioGrid} from "../../api/meta/base/dto/StudioGrid";
import {StudioMapOfOption} from "../../api/meta/base/dto/StudioMapOfOption";
import {StudioModuleMap} from "../../api/meta/base/dto/StudioModuleMap";
import {StudioModuleSelection} from "../../api/meta/base/dto/StudioModuleSelection";
import {StudioPlugin} from "../../api/meta/base/dto/StudioPlugin";
import {StudioPluginBundle} from "../../api/meta/base/dto/StudioPluginBundle";
import {StudioSection} from "../../api/meta/base/dto/StudioSection";
import {StudioVar} from "../../api/meta/base/dto/StudioVar";
import {StudioVarBoolean} from "../../api/meta/base/dto/StudioVarBoolean";
import {StudioVarButtonVariant} from "../../api/meta/base/dto/StudioVarButtonVariant";
import {StudioVarColor} from "../../api/meta/base/dto/StudioVarColor";
import {StudioVarCondition} from "../../api/meta/base/dto/StudioVarCondition";
import {StudioVarCurrency} from "../../api/meta/base/dto/StudioVarCurrency";
import {StudioVarDate} from "../../api/meta/base/dto/StudioVarDate";
import {StudioVarDateTime} from "../../api/meta/base/dto/StudioVarDateTime";
import {StudioVarDay} from "../../api/meta/base/dto/StudioVarDay";
import {StudioVarDecimal} from "../../api/meta/base/dto/StudioVarDecimal";
import {StudioVarDeviceSize} from "../../api/meta/base/dto/StudioVarDeviceSize";
import {StudioVarDeviceType} from "../../api/meta/base/dto/StudioVarDeviceType";
import {StudioVarDirection} from "../../api/meta/base/dto/StudioVarDirection";
import {StudioVarDividerKind} from "../../api/meta/base/dto/StudioVarDividerKind";
import {StudioVarDocument} from "../../api/meta/base/dto/StudioVarDocument";
import {StudioVarDuration} from "../../api/meta/base/dto/StudioVarDuration";
import {StudioVarEmail} from "../../api/meta/base/dto/StudioVarEmail";
import {StudioVarHtml} from "../../api/meta/base/dto/StudioVarHtml";
import {StudioVarHyperlink} from "../../api/meta/base/dto/StudioVarHyperlink";
import {StudioVarImageCorner} from "../../api/meta/base/dto/StudioVarImageCorner";
import {StudioVarLanguage} from "../../api/meta/base/dto/StudioVarLanguage";
import {StudioVarMap} from "../../api/meta/base/dto/StudioVarMap";
import {StudioVarMapping} from "../../api/meta/base/dto/StudioVarMapping";
import {StudioVarMapPinShape} from "../../api/meta/base/dto/StudioVarMapPinShape";
import {StudioVarMobileNumber} from "../../api/meta/base/dto/StudioVarMobileNumber";
import {StudioVarNumber} from "../../api/meta/base/dto/StudioVarNumber";
import {StudioVarParagraph} from "../../api/meta/base/dto/StudioVarParagraph";
import {StudioVarPlacement} from "../../api/meta/base/dto/StudioVarPlacement";
import {StudioVarRatingKind} from "../../api/meta/base/dto/StudioVarRatingKind";
import {StudioVarSequence} from "../../api/meta/base/dto/StudioVarSequence";
import {StudioVarSetOfText} from "../../api/meta/base/dto/StudioVarSetOfText";
import {StudioVarStroke} from "../../api/meta/base/dto/StudioVarStroke";
import {StudioVarSymbol} from "../../api/meta/base/dto/StudioVarSymbol";
import {StudioVarText} from "../../api/meta/base/dto/StudioVarText";
import {StudioVarTextSize} from "../../api/meta/base/dto/StudioVarTextSize";
import {StudioVarTime} from "../../api/meta/base/dto/StudioVarTime";
import {StudioVarTimeZone} from "../../api/meta/base/dto/StudioVarTimeZone";
import {StudioVarTree} from "../../api/meta/base/dto/StudioVarTree";
import {StudioVarUserSetting} from "../../api/meta/base/dto/StudioVarUserSetting";
import {ValidationResult} from "../../api/meta/base/dto/ValidationResult";
import {SystemRoleSet} from "../../api/meta/base/StudioSetsFieldType";
import {EnumDefnForms} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaIdDeeplink} from "../../api/meta/base/Types";
import {MetaIdOption} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {MetaIdGroup} from "../../api/meta/base/Types";
import {MetaIdAction} from "../../api/meta/base/Types";
import {MetaIdReport} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {PluginApiId} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {EnumArrayDefnForms} from "../../api/meta/base/Types";
import {EntId, EnumDefnAdminDoNotOptionEnt, MetaId, MetaIdRole, PluginBundleId, PluginId} from "../../api/meta/base/Types";
import {getValidationErrorMessage} from "../../api/nucleus/base/Protocol";
import {SigEntAdminCaller} from "../../api/studio/studioMain/sig/SigEntAdminCaller";
import {SigStudioEnt} from "../../api/studio/studioMain/sig/SigStudioEnt";
import {TypeProductionUserFilterType} from "../../cache/controlPanel/TypeCacheControlPanel";
import {TypeEntSysFormMap} from "../../cache/studio/ent/TypesCacheStudioEnt";
import {ICacheStudioEntState} from "../../cache/studio/ent/TypesCacheStudioEnt";
import {isSystemForm} from "../../routes/studio/ent/deploy/plugins/StudioEntDeployPluginPlus";
import {store} from "../../Store";
import {RootState} from "../../Store";
import {IListItemsById, TypeListItemId} from "../types/list/TypesList";
import {IListItemAPSA} from "../types/list/TypesListAPSA";
import {FormStore} from "../types/TypesForm";
import {IFormFieldError} from "../types/TypesForm";
import {ILineSecondary} from "../types/TypesGlobal";
import {ILinePrimary} from "../types/TypesGlobal";
import {EnumListItemDirection, EnumStudioSearchPathKeys, IStudioJumpPath} from "../types/TypesStudio";
import {getResolvedConditionVar} from "./ArgBinderPlus";
import {STR_MODULES} from "./ConstantsPlus";
import {getDateFromString} from "./DatePlus";
import {formatDate} from "./DatePlus";
import {getCombinedFieldId} from "./FormPlus";
import {loopDefnForm} from "./FormPlus";
import {SelectList} from "./ListPlus";
import {toLabel} from "./StringPlus";
import {loopStudioForm} from "./StudioFormPlus";

export const formSchedulerTrigger: EnumDefnForms = "$FormSchedulerTrigger";
export const formPaymentReceipt: EnumDefnForms = "$FormRazorpayPaymentReceipt";
export const formNeomeComment: EnumDefnForms = "$FormNeomeComment";
export const formNeomeLocation: EnumDefnForms = "$FormNeomeLocation";

const arrayToRole = (metaId: string, roleName: string) =>
{
  return {
    metaId: metaId,
    details: {
      name: roleName
    }
  } as StudioEntRole;
};

export function insertSystemRolesStudio(roleMap?: StudioEntRoleMap): StudioEntRoleMap
{
  const systemRoleMap = SystemRoleSet.reduce((previousValue, roleId) =>
  {
    previousValue.keys.push(roleId);
    previousValue.map[roleId] = arrayToRole(roleId, roleId);
    return previousValue;
  }, {
    keys: [] as MetaIdRole[],
    map: {} as Record<MetaIdRole, StudioEntRole>
  } as StudioEntRoleMap);

  if(!roleMap)
  {
    return systemRoleMap;
  }

  return {
    ...roleMap,
    keys: [...new Set([...roleMap.keys, ...systemRoleMap.keys])],
    map: {...roleMap.map, ...systemRoleMap.map}
  } as StudioEntRoleMap;
}

const arrayToForm = (metaId: string, formName: string) =>
{
  return {
    metaId: metaId,
    details: {
      name: formName
    }
  } as StudioForm;
};

export function insertSystemFormsStudioForEnt(entSysFormMap?: TypeEntSysFormMap): StudioFormMap
{
  const studioFormMap = {
    keys: [],
    map: {}
  } as StudioFormMap;

  if(entSysFormMap)
  {
    Object.keys(entSysFormMap).forEach(metaIdForm =>
    {
      const defnForm = entSysFormMap[metaIdForm] as DefnForm;

      const studioForm = convertDefnFormToStudioForm(defnForm);

      studioFormMap.keys.push(studioForm.metaId);
      studioFormMap.map[studioForm.metaId] = studioForm;
    });
  }

  return studioFormMap;
}

function convertDefnFormToStudioForm(defnForm: DefnForm): StudioForm
{
  const studioForm = {
    metaId: defnForm.metaId,
    details: {
      name: defnForm.name,
      label: defnForm.label
    }
  } as StudioForm;

  const compMap = {
    keys: [],
    map: {}
  } as StudioCompositeMap;

  loopDefnForm(defnForm, (_parent, comp) =>
  {
    const parent = _parent as DefnSection | DefnGrid;
    const parentId = parent.metaId;
    const parentType = parent.type;

    let fieldMap = {
      keys: [],
      map: {}
    } as StudioFieldMap;

    if(parentType === "section" || parentType === "grid")
    {
      if(!compMap.map[parentId])
      {
        compMap.keys.push(parentId);
      }
      else
      {
        fieldMap = compMap.map[parentId].fieldMap;
      }

      const compId = comp.metaId;

      fieldMap.keys.push(compId);
      fieldMap.map[compId] = {
        metaId: compId,
        type: comp.type,
        details: {
          name: comp.name,
          label: comp.label
        }
      } as StudioField;

      compMap.map[parentId] = {
        metaId: parentId,
        type: parentType,
        details: {
          name: parent.name,
          label: parent.label
        },
        fieldMap: fieldMap
      } as StudioSection | StudioGrid;
    }
  });

  studioForm.compositeMap = compMap;

  return studioForm;
}

export function insertSystemFormsStudioForPlugin(formMap?: StudioFormMap): StudioFormMap
{
  const systemFormMap = EnumArrayDefnForms.reduce((previousValue, formId) =>
  {
    previousValue.keys.push(formId);
    previousValue.map[formId] = arrayToForm(formId, formId);
    return previousValue;
  }, {
    keys: [] as MetaId[],
    map: {} as Record<MetaId, StudioForm>
  } as StudioFormMap);

  if(!formMap)
  {
    return systemFormMap;
  }
  return {
    ...formMap,
    keys: [...new Set([...formMap.keys, ...systemFormMap.keys])],
    map: {...formMap.map, ...systemFormMap.map}
  } as StudioFormMap;
}

export function moveListItem(metaId: MetaId, keys: MetaId[], moveDirection: EnumListItemDirection)
{
  const index = keys.findIndex(key => key === metaId);
  if(index === 0 && (moveDirection === "up" || moveDirection === "top"))
  {
    return;
  }

  if(index === keys.length - 1 && (moveDirection === "down" || moveDirection === "bottom"))
  {
    return;
  }

  const temp = keys[index];
  switch(moveDirection)
  {
    case "down":
      keys[index] = keys[index + 1];
      keys[index + 1] = temp;
      return;
    case "up":
      keys[index] = keys[index - 1];
      keys[index - 1] = temp;
      return;
    case "top":
      keys.splice(index, 1);
      keys.unshift(temp);
      return;
    case "bottom":
      keys.splice(index, 1);
      keys.push(temp);
      return;
  }
}

export function moveListItemIndex(keys: MetaId[], startIndex: number, endIndex: number)
{
  if(startIndex === endIndex)
  {
    return;
  }

  if(startIndex < 0 || startIndex >= keys.length || endIndex < 0 || endIndex >= keys.length)
  {
    return;
  }

  const key = keys.splice(startIndex, 1)[0];
  keys.splice(endIndex, 0, key);
}

export function getVariablesUiItemsById(
  varMap: StudioVarMap,
  isPluginVariable?: boolean,
  readOnly?: boolean,
  fnResolver?: IResolveFuncs
)
{
  const uiItemIds: TypeListItemId[] = [];
  const uiItemsById = {} as IListItemsById;

  const map = varMap.map;
  const keys = varMap.keys;

  keys.forEach((variableId) =>
  {
    const variable = map[variableId];

    if(!variable)
    {
      return;
    }

    uiItemIds.push(variableId);

    uiItemsById[variableId] = {
      type: "ps",
      primary: getVariablePrimary(variable, isPluginVariable),
      secondary: getVariableSecondary(variable, isPluginVariable, fnResolver),
      hideMenu: readOnly ? true : isPluginVariable === true,
      tooltip: variable.details.description
        ? {
          secondary: variable.details.description
        }
        : undefined
    } as IListItemAPSA;
  });

  return {
    uiItemIds,
    uiItemsById
  };
}

function getVariablePrimary(variable: StudioVar, isPluginVariable?: boolean)
{
  let primary = {} as ILinePrimary;
  primary.text = variable.details.name;
  primary.caption = {
    text: variable.kind && toLabel(variable.kind),
    ignoreSelection: true
  };

  if(!isPluginVariable)
  {
    if(variable.deploy === "requiredOnDeploy")
    {
      primary.color = "warningLight";
    }
  }

  return primary;
}

function getVariableSecondary(variable: StudioVar, isPluginVariable?: boolean, fnResolver?: IResolveFuncs)
{
  let secondary = {} as ILineSecondary;
  secondary.text = resolveStudioVarValue(variable, fnResolver) || undefined;

  if(isPluginVariable)
  {
    secondary.color = "textDisabled";
  }
  else
  {
    if(variable.deploy === "requiredOnDeploy")
    {
      secondary.color = "warningLight";
    }
    else if(secondary.text)
    {
      secondary.color = undefined;
    }
    else
    {
      secondary.text = "No value";
      secondary.color = "textDisabled";
    }
  }

  return secondary;
}

export function selectCacheStudio(state: RootState)
{
  return state.cache.studio;
}

//region enterprise
export function selectCacheStudioEntValidationResult(state: RootState, entId: EntId): ValidationResult | undefined
{
  return state.cache.studio.ent.entMap[entId]?.validationResult;
}

export function selectCacheStudioEnt(state: RootState, entId: EntId): SigStudioEnt | undefined
{
  return selectCacheStudio(state).ent.entMap[entId];
}

export function selectCacheStudioEntState(state: RootState, entId: EntId): ICacheStudioEntState | undefined
{
  return state.cache.studio.ent.entStateMap[entId];
}

export function selectEntModules(state: RootState, entId: EntId): StudioModuleSelection | undefined
{
  return state.cache.studio.ent.selectedModuleMap[entId];
}

export function selectEntAdminCaller(state: RootState, entId: EntId): SigEntAdminCaller | undefined
{
  return selectCacheStudio(state).ent.entAdminCallerMap[entId];
}

export function selectEntModulesFromDetail(state: RootState, entId: EntId): StudioModuleMap | undefined
{
  return selectCacheStudioEnt(state, entId)?.ent.moduleMap;
}

export const selectStudioEntFormStore = createSelector([
    selectCacheStudioEnt,
    (state: RootState, entId: EntId) => state.cache.studio.ent.entStateMap[entId]?.dirtyVersion,
    state => state.cache.studio.ent.entSysFormMap,
    selectEntModulesFromDetail
  ],
  (sigStudioEnt, dirtyVersion, entSysFormMap, moduleMap) =>
  {
    return {
      ...sigStudioEnt?.ent,
      deployPaymentProviderMap: sigStudioEnt?.ent?.deployPaymentProviderMap,
      formMap: sigStudioEnt?.ent?.formMap,
      validationResult: sigStudioEnt?.validationResult,
      moduleMap: moduleMap,
      version: dirtyVersion,
      sysFormMap: insertSystemFormsStudioForEnt(entSysFormMap)
    } as FormStore;
  }
);

export function validateAdminEditLockPermission(
  state: RootState,
  entId: EntId,
  tabName: EnumDefnAdminDoNotOptionEnt,
  parentTabName?: EnumDefnAdminDoNotOptionEnt)
{
  const hasEditLock = selectEntAdminCaller(state, entId)?.hasLock;
  if(hasEditLock)
  {
    return validateAdminDoNotEditPermission(state, entId, tabName, parentTabName);
  }
  else
  {
    return false;
  }
}

export function validateAdminDoNotEditPermission(
  state: RootState,
  entId: EntId,
  tabName: EnumDefnAdminDoNotOptionEnt,
  parentTabName?: EnumDefnAdminDoNotOptionEnt
)
{
  const callerDoNotEditOptionSet: EnumDefnAdminDoNotOptionEnt[] =
    selectEntAdminCaller(state, entId)?.doNotEditOptionSet ?? [];

  return !(callerDoNotEditOptionSet.includes(tabName as EnumDefnAdminDoNotOptionEnt)
    || callerDoNotEditOptionSet.includes(parentTabName as EnumDefnAdminDoNotOptionEnt));
}

export function validateAdminDoNotShowPermission(
  state: RootState,
  entId: EntId,
  tabName: EnumDefnAdminDoNotOptionEnt,
  parentTabName?: EnumDefnAdminDoNotOptionEnt)
{
  const callerDoNotShowOptionSet: EnumDefnAdminDoNotOptionEnt[] =
    selectEntAdminCaller(state, entId)?.doNotShowOptionSet ?? [];

  return !(callerDoNotShowOptionSet.includes(tabName as EnumDefnAdminDoNotOptionEnt)
    || callerDoNotShowOptionSet.includes(parentTabName as EnumDefnAdminDoNotOptionEnt));
}

export function hasEditLockEnt(state: RootState, entId: EntId)
{
  const adminId = state.cache.app.caller.entAdminIdMap[entId];
  const sigAdminEditLockDetail = selectCacheStudio(state)?.ent.entEditLockDetailMap[entId];
  if(adminId && sigAdminEditLockDetail)
  {
    return sigAdminEditLockDetail.adminId === adminId;
  }
}

//endregion

//region plugin
export function selectPluginModulesFromDetail(state: RootState, pluginBundleId: PluginBundleId, pluginId: PluginId)
{
  const deployedPluginMap = selectCacheStudioPluginBundle(state, pluginBundleId)?.deployMap;

  if(deployedPluginMap?.keys && deployedPluginMap?.keys.length > 0 && deployedPluginMap?.keys.includes(pluginId))
  {
    return deployedPluginMap.map[pluginId]?.moduleMap;
  }
  else
  {
    return selectCacheStudioPluginBundle(state,
      pluginBundleId
    )?.draft?.studioPlugin?.moduleMap;
  }
}

export function selectPluginModules(state: RootState, pluginBundleId: PluginBundleId, pluginId: PluginId)
{
  const selectedModulesMap = state.cache.studio.plugin.selectedModulesMap;
  const pluginBundleFilterMap = selectedModulesMap
    ? selectedModulesMap[pluginBundleId]
    : undefined;

  return pluginBundleFilterMap
    ? pluginBundleFilterMap[pluginId]
    : undefined;
}

export function selectCacheStudioPluginValidationResult(
  state: RootState,
  pluginBundleId: PluginBundleId): ValidationResult | undefined
{
  return state.cache.studio.plugin.pluginMap[pluginBundleId]?.validationResult;
}

export function selectCacheStudioPluginBundle(
  state: RootState,
  pluginBundleId: PluginBundleId): StudioPluginBundle | undefined
{
  return state.cache.studio.plugin.pluginMap[pluginBundleId]?.studioPluginBundle;
}

export function selectCacheStudioPluginSelectedVersion(
  state: RootState,
  pluginBundleId: PluginBundleId,
  pluginId: PluginId): StudioPlugin | undefined
{
  const deployedVersion = selectCacheStudioPluginBundle(state, pluginBundleId)?.deployMap?.map[pluginId];
  const draftVersion = selectCacheStudioPluginBundle(state, pluginBundleId)?.draft?.studioPlugin;

  return (deployedVersion || draftVersion) as StudioPlugin | undefined;
}

export function selectPluginFormStore(
  state: RootState,
  pluginBundleId: PluginBundleId,
  pluginId: PluginId)
{
  const dirtyVersion = selectCacheStudio(state).plugin.pluginStateMap[pluginBundleId]?.dirtyVersion;
  const selectedPluginVersion = selectCacheStudioPluginSelectedVersion(state, pluginBundleId, pluginId);
  const validationResult = selectCacheStudioPluginValidationResult(state, pluginBundleId);

  return {
    ...selectedPluginVersion,
    formMap: insertSystemFormsStudioForPlugin(selectedPluginVersion?.formMap),
    varMap: selectedPluginVersion?.varMap,
    moduleMap: selectedPluginVersion?.moduleMap,
    validationResult: validationResult,
    version: dirtyVersion
  } as FormStore;
}

export function hasEditLockPlugin(state: RootState, pluginBundleId: PluginBundleId)
{
  const adminId = state.cache.app.caller.pluginAdminIdMap[pluginBundleId];
  const sigAdminEditLockDetail = selectCacheStudio(state)?.plugin.pluginEditLockDetailMap[pluginBundleId];
  if(adminId && sigAdminEditLockDetail)
  {
    return sigAdminEditLockDetail.adminId === adminId;
  }
}

//endregion

export function getFormFieldsErrorList(
  sectionName: EnumStudioSearchPathKeys,
  elementId?: MetaId,
  validationResult?: ValidationResult,
  combinationLength?: number   // this will be used to combine errorKeys eg: 3 means item/field/text
)
{
  const errorList = [] as IFormFieldError[];
  if(validationResult?.errorMap)
  {
    Object.entries(validationResult.errorMap).forEach(([key, value]) =>
    {
      const errMsg = getValidationErrorMessage(cloneDeep(value));
      const errMsgChild = [] as string[];
      if(value.children)
      {
        Object.entries(value.children).forEach(([_, value]) =>
        {
          errMsgChild.push(getValidationErrorMessage(cloneDeep(value)));
        });
      }
      let steps = getJumpStepsFromPath(key);
      const stepIndex = steps.findIndex(step => step.step ===
        (getJumpStepFromStudioEntStep(sectionName) || sectionName));
      if(stepIndex >= 0)
      {
        const step = steps[stepIndex];
        if(elementId)
        {
          if(step.itemId !== elementId)
          {
            steps = [];
          }
          else
          {
            steps = steps.slice(stepIndex + 1);
          }
        }
        else if(!elementId)
        {
          steps = steps.slice(stepIndex);
        }

        steps.forEach((_step) =>
        {
          if(_step?.itemId)
          {
            errorList.push({
              key: _step.step,
              errorOption: {
                type: "error",
                message: errMsg
              },
              errorChildrenOption: errMsgChild.map((msg) => ({
                type: "error",
                message: msg
              }))
            } as IFormFieldError);
          }

          errorList.push({
            key: _step?.itemId ?? _step.step,
            errorOption: {
              type: "error",
              message: errMsg
            },
            errorChildrenOption: errMsgChild.map((msg) => ({
              type: "error",
              message: msg
            }))
          } as IFormFieldError);
        });
      }
    });
  }
  return prepareErrorList(errorList, combinationLength);
}

function prepareErrorList(errorList: IFormFieldError[], combinationLength: number = 3)
{
  const newErrorList = [] as IFormFieldError[];

  let i = combinationLength;
  const len = errorList.length;

  while(i > 1 && i <= len)
  {
    getGroupedErrorList(errorList, i, newErrorList);
    i--;
  }
  return [...newErrorList, ...errorList];

}

function getGroupedErrorList(errorList: IFormFieldError[], len: number, newErrorList: IFormFieldError[])
{

  let start = 0;
  while(start + len <= errorList.length)
  {
    let end = start + len;
    const _errorList = [] as IFormFieldError[];
    for(let j = start; j < end; j++)
    {
      const errorItem = errorList[j];
      _errorList.push(errorItem);
    }
    const errorItem = combineErrorItem(_errorList);
    if(errorItem)
    {
      newErrorList.push(errorItem);
    }
    start++;
  }

}

function combineErrorItem(errorItems: IFormFieldError[])
{
  if(errorItems.length > 1)
  {
    let sameMsg = true;
    let errorMsg = errorItems[0].errorOption.message;
    let key: string = errorItems[0].key;
    for(let i = 1; i < errorItems.length; i++)
    {
      const item = errorItems[i];
      if(item.errorOption.message === errorMsg)
      {
        key = getCombinedFieldId([key, item.key]);
      }
      else
      {
        sameMsg = false;
        break;
      }
    }
    if(sameMsg && key)
    {
      return {
        ...errorItems[0],
        key: key
      } as IFormFieldError;
    }
  }
}

export function getJumpStepsFromPath(path: string)
{
  const pathArr = path.split("/") as EnumStudioSearchPathKeys[];
  const steps = [] as IStudioJumpPath[];

  for(let i = 0; i < pathArr.length; i++)
  {
    if(!pathArr[i])
    {
      continue;
    }
    const step = getJumpStepFromStudioEntStep(pathArr[i]) || pathArr[i];

    if(i < pathArr.length && pathArr[i + 1] === "map")
    {
      steps.push({
        step: step,
        itemId: pathArr[i + 2]
      });
      i += 2;
    }
    else
    {
      steps.push({
        step: step
      });
    }
  }
  return steps;
}

const obj = {
  about: "About",
  actionMap: "Actions",
  automationMap: "Automations",
  createdBy: "About",
  creationTime: "About",
  deeplinkMap: "Deeplinks",
  deploy: "Deploy",
  driveSheetMap: "DriveSheets",
  formMap: "Forms",
  groupMap: "Groups",
  deployPluginMap: "Deploy",
  deployVarMap: "Deploy",
  deployPaymentProviderMap: "Deploy",
  promptMap: "Prompts",
  reportMap: "Reports",
  roleMap: "Roles",
  spreadsheetMap: "Spreadsheets",
  translationMap: "Translations",
  varMap: "Variables",

  eventMap: "AutomationEvent",
  stepMap: "AutomationStep",
  apiMap: "Api",
  resourceMap: "Resources",
  formulaMap: "FormFormulas",
  compositeMap: "FormComposite",
  fieldMap: "Field",
  visibilityRuleMap: "FormVisibilityRules",
  layoutMap: "FormContentLayout",

  setupMode: "About",
  details: "Details",
  admins: "Admins",
  users: "Users",
  permissionMatrix: "permissionMatrix"
} as Record<EnumStudioSearchPathKeys, IStudioJumpPath["step"]>;

export function getJumpStepFromStudioEntStep(pathKey: EnumStudioSearchPathKeys): IStudioJumpPath["step"] | undefined
{
  return obj[pathKey];
}

export function fnDeltaItemModules(studioModules: StudioModuleSelection, itemModules?: StudioModuleSelection)
{
  let allow = false;

  if(studioModules && studioModules.moduleIdSet && studioModules.moduleIdSet.length > 0)
  {
    if(itemModules && itemModules.moduleIdSet
      && itemModules.moduleIdSet.find(value => studioModules.moduleIdSet.includes(
        value)))
    {
      allow = true;
    }
  }
  else if(itemModules && itemModules.moduleIdSet && itemModules.moduleIdSet.length > 0)
  {
    allow = true;
  }
  else
  {
    allow = true;
  }

  return allow;
}

export function getEmptyKeysAndMap<T>()
{
  return {
    keys: [] as MetaId[],
    map: {} as Record<MetaId, T>
  };
}

// region studio resolver

export interface IResolvedIOFormNames
{
  inputFormName?: string,
  outputFormName?: string
}

export interface IResolvedPluginIOForms
{
  inputForm?: DefnForm,
  outputForm?: DefnForm
}

export interface IResolvedIOForms
{
  inputForm?: StudioForm,
  outputForm?: StudioForm
}

export interface IResolvedField
{
  field?: StudioField | DefnField,
  composite?: StudioComposite | DefnComp
}

export interface IResolveFuncs
{
  getPlugin: (pluginId: MetaIdPlugin) => StudioEntPlugin | undefined,
  getPluginName: (pluginId: MetaIdPlugin) => string | undefined,
  getPluginForm: (pluginId: MetaIdPlugin, formId: MetaIdForm) => DefnForm | undefined;
  getPluginFormName: (pluginId: MetaIdPlugin, formId: MetaIdForm) => string | undefined,
  getPluginGrid: (pluginId: MetaIdPlugin, formId: MetaIdForm, gridId: MetaIdGrid) => DefnComp | undefined,
  getPluginGridName: (pluginId: MetaIdPlugin, formId: MetaIdForm, gridId: MetaIdGrid) => string | undefined,
  getPluginApi: (pluginId: MetaIdPlugin, pluginApiId: PluginApiId) => StudioEntPluginApi | undefined,
  getPluginApiName: (pluginId: MetaIdPlugin, pluginApiId: PluginApiId) => string | undefined,
  getPluginApiIOForms: (
    pluginId: MetaIdPlugin,
    pluginApiId: PluginApiId
  ) => IResolvedPluginIOForms,
  getPluginApiIOFormNames: (
    pluginId: MetaIdPlugin,
    pluginApiId: PluginApiId
  ) => IResolvedIOFormNames,
  getPluginFormFieldName: (
    pluginId: MetaIdPlugin,
    formId: MetaIdForm,
    fieldId: MetaIdField,
    withComposite?: boolean
  ) => string | undefined,

  getVariable: (varId: MetaIdVar) => StudioVar | undefined,
  getVariableName: (varId: MetaIdVar) => string | undefined,

  getForm: (formId: MetaIdForm) => StudioForm | undefined,
  getFormName: (formId: MetaIdForm) => string | undefined,

  getGrid: (formId: MetaIdForm, gridId: MetaIdGrid) => StudioComposite | undefined,
  getGridName: (formId: MetaIdForm, gridId: MetaIdGrid) => string | undefined,

  getComp: (formId: MetaIdForm, compId: MetaIdComposite) => StudioComposite | undefined,
  getCompName: (formId: MetaIdForm, compId: MetaIdComposite) => string | undefined,

  getFormField: (
    formId: MetaIdForm,
    fieldId: MetaIdField
  ) => IResolvedField | undefined,
  getFormFieldName: (
    formId: MetaIdForm,
    fieldId: MetaIdField,
    withComposite?: boolean
  ) => string | undefined,
  getPickTextFieldOptionName: (
    formId: MetaIdForm,
    pickTextFieldId: MetaIdField,
    pickedOptionId: MetaIdOption
  ) => string | undefined,

  getSpreadSheet: (spreadSheetId: MetaIdSpreadsheet) => StudioEntSpreadsheet | undefined,
  getSpreadSheetName: (spreadSheetId: MetaIdSpreadsheet) => string | undefined,
  getSpreadSheetForm: (spreadSheetId: MetaIdSpreadsheet) => StudioForm | undefined,
  getSpreadSheetFormName: (spreadSheetId: MetaIdSpreadsheet) => string | undefined,

  getReportName: (reportId: MetaIdReport) => string | undefined,
  getReportForms: (reportId: MetaIdReport) => IResolvedIOForms;
  getReportFormNames: (reportId: MetaIdReport) => IResolvedIOFormNames;

  getAction: (actionId: MetaIdAction) => StudioEntAction | undefined,
  getActionName: (actionId: MetaIdAction) => string | undefined,
  getActionFormNames: (actionId: MetaIdAction) => IResolvedIOFormNames;

  getGroup: (groupId: MetaIdGroup) => StudioEntGroup | undefined,
  getGroupName: (groupId: MetaIdGroup) => string | undefined,

  getRole: (roleId: MetaIdRole) => StudioEntRole | undefined,
  getRoleName: (roleId: MetaIdRole) => string | undefined,

  getDeeplink: (deeplinkId: MetaIdDeeplink) => StudioEntDeeplink | undefined,
  getDeeplinkName: (deeplinkId: MetaIdDeeplink) => string | undefined,
}

export function fnUseStudioResolver(formStore: FormStore): IResolveFuncs
{
  const getPlugin = (pluginId: MetaIdPlugin) =>
  {
    return formStore.pluginMap?.map[pluginId];
  };

  const getPluginName = (pluginId: MetaIdPlugin) =>
  {
    return getPlugin(pluginId)?.pluginName;
  };

  const getPluginForm = (pluginId: MetaIdPlugin, formId: MetaIdForm) =>
  {
    const plugin = getPlugin(pluginId);
    return plugin?.pluginFormMap[formId];
  };

  const getPluginGrid = (pluginId: MetaIdPlugin, formId: MetaIdForm, gridId: MetaIdGrid) =>
  {
    const plugin = getPlugin(pluginId);
    const pluginFormGrid = plugin?.pluginFormMap[formId]?.compMap?.[gridId];
    return pluginFormGrid?.type === "grid" ? pluginFormGrid : undefined;
  };

  const getPluginFormName = (pluginId: MetaIdPlugin, formId: MetaIdForm) =>
  {
    if(EnumArrayDefnForms.includes(formId))
    {
      return formId;
    }

    return getPluginForm(pluginId, formId)?.name;
  };

  const getPluginGridName = (pluginId: MetaIdPlugin, formId: MetaIdForm, gridId: MetaIdGrid) =>
  {
    return getPluginGrid(pluginId, formId, gridId)?.name;
  };

  const getPluginApi = (pluginId: MetaIdPlugin, pluginApiId: PluginApiId) =>
  {
    const plugin = getPlugin(pluginId);
    return plugin?.pluginApiIdMap[pluginApiId];
  };

  const getPluginApiName = (pluginId: MetaIdPlugin, pluginApiId: PluginApiId) =>
  {
    return getPluginApi(pluginId, pluginApiId)?.name;
  };

  const getPluginApiIOForms = (pluginId: MetaIdPlugin, pluginApiId: PluginApiId) =>
  {
    const pluginApi = getPluginApi(pluginId, pluginApiId);
    const pluginApiInputFormId = pluginApi?.inputFormId;
    const pluginApiOutputFormId = pluginApi?.outputFormId;

    const inputForm = pluginApiInputFormId
      ? getPluginForm(pluginId, pluginApiInputFormId)
      : undefined;
    const outputForm = pluginApiOutputFormId
      ? getPluginForm(pluginId, pluginApiOutputFormId)
      : undefined;

    return {
      inputForm,
      outputForm
    } as IResolvedPluginIOForms;
  };

  const getPluginApiIOFormNames = (pluginId: MetaIdPlugin, pluginApiId: PluginApiId) =>
  {
    const pluginApi = getPluginApi(pluginId, pluginApiId);
    const pluginApiInputFormId = pluginApi?.inputFormId;
    const pluginApiOutputFormId = pluginApi?.outputFormId;

    const inputForm = pluginApiInputFormId
      ? getPluginFormName(pluginId, pluginApiInputFormId)
      : undefined;
    const outputForm = pluginApiOutputFormId
      ? getPluginFormName(pluginId, pluginApiOutputFormId)
      : undefined;

    return {
      inputFormName: inputForm,
      outputFormName: outputForm
    };
  };

  const getPluginFormField = (pluginId: MetaIdPlugin, formId: MetaIdForm, fieldId: MetaIdField) =>
  {
    let fieldData = {} as IResolvedField;

    const form = getPluginForm(pluginId, formId);

    if(form)
    {
      loopDefnForm(form, (composite, field) =>
      {
        if(field && field.metaId === fieldId)
        {
          fieldData = {
            field: field,
            composite: composite
          };
          return;
        }
      });
    }

    return fieldData;
  };

  const getPluginFormFieldName = (
    pluginId: MetaIdPlugin,
    formId: MetaIdForm,
    fieldId: MetaIdField,
    withComposite?: boolean) =>
  {
    const {
      field,
      composite
    } = getPluginFormField(pluginId, formId, fieldId);

    if(field && composite)
    {
      const _field = field as DefnField;
      const _composite = composite as DefnComp;

      return (withComposite && _composite.type === "grid")
        ? _composite.name + "." + _field?.name
        : _field?.name;
    }
  };

  const getVariable = (varId: MetaIdVar) =>
  {
    return formStore.varMap?.map[varId];
  };

  const getVariableName = (varId: MetaIdVar) =>
  {
    return getVariable(varId)?.details?.name;
  };

  const getForm = (formId: MetaIdForm) =>
  {
    return isSystemForm(formId)
      ? formStore.sysFormMap?.map[formId]
      : formStore.formMap?.map[formId];
  };

  const getFormName = (formId: MetaIdForm) =>
  {
    return getForm(formId)?.details?.name;
  };

  const getGrid = (formId: MetaIdForm, gridId: MetaIdGrid) =>
  {
    const compositeMap = getForm(formId)?.compositeMap;
    const composite = compositeMap?.map[gridId];
    if(composite && composite.type === "grid")
    {
      return composite;
    }
  };

  const getGridName = (formId: MetaIdForm, gridId: MetaIdGrid) =>
  {
    return getGrid(formId, gridId)?.details?.name;
  };

  const getComp = (formId: MetaIdForm, compId: MetaIdComposite) =>
  {
    const compositeMap = getForm(formId)?.compositeMap;
    const composite = compositeMap?.map[compId];
    if(composite && (composite.type === "section" || composite.type === "grid"))
    {
      return composite;
    }
  };

  const getCompName = (formId: MetaIdForm, compId: MetaIdComposite) =>
  {
    return getComp(formId, compId)?.details?.name;
  };

  const getFormField = (formId: MetaIdForm, fieldId: MetaIdField) =>
  {
    let fieldData = {} as IResolvedField;

    const form = isSystemForm(formId)
      ? formStore.sysFormMap?.map[formId]
      : formStore.formMap?.map[formId];

    if(form)
    {
      loopStudioForm(form, (composite, field) =>
      {
        if(field.metaId === fieldId)
        {
          fieldData = {
            field: field,
            composite: composite
          };
          return;
        }
      });
    }

    return fieldData;
  };

  const getFormFieldName = (formId: MetaIdForm, fieldId: MetaIdField, withComposite?: boolean) =>
  {
    const {
      field,
      composite
    } = getFormField(formId, fieldId);

    if(field && composite)
    {
      const _field = field as StudioField;
      const _composite = composite as StudioComposite;

      return (withComposite && _composite.type === "grid")
        ? _composite.details?.name + "." + _field.details?.name
        : _field.details?.name;
    }
  };

  const getPickTreeNodeValue = (fieldDtoTree: FieldDtoTreeNode, optionId: string): string | undefined =>
  {
    const map = fieldDtoTree.map;
    const keys = fieldDtoTree.keys;
    const length = keys.length;

    if(map[optionId])
    {
      return map[optionId]?.value;
    }

    for(let i = 0; i < length; i++)
    {
      const key = keys[i];
      const node = map[key];

      const value = getPickTreeNodeValue(node, optionId);

      if(value)
      {
        return value;
      }
    }

    return undefined;
  };

  const getPickTextFieldOptionName = (
    formId: MetaIdForm,
    pickTextFieldId: MetaIdField,
    pickedOptionId: MetaIdOption
  ) =>
  {
    const {field} = getFormField(formId, pickTextFieldId);

    if(field?.type === "pickText")
    {
      const sourceVarId = (field as StudioFieldPickText).sourceVarId;

      if(sourceVarId)
      {
        const variable = getVariable(sourceVarId) as StudioVarSetOfText | undefined;
        if(variable?.value)
        {
          const setOfTextVarValue = variable?.value as StudioMapOfOption | undefined;

          return setOfTextVarValue?.map?.[pickedOptionId]?.value;
        }
      }
    }

    if(field?.type === "pickTree")
    {
      const sourceVarId = (field as StudioFieldPickTree).sourceVarId;

      if(sourceVarId)
      {
        const variable = getVariable(sourceVarId) as StudioVarTree | undefined;
        if(variable?.value?.value)
        {
          let node = variable.value.value;
          let optionValue: string | undefined = undefined;

          node.keys.forEach(key =>
          {
            if(!optionValue)
            {
              optionValue = getPickTreeNodeValue(node.map[key], pickedOptionId);
            }
          });

          return optionValue;
        }
      }
    }

    if(field?.type === "pickRole")
    {
      const roleMap = formStore.roleMap;

      if(roleMap?.map && roleMap.map[pickedOptionId])
      {
        const details = roleMap.map[pickedOptionId].details;

        return details.label || details.name;
      }
    }
  };

  const getSpreadSheet = (spreadSheetId: MetaIdSpreadsheet) =>
  {
    return formStore.spreadsheetMap?.map[spreadSheetId];
  };

  const getSpreadSheetName = (spreadSheetId: MetaIdSpreadsheet) =>
  {
    return getSpreadSheet(spreadSheetId)?.details?.name;
  };

  const getSpreadSheetForm = (spreadSheetId: MetaIdSpreadsheet) =>
  {
    const spreadSheetFormId = getSpreadSheet(spreadSheetId)?.formId;
    return spreadSheetFormId ? getForm(spreadSheetFormId) : undefined;
  };

  const getSpreadSheetFormName = (spreadSheetId: MetaIdSpreadsheet) =>
  {
    const spreadSheetForm = getSpreadSheetForm(spreadSheetId);
    return spreadSheetForm?.details?.name;
  };

  const getReport = (reportId: MetaIdReport) =>
  {
    return formStore.reportMap?.map[reportId];
  };

  const getReportName = (reportId: MetaIdReport) =>
  {
    return getReport(reportId)?.details?.name;
  };

  const getReportIOForms = (reportId: MetaIdReport) =>
  {
    const report = getReport(reportId) as
      | StudioEntReportMapper
      | StudioEntReportSpreadsheet
      | StudioEntReportComposite
      | StudioEntReportQuery
      | StudioEntReportPlugin;

    const inputForm = report?.inputFormId
      ? getForm(report.inputFormId)
      : undefined;
    const outputForm = report?.outputFormId
      ? getForm(report.outputFormId)
      : undefined;

    return {
      inputForm,
      outputForm
    } as IResolvedIOForms;
  };

  const getReportIOFormNames = (reportId: MetaIdReport) =>
  {
    const {
      inputForm,
      outputForm
    } = getReportIOForms(reportId);

    return {
      inputFormName: inputForm?.details?.name,
      outputFormName: outputForm?.details?.name
    };
  };

  const getAction = (actionId: MetaIdAction) => formStore.actionMap?.map[actionId];
  const getActionName = (actionId: MetaIdAction) => getAction(actionId)?.details?.name;

  const getActionIOFormName = (actionId: MetaIdAction): IResolvedIOFormNames =>
  {
    const action = getAction(actionId);

    switch(action?.kind)
    {
      case "rowInsert":
      {
        const spreadsheetId = (action as StudioEntActionRowInsert).spreadsheetId;
        if(spreadsheetId)
        {
          return {
            inputFormName: getSpreadSheetFormName(spreadsheetId),
            outputFormName: undefined
          };
        }
      }
        break;
      case "spreadsheetEditor":
      {
        const spreadsheetId = (action as StudioEntActionSpreadsheetEditor).spreadsheetId;
        if(spreadsheetId)
        {
          return {
            inputFormName: undefined,
            outputFormName: getSpreadSheetFormName(spreadsheetId)
          };
        }
      }
        break;
      case "report":
      {
        const reportId = (action as StudioEntActionReport).reportId;
        if(reportId)
        {
          return getReportIOFormNames(reportId);
        }
      }
        break;
    }

    return {
      inputFormName: undefined,
      outputFormName: undefined
    };
  };

  const getGroup = (groupId: MetaIdGroup) => formStore.groupMap?.map[groupId];
  const getGroupName = (groupId: MetaIdGroup) => getGroup(groupId)?.details?.name;

  const getRole = (roleId: MetaIdRole) => formStore.roleMap?.map[roleId];
  const getRoleName = (roleId: MetaIdRole) => getRole(roleId)?.details?.name;

  const getDeeplink = (deepLinkId: MetaIdDeeplink) => formStore.deeplinkMap?.map[deepLinkId];
  const getDeeplinkName = (deepLinkId: MetaIdDeeplink) => getDeeplink(deepLinkId)?.name;

  return {
    getPlugin,
    getPluginName,
    getPluginForm,
    getPluginFormName,
    getPluginGrid,
    getPluginGridName,
    getPluginApi,
    getPluginApiName,
    getPluginApiIOForms,
    getPluginApiIOFormNames,
    getPluginFormFieldName,
    getVariable,
    getVariableName,
    getForm,
    getFormName,
    getGrid,
    getGridName,
    getComp,
    getCompName,
    getFormField,
    getFormFieldName,
    getPickTextFieldOptionName,
    getSpreadSheet,
    getSpreadSheetName,
    getSpreadSheetForm,
    getSpreadSheetFormName,
    getReportName,
    getReportForms: getReportIOForms,
    getReportFormNames: getReportIOFormNames,
    getAction,
    getActionName,
    getGroup,
    getGroupName,
    getRole,
    getRoleName,
    getDeeplink,
    getDeeplinkName,
    getActionFormNames: getActionIOFormName
  };
}

// endregion

// region exclude mapping vars

type TypeExcludeMappingVar = "onlySrc" | "onlyTarget" | "both";

export interface IExcludeMappingVars
{
  excludeIdSet: MetaIdVar[],
  src?: MetaIdForm,
  target?: MetaIdVar
}

export function getExcludeMappingVarIdSet(
  type: TypeExcludeMappingVar,
  varMap: StudioVarMap,
  fromFormId?: MetaIdForm,
  toFormId?: MetaIdForm
)
{
  const excludeMappingVarIdSet: MetaIdVar[] = [];

  varMap.keys.forEach(variableId =>
  {
    const variable = varMap.map[variableId] as StudioVarMapping;
    if(variable.kind === "mapping")
    {
      const varFromFormId = variable.value?.fromFormId;
      const varToFormId = variable.value?.toFormId;

      switch(type)
      {
        case "onlySrc":
          if(!varFromFormId || (varFromFormId && fromFormId && varFromFormId === fromFormId))
          {
            return;
          }
          break;
        case "onlyTarget":
          if(!varToFormId || (varToFormId && toFormId && varToFormId === toFormId))
          {
            return;
          }
          break;
        case "both":
          if((!varFromFormId || (varFromFormId && fromFormId && varFromFormId === fromFormId))
            && (varToFormId && toFormId && varToFormId === toFormId))
          {
            return;
          }
          break;
        default:
          excludeMappingVarIdSet.push(variableId);
          return;
      }

      excludeMappingVarIdSet.push(variableId);
    }
  });

  return {
    excludeIdSet: excludeMappingVarIdSet,
    src: fromFormId,
    target: toFormId
  } as IExcludeMappingVars;
}

// endregion

export function getExcludeConditionVarIds(
  varMap: StudioVarMap,
  src?: MetaIdForm,
  inputForm?: MetaIdForm,
  excludeInputFormVars?: boolean
)
{
  const excludeConditionVarIdSet: MetaIdVar[] = [];

  varMap.keys.forEach(variableId =>
  {
    const variable = varMap.map[variableId] as StudioVarCondition;
    if(variable.kind === "condition")
    {
      const fromSourceFormId = variable.value?.sourceFormId;
      const inputFormId = variable.value?.inputFormId;

      if(excludeInputFormVars && inputFormId)
      {
        excludeConditionVarIdSet.push(variableId);
        return;
      }

      if(fromSourceFormId && src && fromSourceFormId === src)
      {
        if(inputFormId && inputForm && inputFormId !== inputForm)
        {
          excludeConditionVarIdSet.push(variableId);
          return;
        }
        return;
      }

      excludeConditionVarIdSet.push(variableId);
    }
  });

  return excludeConditionVarIdSet;
}

function getModules(allModules?: StudioModuleMap, selectedModules?: StudioModuleSelection): string
{
  let modulesValue = "" as string;
  if(allModules)
  {
    modulesValue =
      `${toLabel(STR_MODULES)}: ${selectedModules?.moduleIdSet.map((modules) => allModules.map[modules].name)
      .join(", ")}`;
  }
  return modulesValue;
}

export function getSelectedModules(allModules?: StudioModuleMap, selectedModules?: StudioModuleSelection)
{
  let modules = "";
  if(selectedModules?.moduleIdSet && selectedModules.moduleIdSet.length > 0)
  {
    modules += getModules(allModules, selectedModules);
  }
  else
  {
    modules = `No ${STR_MODULES}`;
  }

  return modules;
}

export function getResolvedIOFormNamesHelperText(inputFormName?: string, outputFormName?: string): string
{
  if(inputFormName && outputFormName)
  {
    return (`Input form: ${inputFormName}, Output form: ${outputFormName}`);
  }
  else if(inputFormName)
  {
    return (`Input form: ${inputFormName}`);
  }
  else if(outputFormName)
  {
    return (`Output form: ${outputFormName}`);
  }
  return "";
}

// region studio usage filter

export type TypeStudioFilterType =
  | "SingleUse"
  | "MultiUse"
  | "Unused"
  | "All";

export function getSelectedUsageFilterLabel(selectedFilterType: TypeStudioFilterType): string | undefined
{
  switch(selectedFilterType)
  {
    case "SingleUse":
      return `single use`;
    case "MultiUse":
      return `multi use`;
    case "Unused":
      return `unused`;
    case "All":
      return `all`;
  }
}

export function getSelectedUserFilterLabel(selectedFilterType: TypeProductionUserFilterType): string | undefined
{
  switch(selectedFilterType)
  {
    case "ShowActive":
      return `active`;
    case "ShowDeactivated":
      return `deactivated`;
    case "ShowInviteOnly":
      return `invite only`;
    case "All":
      return `all`;
  }
}

// endregion

// region variable value resolve
export function resolveStudioVarValue(studioVar?: StudioVar, resolveFuncs?: IResolveFuncs): string | undefined
{
  if(studioVar && studioVar.kind)
  {
    switch(studioVar.kind)
    {
      case "bool":
      {
        const value = (studioVar as StudioVarBoolean).value?.value;
        return (value === true) ? "Yes" : "No";
      }
      case "buttonVariant":
        return (studioVar as StudioVarButtonVariant).value;
      case "color":
        return (studioVar as StudioVarColor).value?.value;
      case "currency":
        return (studioVar as StudioVarCurrency).value?.value;
      case "date":
      {
        const value = (studioVar as StudioVarDate).value;
        return value?.value
          ? toLabel(value.value)
          : value?.customValue
            ? formatDate(value?.customValue, "local")
            : undefined;
      }
      case "dateTime":
      {
        const value = (studioVar as StudioVarDateTime).value;
        return (value?.value || getDateFromString(value?.customValue));
      }
      case "day":
        return (studioVar as StudioVarDay).value;
      case "decimal":
      {
        const value = (studioVar as StudioVarDecimal).value?.value;
        return value?.toString();
      }
      case "deviceSize":
        return (studioVar as StudioVarDeviceSize).value;
      case "deviceType":
        return (studioVar as StudioVarDeviceType).value;
      case "direction":
        return (studioVar as StudioVarDirection).value;
      case "dividerKind":
        return (studioVar as StudioVarDividerKind).value;
      case "document":
        return (studioVar as StudioVarDocument).value?.value?.fileName;
      case "duration":
      {
        const value = (studioVar as StudioVarDuration).value;
        return value?.value.value + " " + value?.value.unit;
      }
      case "email":
        return (studioVar as StudioVarEmail).value?.value;
      case "textSize":
        return (studioVar as StudioVarTextSize).value;
      case "html":
        return (studioVar as StudioVarHtml).value?.value;
      case "hyperlink":
        return (studioVar as StudioVarHyperlink).value?.value;
      case "icon":
        break;
      case "image":
        break;
      case "imageCorner":
        return (studioVar as StudioVarImageCorner).value;
      case "language":
        return (studioVar as StudioVarLanguage).value?.value;
      case "location":
        break;
      case "mapPinShape":
        return (studioVar as StudioVarMapPinShape).value;
      case "mobileNumber":
      {
        const value = (studioVar as StudioVarMobileNumber).value?.value;
        return `(${value?.countryCode}) ${value?.nationalNumber}`;
      }
      case "number":
      {
        const value = (studioVar as StudioVarNumber).value?.value;
        return value?.toString();
      }
      case "paragraph":
        return (studioVar as StudioVarParagraph).value?.value;
      case "ratingKind":
        return (studioVar as StudioVarRatingKind).value;
      case "stroke":
        return (studioVar as StudioVarStroke).value;
      case "symbol":
        return (studioVar as StudioVarSymbol).value;
      case "placement":
        return (studioVar as StudioVarPlacement).value;
      case "time":
        return (studioVar as StudioVarTime).value?.value;
      case "timeZone":
        return (studioVar as StudioVarTimeZone).value?.value;
      case "condition":
        const value = (studioVar as StudioVarCondition).value;
        return value && resolveFuncs
          ? getResolvedConditionVar(value, resolveFuncs)
          : undefined;
      case "function":
        break;
      case "mapping":
        return resolverMappingVarValue(studioVar as StudioVarMapping, resolveFuncs);
      case "sequence":
        return (studioVar as StudioVarSequence).value?.value?.toString();
      case "userSetting":
        return (studioVar as StudioVarUserSetting).value?.userSettingOptionKind;
      case "setOfDay":
        break;
      case "setOfNumber":
        break;
      case "setOfText":
        break;
      case "setOfTime":
        break;
      case "tree":
        break;
      case "text":
        return (studioVar as StudioVarText).value?.value ?? "";
      default:
        return undefined;
    }
  }
}

function resolverMappingVarValue(mappingVar: StudioVarMapping, resolveFuncs?: IResolveFuncs)
{
  const mappingVarValue = mappingVar.value;
  if(mappingVarValue && resolveFuncs)
  {
    const fromFormId = mappingVarValue.fromFormId;
    const fromGridId = mappingVarValue.fromGridId;
    const fromPluginId = mappingVarValue.fromPluginId;

    const toFormId = mappingVarValue.toFormId;
    const toGridId = mappingVarValue.toGridId;
    const toPluginId = mappingVarValue.toPluginId;

    const resolveFrom = resolveFuncs
      ? resolveMappingSrc(resolveFuncs, fromFormId, fromGridId, fromPluginId)
      : "";

    const resolveTo = resolveFuncs
      ? resolveMappingSrc(resolveFuncs, toFormId, toGridId, toPluginId)
      : "";

    return (!isEmpty(resolveFrom) || !isEmpty(resolveTo))
      ? `${resolveFrom || ""} > ${resolveTo || ""}`
      : "";
  }
}

function resolveMappingSrc(
  resolveFuncs: IResolveFuncs,
  formId?: MetaIdForm,
  gridId?: MetaIdGrid,
  pluginId?: MetaIdPlugin
)
{
  if(pluginId && formId)
  {
    const fromPluginFormName = resolveFuncs.getPluginFormName(pluginId, formId);
    if(gridId)
    {
      const fromPluginFormGridName = resolveFuncs.getPluginGridName(pluginId, formId, gridId);
      return fromPluginFormName && fromPluginFormGridName
        ? `${fromPluginFormName}.${fromPluginFormGridName}`
        : "";
    }
    return fromPluginFormName || "";
  }
  else if(!pluginId && formId)
  {
    const fromFormName = resolveFuncs.getFormName(formId);
    if(gridId)
    {
      const fromFormGridName = resolveFuncs.getGridName(formId, gridId);
      return fromFormName && fromFormGridName
        ? `${fromFormName}.${fromFormGridName}`
        : "";
    }
    return fromFormName || "";
  }
}

// endregion

// region drag and drop multi level list

interface IFieldDragDropLocation
{
  fromId: string;
  fromIndex: number;
  toId: string;
  toIndex: number;
}

export function calcDragDropLocation(
  selectList: SelectList,
  startIndex: number,
  _dragEndIndex: number
): IFieldDragDropLocation | undefined
{
  const rootState = store.getState();
  const list = selectList(rootState);
  const displayItemIds = list.displayItemIds;
  const groupsById = list.groupsById;
  const dragEndIndex = startIndex > _dragEndIndex
    ? _dragEndIndex - 1
    : _dragEndIndex;

  if(groupsById)
  {
    let fromCount = 0;
    let fromId: string = "";

    for(let i = startIndex; i >= 0; i--)
    {
      const itemId = displayItemIds[i];
      const groupItem = groupsById[itemId];
      if(groupItem)
      {
        fromId = itemId;
        break;
      }
      fromCount++;
    }

    let toCount = 0;
    let toId: string = "";

    for(let i = dragEndIndex; i >= 0; i--)
    {
      const itemId = displayItemIds[i];
      const groupItem = groupsById[itemId];
      if(groupItem)
      {
        toId = itemId;
        break;
      }
      toCount++;
    }

    if(fromId === toId && startIndex < _dragEndIndex)
    {
      toCount--;
    }

    return {
      fromId: fromId,
      fromIndex: fromCount - 1,
      toId: toId,
      toIndex: toCount
    };
  }
}

// endregion

import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {useRef} from "react";
import {useEffect} from "react";
import React from "react";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {getAnimationSxForVisibilityRules} from "../../../../base/plus/FormPlus";
import theme from "../../../../base/plus/ThemePlus";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";

export default function FieldBase(props: {
  fieldId: MetaIdField,
  flexGrow?: number,
  children: React.ReactNode,
  width?: StandardCSSProperties["width"],
  pt?: StandardCSSProperties["paddingTop"],
  pr?: StandardCSSProperties["paddingRight"],
  pb?: StandardCSSProperties["paddingBottom"],
  pl?: StandardCSSProperties["paddingLeft"],
  mt?: StandardCSSProperties["marginTop"],
  overflow?: StandardCSSProperties["overflow"]
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const parentSection = formSectionCtx.getParent && formSectionCtx.getParent();

  const parentKey = parentSection?.metaId;
  const fieldId = props.fieldId;
  const pl = props.pl;
  const pr = props.pr;
  const pt = props.pt;
  const pb = props.pb;

  const defn = formSectionCtx.getDefn && formSectionCtx.getDefn(fieldId);
  const maxWidth = defn?.maxWidth;

  const isFirst = formCtx.isFirstField(fieldId, parentKey);
  const isLast = formCtx.isLastField(fieldId, parentKey);
  const focusField = formCtx.getFocusField();
  const visibilityOption = formCtx.getFieldVisibilityOption(fieldId);

  const comp = formCtx.getDefnComp(fieldId);
  const hidden = comp?.hidden;
  const invisible = comp?.invisible;

  const ref = useRef({} as HTMLDivElement);

  useEffect(() =>
  {
    if(visibilityOption?.blinkDurationMs)
    {
      setTimeout(() =>
      {
        formCtx.setFieldVisibilityOption(fieldId, {
          ...visibilityOption,
          blinkDurationMs: undefined
        });
      }, visibilityOption?.blinkDurationMs);
    }

    if(visibilityOption?.shakeDurationMs)
    {
      setTimeout(() =>
      {
        formCtx.setFieldVisibilityOption(fieldId, {
          ...visibilityOption,
          shakeDurationMs: undefined
        });
      }, visibilityOption?.shakeDurationMs);
    }

    if(visibilityOption?.highlightDurationMs)
    {
      setTimeout(() =>
      {
        formCtx.setFieldVisibilityOption(fieldId, {
          ...visibilityOption,
          highlightDurationMs: undefined
        });
      }, visibilityOption?.highlightDurationMs);
    }

  }, [visibilityOption]);

  return <Box
    ref={ref}
    sx={{
      width: props.width || "100%",
      maxWidth: maxWidth,
      ...parentSection?.sectionDirection === "horizontal"
        ? {
          pl: pl ?? (isFirst ? FORM_FIELD_MEDIUM_PADDING : 0),
          pr: pr ?? (isLast ? FORM_FIELD_MEDIUM_PADDING : 0)
        }
        : {
          pl: pl ?? FORM_FIELD_MEDIUM_PADDING,
          pr: pr ?? FORM_FIELD_MEDIUM_PADDING
        },
      pt: pt,
      pb: pb,
      flexGrow: props.flexGrow,
      ...(visibilityOption?.hidden
        || hidden
        || visibilityOption?.invisible
        || invisible) && {
        display: "none"
      },
      mt: props.mt,
      overflow: props.overflow
    }}
  >

    {focusField?.key === fieldId
    || visibilityOption?.blinkDurationMs
    || visibilityOption?.shakeDurationMs
    || visibilityOption?.highlightDurationMs
      ? <Box
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: focusField && focusField.key === fieldId
            ? focusField.bgColor
              ? theme.common.color(focusField.bgColor)
              : theme.common.bgcolorSelection
            : undefined,
          opacity: 0.8,
          ...getAnimationSxForVisibilityRules(visibilityOption)
        }}
      >
        {props.children}
      </Box>
      : props.children
    }

  </Box>;
}

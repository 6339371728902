import {Property} from "csstype";
import {px} from "../../../base/plus/StringPlus";
import theme from "../../../base/plus/ThemePlus";
import BannerMain from "../assets/NeomeLogo.svg";
import LayoutFlexCol from "../layout/LayoutFlexCol";

export default function LogoMainAuth(props: {
  shrinkImage?: boolean,
  maxHeight?: Property.MaxHeight,
  borderRadius?: number
})
{
  return (
    <LayoutFlexCol
      flexGrow={1}
      width={"100%"}
      bgcolor={theme.common.color("primaryDark")}
      height={"100%"}
      maxHeight={props.maxHeight}
      borderRadius={px(props.borderRadius)}
    >
      <img
        style={{
          height: props.shrinkImage ? px(240) : undefined,
          width: props.shrinkImage ? px(240) : undefined
        }}
        src={BannerMain}
        alt={"Neome bannerMain"}
      />
    </LayoutFlexCol>
  );
}

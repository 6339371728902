import {isArray} from "lodash";
import {useEffect} from "react";
import {useCallback} from "react";
import React from "react";
import {useMemo} from "react";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldFormList} from "../../../../api/meta/base/dto/DefnFieldFormList";
import {DefnFieldFormListItem} from "../../../../api/meta/base/dto/DefnFieldFormListItem";
import {DefnLayoutCard} from "../../../../api/meta/base/dto/DefnLayoutCard";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {FieldDtoGridRow} from "../../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {RowId} from "../../../../api/meta/base/Types";
import {STR_CLICK_HERE_TO_ADD_RECORDS} from "../../../../base/plus/ConstantsPlus";
import {createDefaultDefnForm} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {getEmptyKeysAndMap} from "../../../../base/plus/StudioPlus";
import {listSetPick} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetPickType} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetSelectedItemId} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetLayoutType} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItemForm} from "../../../../base/types/list/TypeListForm";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {useAppSelector} from "../../../app/AppHooks";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import RawQuickLink from "../../../atom/raw/RawQuickLink";
import {CbOnClickForm} from "../base/CtxForm";
import {useFormCtx} from "../base/CtxForm";
import {IFieldGridRawProps} from "../composite/FieldGrid";
import {fieldListItemVal} from "./FieldRawList";
import FieldRawList from "./FieldRawList";
import {IFieldRawListRef} from "./FieldRawList";

const fieldSection = "fieldSection";
const fieldListItem = "fieldListItem";

export default function RawFieldGridList(props: IFieldGridRawProps)
{
  const defnForm = props.defnForm;
  const defnGrid = props.defnGrid;
  const layoutDto = props.layout;
  const fieldValueGridList = props.fieldValue;
  const formCtx = useFormCtx();

  const cbOnClick = formCtx.getOnClick();
  const defnTheme = formCtx.getDefnTheme();
  const readOnly = formCtx.isFieldReadonly(defnGrid);
  const disabled = formCtx.isFieldDisable(defnGrid as DefnFieldEditable) || defnGrid.disabled;
  const compMap = defnForm.compMap;
  const isReport = defnTheme.formVariant === "report";
  const isRowAction = Boolean(defnGrid?.rowActionPermissionMap?.keys.length);
  const hideMenu = (readOnly || disabled) || (isReport && !isRowAction);

  const fieldId = getFieldKey(defnGrid);
  const cbRefList = {} as IFieldRawListRef<FieldDtoGridRow>;
  const selectList = formCtx.getSelectList(fieldId);
  const isPickMany = defnGrid.isPickMany;
  const pickedRowIdSet = defnGrid.pickedRowIdSet;
  const layout = (layoutDto?.kind === "list" || layoutDto?.kind === "card")
    ? layoutDto as DefnLayoutCard
    : undefined;
  const isCard = layout?.kind === "card";

  const fieldValue = useMemo(() => fieldValueGridList
    ? convertMapToArray(fieldValueGridList)
    : convertMapToArray(getEmptyKeysAndMap() as FieldValueGrid), [fieldValueGridList]);

  const listName = useAppSelector(state => selectList ? selectList(state).listName : undefined);

  const hideAddRecordText = useMemo(() =>
  {
    return Boolean(readOnly || disabled || isReport);

  }, [disabled, isReport, readOnly]);

  const hideAddBtn = useMemo(() =>
  {
    const isFieldValuePresent = Boolean(fieldValue && fieldValue.length > 0);

    return Boolean(!isFieldValuePresent || readOnly || disabled || isReport);

  }, [fieldValue, disabled, isReport, readOnly]);

  const onChange = (fieldValue: FieldDtoGridRow[]) =>
  {
    props.onChange(convertArrayToMap(fieldValue));
  };

  const cbClick = useCallback(() =>
  {
    cbOnClick && cbOnClick(fieldId, "fieldGridAddNew");
  }, [fieldId, cbOnClick]);

  const doLoad = useCallback((_selectList: SelectList, fieldValue: FieldDtoGridRow[]) =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    if(fieldValue?.length && layout)
    {
      fieldValue.forEach((tp) =>
      {
        const rowId = tp.rowId;
        const fieldValue = fieldValueGridList?.map[rowId];
        if(rowId && !uiItemsById[rowId])
        {
          if(fieldValue)
          {
            uiItemIds.push(rowId);
            uiItemsById[rowId] = {
              type: "form",
              hideMenu: hideMenu,
              id: rowId,
              rowId: rowId,
              defnFieldFormListId: fieldId,
              rightSwipeActions: [
                {
                  name: "more",
                  color: "white",
                  bgcolor: "primary"
                }
              ],
              userField: {
                [fieldListItemVal]: fieldValue
              }
            } as IListItemForm;
          }
        }
      });
    }

    const formItemDefnCompMap = layout
      ? {
        ...createDefaultDefnForm({
          ...compMap,
          [fieldSection]: {
            type: "section",
            metaId: fieldSection,
            name: fieldSection,
            fieldIdSet: [fieldListItem],
            overflowHidden: true,
            flexGrow: true,
            pl: 0,
            pr: 0,
            pt: 0,
            pb: 0
          } as DefnSection,
          [fieldListItem]: {
            type: "formListItem",
            metaId: fieldListItem,
            layout: layout.item,
            isCard: isCard,
            name: fieldListItem
          } as DefnFieldFormListItem,
          [fieldId]: {
            type: "formList",
            metaId: fieldId,
            name: fieldId,
            showAsCardLayout: isCard,
            hideMenu: true,
            displaySectionId: fieldSection,
            bgColorVar: {
              value: "white"
            }
          } as DefnFieldFormList
        })
      } as DefnFormUi
      : undefined;
    
    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById,
      defnForm: formItemDefnCompMap,
      layout: layout
    } as IListData;
  }, [layout, compMap, isCard, fieldId, fieldValueGridList?.map, hideMenu]);

  const onClickList: CbOnClickForm = useCallback((
    row: TypeListItemId,
    variant: FormClickVariant,
    value?: any,
    menuAnchor?: Element,
    menuProps?: IMenuProps
  ) =>
  {
    switch(variant)
    {
      case "listItem":
        const userField = value?.userField[fieldListItemVal];
        cbOnClick && cbOnClick(
          fieldId,
          "fieldGridItem",
          userField,
          menuAnchor,
          menuProps
        );

        if(listName)
        {
          dispatchList(listName, listSetSelectedItemId(undefined));
        }
        break;
      case "spotMenu":
        cbOnClick && cbOnClick(fieldId, "fieldGridRowAction", fieldValueGridList?.map[row], menuAnchor, menuProps);
    }
  }, [fieldId, cbOnClick, listName, fieldValueGridList]);

  const onClickIconBtn = (iconName: EnumIconButton) =>
  {
    if(iconName === "add")
    {
      cbOnClick && cbOnClick(fieldId, "fieldGridAddNew");
    }
  };

  useEffect(() =>
  {
    formCtx.setCbFieldRemoteClick(fieldId, cbClick);
  }, [cbClick]);

  useEffect(() =>
  {
    if(listName && (layoutDto?.kind === "list" || layoutDto?.kind === "card"))
    {
      dispatchList(listName, listSetLayoutType({
        listLayoutType: layoutDto.kind
      }));
    }
  }, [listName, layoutDto?.kind]);

  useEffect(() =>
  {
    if(listName)
    {
      if(isPickMany)
      {
        dispatchList(listName, listSetPickType("pickMany"));
      }
      if(pickedRowIdSet && pickedRowIdSet.length)
      {
        pickedRowIdSet.forEach(rowId =>
        {
          dispatchList(listName, listSetPick({
            itemId: rowId,
            pickValue: true
          }));
        });
      }
    }
  }, [listName]);

  if(!layout)
  {
    return null;
  }

  return (
    <FieldRawList
      isLastField={true}
      fieldId={fieldId}
      autoHeight={layout.renderingMode === "auto"}
      fieldValue={fieldValue}
      disableSpotMenu={isReport}
      cbRef={cbRefList}
      doLoad={doLoad}
      onChange={onChange}
      onClickList={onClickList}
      emptyComp={
        !hideAddRecordText && <RawQuickLink
          name={STR_CLICK_HERE_TO_ADD_RECORDS}
          Icon={"AddRounded"}
          onClick={() => onClickIconBtn("add")}
          color={"primary"}
        />
      }
      buttonStrip={
        !hideAddBtn
          ? <RawButtonStrip
            iconButtonList={["add"]}
            onClick={onClickIconBtn}
            size={"large"}
          />
          : null
      }
    />
  );
}

function convertMapToArray(dto?: FieldValueGrid): FieldDtoGridRow[]
{
  if(!dto || !isArray(dto?.keys))
  {
    return [];
  }

  return dto.keys.map((key) =>
  {
    return dto.map[key];
  });
}

function convertArrayToMap(arr: FieldDtoGridRow[]): FieldValueGrid | null
{
  const keys = [] as RowId[];
  const map = {} as Record<RowId, FieldDtoGridRow>;

  arr.forEach((value) =>
  {
    const key = value.rowId;
    keys.push(key);
    map[key] = value;
  });

  if(keys.length)
  {
    return {
      keys: keys,
      map: map
    } as FieldValueGrid;
  }
  return null;
}

import {GoogleMapProps} from "@react-google-maps/api";
import {GoogleMap} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {isEmpty} from "lodash";
import {useEffect} from "react";
import {useLayoutEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {STR_FAIL_TO_LOAD_GOOGLE_MAP} from "../../base/plus/ConstantsPlus";
import {getGoogleMapApiKey} from "../../base/plus/LocationPlus";
import {IMapRef} from "../../base/types/TypeMap";
import {CbOnClickMapMarker} from "../../base/types/TypeMap";
import {TypeMarkerId} from "../../base/types/TypeMap";
import {ILocationMarkers} from "../../base/types/TypeMap";
import {ILocationMarker} from "../../base/types/TypeMap";
import {ILatLng} from "../../base/types/TypesStudio";
import LayoutFlexCol from "../atom/layout/LayoutFlexCol";
import RawFadeLoader from "../atom/raw/RawFadeLoader";
import RawNothingHere from "../atom/raw/RawNothingHere";
import loadGoogleMapScript from "./MapLoadScript";
import {MapMarker} from "./MapMarker";
import {IMarkerTooltip} from "./MapMarkerTooltip";
import {TooltipTitle} from "./MapMarkerTooltip";
import {MarkerTooltip} from "./MapMarkerTooltip";
import {MapPolyLine} from "./MapPolyLine";

const libraries: Libraries = ["places"];

export default function RawGoogleMap(props: {
  initialDataMap?: ILocationMarkers,
  cbRef?: IMapRef,
  onClickMarker?: CbOnClickMapMarker
})
{
  const googleMapApiKey = getGoogleMapApiKey();
  const onClickMarker = props.onClickMarker;
  const cbRef = props.cbRef;

  const [initialDataMap, setInitialDataMap] = useState(props.initialDataMap);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<Error | undefined>(undefined);

  const addMarkerIntoInitialData = useCallback((markerId: TypeMarkerId, marker: ILocationMarker) =>
  {
    setInitialDataMap(prevDataMap =>
    {
      const prevMap = prevDataMap!;

      const initialMarkerKeySet = prevMap
        ? Object.keys(prevMap)
        : [];

      if(initialMarkerKeySet.includes(markerId))
      {
        return {
          ...prevMap,
          [markerId]: [...prevMap[markerId], marker]
        };
      }
      else
      {
        return {
          ...prevMap,
          [markerId]: [marker]
        };
      }
    });

  }, []);

  useEffect(() =>
  {
    const mapLoading = loadGoogleMapScript(googleMapApiKey, libraries, setIsLoaded, setLoadError);
    return mapLoading;
  }, [googleMapApiKey]);

  if(cbRef)
  {
    cbRef.setMarker = addMarkerIntoInitialData;
  }

  if(loadError)
  {
    const errorMsg = loadError?.message
      ? loadError?.message
      : STR_FAIL_TO_LOAD_GOOGLE_MAP;

    return (
      <RawNothingHere
        helperTextData={{
          title: errorMsg
        }}
      />
    );
  }

  return isLoaded && window.google
    ? <GoogleMapRaw
      initialDataMap={initialDataMap}
      onClickMarker={onClickMarker}
    />
    : <LayoutFlexCol
      alignItems={"center"}
      justifyContent={"center"}
    >
      <RawFadeLoader />
    </LayoutFlexCol>;
}

function GoogleMapRaw(props: {
  initialDataMap?: ILocationMarkers,
  onClickMarker?: CbOnClickMapMarker,
})
{
  const mapStyles = {
    height: "100%",
    width: "100%"
  } as GoogleMapProps;
  const initialDataMap = props.initialDataMap;
  const onCLickMarker = props.onClickMarker;

  const [infoWindowToolTipTitle, setInfoWindowToolTipTitle] = useState<IMarkerTooltip | undefined>(undefined);
  const [infoWindowPosition, setInfoWindowPosition] = useState<google.maps.LatLng | undefined>(undefined);
  const [markers, setMarkers] = useState<ILatLng[]>();

  const onClickMarker = (markerId: string, eventTarget: (EventTarget | null), latLng?: ILatLng) =>
  {
    onCLickMarker && onCLickMarker(markerId, eventTarget, latLng);
  };

  const onMouseOverMarker = (position: google.maps.LatLng, markerToolTip?: IMarkerTooltip) =>
  {
    setInfoWindowPosition(position);
    setInfoWindowToolTipTitle(markerToolTip);
  };

  const onMouseOutMarker = () =>
  {
    setInfoWindowPosition(undefined);
    setInfoWindowToolTipTitle(undefined);
  };

  const onLoadMap = useCallback((map: any) =>
  {
    const bounds = new window.google.maps.LatLngBounds();

    markers?.forEach((location) =>
    {
      bounds.extend({
        lat: location.lat,
        lng: location.lng
      });
    });

    map.fitBounds(bounds);
    map.setOptions({
      minZoom: 3,
      maxZoom: 18
    });

  }, [markers]);

  useLayoutEffect(() =>
  {
    if(!isEmpty(initialDataMap))
    {
      Object.values(initialDataMap).forEach((marker) =>
      {
        marker.forEach(item =>
        {
          setMarkers(prevState => [
            ...prevState ?? [], {
              lat: item.locations.lat,
              lng: item.locations.lng
            }
          ]);
        });
      });
    }

  }, [initialDataMap]);

  return (

    <GoogleMap
      mapContainerStyle={mapStyles}
      onLoad={(map) => onLoadMap(map)}
      options={{
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true
      }}
    >
      {initialDataMap &&
        <MapMarkerSet
          markerSet={initialDataMap}
          onClickMarker={onClickMarker}
          onMouseOverMarker={onMouseOverMarker}
          onMouseOutMarker={onMouseOutMarker}
        />
      }

      {initialDataMap &&
        <MapPolylineSet
          markerSet={initialDataMap}
        />
      }

      <MapMarkerTooltip
        position={infoWindowPosition}
      >
        {
          infoWindowToolTipTitle &&
          <TooltipTitle tooltip={infoWindowToolTipTitle} />
        }
      </MapMarkerTooltip>
    </GoogleMap>
  );
}

function MapMarkerSet(props: {
  markerSet: ILocationMarkers,
  onClickMarker?: CbOnClickMapMarker,
  onMouseOverMarker?: (position: google.maps.LatLng, markerToolTip?: IMarkerTooltip) => void,
  onMouseOutMarker?: (position: google.maps.LatLng) => void
})
{
  const markerSet = props.markerSet;
  const onClickMarker = props.onClickMarker;
  const onMouseOverMarker = props.onMouseOverMarker;
  const onMouseOutMarker = props.onMouseOutMarker;

  return (
    <>
      {
        Object.keys(markerSet).map((key, index) =>
        {
          const locationSet = markerSet[key];
          return (
            <div key={index}>
              {
                locationSet.map((value, markerIndex) =>
                {
                  const markerColor = value.markerColor;
                  const markerToolTip = value.markerToolTip;
                  const markerShape = value.markerShape;
                  const markerValue = value.markerId;

                  return (markerValue &&
                    <MapMarker
                      key={markerIndex}
                      markerId={markerValue}
                      markerColor={markerColor}
                      markerToolTip={markerToolTip}
                      markerValue={value}
                      markerShape={markerShape}
                      handleClick={onClickMarker}
                      onMouseOverMarker={onMouseOverMarker}
                      onMouseOutMarker={onMouseOutMarker}
                    />
                  );
                })
              }
            </div>
          );
        })
      }
    </>
  );
}

function MapPolylineSet(props: {
  markerSet: ILocationMarkers,
})
{
  const markerSet = props.markerSet;
  const [polyLineGroup, setPolyLineGroup] = useState<Record<string, ILatLng[]>>();

  useEffect(() =>
  {
    const _polyLineGroup = {} as Record<string, ILatLng[]>;
    Object.keys(markerSet).forEach((userName) =>
    {
      if(markerSet[userName].length > 1)
      {
        _polyLineGroup[userName] = markerSet[userName].map(marker => marker.locations);
      }
    });

    setPolyLineGroup(_polyLineGroup);
  }, [markerSet]);

  return (
    <>
      {
        Object.keys(markerSet).map((key) =>
        {
          const polyLineLocationSet = markerSet[key];
          const polyLineArray = polyLineGroup ? polyLineGroup[key] ?? [] : [];

          const polylines = [];
          for(let i = 0; i < polyLineArray.length - 1; i++)
          {
            const startPoint = polyLineArray[i];
            const endPoint = polyLineArray[i + 1];
            const segmentStroke = polyLineLocationSet[i]?.polyLineStroke;

            polylines.push(
              <MapPolyLine
                key={`${key}_${i}`}
                polyLineArray={[startPoint, endPoint]}
                polyLineStroke={segmentStroke}
                polyLineColor={polyLineLocationSet[i].polyLineColor}
              />
            );
          }
          return polylines;
        })
      }
    </>
  );
}

function MapMarkerTooltip(props: {
  children?: React.ReactNode,
  position?: google.maps.LatLng
})
{
  const position = props.position;
  const children = props.children;

  if(!position || !children)
  {
    return null;
  }

  return (
    <MarkerTooltip
      position={position}
    >
      {children}
    </MarkerTooltip>
  );
}

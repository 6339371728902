import {ReactElement} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDivider} from "../../../../api/meta/base/dto/DefnFieldDivider";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {DefnStudioMapOfDynamicRule} from "../../../../api/meta/base/dto/DefnStudioMapOfDynamicRule";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickSpreadsheetId} from "../../../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {DefnStudioSetOfModule} from "../../../../api/meta/base/dto/DefnStudioSetOfModule";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioDetails} from "../../../../api/meta/base/dto/StudioDetails";
import {StudioDtoFormula} from "../../../../api/meta/base/dto/StudioDtoFormula";
import {StudioDtoLayoutFormContentReport} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentReport";
import {StudioField} from "../../../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioVar} from "../../../../api/meta/base/dto/StudioVar";
import {StudioVisibilityRule} from "../../../../api/meta/base/dto/StudioVisibilityRule";
import {StudioBuildArgBinderParamVariableType} from "../../../../api/meta/base/StudioSetsVarType";
import {SysId} from "../../../../api/meta/base/SysId";
import {ArtifactId} from "../../../../api/meta/base/Types";
import {EnumStudioCompType} from "../../../../api/meta/base/Types";
import {MetaIdLayoutFormContent} from "../../../../api/meta/base/Types";
import {MetaIdFormula} from "../../../../api/meta/base/Types";
import {EnumDefnPermission, EnumDefnThemeColor, EnumDefnThemeDividerKind, MetaIdComposite, MetaIdField, MetaIdForm, MetaIdRole, MetaIdSection, MetaIdVisibilityRule} from "../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../base/plus/ArgBinderPlus";
import {STR_MODULES} from "../../../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {FormStore} from "../../../../base/types/TypesForm";
import {TypeListSortType} from "../../../../base/types/TypesStudio";
import {EnumListItemDirection, IStudioSize} from "../../../../base/types/TypesStudio";
import {TypeStudioSetupKind} from "../../../../cache/studio/ent/TypesCacheStudioEnt";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import {EnumIconFormBuilder} from "../../../atom/icon/IconForm";

//Fields common properties
export const fieldKeyName = "name";
export const fieldKeyLabel = "label";
export const fieldKeyModulesSet = "modules";
export const fieldKeyDescription = "description";

export const fieldTimeDateTime = "fieldTimeDateTime";
export const fieldCustomDateTime = "customValueDateTime";
export const fieldGap = "gap";
export const fieldGap1 = "gap1";
export const fieldGap2 = "gap2";
export const fieldGap3 = "gap3";
export const fieldGap4 = "gap4";
export const fieldGap5 = "gap5";

export const fieldSpreadsheet = "spreadsheetId";
export const fieldReport = "reportId";
export const propKeyOutputFormContentLayoutId = "outputFormContentLayoutId";
export const propKeyOutputFormTemplateLayoutId = "outputFormTemplateLayoutId";
export const fieldSendMessageToInbox = "sendMessageToInbox";
export const fieldIncreaseAsideWidth = "increaseWitch";
export const fieldDoNotOpenAside = "doNotOpenAside";

export const compFilterLayoutMode = "filterLayoutMode";

//Tabs
export const fieldTabDetails = "details";
export const fieldTabSource = "source";
export const fieldTabProperties = "properties";
export const fieldTabPermissions = "permissions";
export const fieldActionTab = "action";
export const fieldLayoutTab = "layout";

export const fieldPropertiesSubTabId = "propertiesSubTabId";
export const fieldPropertiesField = "TabField";
export const fieldPropertiesVar = "TabVar";
export const fieldPropertiesConst = "TabConst";
export const fieldSourceSubTabId = "sourceSubTabId";
export const fieldSourceConstTab = "TabSourceConst";
export const fieldSourceVariableTab = "TabSourceVariable";
export const fieldSourcePluginTab = "TabSourcePlugin";

//Form visibility action
export const fieldVisibilityList = "visibilityRuleMap";
export const fieldVisibilityActionsIfTrue = "actionMapIfTrue";
export const fieldVisibilityActionsIfFalse = "actionMapIfFalse";
//Form visibility condition
export const fieldVisibilityCondMap = "visibilityCondMap";

//set of users condition
export const fieldSetOfUsersCondition = "setOfUserCondition";
export const fieldSetOfUsersFormId = "setOfUsersFormId";
//Form grid layout
export const fieldMapOfLayoutGrid = "layoutGridMap";
export const fieldDeeplinkDefaultLayoutFlexGrow = "fieldDeeplinkDefaultLayoutFlexGrow";
export const fieldAsideDefaultLayout = "asideDefaultLayout";
export const fieldIndexFieldNameVarId = "indexFieldNameVarId";

// Form content layout
export const fieldContentLayoutList = "contentLayoutMap";

// Form formula
export const fieldMapOfFormula = "formulaMap";

//Role permission tab
export const fieldPermission = "permission";
export const propKeyDefaultValueVar = "defaultVarId";
export const propKeyDefaultValueTextVar = "defaultVarId";
export const propKeyVarPlaceholder = "placeHolderVarId";
export const propKeyVarIcon = "iconVarId";
export const propKeyRequired = "required";
export const propKeyDisabled = "disabled";
export const propKeyAutoFocus = "autoFocus";
export const propKeyAutoFormat = "autoFormat";
export const propKeyAutoFill = "autoFill";
export const propKeyPickRatingVariant = "pickRatingKind";
export const propKeyVarHelperText = "helperTextVarId";
export const propKeyPrefixVar = "prefixVarId";
export const propKeyPrefix = "prefix";
export const propKeySuffixVar = "suffixVarId";
export const propKeySuffix = "suffix";
export const propKeyMinVar = "minVarId";
export const propKeyMaxVar = "maxVarId";
export const propKeyDisplayDateFormat = "displayDateFormat";
export const propKeyStep = "step";
export const propKeyStepVar = "stepVarId";
export const propKeyStepFieldId = "stepFieldId";
export const propKeyValidDomain = "validDomainVarId";
export const propKeyInvalidDomain = "invalidDomainVarId";
export const propKeyAutoPickSelf = "autoPickSelf";
export const propKeyCaptureTime = "captureTime";
export const propKeyCaptureUser = "captureUser";
export const propKeyCaptureMode = "captureMode";
export const propKeyMinCharacterCount = "minCharCountVarId";
export const propKeyMaxCharacterCount = "maxCharCountVarId";
export const propKeyLineCount = "lineCount";
export const propKeyLineCountVar = "lineCountVarId";
export const propKeyLineCountFieldId = "lineCountFieldId";
export const propKeyFlexHeight = "flexGrow";
export const propKeyShowSecond = "showSecond";
export const propKeyShowSecondVar = "showSecondVar";
export const propKeyShowSecondFieldId = "showSecondFieldId";
export const propKeyValidMobileCountryCodes = "validMobileCountryCodeSetVarId";
export const propKeyInvalidMobileCountryCodes = "invalidMobileCountryCodeSetVarId";
export const propKeyShowPreview = "showPreview";
export const propKeyShowPreviewVar = "showPreviewVarId";
export const propKeyShowPreviewFieldId = "showPreviewFieldId";
export const propKeyShowSize = "showSize";
export const propKeyShowSizeVar = "showSizeVarId";
export const propKeyShowSizeFieldId = "showSizeFieldId";
export const propKeyTextPatternVar = "textPatternVarId";
export const propKeySetOnSend = "setOnSend";
export const propKeyTextPattern = "textPattern";
export const propKeyTextPatternFieldId = "textPatternFieldId";
export const propKeyMaxSize = "maxSize";
export const propKeyMaxSizeVar = "maxSizeVarId";
export const propKeyMaxSizeFieldId = "maxSizeFieldId";
export const propKeyCaptureLocation = "captureLocation";
export const propKeySpreadsheetLayout = "layoutSpreadsheetId";
export const propKeyConditionVarId = "filterConditionVarId";
export const propKeyFieldKeySet = "keySet";
export const propKeyFieldRefreshOn = "refreshOn";
export const propKeyShowRefreshInMenu = "showRefreshInMenu";
export const propKeyShowRefreshButtonOn = "showRefreshButtonOn";
export const propKeyOverlayLayoutSpreadsheet = "overlayLayoutSpreadsheet";
export const propKeyOverlayLayoutGrid = "overlayLayoutGrid";
export const propKeyLookupFieldId = "lookupFieldId";

export const propKeyFromDefault = "fromDefault";
export const propKeyFromDefaultVarId = "fromDefaultVarId";
export const propKeyFromDefaultFieldId = "fromDefaultFieldId";
export const propKeyToDefault = "toDefault";
export const propKeyToDefaultVarId = "toDefaultVarId";
export const propKeyToDefaultFieldId = "toDefaultFieldId";

export const propKeyFromDefaultDateRangeCustom = "fromDefaultDateRangeCustom";
export const propKeyToDefaultDateRangeCustom = "toDefaultDateRangeCustom";
export const propKeyFromDefaultDateTimeRangeEnum = "fromDefaultDateTimeRangeEnum";
export const propKeyToDefaultDateTimeRangeEnum = "toDefaultDateTimeRangeEnum";

export const propKeyFromDefaultDateTimeRangeCustom = "fromDefaultDateTimeRangeCustom";
export const propKeyToDefaultDateTimeRangeCustom = "toDefaultDateTimeRangeCustom";
export const propKeyShowAsCheckBox = "showAsCheckbox";
export const propKeyShowAsCheckBoxVar = "showAsCheckboxVarId";
export const propKeyShowAsCheckboxFieldId = "showAsCheckboxFieldId";
export const propKeyPosition = "position";
export const propKeyPositionVar = "positionVarId";
export const propKeyLabelPlacement = "labelPlacement";
export const propKeyLabelPlacementVar = "labelPlacementVarId";
export const propKeyShowCapturedValuesOnAside = "showCapturedValuesOnAside";
export const propKeyRolesDataSource = "roleIdDataSource";
export const propKeyDefaultValueRoleId = "defaultValue";
export const propKeyRefUserFields = "copyFieldMap";
export const propKeyDataSourceVarId = "dataSourceVarId";
export const propKeyShowAs = "showAs";
export const propKeyDisplayProperty = "displayProperty";
export const propKeySourceVarId = "sourceVarId";
export const propKeyColorVar = "colorVarId";
export const propKeyDividerKindVar = "dividerKindVarId";
export const propKeyColor = "color";
export const propKeyDividerKind = "divider";
export const propKeyTextSize = "font";
export const propKeyTextSizeVar = "fontVarId";
export const propKeyTextSizeFieldId = "fontFieldId";
export const propKeyJustifyText = "justifyText";
export const propKeyJustifyTextVar = "justifyTextVarId";
export const propKeyShowCloseButton = "showCloseButton";
export const propKeyFlexGrow = "flexGrow";
export const propKeyShowBorder = "showBorder";
export const propKeyShowLabel = "showLabel";
export const propKeyShowLabelVarId = "showLabelVarId";
export const propKeyShowLabelFieldId = "showLabelFieldId";
export const propKeyTopPadding = "topPadding";
export const propKeyTopPaddingVar = "topPaddingVarId";
export const propKeyBottomPadding = "bottomPadding";
export const propKeyBottomPaddingVar = "bottomPaddingVarId";
export const propKeyLeftPadding = "leftPadding";
export const propKeyLeftPaddingVar = "leftPaddingVarId";
export const propKeyRightPadding = "rightPaddingVar";
export const propKeyRightPaddingVar = "rightPaddingVarId";
export const propKeyBgColorVarId = "bgColorVarId";
export const propKeyBgColor = "bgColor";
export const propKeyMetaIdPlugin = "metaIdPlugin";
export const propKeyPluginApiId = "pluginApiId";
export const propKeyInputFormMapping = "pluginInputFormMapping";
export const propKeyPickGridId = "gridId";
export const propKeyPickLayoutGridId = "gridLayoutId";
export const propKeyGridDisplayField = "gridDisplayFieldId";
export const propKeyShowAsDropdown = "showAsDropdown";
export const propKeyShowAsDropdownFieldId = "showAsDropdownFieldId";
export const propKeyShowAsDropdownVarId = "showAsDropdownVarId";
export const propKeyBold = "bold";
export const propKeyBoldVar = "boldVarId";
export const propKeyBoldFieldId = "boldFieldId";
export const propKeyItalic = "italic";
export const propKeyItalicVar = "italicVarId";
export const propKeyItalicFieldId = "italicFieldId";
export const propKeyUnderline = "underline";
export const propKeyUnderlineVar = "underlineVarId";
export const propKeyUnderlineFieldId = "underlineFieldId";
export const propKeyStrikeThrough = "strikeThrough";
export const propKeyStrikeThroughVar = "strikeThroughVarId";
export const propKeyStrikeThroughFieldId = "strikeThroughFieldId";
export const propKeyOpacity = "opacity";
export const propKeyOpacityVar = "opacityVarId";
export const propKeyOpacityFieldId = "opacityFieldId";
export const propKeyNumberOfDigitAfterPeriodVarId = "numberOfDigitAfterPeriodVarId";
export const propKeyNumberOfDigitAfterPeriodValue = "numberOfDigitAfterPeriod";
export const propKeyNumberOfDigitAfterPeriodFieldId = "numberOfDigitAfterPeriodFieldId";
export const propKeyVideoFormatType = "videoFormatType";
export const propKeyButtonKind = "kind";
export const propKeyRefCopyFields = "copyFieldMap";
export const propKeyDoNotInLine = "btnPosDoNotInline";
export const propKeyButtonVariant = "btnVariant";
export const propKeyBackgroundColor = "btnBackgroundColor";
export const propKeyAddToToolBar = "btnPosToolbar";
export const propKeyAddToFooter = "btnPosFooter";
export const propKeyAddToMenu = "btnPosMenu";
export const propKeyDisabledElevation = "disabledElevation";
export const propKeyDisabledElevationVar = "disableElevationVarId";
export const propKeyDisabledElevationFieldId = "disableElevationFieldId";
export const propKeyButtonVariantVar = "buttonVariantVarId";
export const propKeyButtonPosition = "buttonPosition";
export const propKeyButtonPositionVar = "buttonPositionVarId";
export const propKeyIconPosition = "iconPosition";
export const propKeyIconPositionVar = "iconPositionVarId";
export const propKeyBackgroundColorVar = "bgColorVarId";
export const propKeyActionDisableFieldIdArray = "actionDisableFieldIdArray";
export const propKeyActionClickButtonFieldIdArray = "actionClickButtonFieldIdArray";
export const propKeyActionFormSection = "actionFormSection";
export const propKeyActionEnableFieldIdArray = "actionEnableFieldIdArray";
export const propKeyActionInvisibleFieldIdArray = "actionInvisibleFieldIdArray";
export const propKeyActionVisibleFieldIdArray = "actionVisibleFieldIdArray";
export const propKeyActionSetDefaultFieldIdArray = "actionSetDefaultFieldIdArray";
export const propKeyActionToggleBooleanFieldIdArray = "actionToggleBooleanFieldIdArray";
export const propKeyActionOpenLinkInSameTabFieldId = "actionOpenLinkInSameTabFieldId";
export const propKeyActionOpenLinkInNewTabFieldId = "actionOpenLinkInNewTabFieldId";
export const propKeyActionOpenLinkInNewTabVarId = "actionOpenLinkInNewTabVarId";
export const propKeyActionOpenLinkInSameTabVarId = "actionOpenLinkInSameTabVarId";
export const propKeyActionLinkSection = "actionLinkSection";
export const propKeyActionCloseAside = "actionCloseAside";
export const fieldNormalButtonLabel = "normalButtonLabel";
export const invokePlugin = "invokePlugin";
export const fieldButtonActionCheckBox = "buttonActionCheckBox";
export const fieldButtonActionTabSection = "buttonActionTabSection";
export const propKeyExecuteActionsAfterTargetSuccess = "executeActionsAfterTargetSuccess";
export const propKeyActionPluginSection = "actionPluginSection";
export const propKeyTargetType = "targetType";
export const propKeyButtonActionId = "actionId";
export const propKeyActionGroupIdSet = "actionGroupIdSet";
export const propKeyActionMappingVarId = "actionMappingVarId";
export const propKeyButtonSpreadsheetId = "spreadsheetId";
export const propKeyButtonSpreadsheetMappingVarId = "spreadsheetMappingVarId";
export const propKeyButtonPluginInputMappingVarId = "pluginInputMappingVarId";
export const propKeyButtonPluginOutputMappingVarId = "pluginOutputMappingVarId";
export const propKeyButtonReportInputMappingVarId = "reportInputMappingVarId";
export const propKeyButtonReportOutputMappingVarId = "reportOutputMappingVarId";
export const propKeyKind = "kindVarId";
export const propKeyAudioFormat = "audioFormatType";
export const propKeyFileTypeSet = "fileTypeSet";
export const propKeyActionTab = "buttonActionTab";
export const propKeyForceLeafSelection = "forceLeafSelection";
export const propKeyIncludeAllChildrenInReport = "includeAllChildrenInReport";
export const propKeyPluginErrorField = "pluginErrorField";
export const propKeyPickTextInfo = "pickTextInfo";
export const propKeyPickTextNote = "pickTextNote";
export const propKeyBarCode = "barCode";
export const propKeyQrCode = "qrCode";
export const propKeyShowCode = "showCode";
export const propKeyAllowRangePicker = "allowRangePicker";
export const propKeyAllowDuplicate = "allowDuplicate";
export const propKeyShowAsInputBox = "showAsInputBox";
export const propKeyDynamicConditionLabel = "dynamicConditionLabel";
export const propKeyDynamicRuleMap = "ruleMap";
export const propKeyRefReportOutputFormGridId = "reportOutputFormGridId";
export const propKeyRefReportOutputFormGridLayoutId = "reportOutputFormGridLayoutId";
export const propKeyEditableFieldIdSet = "editableFieldIdSet";
export const propKeyCopyFields = "copyFieldMap";
export const propKeyForceOpenOnFormCreate = "forceOpenOnFormCreate";
export const propKeyCategoryFilterDisplayFieldId = "categoryFilterDisplayFieldId";
export const propKeyForceOpenOnGridRowCreate = "forceOpenOnGridRowCreate";
export const propKeyCanCreateRefRecord = "canCreateRefRecord";
export const ASIDE_FOOTER_BUTTON_KIND = "apply";
export const propKeyRequiredField = "requiredFieldId";
export const propKeyDisabledField = "disabledFieldId";
export const propKeyDefaultFieldId = "defaultFieldId";
export const propKeyDefaultOptionFieldId = "defaultOptionFieldId";
export const propKeyPlaceHolderFieldId = "placeHolderFieldId";
export const propKeyHelperTextFieldId = "helperTextFieldId";
export const propKeyMinCharCountFieldId = "minCharCountFieldId";
export const propKeyMaxCharCountFieldId = "maxCharCountFieldId";
export const propKeyMinFieldId = "minFieldId";
export const propKeyMaxFieldId = "maxFieldId";
export const propKeyRequiredVarId = "requiredVarId";
export const propKeyDisabledVarId = "disabledVarId";
export const propKeyDefaultValue = "defaultValue";
export const propKeyDefaultOptionId = "defaultOptionId";
export const propKeyDefaultValueCustom = "defaultValueDate";
export const propKeyDefaultValueDateTime = "defaultValueDateTime";
export const propKeyDefaultValueTime = "defaultValueTime";
export const propKeyDefaultRoleId = "defaultRoleId";
export const propKeyDefaultRoleIdSet = "defaultRoleIdSet";
export const propKeyDefaultRoleFieldId = "defaultRoleFieldId";

export const propKeyPlaceHolderValue = "placeHolderValue";
export const propKeyIconValue = "IconValue";
export const propKeyHelperTextValue = "helperTextValue";
export const propKeyMinCharCount = "minCharCount";
export const propKeyMaxCharCount = "maxCharCount";
export const propKeyMin = "min";
export const propKeyMax = "max";
export const propKeyMaxCustomDate = "maxCustomDate";
export const propKeyMinCustomDate = "minCustomDate";

export const propKeyMaxCustomDateTime = "maxCustomDateTime";
export const propKeyMinCustomDateTime = "minCustomDateTime";
export const propKeyMinCustomTime = "minCustomTime";
export const propKeyMaxCustomTime = "maxCustomTime";

export const fieldValueDefaultTimeEnum = "defaultTimeEnum";
export const fieldValueMinTimeEnum = "minTimeEnum";
export const fieldValueMaxTimeEnum = "maxTimeEnum";

export const propKeyMaxCustomDateRange = "maxCustomDateRange";
export const propKeyMinCustomDateRange = "minCustomDateRange";

export const propKeyMaxCustomDateTimeRange = "maxCustomDateTimeRange";
export const propKeyMinCustomDateTimeRange = "minCustomDateTimeRange";
export const propKeyMaxCustomDateTimeRangeTime = "maxCustomDateTimeRangeTime";
export const propKeyMinCustomDateTimeRangeTime = "minCustomDateTimeRangeTime";
export const propKeyDisplayTextVarId = "displayTextVarId";
export const propKeyHyperlinkFieldIdSet = "hyperlinkFieldIdSet";
export const propKeyBoolDefaultValue = "boolDefaultValue";
export const propKeyTextValidationPattern = "validationPattern";
export const propKeyTextValidationPatternCustomValue = "customValue";
export const propKeyToolTip = "toolTip";

export const fieldLayoutGridId = "layoutGridId";

export interface IRolePermission
{
  metaIdRole: MetaIdRole,
  permission: EnumDefnPermission
}

export function getFieldGap(compGapId: string, dividerKindVar?: EnumDefnThemeDividerKind, color?: EnumDefnThemeColor)
{
  return {
    [compGapId]: {
      type: "divider",
      name: compGapId,
      metaId: compGapId,
      colorVar: color,
      dividerKindVar: dividerKindVar ?? "thin"
    } as DefnFieldDivider
  };
}

function getStudioDetailsExtraDefn(
  fieldType?: EnumStudioCompType,
  formId?: MetaIdForm,
  overrideCompositeIdSet?: MetaIdComposite[],
  compositeId?: MetaIdComposite,
  isRefChild?: boolean
)
{
  switch(fieldType)
  {
    case "dynamic":
      return {
        ...getFieldGap(fieldGap1, "thick"),

        [propKeyDynamicConditionLabel]: {
          type: "label",
          metaId: propKeyDynamicConditionLabel,
          name: propKeyDynamicConditionLabel,
          label: "Rules",
          disabled: true
        } as DefnFieldLabel,

        [propKeyDynamicRuleMap]: {
          type: "studioMapOfDynamicRule",
          metaId: propKeyDynamicRuleMap,
          name: propKeyDynamicRuleMap,
          label: "Dynamic rule",
          formId: formId,
          compositeIdSet: overrideCompositeIdSet
        } as DefnStudioMapOfDynamicRule
      };
    case "identifier":
      return {
        ...getFieldGap(fieldGap2, "thick"),

        [propKeyTextPatternVar]: {
          type: "studioVarIdTextEditor",
          metaId: propKeyTextPatternVar,
          label: "Text pattern",
          argBinderFormId: formId,
          showAsEdit: true,
          filterVarKindSet: ["text"],
          argBinderFilterVarKindSet: [...StudioBuildArgBinderParamVariableType, "sequence"]
        } as DefnStudioVarIdTextEditor,

        ...!isRefChild && {
          ...getFieldGap(fieldGap3, "thick"),

          [propKeySetOnSend]: {
            type: "bool",
            name: propKeySetOnSend,
            metaId: propKeySetOnSend,
            label: "Set on send",
            showAsCheckboxVar: true
          } as DefnFieldSwitch
        }
      };
    case "hyperlinkRow":
      return {
        ...getFieldGap(fieldGap2, "thick"),

        [fieldSpreadsheet]: {
          type: "pickSpreadsheetId",
          metaId: fieldSpreadsheet,
          name: fieldSpreadsheet,
          label: "Spreadsheet"
        } as DefnStudioPickSpreadsheetId,

        [propKeyDisplayTextVarId]: {
          type: "studioVarIdTextEditor",
          metaId: propKeyDisplayTextVarId,
          name: propKeyDisplayTextVarId,
          label: "Display text",
          filterVarKindSet: ["text"],
          showAsEdit: true,
          argBinderFormId: formId
        } as DefnStudioVarIdTextEditor,

        [propKeyHyperlinkFieldIdSet]: {
          type: "studioSetOfFieldId",
          metaId: propKeyHyperlinkFieldIdSet,
          name: propKeyHyperlinkFieldIdSet,
          label: "Hyperlink fields",
          compositeIdSet: compositeId
            ? [compositeId]
            : [],
          formId: formId
        } as DefnStudioPickFieldId
      };
  }
}

export function getStudioDetailsCompMap(
  labelHelperTextName?: string,
  showLabelHelperTextName?: boolean,
  excludeLabelField?: boolean,
  fieldType?: EnumStudioCompType,
  formId?: MetaIdForm,
  overrideCompositeIdSet?: MetaIdComposite[],
  compositeId?: MetaIdComposite,
  isRefChild?: boolean
)
{
  return {
    [fieldKeyName]: {
      type: "symbol",
      metaId: fieldKeyName,
      name: "Name",
      required: true
    } as DefnFieldText,

    ...!excludeLabelField && {
      [fieldKeyLabel]: {
        type: "text",
        metaId: fieldKeyLabel,
        name: "Label",
        helperTextVar: (showLabelHelperTextName && labelHelperTextName)
          ? stringToDefnDtoText(`Label: ${toLabel(labelHelperTextName)}`)
          : undefined
      } as DefnFieldText
    },

    [fieldKeyModulesSet]: {
      type: "studioSetOfModule",
      metaId: fieldKeyModulesSet,
      name: toLabel(STR_MODULES)
    } as DefnStudioSetOfModule,

    [fieldKeyDescription]: {
      type: "paragraph",
      metaId: fieldKeyDescription,
      name: "Description",
      lineCount: 4
    } as DefnFieldParagraph,

    ...getStudioDetailsExtraDefn(
      fieldType,
      formId,
      overrideCompositeIdSet,
      compositeId,
      isRefChild
    )
  };
}

export function getStudioDetailsToValue(studioDetails: StudioDetails)
{
  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", studioDetails.name),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", studioDetails?.label),
    [fieldKeyModulesSet]: fnRawValueToFieldValue("studioSetOfModule", studioDetails?.modules),
    [fieldKeyDescription]: fnRawValueToFieldValue("paragraph", studioDetails?.description)
  };
}

export function getStudioDetailsToDto(fieldValues: FieldValues, studioDetails?: StudioDetails)
{
  return {
    ...studioDetails,
    name: fnFieldValueToRawValue("symbol", fieldValues[fieldKeyName]) || studioDetails?.name,
    label: fnFieldValueToRawValue("text", fieldValues[fieldKeyLabel]),
    modules: fnFieldValueToRawValue("studioSetOfModule", fieldValues[fieldKeyModulesSet]),
    description: fnFieldValueToRawValue("paragraph", fieldValues[fieldKeyDescription])
  } as StudioDetails;
}

export type FormBuilderItemVariant =
  | "composite"
  | "studioVar"
  | "field"
  | "form"
  | "formula"
  | "visibilityRule"
  | "contentLayout"
  | "defaultAsideLayout";

export interface IFormBuilderProps
{
  formStore: FormStore,
  studioSize: IStudioSize,
  selectList: SelectList,
  selectListMap: Record<MetaIdField, SelectList>,
  setupMode?: TypeStudioSetupKind,
  footerLeftIcons?: EnumIconFormBuilder[],
  footerRightIcons?: EnumIconButton[],
  readOnly?: boolean,
  isPluginForm?: boolean,
  callerPermission?: ICallerPermission,
  cbOnSubmit?: CbOnSubmitBuilderItem,
  cbOnRemove?: CbOnRemoveBuilderItem,
  cbOnMoveItem?: CbOnMoveBuilderItem,
  cbOnDragItem?: CbOnDragBuilderItem,
  cbOnShowVar?: (varId: MetaIdField) => void,
  cbOnAddNewVar?: (studioVar: StudioVar) => void;
  cbOnEditVar?: (variable: StudioVar, cbOnClose: () => void) => ReactElement;
  cbGetCliCode?: (artifactId: ArtifactId, metaId?: SysId) => void
}

interface ISubmitField
{
  field: StudioField,
  compositeId: MetaIdComposite,
  headerFooterId?: MetaIdSection,
}

export interface IFormBuilderSubmitItem
{
  formId: MetaIdForm,
  form?: StudioForm,
  composite?: StudioComposite,
  field?: ISubmitField,
  studioVar?: StudioVar,
  visibilityRule?: StudioVisibilityRule,
  contentLayout?: StudioDtoLayoutFormContentReport,
  formula?: StudioDtoFormula,
  isDuplicate?: boolean,
  defaultContentLayout?: MetaIdLayoutFormContent,
}

export interface IFormBuilderRemoveItem
{
  compositeId?: MetaIdComposite,
  fieldId?: MetaIdField,
  formulaId?: MetaIdFormula,
  visibilityRuleId?: MetaIdVisibilityRule,
  contentLayoutId?: MetaIdLayoutFormContent
}

export interface IFormBuilderMoveItem
{
  direction: EnumListItemDirection,
  formulaId?: MetaIdFormula,
  visibilityRuleId?: MetaIdVisibilityRule,
  contentLayoutId?: MetaIdLayoutFormContent,
  compositeId?: MetaIdComposite,
  headerFooterId?: MetaIdSection,
  fieldId?: MetaIdField
}

export type CbOnSubmitBuilderItem = (
  variant: FormBuilderItemVariant,
  item: IFormBuilderSubmitItem) => void;

export type CbOnRemoveBuilderItem = (
  variant: FormBuilderItemVariant,
  item: IFormBuilderRemoveItem,
  preventTrashRemove?: boolean
) => void;

export type CbOnMoveBuilderItem = (
  variant: FormBuilderItemVariant,
  item: IFormBuilderMoveItem
) => void;

export type CbOnDragBuilderItem = (
  variant: FormBuilderItemVariant,
  itemId:
    | MetaIdFormula
    | MetaIdVisibilityRule
    | MetaIdLayoutFormContent
    | MetaIdComposite
    | MetaIdSection
    | MetaIdField,
  dragStartIndex: number,
  dragEndIndex: number,
  fromCompId?: MetaIdComposite,
  fromCompIndex?: number,
  toCompId?: MetaIdComposite,
  toCompIndex?: number
) => void;

export interface IFormBuilderItemUserField
{
  showMoveUp: boolean,
  showMoveDown: boolean,
  variant: FormBuilderItemVariant,
  item: StudioComposite | StudioField
}

interface ICallerPermissionMode
{
  show: boolean,
  edit: boolean,
}

export interface ICallerPermission
{
  formEditDetails: ICallerPermissionMode,
  formEditContentLayout: ICallerPermissionMode,
  formEditVisibility: ICallerPermissionMode,
  formEditFormulas: ICallerPermissionMode,
  forms: ICallerPermissionMode,
  formEditPaymentConfig: ICallerPermissionMode,
}

type TypeDtoFormSetKey =
  | "compositeList"
  | "visibilityList"
  | "layoutContentList"
  | "formulaList"

export type TypeActionSortFormList = {
  setKey: TypeDtoFormSetKey,
  sortType: TypeListSortType
};

import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnLayoutGridLocmap} from "../../api/meta/base/dto/DefnLayoutGridLocmap";
import {FieldValueColor} from "../../api/meta/base/dto/FieldValueColor";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {EnumDefnThemeStroke} from "../../api/meta/base/Types";
import {EnumDefnMapPinShape} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {IMarkerTooltip} from "../../nucleus/googleMap/MapMarkerTooltip";
import {getFormFieldValueAsText} from "./FieldValuePlus";
import {fnFieldValueToRawValue} from "./FieldValuePlus";

export interface IMarkerValue
{
  locationFieldValue?: FieldValueLocation,
  colorFieldValue?: FieldValueColor,
  toolTipFieldValue?: IMarkerTooltip,
  shapeFieldValue?: EnumDefnMapPinShape,
  strokeFieldValue?: EnumDefnThemeStroke,
  groupByFieldValue?: any
}

export function getMarkerValueFromValueMap(
  compMap: Record<MetaIdField, DefnComp>,
  valueMap?: Record<MetaIdField, any>,
  locmap?: DefnLayoutGridLocmap,
  toolTipFieldId?: MetaIdField
): IMarkerValue
{

  const locationFieldId = locmap?.locationFieldId;
  const colorFieldId = locmap?.colorFieldId;
  const shapeFieldId = locmap?.shapeFieldId;
  const strokeFieldId = locmap?.strokeFieldId;
  const groupByFieldId = locmap?.groupByFieldId;

  const locationFieldValue = (valueMap && locationFieldId)
    ? valueMap[locationFieldId] as FieldValueLocation
    : undefined;

  const colorFieldValue = (valueMap && colorFieldId)
    ? valueMap[colorFieldId] as FieldValueColor
    : undefined;

  const toolTipFieldValue = (valueMap && toolTipFieldId)
    ? {
      showTooltip: !!toolTipFieldId,
      value: toolTipFieldId
        ? getToolTipFieldValue(toolTipFieldId, valueMap, compMap)
        : undefined
    } as IMarkerTooltip
    : undefined;

  const shapeFieldValue = valueMap && shapeFieldId
    ? valueMap[shapeFieldId] as EnumDefnMapPinShape
    : undefined;

  const strokeFieldValue = valueMap && strokeFieldId
    ? valueMap[strokeFieldId] as EnumDefnThemeStroke
    : undefined;

  const groupByFieldValue = (valueMap && groupByFieldId)
    ? getGroupByFieldValue(groupByFieldId, valueMap, compMap)
    : undefined;

  return {
    locationFieldValue: locationFieldValue,
    colorFieldValue: colorFieldValue,
    toolTipFieldValue: toolTipFieldValue,
    shapeFieldValue: shapeFieldValue,
    strokeFieldValue: strokeFieldValue,
    groupByFieldValue: groupByFieldValue
  } as IMarkerValue;
}

function getGroupByFieldValue(
  groupByFieldId: MetaIdField,
  valueMap: Record<MetaIdField, any>,
  compMap: Record<MetaIdField, DefnComp>)
{
  const comp = compMap[groupByFieldId];
  const value = valueMap[groupByFieldId];

  if(comp && value)
  {
    return fnFieldValueToRawValue(comp.type, value);
  }
}

function getToolTipFieldValue(
  tooltipFieldId: MetaIdField,
  valueMap: Record<MetaIdField, any>,
  compMap: Record<MetaIdField, DefnComp>)
{
  const comp = compMap[tooltipFieldId];
  const value = valueMap[tooltipFieldId];

  if(comp && value)
  {
    if(comp.type === "pickUser" || comp.type === "userId")
    {
      return getFormFieldValueAsText(comp, value);
    }
    else
    {
      return fnFieldValueToRawValue(comp.type, value);
    }
  }
}



import {SigGroupInfo} from "../../../../api/home/aside/sig/SigGroupInfo";
import {SigUserAvatar} from "../../../../api/home/drawer/sig/SigUserAvatar";
import {isGlobal} from "../../../../api/meta/base/ApiPlus";
import {isNonGlobalEntId} from "../../../../api/meta/base/ApiPlus";
import {GroupId} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {STR_YOU} from "../../../../base/plus/ConstantsPlus";
import {STR_ADMIN} from "../../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {getImageThumbnail} from "../../../../base/plus/MediaPlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IList} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListBinder} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {comboToEntId} from "../../../../base/types/TypesComboId";
import {toComboId} from "../../../../base/types/TypesComboId";
import {TypeComboIdChat} from "../../../../base/types/TypesComboId";
import {TypeComboId} from "../../../../base/types/TypesComboId";
import {TypeUserField} from "../../../../base/types/TypesGlobal";
import {getCallerEntUserId} from "../../../../Store";
import {textUserOrYou} from "../../../../Store";
import {getCallerEntUserIdGlobal} from "../../../../Store";
import {store} from "../../../../Store";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";

export type TypeSigUserAvatar = SigUserAvatar | undefined

export const LOAD_MORE_ITEM_ID = "Footer~~Item~~Id";

function isReservedItemId(itemId: TypeListItemId): boolean
{
  return itemId === LOAD_MORE_ITEM_ID;
}

export default class SrvcHomeAsideGroupInfoSearchMembers extends ISrvc
{
  subscribe(entChatId: TypeComboId, unsubscribe?: boolean): void
  {
    const subscriberId = this.getSubscriberId();
    Srvc.app.pubsub.homeAvatar(subscriberId, entChatId, unsubscribe);
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectAvatar.bind(this),
      onBindSourceItem1: this.bindAvatar.bind(this)
    } as IListBinder<TypeSigUserAvatar>;
  }

  load(listName: string, entId: EntId, groupId: GroupId, sig: SigGroupInfo)
  {
    const rootState = store.getState();
    const itemIds = [] as TypeComboId[];
    const itemsById = {} as IListItemsById;
    const caller = rootState.cache.app.caller;
    const callerId = getCallerEntUserIdGlobal(rootState);
    const callerEntUserId = getCallerEntUserId(rootState, entId);
    const groupMemberMap = sig.memberMap;
    const groupAdminMap = sig.adminMap;
    const groupMemberKeys = Object.keys(groupMemberMap);
    const groupAdminKeys = Object.keys(groupAdminMap);
    const userComboId = toComboId(sig.entId, callerId);
    const callerEntComboId = toComboId(sig.entId, callerEntUserId);
    const entChatId = toComboId(sig.entId, sig.groupId);

    if(isGlobal(sig.entId))
    {
      itemIds.push(userComboId);
    }
    else
    {
      //for enterprise group userComboId id push to top
      itemIds.push(callerEntComboId);
    }

    itemsById[userComboId] = {
      type: "aps",
      hideMenu: true,
      avatarLeft: {
        icon: "user",
        src: getImageThumbnail(caller.callerMediaIdAvatar)
      },
      primary: {
        text: STR_YOU,
        caption: {
          text: groupAdminKeys.includes(callerId) ? STR_ADMIN : "",
          color: "success",
          ignoreSelection: true
        }
      },
      secondary: {
        text: caller.callerAbout
      },
      version: caller.callerVersion,
      ignoreSelection: true

    } as IListItemAPSA;

    if(groupMemberKeys.length)
    {
      // admin members
      groupAdminKeys.forEach((prefix) =>
      {
        const adminComboId = toComboId(sig.entId, prefix);
        if(prefix !== callerId)
        {
          if(adminComboId !== callerEntComboId)
          {
            itemIds.push(adminComboId);
            itemsById[adminComboId] = {
              type: "aps"
            } as IListItemAPSA;
          }
        }
      });

      // other members
      groupMemberKeys.forEach((prefix) =>
      {
        const memberComboId = toComboId(sig.entId, prefix);

        if(!groupAdminKeys.includes(prefix) && prefix !== callerId)
        {
          if(memberComboId !== callerEntComboId)
          {
            itemIds.push(memberComboId);
            itemsById[memberComboId] = {
              type: "aps"
            } as IListItemAPSA;
          }
        }
      });
    }

    dispatchList(listName, listRefresh({
      version: sig.version,
      itemIds: itemIds,
      itemsById: itemsById,
      userField: {value: entChatId} as TypeUserField
    } as IList));

    this.onPostLoad(listName, itemIds, itemsById);
  }

  protected onPostLoad(listName: string, itemIds: TypeComboId[], itemsById: IListItemsById)
  {
    // override when required
  }

  protected getSubscriberId(): string
  {
    return "SrvcHomeAsideGroupInfoSearchMembers";
  }

  protected selectAvatar(state: RootState, entChatId: TypeComboId): TypeSigUserAvatar
  {
    return isReservedItemId(entChatId) ? undefined : state.cache.app.user.userAvatarMap[entChatId as EntUserId];
  }

  protected bindAvatar(listName: string, entChatId: TypeComboId, avatar?: TypeSigUserAvatar): void
  {
    if(isReservedItemId(entChatId))
    {
      return;
    }

    if(avatar)
    {
      const rootState = store.getState();
      const sig = avatar as SigUserAvatar;
      const entGroupId = rootState.home.aside.groupInfo.memberList.userField?.value as TypeComboIdChat;

      const groupInfo = rootState.cache.app.group.groupInfo[entGroupId];

      const groupFlag = Object.keys(groupInfo.adminMap);

      const getMemberAvtarSrc = (entGroupId: string) =>
      {
        let avtarSrc: string | undefined;
        if(isNonGlobalEntId(entGroupId))
        {
          const entId = comboToEntId(entGroupId);
          const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];
          avtarSrc = getMediaSrc(entAvatar?.avatarId);
        }
        else
        {
          avtarSrc = getImageThumbnail(sig.mediaIdAvatar);
        }

        return avtarSrc;
      };

      const memberAvtarSrc = getMemberAvtarSrc(entGroupId);

      const callerId = getCallerEntUserIdGlobal(rootState);

      const newItem = {
        version: sig.version,
        type: "aps",
        avatarLeft: {
          src: memberAvtarSrc,
          icon: "user"
        },
        primary: {
          text: textUserOrYou(rootState, sig)
        },
        secondary: {
          text: sig.about,
          optional: sig.handle
        },
        userField: {value: sig.entUserId} as TypeUserField,
        hideMenu: !groupFlag.includes(callerId)
      } as IListItemAPSA;

      if(groupFlag.includes(sig?.entUserId))
      {
        newItem.primary = {
          text: textUserOrYou(rootState, sig),
          caption: {
            text: groupFlag.includes(sig?.entUserId) ? "Admin" : "",
            color: "success",
            ignoreSelection: true
          }
        };
      }

      if(callerId === sig.entUserId)
      {
        newItem.hideMenu = true;
      }

      dispatchList(listName, listSetItem({
        itemId: entChatId,
        newItem: newItem
      }));
    }
  }
}

import {nextMetaIdLayoutFormContent} from "../../../../api/meta/base/ApiPlus";
import {nextMetaIdFormula} from "../../../../api/meta/base/ApiPlus";
import {nextMetaIdVisibilityRule} from "../../../../api/meta/base/ApiPlus";
import {nextMetaIdField, nextMetaIdForm, nextMetaIdGrid, nextMetaIdSection} from "../../../../api/meta/base/ApiPlus";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioDtoFormula} from "../../../../api/meta/base/dto/StudioDtoFormula";
import {StudioDtoLayoutFormContentReport} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentReport";
import {StudioField} from "../../../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVisibilityRule} from "../../../../api/meta/base/dto/StudioVisibilityRule";
import {MetaIdLayoutFormContent} from "../../../../api/meta/base/Types";
import {MetaIdFormula} from "../../../../api/meta/base/Types";
import {EntId, MetaIdComposite, MetaIdField, MetaIdForm, MetaIdSection, MetaIdVisibilityRule} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {TypeStudioFilterType} from "../../../../base/plus/StudioPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {EnumListItemDirection} from "../../../../base/types/TypesStudio";
import {FormBuilderItemVariant} from "../../../../nucleus/form/builder/base/TypesFormBuilder";
import {store} from "../../../../Store";
import {dispatchEnt} from "../../SrvcCacheStudio";
import {addEntDefaultAsideLayout} from "../SliceCacheStudioEnt";
import {dragFormItem} from "../SliceCacheStudioEnt";
import {setEntUsageFilter} from "../SliceCacheStudioEnt";
import {removeEntContentLayoutMap} from "../SliceCacheStudioEnt";
import {addEntContentLayoutMap} from "../SliceCacheStudioEnt";
import {removeEntFormFormula} from "../SliceCacheStudioEnt";
import {addEntFormFormula} from "../SliceCacheStudioEnt";
import {addEntForm, addEntFormComposite, addEntFormField, addEntVisibilityRuleMap, moveEntItem, moveFormItem, removeEntForm, removeEntFormComposite, removeEntFormField, removeEntVisibilityRuleMap} from "../SliceCacheStudioEnt";
import {ActionDragFormItem} from "../TypesCacheStudioEnt";
import {ActionMoveFormItem} from "../TypesCacheStudioEnt";

export default class SrvcCacheStudioEntForms extends ISrvc
{
  //region Form
  addEntForm(entId: EntId, form: StudioForm)
  {
    dispatchEnt(entId, addEntForm({
      entId: entId,
      sig: {
        ...form,
        metaId: form.metaId || nextMetaIdForm()
      }
    }));
  }

  removeEntForm(entId: EntId, formId: MetaIdForm)
  {
    dispatchEnt(entId, removeEntForm({
      entId: entId,
      metaId: formId
    }));

  }

  duplicateEntForm(entId: EntId, formId: MetaIdForm)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId].ent.formMap;
    if(!formMap)
    {
      return;
    }

    const index = formMap.keys.indexOf(formId);
    if(index === -1)
    {
      return;
    }

    const form = formMap.map[formId]
      ? JSON.parse(updateAllMetaIds(JSON.stringify(formMap.map[formId])))
      : undefined;

    dispatchEnt(entId, addEntForm({
      entId: entId,
      sig: form,
      insertIndex: index + 1
    }));
  }

  setUsageFilter(entId: EntId, filterType: TypeStudioFilterType)
  {
    store.dispatch(setEntUsageFilter({
      entId: entId,
      formUsageFilter: filterType
    }));
  }

  //endregion

  //region Section
  addEntFormComposite(
    entId: EntId,
    formId: MetaIdForm,
    studioComposite: StudioComposite)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId]?.ent?.formMap;
    if(!formMap)
    {
      return;
    }

    if(studioComposite.type === "section" && !(studioComposite as StudioSection).metaId)
    {
      (studioComposite as StudioSection).metaId = nextMetaIdSection();
    }
    else if(studioComposite.type === "grid" && !(studioComposite as StudioGrid).metaId)
    {
      (studioComposite as StudioGrid).metaId = nextMetaIdGrid();
    }

    dispatchEnt(entId, addEntFormComposite({
      entId: entId,
      formId: formId,
      sig: studioComposite
    }));
  }

  removeEntFormComposite(entId: EntId, formId: MetaIdForm, compositeId: MetaIdComposite)
  {
    dispatchEnt(entId, removeEntFormComposite({
      entId: entId,
      formId: formId,
      metaId: compositeId
    }));
  }

  duplicateEntFormComposite(entId: EntId, formId: MetaIdForm, compositeId: MetaIdComposite)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId].ent.formMap;
    if(!formMap)
    {
      return;
    }
    const form = formMap.map[formId];

    const index = form?.compositeMap.keys.indexOf(compositeId) ?? -1;
    if(index === -1)
    {
      return;
    }

    const composite = form.compositeMap.map[compositeId];

    const entComposite = composite
      ? JSON.parse(updateAllMetaIds(JSON.stringify(composite)))
      : undefined;

    dispatchEnt(entId, addEntFormComposite({
      entId: entId,
      sig: entComposite,
      formId: formId,
      insertIndex: index + 1
    }));
  }

  // endregion

  //region Field

  addEntFormField(
    entId: EntId,
    formId: MetaIdForm,
    compositeId: MetaIdComposite,
    studioField: StudioField)
  {
    if(!studioField.metaId)
    {
      studioField.metaId = nextMetaIdField();
    }
    dispatchEnt(entId, addEntFormField({
      entId: entId,
      formId: formId,
      parentCompositeId: compositeId,
      sig: studioField
    }));
  }

  removeEntFormField(
    entId: EntId,
    formId: MetaIdForm,
    metaIdSection: MetaIdField,
    metaIdField: MetaIdField,
    preventTrashRemove?: boolean
  )
  {
    dispatchEnt(entId, removeEntFormField({
      entId: entId,
      formId: formId,
      parentCompositeId: metaIdSection,
      metaId: metaIdField,
      preventTrashRemove: preventTrashRemove
    }));
  }

  duplicateEntFormField(
    entId: EntId,
    formId: MetaIdForm,
    compositeId: MetaIdComposite,
    fieldId: MetaIdField)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId].ent.formMap;
    if(!formMap)
    {
      return;
    }
    const form = formMap.map[formId];

    const composite = form.compositeMap.map[compositeId];

    const index = composite?.fieldMap.keys.indexOf(fieldId);
    if(index === -1)
    {
      return;
    }
    const field = composite?.fieldMap?.map[fieldId];

    const entField = field
      ? JSON.parse(updateAllMetaIds(JSON.stringify(field)))
      : undefined;

    dispatchEnt(entId, addEntFormField({
      entId: entId,
      formId: formId,
      parentCompositeId: compositeId,
      sig: entField,
      insertIndex: index + 1
    }));
  }

  // endregion

  //region Visibility
  addEntFormVisibilityRule(entId: EntId, formId: MetaIdForm, studioVisibilityRule: StudioVisibilityRule)
  {
    dispatchEnt(entId, addEntVisibilityRuleMap({
      entId: entId,
      formId: formId,
      sig: {
        ...studioVisibilityRule,
        metaId: studioVisibilityRule.metaId || nextMetaIdVisibilityRule()
      }
    }));
  }

  addEntDefaultAsideLayout(entId: EntId, formId: MetaIdForm, defaultAsideLayout?: MetaIdLayoutFormContent)
  {
    dispatchEnt(entId, addEntDefaultAsideLayout({
      entId: entId,
      formId: formId,
      defaultAsideLayout: defaultAsideLayout
    }));
  }

  removeEntFormVisibilityRule(entId: EntId, formId: MetaIdForm, metaId: MetaIdVisibilityRule)
  {
    dispatchEnt(entId, removeEntVisibilityRuleMap({
      entId: entId,
      metaId: metaId,
      formId: formId
    }));
  }

  duplicateEntFormVisibilityRule(entId: EntId, formId: MetaIdForm, studioVisibilityRuleId: MetaIdVisibilityRule)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId].ent.formMap;
    if(!formMap)
    {
      return;
    }
    const form = formMap.map[formId];

    const index = form?.visibilityRuleMap.keys.indexOf(studioVisibilityRuleId);
    if(index === -1)
    {
      return;
    }
    const visibilityRule = form.visibilityRuleMap.map[studioVisibilityRuleId];

    const entVisibilityRule = visibilityRule
      ? JSON.parse(updateAllMetaIds(JSON.stringify(visibilityRule)))
      : undefined;

    dispatchEnt(entId, addEntVisibilityRuleMap({
      entId: entId,
      sig: entVisibilityRule,
      formId: formId,
      insertIndex: index + 1
    }));
  }

  // endregion

  //region content layout
  addEntFormContentLayout(
    entId: EntId,
    formId: MetaIdForm,
    studioFormContentLayout: StudioDtoLayoutFormContentReport)
  {
    dispatchEnt(entId, addEntContentLayoutMap({
      entId: entId,
      formId: formId,
      sig: {
        ...studioFormContentLayout,
        metaId: studioFormContentLayout.metaId || nextMetaIdLayoutFormContent()
      }
    }));
  }

  removeEntFormContentLayout(entId: EntId, formId: MetaIdForm, metaId: MetaIdLayoutFormContent)
  {
    dispatchEnt(entId, removeEntContentLayoutMap({
      entId: entId,
      metaId: metaId,
      formId: formId
    }));
  }

  duplicateEntFormContentLayout(entId: EntId, formId: MetaIdForm, studioContentLayoutId: MetaIdLayoutFormContent)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId].ent.formMap;

    if(!formMap)
    {
      return;
    }
    const form = formMap.map[formId];

    const index = form?.layoutMap?.keys.indexOf(studioContentLayoutId) ?? -1;
    if(index === -1)
    {
      return;
    }
    const contentLayout = form?.layoutMap?.map[studioContentLayoutId];

    const entContentLayout = contentLayout
      ? JSON.parse(updateAllMetaIds(JSON.stringify(contentLayout)))
      : undefined;

    dispatchEnt(entId, addEntContentLayoutMap({
      entId: entId,
      sig: entContentLayout,
      formId: formId,
      insertIndex: index + 1
    }));
  }

  // endregion

  //region Formulas
  addEntFormFormula(entId: EntId, formId: MetaIdForm, studioFormula: StudioDtoFormula)
  {
    dispatchEnt(entId, addEntFormFormula({
      entId: entId,
      formId: formId,
      sig: {
        ...studioFormula,
        metaId: studioFormula.metaId || nextMetaIdFormula()
      }
    }));
  }

  removeEntFormFormula(entId: EntId, formId: MetaIdForm, metaId: MetaIdFormula)
  {
    dispatchEnt(entId, removeEntFormFormula({
      entId: entId,
      metaId: metaId,
      formId: formId
    }));
  }

  duplicateEntFormFormula(entId: EntId, formId: MetaIdForm, formulaId: MetaIdFormula)
  {
    const rootState = store.getState();
    const formMap = rootState.cache.studio.ent.entMap[entId].ent.formMap;
    if(!formMap)
    {
      return;
    }
    const form = formMap.map[formId];

    const index = form?.formulaMap.keys?.indexOf(formulaId) ?? -1;
    if(index === -1)
    {
      return;
    }
    const formula = form.formulaMap.map[formulaId];

    const entFormula = formula
      ? JSON.parse(updateAllMetaIds(JSON.stringify(formula)))
      : undefined;

    dispatchEnt(entId, addEntFormFormula({
      entId: entId,
      sig: entFormula,
      formId: formId,
      insertIndex: index + 1
    }));
  }

  // endregion

  moveItem(entId: EntId, metaId: MetaIdForm, moveDirection: EnumListItemDirection)
  {
    dispatchEnt(entId, moveEntItem({
      entId,
      metaId,
      setKey: "formList",
      moveDirection
    }));
  }

  moveFormItem(
    entId: EntId,
    formId: MetaIdForm,
    moveDirection: EnumListItemDirection,
    variant: FormBuilderItemVariant,
    compositeId?: MetaIdComposite,
    headerFooterId?: MetaIdSection,
    fieldId?: MetaIdField,
    formulaId?: MetaIdFormula,
    visibilityRuleId?: MetaIdVisibilityRule,
    contentLayoutId?: MetaIdLayoutFormContent)
  {
    dispatchEnt(entId, moveFormItem({
      entId,
      formId,
      fieldId,
      moveDirection,
      compositeId,
      headerFooterId,
      variant,
      formulaId,
      visibilityRuleId,
      contentLayoutId
    } as ActionMoveFormItem));
  }

  dragFormItem(
    entId: EntId,
    formId: MetaIdForm,
    variant: FormBuilderItemVariant,
    itemId:
      | MetaIdComposite
      | MetaIdSection
      | MetaIdField
      | MetaIdFormula
      | MetaIdVisibilityRule
      | MetaIdLayoutFormContent,
    dragStartIndex: number,
    dragEndIndex: number,
    fromCompId?: MetaIdComposite,
    fromCompIndex?: number,
    toCompId?: MetaIdComposite,
    toCompIndex?: number
  )
  {
    dispatchEnt(entId, dragFormItem({
      entId,
      variant,
      formId,
      itemId,
      dragStartIndex,
      dragEndIndex,
      fromCompId,
      fromCompIndex,
      toCompId,
      toCompIndex
    } as ActionDragFormItem));
  }
}

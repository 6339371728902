import React from "react";
import {useCallback} from "react";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioDtoFormula} from "../../../../api/meta/base/dto/StudioDtoFormula";
import {StudioDtoLayoutFormContentReport} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentReport";
import {StudioField} from "../../../../api/meta/base/dto/StudioField";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVisibilityRule} from "../../../../api/meta/base/dto/StudioVisibilityRule";
import {EnumStudioCompType} from "../../../../api/meta/base/Types";
import {getPasteFailMsg} from "../../../../base/plus/SrvcPlus";
import {copyToClipboard} from "../../../../base/plus/StringPlus";
import {updateAllMetaIds} from "../../../../base/plus/SysPlus";
import {IDtoEntCopy} from "../../../../base/types/TypesStudio";
import DialogPaste from "../../../atom/dialog/DialogPaste";
import {usePageCtx} from "../../../ctx/CtxPage";

export default function useCopyPasteStudioFormItem()
{
  const pageCtx = usePageCtx();

  // region copy/paste field
  const copyStudioField = (field: StudioField) =>
  {
    const payloadCopy: IDtoEntCopy = {
      type: "studioFormField",
      payload: field
    };
    copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
  };

  const pasteStudioField = useCallback((
    cb: (studioField: StudioField) => void,
    fieldType?: EnumStudioCompType) =>
  {
    const onClickApply = (stringifyItem: string) =>
    {
      const payload = JSON.parse(stringifyItem) as IDtoEntCopy;
      if(payload.type === "studioFormField")
      {
        const studioField = payload.payload as StudioField;
        if(isValidStudioField(studioField, fieldType))
        {
          cb(studioField);
        }
        else
        {
          pageCtx.showErrorToast("Invalid field");
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(payload.type, "field"));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={"Paste field"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(text) => onClickApply(text)}
      />
    );
  }, [pageCtx]);

  //endregion

  // region copy/paste form

  const copyStudioForm = (form?: StudioForm) =>
  {
    if(form)
    {
      const payloadCopy: IDtoEntCopy = {
        type: "studioForm",
        payload: form
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  };

  const pasteStudioForm = useCallback((cb: (form: StudioForm) => void) =>
  {
    const onClickApply = (stringifyItem: string) =>
    {

      const payload = JSON.parse(stringifyItem) as IDtoEntCopy;
      if(payload.type === "studioForm")
      {
        const form = payload.payload as StudioForm;
        if(isValidStudioForm(form))
        {
          cb(form);
        }
        else
        {
          pageCtx.showErrorToast("Invalid form");
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(payload.type, "form"));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={"Paste form"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={onClickApply}
      />
    );
  }, [pageCtx]);

  //endregion

  // region copy/paste composite

  const copyStudioComposite = (studioComposite?: StudioComposite) =>
  {
    if(studioComposite && (studioComposite.type === "section" || studioComposite.type === "grid"))
    {
      const payloadCopy: IDtoEntCopy = {
        type: studioComposite.type === "section"
          ? "studioFormSection"
          : "studioFormGrid",
        payload: studioComposite
      };
      copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
    }
  };

  const pasteStudioComposite = useCallback((
    cb: (studioComposite: StudioComposite) => void,
    compositeType?: "section" | "grid") =>
  {
    const itemName = compositeType || "section/grid";
    const onClickApply = (stringifyItem: string) =>
    {
      const copyDto = JSON.parse(stringifyItem) as IDtoEntCopy;

      if(compositeType && compositeType === "section" && copyDto.type === "studioFormSection")
      {
        const studioSection = copyDto.payload as StudioSection;
        if(isValidStudioFormSection(studioSection))
        {
          cb(studioSection);
        }
        else
        {
          pageCtx.showErrorToast(`Invalid section`);
        }
      }
      else if(compositeType && compositeType === "grid" && copyDto.type === "studioFormGrid")
      {
        const studioGrid = copyDto.payload as StudioGrid;
        if(isValidStudioFormGrid(studioGrid))
        {
          cb(studioGrid);
        }
        else
        {
          pageCtx.showErrorToast("Invalid grid");
        }
      }
      else if(!compositeType && (copyDto.type === "studioFormSection" || copyDto.type === "studioFormGrid"))
      {
        if(copyDto.type === "studioFormSection" || copyDto.type === "studioFormGrid")
        {
          const studioComposite = copyDto.payload as StudioComposite;
          if(isValidStudioFormGrid(studioComposite as StudioGrid) ||
            isValidStudioFormSection(studioComposite as StudioSection))
          {
            cb(studioComposite);
          }
          else
          {
            pageCtx.showErrorToast("Invalid section or grid");
          }
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(copyDto.type, itemName));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={`Paste`}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={onClickApply}
      />
    );
  }, [pageCtx]);

  //endregion

  // region copy/paste header/footer

  const pasteStudioColumn = useCallback((cb: (
    studioField?: StudioField,
    studioSection?: StudioSection) => void) =>
  {
    const onClickApply = (stringifyItem: string) =>
    {
      const copyDto = JSON.parse(stringifyItem) as IDtoEntCopy;

      if(copyDto.type === "studioFormField")
      {
        const studioField = copyDto.payload as StudioField;
        if(isValidStudioField(studioField))
        {
          cb(studioField);
        }
        else
        {
          pageCtx.showErrorToast(`Invalid column`);
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(copyDto.type, "column"));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={`Paste`}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={onClickApply}
      />
    );
  }, [pageCtx]);

  //endregion

  // region copy/paste visibilityRules
  const copyStudioVisibilityRules = (studioVisibilityRule: StudioVisibilityRule) =>
  {
    const payloadCopy: IDtoEntCopy = {
      type: "visibilityRule",
      payload: studioVisibilityRule
    };
    copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
  };

  const pasteStudioVisibilityRules = useCallback((
    cb: (studioVisibilityRule: StudioVisibilityRule) => void) =>
  {
    const onClickApply = (stringifyItem: string) =>
    {
      const payload = JSON.parse(stringifyItem) as IDtoEntCopy;
      if(payload.type === "visibilityRule")
      {
        const studioField = payload.payload as StudioVisibilityRule;
        if(isValidStudioVisibilityRules(studioField))
        {
          cb(studioField);
        }
        else
        {
          pageCtx.showErrorToast("Invalid visibilityRule");
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(payload.type, "visibilityRule"));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={"Paste visibility rule"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(text) => onClickApply(text)}
      />
    );
  }, [pageCtx]);

  //endregion

  // region copy/paste visibilityRules
  const copyStudioContentLayout = (contentLayout: StudioDtoLayoutFormContentReport) =>
  {
    const payloadCopy: IDtoEntCopy = {
      type: "studioFormContentLayout",
      payload: contentLayout
    };
    copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
  };

  const pasteStudioContentLayout = useCallback((
    cb: (contentLayout: StudioDtoLayoutFormContentReport) => void) =>
  {
    const onClickApply = (stringifyItem: string) =>
    {
      const payload = JSON.parse(stringifyItem) as IDtoEntCopy;
      if(payload.type === "studioFormContentLayout")
      {
        const studioField = payload.payload as StudioDtoLayoutFormContentReport;
        if(isValidStudioContentLayout(studioField))
        {
          cb(studioField);
        }
        else
        {
          pageCtx.showErrorToast("Invalid content layout");
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(payload.type, "contentLayout"));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={"Paste content layout"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(text) => onClickApply(text)}
      />
    );
  }, [pageCtx]);

  //endregion

  // region copy/paste form formulas
  const copyStudioFormFormulas = (studioFormFormula: StudioDtoFormula) =>
  {
    const payloadCopy: IDtoEntCopy = {
      type: "formula",
      payload: studioFormFormula
    };
    copyToClipboard(updateAllMetaIds(JSON.stringify(payloadCopy)));
  };

  const pasteStudioFormFormulas = useCallback((
    cb: (studioFormFormula: StudioDtoFormula) => void) =>
  {
    const onClickApply = (stringifyItem: string) =>
    {
      const payload = JSON.parse(stringifyItem) as IDtoEntCopy;
      if(payload.type === "formula")
      {
        const formFormula = payload.payload as StudioDtoFormula;
        if(isValidStudioFormFormula(formFormula))
        {
          cb(formFormula);
        }
        else
        {
          pageCtx.showErrorToast("Invalid formula");
        }
      }
      else
      {
        pageCtx.showErrorToast(getPasteFailMsg(payload.type, "formula"));
      }
    };

    pageCtx.showDialog(
      <DialogPaste
        title={"Paste formula"}
        onClose={() => pageCtx.showDialog(undefined)}
        onApply={(text) => onClickApply(text)}
      />
    );
  }, [pageCtx]);

  //endregion

  return {
    copyStudioField,
    copyStudioForm,
    copyStudioComposite,
    copyStudioVisibilityRules,
    copyStudioFormFormulas,
    copyStudioContentLayout,
    pasteStudioField,
    pasteStudioForm,
    pasteStudioComposite,
    pasteStudioColumn,
    pasteStudioVisibilityRules,
    pasteStudioFormFormulas,
    pasteStudioContentLayout
  };
}

function isValidStudioForm(form: StudioForm)
{
  return Boolean(form.details?.name && form.metaId);
}

function isValidStudioVisibilityRules(studioVisibilityRule: StudioVisibilityRule)
{
  return !!studioVisibilityRule.metaId && !!studioVisibilityRule.name;
}

function isValidStudioContentLayout(studioContentLayout: StudioDtoLayoutFormContentReport)
{
  return !!studioContentLayout.metaId && !!studioContentLayout.name;
}

function isValidStudioFormFormula(studioFormFormula: StudioDtoFormula)
{
  return Boolean(studioFormFormula.assignToFieldId && studioFormFormula.metaId);
}

function isValidStudioField(field: StudioField, fieldType?: EnumStudioCompType)
{
  return Boolean(field.details.name
    && field.metaId
    && (!fieldType
      || (fieldType
        && field.type === fieldType)));
}

function isValidStudioFormSection(studioSection: StudioSection): boolean
{
  return Boolean(studioSection.details.name
    && studioSection.metaId
    && studioSection.type === "section"
    && Boolean(studioSection.fieldMap.map)
    && Boolean(studioSection.fieldMap.keys));
}

function isValidStudioFormGrid(studioSection: StudioSection): boolean
{
  return Boolean(studioSection.details.name
    && studioSection.metaId
    && studioSection.type === "grid"
    && Boolean(studioSection.fieldMap.map)
    && Boolean(studioSection.fieldMap.keys));
}

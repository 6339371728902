import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickPluginBundleId} from "../../../../api/meta/base/dto/DefnStudioPickPluginBundleId";
import {StudioEntPluginMap} from "../../../../api/meta/base/dto/StudioEntPluginMap";
import {PluginBundleId} from "../../../../api/meta/base/Types";
import {DtoPluginBundle} from "../../../../api/studio/base/dto/DtoPluginBundle";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioPickPluginId(props: {
  defn: DefnStudioPickPluginBundleId
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const formStore = formCtx.getStore();
  const fieldId = getFieldKey(defn);
  const pluginMap = formStore?.pluginBundleMap;
  const importMap = formStore?.pluginMap;
  const options = getOptions(pluginMap, importMap);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        {...props}
        optionMap={optionsToMapOfOption(options)}
        fieldProps={fieldProps}
      />}
    />
  );
}

function getOptions(
  pluginMap?: Record<PluginBundleId, DtoPluginBundle>,
  importMap?: StudioEntPluginMap
): DefnDtoOption[]
{

  const excludePluginIdSet = [] as PluginBundleId[];
  importMap && (importMap.keys).forEach((plugin) =>
  {
    const pluginBundleId = importMap.map[plugin].pluginBundleId;
    excludePluginIdSet.push(pluginBundleId);
  });

  if(!pluginMap)
  {
    return [];
  }

  const options: DefnDtoOption[] = [];

  Object.values(pluginMap).map((pluginBundle) =>
  {
    const pluginVersionMap = pluginBundle.pluginVersionMap;

    if(excludePluginIdSet && excludePluginIdSet.includes(pluginBundle.pluginBundleId))
    {
      return;
    }

    const keys = Object.keys(pluginVersionMap);
    const lastKey = keys[keys.length - 1];
    const lastVersion = pluginVersionMap[lastKey];

    options.push({
      metaId: lastVersion.pluginBundleId,
      value: lastVersion.pluginName
    } as DefnDtoOption);

  });

  return options;
}

import {RpcAside} from "../../../api/home/aside/RpcAside";
import {SigMessageReceiptMap} from "../../../api/home/aside/sig/SigMessageReceiptMap";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {MsgOffset} from "../../../api/home/main/msg/MsgOffset";
import {isEntUserId} from "../../../api/meta/base/ApiPlus";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isMessageId} from "../../../api/meta/base/ApiPlus";
import {EntUserId} from "../../../api/meta/base/Types";
import {MessageId} from "../../../api/meta/base/Types";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {formatCaptionDateTime} from "../../../base/plus/DatePlus";
import {listRecursiveCalls} from "../../../base/plus/ListPlus";
import {createListItemIdSkeleton} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {textUser} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {listSetIfExistAvatar} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistPrimaryLine} from "../../../base/slices/list/SliceListAPSAActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinder} from "../../../base/types/list/TypesList";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListItemChat} from "../../../base/types/list/TypesListChat";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {IBubbleMessage} from "../../../base/types/TypesBubble";
import {isComboId} from "../../../base/types/TypesComboId";
import {toComboIdDto} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";
import SrvcHomeListChat from "../app/SrvcHomeListChat";

const read = "read";
const delivered = "delivered";
const gapDivider = createListItemIdSkeleton("gapDivider");
const EMPTY_VALUE_STR = "--";

interface TypeUiGroupsById extends IListGroupsById
{
  messageId: {
    itemIds: string[]
  },
  readByIds: {
    itemIds: string[]
  },
  deliveredByIds: {
    itemIds: string[]
  },
  singleUser: {
    itemIds: string[]
  },
}

interface IUserParams
{
  entId: EntId;
  chatId: ChatId;
  message: IBubbleMessage;
}

export class SrvcHomeAsideMessageInfo extends SrvcHomeListChat
{
  public readonly subscriberId = "SrvcHomeAsideMessageInfo";

  constructor()
  {
    super((state: RootState) => state.home.aside.messageInfoList);
  }

  subscribe(subscriberId: string, itemId: TypeComboId | MessageId, unsubscribe?: boolean): void
  {
    if(!isMessageId(itemId))
    {
      const split = toComboIdDto(itemId);
      if(isEntUserId(split.chatId))
      {
        Srvc.app.pubsub.user.userAvatar(subscriberId, split.entId as EntId, split.chatId as EntUserId, unsubscribe);
      }
    }
  }

  rpcMessageReceiptGet(entId: EntId, chatId: ChatId, message: IBubbleMessage)
  {
    const messageOffset = message.messageOffset;
    const msg = {
      chatId: chatId,
      offset: messageOffset
    } as MsgOffset;

    RpcAside.messageReceiptGet(
      entId,
      msg,
      envSig => listRecursiveCalls<SigMessageReceiptMap>(
        envSig,
        this.selectList,
        () => this.rpcMessageReceiptGet(entId, chatId, message),
        (listName, sig) => this.doLoad(listName, sig, {
          entId: entId,
          chatId: chatId,
          message: message
        })
      )
    );
  }

  doLoad(listName: string, sig: SigMessageReceiptMap, userParams: IUserParams)
  {
    const uiGroupsById = {} as TypeUiGroupsById;
    const uiItemsById = {} as IListItemsById;
    const message = userParams.message;
    const entId = userParams.entId as EntId;
    const chatId = userParams.chatId as ChatId;

    if(message)
    {
      this.doLoadMessage(uiGroupsById, uiItemsById, message, chatId, entId);
    }

    if(isGroupId(chatId))
    {
      this.doLoadReadByHeader(uiItemsById);
      this.doLoadReadBy(sig, entId, uiGroupsById, uiItemsById);
      this.doLoadDeliveredByHeader(uiItemsById);
      this.doLoadDeliveredBy(sig, entId, uiGroupsById, uiItemsById);
    }
    else
    {
      this.doLoadForSingleUser(uiGroupsById, uiItemsById, sig);
    }

    dispatchList(listName, listRefresh({
      groupsById: uiGroupsById,
      itemsById: uiItemsById,
      version: random()
    }));
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectSourceItem.bind(this),
      onBindSourceItem1: this.bindSourceItem.bind(this),

      selectUserAvatar: this.selectEntUserAvatar.bind(this)
    } as IListBinder<SigUserAvatar | undefined>;
  }

  private selectSourceItem(
    state: RootState,
    itemId: TypeComboId
  ): SigUserAvatar | undefined
  {
    if(isComboId(itemId))
    {
      return state.cache.app.user.userAvatarMap[itemId];
    }
  }

  private bindSourceItem(
    listName: string,
    itemId: TypeComboId,
    avatar: SigUserAvatar | undefined
  ): void
  {
    if(avatar)
    {
      const sig = avatar as SigUserAvatar;

      dispatchList(listName, listSetIfExistAvatar({
        itemId: itemId,
        src: getImageThumbnail(sig.mediaIdAvatar),
        icon: "user"
      }));

      dispatchList(listName, listSetIfExistPrimaryLine({
        itemId: itemId,
        text: textUser(sig)
      }));
    }
  }

  private doLoadMessage(
    uiGroupsById: TypeUiGroupsById,
    uiItemsById: IListItemsById,
    message: IBubbleMessage,
    chatId: ChatId,
    entId: EntId
  )
  {
    const messageId = message.messageId;
    uiGroupsById["message"] = {
      itemIds: [messageId]
    };

    uiItemsById["message"] = {
      type: "listGroup",
      header: {},
      hideHeader: true,
      hideMenu: true,
      ignoreSelection: true
    } as IListItemGroup;

    uiItemsById[messageId] = {
      sig: message,
      type: message.payload.messageType,
      hideMenu: true,
      ignoreSelection: true,
      chatId: chatId,
      entId: entId
    } as IListItemChat;

    uiItemsById[gapDivider] = {
      type: "gapHalf"
    } as IListItemAPSA;
  }

  private doLoadReadBy(
    sig: SigMessageReceiptMap,
    entId: EntId,
    uiGroupsById: TypeUiGroupsById,
    uiItemsById: IListItemsById)
  {
    const itemIds = [] as string[];
    Object.keys(sig.entUserReceiptMap).forEach((entUserId, index) =>
    {
      const readOn = sig.entUserReceiptMap[entUserId].readOn;
      if(readOn)
      {
        const date = formatCaptionDateTime(new Date(readOn), true);
        const comboId = toComboId(entId, entUserId);

        const rootState = store.getState();
        const userAvatar = rootState.cache.app.user.userAvatarMap[comboId];
        itemIds.push(comboId);
        uiItemsById[comboId] = {
          type: "aps",
          avatarLeft: {
            icon: "user",
            src: undefined
          },
          primary: {
            text: userAvatar ? textUser(userAvatar) : ""
          },
          secondary: {
            text: date
          },
          ignoreSelection: true
        } as IListItemAPSA;
      }
    });

    itemIds.push(gapDivider);

    uiGroupsById[read] = {
      itemIds: itemIds
    };

  }

  private doLoadDeliveredBy(
    sig: SigMessageReceiptMap,
    entId: EntId,
    uiGroupsById: TypeUiGroupsById,
    uiItemsById: IListItemsById)
  {
    const itemIds = [] as string[];
    Object.keys(sig.entUserReceiptMap).forEach(entUserId =>
    {
      if(!sig.entUserReceiptMap[entUserId].readOn)
      {
        const deliveredOn = sig.entUserReceiptMap[entUserId].deliveredOn;
        const date = deliveredOn && formatCaptionDateTime(new Date(deliveredOn), true);
        const comboId = toComboId(entId, entUserId);
        const rootState = store.getState();
        const userAvatar = rootState.cache.app.user.userAvatarMap[comboId];
        itemIds.push(comboId);

        uiItemsById[comboId] = {
          type: "aps",
          avatarLeft: {
            icon: "user",
            src: undefined
          },
          primary: {
            text: userAvatar ? textUser(userAvatar) : ""
          },
          secondary: {
            text: date
          },
          ignoreSelection: true
        } as IListItemAPSA;
      }
    });
    uiGroupsById[delivered] = {
      itemIds: itemIds
    };

  }

  private doLoadReadByHeader(uiItemsById: IListItemsById)
  {
    uiItemsById[read] = {
      type: "listGroup",
      header: {
        text: "Read By",
        mt: theme.common.gapQuarter,
        mb: theme.common.gapQuarter,
        ml: gapStd,
        color: "info"
      },
      hideMenu: true,
      ignoreSelection: true
    } as IListItem;
  }

  private doLoadDeliveredByHeader(uiItemsById: IListItemsById)
  {
    uiItemsById[delivered] = {
      type: "listGroup",
      header: {
        text: "Delivered to",
        mt: theme.common.gapQuarter,
        mb: theme.common.gapQuarter,
        ml: gapStd,
        color: "info"
      },
      hideMenu: true,
      ignoreSelection: true
    } as IListItem;
  }

  private doLoadForSingleUser(
    uiGroupsById: IListGroupsById,
    uiItemsById: IListItemsById,
    sig?: SigMessageReceiptMap)
  {
    const userMessageDeliveredOnDate = sig && this.getDeliveredOnDate(sig);
    const userMessageReadOnDate = sig && this.getReadOnDate(sig);

    const readOn = "readOn";
    const deliveredOn = "deliveredOn";

    uiGroupsById[read] = {
      itemIds: [readOn, gapDivider]
    };

    uiGroupsById[delivered] = {
      itemIds: [deliveredOn]
    };

    uiItemsById[read] = {
      type: "listGroup",
      header: {
        text: "Read",
        color: "info",
        variant: "body2",
        ml: gapStd
      },
      hideMenu: true,
      ignoreSelection: true
    } as IListItem;

    uiItemsById[readOn] = {
      type: "p",
      primary: {
        text: userMessageReadOnDate?.length ? userMessageReadOnDate[0] : EMPTY_VALUE_STR,
        color: "textSecondary",
        variant: "body2"
      },
      ignoreSelection: true,
      hideMenu: true
    } as IListItemAPSA;

    uiItemsById[gapDivider] = {
      type: "gapHalf"
    } as IListItemAPSA;

    uiItemsById[delivered] = {
      type: "listGroup",
      header: {
        text: "Delivered",
        color: "info",
        variant: "body2",
        ml: gapStd
      },
      hideMenu: true,
      ignoreSelection: true
    } as IListItem;

    uiItemsById[deliveredOn] = {
      type: "p",
      primary: {
        text: userMessageDeliveredOnDate?.length ? userMessageDeliveredOnDate[0] : EMPTY_VALUE_STR,
        color: "textSecondary",
        variant: "body2"
      },

      ignoreSelection: true,
      hideMenu: true
    } as IListItemAPSA;

  }

  private getDeliveredOnDate(sig: SigMessageReceiptMap)
  {
    const date = [] as string[];

    Object.keys(sig.entUserReceiptMap).forEach((userId) =>
    {
      const receiptMap = sig.entUserReceiptMap[userId];
      if(receiptMap)
      {
        const deliveredOn = receiptMap.deliveredOn;
        if(deliveredOn)
        {
          date.push(formatCaptionDateTime(new Date(deliveredOn), true));
        }
        else
        {
          date.push(EMPTY_VALUE_STR);
        }
      }
    });

    return date;
  }

  private getReadOnDate(sig: SigMessageReceiptMap)
  {
    const date = [] as string[];

    Object.keys(sig.entUserReceiptMap).forEach((userId) =>
    {
      const receiptMap = sig.entUserReceiptMap[userId];
      if(receiptMap)
      {
        const readOn = receiptMap.readOn;
        if(readOn)
        {
          date.push(formatCaptionDateTime(new Date(readOn), true));
        }
        else
        {
          date.push(EMPTY_VALUE_STR);
        }
      }
    });

    return date;
  }
}


import {isEntId} from "../../../api/meta/base/ApiPlus";
import {ArtifactId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MsgStudioSearch} from "../../../api/studio/studioDrawer/msg/MsgStudioSearch";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {SigStudioEntAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioEntAvatar";
import {SigStudioPluginAvatar} from "../../../api/studio/studioDrawer/sig/SigStudioPluginAvatar";
import {SigStudioSearch} from "../../../api/studio/studioDrawer/sig/SigStudioSearch";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {isProd} from "../../../base/plus/SysPlus";
import {gapQuarter} from "../../../base/plus/ThemePlus";
import {listSetSearch} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioDrawerSearch extends ISrvc
{
  constructor(readonly selectList: SelectList)
  {
    super();
  }

  rpcSearch(query: string, searchId: string, artifactIdSet: ArtifactId[])
  {
    const msg: MsgStudioSearch = {
      query: query,
      searchId: searchId,
      filterArtifactIdSet: artifactIdSet
    };

    RpcStudioDrawer.studioSearch(msg, envSig =>
    {
      if(!envSig || envSig.error)
      {
        Srvc.app.toast.showErrorToast(envSig);
        return;
      }

      const searchMap = envSig.sig;
      if(searchMap)
      {
        this.doLoad(searchMap, query);
      }
    });
  }

  private doLoad(sig: SigStudioSearch, query: string)
  {
    const rootState = store.getState();
    const studioSearchList = this.selectList(rootState);
    const entIdAdminAvatarMap = rootState.cache.app.caller.entIdAdminAvatarMap;
    const pluginIdAdminAvatarMap = rootState.cache.app.caller.pluginIdAdminAvatarMap;
    const listName = studioSearchList.listName;

    const {
      uiItemsById,
      uiGroupsById
    } = this.getListIds(sig, entIdAdminAvatarMap, pluginIdAdminAvatarMap);

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    }));

    dispatchList(listName, listSetSearch(query));
  }

  private getListIds(
    sig: SigStudioSearch,
    entIdAdminAvatarMap: Record<EntId, SigStudioEntAvatar>,
    pluginIdAdminAvatarMap: Record<PluginBundleId, SigStudioPluginAvatar>
  )
  {
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;

    const searchResultMap = Object.entries(sig.searchResultMap ?? {});

    searchResultMap.forEach(([artifactId, dtoSearchNeatPath], index) =>
    {
      const artifact = isEntId(artifactId)
        ? entIdAdminAvatarMap[artifactId]
        : pluginIdAdminAvatarMap[artifactId];

      const artifactItemIds = [] as TypeListItemId[];

      const searchResults = dtoSearchNeatPath.searchResult?.result;
      const neatPathMap = dtoSearchNeatPath.neatPathMap;

      if(searchResults && neatPathMap)
      {
        Object.entries(searchResults).forEach(([searchPath, results]) =>
        {
          const itemId = `${artifactId}/${searchPath}`;
          const neatPath = neatPathMap[searchPath];

          if(!neatPath)
          {
            return;
          }

          const secondaryText = `${neatPath.secondary} : ${results?.join(" , ")}`;

          artifactItemIds.push(itemId);

          uiItemsById[itemId] = {
            type: "ps",
            primary: {
              text: neatPath.primary?.join(" > "),
              caption: {
                text: neatPath.caption,
                type: "text",
                ignoreSelection: true
              },
              showTooltip: true
            },
            secondary: {
              text: secondaryText
            },
            hideMenu: isProd(),// to add a menu to copy path to clipboard.for debugging
            userField: {
              artifactId: artifactId,
              searchPath: searchPath
            }
          } as IListItemAPSA;
        });
      }

      uiItemsById[artifactId] = {
        type: "listGroup",
        hideMenu: true,
        ignoreSelection: true,
        header: {
          text: artifact.name,
          mt: gapQuarter,
          mb: gapQuarter,
          expand: true
        }
      } as IListItemGroup;

      uiGroupsById[artifactId] = {
        itemIds: artifactItemIds
      };

      if(index !== searchResultMap.length - 1)
      {
        uiGroupsById[artifactId + "-gapId1"] = "gapHalf";
      }
    });

    return {
      uiItemsById,
      uiGroupsById
    };
  }
}

import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {TextField} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldSignature} from "../../../../api/meta/base/dto/DefnFieldSignature";
import {FieldValueSignature} from "../../../../api/meta/base/dto/FieldValueSignature";
import {defnDtoTextToString} from "../../../../base/plus/ArgBinderPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {useDeviceType} from "../../../app/AppHooks";
import DialogSignature from "../../../atom/dialog/DialogSignature";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconButton from "../../../atom/raw/RawIconButton";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawCaptureExtraProperties from "../raw/RawCaptureExtraProperties";

const maxHeight = "150px";
export default function FieldSignature(props: {
  defn: DefnFieldSignature
})
{
  const {defn} = props;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const helperText = getFieldHelperText();
  const icon = getFieldIcon();

  const pageCtx = usePageCtx();
  const deviceType = useDeviceType();
  const isMobile = Boolean(deviceType === "mobile");
  const formCtx = useFormCtx();

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);

  const defnTheme = formCtx.getDefnTheme();

  const theme = useTheme();
  const gapStd = theme.common.gapStd;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const showCapturedAsHelperText = defn.showCapturedValuesOnAside;
  const getOnClick = formCtx.getOnClick();

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  const onClick = (fieldValue?: FieldValueSignature, onChange?: (img?: FieldValueSignature) => void) =>
  {
    pageCtx.showDialog(
      <DialogSignature
        fullScreen={isMobile}
        onSave={(imageData) =>
        {
          if(imageData)
          {
            onChange?.({
              signature: imageData
            } as FieldValueSignature);
          }
          else
          {
            onChange?.(undefined);
          }
          pageCtx.showDialog(undefined);
        }}
        onClose={() => pageCtx.showDialog(undefined)}
        value={fieldValue?.signature}
        readOnly={readOnly}
      />
    );
  };

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error,
          isTouched
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueSignature | undefined;

        const reportNode = fieldValue
          ? <ReportSignaturePreview signature={fieldValue} />
          : undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
            reportNode={reportNode}
          >
            <LayoutFlexCol
              width={"100%"}
              overflowX={"visible"}
              overflowY={"visible"}
              alignItems={"start"}
            >
              <LayoutFlexRow
                overflowX={"visible"}
                overflowY={"visible"}
                width={"100%"}
              >

                <TextField
                  fullWidth
                  size={defnTheme.fieldSize}
                  margin={defnTheme.fieldMargin}
                  variant={defnTheme.fieldVariant}
                  helperText={isError ? error?.message : helperText}
                  autoFocus={Boolean(defn.autoFocus)}
                  disabled={formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled}
                  name={fieldId}
                  label={label}
                  placeholder={defnDtoTextToString(defn.placeHolderVar)}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      visibility: "none"
                    },
                    ...borderColor && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: borderColor
                        }
                      }
                    }
                  }}
                  error={isError}
                  onClick={() =>
                  {
                    (!disabled && !readOnly) && onClick(fieldValue, field.onChange);
                    getOnClick && getOnClick(fieldId, "field");
                  }}
                  onBlur={field.onBlur}
                  required={Boolean(defn.required)}
                  onKeyDown={(event) =>
                  {
                    if(event.key === "Enter")
                    {
                      (!disabled && !readOnly) && onClick(fieldValue, field.onChange);
                      getOnClick && getOnClick(fieldId, "field");
                    }
                  }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: icon ?
                      (
                        <Box
                          position={"absolute"}
                          right={px(gapHalf)}
                        >
                          <RawIconButton
                            name={icon}
                            icon={icon}
                          />
                        </Box>
                      ) : undefined,
                    startAdornment: (
                      fieldValue?.signature &&
                      <img
                        src={fieldValue.signature}
                        alt={"signature"}
                        style={{
                          padding: px(gapStd),
                          paddingLeft: 0,
                          width: "100%",
                          maxHeight: maxHeight
                        }}
                      />
                    )
                  }}
                />
                <FieldRawRefButton
                  defn={defn}
                />
              </LayoutFlexRow>
              {
                <RawCaptureExtraProperties
                  captureTime={fieldValue?.captureTime}
                  captureUser={fieldValue?.captureUser}
                  captureLocation={fieldValue?.captureLocation}
                  showCapturedAsHelperText={showCapturedAsHelperText}
                />
              }
            </LayoutFlexCol>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function ReportSignaturePreview(props: {
  signature: FieldValueSignature
})
{
  const fieldSignaturePreviewWidth = "200px";
  const fieldSignaturePreviewHeight = "80px";
  const signature = props.signature;

  return (
    <Box
      width={fieldSignaturePreviewWidth}
      height={fieldSignaturePreviewHeight}
      border={1}
      borderColor={theme.common.borderColor}
    >
      <img
        src={signature.signature}
        alt={"signature"}
        style={{
          aspectRatio: "1/1",
          objectFit: "contain",
          width: "100%",
          height: "100%"
        }}
      />
    </Box>
  );
}

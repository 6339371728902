import {GridApi} from "ag-grid-community";
import {SigSpreadsheetRowExpiry} from "../../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {SigSpreadsheetRow} from "../../../../api/home/main/sig/SigSpreadsheetRow";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {EntUserId} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {RowId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {resolveBubbleFooterTimer} from "../../../../base/plus/BubblePlus";
import {STR_EXPIRED_TEXT} from "../../../../base/plus/ConstantsPlus";
import {random} from "../../../../base/plus/StringPlus";
import {IGridBinderThree} from "../../../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import {toComboId} from "../../../../base/types/TypesComboId";
import {ICacheSsEditorRow} from "../../../../cache/app/spreadsheet/ssEditor/TypesCacheSpreadsheetEditor";
import {store} from "../../../../Store";
import {RootState} from "../../../../Store";
import {Srvc} from "../../../Srvc";
import {isCommentableForm} from "../../form/SrvcForm";
import {selectCacheRow} from "../SrvcSpreadsheet";
import {TypeUserFieldSsEditorItem} from "./SrvcSsEditor";

export default class SrvcSsEditorTable extends ISrvc
{
  subscriberId = "SrvcSsEditorTable";

  getGridBinder(
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet)
  {
    const selectSsRow = (rootState: RootState, rowId: RowId) => selectCacheRow(rootState, entId, rowId);
    const selectSsRowId = (rootState: RootState, rowId: RowId) =>
    {
      return rootState.cache.app.spreadsheet.ssEditor.ssStateMap[spreadsheetId]?.rowIdMap[rowId];
    };

    const selectSsExpiry = (rootState: RootState, rowId: RowId) =>
    {
      return rootState.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.spreadsheetRowExpiryMap?.[rowId];
    };

    const selectUserAvatar = (
      rootState: RootState,
      _: RowId,
      entUserId: EntUserId) =>
    {
      const userAvatar = rootState.cache.app.user.userAvatarMap[toComboId(entId, entUserId)];
      if(!userAvatar)
      {
        Srvc.app.spreadsheet.subscribeSsUser(this.subscriberId, entId, entUserId);
      }
      return userAvatar;
    };

    const selectIsCommentable = (
      rootState: RootState,
      _: RowId,
      formId: MetaIdForm,
      entUserId: EntUserId) => isCommentableForm(rootState, entId, formId, entUserId);

    const onBindSourceRow = (
      rowId: RowId,
      api: GridApi<IDataGridCell>,
      sourceRow?: SigSpreadsheetRow) => this.onBindSsRow(rowId, api, entId, sourceRow);

    return {
      selectSourceRow1: selectSsRow.bind(this),
      onBindSourceRow1: onBindSourceRow.bind(this),
      selectSourceRow2: selectSsRowId.bind(this),
      onBindSourceRow2: this.onBindSsRowId.bind(this),
      selectSourceRow3: selectSsExpiry.bind(this),
      onBindSourceRow3: this.onBindSsExpiry.bind(this),
      selectUserAvatar: selectUserAvatar.bind(this),
      selectIsCommentable: selectIsCommentable.bind(this)
    } as IGridBinderThree<
      SigSpreadsheetRow | undefined,
      ICacheSsEditorRow | undefined,
      SigSpreadsheetRowExpiry | undefined>;
  }

  subscribeRows(entId: EntId, spreadsheetId: MetaIdSpreadsheet, rowIdList: RowId[], unsubscribe?: boolean)
  {
    Srvc.app.spreadsheet.subscribeSpreadsheetRows(this.subscriberId, entId, rowIdList, spreadsheetId, unsubscribe);
  }

  createDataGridRow(rootState: RootState, entId: EntId, sigSpreadsheetRow: SigSpreadsheetRow)
  {
    const hideMenu =
      ((entId && sigSpreadsheetRow.spreadsheetId)
        ? Srvc.app.spreadsheet.SsEditor.hideMenu(rootState,
          entId,
          sigSpreadsheetRow.spreadsheetId,
          sigSpreadsheetRow?.formValue?.createdBy
        )
        : undefined);

    return {
      ...sigSpreadsheetRow?.formValue,
      ...sigSpreadsheetRow.rowCommentCount?.commentCount && {
        commentCount: {
          count: sigSpreadsheetRow.rowCommentCount?.commentCount || 0,
          unreadCount: sigSpreadsheetRow.rowCommentCount?.unreadCommentCount
        }
      },
      version: random(),
      hideMenu: hideMenu,
      userField: {
        sigSpreadsheetRow: sigSpreadsheetRow
      } as TypeUserFieldSsEditorItem
    } as IDataGridCell;
  }

  //region private
  private onBindSsRow(
    rowId: RowId,
    api: GridApi<IDataGridCell>,
    entId: EntId,
    sourceRow?: SigSpreadsheetRow): void
  {
    if(sourceRow)
    {
      const rowNode = api.getRowNode(rowId);
      const version = (rowNode?.data?.userField as TypeUserFieldSsEditorItem)?.sigSpreadsheetRow?.version;
      const commentCount = (rowNode?.data?.userField as TypeUserFieldSsEditorItem)?.sigSpreadsheetRow?.rowCommentCount?.commentCount;

      if(rowNode && (version !== sourceRow.version || commentCount !== sourceRow.rowCommentCount?.commentCount))
      {
        const formValue = sourceRow.formValue as IDataGridCell;
        if(formValue)
        {
          const rootState = store.getState();
          const dataGridRow = this.createDataGridRow(rootState, entId, sourceRow);
          rowNode?.setData(dataGridRow);
          api.onFilterChanged();
        }
      }
    }
  }

  private onBindSsRowId(
    rowId: RowId,
    api: GridApi<IDataGridCell>,
    source?: ICacheSsEditorRow): void
  {
    const rowNode = api.getRowNode(rowId);
    if(rowNode && rowNode.data?.rowId)
    {
      const dataGridRow = {
        ...rowNode.data,
        version: random(),
        expired: true
      } as IDataGridCell;
      if(source?.error)
      {
        rowNode?.setData(dataGridRow);
      }
      else if(source?.removed)
      {
        api.applyTransaction({remove: [dataGridRow]});
      }
    }

  }

  private onBindSsExpiry(
    rowId: RowId,
    api: GridApi<IDataGridCell>,
    source?: SigSpreadsheetRowExpiry): void
  {
    if(source)
    {
      const rowNode = api.getRowNode(rowId);

      if(rowNode && rowNode.data?.rowId)
      {
        const resolveProgress = resolveBubbleFooterTimer(source || {
          showTimer: true,
          remainingInvisibleProgressPercentage: 0,
          remainingReadProgressPercentage: 0,
          remainingInvisibleTimeMillis: 0,
          remainingReadTimeMillis: 0
        });

        const dataGridRow = {
          ...rowNode.data,
          valueMap: rowNode.data?.valueMap || {},
          sigSpreadsheetRowExpiry: source,
          expired: resolveProgress?.value === STR_EXPIRED_TEXT && resolveProgress?.icon === "invisible"
        } as IDataGridCell;

        rowNode?.setData(dataGridRow);
      }
    }
  }
}

import {Variant} from "@mui/material/styles/createTypography";
import {useMemo} from "react";
import React from "react";
import {SigUserAvatar} from "../../../../../../api/home/drawer/sig/SigUserAvatar";
import {FieldValueEntUserId} from "../../../../../../api/meta/base/dto/FieldValueEntUserId";
import {EntUserId} from "../../../../../../api/meta/base/Types";
import {textUser} from "../../../../../../base/plus/SrvcPlus";
import {RootState} from "../../../../../../Store";
import {useAppSelector} from "../../../../../app/AppHooks";
import RawHighlighter from "../../../../raw/RawHighlighter";

export default function BubbleFieldUser(props: {
  variant: Variant,
  searchWords?: string[],
  fieldValue?: FieldValueEntUserId,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const fieldValue = props.fieldValue;
  const entUserId = fieldValue?.value;
  const user = useAppSelector(state => entUserId ? props.selectUserAvatar?.(state, entUserId) : undefined);
  const userName = useMemo(() => user ? textUser(user) : undefined, [user]);

  return (
    <RawHighlighter
      variant={props.variant}
      value={userName || fieldValue?.displayField}
      color={"textSecondary"}
      searchWords={props.searchWords}
      isUserSelect={true}
    />
  );
}

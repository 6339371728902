import {VideoFileRounded} from "@mui/icons-material";
import {FormHelperText} from "@mui/material";
import {TextField} from "@mui/material";
import {FormControl} from "@mui/material";
import {useMemo} from "react";
import React from "react";
import {Accept} from "react-dropzone";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {nextMediaIdImage} from "../../../../api/meta/base/ApiPlus";
import {nextMediaIdVideo} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldVideo} from "../../../../api/meta/base/dto/DefnFieldVideo";
import {FieldValueVideo} from "../../../../api/meta/base/dto/FieldValueVideo";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {KbToMb} from "../../../../base/plus/MathPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getVideoMetaData} from "../../../../base/plus/MediaPlus";
import {getPrimaryColorOfImage} from "../../../../base/plus/MediaPlus";
import {getBlurredImage} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {getDurationFromMs} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFileSelectorCtx} from "../../../atom/raw/RawFileSelector";
import RawFileSelector from "../../../atom/raw/RawFileSelector";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {usePageCtx} from "../../../ctx/CtxPage";
import ICtxPage from "../../../ctx/ICtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldVideo(props: {
  defn: DefnFieldVideo
})
{
  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldIcon,
    getFieldMaxSize,
    getFieldShowSize,
    getFieldShowPreview
  } = useFieldPropertiesResolver(defn);

  const formCtx = useFormCtx();
  const label = getCompLabel(defn);
  const fieldId = getFieldKey(defn);

  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);

  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const maxSize = getFieldMaxSize();
  const icon = getFieldIcon();
  const showPreview = getFieldShowPreview();
  const showSize = getFieldShowSize();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched || Boolean(error);
        const fieldValue = field.value as FieldValueVideo | undefined;

        const onChange = (value?: FieldValueVideo) =>
        {
          if(fieldValue)
          {
            MediaStore.removeMedia(fieldValue.mediaIdImage);
            MediaStore.removeMedia(fieldValue.mediaIdVideo);
            MediaStore.removeMedia(fieldValue.mediaIdBlurImage);
          }
          if(value)
          {
            field.onChange(value);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
              defaultValue={fieldValue?.fileName || fieldValue?.mediaIdVideo || ""}
            >
              <RawVideo
                fieldId={fieldId}
                defn={defn}
                fieldValue={fieldValue}
                onChange={onChange}
                defnTheme={defnTheme}
                disabled={disabled}
                label={label}
                readOnly={readOnly}
                error={error}
                required={required}
                showPreview={showPreview as boolean}
                showSize={showSize as boolean}
                maxSize={maxSize as number}
                placeHolder={placeHolder}
                helperText={helperText}
                icon={icon}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawVideo(props: {
  fieldId: MetaIdField,
  defn: DefnFieldVideo,
  defnTheme: DefnDtoFormTheme,
  fieldValue?: FieldValueVideo,
  disabled?: boolean,
  required?: boolean,
  placeHolder?: string,
  helperText?: string,
  maxSize?: number,
  showPreview?: boolean,
  showSize?: boolean,
  icon?: string,
  label?: string,
  readOnly?: boolean,
  error?: FieldError,
  onChange: (value?: FieldValueVideo) => void
})
{
  const videoFormatType = props.defn.videoFormatType;
  const accept = {} as Accept;
  if(videoFormatType === "mp4")
  {
    accept["video/mp4"] = [".mp4"];
  }
  else
  {
    accept["video/mp4"] = [".mp4"];
    accept["video/x-msvideo"] = [".avi"];
    accept["video/quicktime"] = [".mov"];
    accept["video/webm"] = [".webm"];
  }

  return (
    <RawFileSelector
      multiple={false}
      accept={accept}
    >
      <RealRawVideo {...props} />
    </RawFileSelector>
  );
}

function RealRawVideo(props: {
  fieldId: MetaIdField,
  defn: DefnFieldVideo,
  fieldValue?: FieldValueVideo,
  accept?: Accept,
  defnTheme: DefnDtoFormTheme,
  disabled?: boolean,
  label?: string,
  readOnly?: boolean,
  error?: FieldError,
  required?: boolean,
  placeHolder?: string,
  helperText?: string,
  showPreview?: boolean,
  showSize?: boolean,
  icon?: string,
  onChange: (value?: FieldValueVideo) => void
})
{
  const {
    fieldId,
    defn,
    required,
    helperText,
    placeHolder,
    icon,
    fieldValue,
    defnTheme,
    disabled,
    showPreview,
    showSize,
    label,
    readOnly,
    accept,
    error,
    onChange
  } = props;

  const fileSelectorCtx = useFileSelectorCtx();
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();
  const fileName = fieldValue?.fileName;
  const maxFileUploadAlert = `number of uploaded should be 1`;

  const videoSrc = useMemo(() =>
  {
    const tempVideoUrl = fieldValue?.mediaIdVideo
      ? MediaStore.getMedia(fieldValue.mediaIdVideo)
      : undefined;

    return tempVideoUrl || getMediaSrc(fieldValue?.mediaIdVideo);
  }, [fieldValue?.mediaIdVideo]);

  const fieldBorderColor = formCtx.getFieldBorderColor;

  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
  const uploadFile = (pageCtx: ICtxPage, files: File[]) =>
  {
    const file = files[0];

    if(files.length === 1)
    {
      const mediaIdVideo = nextMediaIdVideo(getFileExt(file));
      const mediaIdBlurImage = nextMediaIdImage("jpeg");
      const mediaIdImage = nextMediaIdImage("jpeg");
      const videoSize = (files[0].size);
      getVideoMetaData(file, async(thumbnail, duration) =>
      {
        const durationMs = parseFloat(getDurationFromMs(duration ?? 0));
        const primaryColor = await getPrimaryColorOfImage(thumbnail as File);
        const blurredThumbnail = await getBlurredImage(thumbnail as File);

        file && MediaStore.setBlobToUrl(mediaIdVideo, file);
        thumbnail && MediaStore.setBlobToUrl(mediaIdImage, thumbnail as File);
        blurredThumbnail && MediaStore.setBlobToUrl(mediaIdBlurImage, blurredThumbnail as File);

        onChange({
          mediaIdVideo: mediaIdVideo,
          mediaIdImage: mediaIdImage,
          mediaIdBlurImage: mediaIdBlurImage,
          durationMs: durationMs,
          primaryColor: primaryColor,
          size: videoSize,
          fileName: file.name
        } as FieldValueVideo);
      });
    }
    else
    {
      pageCtx.showErrorToast(maxFileUploadAlert);
    }
  };

  const onClickUpload = () =>
  {
    if(!formCtx.isFieldReadonly(defn))
    {
      fileSelectorCtx.showFileDialog({
        accept: accept,
        onFileSelect: (files) =>
        {
          uploadFile(pageCtx, files);
        }
      });
    }
  };

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () => getOnClick(fieldId, "field")
    : undefined;

  return (
    <LayoutFlexCol
      width={"100%"}
      flexGrow={1}
      alignItems={"flex-start"}
      overflowY={"visible"}
      overflowX={"visible"}
    >
      <LayoutFlexRow
        width={"100%"}
        flexGrow={1}
        overflowY={"visible"}
        overflowX={"visible"}
      >
        <TextField
          fullWidth
          type={"text"}
          aria-readonly={true}
          size={defnTheme.fieldSize}
          margin={defnTheme.fieldMargin}
          variant={defnTheme.fieldVariant}
          error={Boolean(error?.message)}
          autoComplete={defn.autoFill ? "on" : "off"}
          autoFocus={defn.autoFocus}
          disabled={disabled}
          hiddenLabel={defn.hideLabel}
          label={!defn.hideLabel ? label : undefined}
          name={fieldId}
          placeholder={placeHolder}
          InputProps={{
            readOnly: readOnly,
            endAdornment: icon
              ? <RawIconButton
                name={icon}
                icon={icon}
              />
              : <VideoFileRounded color={"action"} />
          }}
          required={required}
          onClick={() =>
          {
            onClick && onClick();
            onClickUpload();
          }}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClick && onClick();
              onClickUpload();
            }
          }}
          value={fileName || fieldValue?.mediaIdVideo || ""}
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
        />
        {
          !(readOnly || disabled) &&
          <LayoutFlexRow
            ml={px(gapHalf)}
            mr={`-${gapHalf}px`}
          >
            <RawIconStrip
              iconStrip={["delete"]}
              onClick={(icon) =>
              {
                if(icon === "delete")
                {
                  onChange();
                }
              }}
              iconStripDisable={(!fieldValue || readOnly || disabled) ? ["delete"] : []}
            />
          </LayoutFlexRow>
        }
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>

      <FormHelperText
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {error?.message ? error.message : helperText}
      </FormHelperText>

      {
        showPreview && videoSrc &&
        <video
          controls
          controlsList={"nodownload noplaybackrate"}
          style={{
            width: "100%",
            marginTop: px(gapHalf),
            borderRadius: px(gapQuarter)
          }}
          src={videoSrc}
        />
      }
      {
        videoSrc && showSize && fieldValue?.size &&
        <FieldLabel
          defn={{
            label: `Size ${KbToMb(fieldValue.size)} MB`,
            textSizeVar: "caption"
          } as DefnFieldLabel}
        />
      }
    </LayoutFlexCol>
  );
}

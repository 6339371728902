import {MsgGroupCreate} from "../../../api/home/drawer/msg/MsgGroupCreate";
import {RpcDrawer} from "../../../api/home/drawer/RpcDrawer";
import {SigGroupCandidateMap} from "../../../api/home/drawer/sig/SigGroupCandidateMap";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {EntUserId} from "../../../api/meta/base/Types";
import {ISigAcceptor} from "../../../api/nucleus/base/ISigAcceptor";
import {SigDone} from "../../../api/nucleus/base/sig/SigDone";
import ISrvc from "../../../base/ISrvc";
import {createListItemGroup} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {textUser} from "../../../base/plus/SrvcPlus";
import {random} from "../../../base/plus/StringPlus";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListGroupsById} from "../../../base/types/list/TypesListGroup";
import {isComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {RootState} from "../../../Store";

type TypeSigAvatarNewGroup = SigUserAvatar | undefined;

export class SrvcHomeDrawerNewGroup extends ISrvc
{
  getListBinder()
  {
    return {
      selectSourceItem1: this.selectAvatar.bind(this),
      onBindSourceItem1: this.onBindAvatar.bind(this)
    } as IListBinder<TypeSigAvatarNewGroup>;
  }

  //region rpc

  rpcGroupCandidateListGet()
  {
    RpcDrawer.groupCandidateListGet({}, envSig => listRecursiveCalls<SigGroupCandidateMap>(
      envSig,
      state => state.home.drawer.homeNewGroup,
      () => this.rpcGroupCandidateListGet(),
      this.doLoad.bind(this)
    ));
  }

  rpcGroupCreate(msg: MsgGroupCreate, cbProgress: ISigAcceptor<SigDone>)
  {
    RpcDrawer.groupCreate(msg, cbProgress);
  }

  //endregion

  //region private

  private doLoad(listName: string, sig: SigGroupCandidateMap)
  {
    const candidateMap = sig.candidateMap;

    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;

    Object
    .keys(candidateMap)
    .forEach((prefix) =>
    {
      const itemIds = [] as TypeListItemId[];
      candidateMap[prefix].forEach((avatar) =>
      {
        const itemId = toComboId(avatar.entId, avatar.entUserId);
        uiItemsById[itemId] = this.createUserAvatar(avatar);
        itemIds.push(itemId);
      });

      uiItemsById[prefix] = createListItemGroup(prefix);
      uiGroupsById[prefix] = {
        itemIds: itemIds
      };
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById,
      version: random(16)
    }));
  }

  private selectAvatar(state: RootState, entUserId: EntUserId): TypeSigAvatarNewGroup
  {
    return isComboId(entUserId) ? state.cache.app.user.userAvatarMap[entUserId] : undefined;
  }

  private onBindAvatar(listName: string, aboutId: EntUserId, avatar?: TypeSigAvatarNewGroup): void
  {
    if(avatar)
    {
      const newItem = this.createUserAvatar(avatar);

      dispatchList(listName, listSetItem({
        itemId: aboutId,
        newItem: newItem
      }));
    }
  }

  private createUserAvatar(sig: SigUserAvatar)
  {
    const handle = sig.handle;
    return {
      type: "aps",
      avatarLeft: {
        src: getImageThumbnail(sig.mediaIdAvatar),
        icon: "user"
      },
      primary: {
        text: textUser(sig)
      },
      secondary: {
        text: handle ? handle : "No handle",
        color: handle ? "textSecondary" : "textDisabled"
      }
    } as IListItem;
  }

  //endregion
}

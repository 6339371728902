import {cloneDeep} from "lodash";
import {isEmpty} from "lodash";
import {DefnComp} from "../../api/meta/base/dto/DefnComp";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnDtoParagraph} from "../../api/meta/base/dto/DefnDtoParagraph";
import {DefnDtoText} from "../../api/meta/base/dto/DefnDtoText";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldAudio} from "../../api/meta/base/dto/DefnFieldAudio";
import {DefnFieldCamera} from "../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldChipSet} from "../../api/meta/base/dto/DefnFieldChipSet";
import {DefnFieldChipSetDate} from "../../api/meta/base/dto/DefnFieldChipSetDate";
import {DefnFieldChipSetDay} from "../../api/meta/base/dto/DefnFieldChipSetDay";
import {DefnFieldChipSetDeviceSize} from "../../api/meta/base/dto/DefnFieldChipSetDeviceSize";
import {DefnFieldChipSetDeviceType} from "../../api/meta/base/dto/DefnFieldChipSetDeviceType";
import {DefnFieldChipSetTime} from "../../api/meta/base/dto/DefnFieldChipSetTime";
import {DefnFieldColor} from "../../api/meta/base/dto/DefnFieldColor";
import {DefnFieldCounter} from "../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldCurrency} from "../../api/meta/base/dto/DefnFieldCurrency";
import {DefnFieldDate} from "../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateRange} from "../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldDateTime} from "../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldDateTimeRange} from "../../api/meta/base/dto/DefnFieldDateTimeRange";
import {DefnFieldDecimal} from "../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldDocument} from "../../api/meta/base/dto/DefnFieldDocument";
import {DefnFieldDuration} from "../../api/meta/base/dto/DefnFieldDuration";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldEmail} from "../../api/meta/base/dto/DefnFieldEmail";
import {DefnFieldHandle} from "../../api/meta/base/dto/DefnFieldHandle";
import {DefnFieldHyperlink} from "../../api/meta/base/dto/DefnFieldHyperlink";
import {DefnFieldIdentifier} from "../../api/meta/base/dto/DefnFieldIdentifier";
import {DefnFieldImage} from "../../api/meta/base/dto/DefnFieldImage";
import {DefnFieldInfo} from "../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldLanguage} from "../../api/meta/base/dto/DefnFieldLanguage";
import {DefnFieldLineStroke} from "../../api/meta/base/dto/DefnFieldLineStroke";
import {DefnFieldLocation} from "../../api/meta/base/dto/DefnFieldLocation";
import {DefnFieldMobileNumber} from "../../api/meta/base/dto/DefnFieldMobileNumber";
import {DefnFieldMonth} from "../../api/meta/base/dto/DefnFieldMonth";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickGridRow} from "../../api/meta/base/dto/DefnFieldPickGridRow";
import {DefnFieldPickReportRow} from "../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldPickUser} from "../../api/meta/base/dto/DefnFieldPickUser";
import {DefnFieldPinShape} from "../../api/meta/base/dto/DefnFieldPinShape";
import {DefnFieldPropertyMap} from "../../api/meta/base/dto/DefnFieldPropertyMap";
import {DefnFieldQuarter} from "../../api/meta/base/dto/DefnFieldQuarter";
import {DefnFieldRating} from "../../api/meta/base/dto/DefnFieldRating";
import {DefnFieldScanCode} from "../../api/meta/base/dto/DefnFieldScanCode";
import {DefnFieldSetOfText} from "../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnFieldShowCode} from "../../api/meta/base/dto/DefnFieldShowCode";
import {DefnFieldSignature} from "../../api/meta/base/dto/DefnFieldSignature";
import {DefnFieldSlider} from "../../api/meta/base/dto/DefnFieldSlider";
import {DefnFieldSymbol} from "../../api/meta/base/dto/DefnFieldSymbol";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnFieldTextSize} from "../../api/meta/base/dto/DefnFieldTextSize";
import {DefnFieldTime} from "../../api/meta/base/dto/DefnFieldTime";
import {DefnFieldTimeZone} from "../../api/meta/base/dto/DefnFieldTimeZone";
import {DefnFieldVideo} from "../../api/meta/base/dto/DefnFieldVideo";
import {DefnFieldVoice} from "../../api/meta/base/dto/DefnFieldVoice";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {FieldValueDate} from "../../api/meta/base/dto/FieldValueDate";
import {FieldValueDecimal} from "../../api/meta/base/dto/FieldValueDecimal";
import {FieldValueEmail} from "../../api/meta/base/dto/FieldValueEmail";
import {FieldValueHandle} from "../../api/meta/base/dto/FieldValueHandle";
import {FieldValueMobile} from "../../api/meta/base/dto/FieldValueMobile";
import {FieldValueNumber} from "../../api/meta/base/dto/FieldValueNumber";
import {FieldValueOptionId} from "../../api/meta/base/dto/FieldValueOptionId";
import {FieldValueParagraph} from "../../api/meta/base/dto/FieldValueParagraph";
import {FieldValueRole} from "../../api/meta/base/dto/FieldValueRole";
import {FieldValueSwitch} from "../../api/meta/base/dto/FieldValueSwitch";
import {FieldValueText} from "../../api/meta/base/dto/FieldValueText";
import {FormValue} from "../../api/meta/base/dto/FormValue";
import {StudioBuildArgBinder} from "../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioDtoArgValueConstant} from "../../api/meta/base/dto/StudioDtoArgValueConstant";
import {StudioDtoArgValueContext} from "../../api/meta/base/dto/StudioDtoArgValueContext";
import {StudioDtoArgValueContextCaller} from "../../api/meta/base/dto/StudioDtoArgValueContextCaller";
import {StudioDtoArgValueContextCallerSetting} from "../../api/meta/base/dto/StudioDtoArgValueContextCallerSetting";
import {StudioDtoArgValueContextEnt} from "../../api/meta/base/dto/StudioDtoArgValueContextEnt";
import {StudioDtoArgValueContextForm} from "../../api/meta/base/dto/StudioDtoArgValueContextForm";
import {StudioDtoArgValueContextPlugin} from "../../api/meta/base/dto/StudioDtoArgValueContextPlugin";
import {StudioDtoArgValueContextRow} from "../../api/meta/base/dto/StudioDtoArgValueContextRow";
import {StudioDtoArgValueDerived} from "../../api/meta/base/dto/StudioDtoArgValueDerived";
import {StudioDtoArgValueField} from "../../api/meta/base/dto/StudioDtoArgValueField";
import {StudioDtoArgValueInput} from "../../api/meta/base/dto/StudioDtoArgValueInput";
import {StudioDtoArgValueOutput} from "../../api/meta/base/dto/StudioDtoArgValueOutput";
import {StudioDtoArgValueSpreadsheet} from "../../api/meta/base/dto/StudioDtoArgValueSpreadsheet";
import {StudioDtoArgValueVariable} from "../../api/meta/base/dto/StudioDtoArgValueVariable";
import {StudioDtoConditionStatement} from "../../api/meta/base/dto/StudioDtoConditionStatement";
import {StudioDtoFieldDynamicCondition} from "../../api/meta/base/dto/StudioDtoFieldDynamicCondition";
import {StudioDtoVisibilityCondition} from "../../api/meta/base/dto/StudioDtoVisibilityCondition";
import {StudioForm} from "../../api/meta/base/dto/StudioForm";
import {StudioMapOfArgBinder} from "../../api/meta/base/dto/StudioMapOfArgBinder";
import {StudioMapOfCondition} from "../../api/meta/base/dto/StudioMapOfCondition";
import {StudioMapOfFieldDynamicCondition} from "../../api/meta/base/dto/StudioMapOfFieldDynamicCondition";
import {StudioVarValueCondition} from "../../api/meta/base/dto/StudioVarValueCondition";
import {EnumDefnTime} from "../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {Symbol} from "../../api/meta/base/Types";
import {AnyTime} from "../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {EnumDefnArgBinderContext} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {EnumDefnArgBinder} from "../../api/meta/base/Types";
import {EnumDefnDate} from "../../api/meta/base/Types";
import {ICallerEnt} from "../../cache/app/callerEnt/TypesCacheCallerEnt";
import {formatDetailedDateTime} from "./DatePlus";
import {dateToLocalString} from "./DatePlus";
import {formatDate} from "./DatePlus";
import {dateToDefaultDateString} from "./DatePlus";
import {fnFieldValueToRawValue} from "./FieldValuePlus";
import {fnRawValueToFieldValue} from "./FieldValuePlus";
import {ensureInitValues} from "./FieldValuePlus";
import {getFormFieldValueAsText} from "./FieldValuePlus";
import {getCombinedString} from "./StringPlus";
import {isJsonString} from "./StringPlus";
import {toLabel} from "./StringPlus";
import {getStudioFieldType} from "./StudioFormPlus";
import {IResolveFuncs} from "./StudioPlus";

export interface IArgBinderValue
{
  kind: EnumDefnArgBinder;
  derivedFieldId?: MetaIdField;
  constantType?: EnumDefnCompType;
  compositeId?: MetaIdComposite;
  contextKind?: EnumDefnArgBinderContext,
  value: string | number | boolean | MetaIdField | MetaIdVar | object | undefined;
}

//region public

export function defnDtoTextToString(dtoText?: DefnDtoText | DefnDtoParagraph): string
{
  return dtoText?.value?.join("") ?? "";
}

export function stringToDefnDtoText(value: string): DefnDtoText | undefined
{
  return {
    value: [value]
  };
}

export function resolveForm(
  callerEnt: ICallerEnt,
  defnForm: DefnForm,
  formValue?: FormValue)
{
  const cloneForm = cloneDeep(defnForm);
  const initValueMap = defnForm
    ? ensureInitValues(defnForm, formValue?.valueMap || {})
    : undefined;
  const filteredFormValue = {
    ...formValue,
    valueMap: initValueMap
  } as FormValue;

  for(let compMapKey in cloneForm.compMap)
  {
    const comp = cloneForm.compMap[compMapKey];
    resolveComp(callerEnt, comp, cloneForm, filteredFormValue);
  }

  return cloneForm;
}

export function identifyArgBinderType(value: string): EnumDefnArgBinder | undefined
{
  if(!isJsonString(value))
  {
    return undefined;
  }

  const argValue = JSON.parse(value);
  return argValue?.kind;
}

export function identifyVariableType(value: string): string | undefined
{
  const variable = JSON.parse(value)?.customValueMap;
  return variable?.kind;
}

//endregion

//region private

export function resolveChatPatternVar(
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  formValue?: FormValue
): DefnDtoParagraph | undefined
{
  if(defnForm.chatPatternVar)
  {
    return {
      value: resolveArgValueDto(defnForm, callerEnt, defnForm.chatPatternVar, formValue)
    } as DefnDtoParagraph;
  }
}

export function resolveChatLabelPatternVar(
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  formValue?: FormValue
): DefnDtoParagraph | undefined
{
  if(defnForm.chatLabelPatternVar)
  {
    return {
      value: resolveArgValueDto(defnForm, callerEnt, defnForm.chatLabelPatternVar, formValue)
    } as DefnDtoText;
  }
}

function resolveComp(
  callerEnt: ICallerEnt,
  comp: DefnComp,
  defnForm: DefnForm,
  formValue?: FormValue)
{
  if(comp as DefnFieldEditable)
  {
    const field = comp as DefnFieldEditable;
    field.helperTextVar = {
      value: resolveArgValueDto(defnForm, callerEnt, field.helperTextVar, formValue)
    };

    field.placeHolderVar = {
      value: resolveArgValueDto(defnForm, callerEnt, field.placeHolderVar, formValue)
    };

    field.prefixVar = {
      value: resolveArgValueDto(defnForm, callerEnt, field.prefixVar, formValue)
    };

    field.suffixVar = {
      value: resolveArgValueDto(defnForm, callerEnt, field.suffixVar, formValue)
    };

    switch(comp.type)
    {
      case "text":
      {
        const defnText = comp as DefnFieldText;
        resolveAndSetValue(defnForm, callerEnt, defnText.defaultVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnText.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnText.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnText.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnText.suffixVar, formValue);
      }
        break;
      case "paragraph":
      {
        const defnFieldParagraph = comp as DefnFieldParagraph;
        resolveAndSetValue(defnForm, callerEnt, defnFieldParagraph.defaultVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnFieldParagraph.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnFieldParagraph.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnFieldParagraph.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnFieldParagraph.suffixVar, formValue);
      }
        break;
      case "info":
      {
        const defnFieldInfo = comp as DefnFieldInfo;
        resolveAndSetValue(defnForm, callerEnt, defnFieldInfo.defaultVar, formValue);
      }
        break;
      case "identifier":
      {
        const defnFieldIdentifier = comp as DefnFieldIdentifier;
        resolveAndSetValue(defnForm, callerEnt, defnFieldIdentifier.textPatternVar, formValue);
      }
        break;
      case "propertyMap":
      {
        const defnPropertyMap = comp as DefnFieldPropertyMap;
        if(defnPropertyMap.defaultVar)
        {
          Object.keys(defnPropertyMap.defaultVar).forEach((key) =>
          {
            if(defnPropertyMap.defaultVar?.[key])
            {
              const value = defnPropertyMap.defaultVar?.[key];
              if(value)
              {
                const dtoText = {value: [value]} as DefnDtoText;
                const _values = resolveArgValueDto(defnForm, callerEnt, dtoText, formValue);
                defnPropertyMap.defaultVar[key] = _values.length > 0 ? _values[0] : "";
              }
            }
          });
        }
      }
        break;
      case "date":
      {
        const defnDate = comp as DefnFieldDate;
        if(!defnDate.displayDateFormat)
        {
          defnDate.displayDateFormat = callerEnt.displayDateFormat;
        }
        resolveAndSetValue(defnForm, callerEnt, defnDate.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDate.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDate.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDate.suffixVar, formValue);

      }
        break;
      case "decimal":
      {
        const defnDecimal = comp as DefnFieldDecimal;
        resolveAndSetValue(defnForm, callerEnt, defnDecimal.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDecimal.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDecimal.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDecimal.suffixVar, formValue);
      }
        break;
      case "image":
      {
        const defnImage = comp as DefnFieldImage;
        resolveAndSetValue(defnForm, callerEnt, defnImage.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnImage.placeHolderVar, formValue);
      }
        break;
      case "label":
      {
        const defnFieldLabel = comp as DefnFieldLabel;
        resolveAndSetValue(defnForm, callerEnt, defnFieldLabel.textPatternVar, formValue);
      }
        break;
      case "number":
      {
        const defnNumber = comp as DefnFieldNumber;
        resolveAndSetValue(defnForm, callerEnt, defnNumber.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnNumber.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnNumber.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnNumber.suffixVar, formValue);
      }
        break;
      case "audio":
      {
        const defnAudio = comp as DefnFieldAudio;
        resolveAndSetValue(defnForm, callerEnt, defnAudio.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnAudio.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnAudio.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnAudio.suffixVar, formValue);
      }
        break;
      case "camera":
      {
        const defnCamera = comp as DefnFieldCamera;
        resolveAndSetValue(defnForm, callerEnt, defnCamera.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnCamera.placeHolderVar, formValue);
      }
        break;
      case "color":
      {
        const defnColor = comp as DefnFieldColor;
        resolveAndSetValue(defnForm, callerEnt, defnColor.helperTextVar, formValue);
      }
        break;
      case "counter":
      {
        const defnCounter = comp as DefnFieldCounter;
        resolveAndSetValue(defnForm, callerEnt, defnCounter.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnCounter.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnCounter.suffixVar, formValue);
      }
        break;
      case "dateTime":
      {
        const defnDateTime = comp as DefnFieldDateTime;
        if(!defnDateTime.displayDateFormat)
        {
          defnDateTime.displayDateFormat = callerEnt.displayDateFormat;
        }
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.suffixVar, formValue);
      }
        break;
      case "dateRange":
      {
        const defnDateRange = comp as DefnFieldDateRange;
        if(!defnDateRange.displayDateFormat)
        {
          defnDateRange.displayDateFormat = callerEnt.displayDateFormat;
        }
        resolveAndSetValue(defnForm, callerEnt, defnDateRange.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateRange.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateRange.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateRange.suffixVar, formValue);
      }
        break;
      case "dateTimeRange":
      {
        const defnDateTimeRange = comp as DefnFieldDateTimeRange;
        if(!defnDateTimeRange.displayDateFormat)
        {
          defnDateTimeRange.displayDateFormat = callerEnt.displayDateFormat;
        }
        resolveAndSetValue(defnForm, callerEnt, defnDateTimeRange.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTimeRange.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTimeRange.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTimeRange.suffixVar, formValue);
      }
        break;
      case "duration":
      {
        const defnDuration = comp as DefnFieldDuration;
        resolveAndSetValue(defnForm, callerEnt, defnDuration.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDuration.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDuration.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDuration.suffixVar, formValue);
      }
        break;
      case "email":
      {
        const defnEmail = comp as DefnFieldEmail;
        if(defnEmail.autoPickSelf)
        {
          defnEmail.defaultValue = callerEnt.handle;
        }
        resolveAndSetValue(defnForm, callerEnt, defnEmail.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnEmail.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnEmail.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnEmail.suffixVar, formValue);
      }
        break;
      case "handle":
      {
        const defnHandle = comp as DefnFieldHandle;
        if(defnHandle.autoPickSelf)
        {
          defnHandle.defaultValue = callerEnt.handle;
        }
        resolveAndSetValue(defnForm, callerEnt, defnHandle.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnHandle.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnHandle.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnHandle.suffixVar, formValue);
      }
        break;
      case "hyperlink":
      {
        const defnHyperlink = comp as DefnFieldHyperlink;
        resolveAndSetValue(defnForm, callerEnt, defnHyperlink.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnHyperlink.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnHyperlink.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnHyperlink.suffixVar, formValue);
      }
        break;
      case "location":
      {
        const defnLocation = comp as DefnFieldLocation;
        resolveAndSetValue(defnForm, callerEnt, defnLocation.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLocation.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLocation.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLocation.suffixVar, formValue);
      }
        break;
      case "mobileNumber":
      {
        const defnMobileNumber = comp as DefnFieldMobileNumber;
        resolveAndSetValue(defnForm, callerEnt, defnMobileNumber.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnMobileNumber.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnMobileNumber.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnMobileNumber.suffixVar, formValue);
      }
        break;
      case "rating":
      {
        const defnRating = comp as DefnFieldRating;
        resolveAndSetValue(defnForm, callerEnt, defnRating.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnRating.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnRating.suffixVar, formValue);
      }
        break;
      case "signature":
      {
        const defnSignature = comp as DefnFieldSignature;
        resolveAndSetValue(defnForm, callerEnt, defnSignature.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSignature.placeHolderVar, formValue);
      }
        break;
      case "slider":
      {
        const defnSlider = comp as DefnFieldSlider;
        resolveAndSetValue(defnForm, callerEnt, defnSlider.helperTextVar, formValue);
      }
        break;
      case "time":
      {
        const defnTime = comp as DefnFieldTime;
        resolveAndSetValue(defnForm, callerEnt, defnTime.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTime.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTime.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTime.suffixVar, formValue);
      }
        break;
      case "video":
      {
        const defnVideo = comp as DefnFieldVideo;
        resolveAndSetValue(defnForm, callerEnt, defnVideo.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnVideo.placeHolderVar, formValue);
      }
        break;
      case "voice":
      {
        const defnVoice = comp as DefnFieldVoice;
        resolveAndSetValue(defnForm, callerEnt, defnVoice.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnVoice.placeHolderVar, formValue);
      }
        break;
      case "pickGridRow":
      {
        const defnPickGridRow = comp as DefnFieldPickGridRow;
        resolveAndSetValue(defnForm, callerEnt, defnPickGridRow.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickGridRow.placeHolderVar, formValue);
      }
        break;
      case "pickText":
      {
        const defnPickText = comp as DefnFieldPickText;
        if(defnPickText.pluginApi && !defnPickText.optionMap)
        {
          const value = formValue?.valueMap?.[defnPickText.metaId] as FieldValueOptionId;
          if(value)
          {
            defnPickText.optionMap = {
              map: {
                [value.optionId]: {
                  value: value.value || " ",
                  metaId: value.optionId,
                  disabled: true
                } as DefnDtoOption
              },
              keys: [value.optionId]
            } as DefnStudioMapOfDtoOption;
          }
        }
        resolveAndSetValue(defnForm, callerEnt, defnPickText.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickText.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickText.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickText.suffixVar, formValue);
      }
        break;
      case "setOfText":
      {
        const defnSetOfText = comp as DefnFieldSetOfText;
        resolveAndSetValue(defnForm, callerEnt, defnSetOfText.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfText.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfText.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfText.suffixVar, formValue);
      }
        break;
      case "pickTree":
      {
        const defnPickTree = comp as DefnFieldPickTree;
        resolveAndSetValue(defnForm, callerEnt, defnPickTree.helperTextVar, formValue);
      }
        break;
      case "pickUser":
      {
        const defnPickUser = comp as DefnFieldPickUser;
        resolveAndSetValue(defnForm, callerEnt, defnPickUser.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickUser.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickUser.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickUser.suffixVar, formValue);
      }
        break;
      case "setOfUser":
      {
        const defnSetOfUser = comp as DefnFieldSetOfUser;
        resolveAndSetValue(defnForm, callerEnt, defnSetOfUser.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfUser.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfUser.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfUser.suffixVar, formValue);
      }
        break;
      case "pickReportRow":
      {
        const defnPickReportRow = comp as DefnFieldPickReportRow;
        resolveAndSetValue(defnForm, callerEnt, defnPickReportRow.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPickReportRow.placeHolderVar, formValue);
      }
        break;
      case "chipSet":
      {
        const defnChipSet = comp as DefnFieldChipSet;
        resolveAndSetValue(defnForm, callerEnt, defnChipSet.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSet.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSet.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSet.suffixVar, formValue);
      }
        break;
      case "chipSetDateTime":
      {
        const defnDateTime = comp as DefnFieldDateTime;
        if(!defnDateTime.displayDateFormat)
        {
          defnDateTime.displayDateFormat = callerEnt.displayDateFormat;
        }
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDateTime.suffixVar, formValue);
      }
        break;
      case "chipSetDate":
      {
        const defnChipSetDate = comp as DefnFieldChipSetDate;
        if(!defnChipSetDate.displayDateFormat)
        {
          defnChipSetDate.displayDateFormat = callerEnt.displayDateFormat;
        }
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDate.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDate.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDate.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDate.suffixVar, formValue);
      }
        break;
      case "chipSetDay":
      {
        const defnChipSetDay = comp as DefnFieldChipSetDay;
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDay.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDay.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDay.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDay.suffixVar, formValue);
      }
        break;
      case "chipSetDeviceType":
      {
        const defnChipSetDeviceType = comp as DefnFieldChipSetDeviceType;
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceType.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceType.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceType.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceType.suffixVar, formValue);
      }
        break;
      case "chipSetDeviceSize":
      {
        const defnChipSetDeviceSize = comp as DefnFieldChipSetDeviceSize;
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceSize.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceSize.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceSize.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetDeviceSize.suffixVar, formValue);
      }
        break;
      case "chipSetTime":
      {
        const defnChipSetTime = comp as DefnFieldChipSetTime;
        resolveAndSetValue(defnForm, callerEnt, defnChipSetTime.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetTime.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetTime.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnChipSetTime.suffixVar, formValue);
      }
        break;
      case "currency":
      {
        const defnCurrency = comp as DefnFieldCurrency;
        resolveAndSetValue(defnForm, callerEnt, defnCurrency.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnCurrency.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnCurrency.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnCurrency.suffixVar, formValue);
      }
        break;
      case "textSize":
      {
        const defnTextSize = comp as DefnFieldTextSize;
        resolveAndSetValue(defnForm, callerEnt, defnTextSize.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTextSize.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTextSize.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTextSize.suffixVar, formValue);
      }
        break;
      case "icon":
      {
        const defnIcon = comp as DefnFieldEditable;
        resolveAndSetValue(defnForm, callerEnt, defnIcon.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnIcon.placeHolderVar, formValue);
      }
        break;
      case "language":
      {
        const defnLanguage = comp as DefnFieldLanguage;
        resolveAndSetValue(defnForm, callerEnt, defnLanguage.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLanguage.placeHolderVar, formValue);
      }
        break;
      case "lineStroke":
      {
        const defnLineStroke = comp as DefnFieldLineStroke;
        resolveAndSetValue(defnForm, callerEnt, defnLineStroke.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLineStroke.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLineStroke.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnLineStroke.suffixVar, formValue);
      }
        break;
      case "setOfLanguageKeys":
      {
        const defnSetOfLanguageKeys = comp as DefnFieldEditable;
        resolveAndSetValue(defnForm, callerEnt, defnSetOfLanguageKeys.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSetOfLanguageKeys.placeHolderVar, formValue);
      }
        break;
      case "month":
      {
        const defnMonth = comp as DefnFieldMonth;
        resolveAndSetValue(defnForm, callerEnt, defnMonth.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnMonth.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnMonth.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnMonth.suffixVar, formValue);
      }
        break;
      case "pinShape":
      {
        const defnPinShape = comp as DefnFieldPinShape;
        resolveAndSetValue(defnForm, callerEnt, defnPinShape.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPinShape.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPinShape.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnPinShape.suffixVar, formValue);
      }
        break;
      case "quarter":
      {
        const defnQuarter = comp as DefnFieldQuarter;
        resolveAndSetValue(defnForm, callerEnt, defnQuarter.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnQuarter.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnQuarter.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnQuarter.suffixVar, formValue);
      }
        break;
      case "timeZone":
      {
        const defnTimeZone = comp as DefnFieldTimeZone;
        resolveAndSetValue(defnForm, callerEnt, defnTimeZone.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTimeZone.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTimeZone.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnTimeZone.suffixVar, formValue);
      }
        break;
      case "document":
      {
        const defnDocument = comp as DefnFieldDocument;
        resolveAndSetValue(defnForm, callerEnt, defnDocument.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDocument.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDocument.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnDocument.suffixVar, formValue);
      }
        break;
      case "symbol":
      {
        const defnSymbol = comp as DefnFieldSymbol;
        resolveAndSetValue(defnForm, callerEnt, defnSymbol.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSymbol.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSymbol.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnSymbol.suffixVar, formValue);
      }
        break;
      case "showCode":
      {
        const defnShowCode = comp as DefnFieldShowCode;
        resolveAndSetValue(defnForm, callerEnt, defnShowCode.defaultVar, formValue);
      }
        break;
      case "scanCode":
      {
        const defnScanCode = comp as DefnFieldScanCode;
        resolveAndSetValue(defnForm, callerEnt, defnScanCode.helperTextVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnScanCode.placeHolderVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnScanCode.prefixVar, formValue);
        resolveAndSetValue(defnForm, callerEnt, defnScanCode.suffixVar, formValue);
      }
        break;
    }
  }
}

const resolveAndSetValue = (
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  dtoVar?: DefnDtoText | DefnDtoParagraph,
  formValue?: FormValue) =>
{
  const resolvedValue = resolveArgValueDto(defnForm, callerEnt, dtoVar, formValue);

  if(dtoVar)
  {
    if(resolvedValue.length > 0)
    {
      dtoVar.value = resolvedValue;
    }
    else
    {
      dtoVar.value = [];
    }
  }
};

export function resolveArgValueDto(
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  dtoText?: DefnDtoText | DefnDtoParagraph,
  formValue?: FormValue): string[]
{
  const resolvedValues = [] as string[];
  const valueArray = dtoText?.value ?? [];

  valueArray.forEach((value) =>
  {
    const resolvedValue = resolveSymbolicText(defnForm, value, formValue, callerEnt);
    resolvedValue && resolvedValues.push(resolvedValue);
  });

  return resolvedValues;
}

export function resolveSymbolicText(
  defnForm: DefnForm,
  value: string,
  formValue?: FormValue,
  callerEnt?: ICallerEnt)
{
  const argBinderType = identifyArgBinderType(value);

  switch(argBinderType)
  {
    case "context":
      return resolveContext(value, callerEnt, formValue);

    case "derived":
      return resolveDerived(value, defnForm, callerEnt, formValue);

    case "field":
      return resolveField(value, defnForm, callerEnt, formValue);

    case "variable":
      return resolveVariable(value, callerEnt);

    default:
      return value;
  }
}

function resolveContext(
  value: string,
  callerEnt?: ICallerEnt,
  formValue?: FormValue): string | undefined
{
  const argValue = JSON.parse(value)?.argValue;
  const contextType = argValue?.kind as EnumDefnArgBinderContext;

  if(callerEnt)
  {
    if(contextType === "caller")
    {
      const contextSubType = (argValue as StudioDtoArgValueContextCaller | undefined)?.attribute;
      switch(contextSubType)
      {
        case "userId":
        case "entUserId":
        case "nickName":
        case "handle":
        case "color":
          return callerEnt[contextSubType];
        case "email":
        case "mobileNumber":
          return callerEnt["handle"];
        case "managerId":
          return callerEnt.managerId;
        case "roles":
          return callerEnt.roleIdSet?.map(roleId =>
          {
            const role = callerEnt.roleMap[roleId];
            return role.label || role.name;
          }).join(", ");
      }
    }
    else if(contextType === "callerSetting")
    {
      const userSettingVarId = (argValue as StudioDtoArgValueContextCallerSetting | undefined)?.userSettingVarId;
      const variable = userSettingVarId && callerEnt.userSettingVarMap
        ? callerEnt.userSettingVarMap[userSettingVarId]
        : undefined;
      const variableValue = variable?.value;

      if(variable && variableValue)
      {
        switch(variable.kind)
        {
          case "text":
            return fnFieldValueToRawValue("text", variableValue) as string | undefined;
          case "number":
          case "decimal":
            const val = fnFieldValueToRawValue(variable.kind, variableValue) as number | undefined;
            return val !== undefined ? val.toString() : undefined;
          case "pickOne":
            return fnFieldValueToRawValue("pickText", variableValue) as string | undefined;
          case "pickMany":
            return (fnFieldValueToRawValue("setOfText", variableValue) as string[] | undefined)?.join(", ");
        }
      }
    }
    else if(contextType === "row")
    {
      const attribute = (argValue as StudioDtoArgValueContextRow | undefined)?.attribute;
      switch(attribute)
      {
        case "createdOn":
          return formValue?.createdOn
            ? formatDetailedDateTime(new Date(formValue?.createdOn))
            : undefined;
        case "createdBy":
          return formValue?.createdBy
            ? formatDetailedDateTime(new Date(formValue?.createdBy))
            : undefined;
        case "updatedOn":
          return formValue?.updatedOn
            ? formatDetailedDateTime(new Date(formValue?.updatedOn))
            : undefined;
        case "updatedBy":
          return formValue?.updatedBy
            ? formatDetailedDateTime(new Date(formValue?.updatedBy))
            : undefined;
        case "id":
          return formValue?.rowId;
        case "order":
          return formValue?.rowOrder;
      }
    }
  }

  return undefined;
}

function resolveDerived(
  value: string,
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  formValue?: FormValue): string | undefined
{
  const argValue = JSON.parse(value)?.argValue;
  const fieldId = argValue?.derivedFieldId;
  const field = fieldId && defnForm.compMap[fieldId] as DefnField;

  if(field)
  {
    switch(field.type)
    {
      case "pickText":
        const optionId = argValue.valueOptionId;
        const pickText = field as DefnFieldPickText;
        const pickTextOptionId = pickText.optionMap?.keys.find((option) => option === optionId);
        return pickTextOptionId ? pickText.optionMap?.map[pickTextOptionId].value : undefined;
      case "number":
        const valueLong = argValue.valueLong;
        return valueLong ? valueLong.toString() : undefined;
      case "decimal":
        const valueDouble = argValue.valueDouble;
        return valueDouble ? valueDouble.toString() : undefined;
      case "bool":
        const valueBoolean = argValue.valueBoolean;
        return valueBoolean !== undefined ? (Boolean(valueBoolean) ? "Yes" : "No") : undefined;
      case "date":
      case "dateTime":
        const valueDate = argValue.valueDate;
        const isDateTime = field.type === "dateTime";
        const dateString = dateToDefaultDateString(new Date(valueDate), isDateTime);
        return getDisplayFormat(
          dateString,
          field.displayDateFormat,
          callerEnt?.displayDateFormat,
          isDateTime
        );
      case "text":
      case "paragraph":
        return argValue.valueText;
      default:
        return resolveFieldValue(field, defnForm, callerEnt, formValue);
    }
  }

  return undefined;
}

function getDisplayFormat(
  dateString: string,
  displayDateFormat?: string,
  entDisplayDateFormat?: string,
  includeTime?: boolean,
  time?: AnyTime
)
{
  if(displayDateFormat)
  {
    return formatDate(dateString, displayDateFormat, includeTime, time);
  }
  if(entDisplayDateFormat)
  {
    return formatDate(dateString, entDisplayDateFormat, includeTime, time);
  }
  return dateToLocalString(dateString);
}

function resolveField(
  value: string,
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  formValue?: FormValue): string | undefined
{
  const argValue = JSON.parse(value)?.argValue;
  const fieldId = argValue?.fieldId;
  const field = fieldId ? defnForm.compMap[fieldId] as DefnField : undefined;

  if(field)
  {
    return resolveFieldValue(field, defnForm, callerEnt, formValue);
  }

  return undefined;
}

function resolveVariable(value: string, callerEnt?: ICallerEnt): string | undefined
{
  const variableKind = identifyVariableType(value) as EnumStudioVarKind;
  const variable = JSON.parse(value)?.customValueMap;

  switch(variableKind)
  {
    case "date":
    {
      let dateString = "";

      if(variable?.customDate)
      {
        dateString = dateToDefaultDateString(new Date(variable.customDate));
      }
      else if(variable?.value)
      {
        dateString = resolveDateValue(variable.value as EnumDefnDate) ?? "";
      }

      return getDisplayFormat(
        dateString,
        variable?.displayDateFormat,
        callerEnt?.displayDateFormat
      );
    }

    case "dateTime":
    {
      let dateTimeString = variable?.customDate;

      if(variable?.value)
      {
        dateTimeString = resolveDateValue(variable.value as EnumDefnDate, true, variable?.customTime) ?? "";
      }

      return formatDate(dateTimeString, undefined, true);
    }

    case "time":
      if(variable?.customValue)
      {
        return variable.customValue;
      }

      return resolveTimeValue(variable?.value as EnumDefnTime);
  }

  return value;
}

function resolveFieldValue(
  field: DefnField,
  defnForm: DefnForm,
  callerEnt?: ICallerEnt,
  formValue?: FormValue): string | undefined
{
  const fieldValue = getFormFieldValueAsText(field, formValue?.valueMap?.[field.metaId]);

  if(fieldValue === undefined)
  {
    switch(field.type)
    {
      case "text":
        const defnText = field as DefnFieldText;
        return resolveArgValueDto(defnForm, callerEnt, defnText.defaultVar, formValue).join("");
      case "paragraph":
        const defnParagraph = field as DefnFieldParagraph;
        return resolveArgValueDto(defnForm, callerEnt, defnParagraph.defaultVar, formValue).join("");
      //TODO: handle other field types
    }
  }

  return fieldValue;
}

export function resolveDateValue(value: EnumDefnDate, includeTime?: boolean, time?: AnyTime): string | undefined
{
  const today = new Date();

  if(value === "yesterday")
  {
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    return dateToDefaultDateString(yesterday, includeTime, time);
  }
  else if(value === "now")
  {
    return dateToDefaultDateString(today, includeTime, time);
  }
  else if(value === "tomorrow")
  {
    const tomorrow = new Date(today.setDate(today.getDate() + 1));
    return dateToDefaultDateString(tomorrow, includeTime, time);
  }
  else if(value === "lastWeek")
  {
    const lastWeek = new Date(today.setDate(today.getDate() - 7));
    return dateToDefaultDateString(lastWeek, includeTime, time);
  }
  else if(value === "nextWeek")
  {
    const nextWeek = new Date(today.setDate(today.getDate() + 7));
    return dateToDefaultDateString(nextWeek, includeTime, time);
  }
  else if(value === "lastYear")
  {
    const lastYear = new Date(today.setFullYear(today.getFullYear() - 1));
    return dateToDefaultDateString(lastYear, includeTime, time);
  }
  else if(value === "nextYear")
  {
    const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
    return dateToDefaultDateString(nextYear, includeTime, time);
  }
  else if(value === "lastQuarter")
  {
    const lastQuarter = new Date(today.setMonth(today.getMonth() - 3));
    return dateToDefaultDateString(lastQuarter, includeTime, time);
  }
  else if(value === "nextQuarter")
  {
    const nextQuarter = new Date(today.setMonth(today.getMonth() + 3));
    return dateToDefaultDateString(nextQuarter, includeTime, time);
  }
  else if(value === "lastMonth")
  {
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    return dateToDefaultDateString(lastMonth, includeTime, time);
  }
  else if(value === "nextMonth")
  {
    const nextDayMonth = new Date(today.setMonth(today.getMonth() + 1));
    return dateToDefaultDateString(nextDayMonth, includeTime, time);
  }
  else if(value === "startOfWeek")
  {
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today.setDate(today.getDate() - dayOfWeek));
    return dateToDefaultDateString(startOfWeek, includeTime, time);
  }
  else if(value === "endOfWeek")
  {
    const dayOfWeek = today.getDay();
    const endOfWeek = new Date(today.setDate(today.getDate() + (6 - dayOfWeek)));
    return dateToDefaultDateString(endOfWeek, includeTime, time);
  }
  else if(value === "startOfMonth")
  {
    const startOfMonth = new Date(today.setDate(1));
    return dateToDefaultDateString(startOfMonth, includeTime, time);
  }
  else if(value === "endOfMonth")
  {
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const numberOfDaysInMonth = lastDayOfMonth.getDate();
    const endOfMonth = new Date(today.setDate(numberOfDaysInMonth));
    return dateToDefaultDateString(endOfMonth, includeTime, time);
  }
  else if(value === "startOfYear")
  {
    const startOfYear = new Date(today.setMonth(0, 1));
    return dateToDefaultDateString(startOfYear, includeTime, time);
  }
  else if(value === "endOfYear")
  {
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const numberOfDaysInMonth = lastDayOfMonth.getDate();
    const endOfYear = new Date(today.setDate(numberOfDaysInMonth));
    return dateToDefaultDateString(endOfYear, includeTime, time);
  }
}

export function resolveTimeValue(value?: EnumDefnTime): string | undefined
{
  const today = new Date();

  if(value === "now")
  {
    return today.toTimeString().split(" ")[0];
  }
}

export function getFieldArgBinderValue(
  studioBuildArgBinder: StudioBuildArgBinder,
  form?: StudioForm,
  peerFieldId?: MetaIdField,
  defnForm?: DefnForm
): IArgBinderValue
{
  const kind = studioBuildArgBinder.kind;
  const studioArgBinder = {
    kind: kind
  } as IArgBinderValue;

  switch(kind)
  {
    case "context":
    {
      const argBinderContext = studioBuildArgBinder?.value as StudioDtoArgValueContext;
      studioArgBinder.contextKind = argBinderContext?.kind;
      switch(argBinderContext?.kind)
      {
        case "caller":
          studioArgBinder.value = (argBinderContext as StudioDtoArgValueContextCaller).attribute;
          break;
        case "ent":
          studioArgBinder.value = (argBinderContext as StudioDtoArgValueContextEnt).attribute;
          break;
        case "form":
          studioArgBinder.value = (argBinderContext as StudioDtoArgValueContextForm).attribute;
          break;
        case "plugin":
          studioArgBinder.value = (argBinderContext as StudioDtoArgValueContextPlugin).attribute;
          break;
        case "row":
          studioArgBinder.value = (argBinderContext as StudioDtoArgValueContextRow).attribute;
          break;
        case "callerSetting":
          studioArgBinder.value = (argBinderContext as StudioDtoArgValueContextCallerSetting).userSettingVarId;
      }
    }
      break;
    case "field":
    {
      const argBinderField = studioBuildArgBinder?.value as StudioDtoArgValueField;
      studioArgBinder.value = argBinderField?.fieldId;
      studioArgBinder.compositeId = argBinderField?.compositeId;
    }
      break;
    case "input":
    {
      const argBinderInput = studioBuildArgBinder?.value as StudioDtoArgValueInput;
      studioArgBinder.value = argBinderInput?.fieldId;
      studioArgBinder.compositeId = argBinderInput?.compositeId;
    }
      break;
    case "variable":
      studioArgBinder.value = (studioBuildArgBinder?.value as StudioDtoArgValueVariable)?.varId;
      break;
    case "constant":
    {
      studioArgBinder.constantType = (studioBuildArgBinder?.value as StudioDtoArgValueConstant)?.type;
      studioArgBinder.value = (studioBuildArgBinder?.value as StudioDtoArgValueConstant)?.value;
      break;
    }
    case "derived":
    {
      const studioArgBinderDerived = studioBuildArgBinder?.value as StudioDtoArgValueDerived;
      const derivedFieldId = studioArgBinderDerived?.derivedFieldId ?? peerFieldId;
      if(derivedFieldId)
      {
        studioArgBinder.derivedFieldId = derivedFieldId;

        const fieldType = getStudioFieldType(derivedFieldId, form, defnForm);

        switch(fieldType)
        {
          case "bool":
            studioArgBinder.value = studioArgBinderDerived?.valueBoolean;
            break;
          case "email":
          case "handle":
          case "hyperlink":
          case "text":
          case "paragraph":
          case "symbol":
          case "mobileNumber":
          case "icon":
          case "identifier":
            studioArgBinder.value = studioArgBinderDerived?.valueText;
            break;
          case "number":
          case "rating":
            studioArgBinder.value = studioArgBinderDerived?.valueLong;
            break;
          case "decimal":
            studioArgBinder.value = studioArgBinderDerived?.valueDouble;
            break;
          case "date":
          case "dateTime":
            studioArgBinder.value = studioArgBinderDerived?.valueDate;
            break;
          case "currency":
          case "pickText":
          case "pickTree":
          case "language":
          case "timeZone":
          case "paymentStatus":
            studioArgBinder.value = studioArgBinderDerived?.valueOptionId;
            break;
        }
      }
    }
      break;
  }

  return studioArgBinder;
}

export function getResolveStatus(values: StudioMapOfArgBinder, paramSet?: string[])
{
  const map = values.map;

  if(!map || !paramSet)
  {
    return false;
  }

  return paramSet.every(param =>
  {
    const argBinder = map[param];
    if(argBinder)
    {
      return calcIsParamResolved(argBinder);
    }
    else
    {
      return false;
    }
  });
}

export function calcIsParamResolved(argValues: StudioBuildArgBinder)
{
  let isResolved = false;

  switch(argValues.kind)
  {
    case "context":
    {
      const value = argValues.value as StudioDtoArgValueContext | undefined;
      isResolved = Boolean(value?.kind);
      switch(value?.kind)
      {
        case "caller":
          isResolved = Boolean((value as StudioDtoArgValueContextCaller)?.attribute);
          break;
        case "ent":
          isResolved = Boolean((value as StudioDtoArgValueContextEnt)?.attribute);
          break;
        case "form":
          isResolved = Boolean((value as StudioDtoArgValueContextForm)?.attribute);
          break;
        case "plugin":
          isResolved = Boolean((value as StudioDtoArgValueContextPlugin)?.attribute);
          break;
        case "row":
          isResolved = Boolean((value as StudioDtoArgValueContextRow)?.attribute);
          break;
        case "callerSetting":
          isResolved = Boolean((value as StudioDtoArgValueContextCallerSetting)?.userSettingVarId);
          break;
      }
    }
      break;
    case "field":
    {
      const value = argValues.value as StudioDtoArgValueField | undefined;
      const isSystemField = value?.fieldId
        ? EnumArrayDefnFields.includes(value.fieldId)
        : false;
      isResolved = Boolean(value?.fieldId)
        ? isSystemField || Boolean(value?.compositeId)
        : false;
    }
      break;
    case "derived":
    {
      const value = argValues.value as StudioDtoArgValueDerived | undefined;
      if(value?.derivedFieldId && value?.derivedFieldType)
      {
        isResolved = Boolean(calcConstantParam(value));
      }
    }
      break;
    case "variable":
    {
      const value = argValues.value as StudioDtoArgValueVariable | undefined;
      isResolved = Boolean(value?.varId);
    }
      break;
    case "input":
    {
      const value = argValues.value as StudioDtoArgValueInput | undefined;
      const isSystemField = value?.fieldId
        ? EnumArrayDefnFields.includes(value.fieldId)
        : false;
      isResolved = Boolean(value?.fieldId)
        ? isSystemField || Boolean(value?.compositeId)
        : false;
    }
      break;
    case "constant":
    {
      const value = argValues.value as StudioDtoArgValueConstant | undefined;
      isResolved = Boolean(value?.type && Boolean(value?.value && !isEmpty(value.value)));
    }
      break;
  }

  return isResolved;
}

function calcConstantParam(constantValue?: StudioDtoArgValueDerived)
{
  if(constantValue)
  {
    const {
      valueBoolean,
      valueDate,
      valueText,
      valueLong,
      valueDouble,
      valueOptionId
    } = constantValue;

    if(valueBoolean !== undefined
      || valueLong !== undefined
      || valueDouble !== undefined)
    {
      return true;
    }

    return Boolean(valueDate
      || valueText
      || valueOptionId);
  }

  return false;
}

// region condition resolver

export function getResolvedConditionVar(
  conditionVarValue: StudioVarValueCondition,
  fnResolve: IResolveFuncs
)
{
  let str = "";
  const node = conditionVarValue.node;

  const srcFormId = conditionVarValue.sourceFormId;
  if(!srcFormId || !node)
  {
    return str;
  }

  const inputFormId = conditionVarValue.inputFormId;
  const srcPluginId = conditionVarValue.sourcePluginId;

  return fnResolveConditionNode(node, srcFormId, fnResolve, inputFormId, undefined, srcPluginId);
}

export function fnResolveConditionNode(
  node: StudioMapOfCondition | StudioMapOfFieldDynamicCondition,
  formId: MetaIdForm,
  fnResolve: IResolveFuncs,
  inputFormId?: MetaIdForm,
  addParentheses?: boolean,
  pluginId?: MetaIdPlugin
)
{
  let str = "";
  const andOr = node?.andOr;
  const statement = node?.statement;
  if(statement)
  {
    return fnResolveConditionStatement(statement, fnResolve, formId, inputFormId, pluginId);
  }
  else if(andOr !== undefined && node?.keys && node?.map)
  {
    const separator = andOr ? " && " : " || ";
    const allCondition = [] as string[];

    node.keys.forEach(conditionId =>
    {
      const condition = node.map?.[conditionId];
      if(condition)
      {
        const resolvedCondition = fnResolveConditionNode(condition, formId, fnResolve, inputFormId, true, pluginId);
        resolvedCondition && allCondition.push(`${resolvedCondition}`);
      }
    });

    if(allCondition.length > 0)
    {
      if(addParentheses)
      {
        str = "(" + allCondition.join(separator) + ")";
      }
      else
      {
        str = allCondition.join(separator);
      }
    }
  }

  return str;
}

const operatorStringToSymbol = {
  "equalTo": "==",
  "greaterThan": ">",
  "greaterThanOrEqualTo": ">=",
  "lessThan": "<",
  "lessThanOrEqualTo": "<=",
  "notEqualTo": "!="
};

export function fnResolveConditionStatement(
  statement: StudioDtoConditionStatement | StudioDtoFieldDynamicCondition,
  fnResolve: IResolveFuncs,
  formId?: MetaIdForm,
  inputFormId?: MetaIdForm,
  pluginId?: MetaIdPlugin
)
{
  const lhs: StudioBuildArgBinder | undefined = typeof statement.lhs === "string"
    ? {
      kind: "field",
      value: {
        fieldId: statement.lhs as MetaIdField
      } as StudioDtoArgValueField,
      argName: ""
    }
    : statement.lhs;

  const lhsResolved = lhs
    ? fnResolveArgBinderValue(fnResolve, lhs, formId, inputFormId, pluginId)
    : "";

  if(statement.operator === "hasValue" || statement.operator === "hasNoValue")
  {
    return lhsResolved + " == " + toLabel(statement.operator);
  }

  const rhsResolved = statement.rhs
    ? fnResolveArgBinderValue(fnResolve, statement.rhs, formId, inputFormId, pluginId)
    : "";

  const symbol = statement.operator ? operatorStringToSymbol[statement.operator] : "";
  return `${lhsResolved} ${symbol} ${rhsResolved}`;
}

export function fnResolveVisibilityConditionStatement(
  statement: StudioDtoVisibilityCondition | StudioDtoFieldDynamicCondition,
  fnResolve: IResolveFuncs,
  formId?: MetaIdForm,
  inputFormId?: MetaIdForm,
  pluginId?: MetaIdPlugin
)
{
  const lhsResolved = statement.lhs
    ? fnResolveArgField(fnResolve, "field", formId, {
      fieldId: statement?.lhs
    } as StudioDtoArgValueField)
    : "";

  if(statement.operator === "hasValue"
    || statement.operator === "hasNoValue"
    || statement.operator === "hasChanged"
  )
  {
    return lhsResolved + " == " + toLabel(statement.operator);
  }

  const rhsResolved = statement.rhs
    ? fnResolveArgBinderValue(fnResolve, statement.rhs, formId, inputFormId, pluginId)
    : "";

  const symbol = statement.operator ? operatorStringToSymbol[statement.operator] : "";
  return `${lhsResolved} ${symbol} ${rhsResolved}`;
}

// region argBinder resolver

type TypeArgContext =
  | StudioDtoArgValueContextCaller
  | StudioDtoArgValueContextEnt
  | StudioDtoArgValueContextForm
  | StudioDtoArgValueContextPlugin
  | StudioDtoArgValueContextRow;

type TypeArgField =
  | StudioDtoArgValueField
  | StudioDtoArgValueInput
  | StudioDtoArgValueOutput
  | StudioDtoArgValueSpreadsheet;

export function fnResolveArgBinderValue(
  fnResolve: IResolveFuncs,
  arg: StudioBuildArgBinder,
  formId?: MetaIdForm,
  inputFormId?: MetaIdForm,
  pluginId?: MetaIdPlugin
): string
{
  let str = "";
  const argKind = arg.kind;
  const argValue = arg.value;

  switch(argKind)
  {
    case "context":
      str = fnResolveArgContext(argValue as TypeArgContext);
      break;
    case "derived":
      str = fnResolveArgDerived(fnResolve, formId, pluginId, argValue as StudioDtoArgValueDerived);
      break;
    case "field":
    case "output":
    case "spreadsheet":
      str = fnResolveArgField(fnResolve, argKind, formId, argValue as TypeArgField, pluginId);
      break;
    case "input":
      str = fnResolveArgField(fnResolve, argKind, inputFormId, argValue as TypeArgField, pluginId);
      break;
    case "variable":
      str = fnResolveArgVariable(fnResolve, argValue as StudioDtoArgValueVariable);
      break;
    case "constant":
      str = fnResolveArgConstant(fnResolve, argValue as StudioDtoArgValueConstant);
      break;
    case "argument":
      break;
  }

  return str;
}

function fnResolveArgContext(argValue?: TypeArgContext)
{
  const attribute = (argValue as TypeArgContext)?.attribute;
  return `ctx${argValue?.kind ? ":" + argValue.kind + (attribute ? "." + attribute : "") : ""}`;
}

function fnResolveArgDerived(
  fnResolve: IResolveFuncs,
  formId?: MetaIdForm,
  pluginId?: MetaIdPlugin,
  argValue?: StudioDtoArgValueDerived
)
{
  let str = "";
  if(!argValue || !argValue.derivedFieldId)
  {
    return str;
  }

  const derivedFieldId = argValue.derivedFieldId;
  const derivedFieldType = argValue.derivedFieldType;

  const fieldName = pluginId && formId
    ? fnResolve.getPluginFormFieldName(pluginId, formId, derivedFieldId)
    : formId
      ? fnResolve.getFormFieldName(formId, derivedFieldId)
      : undefined;

  if(fieldName)
  {
    if(argValue.valueOptionId)
    {
      const optionIdValue = formId
        ? fnResolve.getPickTextFieldOptionName(
          formId,
          derivedFieldId,
          argValue.valueOptionId
        )
        : undefined;

      if(optionIdValue)
      {
        str = `${optionIdValue}`;
      }
    }
    else if(argValue.valueBoolean !== undefined)
    {
      str = argValue.valueBoolean ? "Yes" : "No";
    }
    else if(argValue.valueDouble !== undefined)
    {
      str = `${argValue.valueDouble.toString()}`;
    }
    else if(argValue.valueLong !== undefined)
    {
      str = `${argValue.valueLong.toString()}`;
    }
    else if(argValue.valueDate)
    {
      str = `${formatDate(argValue.valueDate, undefined, derivedFieldType === "dateTime")}`;
    }
    else if(argValue.valueText)
    {
      str = `'${argValue.valueText}'`;
    }
  }

  str = "derived: " + derivedFieldType + "." + str;

  return str;
}

function fnResolveArgField(
  fnResolve: IResolveFuncs,
  argKind: EnumDefnArgBinder,
  formId?: MetaIdForm,
  argValue?: TypeArgField,
  pluginId?: MetaIdPlugin
)
{
  let str = "";

  if(!argValue || !argValue.fieldId)
  {
    return str;
  }

  const fieldId = argValue.fieldId;

  if(EnumArrayDefnFields.includes(fieldId))
  {
    return fieldId;
  }

  const fieldName = formId && pluginId
    ? fnResolve.getPluginFormFieldName(pluginId, formId, fieldId, true)
    : formId
      ? fnResolve.getFormFieldName(formId, fieldId, true)
      : undefined;

  if(fieldName)
  {
    str = argKind === "input"
      ? "input:" + fieldName
      : "field:" + fieldName;
  }

  return str;
}

function fnResolveArgVariable(fnResolve: IResolveFuncs, argValue?: StudioDtoArgValueVariable)
{
  let str = "";

  if(!argValue || !argValue.varId)
  {
    return str;
  }

  const varName = fnResolve.getVariableName(argValue.varId);

  if(varName)
  {
    str = "var:" + varName;
  }

  return str;
}

function fnResolveArgConstant(fnResolve: IResolveFuncs, argValue?: StudioDtoArgValueConstant)
{
  let str = "";

  const fieldType = argValue?.type;

  if(!argValue || !fieldType)
  {
    return str;
  }

  str = "constant: "
    + fieldType
    + "."
    + getConstantFilteredValue(fnResolve, fieldType, argValue.value);

  return str;
}

function getConstantFilteredValue(
  fnResolve: IResolveFuncs,
  fieldType: EnumDefnCompType,
  val: any
)
{
  const value = fnFieldValueToRawValue(fieldType, val);

  if(fieldType === "bool")
  {
    return value ? "Yes" : "No";
  }
  else if(typeof value === "string")
  {
    if(fieldType === "date" || fieldType === "dateTime")
    {
      return formatDate(value, undefined, fieldType === "dateTime");
    }
    else if(fieldType === "pickRole")
    {
      return fnResolve.getRoleName(value);
    }
    else
    {
      return `'${value}'`;
    }
  }
  else if(Array.isArray(value))
  {
    if(fieldType === "chipSetDateTime")
    {
      return "[" + value.map(date => formatDate(date, undefined, true)).join(", ") + "]";
    }
    else if(fieldType === "setOfRole")
    {
      return "[" + value.map(roleId => fnResolve.getRoleName(roleId)).join(", ") + "]";
    }
    else
    {
      return "[" + value.join(", ") + "]";
    }
  }
  else
  {
    return value;
  }

}

export function getFieldStudioBuildArgBinderKey(fieldKey: string)
{
  return getCombinedString([fieldKey, "fieldValue"]);
}

export function getConstValueKeyArgBinderFromDto(fieldKey: string, dto?: StudioBuildArgBinder)
{
  const constValue = dto?.kind === "constant"
    ? (dto?.value as StudioDtoArgValueConstant)
    : undefined;

  if(constValue?.type)
  {
    return getArgBinderConstValueKey(fieldKey, constValue.type);
  }
}

export function getArgBinderConstValueKey(fieldKey: string, fieldType: EnumDefnCompType)
{
  return getCombinedString([fieldKey, fieldType]);
}

export function extractDerivedValues(dto?: StudioBuildArgBinder | null)
{
  if(!dto || isEmpty(dto))
  {
    return dto;
  }

  if(dto.kind !== "derived")
  {
    return dto;
  }

  let derivedValue = cloneDeep(dto.value) as StudioDtoArgValueDerived;

  if(!derivedValue?.derivedFieldId)
  {
    return;
  }

  const derivedFieldType = derivedValue.derivedFieldType;

  switch(derivedFieldType)
  {
    case "bool":
      if(typeof derivedValue.valueBoolean !== "object")
      {
        // @ts-ignore
        derivedValue.valueBoolean =
          fnRawValueToFieldValue("bool", derivedValue?.valueBoolean) as FieldValueSwitch;
      }
      break;
    case "date":
      if(typeof derivedValue.valueDate !== "object")
      {
        // @ts-ignore
        derivedValue.valueDate = fnRawValueToFieldValue("date", derivedValue?.valueDate) as FieldValueDate;
      }
      break;
    case "dateTime":
      if(typeof derivedValue.valueDate !== "object")
      {
        // @ts-ignore
        derivedValue.valueDate =
          fnRawValueToFieldValue("dateTime", derivedValue?.valueDate) as FieldValueDate;
      }
      break;
    case "rating":
      if(typeof derivedValue.valueDouble !== "object")
      {
        // @ts-ignore
        derivedValue.valueDouble =
          fnRawValueToFieldValue("rating", derivedValue?.valueDouble) as FieldValueNumber;
      }
      break;
    case "decimal":
      if(typeof derivedValue.valueDouble !== "object")
      {
        // @ts-ignore
        derivedValue.valueDouble =
          fnRawValueToFieldValue("decimal", derivedValue?.valueDouble) as FieldValueDecimal;
      }
      break;
    case "mobileNumber":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText =
          fnRawValueToFieldValue("mobileNumber", derivedValue?.valueText) as FieldValueMobile;
      }
      break;
    case "number":
      if(typeof derivedValue.valueLong !== "object")
      {
        // @ts-ignore
        derivedValue.valueLong =
          fnRawValueToFieldValue("number", derivedValue?.valueLong) as FieldValueNumber;
      }
      break;
    case "currency":
      if(typeof derivedValue.valueOptionId !== "object")
      {
        // @ts-ignore
        derivedValue.valueOptionId =
          fnRawValueToFieldValue("currency", derivedValue?.valueOptionId) as string;
      }
      break;
    case "symbol":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText =
          fnRawValueToFieldValue("symbol", derivedValue?.valueText) as Symbol;
      }
      break;
    case "text":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText = fnRawValueToFieldValue("text", derivedValue?.valueText) as FieldValueText;
      }
      break;
    case "paragraph":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText = fnRawValueToFieldValue("paragraph", derivedValue?.valueText) as FieldValueParagraph;
      }
      break;
    case "identifier":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText =
          fnRawValueToFieldValue("identifier", derivedValue?.valueText) as FieldValueText;
      }
      break;
    case "hyperlink":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText =
          fnRawValueToFieldValue("hyperlink", derivedValue?.valueText) as FieldValueText;
      }
      break;
    case "email":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText = fnRawValueToFieldValue("email", derivedValue?.valueText) as FieldValueEmail;
      }
      break;
    case "handle":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText =
          fnRawValueToFieldValue("handle", derivedValue?.valueText) as FieldValueHandle;
      }
      break;
    case "pickText":
      if(typeof derivedValue.valueOptionId !== "object")
      {
        // @ts-ignore
        derivedValue.valueOptionId =
          fnRawValueToFieldValue("pickText", derivedValue?.valueOptionId) as FieldValueOptionId;
      }
      break;
    case "pickRole":
      if(typeof derivedValue.valueOptionId !== "object")
      {
        // @ts-ignore
        derivedValue.valueOptionId =
          fnRawValueToFieldValue("pickRole", derivedValue?.valueOptionId) as FieldValueRole;
      }
      break;
    case "language":
      if(typeof derivedValue.valueOptionId !== "object")
      {
        // @ts-ignore
        derivedValue.valueOptionId =
          fnRawValueToFieldValue("language", derivedValue?.valueOptionId) as string;
      }
      break;
    case "timeZone":
      if(typeof derivedValue.valueOptionId !== "object")
      {
        // @ts-ignore
        derivedValue.valueOptionId =
          fnRawValueToFieldValue("timeZone", derivedValue?.valueOptionId) as string;
      }
      break;
    case "icon":
      if(typeof derivedValue.valueText !== "object")
      {
        // @ts-ignore
        derivedValue.valueText = fnRawValueToFieldValue("icon", derivedValue?.valueText) as FieldValueText;
      }
      break;
    case "pickTree":
      if(typeof derivedValue.valueOptionId !== "object")
      {
        // @ts-ignore
        derivedValue.valueOptionId =
          fnRawValueToFieldValue("pickTree", derivedValue?.valueOptionId) as FieldValueOptionId;
      }
      break;
  }
  return {
    ...dto,
    value: derivedValue
  };
}

// endregion

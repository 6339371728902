import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {STR_FAIL_TO_LOAD_GOOGLE_MAP} from "../../base/plus/ConstantsPlus";
import {GoogleMapAuthFailure} from "../../base/plus/LocationPlus";

const loadGoogleMapScript = (
  googleMapApiKey: string,
  libraries: Libraries,
  setIsLoaded: (isLoaded: boolean) => void,
  setLoadError: (error: Error) => void
) =>
{
  let retryCount = 0;
  const maxRetries = 3;
  const retryDelay = 1000;

  const loadScript = () =>
  {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}
      &libraries=${libraries.join(",")}`;

    script.async = true;
    script.defer = true;

    script.onload = () =>
    {
      if(isViewportInfoLoaded())
      {
        setIsLoaded(true);
      }
      else
      {
        document.head.removeChild(script);
        delete (window as GoogleMapAuthFailure).gm_authFailure;
        handleViewportInfoError();
      }
    };

    (window as GoogleMapAuthFailure).gm_authFailure = () =>
    {
      setLoadError(new Error(STR_FAIL_TO_LOAD_GOOGLE_MAP));
    };

    script.onerror = () =>
    {
      handleViewportInfoError();
    };

    document.head.appendChild(script);

    return () =>
    {
      document.head.removeChild(script);
      delete (window as GoogleMapAuthFailure).gm_authFailure;
    };
  };

  const isViewportInfoLoaded = () =>
  {
    return !!(window.google && window.google.maps && window.google.maps.Map);
  };

  const handleViewportInfoError = () =>
  {
    if(retryCount < maxRetries)
    {
      retryCount++;
      console.warn(`Retrying to load Google Maps API... Attempt ${retryCount}`);
      setTimeout(loadScript, retryDelay);
    }
    else
    {
      setLoadError(new Error("Failed to load viewport information after multiple attempts."));
    }
  };

  // Initial call to load the script
  loadScript();

};

export default loadGoogleMapScript;

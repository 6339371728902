import {UploadFileRounded} from "@mui/icons-material";
import {FormHelperText} from "@mui/material";
import {TextField} from "@mui/material";
import {FormControl} from "@mui/material";
import axios from "axios";
import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {Accept} from "react-dropzone";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {nextMediaIdDocument} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldDocument} from "../../../../api/meta/base/dto/DefnFieldDocument";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldValueDocument} from "../../../../api/meta/base/dto/FieldValueDocument";
import {EnumDefnDocFileExt} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {KbToMb} from "../../../../base/plus/MathPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {logError} from "../../../../base/util/AppLog";
import {Srvc} from "../../../../srvc/Srvc";
import AvatarViewDialog from "../../../atom/avatar/AvatarViewDialog";
import DialogAtom from "../../../atom/dialog/DialogAtom";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {useFileSelectorCtx} from "../../../atom/raw/RawFileSelector";
import RawFileSelector from "../../../atom/raw/RawFileSelector";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import ICtxPage from "../../../ctx/ICtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import RawFieldAudioBar from "../advanced/RawFieldAudioBar";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldDocument(props: {
  defn: DefnFieldDocument
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldRequired,
    getFieldIcon,
    getFieldMaxSize,
    getFieldShowSize,
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const placeHolder = getFieldPlaceHolder();
  const maxSize = getFieldMaxSize();
  const icon = getFieldIcon();

  const label = getCompLabel(defn);
  const fieldId = getFieldKey(defn);
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable) || defn.disabled;
  const showSize = getFieldShowSize();

  const fileTypeList = props.defn.fileTypeSet ?? ["any"];
  const accept: Accept = {};

  fileTypeList.forEach((fileType) =>
  {
    switch(fileType)
    {
      case "csv":
        accept["text/csv"] = [".csv"];
        break;
      case "docx":
        accept["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] = [".docx"];
        break;
      case "jar":
        accept["application/java-archive"] = [".jar"];
        break;
      case "pdf":
        accept["application/pdf"] = [...accept[".pdf"] ?? [], `.${fileType}`];
        break;
      case "xls":
        accept["application/vnd.ms-excel"] = [...accept[".xls"] ?? [], `.${fileType}`];
        break;
      case "jpeg":
        accept["image/jpeg"] = [...accept[".jpeg"] ?? [], `.${fileType}`];
        break;
      case "jpg":
        accept["image/jpeg"] = [...accept[".jpg"] ?? [], `.${fileType}`];
        break;
      case "png":
        accept["image/png"] = [...accept[".png"] ?? [], `.${fileType}`];
        break;
      case "svg":
        accept["image/svg+xml"] = [...accept[".svg"] ?? [], `.${fileType}`];
        break;
      case "mp3":
      case "wav":
        accept["audio/*"] = [...accept["audio/*"] ?? [], `.${fileType}`];
        break;
      case "mp4":
        accept["video/*"] = [...accept[".mp4"] ?? [], `.${fileType}`];
        break;
      case "dll":
        accept["application/octet-stream"] = [...accept[".dll"] ?? [], `.${fileType}`];
        break;
      case "doc":
        accept[" application/msword"] = [".doc"];
        break;
      case "xlsx":
        accept["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] =
          [...accept[".xlsx"] ?? [], `.${fileType}`];
        break;
      case "zip":
        accept["application/zip"] = [...accept[".zip"] ?? [], `.${fileType}`];
        break;
      case "xml":
        accept["application/xml"] = [...accept[".xml"] ?? [], `.${fileType}`];
        break;
      case "json":
        accept["application/json"] = [...accept[".json"] ?? [], `.${fileType}`];
        break;
      case "any":
        accept["audio/*"] = [...accept["audio/*"] ?? [], `.${fileType}`];
        accept["image/*"] = [...accept["image/*"] ?? [], `.${fileType}`];
        accept["application/vnd.ms-excel"] = [...accept[".xls"] ?? [], `.${fileType}`];
        accept["application/pdf"] = [...accept[".pdf"] ?? [], `.${fileType}`];
        accept["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] =
          [...accept[".xlsx"] ?? [], `.${fileType}`];
        accept[" application/msword"] = [".doc"];
        accept["application/octet-stream"] = [...accept[".dll"] ?? [], `.${fileType}`];
        accept["video/*"] = [...accept[".mp4"] ?? [], `.${fileType}`];
        accept["application/vnd.ms-excel"] = [...accept[".xls"] ?? [], `.${fileType}`];
        accept["application/java-archive"] = [...accept[".jar"] ?? [], `.${fileType}`];
        accept["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] = [".docx"];
        accept["text/csv"] = [...accept[".csv"] ?? [], `.${fileType}`];
        accept["application/zip"] = [...accept[".zip"] ?? [], `.${fileType}`];
        accept["text/plain"] = [...accept[".txt"] ?? [], `.${fileType}`];
        accept["application/xml"] = [...accept[".xml"] ?? [], `.${fileType}`];
        accept["application/json"] = [...accept[".json"] ?? [], `.${fileType}`];
        break;
    }
  });

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched || Boolean(error);
        const fieldValue = field.value as FieldValueDocument | undefined;
        const helperText = getFieldHelperText();
        const fileSize = fieldValue?.value.fileSize;

        const onChange = (value?: FieldValueDocument) =>
        {
          if(fieldValue)
          {
            MediaStore.removeMedia(fieldValue.value.mediaIdDocument);
          }
          if(value)
          {
            field.onChange(value);
          }
          else
          {
            field.onChange(null);
          }
        };

        const _getHelperText = () =>
        {
          if(error?.message)
          {
            return error.message;
          }
          else if(helperText)
          {
            return helperText;
          }
          else if(fileSize && Boolean(showSize))
          {
            return (`Size: ${KbToMb(fileSize ?? 0)} MB`);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant}
              error={isError}
            >
              <RawDocument
                fieldId={fieldId}
                defn={defn}
                fieldValue={fieldValue}
                onChange={onChange}
                defnTheme={defnTheme}
                disabled={disabled}
                label={label}
                readOnly={readOnly}
                error={error}
                required={required}
                placeHolder={placeHolder}
                maxSize={maxSize as number}
                icon={icon as string}
                accept={accept}
              />
              {(isError || helperText || showSize) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                  tabIndex={-1}
                >
                  {_getHelperText()}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RawDocument(props: {
  fieldId: MetaIdField,
  defn: DefnFieldDocument,
  defnTheme: DefnDtoFormTheme,
  fieldValue?: FieldValueDocument,
  accept?: Accept,
  disabled?: boolean,
  label?: string,
  readOnly?: boolean,
  error?: FieldError,
  required?: boolean,
  placeHolder?: string,
  maxSize?: number,
  icon?: string,
  onChange: (value?: FieldValueDocument) => void
})
{
  return (
    <RawFileSelector
      multiple={true}
      accept={props.accept}
    >
      <RealRawDocument {...props} />
    </RawFileSelector>
  );
}

function RealRawDocument(props: {
  fieldId: MetaIdField,
  defn: DefnFieldDocument,
  fieldValue?: FieldValueDocument,
  accept?: Accept,
  defnTheme: DefnDtoFormTheme,
  disabled?: boolean,
  label?: string,
  readOnly?: boolean,
  error?: FieldError,
  required?: boolean,
  placeHolder?: string,
  maxSize?: number,
  icon?: string,
  onChange: (value?: FieldValueDocument) => void
})
{
  const {
    fieldId,
    defn,
    fieldValue,
    defnTheme,
    disabled,
    label,
    readOnly,
    accept,
    error,
    required,
    placeHolder,
    icon,
    onChange
  } = props;

  const appCtx = useAppCtx();
  const fileSelectorCtx = useFileSelectorCtx();
  const formCtx = useFormCtx();
  const pageCtx = usePageCtx();

  const getOnClick = formCtx.getOnClick();

  const fileName = fieldValue?.value?.fileName;
  const maxFileUploadAlert = `number of uploaded should be 1`;

  const [isPreview, setIsPreview] = useState<boolean>(showPreview(fieldValue?.value.fileExt));
  const isMobile = appCtx.isMobile();

  const tempDocumentUrl = fieldValue?.value.mediaIdDocument
    ? MediaStore.getMedia(fieldValue.value.mediaIdDocument)
    : undefined;

  const documentSrc = tempDocumentUrl || getMediaSrc(fieldValue?.value.mediaIdDocument);

  const uploadFile = useCallback((pageCtx: ICtxPage, files: File[]) =>
  {
    if(files.length === 1)
    {
      const file = files[0];
      const mediaIdDocument = nextMediaIdDocument(getFileExt(file));
      const fileType = getFileExt(file);
      file && MediaStore.setBlobToUrl(mediaIdDocument, file);

      onChange({
        value: {
          fileName: file?.name,
          fileExt: fileType,
          fileSize: file?.size,
          mediaIdDocument: mediaIdDocument
        }
      } as FieldValueDocument);

      setIsPreview(showPreview(fileType));
    }
    else
    {
      pageCtx.showErrorToast(maxFileUploadAlert);
    }
  }, [maxFileUploadAlert, onChange]);

  const downloadDocument = useCallback(() =>
  {
    if(tempDocumentUrl)
    {
      const link = document.createElement("a");
      link.href = tempDocumentUrl;
      link.setAttribute("download", fileName ?? "Document");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else
    {
      if(fieldValue && getMediaSrc(fieldValue.value.mediaIdDocument))
      {
        const src = getMediaSrc(fieldValue.value.mediaIdDocument) as string;
        axios.get(src, {
          responseType: "blob"
        }).then((response) =>
        {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(new Blob([response.data]));
          link.setAttribute("download", fileName ?? "Document");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch((err) =>
        {
          logError("downloadMedia", err);
          Srvc.app.toast.showErrorToast("downloading failed");
        });
      }
    }
  }, [fieldValue, fileName, tempDocumentUrl]);

  const onClick = useCallback(() =>
  {
    getOnClick && getOnClick(fieldId, "field");
    if(!formCtx.isFieldReadonly(defn))
    {
      fileSelectorCtx.showFileDialog({
        accept: accept,
        onFileSelect: (files) =>
        {
          uploadFile(pageCtx, files);
        }
      });
    }
  }, [accept, defn, fieldId, fileSelectorCtx, formCtx, getOnClick, pageCtx, uploadFile]);

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  useEffect(() =>
  {
    setIsPreview(showPreview(fieldValue?.value.fileExt));
  }, [fieldValue]);

  useEffect(() =>
  {
    showPreview(fieldValue?.value?.fileExt);
  }, [fieldValue?.value.fileExt]);

  return (
    <LayoutFlexCol
      overflowX={"visible"}
      overflowY={"visible"}
      width={"100%"}
      alignItems={"left"}
    >
      <LayoutFlexRow
        width={"100%"}
        height={"100%"}
        overflowX={"visible"}
        overflowY={"visible"}
      >
        <TextField
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          fullWidth
          type={"text"}
          aria-readonly={true}
          size={defnTheme.fieldSize}
          margin={defnTheme.fieldMargin}
          variant={defnTheme.fieldVariant}
          error={Boolean(error?.message)}
          autoComplete={defn.autoFill ? "on" : "off"}
          autoFocus={defn.autoFocus}
          disabled={disabled}
          hiddenLabel={defn.hideLabel}
          label={!defn.hideLabel ? label : undefined}
          name={fieldId}
          placeholder={placeHolder}
          InputProps={{
            readOnly: readOnly,
            endAdornment: icon ?
              <RawIconButton
                name={icon}
                icon={icon}
              />
              : <UploadFileRounded color={"action"} />
          }}
          required={required}
          value={fileName ?? ""}
          onClick={onClick}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClick();
            }
          }}
        />

        {
          (!disabled || fieldValue) &&
          <LayoutFlexRow
            ml={px(gapHalf)}
            mr={`-${gapHalf}px`}
            overflowX={"visible"}
            overflowY={"visible"}
          >
            <RawIconStrip
              iconStrip={isPreview ? ["visible"] : fieldValue ? ["download"] : ["visible"]}
              onClick={async(icon) =>
              {
                if(icon === "visible")
                {
                  switch(fieldValue?.value.fileExt)
                  {
                    case "pdf":
                      return pageCtx.showDialog(
                        <DialogAtom
                          title={fileName}
                          fullScreen={isMobile}
                          contentWidth={750}
                          contentHeight={"95%"}
                          content={
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <object
                              data={await previewUrl(documentSrc)}
                              type={"application/pdf"}
                              width={"100%"}
                              height={"100%"}
                            />
                          }
                        />
                      );
                    case "mp3":
                      return pageCtx.showDialog(
                        <DialogAtom
                          title={fileName}
                          onClickHeaderIcon={() => downloadDocument()}
                          headerIcons={["download"]}
                          contentWidth={380}
                          contentHeight={250}
                          content={
                            <RawFieldAudioBar
                              showDuration={true}
                              src={documentSrc}
                              cursorColor={"black"}
                              progressColor={"grey"}
                              waveColor={"green"}
                            />
                          }
                        />
                      );
                    case "mp4":
                      return pageCtx.showDialog(
                        <DialogAtom
                          title={fileName}
                          contentWidth={isMobile ? 380 : 700}
                          contentHeight={isMobile ? 250 : 500}
                          onClickHeaderIcon={() => downloadDocument()}
                          headerIcons={["download"]}
                          content={
                            <video
                              src={documentSrc}
                              controls
                              controlsList={"nodownload"}
                              style={{
                                objectFit: "cover",
                                flexGrow: 1,
                                width: "100%"
                              }}
                              autoPlay={true}
                              preload={"auto"}
                            />
                          }
                        />
                      );
                    case "jpeg":
                    case "png":
                    case "jpg":
                      return pageCtx.showDialog(<AvatarViewDialog
                        imageSrc={documentSrc}
                        onClose={() => pageCtx.showDialog(undefined)}
                      />);
                    default:
                      return downloadDocument();
                  }
                }
                if(icon === "download")
                {
                  downloadDocument();
                }
              }}
              iconStripDisable={!fieldValue ? ["download", "visible"] : []}
            />
          </LayoutFlexRow>
        }

        {
          !(readOnly || disabled) &&
          <LayoutFlexRow
            ml={px(gapHalf)}
            mr={`-${gapHalf}px`}
            overflowX={"visible"}
            overflowY={"visible"}
          >
            <RawIconStrip
              iconStrip={["delete"]}
              onClick={(icon) =>
              {
                if(icon === "delete")
                {
                  onChange(undefined);
                  setIsPreview(false);
                }
              }}
              iconStripDisable={(!fieldValue || readOnly || disabled) ? ["delete"] : []}
            />
          </LayoutFlexRow>
        }
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>
    </LayoutFlexCol>
  );
}

function showPreview(fileType?: EnumDefnDocFileExt)
{
  switch(fileType)
  {
    case "jpeg":
    case "jpg":
    case "mp3":
    case "mp4":
    case "pdf":
    case "png":
      return true;
    default:
      return false;
  }
}

async function previewUrl(url?: string)
{
  if(!url)
  {
    return;
  }

  return await fetch(url, {cache: "force-cache"})
  .then((data) => data.blob())
  .then((blob) => blob ? URL.createObjectURL(blob) : undefined);
}

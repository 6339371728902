import {useMemo} from "react";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnStudioPickPluginId} from "../../../../api/meta/base/dto/DefnStudioPickPluginId";
import {PluginBundleId} from "../../../../api/meta/base/Types";
import {DtoPluginBundle} from "../../../../api/studio/base/dto/DtoPluginBundle";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldStudioPickPluginVersionId(props: {
  defn: DefnStudioPickPluginId
})
{
  const formCtx = useFormCtx();
  const formStore = formCtx.getStore();
  const defnFieldPickPluginVersion = props.defn;
  const fieldId = getFieldKey(defnFieldPickPluginVersion);
  const pluginBundleId = defnFieldPickPluginVersion.pluginBundleId;

  const pluginMap = formStore?.pluginBundleMap;
  const options = useMemo(() => getOptions(pluginBundleId, pluginMap),
    [pluginMap, pluginBundleId]
  );

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        {...props}
        optionMap={optionsToMapOfOption(options)}
        fieldProps={fieldProps}
      />}
    />
  );
}

function getOptions(
  pluginBundleId: PluginBundleId,
  pluginMap?: Record<PluginBundleId, DtoPluginBundle>
)
{
  const options = [] as DefnDtoOption[];
  if(pluginMap)
  {
    Object.values(pluginMap).forEach(pluginBundle =>
    {
      if(pluginBundle.pluginBundleId !== pluginBundleId)
      {
        return;
      }
      const pluginVersionMap = pluginBundle.pluginVersionMap;

      Object.values(pluginVersionMap).forEach(pluginVersion =>
      {
        if(pluginVersion)
        {
          options.push({
            metaId: pluginVersion.pluginId,
            value: pluginVersion.pluginVersion ?? ""
          });
        }
      });
    });
  }

  return options;
}

import {Variant} from "@mui/material/styles/createTypography";
import {cloneDeep} from "lodash";
import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form";
import {isFieldId} from "../../../../api/meta/base/ApiPlus";
import {isGridId} from "../../../../api/meta/base/ApiPlus";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoLayoutCardItem} from "../../../../api/meta/base/dto/DefnDtoLayoutCardItem";
import {DefnDtoLayoutCardItemLine} from "../../../../api/meta/base/dto/DefnDtoLayoutCardItemLine";
import {DefnDtoLayoutCardItemLineSegment} from "../../../../api/meta/base/dto/DefnDtoLayoutCardItemLineSegment";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldFormList} from "../../../../api/meta/base/dto/DefnFieldFormList";
import {DefnFieldHyperlinkRow} from "../../../../api/meta/base/dto/DefnFieldHyperlinkRow";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../../api/meta/base/dto/DefnGrid";
import {DefnLayoutCard} from "../../../../api/meta/base/dto/DefnLayoutCard";
import {FieldValueAudio} from "../../../../api/meta/base/dto/FieldValueAudio";
import {FieldValueCamera} from "../../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FieldValueImage} from "../../../../api/meta/base/dto/FieldValueImage";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {FieldValueVideo} from "../../../../api/meta/base/dto/FieldValueVideo";
import {FieldValueVoice} from "../../../../api/meta/base/dto/FieldValueVoice";
import {FormValue} from "../../../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../../../api/meta/base/dto/FormValueRaw";
import {RowId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC} from "../../../../base/plus/ConstantsPlus";
import {getFormFieldValueAsText} from "../../../../base/plus/FieldValuePlus";
import {isDynamicValueFieldId} from "../../../../base/plus/FormPlus";
import {createDefaultDefnForm} from "../../../../base/plus/FormPlus";
import {dtoToFieldFormListItemsKey} from "../../../../base/plus/FormPlus";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {hasValue} from "../../../../base/plus/StringPlus";
import {random} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {IMedia} from "../../../../base/types/list/TypesListAPSA";
import {IListItemMPSL} from "../../../../base/types/list/TypesListAPSA";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {ILinePrimary} from "../../../../base/types/TypesGlobal";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../../base/types/TypesGlobal";
import {ILineSecondaryAttributedText} from "../../../../base/types/TypesGlobal";
import {ILineSecondaryText} from "../../../../base/types/TypesGlobal";
import {ILineCaption} from "../../../../base/types/TypesGlobal";
import {EnumIconButton} from "../../../atom/icon/IconButtonStrip";
import {TypeLayoutItemLine} from "../../../dialog/base/TypesLayoutBuilder";
import {ISsBrItemUserField} from "../../../ssBr/TypesSsBr";

interface ICbLayoutItemPayload
{
  lineNumber: keyof DefnDtoLayoutCardItem,
  segmentName: TypeLayoutItemLine,
  line: DefnDtoLayoutCardItemLine
  segment: DefnDtoLayoutCardItemLineSegment
}

const LIST_ITEM_MEDIA_PLACEHOLDER_SIZE = 234;

export function getCompLabel(defnComp: DefnComp): string
{
  return (hasValue(defnComp.label) ? defnComp.label : toLabel(defnComp.name)) ?? "";
}

export function getHyperLinkRowFieldIdMap(defnForm: DefnForm)
{
  return Object.values(defnForm.compMap).reduce((colIdMap, field) =>
  {
    if(field.type === "hyperlinkRow")
    {
      const metaId = (field as DefnField).metaId;
      colIdMap[metaId] = metaId;
      (field as DefnFieldHyperlinkRow).hyperlinkFieldIdSet?.forEach(hyperlinkFieldId =>
      {
        if(!colIdMap[hyperlinkFieldId])
        {
          colIdMap[hyperlinkFieldId] = metaId;
        }
      });
    }
    return colIdMap;
  }, {} as Record<MetaIdField, MetaIdField>);  // colId -> fieldHyperLinkRowId
}

export function fnAddValueToGrid(value: FormValueRaw, prevValue?: FieldValueGrid)
{
  const newValue = {
    keys: prevValue?.keys || [],
    map: prevValue?.map || {}
  } as FieldValueGrid;

  const rowId = value.rowId;
  const valueMap = value.valueMap;

  valueMap && Object.keys(valueMap).forEach(key =>
  {
    if(valueMap[key] === undefined || valueMap[key] === null)
    {
      delete valueMap[key];
    }
  });

  if(!newValue.keys.includes(rowId))
  {
    newValue.keys.push(rowId);
  }

  newValue.map[rowId] = value;

  return newValue;
}

//region useFormViewerPlus

export function useFormViewerPlus()
{
  return {
    fnShowPaneFooterAddIcon,
    fnGetFooterIconBtn,
    fnGetDisableFooterIcon
  };
}

// don't export function declare here
// region private
const fnGetDisableFooterIcon = (
  formulaFieldIdSet?: MetaIdField[],
  submitEnable?: boolean,
  isCalculating?: boolean): EnumIconButton[] =>
{
  const buttons = [] as EnumIconButton[];
  if(formulaFieldIdSet && formulaFieldIdSet.length > 0)
  {
    if(isCalculating)
    {
      buttons.push("send");
    }
  }
  if(!submitEnable)
  {
    buttons.push("send");
  }
  return buttons;
};

function fnGetFooterIconBtn(isEditable: boolean, defnForm: DefnForm): EnumIconButton[]
{
  if(isEditable)
  {
    if(defnForm.formulaFieldIdSet?.length)
    {
      return ["calculate", "send"];
    }
    else
    {
      return ["send"];
    }
  }
  return [];
}

export function fnGetFooterIconBtnLabel(
  buttonType: EnumIconButton,
  defnForm: DefnForm,
  isFormEditable?: boolean): string | undefined
{
  if(buttonType === "send" && defnForm.paymentConfig?.enablePayment && !isFormEditable)
  {
    return "Send & Pay";
  }
  return undefined;
}

function fnShowPaneFooterAddIcon(defnForm: DefnForm, currentTab?: MetaIdField)
{
  const compMap = defnForm.compMap;
  if(currentTab && isGridId(currentTab))
  {
    const grid = compMap[currentTab] as DefnGrid;
    if(grid
      && !grid.disabled
      && grid.layoutGridMap?.asideDefaultLayoutId
      && !grid.showAllRowsFieldId)
    {
      const layout = grid.layoutGridMap.map[grid.layoutGridMap.asideDefaultLayoutId];
      if(layout.kind === "list" || layout.kind === "card" || layout.kind === "table")
      {
        return true;
      }
    }
  }
  return false;
}

// endregion

// endregion

export function isSegmentHasContent(segment?: DefnDtoLayoutCardItemLineSegment)
{
  return Boolean(segment?.lineFieldIdSet?.length || segment?.lineVar || segment?.line);
}

export function isLineHasContent(line: DefnDtoLayoutCardItemLine)
{
  return Boolean(isSegmentHasContent(line.first)
    || isSegmentHasContent(line.middle)
    || isSegmentHasContent(line.caption));
}

//region listItem layout
export function getListItemLayout(
  compMap: Record<MetaIdComp, DefnComp>,
  layout?: DefnLayoutCard,
  formValue?: FormValue
): IListItemMPSL
{
  const valueMap = !isEmpty(formValue?.valueMap) ? formValue?.valueMap : undefined;
  const listItem = {
    type: "mpsl",
    hideMenu: true,
    version: random(),
    userField: {
      fieldDtoGridRow: formValue
    } as ISsBrItemUserField
  } as IListItemMPSL;

  if(layout)
  {
    const primary = layout.item.firstLine
      ? getListItemPrimary(layout.item.firstLine, compMap, valueMap)
      : undefined;

    if(primary && (primary.text || primary.number || primary.caption || primary.middle))
    {
      listItem.primary = primary;

      if((primary.middle?.type === "counter" && primary.middle?.value)
        || (primary.caption?.type === "counter" && primary.caption?.value))
      {
        listItem.bgColor = theme.common.bgcolorGreenLight;
      }
    }

    setListItemMedia(listItem, layout.item, compMap, valueMap);
    setListItemSecondaryLines(listItem, compMap, layout, valueMap);
  }

  return listItem;
}

function getListItemPrimary(
  line: DefnDtoLayoutCardItemLine,
  compMap: Record<MetaIdComp, DefnComp>,
  valueMap?: Record<MetaIdComp, any>
)
{
  let primary = {} as ILinePrimary;

  if(line?.first && !isEmpty(line.first))
  {
    setListItemPrimaryFirst(primary, line.first, compMap, valueMap);
  }

  if(line?.middle && !isEmpty(line.middle))
  {
    const middle = getListItemCaption(line.middle, compMap, valueMap);
    if(!isEmpty(middle))
    {
      primary.middle = getListItemCaption(line.middle, compMap, valueMap);
    }
  }

  if(line?.caption && !isEmpty(line.caption))
  {
    const caption = getListItemCaption(line.caption, compMap, valueMap);
    if(!isEmpty(caption))
    {
      primary.caption = getListItemCaption(line.caption, compMap, valueMap);
    }
  }

  return primary;
}

function setListItemPrimaryFirst(
  primary: ILinePrimary,
  first: DefnDtoLayoutCardItemLineSegment,
  compMap: Record<MetaIdComp, DefnComp>,
  valueMap?: Record<MetaIdComp, any>
)
{
  const lineFieldIdSet = first.lineFieldIdSet;
  const lineVar = first.lineVar;
  const line = first.line;

  if(lineFieldIdSet || lineVar || line)
  {
    const lineFieldId = lineFieldIdSet?.at(0);
    const fontFieldId = first.textSizeFieldId;
    const colorFieldId = first.colorFieldId;

    const lineField = lineFieldId ? compMap[lineFieldId] : undefined;
    const fontField = fontFieldId ? compMap[fontFieldId] : undefined;
    const colorField = colorFieldId ? compMap[colorFieldId] : undefined;

    primary.text = fnGetListItemText(first, 0, lineField, valueMap);
    primary.hasMarkDownText = Boolean(lineField?.type === "info");

    const variant = fnGetListItemVariant(first, fontField, valueMap);
    if(variant)
    {
      primary.variant = variant;
    }

    const color = fnGetListItemColor(first, colorField, valueMap);
    if(color)
    {
      primary.color = color;
    }
  }
}

function setListItemSecondaryLines(
  listItem: IListItemMPSL,
  compMap: Record<MetaIdComp, DefnComp>,
  layout: DefnLayoutCard,
  valueMap?: FieldValues
)
{
  Object.keys(layout.item).forEach((key: keyof DefnDtoLayoutCardItem | string) =>
  {
    if(key === "secondLine"
      || key === "thirdLine"
      || key === "fourthLine"
      || key === "fifthLine"
    )
    {
      const line = layout.item[key];
      if(line && isLineHasContent(line))
      {
        const secondary = fnListItemSecondaryLine(line, compMap, valueMap);
        if(secondary && !isEmpty(secondary))
        {
          if(listItem.secondaryList)
          {
            listItem.secondaryList.push(secondary);
          }
          else
          {
            listItem.secondaryList = [secondary];
          }
        }
      }
    }
  });

  if(listItem.secondaryList?.some(secondary =>
    (secondary.middle?.type === "counter" && secondary.middle?.value)
    || (secondary.caption?.type === "counter" && secondary.caption?.value)))
  {
    listItem.bgColor = theme.common.bgcolorGreenLight;
  }
}

function getListItemCaption(
  segment: DefnDtoLayoutCardItemLineSegment,
  compMap: Record<MetaIdComp, DefnComp>,
  valueMap?: FieldValues
)
{
  const caption = {} as ILineCaption;

  const lineFieldIdSet = segment.lineFieldIdSet;
  const lineVar = segment.lineVar;
  const line = segment.line;
  if(lineFieldIdSet || lineVar || line)
  {
    const lineFieldId = lineFieldIdSet?.at(0);
    const fontFieldId = segment.textSizeFieldId;
    const colorFieldId = segment.colorFieldId;

    const lineField = lineFieldId ? compMap[lineFieldId] : undefined;
    const fontField = fontFieldId ? compMap[fontFieldId] : undefined;
    const colorField = colorFieldId ? compMap[colorFieldId] : undefined;

    const captionText = fnGetListItemText(segment, 0, lineField, valueMap);
    if(captionText)
    {
      caption.hasMarkDownText = Boolean(lineField?.type === "info");
      caption.text = captionText;
    }

    const variant = fnGetListItemVariant(segment, fontField, valueMap);
    if(variant)
    {
      caption.variant = variant;
    }

    const color = fnGetListItemColor(segment, colorField, valueMap);
    if(color)
    {
      caption.color = color;
    }
  }

  if(!isEmpty(caption))
  {
    caption.ignoreSelection = true;
    return caption;
  }
}

function fnListItemSecondaryLine(
  line: DefnDtoLayoutCardItemLine,
  compMap: Record<MetaIdComp, DefnComp>,
  valueMap?: FieldValues
)
{
  let secondary = {} as ILineSecondary;

  const first = line.first;
  const middle = line.middle;
  const caption = line.caption;
  if(first && (first.line || first.lineFieldIdSet || first.lineVar))
  {
    const fieldIdSet = first?.lineFieldIdSet;
    const lineVar = first?.lineVar;
    const line = first?.line;
    if(fieldIdSet?.length || lineVar || line)
    {
      secondary = {} as ILineSecondary;
      fnListItemSecondaryLineFirst(secondary, first, compMap, valueMap);
      if(valueMap && isEmpty(secondary))
      {
        secondary = {};
      }
    }
  }
  if(middle && (middle.line || middle.lineFieldIdSet || middle.lineVar))
  {
    secondary = {
      ...secondary,
      middle: getListItemCaption(middle, compMap, valueMap)
    };
  }
  if(caption && (caption.line || caption.lineFieldIdSet || caption.lineVar))
  {
    secondary = {
      ...secondary,
      caption: getListItemCaption(caption, compMap, valueMap)
    };
  }

  return secondary;
}

function fnListItemSecondaryLineFirst(
  secondary: ILineSecondary,
  first: DefnDtoLayoutCardItemLineSegment,
  compMap: Record<MetaIdComp, DefnComp>,
  valueMap?: FieldValues
)
{
  const fieldIdSet = first?.lineFieldIdSet;
  const lineVar = first?.lineVar;
  const line = first?.line;

  const showLabels = first?.showLabels;
  const fontFieldId = first?.textSizeFieldId;
  const colorFieldId = first?.colorFieldId;

  const fontField = fontFieldId ? compMap[fontFieldId] : undefined;
  const colorField = colorFieldId ? compMap[colorFieldId] : undefined;
  const useAttributedText = Boolean((fieldIdSet?.length && fieldIdSet.length > 1) || showLabels);

  if(fieldIdSet && first)
  {
    const firstFieldId = fieldIdSet.at(0);
    if(!useAttributedText && firstFieldId)
    {
      const text = fnGetListItemText(first, 0, compMap[firstFieldId], valueMap);
      if(text)
      {
        secondary.hasMarkDownText = Boolean(compMap[firstFieldId]?.type === "info");
        secondary.text = text;
        secondary.variant = fnGetListItemVariant(first, fontField, valueMap);
        secondary.color = fnGetListItemColor(first, colorField, valueMap);
      }
    }
    else
    {
      const labels = [] as string[];
      const values = [] as string[];
      fieldIdSet.forEach((fieldId, index) =>
      {
        const lineField = compMap[fieldId];
        const text = fnGetListItemText(first, index, lineField, valueMap);
        if(text)
        {
          labels.push(lineField?.label || lineField?.name);
          values.push(text);
        }
      });

      if(values.length)
      {
        (secondary as ILineSecondaryAttributedText).attributedText = [];
      }

      fieldIdSet.forEach((fieldId, index) =>
      {
        const lineField = compMap[fieldId];
        const lineSecondaryText = {} as ILineSecondaryText;
        const label = labels[index];
        const value = values[index];
        lineSecondaryText.text = value;
        lineSecondaryText.variant = fnGetListItemVariant(first, fontField, valueMap);
        lineSecondaryText.color = fnGetListItemColor(first, colorField, valueMap);
        if(value)
        {
          if(showLabels && label)
          {
            const lineSecondaryTextLabel = {} as ILineSecondaryText;
            lineSecondaryTextLabel.text = label + ": ";
            lineSecondaryText.variant = fnGetListItemVariant(first, fontField, valueMap);
            lineSecondaryText.color = "primary";
            (secondary as ILineSecondaryAttributedText).attributedText.push(lineSecondaryTextLabel);
          }

          const _lineSecondaryText = {
            ...lineSecondaryText,
            hasMarkDownText: Boolean(lineField?.type === "info"),
            text: `${value}${index + 1 < values?.length ? ", " : ""}`
          } as ILineSecondaryText;
          (secondary as ILineSecondaryAttributedText).attributedText.push(_lineSecondaryText);
        }
      });
    }
  }
  else if(lineVar?.value || line)
  {
    secondary.text = fnGetListItemText(first, 0, undefined, valueMap);
    secondary.variant = fnGetListItemVariant(first, fontField, valueMap);
    secondary.color = fnGetListItemColor(first, colorField, valueMap);
  }
}

function fnGetListItemText(
  dto: DefnDtoLayoutCardItemLineSegment,
  lineFieldIdSetIndex: number,
  field?: DefnComp,
  valueMap?: FieldValues): string | undefined
{
  const lineFieldId = dto.lineFieldIdSet?.at(lineFieldIdSetIndex);
  const lineVar = dto.lineVar;
  const line = dto.line;
  return (valueMap && lineFieldId)
    ? getFormFieldValueAsText(field, valueMap[lineFieldId])
    : lineVar?.value
      ? lineVar?.value?.join(", ") as string
      : line;
}

function fnGetListItemVariant(
  dto: DefnDtoLayoutCardItemLineSegment,
  field?: DefnComp,
  valueMap?: FieldValues): Variant | undefined
{
  const textSizeFieldId = dto.textSizeFieldId;
  const textSizeVar = dto.textSizeVar;
  const textSize = dto.textSize;
  if(valueMap && textSizeFieldId)
  {
    return getFormFieldValueAsText(field, valueMap[textSizeFieldId]) as Variant;
  }
  else if(textSizeVar)
  {
    return textSizeVar as Variant;
  }
  else if(textSize)
  {
    return textSize as Variant;
  }
}

function fnGetListItemColor(
  dto: DefnDtoLayoutCardItemLineSegment,
  field?: DefnComp,
  valueMap?: FieldValues): TypeTextColor | undefined
{
  const colorFieldId = dto.colorFieldId;
  const colorVar = dto.colorVar;
  const color = dto.color;
  if(valueMap && colorFieldId && valueMap[colorFieldId])
  {
    return getFormFieldValueAsText(field, valueMap[colorFieldId]) as TypeTextColor;
  }
  else if(colorVar?.value)
  {
    return theme.common.colorWithShade(colorVar?.value, colorVar?.shade) as TypeTextColor;
  }
  else if(color?.value)
  {
    return theme.common.colorWithShade(color?.value, color?.shade) as TypeTextColor;
  }
}

function setListItemMedia(
  listItem: IListItemMPSL,
  layoutItem: DefnDtoLayoutCardItem,
  compMap: Record<MetaIdComp, DefnComp>,
  valueMap?: FieldValues)
{
  const mediaFieldIdSet = layoutItem.mediaFieldIdSet;
  const mediaVarSet = layoutItem.mediaVarSet;
  const imageCornerVar = layoutItem.imageCornerVar;

  const isMediaFieldSetHasValues = mediaFieldIdSet && mediaFieldIdSet.length > 0 && valueMap
    ? mediaFieldIdSet.some((mediaFieldId) => valueMap[mediaFieldId] !== undefined && valueMap[mediaFieldId] !== null)
    : false;

  if((mediaFieldIdSet && mediaFieldIdSet?.length > 0) || (mediaVarSet && mediaVarSet?.length > 0))
  {
    if(!isMediaFieldSetHasValues && !(mediaVarSet && mediaVarSet.length > 0))
    {
      listItem.media?.push({
        type: "image",
        src: STR_LIST_ITEM_MEDIA_PLACEHOLDER_SRC,
        width: LIST_ITEM_MEDIA_PLACEHOLDER_SIZE,
        height: LIST_ITEM_MEDIA_PLACEHOLDER_SIZE
      } as IMedia);
    }
    else
    {
      if(isMediaFieldSetHasValues && valueMap)
      {
        listItem.media = [];
        mediaFieldIdSet?.forEach((mediaFieldId) =>
        {
          const mediaField = compMap[mediaFieldId];
          const fieldValue = valueMap[mediaFieldId];
          switch(mediaField.type)
          {
            case "image":
            case "camera":
            {
              const imageValue = (fieldValue as FieldValueImage | FieldValueCamera)?.value;
              const mediaIdImage = imageValue?.mediaIdImage;
              const mediaIdBlurImage = imageValue?.mediaIdBlurImage;

              const tempImgUrl = mediaIdImage ? MediaStore.getMedia(mediaIdImage) : undefined;
              const tempBlurImgUrl = mediaIdBlurImage ? MediaStore.getMedia(mediaIdBlurImage) : undefined;

              const imgUrl = tempImgUrl || getMediaSrc(mediaIdImage);
              const blurImgUrl = tempBlurImgUrl || getMediaSrc(mediaIdBlurImage);

              imageValue && listItem.media?.push({
                type: "image",
                src: imgUrl,
                srcBlur: blurImgUrl,
                thumbnail: blurImgUrl,
                height: imageValue.height,
                width: imageValue.width,
                alt: imageValue.fileName,
                primaryColor: imageValue.primaryColor,
                imageCorner: imageCornerVar
              } as IMedia);
            }
              break;
            case "audio":
            case "voice":
            {
              const audioValue = fieldValue as FieldValueVoice | FieldValueAudio;
              const mediaIdAudio = audioValue?.mediaIdAudio;
              const tempAudioUrl = mediaIdAudio ? MediaStore.getMedia(mediaIdAudio) : undefined;
              const audioUrl = tempAudioUrl || getMediaSrc(mediaIdAudio);

              audioValue && listItem.media?.push({
                type: "audio",
                src: audioUrl,
                alt: audioValue.fileName
              } as IMedia);
            }
              break;
            case "hyperlink":
              const hyperLinkValue = (fieldValue as FieldValueText)?.value;
              hyperLinkValue && listItem.media?.push({
                type: "hyperlink",
                src: (fieldValue as FieldValueText).value
              } as IMedia);

              break;
            case "video":
              const videoValue = fieldValue as FieldValueVideo;
              const mediaIdImage = videoValue?.mediaIdImage;
              const mediaIdBlurImage = videoValue?.mediaIdBlurImage;
              const mediaIdVideo = videoValue?.mediaIdVideo;

              const tempImgUrl = mediaIdImage ? MediaStore.getMedia(mediaIdImage) : undefined;
              const tempBlurImgUrl = mediaIdBlurImage ? MediaStore.getMedia(mediaIdBlurImage) : undefined;
              const tempVideoUrl = mediaIdVideo ? MediaStore.getMedia(mediaIdVideo) : undefined;

              const imgUrl = tempImgUrl || getMediaSrc(mediaIdImage);
              const blurImgUrl = tempBlurImgUrl || getMediaSrc(mediaIdBlurImage);
              const videoUrl = tempVideoUrl || getMediaSrc(mediaIdVideo);

              videoValue && listItem.media?.push({
                type: "video",
                src: videoUrl,
                srcBlur: blurImgUrl,
                thumbnail: imgUrl,
                primaryColor: videoValue.primaryColor
              } as IMedia);
              break;
            case "html":
              const htmlValue = (fieldValue as FieldValueText)?.value;
              htmlValue && listItem.media?.push({
                type: "html",
                src: htmlValue
              } as IMedia);
              break;
          }
        });
      }
      else
      {
        listItem.media = [];
        mediaVarSet && mediaVarSet.forEach((media) =>
        {
          if(media.image)
          {
            const image = media.image;
            listItem.media?.push({
              type: "image",
              src: getMediaSrc(image.mediaIdImage),
              srcBlur: getMediaSrc(image.mediaIdBlurImage),
              thumbnail: getMediaSrc(image.mediaIdBlurImage),
              height: image.height,
              width: image.width,
              alt: image.fileName,
              primaryColor: image.primaryColor
            });
          }
          else if(media.html)
          {
            listItem.media?.push({
              type: "html",
              src: media.html.value?.join(",")
            });
          }
          else if(media.hyperlink)
          {
            listItem.media?.push({
              type: "hyperlink",
              src: media.hyperlink.value
            });
          }
        });
      }
    }
    listItem.showMediaPlaceholder = true;
  }
  else
  {
    listItem.showMediaPlaceholder = false;
  }
}

// endregion

//region formListItem layout

export function mergeLayouts(layout: DefnDtoLayoutCardItem, overlayLayout: DefnDtoLayoutCardItem)
{
  const mergedLayout = cloneDeep(layout);
  loopLayoutItemLine(overlayLayout, (payload) =>
  {
    const segment = payload.segment;
    const lineNumber = payload.lineNumber;
    const segmentName = payload.segmentName;
    switch(lineNumber)
    {
      case "firstLine":
      case "secondLine":
      case "thirdLine":
      case "fourthLine":
      case "fifthLine":
        mergedLayout[lineNumber] = {
          ...mergedLayout[lineNumber],
          [segmentName]: {
            ...mergedLayout[lineNumber]?.[segmentName],
            lineFieldIdSet: segment.lineFieldIdSet?.length
              ? segment.lineFieldIdSet
              : mergedLayout[lineNumber]?.[segmentName]?.lineFieldIdSet,
            lineVar: segment.lineVar || mergedLayout[lineNumber]?.[segmentName]?.lineVar,
            line: segment.line || mergedLayout[lineNumber]?.[segmentName]?.line,
            textSizeFieldId: segment.textSizeFieldId || mergedLayout[lineNumber]?.[segmentName]?.textSizeFieldId,
            textSizeVar: segment.textSizeVar || mergedLayout[lineNumber]?.[segmentName]?.textSizeVar,
            textSize: segment.textSize || mergedLayout[lineNumber]?.[segmentName]?.textSize,
            colorFieldId: segment.colorFieldId || mergedLayout[lineNumber]?.[segmentName]?.colorFieldId,
            colorVar: segment.colorVar || mergedLayout[lineNumber]?.[segmentName]?.colorVar,
            color: segment.color || mergedLayout[lineNumber]?.[segmentName]?.color,
            showLabels: segment.showLabels || mergedLayout[lineNumber]?.[segmentName]?.showLabels
          } as DefnDtoLayoutCardItemLineSegment
        };
        break;
    }
  });

  if(overlayLayout.mediaFieldIdSet?.length)
  {
    mergedLayout.mediaFieldIdSet = overlayLayout.mediaFieldIdSet;
  }
  if(overlayLayout.mediaVarSet?.length)
  {
    mergedLayout.mediaVarSet = overlayLayout.mediaVarSet;
  }
  if(overlayLayout.imageCornerVar?.length)
  {
    mergedLayout.imageCornerVar = overlayLayout.imageCornerVar;
  }

  return mergedLayout;
}

export function loopLayoutItemLine(layoutItem: DefnDtoLayoutCardItem, cb: (payload: ICbLayoutItemPayload) => void)
{
  function fnInsertLine(
    cb: (payload: ICbLayoutItemPayload) => void,
    lineNumber: keyof DefnDtoLayoutCardItem,
    line?: DefnDtoLayoutCardItemLine
  )
  {
    fnInsertSegment(cb, lineNumber, "first", line?.first);
    fnInsertSegment(cb, lineNumber, "middle", line?.middle);
    fnInsertSegment(cb, lineNumber, "caption", line?.caption);
  }

  function fnInsertSegment(
    cb: (payload: ICbLayoutItemPayload) => void,
    lineNumber: keyof DefnDtoLayoutCardItem,
    segmentName: keyof DefnDtoLayoutCardItemLine,
    segment?: DefnDtoLayoutCardItemLineSegment
  )
  {
    if(segment)
    {
      cb({
        lineNumber,
        segmentName,
        line: layoutItem[lineNumber] as DefnDtoLayoutCardItemLine,
        segment: segment
      });
    }
  }

  fnInsertLine(cb, "firstLine", layoutItem?.firstLine);
  fnInsertLine(cb, "secondLine", layoutItem?.secondLine);
  fnInsertLine(cb, "thirdLine", layoutItem?.thirdLine);
  fnInsertLine(cb, "fourthLine", layoutItem?.fourthLine);
  fnInsertLine(cb, "fifthLine", layoutItem?.fifthLine);
}

// endregion

export function prepareFormForListItem(
  defnFormUi: DefnFormUi,
  defnFieldArray: DefnFieldFormList,
  rowId: RowId)
{
  if(!defnFieldArray.displaySectionId || defnFormUi.compMap[defnFieldArray.displaySectionId]?.type !== "section")
  {
    return undefined;
  }
  const defnForm = cloneDeep(defnFormUi);
  const defnFieldFormListId = defnFieldArray.metaId;
  const displayCompositeId = defnFieldArray.displaySectionId;

  const metaIdMap = {} as Record<MetaIdField, string>;
  const compMap = {} as Record<string, DefnComp>;

  compMap[defnFieldFormListId] = defnFieldUiToDefnField(defnFieldArray);
  defnForm.displayCompositeId = displayCompositeId;

  Object.values(defnForm.compMap).forEach(_comp =>
  {
    const comp = _comp as DefnFieldUi;
    delete comp._key;
    delete comp._parenMap;
    metaIdMap[comp.metaId] = dtoToFieldFormListItemsKey(defnFieldArray, rowId, comp.metaId);
    compMap[comp.metaId] = comp;
  });

  const newCompMap = replaceMetaIds(compMap, metaIdMap);

  return {
    ...createDefaultDefnForm(newCompMap),
    displayCompositeId: dtoToFieldFormListItemsKey(defnFieldArray, rowId, displayCompositeId)
  } as DefnFormUi;
}

function replaceMetaIds(
  compMap: Record<string, DefnComp>,
  metaIdMap: Record<MetaIdField, string>): Record<string, DefnComp>
{
  let str = JSON.stringify(compMap);
  Object.entries(compMap).forEach(([key, comp]) =>
  {
    const metaId = key;
    const newMetaId = metaIdMap[metaId];
    if(newMetaId)
    {
      str = str.replaceAll(`"${metaId}"`, `"${newMetaId}"`);
    }
  });

  return JSON.parse(str);
}

function defnFieldUiToDefnField(defnFieldUi: DefnFieldUi): DefnField
{
  if(defnFieldUi._key)
  {
    const newField = cloneDeep(defnFieldUi);
    delete newField._key;
    delete newField._parenMap;
    return newField;
  }
  return defnFieldUi;
}

export function removeUnnecessaryFieldValues(valueMap: FieldValues)
{
  Object.keys(valueMap).forEach(key =>
  {
    if(!(isFieldId(key) || isGridId(key))
      || isDynamicValueFieldId(key))
    {
      delete valueMap[key];
    }
  });
}

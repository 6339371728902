import {InputBase} from "@mui/material";
import {Button} from "@mui/material";
import {useTheme} from "@mui/material";
import {Typography} from "@mui/material";
import {Box} from "@mui/material";
import {GoogleMap} from "@react-google-maps/api";
import {MarkerF} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {useCallback} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import React from "react";
import {useState} from "react";
import {Suggestion} from "react-places-autocomplete";
import {geocodeByAddress} from "react-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import {FieldValueLocation} from "../../../api/meta/base/dto/FieldValueLocation";
import {STR_FAIL_TO_LOAD_GOOGLE_MAP} from "../../../base/plus/ConstantsPlus";
import {STR_ALLOW_LOCATION_PERMISSION} from "../../../base/plus/ConstantsPlus";
import {MAIN_FOOTER_DIALOG_WIDTH} from "../../../base/plus/ConstantsPlus";
import {isoDateTimeNow} from "../../../base/plus/DatePlus";
import {getGeoAddress} from "../../../base/plus/LocationPlus";
import {getLocation} from "../../../base/plus/LocationPlus";
import {browserLocationPermission} from "../../../base/plus/LocationPlus";
import {toGeoPoint} from "../../../base/plus/LocationPlus";
import {getFormattedAddressFromApiResult} from "../../../base/plus/LocationPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {ILatLng} from "../../../base/types/TypesStudio";
import {logError} from "../../../base/util/AppLog";
import {usePageCtx} from "../../ctx/CtxPage";
import loadGoogleMapScript from "../../googleMap/MapLoadScript";
import IconStrip from "../icon/IconStrip";
import {DividerHorizontal} from "../layout/DividerHorizontal";
import {DividerVertical} from "../layout/DividerVertical";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawFadeLoader from "../raw/RawFadeLoader";
import RawNothingHere from "../raw/RawNothingHere";
import DialogAtom from "./DialogAtom";

const libraries = ["places"] as Libraries;
const locationDialogWidth = 900;
const locationDialogHeight = 700;
const locationDialogHeightForMobile = 620;

export default function DialogLocationSearch(props: {
  fullScreen?: boolean,
  defaultGeoPoint?: ILatLng
  onClose: () => void,
  googleMapApiKey: string,
  onSave: (data: FieldValueLocation) => void
})
{
  const pageCtx = usePageCtx();
  const fullScreen = props.fullScreen;
  const defaultGeoPoint = props.defaultGeoPoint;
  const googleMapApiKey = props.googleMapApiKey;

  const onSave = (data: FieldValueLocation) =>
  {
    props.onSave(data);
    pageCtx.showDialog(undefined);
  };

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<Error | undefined>(undefined);

  useEffect(() =>
  {
    const mapLoading = loadGoogleMapScript(googleMapApiKey, libraries, setIsLoaded, setLoadError);
    return mapLoading;

  }, [googleMapApiKey]);

  if(loadError)
  {
    const errorMsg = loadError?.message
      ? loadError?.message
      : STR_FAIL_TO_LOAD_GOOGLE_MAP;

    return (
      <DialogAtom
        title={"Select location"}
        onClose={props.onClose}
        content={
          <RawNothingHere
            helperTextData={{
              title: errorMsg
            }}
          />
        }
        contentWidth={locationDialogWidth}
        contentHeight={fullScreen ? locationDialogHeightForMobile : locationDialogHeight}
      />
    );
  }

  if(!isLoaded)
  {
    return (
      <RawFadeLoader />
    );
  }

  return (
    <DialogAtom
      title={"Select location"}
      fullScreen={fullScreen}
      headerIcons={fullScreen ? [] : ["openInFull"]}
      onClose={props.onClose}
      content={
        <LocationSearch
          onSave={onSave}
          fullScreen={fullScreen}
          defaultGeoPoint={defaultGeoPoint}
        />
      }
      contentWidth={locationDialogWidth}
      contentHeight={fullScreen ? locationDialogHeightForMobile : locationDialogHeight}
    />
  );
}

const LocationSearch = (props: {
  fullScreen?: boolean,
  defaultGeoPoint?: ILatLng,
  onSave?: (data: FieldValueLocation) => void
}) =>
{
  const onSave = props.onSave;
  const fullScreen = props.fullScreen;
  const defaultGeoPoint = props.defaultGeoPoint;

  const [address, setAddress] = useState<string>("");
  const [selectedMarker, setSelectedMarker] = useState<ILatLng[] | undefined>();
  const [coordinates, setCoordinates] = useState<ILatLng>();
  const [searchResultMarker, setSearchResultMarker] = useState<Suggestion | undefined>();
  const [accessPermission, setAccessPermission] = useState<Boolean | undefined>(undefined);
  const [markerDragAddress, setMarkerDragAddress] = useState<string | undefined>(undefined);

  const theme = useTheme();
  const map = useRef<google.maps.Map | undefined>(undefined);
  const gapStd = theme.common.gapStd;
  const gapHalf = theme.common.gapHalf;

  const onSelectItem = async(value: string) =>
  {
    if(value)
    {
      const results = await geocodeByAddress(value);

      onClickSave(results, value);
      setAddress(value);
    }
  };

  const onClickSave = (geoCodeResults: google.maps.GeocoderResult[], addressValue?: string) =>
  {
    if(geoCodeResults)
    {
      const address = getFormattedAddressFromApiResult(geoCodeResults);

      onSave && onSave({
        value: {
          address: addressValue ? addressValue : address.address,
          city: address.city,
          country: address.country,
          geoPoint: toGeoPoint(address.geoPoint.lat, address.geoPoint.lng),
          dateTime: isoDateTimeNow()
        }
      } as FieldValueLocation);
    }
  };

  const mapStyles = {
    height: "100%",
    width: "100%",
    flexGrow: 1
  };

  const onLoadMap = (_map: google.maps.Map) =>
  {
    map.current = _map;
    if(map.current)
    {
      const bounds = new window.google.maps.LatLngBounds();
      if(selectedMarker)
      {
        selectedMarker.forEach((location) =>
        {
          bounds.extend({
            lat: location.lat,
            lng: location.lng
          });
        });
      }
      map.current.setZoom(18);
      map.current.setOptions({
        minZoom: 3,
        maxZoom: 20
      });
    }
  };

  const onClickCurrentLocation = () =>
  {
    if(navigator.geolocation)
    {
      if(coordinates)
      {
        getGeoAddress(coordinates, (geoCodeResults) =>
        {
          onClickSave(geoCodeResults);
        });
      }
    }
  };

  const setMarkerOnSearch = () =>
  {
    if(searchResultMarker)
    {
      const geocoder = new google.maps.Geocoder();
      let _searchResultMarkers = [] as ILatLng[];
      geocoder.geocode({"address": searchResultMarker.description}, function(results, status)
      {
        if(status === google.maps.GeocoderStatus.OK)
        {
          if(results)
          {
            let lat = results[0].geometry.location.lat();
            let lng = results[0].geometry.location.lng();
            _searchResultMarkers.push({
              lat: lat,
              lng: lng
            });
          }
        }

        const bounds = new window.google.maps.LatLngBounds();

        _searchResultMarkers.forEach((location) =>
        {
          bounds.extend({
            lat: location.lat,
            lng: location.lng
          });
        });
        map.current?.fitBounds(bounds);
        map.current?.setZoom(17);

        setSelectedMarker(_searchResultMarkers);
      });
    }
  };

  const onMarkerDragEnd = (markerEvent: google.maps.MapMouseEvent) =>
  {
    if(markerEvent.latLng)
    {
      const markerLatLng = {
        lat: markerEvent.latLng.lat(),
        lng: markerEvent.latLng.lng()
      } as ILatLng;

      getGeoAddress(markerLatLng, (formatted_address) =>
      {
        const _address = getFormattedAddressFromApiResult(formatted_address);
        if(_address)
        {
          setMarkerDragAddress(_address.address);
        }
      });
    }
  };

  const onChangeAutoComplete = (value: string) =>
  {
    setAddress(value);
  };

  const onClickRetry = useCallback(() =>
  {
    browserLocationPermission(result =>
    {
      setAccessPermission(result);
    });
  }, []);

  useEffect(() =>
  {
    browserLocationPermission(result =>
    {
      setAccessPermission(result);
    });
  }, [onClickRetry]);

  useEffect(() =>
  {
    if(accessPermission)
    {
      if(defaultGeoPoint)
      {
        setCoordinates(defaultGeoPoint);
      }
      else
      {
        getLocation(cbResult =>
        {
          setCoordinates(cbResult);
        });
      }
    }
  }, [accessPermission]);

  useEffect(() =>
  {
    setMarkerOnSearch();
  }, [searchResultMarker]);

  if(!accessPermission)
  {
    return (
      <RetryLocationPermission
        onClickRetry={onClickRetry}
      />
    );
  }

  return (
    <Box
      width={"100%"}
      flexGrow={1}
      display={"flex"}
      sx={{
        overflowX: "hidden",
        flexDirection: fullScreen ? "column-reverse" : "row"
      }}
    >
      <LayoutFlexRow
        width={fullScreen ? "100%" : "40%"}
        maxWidth={px(MAIN_FOOTER_DIALOG_WIDTH)}
        height={fullScreen ? "50%" : "100%"}
        alignItems={"flex-start"}
      >
        <PlacesAutocomplete
          value={address}
          shouldFetchSuggestions={true}
          debounce={500}
          onChange={onChangeAutoComplete}
          onError={(err) => logError(" Search location error ", err)}
          onSelect={onSelectItem}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) =>
          {
            if(suggestions.length > 0)
            {
              setMarkerDragAddress(undefined);
              setSearchResultMarker(suggestions[0]);
            }
            return (
              <Box
                width={"100%"}
                height={"100%"}
                display={"flex"}
                flexDirection={"column"}
                p={px(gapStd)}
                flexGrow={1}
              >
                <InputBase
                  placeholder={"Search places"}
                  fullWidth={true}
                  {...getInputProps()}
                  value={address}
                  sx={{
                    height: px(theme.common.heightInbox),
                    pl: px(gapStd),
                    pr: px(gapStd),
                    mb: px(12),
                    borderRadius: px(theme.common.heightInbox / 2),
                    borderColor: theme.common.borderColor,
                    borderWidth: "1px",
                    borderStyle: "solid"
                  }}
                />
                {
                  coordinates
                    ?
                    <Box>
                      <CurrentLocationResult
                        onClickCurrentLocation={onClickCurrentLocation}
                      />
                      <Box
                        mr={-2}
                        height={"100%"}
                        overflow={"auto"}
                        flexGrow={1}
                      >
                        {loading && <Typography>Loading...</Typography>}
                        {
                          suggestions.length
                            ?
                            suggestions.map(suggestion =>
                            {
                              const style = {
                                backgroundColor: suggestion.active ? theme.common.bgcolorHover : undefined,
                                cursor: "pointer",
                                padding: px(gapHalf),
                                fontSize: px(14)
                              };
                              return (
                                <LayoutFlexCol
                                  key={suggestion.placeId}
                                >
                                  <Box
                                    width={"100%"}
                                    {...getSuggestionItemProps(suggestion, {style})}
                                  >
                                    {suggestion.description}
                                  </Box>
                                  <DividerHorizontal />
                                </LayoutFlexCol>
                              );
                            })
                            : markerDragAddress
                              ?
                              <MapDraggableListItem
                                address={markerDragAddress}
                                onSelectItem={onSelectItem}
                              />
                              : null
                        }
                      </Box>
                    </Box>
                    : <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      overflow={"hidden"}
                      bgcolor={"transparent"}
                      height={"100%"}
                      flexGrow={1}
                    >
                      <RawFadeLoader />
                    </Box>
                }

              </Box>
            );
          }
          }
        </PlacesAutocomplete>
      </LayoutFlexRow>
      {
        !fullScreen &&
        <DividerVertical />
      }
      <LayoutFlexCol
        width={"100%"}
        height={fullScreen ? "50%" : "100%"}
        flex={fullScreen ? undefined : 1}
      >
        {
          coordinates
            ?
            <GoogleMap
              mapContainerStyle={mapStyles}
              onLoad={(map) => onLoadMap(map)}
              center={coordinates}
              options={{
                fullscreenControl: false,
                zoomControl: false,
                streetViewControl: false,
                disableDefaultUI: true

              }}
            >
              <MapMarker
                defaultCenter={coordinates}
                selectedMarker={selectedMarker}
                onMarkerDragEnd={onMarkerDragEnd}
              />
            </GoogleMap>
            :
            <RawNothingHere
              helperTextData={{
                title: "Loading..."
              }}
            />
        }
      </LayoutFlexCol>
    </Box>
  );
};

function MapMarker(props: {
  defaultCenter: ILatLng,
  selectedMarker?: ILatLng[],
  onMarkerClick?: (markerEvent: google.maps.MapMouseEvent) => void
  onMarkerDragEnd?: (markerEvent: google.maps.MapMouseEvent) => void
})
{

  const selectedMarker = props.selectedMarker;
  const defaultCenter = props.defaultCenter;
  const onMarkerClick = props.onMarkerClick;
  const onMarkerDragEnd = props.onMarkerDragEnd;

  return (selectedMarker && selectedMarker.length > 0
      ?
      selectedMarker.map((values, index) =>
      {
        return (
          <MarkerF
            key={index}
            position={values}
            draggable={true}
            onClick={onMarkerClick}
            onDragEnd={onMarkerDragEnd}
          />
        );
      })
      :
      <MarkerF
        position={defaultCenter}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
  );
}

function MapDraggableListItem(props: {
  address: string,
  onSelectItem: (value: string) => void
})
{
  const theme = useTheme();
  return (
    <Box
      width={"100%"}
      onClick={() =>
      {
        props.onSelectItem(props.address);
      }}
      sx={{
        backgroundColor: theme.common.bgcolorHover,
        cursor: "pointer",
        padding: px(gapHalf),
        fontSize: px(14)
      }}
    >
      {props.address}
    </Box>
  );
}

function CurrentLocationResult(props: {
  onClickCurrentLocation: () => void
})
{
  const label = "Share your current location";
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  return (
    <LayoutFlexRow
      justifyContent={"flex-start"}
      alignItems={"center"}
      height={px(30)}
      pt={px(gapHalf)}
      pb={px(gapHalf)}
      cursorPointer={true}
      onClick={props.onClickCurrentLocation}
    >

      <IconStrip
        value={"currentLocation"}
        color={theme.palette.primary.main}
      />
      <Typography
        color={theme.palette.primary.main}
        sx={{
          ml: px(gapHalf)
        }}
      >
        {label}
      </Typography>
    </LayoutFlexRow>
  );
}

function RetryLocationPermission(props: {
  onClickRetry?: () => void
})
{
  const theme = useTheme();
  const onClickRetry = props.onClickRetry;

  return (
    <LayoutFlexCol
      width={"100%"}
      height={"100%"}
      bgcolor={theme.common.bgcolorActionBar}
    >
      <Typography
        color={theme.palette.text.disabled}
        sx={{
          paddingBottom: px(gapStd)
        }}
      >
        {STR_ALLOW_LOCATION_PERMISSION}
      </Typography>
      <Button
        disableFocusRipple={true}
        variant={"outlined"}
        onClick={onClickRetry}
      >
        Retry
      </Button>
    </LayoutFlexCol>
  );
}

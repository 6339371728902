import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {useState} from "react";
import React, {useCallback, useEffect, useMemo} from "react";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {StudioDtoLayoutFormContentEditor} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentEditor";
import {StudioDtoLayoutFormContentReport} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentReport";
import {StudioMapOfLayoutFormContent} from "../../../../api/meta/base/dto/StudioMapOfLayoutFormContent";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumDefnFormContentLayoutType} from "../../../../api/meta/base/Types";
import {MetaIdLayoutFormContent} from "../../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf, gapStd} from "../../../../base/plus/ThemePlus";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListItem, TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IAsidePropsStudioFormContentLayout} from "../../../../base/types/TypesAside";
import {IFormRef} from "../../../../base/types/TypesForm";
import {ILineSecondary} from "../../../../base/types/TypesGlobal";
import {IMainPropsStudioFormBuilderFormContentLayout} from "../../../../base/types/TypesMain";
import {IMainPropsStudio} from "../../../../base/types/TypesMain";
import {TypeListSortType} from "../../../../base/types/TypesStudio";
import {IStudioJumpPath} from "../../../../base/types/TypesStudio";
import {useStepListDefaultConfig} from "../../../../routes/studio/app/UiStudioPlus";
import {useAppSelector} from "../../../app/AppHooks";
import helperTextJsonData from "../../../atom/assets/PlaceholderTextStudio.json";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import RawSearch from "../../../atom/raw/RawSearch";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import {List, ListClickVariant} from "../../../list/List";
import {useValidationErrorList} from "../../../list/validation/ListValidation";
import Form from "../../viewer/Form";
import {sortFormItemList} from "../base/FormBuilderPlus";
import useCopyPasteStudioFormItem from "../base/StudioFormCopyPaste";
import {fieldGap1} from "../base/TypesFormBuilder";
import {getFieldGap} from "../base/TypesFormBuilder";
import {TypeActionSortFormList} from "../base/TypesFormBuilder";
import {IFormBuilderProps} from "../base/TypesFormBuilder";

export default function FormBuilderFormContentLayout(props: IFormBuilderProps)
{
  const theme = useTheme();
  const appCtx = useAppCtx();
  const pageCtx = usePageCtx();

  const [selectedMetaIdContentLayout, setSelectedMetaIdContentLayout] = useState<MetaIdLayoutFormContent>();

  const {
    copyStudioContentLayout
  } = useCopyPasteStudioFormItem();

  const formStore = props.formStore;
  const setupMode = props.setupMode;
  const readOnly = props.readOnly;
  const selectListMap = props.selectListMap;
  const cbOnSubmit = props.cbOnSubmit;
  const cbOnRemove = props.cbOnRemove;
  const cbOnAddNewVar = props.cbOnAddNewVar;
  const cbShowVar = props.cbOnShowVar;
  const cbOnEditVar = props.cbOnEditVar;
  const cbGetCliCode = props.cbGetCliCode;

  const formMap = formStore.formMap;
  const validationResult = formStore.validationResult;

  const mainProps = appCtx.getMainProps() as IMainPropsStudioFormBuilderFormContentLayout;
  const selectList = mainProps.selectList;
  const entId = mainProps.itemId;
  const formId = mainProps.formId;

  const listName = useAppSelector(state => selectList(state).listName);

  const form = useMemo(() => (formMap?.map && formId) ? formMap.map[formId] : undefined, [formMap?.map, formId]);
  const contentLayoutMap = form?.layoutMap;

  const onClickItemInfo = useValidationErrorList(selectList, "layoutMap", undefined, validationResult);
  const cbFormRef = useMemo(() => ({} as IFormRef), []);
  const layoutFormContent = formMap?.map[formId].layoutMap;

  const cbOnContentLayoutSubmit = useCallback((
    studioContentLayout: StudioDtoLayoutFormContentReport,
    isDuplicate?: boolean) =>
  {
    cbOnSubmit && cbOnSubmit(
      "contentLayout",
      {
        formId,
        contentLayout: studioContentLayout,
        isDuplicate: Boolean(isDuplicate)
      }
    );
  }, [cbOnSubmit, formId]);

  const cbOnContentLayoutRemove = useCallback((contentLayoutId: MetaIdLayoutFormContent) =>
  {
    if(layoutFormContent?.asideDefaultLayoutId === contentLayoutId)
    {
      cbFormRef.setValue(fieldAsideDefaultLayoutId, null);
    }

    cbOnRemove && cbOnRemove(
      "contentLayout",
      {
        contentLayoutId: contentLayoutId
      }
    );
  }, [cbOnRemove, layoutFormContent?.asideDefaultLayoutId]);

  const onClickListItem = useCallback((
    metaIdVisibilityRule: TypeListItemId,
    jumpPath?: IStudioJumpPath[],
    item?: IListItem
  ) =>
  {
    appCtx.setAsideProps({
      type: "FormContentLayout",
      formId: formId,
      formStore: formStore,
      setupMode: setupMode,
      readOnly: readOnly,
      selectListMap: selectListMap,
      cbOnContentLayoutSubmit: cbOnContentLayoutSubmit,
      cbOnContentLayoutRemove: cbOnContentLayoutRemove,
      metaId: metaIdVisibilityRule,
      jumpPath: jumpPath,
      reset: true,
      cbOnShowVar: cbShowVar,
      cbOnEditVar: cbOnEditVar,
      cbOnAddNewVar: cbOnAddNewVar
    } as IAsidePropsStudioFormContentLayout);

    setSelectedMetaIdContentLayout(metaIdVisibilityRule);
  }, [
    appCtx,
    formId,
    formStore,
    setupMode,
    readOnly,
    selectListMap,
    cbOnContentLayoutSubmit,
    cbOnContentLayoutRemove,
    cbShowVar,
    cbOnEditVar,
    cbOnAddNewVar
  ]);

  const onClickMenu = useCallback((menuAnchor: Element, itemId: TypeListItemId, item?: IListItem) =>
    {
      pageCtx.showMenu(menuAnchor,
        {
          "Copy": () => copyStudioContentLayout(item?.userField?.value as StudioDtoLayoutFormContentReport),
          "Get CLI code": () => cbGetCliCode && cbGetCliCode(entId, itemId),
          "Duplicate": () => cbOnContentLayoutSubmit(item?.userField?.value as StudioDtoLayoutFormContentReport, true),
          "Remove": () =>
          {
            cbOnContentLayoutRemove(itemId);
            if(selectedMetaIdContentLayout === itemId)
            {
              appCtx.setAsideProps(undefined);
            }
          },
          "gap": undefined,
          "Move up": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("contentLayout", {
              contentLayoutId: itemId,
              direction: "up"
            }),
            disabled: !item?.userField?.showMoveUp
          },
          "Move down": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("contentLayout", {
              contentLayoutId: itemId,
              direction: "down"
            }),
            disabled: !item?.userField?.showMoveDown
          },
          "Move top": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("contentLayout", {
              contentLayoutId: itemId,
              direction: "top"
            }),
            disabled: !item?.userField?.showMoveUp
          },
          "Move bottom": {
            onClick: () => props.cbOnMoveItem && props.cbOnMoveItem("contentLayout", {
              contentLayoutId: itemId,
              direction: "bottom"
            }),
            disabled: !item?.userField?.showMoveDown
          }
        }
      );
    },
    [
      copyStudioContentLayout,
      cbGetCliCode,
      entId,
      cbOnContentLayoutSubmit,
      cbOnContentLayoutRemove,
      selectedMetaIdContentLayout
    ]
  );

  const cbOnClick = useCallback((
    menuAnchor: Element,
    itemId: TypeListItemId,
    item: IListItem,
    variant: ListClickVariant,
    pickValue?: boolean,
    selected?: boolean
  ) =>
  {
    switch(variant)
    {
      case "listItem":
        selected && onClickListItem(itemId, undefined, item);
        break;
      case "spotMenu":
        !readOnly && onClickMenu(menuAnchor, itemId, item);
        break;
      case "spotInfo":
        onClickItemInfo(menuAnchor, itemId);
        break;
    }
  }, [onClickItemInfo, onClickListItem, onClickMenu, readOnly]);

  const cbOnClickSort = useCallback((type: TypeListSortType) =>
  {
    const actionSort = {
      sortType: type,
      setKey: "layoutContentList"
    } as TypeActionSortFormList;
    const studioForm = sortFormItemList(actionSort, form);

    if(studioForm)
    {
      cbOnSubmit && cbOnSubmit("form", {
        formId,
        form: studioForm
      });
    }
  }, [form, formId]);

  useEffect(() =>
  {
    if(contentLayoutMap)
    {
      loadFormContentLayoutList(listName, contentLayoutMap);
    }
  }, [contentLayoutMap, listName]);

  useEffect(() =>
  {
    if(layoutFormContent?.asideDefaultLayoutId)
    {
      cbFormRef.setValue(fieldAsideDefaultLayoutId,
        fnRawValueToFieldValue("pickText", layoutFormContent.asideDefaultLayoutId)
      );
    }

  }, [layoutFormContent?.asideDefaultLayoutId]);

  useStepListDefaultConfig(selectList, "layoutMap", onClickListItem, false, true);

  const dtoToValue = useCallback((dto?: StudioMapOfLayoutFormContent) =>
  {
    if(dto)
    {
      return {
        [fieldAsideDefaultLayoutId]: fnRawValueToFieldValue("pickText", dto.asideDefaultLayoutId)
      };
    }
  }, [contentLayoutMap]);

  const onWatch = useCallback((key: string, value: any) =>
  {
    if(key === fieldAsideDefaultLayoutId)
    {
      if(cbOnSubmit)
      {
        cbOnSubmit("defaultAsideLayout", {
          formId,
          defaultContentLayout: fnFieldValueToRawValue("pickText", value, "undefined") as MetaIdLayoutFormContent
        });
      }
    }
  }, [contentLayoutMap, formId]);

  return (
    <>
      <LayoutFlexRow
        width={"100%"}
        pl={px(gapStd)}
        pt={px(gapHalf)}
        justifyContent={"flex-start"}
      >
        <RawIconStrip
          iconStrip={["backIos"]}
          onClick={() =>
          {
            appCtx.setMainProps({
              ...mainProps,
              jumpStepItem: "FormDetails"
            } as IMainPropsStudio);
            appCtx.setAsideProps(undefined);
          }}
        />
        <RawHighlighter
          value={"Form content layouts"}
          variant={"body1"}
        />
      </LayoutFlexRow>

      <RawSearch
        placeHolder={"Search content layouts"}
        onSearchBindListName={listName}
        showSorting={!readOnly}
        onClickSorting={cbOnClickSort}
      />

      <LayoutFlexCol
        flexGrow={1}
        width={"100%"}
        pl={px(gapStd)}
        pr={px(gapStd)}
      >
        <Box
          width={"100%"}
          bgcolor={theme.common.bgcolorContent}
          border={`1px solid ${theme.common.borderColor}`}
          borderBottom={"none"}
        >
          <Form
            cbRef={cbFormRef}
            defnForm={defnForm(contentLayoutMap)}
            store={formStore}
            onWatch={onWatch}
            formReadonly={readOnly}
            initValues={dtoToValue(layoutFormContent)}
          />
        </Box>
        <Box
          width={"100%"}
          height={"100%"}
          bgcolor={theme.common.bgcolorContent}
          border={`1px solid ${theme.common.borderColor}`}
          borderTop={"none"}
        >
          <List
            selectList={selectList}
            emptyComp={
              <RawNothingHere
                helperTextData={helperTextJsonData.formContentLayouts}
              />}
            onClickListItem={cbOnClick}
            emptySearch={STR_NOTHING_HERE}
          />
        </Box>
      </LayoutFlexCol>
    </>
  );
}

function loadFormContentLayoutList(listName: string, contentLayoutMap: StudioMapOfLayoutFormContent)
{
  const uiItemIds = [] as TypeListItemId[];
  const uiItemsById = {} as IListItemsById;

  const contentLayoutKeys = contentLayoutMap.keys;

  contentLayoutKeys.forEach((itemId, index) =>
  {
    const contentLayout = contentLayoutMap.map[itemId];
    uiItemIds.push(itemId);

    const showMoveUp = contentLayoutKeys.length !== 1 && index !== 0;
    const showMoveDown = contentLayoutKeys.length !== 1 && index !== contentLayoutKeys.length - 1;
    const layoutType = toLabel((contentLayout.type ?? "report") as EnumDefnFormContentLayoutType);

    const secondary: ILineSecondary = {};

    if(contentLayout.description && contentLayout.description.length > 0)
    {
      secondary.text = contentLayout.description;
    }
    else
    {
      secondary.text = "No description";
      secondary.color = "textDisabled";
    }

    if(contentLayout.type === "editor")
    {
      const editorLayoutRenderingMode = (contentLayout as StudioDtoLayoutFormContentEditor).editorLayoutRenderingMode;
      secondary.caption = editorLayoutRenderingMode
        ? {
          type: "text",
          text: toLabel(editorLayoutRenderingMode)
        }
        : undefined;
    }

    uiItemsById[itemId] = {
      type: "ps",
      primary: {
        text: `${contentLayout.name}`,
        caption: {
          text: layoutType,
          ignoreSelection: true
        }
      },
      secondary: secondary,
      userField: {
        value: contentLayout,
        showMoveUp: showMoveUp,
        showMoveDown: showMoveDown
      }
    } as IListItemAPSA;

  });

  dispatchList(listName, listRefresh({
    itemIds: uiItemIds,
    itemsById: uiItemsById
  }));
}

const fieldAsideDefaultLayoutId = "asideDefaultLayoutId";
const fieldLabel = "fieldLabel";

function defnForm(contentLayoutMap?: StudioMapOfLayoutFormContent)
{
  const userOptions = {
    keys: [] as MetaIdVar[],
    map: {} as Record<MetaIdVar, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  if(contentLayoutMap)
  {
    const contentLayoutKeys = contentLayoutMap.keys;

    contentLayoutKeys.forEach((itemId) =>
    {
      const contentLayout = contentLayoutMap.map[itemId];

      if(contentLayout.type === "editor")
      {
        userOptions.keys.push(contentLayout.metaId);
        userOptions.map[contentLayout.metaId] = {
          metaId: contentLayout.metaId,
          value: contentLayout.name
        };
      }

    });
  }

  return createDefaultDefnFormStudio({
    [fieldAsideDefaultLayoutId]: {
      type: "pickText",
      label: "Aside default layout",
      metaId: fieldAsideDefaultLayoutId,
      optionMap: userOptions,
      name: fieldAsideDefaultLayoutId
    } as DefnFieldPickText,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldLabel]: {
      type: "label",
      metaId: fieldLabel,
      name: fieldLabel,
      label: "Layouts",
      disabled: true
    } as DefnFieldLabel,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      name: defaultSectionKey,
      fieldIdSet: [fieldAsideDefaultLayoutId, fieldGap1, fieldLabel]
    } as DefnSection

  } as Record<MetaIdField, DefnField>);
}

import {FormControl} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldRefUser} from "../../../../api/meta/base/dto/DefnFieldRefUser";
import {DefnFieldUserId} from "../../../../api/meta/base/dto/DefnFieldUserId";
import {FieldValueEntUserId} from "../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueText} from "../../../../api/meta/base/dto/FieldValueText";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import FieldRawTextField from "../raw/FieldRawTextField";

export default function FieldRawUser(props: {
  defn: DefnFieldUserId | DefnFieldRefUser
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {error} = fieldState;
        const isError = Boolean(error);
        const fieldValue = field.value as FieldValueEntUserId | null;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              <FieldRawTextField
                {...props}
                value={fnRawValueToFieldValue("text", fieldValue?.displayField) as FieldValueText | undefined}
                onBlur={field.onBlur}
                error={error}
                ref={field.ref}
                clearButton={Boolean(fieldValue)}
                onChange={field.onChange}
              />
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

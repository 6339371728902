import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {EnumArrayDefnPluginMode} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPaymentStatus} from "../../../../api/meta/base/Types";
import {EnumArrayDefnGridRenderingMode} from "../../../../api/meta/base/Types";
import {EnumArrayDefnSortOrder} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemePickMultiVariant} from "../../../../api/meta/base/Types";
import {EnumDefnRenderingKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnTextStyle} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDriveSheetFieldLayoutOn} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDriveSheetLayoutFor} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRowProperty} from "../../../../api/meta/base/Types";
import {EnumArrayDefnUserContext} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindSetOfUser} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDynamicOperator} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRefreshOn} from "../../../../api/meta/base/Types";
import {EnumArrayDefnEditorLayoutRenderingMode} from "../../../../api/meta/base/Types";
import {EnumArrayDefnButtonTargetType} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRoles} from "../../../../api/meta/base/Types";
import {EnumArrayDefnLockOperation} from "../../../../api/meta/base/Types";
import {EnumArrayDefnVisibilityActionOn} from "../../../../api/meta/base/Types";
import {EnumArrayDefnCaptureMode} from "../../../../api/meta/base/Types";
import {EnumArrayDefnStoreItem} from "../../../../api/meta/base/Types";
import {EnumArrayDefnUserProperty} from "../../../../api/meta/base/Types";
import {EnumArrayDefnTableStyle} from "../../../../api/meta/base/Types";
import {EnumArrayDefnAutomationTerminateKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnEmptyFieldVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRenderingKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRemoveVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnUpdateVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnInsertVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDateOccurrence} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPosition} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRepeatFrequencyKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnLogOperationKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDeeplinkExpiry} from "../../../../api/meta/base/Types";
import {EnumArrayDefnQuarter} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDeeplinkConstraint} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindDeeplink} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPromptAction} from "../../../../api/meta/base/Types";
import {EnumArrayDefnUserSettingValue} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindFormComposite} from "../../../../api/meta/base/Types";
import {EnumArrayDefnAutomationSource} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeDividerKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnVisibilityOperator} from "../../../../api/meta/base/Types";
import {EnumArrayDefnVisibilityAction} from "../../../../api/meta/base/Types";
import {EnumArrayDefnVideoFormat} from "../../../../api/meta/base/Types";
import {EnumArrayDefnUserSettingOptions} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeTabVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeStroke} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeSectionVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPlacement} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemePickVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeImageCorner} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeFormVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeFieldMargin} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeDirection} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeColorShade} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeColor} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeButtonVariant} from "../../../../api/meta/base/Types";
import {EnumArrayDefnThemeButtonSize} from "../../../../api/meta/base/Types";
import {EnumArrayDefnSetupKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnRowAuditTrail} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPluginSecurityAccess} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPluginResources} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPluginApiMethod} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPermission} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPaymentPlan} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPaymentMethod} from "../../../../api/meta/base/Types";
import {EnumArrayDefnMapPinShape} from "../../../../api/meta/base/Types";
import {EnumArrayDefnLayoutGridKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnLayoutCardFilterKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindTranslation} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindAutomationStep} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindSpreadsheetEvent} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindScheduledEvent} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindReport} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindRating} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindImport} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindHyperlink} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindButton} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindAction} from "../../../../api/meta/base/Types";
import {EnumArrayDefnFuncArg} from "../../../../api/meta/base/Types";
import {EnumArrayDefnEntLockReason} from "../../../../api/meta/base/Types";
import {EnumArrayDefnEntLockBehavior} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDurationUnit} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDocFileExt} from "../../../../api/meta/base/Types";
import {EnumArrayDeviceType} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDeviceSize} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDeploy} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDay} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDate} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDataPartitionPeriod} from "../../../../api/meta/base/Types";
import {EnumArrayDefnConjunction} from "../../../../api/meta/base/Types";
import {EnumArrayDefnCodeEditorLanguage} from "../../../../api/meta/base/Types";
import {EnumArrayDefnKindAutomation} from "../../../../api/meta/base/Types";
import {EnumArrayDefnAudioFormat} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinderContext} from "../../../../api/meta/base/Types";
import {EnumArrayDefnArgBinder} from "../../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {getMonthEnum} from "../../builder/base/FieldBuilderPlus";
import {getPaperSizeDtoToValue} from "../../builder/base/FormBuilderLayoutPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickEnum(props: {
  type: EnumDefnCompType,
  defn: DefnFieldPickEnum
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const filterOptionSet = defn.filterOptionSet;
  const fieldId = getFieldKey(defn);

  let options: string[] = [];

  switch(props.type)
  {
    case "enumArgBinder":
      options = EnumArrayDefnArgBinder;
      break;
    case "enumArgBinderContext":
      options = EnumArrayDefnArgBinderContext;
      break;
    case "enumAudioFormat":
      options = EnumArrayDefnAudioFormat;
      break;
    case "enumAutomationSource":
      options = EnumArrayDefnAutomationSource;
      break;
    case "enumLogOperationKind":
      options = EnumArrayDefnLogOperationKind;
      break;
    case "enumCodeEditorLanguage":
      options = EnumArrayDefnCodeEditorLanguage;
      break;
    case "enumConjunction":
      options = EnumArrayDefnConjunction;
      break;
    case "enumDataPartitionPeriod":
      options = EnumArrayDefnDataPartitionPeriod;
      break;
    case "enumDate":
      options = EnumArrayDefnDate;
      break;
    case "enumDay":
      options = EnumArrayDefnDay;
      break;
    case "enumDeployVar":
      options = EnumArrayDefnDeploy;
      break;
    case "enumDeviceSize":
      options = EnumArrayDefnDeviceSize;
      break;
    case "enumDeviceType":
      options = EnumArrayDeviceType;
      break;
    case "enumDocFileExt":
      options = EnumArrayDefnDocFileExt;
      break;
    case "enumDurationUnit":
      options = EnumArrayDefnDurationUnit;
      break;
    case "enumDateOccurrence":
      options = EnumArrayDefnDateOccurrence;
      break;
    case "enumEntLockBehavior":
      options = EnumArrayDefnEntLockBehavior;
      break;
    case "enumEntLockReason":
      options = EnumArrayDefnEntLockReason;
      break;
    case "enumDeeplinkExpiry":
      options = EnumArrayDefnDeeplinkExpiry;
      break;
    case "enumFuncArgs":
      options = EnumArrayDefnFuncArg;
      break;
    case "enumFrequencyKind":
      options = EnumArrayDefnRepeatFrequencyKind;
      break;
    case "enumKindAction":
      options = EnumArrayDefnKindAction;
      break;
    case "enumKindAutomation":
      options = EnumArrayDefnKindAutomation;
      break;
    case "enumKindButton":
      options = EnumArrayDefnKindButton;
      break;
    case "enumKindFormComposite":
      options = EnumArrayDefnKindFormComposite;
      break;
    case "enumKindHyperlink":
      options = EnumArrayDefnKindHyperlink;
      break;
    case "enumKindImport":
      options = EnumArrayDefnKindImport;
      break;
    case "enumKindRating":
      options = EnumArrayDefnKindRating;
      break;
    case "enumKindReport":
      options = EnumArrayDefnKindReport;
      break;
    case "enumKindScheduledEvent":
      options = EnumArrayDefnKindScheduledEvent;
      break;
    case "enumKindSpreadsheetEvent":
      options = EnumArrayDefnKindSpreadsheetEvent;
      break;
    case "enumKindAutomationStep":
      options = EnumArrayDefnKindAutomationStep;
      break;
    case "enumKindTranslation":
      options = EnumArrayDefnKindTranslation;
      break;
    case "enumLayoutCardFilterKind":
      options = EnumArrayDefnLayoutCardFilterKind;
      break;
    case "enumLayoutGridKind":
      options = EnumArrayDefnLayoutGridKind;
      break;
    case "enumKindDeeplink":
      options = EnumArrayDefnKindDeeplink;
      break;
    case "enumDeeplinkConstraints":
      options = EnumArrayDefnDeeplinkConstraint;
      break;
    case "enumMapPinShape":
      options = EnumArrayDefnMapPinShape;
      break;
    case "enumMonth":
      options = getMonthEnum();
      break;
    case "enumQuarter":
      options = EnumArrayDefnQuarter;
      break;
    case "enumPaymentMethod":
      options = EnumArrayDefnPaymentMethod;
      break;
    case "enumPaymentPlan":
      options = EnumArrayDefnPaymentPlan;
      break;
    case "enumPermission":
      options = EnumArrayDefnPermission;
      break;
    case "enumPluginApiMethod":
      options = EnumArrayDefnPluginApiMethod;
      break;
    case "enumPluginResources":
      options = EnumArrayDefnPluginResources;
      break;
    case "enumPluginSecurityAccess":
      options = EnumArrayDefnPluginSecurityAccess;
      break;
    case "enumPosition":
      options = EnumArrayDefnPosition;
      break;
    case "enumRenderingKind":
      options = getEnumRenderingKindOptions(EnumArrayDefnRenderingKind as EnumDefnRenderingKind[]);
      break;
    case "enumPromptAction":
      options = EnumArrayDefnPromptAction;
      break;
    case "enumRowAuditTrail":
      options = EnumArrayDefnRowAuditTrail;
      break;
    case "enumRoles":
      options = EnumArrayDefnRoles;
      break;
    case "enumSetupKind":
      options = EnumArrayDefnSetupKind;
      break;
    case "enumThemeButtonSize":
      options = EnumArrayDefnThemeButtonSize;
      break;
    case "enumThemeButtonVariant":
      options = EnumArrayDefnThemeButtonVariant;
      break;
    case "enumThemeColor":
      options = EnumArrayDefnThemeColor;
      break;
    case "enumThemeColorShade":
      options = EnumArrayDefnThemeColorShade;
      break;
    case "enumThemeDirection":
      options = EnumArrayDefnThemeDirection;
      break;
    case "enumThemeDividerThickness":
      options = EnumArrayDefnThemeDividerKind;
      break;
    case "enumThemeFieldMargin":
      options = EnumArrayDefnThemeFieldMargin;
      break;
    case "enumThemeFieldSize":
      options = EnumArrayDefnThemeFieldSize;
      break;
    case "enumThemeFieldVariant":
      options = EnumArrayDefnThemeFieldVariant;
      break;
    case "enumThemeFormVariant":
      options = EnumArrayDefnThemeFormVariant;
      break;
    case "enumThemeImageCorner":
      options = EnumArrayDefnThemeImageCorner;
      break;
    case "enumThemePickVariant":
      options = EnumArrayDefnThemePickVariant;
      break;
    case "enumThemePickMultiVariant":
      options = EnumArrayDefnThemePickMultiVariant;
      break;
    case "enumPlacement":
      options = EnumArrayDefnPlacement;
      break;
    case "enumThemeSectionVariant":
      options = EnumArrayDefnThemeSectionVariant;
      break;
    case "enumThemeStroke":
      options = EnumArrayDefnThemeStroke;
      break;
    case "enumThemeTabVariant":
      options = EnumArrayDefnThemeTabVariant;
      break;
    case "enumEditorLayoutRenderingMode":
      options = EnumArrayDefnEditorLayoutRenderingMode;
      break;
    case "enumUserSettingOptions":
      options = EnumArrayDefnUserSettingOptions;
      break;
    case "enumUserSettingValue":
      options = EnumArrayDefnUserSettingValue;
      break;
    case "enumVideoFormat":
      options = EnumArrayDefnVideoFormat;
      break;
    case "enumVisibilityAction":
      options = EnumArrayDefnVisibilityAction;
      break;
    case "enumVisibilityActionOn":
      options = EnumArrayDefnVisibilityActionOn;
      break;
    case "enumVisibilityOperator":
      options = EnumArrayDefnVisibilityOperator;
      break;
    case "enumDynamicOperator":
      options = EnumArrayDefnDynamicOperator;
      break;
    case "enumInsertVariant":
      options = EnumArrayDefnInsertVariant;
      break;
    case "enumUpdateVariant":
      options = EnumArrayDefnUpdateVariant;
      break;
    case "enumRemoveVariant":
      options = EnumArrayDefnRemoveVariant;
      break;
    case "enumEmptyFieldVariant":
      options = EnumArrayDefnEmptyFieldVariant;
      break;
    case "enumTerminateSetting":
      options = EnumArrayDefnAutomationTerminateKind;
      break;
    case "enumTableLayoutStyle":
      options = EnumArrayDefnTableStyle;
      break;
    case "enumUserProperty":
      options = EnumArrayDefnUserProperty;
      break;
    case "enumStoreItem":
      options = EnumArrayDefnStoreItem;
      break;
    case "enumCaptureMode":
      options = EnumArrayDefnCaptureMode;
      break;
    case "enumLockOperation":
      options = EnumArrayDefnLockOperation;
      break;
    case "enumTargetType":
      options = EnumArrayDefnButtonTargetType;
      break;
    case "enumRefreshOn":
      options = EnumArrayDefnRefreshOn;
      break;
    case "enumSetOfUserKind":
      options = EnumArrayDefnKindSetOfUser;
      break;
    case "enumUserContext":
      options = EnumArrayDefnUserContext;
      break;
    case "enumRowProperty":
      options = EnumArrayDefnRowProperty;
      break;
    case "enumDriveSheetLayoutFor":
      options = EnumArrayDefnDriveSheetLayoutFor;
      break;
    case "enumDriveSheetFieldLayoutOn":
      options = EnumArrayDefnDriveSheetFieldLayoutOn;
      break;
    case "enumTextStyle":
      options = EnumArrayDefnTextStyle;
      break;
    case "enumGridRenderingMode":
      options = EnumArrayDefnGridRenderingMode;
      break;
    case "enumSortOrder":
      options = EnumArrayDefnSortOrder;
      break;
    case "paymentStatus":
      options = EnumArrayDefnPaymentStatus;
      break;
    case "enumPluginMode":
      options = EnumArrayDefnPluginMode;
      break;
  }

  if(filterOptionSet)
  {
    options = filterOptionSet;
  }

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) => <FieldRawStudioPick
        fieldProps={fieldProps}
        defn={props.defn}
        optionMap={arrayToMapOfOption(options)}
        sortOption={handleSpecialCase(props.type)}
      />}
    />
  );
}

function handleSpecialCase(type: EnumDefnCompType)
{
  switch(type)
  {
    case "enumKindRating": //for rating sort option
      return true;
  }
}

function getEnumRenderingKindOptions(EnumArrayDefnRenderingKind: EnumDefnRenderingKind[]): string[]
{
  return EnumArrayDefnRenderingKind.map(item =>
  {
    return getPaperSizeDtoToValue(item);
  });
}

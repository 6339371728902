import {Box} from "@mui/material";
import {MarkerF} from "@react-google-maps/api";
import React from "react";
import {EnumDefnMapPinShape} from "../../api/meta/base/Types";
import {CbOnClickMapMarker} from "../../base/types/TypeMap";
import {ILocationMarker} from "../../base/types/TypeMap";
import {ILatLng} from "../../base/types/TypesStudio";
import {IMarkerTooltip} from "./MapMarkerTooltip";

const SVG_MARKER_PIN = "M7 20.0664C2.333 13.9764 0 9.64341 0 7.06641C0 5.20989 0.737498 3.42941 2.05025 2.11666C3.36301 0.803904 5.14348 0.0664063 7 0.0664062C8.85652 0.0664063 10.637 0.803904 11.9497 2.11666C13.2625 3.42941 14 5.20989 14 7.06641C14 9.64341 11.667 13.9764 7 20.0664ZM7 11.0664C8.06087 11.0664 9.07828 10.645 9.82843 9.89483C10.5786 9.14469 11 8.12727 11 7.06641C11 6.00554 10.5786 4.98812 9.82843 4.23798C9.07828 3.48783 8.06087 3.06641 7 3.06641C5.93913 3.06641 4.92172 3.48783 4.17157 4.23798C3.42143 4.98812 3 6.00554 3 7.06641C3 8.12727 3.42143 9.14469 4.17157 9.89483C4.92172 10.645 5.93913 11.0664 7 11.0664Z";
const SVG_MARKER_CIRCLE = "M7,32L7,32c-3.9,0-7-3.1-7-7l0,0c0-3.9,3.1-7,7-7h0c3.9,0,7,3.1,7,7v0C14,28.9,10.9,32,7,32z";
const SVG_MARKER_SQUARE = "M11,32H1c-0.6,0-1-0.4-1-1V21c0-0.6,0.4-1,1-1h10c0.6,0,1,0.4,1,1v10C12,31.6,11.6,32,11,32z";
const SVG_MARKER_TRIANGLE = "M7.1,19.6L0.9,30.5C0.5,31.2,1,32,1.7,32h12.5c0.8,0,1.3-0.8,0.9-1.5L8.9,19.6C8.5,19,7.5,19,7.1,19.6z";
const SVG_MARKER_STAR = "M8.7,17.2l1.6,4.1c0.1,0.3,0.3,0.4,0.6,0.4l4.4,0.3c0.6,0,0.9,0.8,0.4,1.3l-3.4,2.8c-0.2,0.2-0.3,0.5-0.2,0.7l1.1,4.3 c0.2,0.6-0.5,1.1-1.1,0.8l-3.7-2.4c-0.2-0.1-0.5-0.1-0.8,0l-3.7,2.4c-0.5,0.3-1.2-0.2-1.1-0.8l1.1-4.3c0.1-0.3,0-0.5-0.2-0.7 l-3.4-2.8C-0.2,22.9,0,22.1,0.7,22l4.4-0.3c0.3,0,0.5-0.2,0.6-0.4l1.6-4.1C7.6,16.6,8.4,16.6,8.7,17.2z";
const SVG_MARKER_HEART = "M0,22.9c0,3.9,3.2,6,5.6,7.8C6.4,31.4,7.2,32,8,32s1.6-0.6,2.4-1.3c2.4-1.9,5.6-3.9,5.6-7.8c0-3.9-4.4-6.6-8-2.9 C4.4,16.3,0,19,0,22.9z";

export function MapMarker(props: {
  markerId: string,
  markerColor?: string,
  markerToolTip?: IMarkerTooltip,
  markerShape?: EnumDefnMapPinShape,
  markerValue?: ILocationMarker,
  handleClick?: CbOnClickMapMarker
  onMouseOverMarker?: (position: google.maps.LatLng, markerToolTip?: IMarkerTooltip) => void
  onMouseOutMarker?: (position: google.maps.LatLng) => void
})
{
  const markerId = props.markerId;
  const markerColor = props.markerColor;
  const markerToolTip = props.markerToolTip;
  const markerValue = props.markerValue;
  const markerShape = props.markerShape;
  const handleClick = props.handleClick;
  const onMouseOverMarker = props.onMouseOverMarker;
  const onMouseOutMarker = props.onMouseOutMarker;

  const firstMarker = markerValue
    ? markerValue.locations
    : {
      lat: 0,
      lng: 0
    };
  const firstIndicator = {
    lat: firstMarker.lat,
    lng: firstMarker.lng
  };

  //Marker style
  const markerPinShape = getMarkerPath(markerShape);

  const firstMarkerIcon = {
    path: markerPinShape.path,
    scale: markerPinShape.scale,
    fillColor: markerColor,
    fillOpacity: 1,
    strokeColor: markerColor,
    anchor: new google.maps.Point(6, 20)
  };

  const onClick = (e: google.maps.MapMouseEvent) =>
  {
    const latLng = {
      lat: e.latLng?.lat(),
      lng: e.latLng?.lng()
    } as ILatLng;

    handleClick && handleClick(
      markerId,
      (e.domEvent as Event).currentTarget,
      latLng
    );
  };

  const onMouseOver = (item: google.maps.MapMouseEvent | null) =>
  {
    const position = item?.latLng;

    if(markerToolTip && position)
    {
      onMouseOverMarker && onMouseOverMarker(position, markerToolTip);
    }
  };
  const onMouseOut = (item: google.maps.MapMouseEvent | null) =>
  {
    const position = item?.latLng;
    if(position)
    {
      onMouseOutMarker && onMouseOutMarker(position);
    }
  };

  return (
    <Box key={markerId}>
      <MarkerF
        position={firstIndicator}
        draggable={false}
        icon={firstMarkerIcon}
        animation={window.google.maps.Animation.DROP}
        onClick={onClick}
        onMouseOver={(e) => onMouseOver(e)}
        onMouseOut={(e) => onMouseOut(e)}
      />
    </Box>
  );
}

interface IMarkerPinShape
{
  path: string;
  scale: number;
}

function getMarkerPath(value?: EnumDefnMapPinShape): IMarkerPinShape
{

  switch(value)
  {
    case "circle":
      return {
        path: SVG_MARKER_CIRCLE,
        scale: 1.2
      };
    case "circleMedium":
      return {
        path: SVG_MARKER_CIRCLE,
        scale: 1.5
      };
    case "circleBig":
      return {
        path: SVG_MARKER_CIRCLE,
        scale: 1.7
      };
    case "square":
      return {
        path: SVG_MARKER_SQUARE,
        scale: 1.2
      };
    case "squareMedium":
      return {
        path: SVG_MARKER_SQUARE,
        scale: 1.5
      };
    case "squareBig":
      return {
        path: SVG_MARKER_SQUARE,
        scale: 1.7
      };
    case "triangle":
      return {
        path: SVG_MARKER_TRIANGLE,
        scale: 1.2
      };
    case "triangleMedium":
      return {
        path: SVG_MARKER_TRIANGLE,
        scale: 1.5
      };
    case "triangleBig":
      return {
        path: SVG_MARKER_TRIANGLE,
        scale: 1.7
      };
    case "star":
      return {
        path: SVG_MARKER_STAR,
        scale: 1.2
      };
    case "starMedium":
      return {
        path: SVG_MARKER_STAR,
        scale: 1.5
      };
    case "starBig":
      return {
        path: SVG_MARKER_STAR,
        scale: 1.7
      };
    case "heart":
      return {
        path: SVG_MARKER_HEART,
        scale: 1.2
      };
    case "heartMedium":
      return {
        path: SVG_MARKER_HEART,
        scale: 1.5
      };
    case "heartBig":
      return {
        path: SVG_MARKER_HEART,
        scale: 1.7
      };
    case "pin":
      return {
        path: SVG_MARKER_PIN,
        scale: 1.2
      };
    default:
      return {
        path: SVG_MARKER_PIN,
        scale: 1.2
      };
  }
}

import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {MsgCallerChatNotificationSettingPut} from "../../../api/home/aside/msg/MsgCallerChatNotificationSettingPut";
import {RpcAside} from "../../../api/home/aside/RpcAside";
import {SigGroupInfo} from "../../../api/home/aside/sig/SigGroupInfo";
import {MsgChatId} from "../../../api/home/base/msg/MsgChatId";
import {MsgGroupId} from "../../../api/home/base/msg/MsgGroupId";
import {EnumMessageType} from "../../../api/home/base/Types";
import {RpcDrawer} from "../../../api/home/drawer/RpcDrawer";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {MsgChatBlock} from "../../../api/home/main/msg/MsgChatBlock";
import {RpcMain} from "../../../api/home/main/RpcMain";
import {isGroupId} from "../../../api/meta/base/ApiPlus";
import {isNonGlobalEntId} from "../../../api/meta/base/ApiPlus";
import {isEntUserId} from "../../../api/meta/base/ApiPlus";
import {ChatId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {STR_NO_MESSAGE} from "../../../base/plus/ConstantsPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {getEntTooltip} from "../../../base/plus/SrvcPlus";
import {textUser} from "../../../base/plus/SrvcPlus";
import {getLabel} from "../../../base/plus/StringPlus";
import {hasFormatMarkdownText} from "../../../base/plus/StringPlus";
import {listSetIfExistSecondaryLineIcon} from "../../../base/slices/list/SliceListAPSAActions";
import {listRemoveIfExistOverwriteSecondary} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistSecondaryLineBadge} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistSecondaryLine} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetIfExistPrimaryLineCaption} from "../../../base/slices/list/SliceListAPSAActions";
import {listSetSelectedItemId} from "../../../base/slices/list/SliceListSharedActions";
import {listResetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListBinderSeven} from "../../../base/types/list/TypesList";
import {IListItem} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {comboToEntId} from "../../../base/types/TypesComboId";
import {DtoComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import {comboToAboutId} from "../../../base/types/TypesComboId";
import {toComboIdDto} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {TypeUserField} from "../../../base/types/TypesGlobal";
import {IAvatar} from "../../../base/types/TypesGlobal";
import {ILastMessage} from "../../../cache/home/drawer/msgLast/TypesCacheHomeDrawerMsgLast";
import {isYou} from "../../../Store";
import {textUserOrYou} from "../../../Store";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigRecentAvatar = SigGroupAvatar | SigUserAvatar | SigGroupInfo | undefined;
type TypeLastMessage = ILastMessage | undefined;
type TypeBadge = number | undefined;

const invalidYouTypes = [
  "groupAboutChange",
  "groupAvatarChange",
  "groupCreate",
  "groupExit",
  "groupJoinWithInvite",
  "groupMemberAdd",
  "groupMemberRemove",
  "groupNameChange"
] as EnumMessageType[];

export interface IUserFieldRecentListItem extends TypeUserField
{
  isPinned?: boolean;
  isBlocked?: boolean;
  isMute?: boolean;
  isYou?: boolean;
}

export default class SrvcHomeDrawerRecentList extends ISrvc
{
  protected flagWsocAuth = true;
  private readonly subscriberId = "SrvcHomeDrawerRecentList";

  constructor(public selectList: SelectList)
  {
    super();
  }

  subscribe(entChatId: TypeComboId, unsubscribe?: boolean): void
  {
    const comboIdDto = toComboIdDto(entChatId);
    const entId = comboIdDto.entId;
    const chatId = comboIdDto.chatId;
    const subscriberId = this.subscriberId;

    const topicList = Srvc.cache.home.drawer.recent.getSubscribeTopics(entId, chatId);
    Srvc.app.pubsub.handleSubscribeRecentList(subscriberId, topicList, true, unsubscribe);

    // the badge map is subscribed at boot time
  }

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectAvatar.bind(this),
      onBindSourceItem1: this.onBindAvatar.bind(this),

      selectSourceItem2: this.selectLastMessage.bind(this),
      onBindSourceItem2: this.onBindLastMessage.bind(this),

      selectSourceItem3: this.selectBadge.bind(this),
      onBindSourceItem3: this.onBindBadge.bind(this),

      selectSourceItem4: this.selectSender.bind(this),
      onBindSourceItem4: this.onBindSender.bind(this),

      selectSourceItem5: this.selectTyping.bind(this),
      onBindSourceItem5: this.onBindTyping.bind(this),

      selectSourceItem6: this.selectEntAvatar.bind(this),
      onBindSourceItem6: this.onBindEntAvatar.bind(this),

      selectSourceItem7: this.selectMute.bind(this),
      onBindSourceItem7: this.onBindMute.bind(this)

    } as IListBinderSeven<
      SigUserAvatar | undefined,
      TypeLastMessage,
      TypeBadge,
      SigUserAvatar | undefined,
      string | undefined,
      SigGroupAvatar | undefined,
      boolean | undefined
    >;
  }

  load(listName: string)
  {
    const rootState = store.getState();
    const cacheRecent = rootState.cache.home.drawer.recent;
    const uiItemsById = {} as IListItemsById;
    const uiItemIds = [] as TypeComboId[];

    cacheRecent.recentList.forEach(entChatId =>
    {
      uiItemIds.push(entChatId);
      const avatar = this.selectAvatar(rootState, entChatId);
      const dtoComboId = toComboIdDto(entChatId);
      const entId = dtoComboId.entId;
      const chatId = dtoComboId.chatId;

      uiItemsById[entChatId] = avatar
        ? this.createAvatar(rootState,
          entId,
          chatId,
          avatar
        )
        : {} as IListItem;
    });

    dispatchList(listName, listRefresh({
      version: cacheRecent.version,
      itemsById: uiItemsById,
      itemIds: uiItemIds,
      userField: {
        loaded: true
      }
    }));
    dispatchList(listName, listSetSelectedItemId(this.selectList(rootState).selectedItemId as TypeComboId));
  }

  rpcRecentItemPin(entId: EntId, chatId: ChatId)
  {
    const msg = {
      chatId: chatId
    } as MsgChatId;

    RpcDrawer.recentItemPin(entId, msg, Srvc.app.toast.showErrorToast);
  }

  rpcRecentItemUnPin(entId: EntId, chatId: ChatId)
  {
    const msg = {
      chatId: chatId
    } as MsgChatId;

    RpcDrawer.recentItemUnPin(entId, msg, Srvc.app.toast.showErrorToast);
  }

  rpcChatClear(entId: EntId, chatId: ChatId)
  {
    const msg = {
      chatId: chatId
    } as MsgChatId;

    RpcMain.chatClear(entId, msg, Srvc.app.toast.showErrorToast);
  }

  rpcChatRemove(entId: EntId, chatId: ChatId, cbSuccess?: () => void)
  {
    const msg = {
      chatId: chatId
    } as MsgChatId;

    RpcMain.chatRemove(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcGroupExit(entId: EntId, chatId: ChatId, cbSuccess?: () => void)
  {
    const msg = {
      groupId: chatId
    } as MsgGroupId;

    RpcDrawer.groupExit(msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcChatBlock(chatId: ChatId, block: boolean, cbSuccess?: () => void)
  {
    const msg = {
      chatId: chatId,
      block
    } as MsgChatBlock;

    RpcMain.chatBlock(msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  rpcChatNotificationSetting(entId: EntId, chatId: ChatId, mute: boolean, cbSuccess?: () => void)
  {
    const msg = {
      chatId: chatId,
      notificationSetting: {
        mute: mute
      }
    } as MsgCallerChatNotificationSettingPut;

    RpcAside.callerChatNotificationSettingPut(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  protected onWsocAuth()
  {
    if(!this.flagWsocAuth)
    {
      Srvc.cache.home.drawer.recent.wsocRecentListGet(false);
      Srvc.cache.app.badge.wsocBadgeMapGet();
    }
  }

  protected onWsocClose()
  {
    this.flagWsocAuth = false;
  }

  //region private

  private createAvatar(
    rootState: RootState,
    entId: EntId,
    chatId: ChatId,
    avatar: SigGroupAvatar | SigUserAvatar | SigGroupInfo)
  {
    const entChatId = toComboId(entId, chatId);
    const sig = avatar as SigUserAvatar | SigGroupAvatar;

    const dtoComboId = toComboIdDto(entChatId);
    const isGroup = isGroupId(chatId);
    const isUser = !isGroup;

    const isPinned = Boolean(rootState.cache.home.drawer.recent.recentMap[entChatId]?.isPinned);
    const _isChatNotificationMuted = isChatNotificationMuted(entId, chatId);
    const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
    const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];
    const badge = rootState.cache.app.badge.badgeMap[entChatId];
    const sender = this.getSender(rootState, dtoComboId, lastMessage);
    const overwriteIcon1Icon2AndText = isUser ? rootState.cache.app.user.userTypingText[entChatId] : undefined;

    let primaryText: string;
    let isBlocked: boolean | undefined;
    if(isGroup)
    {
      const groupAvtar = avatar as SigGroupAvatar;
      primaryText = primaryText = getLabel(groupAvtar);
    }
    else
    {
      const userAvatar = avatar as SigUserAvatar;
      primaryText = textUser(userAvatar);
      isBlocked = userAvatar.isBlocked;
    }
    const hasMarkDownText = Boolean(lastMessage
      && (lastMessage.messageType === "text" || lastMessage.messageType === "linkText")
      && lastMessage.messageSummary
      && hasFormatMarkdownText(lastMessage?.messageSummary));
    return {
      version: sig.version,
      type: "aps",
      avatarLeft: this.getAvatarLeft(entAvatar, sig, dtoComboId),
      primary: {
        text: primaryText,
        icon1: isPinned ? "pin" : undefined,
        caption: {
          text: lastMessage ? lastMessage.messageTime : undefined,
          ignoreSelection: true
        }
      },
      secondary: {
        overwriteIcon1Icon2AndText: overwriteIcon1Icon2AndText,
        overwriteIcon1Icon2AndTextColor: overwriteIcon1Icon2AndText ? "successLight" : undefined,
        icon1: lastMessage ? lastMessage.receiptStatus : undefined,
        icon2: lastMessage && lastMessage.messageType !== "text" ? lastMessage.messageType : undefined,
        icon4: _isChatNotificationMuted ? "mute" : undefined,
        text: lastMessage ? this.getSecondaryText(rootState, lastMessage, sender) : STR_NO_MESSAGE,
        color: !Boolean(lastMessage) && "textDisabled",
        hasMarkDownText: hasMarkDownText,
        badge: {
          value: badge
        }
      },
      userField: {
        isPinned: isPinned,
        isBlocked: isBlocked,
        isMute: _isChatNotificationMuted,
        isYou: isYou(rootState, (sig as SigUserAvatar))
      } as IUserFieldRecentListItem,
      ignoreSelectionCaption: true
    } as IListItemAPSA;
  }

  private selectAvatar(state: RootState, entChatId: TypeComboId): TypeSigRecentAvatar
  {
    const chatId = comboToAboutId(entChatId);

    if(isGroupId(chatId))
    {
      return state.cache.app.group.groupAvatarMap[entChatId];
    }
    else
    {
      return state.cache.app.user.userAvatarMap[entChatId];
    }
  }

  private onBindAvatar(listName: string, entChatId: TypeComboId, avatar?: TypeSigRecentAvatar): void
  {
    if(avatar)
    {
      const rootState = store.getState();
      const dtoComboId = toComboIdDto(entChatId);
      const entId = dtoComboId.entId;
      const chatId = dtoComboId.chatId;
      const newItem = this.createAvatar(rootState, entId, chatId, avatar);

      dispatchList(listName, listSetItem({
        itemId: entChatId,
        newItem: newItem
      }));
    }
    else
    {
      dispatchList(listName, listResetItem(entChatId));
    }
  }

  private selectLastMessage(state: RootState, entChatId: TypeComboId): TypeLastMessage
  {
    return state.cache.home.drawer.msgLast.lastMessageMap[entChatId];
  }

  private onBindLastMessage(listName: string, entChatId: TypeComboId, lastMessage?: TypeLastMessage): void
  {
    const dtoComboId = toComboIdDto(entChatId);
    const rootState = store.getState();
    const sender = this.getSender(rootState, dtoComboId, lastMessage);

    dispatchList(listName, listSetIfExistPrimaryLineCaption({
      itemId: entChatId,
      text: lastMessage ? lastMessage.messageTime : undefined
    }));

    const hasMarkDownText = Boolean(lastMessage
      && (lastMessage.messageType === "text" || lastMessage.messageType === "linkText")
      && lastMessage.messageSummary
      && hasFormatMarkdownText(lastMessage?.messageSummary));

    dispatchList(listName, listSetIfExistSecondaryLine({
      itemId: entChatId,
      icon1: lastMessage ? lastMessage.receiptStatus : undefined,
      icon2: lastMessage && lastMessage.messageType !== "text" ? lastMessage.messageType : undefined,
      text: lastMessage ? this.getSecondaryText(rootState, lastMessage, sender) : STR_NO_MESSAGE,
      color: !Boolean(lastMessage) ? "textDisabled" : undefined,
      hasMarkDownText: hasMarkDownText
    }));
  }

  private selectSender(state: RootState, entChatId: TypeComboId): SigUserAvatar | undefined
  {
    const dtoComboId = toComboIdDto(entChatId);
    const entId = dtoComboId.entId;
    const chatId = dtoComboId.chatId;
    if(isGroupId(chatId))
    {
      const lastMessage = state.cache.home.drawer.msgLast.lastMessageMap[entChatId];
      if(lastMessage)
      {
        const senderId = toComboId(entId, lastMessage.senderId);
        return state.cache.app.user.userAvatarMap[senderId];
      }
    }

    return undefined;
  }

  private onBindSender(listName: string, entChatId: TypeComboId, sender?: SigUserAvatar): void
  {
    if(sender)
    {
      const rootState = store.getState();
      const lastMessage = rootState.cache.home.drawer.msgLast.lastMessageMap[entChatId];
      dispatchList(listName, listSetIfExistSecondaryLine({
        itemId: entChatId,
        text: this.getSecondaryText(rootState, lastMessage, sender)
      }));
    }
  }

  private selectBadge(state: RootState, entChatId: TypeComboId): TypeBadge
  {
    return state.cache.app.badge.badgeMap[entChatId];
  }

  private onBindBadge(listName: string, entChatId: TypeComboId, badge?: TypeBadge): void
  {
    dispatchList(listName, listSetIfExistSecondaryLineBadge({
      itemId: entChatId,
      value: badge
    }));
  }

  private selectMute(state: RootState, entChatId: TypeComboId): boolean | undefined
  {
    const dtoComboId = toComboIdDto(entChatId);
    const entId = dtoComboId.entId;
    const chatId = dtoComboId.chatId;
    return state.cache.app.caller.chatNotificationSettingMap?.[entId]?.map?.[chatId]?.mute;
  }

  private onBindMute(listName: string, entChatId: TypeComboId, mute?: boolean): void
  {
    dispatchList(listName, listSetIfExistSecondaryLineIcon({
      itemId: entChatId,
      icon4: mute ? "mute" : undefined
    }));
  }

  private selectTyping(state: RootState, entChatId: TypeComboId): string | undefined
  {
    const dtoComboId = toComboIdDto(entChatId);
    if(isEntUserId(dtoComboId.chatId))
    {
      return state.cache.app.user.userTypingText[entChatId];
    }
    else
    {
      return state.cache.app.group.groupTypingTextShort[entChatId];
    }
  }

  private onBindTyping(listName: string, entChatId: TypeComboId, typingText?: string): void
  {
    if(typingText)
    {
      dispatchList(listName, listSetIfExistSecondaryLine({
        itemId: entChatId,
        overwriteIcon1Icon2AndText: typingText,
        overwriteIcon1Icon2AndTextColor: "successLight"
      }));
    }
    else
    {
      dispatchList(listName, listRemoveIfExistOverwriteSecondary(entChatId));
    }
  }

  private selectEntAvatar(state: RootState, entChatId: TypeComboId)
  {
    const entId = comboToEntId(entChatId);
    return state.cache.app.caller.entIdUserAvatarMap[entId];
  }

  private onBindEntAvatar(listName: string, entChatId: TypeComboId, entAvatar: SigEntAvatarUser)
  {
    if(entAvatar)
    {
      const rootState = store.getState();
      const dtoComboId = toComboIdDto(entChatId);
      const entId = dtoComboId.entId;
      const chatId = dtoComboId.chatId;

      const avatar = this.selectAvatar(rootState, entChatId);
      if(avatar)
      {
        const newItem = this.createAvatar(rootState, entId, chatId, avatar);
        dispatchList(listName, listSetItem({
          itemId: entChatId,
          newItem: newItem
        }));
      }
    }
  }

  private getSecondaryText(rootState: RootState, lastMessage?: ILastMessage, sender?: SigUserAvatar)
  {
    let userText = lastMessage?.messageSummary;
    const messageType = lastMessage?.messageType;
    const _isYou = sender
      ? isYou(rootState, sender)
      : undefined;

    if(sender
      && lastMessage
      && !(_isYou && messageType && invalidYouTypes.includes(messageType) && lastMessage))
    {
      userText = textUserOrYou(rootState, sender) + ": " + lastMessage.messageSummary;
    }

    return userText;
  }

  private getSender(state: RootState, dtoComboId: DtoComboId, lastMessage?: ILastMessage)
  {
    return isGroupId(dtoComboId.chatId) && lastMessage
      ? state.cache.app.user.userAvatarMap[toComboId(dtoComboId.entId, lastMessage.senderId)]
      : undefined;
  }

  private getAvatarLeft(entAvatar: SigEntAvatarUser, avatar: SigUserAvatar | SigGroupAvatar, dtoComboId: DtoComboId)
  {
    const isGroup = isGroupId(dtoComboId.chatId);

    return {
      src: isNonGlobalEntId(dtoComboId.entId)
        ? getImageThumbnail(entAvatar?.avatarId)
        : getImageThumbnail(avatar.mediaIdAvatar),
      icon: isGroup
        ? "group"
        : "user",
      tooltip: getEntTooltip(entAvatar)
    } as IAvatar;
  }

  //endregion
}

export function isChatNotificationMuted(entId: EntId, chatId: ChatId)
{
  const rootState = store.getState();
  const chatNotificationSettingMap = rootState.cache.app.caller.chatNotificationSettingMap;
  const entChatNotificationSetting = chatNotificationSettingMap && chatNotificationSettingMap[entId];
  const chatMap = entChatNotificationSetting && entChatNotificationSetting.map;
  const notificationSetting = chatMap && chatMap[chatId];

  return Boolean(notificationSetting?.mute);
}

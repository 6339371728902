import {useCallback} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdLayoutGrid} from "../../../../../api/meta/base/ApiPlus";
import {DefnFieldButton} from "../../../../../api/meta/base/dto/DefnFieldButton";
import {DefnStudioMapOfDtoOption} from "../../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickGridId} from "../../../../../api/meta/base/dto/DefnStudioPickGridId";
import {DefnStudioPickLayoutGridId} from "../../../../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnStudioPickReportId} from "../../../../../api/meta/base/dto/DefnStudioPickReportId";
import {StudioDtoLayoutCard} from "../../../../../api/meta/base/dto/StudioDtoLayoutCard";
import {StudioDtoLayoutOverlaySpreadsheet} from "../../../../../api/meta/base/dto/StudioDtoLayoutOverlaySpreadsheet";
import {StudioFieldRefReport} from "../../../../../api/meta/base/dto/StudioFieldRefReport";
import {AllEditableType} from "../../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdSpreadsheet} from "../../../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {isSystemField} from "../../../../../base/plus/StudioFormPlus";
import {IResolvedIOFormNames} from "../../../../../base/plus/StudioPlus";
import {getResolvedIOFormNamesHelperText} from "../../../../../base/plus/StudioPlus";
import {FormStore} from "../../../../../base/types/TypesForm";
import {FORMS_REF_REPORT_OVERLAY_GRID_LAYOUT} from "../../../../atom/assets/HelperTextStudio";
import {FORMS_FIELD_ONLY_LIST_CARD_LAYOUT} from "../../../../atom/assets/HelperTextStudio";
import {usePageCtx} from "../../../../ctx/CtxPage";
import DialogNewLayoutGridCard from "../../../../dialog/DialogNewLayoutGridCard";
import DialogNewLayoutGridList from "../../../../dialog/DialogNewLayoutGridList";
import {fieldLayoutGridId} from "../../base/TypesFormBuilder";
import {propKeyPickGridId} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyOverlayLayoutGrid} from "../../base/TypesFormBuilder";
import {propKeyFieldKeySet} from "../../base/TypesFormBuilder";
import {propKeyCopyFields} from "../../base/TypesFormBuilder";
import {fieldReport} from "../../base/TypesFormBuilder";
import {propKeyEditableFieldIdSet} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";

export function getDefnFieldRefReport(
  spreadsheetId?: MetaIdSpreadsheet,
  formId?: MetaIdForm,
  helperTextReportFormName?: IResolvedIOFormNames,
  selectedRefFieldIdSet?: MetaIdField[],
  gridId?: MetaIdGrid
)
{
  const systemFieldOptions = selectedRefFieldIdSet
    ? selectedRefFieldIdSet.reduce((previousValue, currentValue) =>
    {
      if(isSystemField(currentValue))
      {
        previousValue.map[currentValue] = {
          metaId: currentValue,
          value: currentValue
        };
        previousValue.keys.push(currentValue);
      }
      return previousValue;
    }, {
      map: {},
      keys: []
    } as DefnStudioMapOfDtoOption)
    : undefined;

  return {
    [fieldReport]: {
      type: "pickReportId",
      label: "Report",
      name: fieldReport,
      metaId: fieldReport,
      disabled: true,
      helperTextVar: stringToDefnDtoText(getResolvedIOFormNamesHelperText(
        helperTextReportFormName?.inputFormName,
        helperTextReportFormName?.outputFormName
      ))
    } as DefnStudioPickReportId,

    [propKeyPickGridId]: {
      type: "pickGridId",
      name: propKeyPickGridId,
      metaId: propKeyPickGridId,
      label: "Grid",
      formId: formId,
      required: true
    } as DefnStudioPickGridId,

    ...getFieldGap(fieldGap1, "thick"),

    [propKeyCopyFields]: {
      type: "studioSetOfFieldId",
      name: propKeyCopyFields,
      metaId: propKeyCopyFields,
      label: "Copy fields",
      formId: formId,
      compositeIdSet: gridId
        ? [gridId]
        : [],
      filterFieldTypeSet: AllEditableType
    } as DefnStudioPickFieldId,

    [propKeyEditableFieldIdSet]: {
      type: "studioSetOfFieldId",
      name: propKeyEditableFieldIdSet,
      metaId: propKeyEditableFieldIdSet,
      label: "Editable fields",
      showCompositeName: true,
      formId: formId,
      compositeIdSet: gridId
        ? [gridId]
        : [],
      includeFieldIdSet: selectedRefFieldIdSet && selectedRefFieldIdSet.length > 1
        ? selectedRefFieldIdSet
        : []
    } as DefnStudioPickFieldId,

    [propKeyFieldKeySet]: {
      type: "studioSetOfFieldId",
      name: propKeyFieldKeySet,
      metaId: propKeyFieldKeySet,
      label: "Key fields",
      showCompositeName: true,
      formId: formId,
      compositeIdSet: gridId
        ? [gridId]
        : [],
      includeFieldIdSet: selectedRefFieldIdSet && selectedRefFieldIdSet.length > 0
        ? selectedRefFieldIdSet
        : [],
      includeOptionMap: systemFieldOptions
    } as DefnStudioPickFieldId
  };
}

export function getDefnLayoutTabRefReport(
  formId?: MetaIdForm,
  gridId?: MetaIdGrid,
  isFieldRefReportOverlayLayoutDisabled?: boolean,
  isFieldRefOverlayLayoutFilledGrid?: boolean
)
{
  return {
    [fieldLayoutGridId]: {
      type: "pickLayoutGridId",
      name: fieldLayoutGridId,
      metaId: fieldLayoutGridId,
      label: "Grid layout",
      formId: formId,
      gridId: gridId,
      disabled: !gridId,
      filterLayoutKindSet: ["card", "list"],
      required: true,
      helperTextVar: stringToDefnDtoText(FORMS_FIELD_ONLY_LIST_CARD_LAYOUT)
    } as DefnStudioPickLayoutGridId,

    ...getFieldGap(fieldGap4, "thick"),

    [propKeyOverlayLayoutGrid]: {
      type: "button",
      name: propKeyOverlayLayoutGrid,
      metaId: propKeyOverlayLayoutGrid,
      label: "Overlay grid layout",
      disabled: isFieldRefReportOverlayLayoutDisabled,
      buttonVariantVar: "text",
      iconPositionVar: "end",
      justifyContent: "spaceBetween",
      buttonPositionVar: "flexCenter",
      iconVar: isFieldRefOverlayLayoutFilledGrid
        ? "CheckBoxRounded"
        : "CheckBoxOutlineBlankRounded",
      helperTextVar: stringToDefnDtoText(FORMS_REF_REPORT_OVERLAY_GRID_LAYOUT)
    } as DefnFieldButton
  };
}

export function defnValueToStudioFieldRefReport(value: FieldValues): StudioFieldRefReport
{
  const refFieldChildren = fnFieldValueToRawValue("studioSetOfFieldRefId", value[propKeyCopyFields]) as MetaIdField[];
  const copyFieldMap = {} as Record<string, string>;
  if(refFieldChildren && Array.isArray(refFieldChildren))
  {
    refFieldChildren.forEach((refFieldChildId, index) =>
    {
      const newCopyFieldId = "copyField" + index;
      copyFieldMap[newCopyFieldId] = refFieldChildId;
    });
  }

  return {
    type: "refReport",
    reportId: fnFieldValueToRawValue("pickReportId", value[fieldReport]),
    gridId: fnFieldValueToRawValue("pickGridId", value[propKeyPickGridId]),
    layoutGridId: fnFieldValueToRawValue("pickLayoutGridId",
      value[fieldLayoutGridId]
    ),
    copyFieldMap: copyFieldMap,
    editableFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[propKeyEditableFieldIdSet]),
    keyFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", value[propKeyFieldKeySet])
  } as StudioFieldRefReport;
}

export function studioFieldRefReportToDefnValue(studioField: StudioFieldRefReport)
{
  return {
    [fieldReport]: fnRawValueToFieldValue("pickReportId", studioField.reportId),
    [propKeyPickGridId]: fnRawValueToFieldValue("pickGridId", studioField.gridId),
    [fieldLayoutGridId]: fnRawValueToFieldValue("pickLayoutGridId", studioField.layoutGridId),
    [propKeyCopyFields]: Object.values(studioField.copyFieldMap ?? {}),
    [propKeyEditableFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.editableFieldIdSet),
    [propKeyFieldKeySet]: fnRawValueToFieldValue("studioSetOfFieldId", studioField.keyFieldIdSet)
  };
}

export function useRefOverlayLayoutGrid()
{
  const pageCtx = usePageCtx();

  return useCallback((
    kind: EnumDefnLayoutGridKind,
    formStore: FormStore,
    metaIdGrid: MetaIdGrid,
    metaIdForm: MetaIdForm,
    oldValueSpreadsheet?: StudioDtoLayoutOverlaySpreadsheet,
    overRideCompositeIdSet?: MetaIdComposite[],
    cbSuccess?: (overlayLayoutGrid?: StudioDtoLayoutOverlaySpreadsheet) => void
  ) =>
  {
    switch(kind)
    {
      case "card":
        pageCtx.showDialog(
          <DialogNewLayoutGridCard
            metaIdForm={metaIdForm}
            metaIdGrid={metaIdGrid}
            formStore={formStore}
            values={{
              item: oldValueSpreadsheet?.item,
              kind: "card",
              metaId: nextMetaIdLayoutGrid(),
              name: "card"
            } as StudioDtoLayoutCard}
            layoutType={kind}
            isOverride={true}
            overrideCompositeIdSet={overRideCompositeIdSet}
            onClickOk={(value) =>
            {
              cbSuccess && cbSuccess({
                item: (value as StudioDtoLayoutCard).item
              });
            }}
          />
        );
        break;
      case "list":
        pageCtx.showDialog(
          <DialogNewLayoutGridList
            metaIdForm={metaIdForm}
            metaIdGrid={metaIdGrid}
            formStore={formStore}
            values={{
              item: oldValueSpreadsheet?.item,
              kind: "list",
              metaId: nextMetaIdLayoutGrid(),
              name: "list"
            } as StudioDtoLayoutCard}
            layoutType={kind}
            isOverride={true}
            overrideCompositeIdSet={overRideCompositeIdSet}
            onClickOk={(value) =>
            {
              cbSuccess && cbSuccess({
                item: (value as StudioDtoLayoutCard).item
              });
            }}
          />
        );
    }
  }, []);
}

import {CloseRounded} from "@mui/icons-material";
import {Paper} from "@mui/material";
import {PaperProps} from "@mui/material";
import {Typography} from "@mui/material";
import {IconButton} from "@mui/material";
import {Checkbox} from "@mui/material";
import {FormControlLabel} from "@mui/material";
import {CircularProgress} from "@mui/material";
import {Button} from "@mui/material";
import {DialogActions} from "@mui/material";
import {useTheme} from "@mui/material";
import {Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {TransitionProps} from "@mui/material/transitions";
import {Box} from "@mui/system";
import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import * as React from "react";
import Draggable from "react-draggable";
import {ALERT_DIALOG_CONTENT_WIDTH} from "../../../base/plus/ConstantsPlus";
import {ALERT_DIALOG_CONTENT_HEIGHT} from "../../../base/plus/ConstantsPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {EnumIconStrip} from "../../../base/types/TypesIcon";
import {useDeviceType} from "../../app/AppHooks";
import {usePageCtx} from "../../ctx/CtxPage";
import {BoxP} from "../box/BoxP";
import {BoxPS} from "../box/BoxPS";
import LayoutFlexCol from "../layout/LayoutFlexCol";
import LayoutFlexRow from "../layout/LayoutFlexRow";
import RawIconStrip from "../raw/RawIconStrip";
import RawTooltip from "../raw/RawTooltip";
import DialogTextError from "./DialogTextError";

export interface IDialogAtomRef
{
  setDescription(description: string): void;

  setTitle(title: string): void;

  setSubTitle(subTitle: string): void;

  setContent(content: React.ReactNode): void;

  setContentWidth(contentWidth: number | string): void;

  setContentHeight(contentHeight: number | string): void;

  setFullScreen(fullscreen: boolean): void,

  setSpinner(spinner: boolean): void;

  setHeaderIcons(headerIcons: EnumIconStrip[]): void;

  setRightFooterActions(actions?: string[]): void;

  setLeftFooterActions(actions?: string[]): void;

  disableFooterActions(actions?: string[]): void;

  setAlert(alert: string): void;

  close(): void;

  onClickHeaderIcon(headerIcon: EnumIconStrip): void;

  onClickFooterAction(action: string): void;
}

export type TypeActionClose = "backdropClick" | "escapeKeyDown" | "crossBtn"
export const DIALOG_HEADER_FOOTER_HEIGHT = 58;

export interface IDialogAtomFooterActionCheckbox
{
  label?: string;
  value?: string;
}

export default function DialogAtom(props: {
  title?: string,
  subTitle?: string,
  cbRef?: IDialogAtomRef,
  description?: string,
  content?: React.ReactNode,
  contentWidth?: number | string,
  contentHeight?: number | string,
  onOpen?: () => void,
  onClose?: () => void,
  doNotCloseActions?: string[],
  forceBtnClose?: boolean,
  draggable?: boolean,
  fullWidth?: boolean,
  fullScreen?: boolean,
  isOverflowHidden?: boolean

  headerIcons?: EnumIconStrip[],
  onClickHeaderIcon?: (headerIcon: EnumIconStrip) => void,

  rightFooterActions?: string[],
  leftFooterCheckBoxActions?: IDialogAtomFooterActionCheckbox[],
  leftFooterNode?: React.ReactNode,
  middleFooterText?: string
  leftFooterActions?: string[],
  disableRightFooterActions?: string[],
  onClickFooterAction?: (action: string) => void,
  onClickFooterCheckBoxAction?: (action: IDialogAtomFooterActionCheckbox, checked: boolean) => void,
})
{
  const onOpen = props.onOpen;
  const onClose = props.onClose;
  const onClickHeaderIcon = props.onClickHeaderIcon;
  const onClickFooterAction = props.onClickFooterAction;
  const doNotCloseActions = props.doNotCloseActions;
  const fullWidth = props.fullWidth;
  const draggable = props.draggable !== undefined
    ? props.draggable
    : true;

  const theme = useTheme();
  const pageCtx = usePageCtx();
  const deviceType = useDeviceType();

  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState(props.title);
  const [subTitle, setSubTitle] = useState(props.subTitle);
  const [description, setDescription] = useState(props.description);
  const [content, setContent] = useState(props.content);
  const [contentWidth, setContentWidth] = useState(props.contentWidth);
  const [contentHeight, setContentHeight] = useState(props.contentHeight);
  const [spinner, setSpinner] = useState(false);
  const [headerIcons, setHeaderIcons] = useState(props.headerIcons);
  const [rightFooterActions, setRightFooterActions] = useState(props.rightFooterActions);
  const [leftFooterActions, setLeftFooterActions] = useState(props.leftFooterActions);
  const [disableFooterActions, setDisableFooterActions] = useState(props.disableRightFooterActions);
  const [forceBtnClose, setForceBtnClose] = useState(props.forceBtnClose);
  const [fullScreen, setFullScreen] = useState(props.fullScreen);

  const durationTransition = theme.common.durationTransition;
  const gap = px(theme.common.gapStd);
  const iconGap = px(theme.common.gapQuarter);

  const refSetDescription = useCallback((description?: string) =>
  {
    setDescription(description);
  }, []);

  const refSetTitle = useCallback((title?: string) =>
  {
    setTitle(title);
  }, []);

  const refSetSubTitle = useCallback((subTitle?: string) =>
  {
    setSubTitle(subTitle);
  }, []);

  const refSetContent = useCallback((content?: React.ReactNode) =>
  {
    setContent(content);
  }, []);

  const refSetContentWidth = useCallback((contentWidth: number) =>
  {
    setContentWidth(contentWidth);
  }, []);

  const refSetContentHeight = useCallback((contentHeight: number) =>
  {
    setContentHeight(contentHeight);
  }, []);

  const refSetFullScreen = useCallback((fullScreen: boolean) =>
  {
    setFullScreen(fullScreen);
  }, []);

  const refSetSpinner = useCallback((spinner: boolean) =>
  {
    setSpinner(spinner);
  }, []);

  const refSetHeaderIcons = useCallback((headerIcons: EnumIconStrip[]) =>
  {
    setHeaderIcons(headerIcons);
  }, []);

  const refSetRightFooterActions = useCallback((actions?: string[]) =>
  {
    setRightFooterActions(actions);
  }, []);

  const refSetLeftFooterActions = useCallback((actions?: string[]) =>
  {
    setLeftFooterActions(actions);
  }, []);

  const refDisableFooterActions = useCallback((actions?: string[]) =>
  {
    setDisableFooterActions(actions);
  }, []);

  const refSetAlert = useCallback((alert: string) =>
  {
    setSpinner(false);
    setRightFooterActions(undefined);
    setLeftFooterActions(undefined);
    setContentHeight(ALERT_DIALOG_CONTENT_HEIGHT);
    setForceBtnClose(false);
    setContent((<DialogTextError value={alert} />));
  }, []);

  const refOnClickHeaderIcon = useCallback((headerIcon: EnumIconStrip) =>
  {
    const icons = [...headerIcons || []];

    const toggleIcon = (fromIcon: EnumIconStrip, toIcon: EnumIconStrip) =>
    {
      const iconIndex = headerIcons?.findIndex(icon => icon === fromIcon);
      if(iconIndex !== undefined && iconIndex !== -1)
      {
        icons.splice(iconIndex, 1, toIcon);
        setHeaderIcons(icons);
      }
    };

    if(headerIcon === "openInFull" || headerIcon === "miniMize")
    {
      setFullScreen(!fullScreen);
      toggleIcon(headerIcon, headerIcon === "openInFull" ? "miniMize" : "openInFull");
    }

    if(onClickHeaderIcon)
    {
      onClickHeaderIcon(headerIcon);
    }
  }, [fullScreen, headerIcons, onClickHeaderIcon]);

  const refOnClickFooterAction = useCallback((action: string | TypeActionClose) =>
  {
    if(action === "backdropClick")
    {
      return;
    }

    if(forceBtnClose)
    {
      if(action === "backdropClick" || action === "escapeKeyDown")
      {
        return;
      }
    }

    if(doNotCloseActions && doNotCloseActions.includes(action))
    {
      if(onClickFooterAction)
      {
        onClickFooterAction(action);
      }
      return;
    }
    if(onClickFooterAction)
    {
      onClickFooterAction(action);
    }
    setOpen(false);

  }, [doNotCloseActions, durationTransition, forceBtnClose, pageCtx, onClickFooterAction]);

  const handleKeyDown = useCallback((event: KeyboardEvent) =>
  {
    if(event.key === "Escape")
    {
      refOnClickFooterAction("escapeKeyDown");
    }
  }, [refOnClickFooterAction]);

  useEffect(() =>
  {
    if(open && onOpen)
    {
      onOpen();
    }
    if(!open)
    {
      setTimeout(() =>
      {
        pageCtx.showDialog(undefined);
      }, durationTransition);
      onClose && onClose();
    }
  }, [open, onOpen, onClose]);

  useEffect(() =>
  {
    setDisableFooterActions(props.disableRightFooterActions);
  }, [props.disableRightFooterActions]);

  useEffect(() =>
  {
    document.addEventListener("keydown", handleKeyDown);

    return () =>
    {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const cbRef = props.cbRef;
  if(cbRef)
  {
    cbRef.setDescription = refSetDescription;
    cbRef.setTitle = refSetTitle;
    cbRef.setSubTitle = refSetSubTitle;
    cbRef.setContent = refSetContent;
    cbRef.setContentWidth = refSetContentWidth;
    cbRef.setContentHeight = refSetContentHeight;
    cbRef.setFullScreen = refSetFullScreen;
    cbRef.setSpinner = refSetSpinner;
    cbRef.setHeaderIcons = refSetHeaderIcons;
    cbRef.setRightFooterActions = refSetRightFooterActions;
    cbRef.setLeftFooterActions = refSetLeftFooterActions;
    cbRef.disableFooterActions = refDisableFooterActions;
    cbRef.setAlert = refSetAlert;
    cbRef.onClickFooterAction = refOnClickFooterAction;
    cbRef.onClickHeaderIcon = refOnClickHeaderIcon;
    cbRef.close = () => setOpen(false);
  }

  return (
    <Dialog
      id={"draggable-dialog"}
      PaperComponent={(draggable && deviceType === "desktop") ? PaperComponent : undefined}
      open={open}
      fullWidth={Boolean(props.fullWidth)}
      fullScreen={Boolean(fullScreen)}
      scroll={"paper"}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
      PaperProps={{
        sx: {
          display: "flex",
          width: contentWidth,
          height: contentHeight,
          maxWidth: "100%",
          maxHeight: contentHeight
            ? contentHeight
            : Boolean(fullScreen)
              ? "100%"
              : "70%",
          minWidth: Boolean(fullScreen) || Boolean(fullWidth)
            ? "100%"
            : deviceType === "mobile"
              ? "95%"
              : contentWidth
                ? contentWidth
                : ALERT_DIALOG_CONTENT_WIDTH,
          minHeight: Boolean(fullScreen)
            ? "100%"
            : contentHeight
              ? contentHeight
              : ALERT_DIALOG_CONTENT_HEIGHT
        }
      }}
      TransitionComponent={Transition}
      onClose={(event, reason) => refOnClickFooterAction(reason)}
      closeAfterTransition={true}
      aria-labelledby={"draggable-dialog-title"}
    >
      <DialogTitle
        id={"draggable-dialog-title"}
        variant={"subtitle1"}
        sx={{
          height: DIALOG_HEADER_FOOTER_HEIGHT,
          fontWeight: 500,
          pl: gap,
          pr: gap,
          position: "relative",
          bgcolor: theme.common.bgcolorActionBar,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: draggable ? "move" : undefined
        }}
      >
        {
          subTitle && subTitle.length > 0
            ? <BoxPS
              primary={{
                text: title || "",
                variant: "body1",
                bold: true
              }}
              secondary={{
                text: subTitle,
                color: "textDisabled"
              }}
              flexGrow={1}
              pr={gapStd}
            />
            : <BoxP
              primary={{
                text: title || "",
                variant: "body1",
                bold: true
              }}
              flexGrow={1}
              pr={gapStd}
            />
        }

        <LayoutFlexRow justifyContent={"flex-start"}>

          <LayoutFlexRow
            justifyContent={"flex-start"}
            overflowX={"visible"}
            overflowY={"visible"}
          >
            {
              headerIcons &&
              <RawIconStrip
                iconStrip={headerIcons}
                onClick={(icon) => refOnClickHeaderIcon(icon)}
              />
            }
          </LayoutFlexRow>

          <LayoutFlexRow justifyContent={"flex-start"}>
            {
              spinner &&
              <CircularProgress
                size={"24px"}
                sx={{
                  color: "primary",
                  ml: iconGap
                }}
              />
            }

            <RawTooltip
              title={"Close"}
            >
              <IconButton
                size={"small"}
                sx={{
                  ml: iconGap
                }}
                onClick={() => refOnClickFooterAction("crossBtn")}
              >
                <CloseRounded />
              </IconButton>
            </RawTooltip>

          </LayoutFlexRow>
        </LayoutFlexRow>

      </DialogTitle>

      <DialogContent
        dividers={true}
        sx={{
          display: "flex",
          m: 0,
          p: 0,
          overflow: props.isOverflowHidden ? "hidden" : "auto",
          height: "100%"
        }}
      >
        {
          description &&
          (
            <DialogContentText
              id="dialog-description"
              tabIndex={-1}
              sx={{
                p: gap,
                userSelect: "text"
              }}
            >
              {description}
            </DialogContentText>
          )
        }

        <LayoutFlexCol
          width={"100%"}
          flexGrow={1}
        >
          {content}
        </LayoutFlexCol>

      </DialogContent>

      {

        (rightFooterActions || leftFooterActions)
          ? <FooterActions
            onClickAction={refOnClickFooterAction}
            rightActions={rightFooterActions}
            leftActions={leftFooterActions}
            leftCheckBoxActions={props.leftFooterCheckBoxActions}
            leftFooterNode={props.leftFooterNode}
            middleFooterText={props.middleFooterText}
            onClickFooterCheckBoxAction={props.onClickFooterCheckBoxAction}
            disableFooterActions={disableFooterActions}
          />
          : null
      }

    </Dialog>
  );
}

function FooterActions(props: {
  rightActions?: string [],
  leftCheckBoxActions?: IDialogAtomFooterActionCheckbox[],
  leftFooterNode?: React.ReactNode,
  leftActions?: string[],
  middleFooterText?: string,
  disableFooterActions?: string[]
  onClickAction: (reason: string) => void,
  onClickFooterCheckBoxAction?: (action: IDialogAtomFooterActionCheckbox, checked: boolean) => void,
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const rightActions = props.rightActions;
  const leftCheckBoxActions = props.leftCheckBoxActions;
  const leftFooterNode = props.leftFooterNode;
  const onClickFooterCheckBoxAction = props.onClickFooterCheckBoxAction;
  const leftActions = props.leftActions;
  const onClickAction = props.onClickAction;
  const autoRightFocus = rightActions?.[rightActions.length - 1];
  const autoLeftFocus = leftActions?.[leftActions.length - 1];

  return (
    <DialogActions
      disableSpacing={true}
      sx={{
        position: "relative",
        bgcolor: theme.common.bgcolorActionBar,
        padding: px(gapHalf),
        height: DIALOG_HEADER_FOOTER_HEIGHT
      }}
    >
      <LayoutFlexRow
        width={"100%"}
        justifyContent={"space-between"}
      >
        <LayoutFlexRow justifyContent={"start"}>
          {
            leftCheckBoxActions &&
            <LayoutFlexCol
              alignItems={"start"}
              pl={px(gapHalf)}
            >
              {
                leftCheckBoxActions?.map((action) => (
                  <FormControlLabel
                    key={action.value}
                    control={<Checkbox
                      onChange={onClickFooterCheckBoxAction
                        ? (_, checked) => onClickFooterCheckBoxAction(action, checked)
                        : onClickFooterCheckBoxAction}
                    />}
                    label={action.label}
                  />
                ))
              }
            </LayoutFlexCol>
          }

          {leftFooterNode}

          {
            leftActions && leftActions.map((action) => (
              <Button
                disableFocusRipple={true}
                autoFocus={action === autoLeftFocus}
                sx={{
                  fontWeight: action === autoLeftFocus ? 700 : undefined
                }}
                key={action}
                onClick={() => onClickAction(action)}
                disabled={props.disableFooterActions
                  ? props.disableFooterActions.findIndex(_action => _action === action) >= 0
                  : false}
              >
                {action}
              </Button>
            ))
          }
        </LayoutFlexRow>

        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)"
          }}
        >
          {props.middleFooterText && <Typography
            sx={{
              ml: px(gapStd),
              mr: px(gapStd),
              color: theme.common.color("textDisabled")
            }}
          >
            {props.middleFooterText}
          </Typography>}
        </Box>

        <LayoutFlexRow>
          {
            rightActions?.map((action) => (
              <Button
                disableFocusRipple={true}
                autoFocus={action === autoRightFocus}
                sx={{
                  fontWeight: action === autoRightFocus ? 700 : undefined
                }}
                key={action}
                onClick={() => onClickAction(action)}
                disabled={props.disableFooterActions
                  ? props.disableFooterActions.findIndex(_action => _action === action) >= 0
                  : false}
              >
                {action}
              </Button>
            ))
          }
        </LayoutFlexRow>
      </LayoutFlexRow>
    </DialogActions>
  );
}

function PaperComponent(props: PaperProps)
{
  return (
    <Draggable
      bounds={"#draggable-dialog"}
      handle={"#draggable-dialog-title"}
      cancel={"[class*=\"MuiDialogContent-root\"]"}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) =>
  {
    const theme = useTheme();

    // noinspection RequiredAttributes
    return (
      <Slide
        direction="up"
        timeout={theme.common.durationTransition}
        ref={ref}
        {...props}
      />
    );
  }
);

// neome API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {nextIdBig} from "./SysId";
import {getSuffix} from "./SysId";
import {nextId} from "./SysId";
import {getPrefix} from "./SysId";
import {SEP_PREFIX} from "./SysId";
import {SysId} from "./SysId";
import {PREFIX_META_ID_TABLE_STYLE} from "./Types";
import {MetaIdTableStyle} from "./Types";
import {PREFIX_META_ID_PLUGIN_RESOURCE} from "./Types";
import {MetaIdPluginResource} from "./Types";
import {PREFIX_META_ID_PAYMENT_PROVIDER} from "./Types";
import {MetaIdPaymentProvider} from "./Types";
import {MetaIdChartXAxis} from "./Types";
import {PREFIX_META_ID_CHART_X_AXIS} from "./Types";
import {EntUserId} from "./Types";
import {PREFIX_META_ID_LAYOUT_DRIVE_SHEET} from "./Types";
import {MetaIdLayoutDriveSheet} from "./Types";
import {PREFIX_META_ID_USER_CONDITION} from "./Types";
import {MetaIdUserCondition} from "./Types";
import {PREFIX_META_ID_PIPELINE_VAR} from "./Types";
import {MetaIdPipelineVar} from "./Types";
import {TransactionId} from "./Types";
import {PREFIX_TRANSACTION_ID} from "./Types";
import {SUFFIX_TEMPLATE} from "./Types";
import {PREFIX_META_ID_FIELD_DYNAMIC_RULE} from "./Types";
import {PREFIX_META_ID_FIELD_DYNAMIC_CONDITION} from "./Types";
import {MetaIdFieldDynamicRule} from "./Types";
import {MetaIdFieldDynamicCondition} from "./Types";
import {PREFIX_META_ID_FOOTER} from "./Types";
import {MetaIdFooter} from "./Types";
import {PREFIX_META_ID_HEADER} from "./Types";
import {MetaIdHeader} from "./Types";
import {PREFIX_META_ID_CHART_Y_AXIS} from "./Types";
import {MetaIdChartYAxis} from "./Types";
import {StoreItemId} from "./Types";
import {PREFIX_STORE_ITEM_ID} from "./Types";
import {PREFIX_META_ID_SWIMLANE} from "./Types";
import {MetaIdSwimlane} from "./Types";
import {MetaIdPartition} from "./Types";
import {PREFIX_META_ID_PARTITION} from "./Types";
import {MetaIdDeeplink} from "./Types";
import {PREFIX_META_ID_DEEPLINK} from "./Types";
import {MetaIdCode} from "./Types";
import {PREFIX_META_ID_CODE} from "./Types";
import {MetaIdPlugin} from "./Types";
import {PREFIX_META_ID_PROMPT} from "./Types";
import {MetaIdPrompt} from "./Types";
import {PREFIX_META_ID_STEP} from "./Types";
import {PREFIX_META_ID_EVENT} from "./Types";
import {PREFIX_META_ID_MODULE} from "./Types";
import {MetaIdModule} from "./Types";
import {MetaIdLayoutGrid} from "./Types";
import {PREFIX_META_ID_LAYOUT_GRID} from "./Types";
import {MetaIdVisibilityCondition} from "./Types";
import {PREFIX_META_ID_VISIBILITY_CONDITION} from "./Types";
import {MetaIdVisibilityAction} from "./Types";
import {PREFIX_META_ID_VISIBILITY_ACTION} from "./Types";
import {MetaIdLayoutFormContent} from "./Types";
import {PREFIX_META_ID_LAYOUT_FORM_CONTENT} from "./Types";
import {PREFIX_META_ID_VISIBILITY_RULE} from "./Types";
import {MetaIdVisibilityRule} from "./Types";
import {PREFIX_META_ID_CONDITION} from "./Types";
import {MetaIdCondition} from "./Types";
import {MetaIdMapping} from "./Types";
import {PREFIX_META_ID_MAPPING} from "./Types";
import {PREFIX_META_ID_FUNC_ARG} from "./Types";
import {MetaIdFuncArg} from "./Types";
import {PREFIX_ADMIN_ID} from "./Types";
import {AdminId} from "./Types";
import {PREFIX_META_ID_OPTION} from "./Types";
import {MetaIdOption} from "./Types";
import {PREFIX_META_ID_GRID} from "./Types";
import {MetaIdTab} from "./Types";
import {PREFIX_META_ID_TAB} from "./Types";
import {MetaIdGrid} from "./Types";
import {MetaIdSection} from "./Types";
import {PREFIX_META_ID_SECTION} from "./Types";
import {PREFIX_META_ID_PLUGIN} from "./Types";
import {MetaId} from "./Types";
import {PREFIX_META_ID_AUTOMATION} from "./Types";
import {MetaIdAutomation} from "./Types";
import {
  GroupId,
  MediaIdDocument,
  MediaIdImage,
  MediaIdJar,
  PREFIX_GROUP_ID,
  PREFIX_MEDIA_ID_AVATAR,
  PREFIX_MEDIA_ID_DOCUMENT,
  PREFIX_MEDIA_ID_IMAGE,
  PREFIX_REQUEST_ID,
  PREFIX_ROW_ID,
  PREFIX_TAB_ID,
  PREFIX_USER_ID,
  RequestId,
  TabId
} from "./Types";
import {PREFIX_MEDIA_ID_JAR} from "./Types";
import {PREFIX_MEDIA_ID_THUMBNAIL} from "./Types";
import {MediaIdThumbnail} from "./Types";
import {MediaIdAvatar} from "./Types";
import {PREFIX_MEDIA_ID_AUDIO} from "./Types";
import {MediaIdAudio} from "./Types";
import {PREFIX_DEVICE_ID} from "./Types";
import {DeviceId} from "./Types";
import {PREFIX_MEDIA_ID_VIDEO} from "./Types";
import {MediaIdVideo} from "./Types";
import {PREFIX_MEDIA_ID_STICKER} from "./Types";
import {MediaIdSticker} from "./Types";
import {PREFIX_ENT_ID} from "./Types";
import {EntId} from "./Types";
import {PREFIX_ENT_USER_ID} from "./Types";
import {UserId} from "./Types";
import {
  MessageId,
  MetaIdAction,
  MetaIdField,
  MetaIdForm,
  MetaIdReport,
  MetaIdSpreadsheet,
  PluginBundleId,
  PREFIX_MESSAGE_ID,
  PREFIX_META_ID_ACTION,
  PREFIX_META_ID_FIELD,
  PREFIX_META_ID_FORM,
  PREFIX_META_ID_REPORT,
  PREFIX_META_ID_SPREADSHEET,
  PREFIX_PLUGIN_BUNDLE_ID
} from "./Types";
import {
  MetaIdDriveSheet,
  MetaIdGroup,
  MetaIdRole,
  PluginApiId,
  PluginId,
  PREFIX_META_ID_DRIVE_SHEET,
  PREFIX_META_ID_FORMULA,
  PREFIX_META_ID_GROUP,
  PREFIX_META_ID_ROLE,
  PREFIX_META_ID_TRANSLATION,
  PREFIX_META_ID_VAR,
  PREFIX_PLUGIN_API_ID,
  PREFIX_PLUGIN_ID
} from "./Types";

export const ENT_ID_GLOBAL = "global";
export const META_ID_OPTION_ORPHAN = "metaOption-orphan";
export const ENT_SYSTEM_USER = "eu-system";

export function isOrphanOptionId(sysId?: MetaIdOption): boolean
{
  return sysId ? sysId === META_ID_OPTION_ORPHAN : false;
}

export function isGroupId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_GROUP_ID : false;
}

export function isGlobal(entId: EntId): boolean
{
  return entId === ENT_ID_GLOBAL;
}

export function isEntId(sysId?: SysId): boolean
{
  return sysId
    ? isGlobal(sysId) || getPrefix(sysId) === PREFIX_ENT_ID
    : false;
}

export function isTemplateId(sysId?: SysId): boolean
{
  return sysId
    ? isGlobal(sysId) || getSuffix(sysId).endsWith(SUFFIX_TEMPLATE)
    : false;
}

export function isPluginId(sysId?: SysId): boolean
{
  return sysId
    ? isGlobal(sysId) || getPrefix(sysId) === PREFIX_PLUGIN_BUNDLE_ID
    : false;
}

export function isStoreItemId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_STORE_ITEM_ID : false;
}

export function isSpreadsheetId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_SPREADSHEET : false;
}

export function isReportId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_REPORT : false;
}

export function isFormId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_FORM : false;
}

export function isSectionId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_SECTION : false;
}

export function isTabId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_TAB : false;
}

export function isGridId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_GRID : false;
}

export function isFieldId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_FIELD : false;
}

export function isNonGlobalEntId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_ENT_ID : false;
}

export function isEntUserId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_ENT_USER_ID : false;
}

export function isMessageId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_MESSAGE_ID : false;
}

export function isRowId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_ROW_ID : false;
}

export function isMediaImage(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_MEDIA_ID_IMAGE : false;
}

export function isVariableId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_VAR : false;
}

export function isRoleId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_ROLE : false;
}

export function isImportId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_PLUGIN : false;
}

export function isSystemUser(userId?: EntUserId): boolean
{
  return userId === ENT_SYSTEM_USER;
}

export function isTranslationId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_TRANSLATION : false;
}

export function isPipelineVarId(sysId?: SysId): boolean
{
  return sysId ? getPrefix(sysId) === PREFIX_META_ID_PIPELINE_VAR : false;
}

export function nextDeviceId(): DeviceId
{
  return nextId<DeviceId>(PREFIX_DEVICE_ID);
}

export function nextRequestId(): RequestId
{
  return nextIdBig<RequestId>(PREFIX_REQUEST_ID);
}

export function nextRowId(): RequestId
{
  return nextIdBig<RequestId>(PREFIX_ROW_ID);
}

export function nextUserId(): UserId
{
  return nextId<UserId>(PREFIX_USER_ID);
}

export function nextEntUserId(): UserId
{
  return nextId<UserId>(PREFIX_ENT_USER_ID);
}

export function nextTabId(): TabId
{
  return nextId<TabId>(PREFIX_TAB_ID);
}

export function nextTransactionId(): TabId
{
  return nextId<TransactionId>(PREFIX_TRANSACTION_ID);
}

export function nextEntId(): EntId
{
  return nextId<EntId>(PREFIX_ENT_ID);
}

export function nextTemplateId(): EntId
{
  return nextEntId().concat(SUFFIX_TEMPLATE);
}

export function nextMetaIdModule(): MetaIdModule
{
  return nextId<MetaIdModule>(PREFIX_META_ID_MODULE);
}

export function nextAdminId(): AdminId
{
  return nextId<AdminId>(PREFIX_ADMIN_ID);
}

export function nextPluginBundleId(): PluginBundleId
{
  return nextId<PluginBundleId>(PREFIX_PLUGIN_BUNDLE_ID);
}

export function nextPluginId(): PluginId
{
  return nextId<PluginId>(PREFIX_PLUGIN_ID);
}

export function nextPluginApiId(): PluginApiId
{
  return nextId<PluginApiId>(PREFIX_PLUGIN_API_ID);
}

export function nextPluginResourceId(): MetaIdPluginResource
{
  return nextId<MetaIdPluginResource>(PREFIX_META_ID_PLUGIN_RESOURCE);
}

export function nextStoreItemId(): StoreItemId
{
  return nextId<StoreItemId>(PREFIX_STORE_ITEM_ID);
}

export function nextMediaIdAudio(ext: string): MediaIdAudio
{
  return nextId<MediaIdAudio>(PREFIX_MEDIA_ID_AUDIO, ext);
}

export function nextMediaIdDocument(ext: string): MediaIdDocument
{
  return nextId<MediaIdDocument>(PREFIX_MEDIA_ID_DOCUMENT, ext);
}

export function nextMediaIdJar(ext: string): MediaIdJar
{
  return nextId<MediaIdJar>(PREFIX_MEDIA_ID_JAR, ext);
}

export function nextMediaIdImage(ext: string): MediaIdImage
{
  return nextId<MediaIdImage>(PREFIX_MEDIA_ID_IMAGE, ext);
}

export function nextMediaIdAvatar(): MediaIdAvatar
{
  return nextId<MediaIdAvatar>(PREFIX_MEDIA_ID_AVATAR, "jpg");
}

export function nextMediaIdSticker(): MediaIdSticker
{
  return nextId<MediaIdSticker>(PREFIX_MEDIA_ID_STICKER);
}

export function nextMediaIdThumbnail(): MediaIdThumbnail
{
  return nextId<MediaIdThumbnail>(PREFIX_MEDIA_ID_THUMBNAIL);
}

export function nextMediaIdVideo(ext: string): MediaIdVideo
{
  return nextId<MediaIdVideo>(PREFIX_MEDIA_ID_VIDEO, ext);
}

export function nextGroupId(): GroupId
{
  return nextId<GroupId>(PREFIX_GROUP_ID);
}

export function nextMessageId(): MessageId
{
  return nextId<MessageId>(PREFIX_MESSAGE_ID);
}

export function nextMetaIdRole(): MetaIdRole
{
  return nextId<MetaIdRole>(PREFIX_META_ID_ROLE);
}

export function nextMetaIdDeeplink(): MetaIdDeeplink
{
  return nextId<MetaIdDeeplink>(PREFIX_META_ID_DEEPLINK);
}

export function nextMetaIdDriveSheet(): MetaIdDriveSheet
{
  return nextId<MetaIdDriveSheet>(PREFIX_META_ID_DRIVE_SHEET);
}

export function nextMetaIdPlugin(): MetaIdPlugin
{
  return nextId<MetaIdPlugin>(PREFIX_META_ID_PLUGIN);
}

export function nextMetaIdReport(): MetaIdReport
{
  return nextId<MetaIdReport>(PREFIX_META_ID_REPORT);
}

export function nextMetaIdAction(): MetaIdAction
{
  return nextId<MetaIdAction>(PREFIX_META_ID_ACTION);
}

export function nextMetaIdGroup(): MetaIdGroup
{
  return nextId<MetaIdGroup>(PREFIX_META_ID_GROUP);
}

export function nextMetaIdSpreadsheet(): MetaIdSpreadsheet
{
  return nextId<MetaIdSpreadsheet>(PREFIX_META_ID_SPREADSHEET);
}

export function nextMetaIdForm(): MetaIdForm
{
  return nextId<MetaIdForm>(PREFIX_META_ID_FORM);
}

export function nextMetaIdAutomation(): MetaIdAutomation
{
  return nextId<MetaIdAutomation>(PREFIX_META_ID_AUTOMATION);
}

export function nextMetaIdAutomationEvent(): MetaId
{
  return nextId<MetaId>(PREFIX_META_ID_EVENT);
}

export function nextMetaIdAutomationEventStep(): MetaId
{
  return nextId<MetaId>(PREFIX_META_ID_STEP);
}

export function nextMetaIdField(): MetaIdField
{
  return nextId<MetaIdField>(PREFIX_META_ID_FIELD);
}

export function nextMetaIdFormula(): MetaIdField
{
  return nextId<MetaIdField>(PREFIX_META_ID_FORMULA);
}

export function nextMetaIdPartition(): MetaIdPartition
{
  return nextId<MetaIdPartition>(PREFIX_META_ID_PARTITION);
}

export function nextMetaIdSwimLane(): MetaIdSwimlane
{
  return nextId<MetaIdSwimlane>(PREFIX_META_ID_SWIMLANE);
}

export function nextMetaIdChartXAxis(): MetaIdSwimlane
{
  return nextId<MetaIdChartXAxis>(PREFIX_META_ID_CHART_X_AXIS);
}

export function nextMetaIdChartYAxis(): MetaIdChartYAxis
{
  return nextId<MetaIdChartYAxis>(PREFIX_META_ID_CHART_Y_AXIS);
}

export function nextMetaIdFuncArg(): MetaIdFuncArg
{
  return nextId<MetaIdFuncArg>(PREFIX_META_ID_FUNC_ARG);
}

export function nextMetaIdTranslation(): MetaIdField
{
  return nextId<MetaIdField>(PREFIX_META_ID_TRANSLATION);
}

export function nextMetaIdSection(): MetaIdSection
{
  return nextId<MetaIdSection>(PREFIX_META_ID_SECTION);
}

export function nextMetaIdGrid(): MetaIdGrid
{
  return nextId<MetaIdGrid>(PREFIX_META_ID_GRID);
}

export function nextMetaIdTab(): MetaIdTab
{
  return nextId<MetaIdTab>(PREFIX_META_ID_TAB);
}

export function nextMetaIdHeader(): MetaIdHeader
{
  return nextId<MetaIdHeader>(PREFIX_META_ID_HEADER);
}

export function nextMetaIdTableStyle(): MetaIdTableStyle
{
  return nextId<MetaIdTableStyle>(PREFIX_META_ID_TABLE_STYLE);
}

export function nextMetaIdPipelineVar(): MetaIdPipelineVar
{
  return nextId<MetaIdPipelineVar>(PREFIX_META_ID_PIPELINE_VAR);
}

export function nextMetaIdFooter(): MetaIdFooter
{
  return nextId<MetaIdFooter>(PREFIX_META_ID_FOOTER);
}

export function nextMetaIdVar(): MetaIdTab
{
  return nextId<MetaIdTab>(PREFIX_META_ID_VAR);
}

export function nextOptionId(): MetaIdOption
{
  return nextId<MetaIdOption>(PREFIX_META_ID_OPTION);
}

export function nextMetaIdMapping(): MetaIdMapping
{
  return nextId<MetaIdMapping>(PREFIX_META_ID_MAPPING);
}

export function nextMetaIdCondition(): MetaIdCondition
{
  return nextId<MetaIdCondition>(PREFIX_META_ID_CONDITION);
}

export function nextMetaIdPaymentProvider(): MetaIdPaymentProvider
{
  return nextId<MetaIdPaymentProvider>(PREFIX_META_ID_PAYMENT_PROVIDER);
}

export function createMediaIdThumbnail(mediaIdAvatar: MediaIdAvatar): MediaIdThumbnail
{
  return (PREFIX_MEDIA_ID_THUMBNAIL + SEP_PREFIX + getSuffix(mediaIdAvatar)) as MediaIdThumbnail;
}

export function nextMetaIdLayoutFormContent(): MetaIdLayoutFormContent
{
  return nextId<MetaIdLayoutFormContent>(PREFIX_META_ID_LAYOUT_FORM_CONTENT);
}

export function nextMetaIdVisibilityRule(): MetaIdVisibilityRule
{
  return nextId<MetaIdVisibilityRule>(PREFIX_META_ID_VISIBILITY_RULE);
}

export function nextMetaIdPrompt(): MetaIdPrompt
{
  return nextId<MetaIdPrompt>(PREFIX_META_ID_PROMPT);
}

export function nextMetaIdCode(): MetaIdCode
{
  return nextId<MetaIdCode>(PREFIX_META_ID_CODE);
}

export function nextMetaIdVisibilityAction(): MetaIdVisibilityAction
{
  return nextId<MetaIdVisibilityAction>(PREFIX_META_ID_VISIBILITY_ACTION);
}

export function nextMetaIdVisibilityCondition(): MetaIdVisibilityCondition
{
  return nextId<MetaIdVisibilityCondition>(PREFIX_META_ID_VISIBILITY_CONDITION);
}

export function nextMetaIdSetOfUserCondition(): MetaIdUserCondition
{
  return nextId<MetaIdUserCondition>(PREFIX_META_ID_USER_CONDITION);
}

export function nextMetaIdFieldDynamicCondition(): MetaIdFieldDynamicCondition
{
  return nextId<MetaIdFieldDynamicCondition>(PREFIX_META_ID_FIELD_DYNAMIC_CONDITION);
}

export function nextMetaIdFieldDynamicRule(): MetaIdFieldDynamicRule
{
  return nextId<MetaIdFieldDynamicRule>(PREFIX_META_ID_FIELD_DYNAMIC_RULE);
}

export function nextMetaIdLayoutGrid(): MetaIdLayoutGrid
{
  return nextId<MetaIdLayoutGrid>(PREFIX_META_ID_LAYOUT_GRID);
}

export function nextMetaIdLayoutDriveSheet(): MetaIdLayoutDriveSheet
{
  return nextId<MetaIdLayoutDriveSheet>(PREFIX_META_ID_LAYOUT_DRIVE_SHEET);
}


import {Typography} from "@mui/material";
import {isEqual} from "lodash";
import {useRef} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {MetaId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {getFormFieldsErrorList} from "../../../../base/plus/StudioPlus";
import {isProd} from "../../../../base/plus/SysPlus";
import {listReset} from "../../../../base/slices/list/SliceList";
import {listPushBottom} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {listMoveBottom} from "../../../../base/slices/list/SliceListSharedActions";
import {listMoveTop} from "../../../../base/slices/list/SliceListSharedActions";
import {listMoveDown} from "../../../../base/slices/list/SliceListSharedActions";
import {listMoveUp} from "../../../../base/slices/list/SliceListSharedActions";
import {listRemove} from "../../../../base/slices/list/SliceListSharedActions";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {IListData} from "../../../../base/types/list/TypesList";
import {IListItem} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IFormFieldError} from "../../../../base/types/TypesForm";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {EnumStudioSearchPathKeys} from "../../../../base/types/TypesStudio";
import {store} from "../../../../Store";
import {useAppSelector} from "../../../app/AppHooks";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {usePageCtx} from "../../../ctx/CtxPage";
import {ListClickVariant} from "../../../list/List";
import {List} from "../../../list/List";
import {useValidationErrorList} from "../../../list/validation/ListValidation";
import {useFormCtx} from "../base/CtxForm";
import FieldRawListShell from "./FieldRawListShell";

export interface IFieldRawListRef<TypeFieldValue>
{
  addItem: (item: TypeFieldValue) => void;
  setItems: (item: TypeFieldValue[]) => void;
  updateItem: (item: TypeFieldValue, itemId: TypeListItemId) => void;
  refresh: () => void;
}

export const fieldListItemVal = "fieldListItemVal";
export const fieldListLoaded = "loaded";

export type CbOnClickFormList = (
  metaId: MetaIdField,
  variant: FormClickVariant,
  value?: any,
  menuAnchor?: Element,
  menuProps?: IMenuProps,
  isFirstItem?: boolean,
  isLastItem?: boolean,
  validationError?: IFormFieldError[],
  pickValue?: boolean,
  selected?: boolean
) => void

export default function FieldRawList<TypeFieldValue, SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  fieldId: MetaIdField,
  fieldValue: TypeFieldValue[],
  cbRef?: IFieldRawListRef<TypeFieldValue>,
  isLastField?: boolean,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  buttonStrip?: React.ReactNode,
  emptyComp?: React.ReactNode | string,
  disableSpotMenu?: boolean,
  disableAutoReload?: boolean,
  onClickList?: CbOnClickFormList,
  doLoad?: (selectList: SelectList, fieldValue: TypeFieldValue[]) => IListData,
  onChange: (fieldValue: TypeFieldValue[]) => void,
  disableDynamicHeight?: boolean,
  autoHeight?: boolean
})
{
  const fieldId = props.fieldId;
  const formCtx = useFormCtx();
  const selectList = formCtx.getSelectList(fieldId);
  const formStore = formCtx.getStore();

  return selectList
    ? <RealFieldRawList
      {...props}
      formStore={formStore}
      selectList={selectList}
    />
    : !isProd()
      ? <Typography color={"red"}>Field List error: provide a sliceList path in formProps</Typography>
      : null;
}

function RealFieldRawList<TypeFieldValue, SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  fieldId: MetaIdField,
  fieldValue: TypeFieldValue[],
  selectList: SelectList,
  cbRef?: IFieldRawListRef<TypeFieldValue>,
  isLastField?: boolean
  formStore?: FormStore,
  buttonStrip?: React.ReactNode,
  emptyComp?: React.ReactNode | string,
  disableSpotMenu?: boolean
  disableAutoReload?: boolean,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  onClickList?: CbOnClickFormList,
  doLoad?: (selectList: SelectList, fieldValue: TypeFieldValue[]) => IListData
  onChange: (fieldValue: TypeFieldValue[]) => void,
  autoHeight?: boolean
})
{
  const selectList = props.selectList;
  const onClickList = props.onClickList;
  const onChange = props.onChange;
  const formStore = props.formStore;
  const isLastField = props.isLastField;
  const disableSpotMenu = props.disableSpotMenu;
  const disableAutoReload = props.disableAutoReload;
  const fieldValue = props.fieldValue;
  const doLoad = props.doLoad;
  const fieldId = props.fieldId;

  const hookFormCtx = useFormContext();
  const pageCtx = usePageCtx();
  const listName = useAppSelector(state => selectList(state).listName);
  const displayFirstItemId = useAppSelector(state => selectList(state).displayFirstItemId);
  const displayLastItemId = useAppSelector(state => selectList(state).displayLastItemId);
  const displayItemIds = useAppSelector(state => selectList(state).displayItemIds);
  const searchWords = useAppSelector(state => selectList(state).searchWords);
  const loaded = useAppSelector(state => selectList(state).userField?.loaded);
  const itemsById = useAppSelector(state => selectList(state).itemsById);
  const validationResult = formStore?.validationResult;
  const skipErrorSet = useRef<TypeListItemId[]>();
  const error = hookFormCtx.getFieldState(fieldId)?.error;
  const onClickItemInfo = useValidationErrorList(selectList,
    fieldId as EnumStudioSearchPathKeys,
    undefined,
    (error ? validationResult : undefined),
    skipErrorSet.current
  );
  const errorList = useCallback((metaId: MetaId) =>
  {
    return getFormFieldsErrorList(fieldId as EnumStudioSearchPathKeys, metaId, validationResult, 5);
  }, [validationResult, fieldId]);

  const onChangeList = useCallback(() =>
  {
    const newListValue = displayItemIds.reduce((itemArray, itemId) =>
    {
      const item = itemsById[itemId];
      const userField = item?.userField;
      if(userField && userField[fieldListItemVal])
      {
        itemArray.push(userField[fieldListItemVal] as TypeFieldValue);
      }
      return itemArray;
    }, [] as TypeFieldValue[]);

    if(!isEqual(newListValue, fieldValue))
    {
      onChange(newListValue);
    }
  }, [displayItemIds, itemsById, fieldValue, onChange]);

  const loadList = useCallback((selectList: SelectList, fieldValue: TypeFieldValue[]) =>
  {
    if(doLoad)
    {
      const rootState = store.getState();
      const pickItemIds = selectList(rootState).pickItemIds;
      const iListData = doLoad(selectList, fieldValue);
      const listData = {
        ...iListData,
        pickItemIds: pickItemIds,
        userField: {
          ...iListData.userField,
          [fieldListLoaded]: true
        }
      } as IListData;
      dispatchList(listName, listRefresh(listData));
    }
  }, [doLoad, listName]);

  const addItem = useCallback((item: TypeFieldValue) =>
  {
    if(doLoad)
    {
      const {
        itemIds,
        itemsById
      } = doLoad(selectList, [item]);

      if(itemIds && itemsById)
      {
        const itemId = itemIds[0];
        dispatchList(listName, listSetItem({
          itemId: itemId,
          newItem: itemsById[itemId]
        }));
        dispatchList(listName, listPushBottom(itemId));
      }
    }
  }, [doLoad, listName, selectList]);

  const updateItem = useCallback((item: TypeFieldValue, itemId: TypeListItemId) =>
  {
    if(doLoad)
    {
      const {
        itemIds,
        itemsById
      } = doLoad(selectList, [item]);

      if(itemIds && itemsById)
      {
        if(!skipErrorSet.current)
        {
          skipErrorSet.current = [itemId];
        }
        else
        {
          skipErrorSet.current?.push(itemId);
        }

        dispatchList(listName, listSetItem({
          itemId: itemId,
          newItem: itemsById[itemIds[0]]
        }));
      }
    }
  }, [doLoad, listName, selectList]);

  const setItems = useCallback((items: TypeFieldValue[]) =>
  {
    loadList(selectList, items);
  }, [loadList, selectList]);

  const refRefresh = useCallback(() =>
  {
    loadList(selectList, fieldValue);
  }, [loadList, selectList, fieldValue]);

  const onClickListItem = useCallback((
      menuAnchor: Element,
      itemId: TypeListItemId,
      item: IListItem,
      variant: ListClickVariant,
      pickValue?: boolean,
      selected?: boolean) =>
    {
      const isFirst = displayFirstItemId === itemId;
      const isLast = displayLastItemId === itemId;
      let menuProps: IMenuProps = {};

      const validationError = errorList(itemId);

      switch(variant)
      {
        case "listItem":
          onClickList && onClickList(itemId,
            "listItem",
            item,
            menuAnchor,
            menuProps,
            isFirst,
            isLast,
            validationError,
            pickValue,
            selected
          );
          break;
        case "spotMenu":
          if(!disableSpotMenu)
          {
            menuProps = {
              "Edit": {
                onClick: () =>
                {
                  onClickList && onClickList(itemId,
                    "listItem",
                    item,
                    menuAnchor,
                    menuProps,
                    isFirst,
                    isLast,
                    validationError,
                    pickValue,
                    selected
                  );
                }
              },
              "Remove": {
                onClick: () =>
                {
                  dispatchList(listName, listRemove(itemId));
                }
              },
              "gap1": undefined,
              "Move up": {
                onClick: () =>
                {
                  dispatchList(listName, listMoveUp(itemId));
                },
                disabled: isFirst
              },
              "Move down": {
                onClick: () =>
                {
                  dispatchList(listName, listMoveDown(itemId));
                },
                disabled: isLast
              },
              "Move top": {
                onClick: () =>
                {
                  dispatchList(listName, listMoveTop(itemId));
                },
                disabled: isFirst
              },
              "Move bottom": {
                onClick: () =>
                {
                  dispatchList(listName, listMoveBottom(itemId));
                },
                disabled: isLast
              }
            };

            menuAnchor && pageCtx.showMenu(menuAnchor, menuProps);
          }
          onClickList && onClickList(itemId,
            "spotMenu",
            item,
            menuAnchor,
            menuProps,
            isFirst,
            isLast,
            validationError,
            pickValue,
            selected
          );
          break;
        case "spotInfo":
          onClickItemInfo(menuAnchor, itemId);
          onClickList && onClickList(itemId, "spotInfo", item, menuAnchor, menuProps, isFirst, isLast, validationError);
          break;
      }
    },
    [displayFirstItemId, displayLastItemId, errorList, onClickList, disableSpotMenu, onClickItemInfo, pageCtx, listName]
  );

  const onItemSubscribe = useCallback((itemId: TypeListItemId) =>
  {
    const rootState = store.getState();
    const itemsById = selectList(rootState).itemsById;
    onClickList &&
    onClickList(itemId, "listItemSubscribe", itemsById[itemId]);
  }, []);

  const onItemUnsubscribe = useCallback((itemId: TypeListItemId) =>
  {
    const rootState = store.getState();
    const itemsById = selectList(rootState).itemsById;
    onClickList &&
    onClickList(itemId, "listItemUnsubscribe", itemsById[itemId]);
  }, []);

  const onBottomReached = useCallback(() =>
  {
    onClickList &&
    onClickList(fieldId, "listBottomReached");
  }, [onClickList]);

  if(props.cbRef)
  {
    props.cbRef.addItem = addItem;
    props.cbRef.setItems = setItems;
    props.cbRef.updateItem = updateItem;
    props.cbRef.refresh = refRefresh;
  }

  useEffect(() =>
  {
    if(disableAutoReload)
    {
      loadList(selectList, fieldValue);
    }
  }, []);

  useEffect(() =>
  {
    if(!disableAutoReload)
    {
      loadList(selectList, fieldValue);
    }
  }, [fieldValue]);

  useEffect(() =>
  {
    if(loaded && !searchWords)
    {
      onChangeList();
    }
  }, [itemsById, displayItemIds, loaded]);

  useEffect(() =>
  {
    return () =>
    {
      dispatchList(listName, listReset());
    };
  }, []);

  return (
    <FieldRawListShell
      list={
        <List
          selectList={selectList}
          onClickListItem={onClickListItem}
          listBinder={props.listBinder}
          onItemSubscribe={onItemSubscribe}
          onItemUnsubscribe={onItemUnsubscribe}
          onBottomReached={onBottomReached}
          emptyComp={props.emptyComp}
        />
      }
      buttonStrip={props.buttonStrip}
      showButtonStripAtBottom={isLastField}
      selectList={selectList}
      autoHeight={props.autoHeight}
    />
  );
}


import {FieldValues} from "react-hook-form/dist/types/fields";
import {DtoEntAction} from "../../../api/ent/base/dto/DtoEntAction";
import {DtoEntActionReport} from "../../../api/ent/base/dto/DtoEntActionReport";
import {DtoEntActionRowInsert} from "../../../api/ent/base/dto/DtoEntActionRowInsert";
import {DtoEntActionSpreadsheetEditor} from "../../../api/ent/base/dto/DtoEntActionSpreadsheetEditor";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldButton} from "../../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldPickReportRow} from "../../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldPickUser} from "../../../api/meta/base/dto/DefnFieldPickUser";
import {DefnFieldRef} from "../../../api/meta/base/dto/DefnFieldRef";
import {DefnFieldSetOfText} from "../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {FieldValueError} from "../../../api/meta/base/dto/FieldValueError";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {MetaIdAction} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EnvSignal} from "../../../api/nucleus/base/dto/EnvSignal";
import {Sig} from "../../../api/nucleus/base/sig/Sig";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";

import {IFormViewerRef} from "../../../nucleus/form/viewer/base/formViewer/TypesFormViewer";
import {Srvc} from "../../../srvc/Srvc";
import {RootState} from "../../../Store";

//endregion

export function getFieldIdToRefFieldIdMap(defnForm: DefnForm, type: EnumDefnCompType)
{
  const refFieldMap = {} as Record<MetaIdField, MetaIdField>;

  if(defnForm.compMap)
  {
    Object.entries(defnForm.compMap).forEach(([key, comp]) =>
    {
      if(comp.type === type)
      {
        const defnRef = comp as DefnFieldRef | DefnFieldPickReportRow;

        if(defnRef.copyFieldMap)
        {
          Object.keys(defnRef.copyFieldMap).forEach(childId =>
          {
            refFieldMap[childId] = key;
          });
        }
      }
    });
  }

  return refFieldMap;
}

export function fnHandleFieldApiCallError(
  field: DefnField,
  envSig: EnvSignal<Sig>,
  cbRefFormViewer?: IFormViewerRef)
{
  if(envSig.error)
  {
    let pluginErrorFieldId: MetaIdField | undefined;
    if(field.type === "button")
    {
      pluginErrorFieldId = (field as DefnFieldButton).pluginErrorFieldId;
    }
    else if(field.type === "pickText")
    {
      pluginErrorFieldId = (field as DefnFieldPickText).pluginErrorFieldId;
    }
    else if(field.type === "setOfText")
    {
      pluginErrorFieldId = (field as DefnFieldSetOfText).pluginErrorFieldId;
    }
    else if(field.type === "pickTree")
    {
      pluginErrorFieldId = (field as DefnFieldPickTree).pluginErrorFieldId;
    }
    else if(field.type === "pickUser")
    {
      pluginErrorFieldId = (field as DefnFieldPickUser).pluginErrorFieldId;
    }
    else if(field.type === "setOfUser")
    {
      pluginErrorFieldId = (field as DefnFieldSetOfUser).pluginErrorFieldId;
    }

    if(pluginErrorFieldId)
    {
      let errorMessage = envSig.error?.errorMessage;
      let errorParams = envSig.error?.errorParams;

      if(envSig.error?.errorCode === "validationError")
      {
        const validationErrors = envSig.error.validationErrors;
        if(validationErrors && validationErrors.length > 0)
        {
          const validation = validationErrors[0];
          errorMessage = validation.errorMessage;
          errorParams = validation.errorParams;
        }
      }

      if(errorMessage)
      {
        const errorValue: FieldValueError = {
          errorCounter: 1,
          errorReason: errorMessage,
          errorParameterSet: errorParams,
          severity: "error"
        };
        cbRefFormViewer?.setValue && cbRefFormViewer?.setValue(pluginErrorFieldId, errorValue);
      }
    }
    else
    {
      Srvc.app.toast.showErrorToast(envSig);
    }
  }
}

export function selectCallerEntAction(state: RootState, entId: EntId, actionId?: MetaIdAction)
{
  if(!actionId)
  {
    return;
  }

  return selectCallerEnt(state, entId)?.actionMap[actionId];
}

export function fnGetActionSpreadsheetId(
  state: RootState,
  entId: EntId,
  actionId?: MetaIdAction,
  sigSpreadsheetRow?: SigSpreadsheetRow)
{
  const metaIdSpreadsheet: MetaIdSpreadsheet | undefined = sigSpreadsheetRow?.spreadsheetId;

  return metaIdSpreadsheet ||
    (selectCallerEntAction(state,
      entId,
      actionId
    ) as DtoEntActionRowInsert)?.spreadsheetId;
}

export function fnGetDefaultActionValueMap(action?: DtoEntAction, defaultValueMap?: FieldValues)
{
  if(!action)
  {
    return;
  }

  const actionDefaultValueMap = (action as DtoEntActionRowInsert | DtoEntActionReport | DtoEntActionSpreadsheetEditor)?.defaultValueMap;

  if(!defaultValueMap && !actionDefaultValueMap)
  {
    return;
  }
  else
  {
    return {
      ...(actionDefaultValueMap || {}),
      ...(defaultValueMap || {})
    };
  }
}

import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdMapping} from "../../../api/meta/base/ApiPlus";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnSection} from "../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfMapping} from "../../../api/meta/base/dto/DefnStudioMapOfMapping";
import {DefnStudioPickFieldId} from "../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickFormId} from "../../../api/meta/base/dto/DefnStudioPickFormId";
import {DefnStudioPickGridId} from "../../../api/meta/base/dto/DefnStudioPickGridId";
import {DefnStudioPickImportPluginId} from "../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickPluginCompId} from "../../../api/meta/base/dto/DefnStudioPickPluginCompId";
import {DefnStudioPickPluginFieldId} from "../../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {DefnStudioPickPluginFormId} from "../../../api/meta/base/dto/DefnStudioPickPluginFormId";
import {DefnTab} from "../../../api/meta/base/dto/DefnTab";
import {StudioDtoMappingFieldMap} from "../../../api/meta/base/dto/StudioDtoMappingFieldMap";
import {StudioDtoMappingFieldMapBase} from "../../../api/meta/base/dto/StudioDtoMappingFieldMapBase";
import {StudioDtoMappingGrid} from "../../../api/meta/base/dto/StudioDtoMappingGrid";
import {StudioDtoMappingGridMap} from "../../../api/meta/base/dto/StudioDtoMappingGridMap";
import {StudioVarMapping} from "../../../api/meta/base/dto/StudioVarMapping";
import {EnumArrayDefnFields} from "../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {arrayToMapOfOption} from "../../../base/plus/JsPlus";
import {formNeomeLocation} from "../../../base/plus/StudioPlus";
import {formNeomeComment} from "../../../base/plus/StudioPlus";
import {formPaymentReceipt} from "../../../base/plus/StudioPlus";
import {formSchedulerTrigger} from "../../../base/plus/StudioPlus";
import {fieldGap5} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap4} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap3} from "../../form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../../form/builder/base/TypesFormBuilder";
import {fieldMappingToLabel} from "../base/VariablePlus";
import {fieldMappingFromLabel} from "../base/VariablePlus";
import {fieldVariableMappingToKey} from "../base/VariablePlus";
import {fieldVariableMappingFromKey} from "../base/VariablePlus";
import {fieldFieldMappingVariantEmpty} from "../base/VariablePlus";
import {fieldFieldMappingVariantUpdate} from "../base/VariablePlus";
import {fieldFieldMappingVariantInsert} from "../base/VariablePlus";
import {fieldMappingCopyFieldKeysLabel} from "../base/VariablePlus";
import {fieldGridMappingVariantEmpty} from "../base/VariablePlus";
import {fieldMappingCopyGridKeysLabel} from "../base/VariablePlus";
import {fieldGridMappingVariantRemove} from "../base/VariablePlus";
import {fieldGridMappingVariantUpdate} from "../base/VariablePlus";
import {fieldGridMappingVariantInsert} from "../base/VariablePlus";
import {fieldVariableMappingToGridKey} from "../base/VariablePlus";
import {fieldVariableMappingFromGridKey} from "../base/VariablePlus";
import {fieldMappingToType} from "../base/VariablePlus";
import {TypeFromToType} from "../base/VariablePlus";
import {fieldMappingFromType} from "../base/VariablePlus";
import {fieldVariableMappingToPluginId} from "../base/VariablePlus";
import {fieldVariableMappingFromPluginId} from "../base/VariablePlus";
import {fieldVariableMappingFromFormId, fieldVariableMappingFromGridId, fieldVariableMappingToFormId, fieldVariableMappingToGridId} from "../base/VariablePlus";
import {fieldMappingFromToTab} from "../VariableBuilder";
import {compMappingMappingTab} from "../VariableBuilder";

export const fieldFieldMap = "fieldMap";
export const fieldGridMap = "gridMap";

const fieldTabMappingGridSection = "mappingGridSection";
const fieldTabMappingFieldSection = "mappingFieldSection";

export function fnVariableBuilderMapping(fieldValueMapping: string)
{
  function getVarComp(
    fromType: TypeFromToType,
    fromPluginId?: MetaIdPlugin,
    fromFormId?: MetaIdForm,
    fromGridId?: MetaIdField,
    toType?: TypeFromToType,
    toPluginId?: MetaIdPlugin,
    toFormId?: MetaIdForm,
    toGridId?: MetaIdField
  )
  {
    function getVarCompMappingFrom()
    {
      return {
        [fieldMappingFromLabel]: {
          type: "label",
          metaId: fieldMappingFromLabel,
          name: fieldMappingFromLabel,
          label: "From",
          disabled: true
        } as DefnFieldLabel,

        [fieldMappingFromType]: {
          type: "pickText",
          metaId: fieldMappingFromType,
          name: fieldMappingFromType,
          required: true,
          optionMap: arrayToMapOfOption(["regular", "plugin"] as TypeFromToType[]),
          defaultOptionIdArray: ["regular"] as TypeFromToType[],
          showAs: "radioButtonHorizontal"
        } as DefnFieldPickText,

        ...fromType === "plugin" && {
          [fieldVariableMappingFromPluginId]: {
            type: "pickImportPluginId",
            metaId: fieldVariableMappingFromPluginId,
            name: fieldVariableMappingFromPluginId,
            label: "From plugin"
          } as DefnStudioPickImportPluginId,

          [fieldVariableMappingFromFormId]: {
            type: "pickPluginFormId",
            metaId: fieldVariableMappingFromFormId,
            pluginId: fromPluginId,
            name: "Form"
          } as DefnStudioPickPluginFormId,

          [fieldVariableMappingFromGridId]: {
            type: "pickPluginCompId",
            metaId: fieldVariableMappingFromGridId,
            pluginId: fromPluginId,
            formId: fromFormId,
            filterCompTypeSet: ["grid"],
            name: "Grid"
          } as DefnStudioPickPluginCompId
        },

        ...fromType === "regular" && {
          [fieldVariableMappingFromFormId]: {
            type: "pickFormId",
            metaId: fieldVariableMappingFromFormId,
            name: "Form",
            includeOptionMap: arrayToMapOfOption([
              formSchedulerTrigger,
              formPaymentReceipt,
              formNeomeComment,
              formNeomeLocation
            ], true)
          } as DefnStudioPickFormId,

          [fieldVariableMappingFromGridId]: {
            type: "pickGridId",
            metaId: fieldVariableMappingFromGridId,
            name: "Grid",
            formId: fromFormId
          } as DefnStudioPickGridId
        }
      };
    }

    function getVarCompMappingTo()
    {
      return {
        ...getFieldGap(fieldGap2, "thick"),
        [fieldMappingToLabel]: {
          type: "label",
          metaId: fieldMappingToLabel,
          name: fieldMappingToLabel,
          label: "To",
          disabled: true
        } as DefnFieldLabel,

        [fieldMappingToType]: {
          type: "pickText",
          metaId: fieldMappingToType,
          name: fieldMappingToType,
          required: true,
          optionMap: arrayToMapOfOption(["regular", "plugin"] as TypeFromToType[]),
          defaultOptionIdArray: ["regular"] as TypeFromToType[],
          showAs: "radioButtonHorizontal"
        } as DefnFieldPickText,

        ...toType === "plugin" && {
          [fieldVariableMappingToPluginId]: {
            type: "pickImportPluginId",
            name: fieldVariableMappingToPluginId,
            metaId: fieldVariableMappingToPluginId,
            label: "Plugin"
          } as DefnStudioPickImportPluginId,

          [fieldVariableMappingToFormId]: {
            type: "pickPluginFormId",
            metaId: fieldVariableMappingToFormId,
            pluginId: toPluginId,
            name: "Form",
            required: true,
            showAs: "dropdown"
          } as DefnStudioPickPluginFormId,

          [fieldVariableMappingToGridId]: {
            type: "pickPluginCompId",
            metaId: fieldVariableMappingToGridId,
            pluginId: toPluginId,
            formId: toFormId,
            filterCompTypeSet: ["grid"],
            name: "Grid"
          } as DefnStudioPickPluginCompId
        },

        ...toType === "regular" && {
          [fieldVariableMappingToFormId]: {
            type: "pickFormId",
            metaId: fieldVariableMappingToFormId,
            name: "Form",
            required: true
          } as DefnStudioPickFormId,

          [fieldVariableMappingToGridId]: {
            type: "pickGridId",
            metaId: fieldVariableMappingToGridId,
            name: "Grid",
            formId: toFormId
          } as DefnStudioPickGridId
        }
      };
    }

    const mappingForm = getVarCompMappingFrom();
    const mappingTo = getVarCompMappingTo();

    function getVarCompMappingFromTo()
    {
      return {
        ...mappingForm,
        ...mappingTo,

        [fieldMappingFromToTab]: {
          type: "section",
          name: fieldMappingFromToTab,
          metaId: fieldMappingFromToTab,
          label: "From > To",
          fieldIdSet: [
            ...Object.keys(mappingForm),
            ...Object.keys(mappingTo)
          ]
        } as DefnSection
      };
    }

    function getVarCompMapping()
    {
      const defnGridCopy = getDefnGridCopy(
        fromPluginId,
        fromFormId,
        fromGridId,
        toPluginId,
        toFormId,
        toGridId
      );

      const defnFieldCopy = getDefnFieldCopy(
        fromPluginId,
        fromFormId,
        fromGridId,
        toPluginId,
        toFormId,
        toGridId
      );

      return {
        ...defnGridCopy,

        [fieldGridMap]: {
          type: "studioGridMappingTree",
          metaId: fieldGridMap,
          name: fieldGridMap,
          label: "Mapping",
          required: true,
          fromFormId: fromFormId,
          fromGridId: fromGridId,
          toFormId: toFormId,
          toGridId: toGridId,
          fromPluginId: fromPluginId,
          toPluginId: toPluginId
        } as DefnStudioMapOfMapping,

        ...defnFieldCopy,

        [fieldFieldMap]: {
          type: "studioFieldMappingTree",
          metaId: fieldFieldMap,
          name: fieldFieldMap,
          label: "Mapping",
          required: true,
          fromFormId: fromFormId,
          fromGridId: fromGridId,
          toFormId: toFormId,
          toGridId: toGridId,
          fromPluginId: fromPluginId,
          toPluginId: toPluginId
        } as DefnStudioMapOfMapping,

        [fieldTabMappingGridSection]: {
          type: "section",
          metaId: fieldTabMappingGridSection,
          name: fieldTabMappingGridSection,
          label: "Grid",
          fieldIdSet: [
            ...Object.keys(defnGridCopy),
            ...(!fromGridId && !toGridId)
              ? [
                fieldGridMap
              ]
              : []
          ]
        } as DefnSection,

        [fieldTabMappingFieldSection]: {
          type: "section",
          metaId: fieldTabMappingFieldSection,
          name: fieldTabMappingFieldSection,
          label: "Field",
          fieldIdSet: [
            ...Object.keys(defnFieldCopy),
            fieldFieldMap
          ]
        } as DefnSection,

        ...(!fromGridId && !toGridId)
          ? {
            [compMappingMappingTab]: {
              type: "tab",
              metaId: compMappingMappingTab,
              name: compMappingMappingTab,
              label: "Mapping",
              tabIdSet: [fieldTabMappingFieldSection, fieldTabMappingGridSection]
            } as DefnTab
          }
          : {
            [compMappingMappingTab]: {
              type: "section",
              metaId: compMappingMappingTab,
              name: compMappingMappingTab,
              label: "Mapping",
              fieldIdSet: [...Object.keys(defnGridCopy), fieldTabMappingFieldSection]
            } as DefnSection
          }

      };
    }

    return {
      ...getVarCompMappingFromTo(),
      ...getVarCompMapping()
    };
  }

  function dtoToDefn(
    valueMap: FieldValues,
    variable: StudioVarMapping)
  {
    const varValue = variable.value;

    const formFormId = fnRawValueToFieldValue("pickFormId", varValue?.fromFormId);
    const fromGridId = fnRawValueToFieldValue("pickGridId", varValue?.fromGridId);
    const toFormId = fnRawValueToFieldValue("pickFormId", varValue?.toFormId);
    const toGridId = fnRawValueToFieldValue("pickGridId", varValue?.toGridId);
    const fromPluginId = fnRawValueToFieldValue("pickPluginId", varValue?.fromPluginId);
    const toPluginId = fnRawValueToFieldValue("pickPluginId", varValue?.toPluginId);

    valueMap[fieldMappingFromType] = fromPluginId
      ? fnRawValueToFieldValue("pickText", "plugin")
      : fnRawValueToFieldValue("pickText", "regular");

    valueMap[fieldMappingToType] = toPluginId
      ? fnRawValueToFieldValue("pickText", "plugin")
      : fnRawValueToFieldValue("pickText", "regular");

    const gridToGrid = fromGridId && toGridId;

    const gridMap = varValue?.gridMappingMap;
    const firstKey = gridMap?.keys[0];
    const mappingGrid: StudioDtoMappingGrid | undefined = (gridMap && firstKey)
      ? gridMap.map[firstKey]
      : undefined;

    const fieldMap: StudioDtoMappingFieldMap | undefined = (gridToGrid && firstKey)
      ? gridMap?.map[firstKey]?.fieldMappingMap as StudioDtoMappingFieldMap
      : varValue?.fieldMappingMap;

    valueMap[fieldVariableMappingFromGridKey] = fromPluginId
      ? fnRawValueToFieldValue("pickPluginFieldId", mappingGrid?.fromGridKey ?? "$RowId")
      : fnRawValueToFieldValue("pickFieldId", mappingGrid?.fromGridKey ?? "$RowId");

    valueMap[fieldVariableMappingToGridKey] = toPluginId
      ? fnRawValueToFieldValue("pickPluginFieldId", mappingGrid?.toGridKey)
      : fnRawValueToFieldValue("pickFieldId", mappingGrid?.toGridKey);

    valueMap[fieldGridMappingVariantInsert] = fnRawValueToFieldValue("enumInsertVariant",
      mappingGrid?.insertVariant ?? "insertForced"
    );
    valueMap[fieldGridMappingVariantUpdate] = fnRawValueToFieldValue("enumUpdateVariant",
      mappingGrid?.updateVariant ?? "updateForced"
    );
    valueMap[fieldGridMappingVariantRemove] = fnRawValueToFieldValue("enumRemoveVariant",
      mappingGrid?.removeVariant ?? "removeForced"
    );
    valueMap[fieldGridMappingVariantEmpty] = fnRawValueToFieldValue("enumEmptyFieldVariant",
      mappingGrid?.emptyFieldVariant ?? "overrideEmptyField"
    );

    valueMap[fieldVariableMappingFromKey] = fromPluginId
      ? fnRawValueToFieldValue("pickPluginFieldId", fieldMap?.fromKey)
      : fnRawValueToFieldValue("pickFieldId", fieldMap?.fromKey);

    valueMap[fieldVariableMappingToKey] = toPluginId
      ? fnRawValueToFieldValue("pickPluginFieldId", fieldMap?.toKey)
      : fnRawValueToFieldValue("pickFieldId", fieldMap?.toKey);

    valueMap[fieldFieldMappingVariantInsert] = fnRawValueToFieldValue("enumInsertVariant", fieldMap?.insertVariant);
    valueMap[fieldFieldMappingVariantUpdate] = fnRawValueToFieldValue("enumUpdateVariant", fieldMap?.updateVariant);
    valueMap[fieldFieldMappingVariantEmpty] =
      fnRawValueToFieldValue("enumEmptyFieldVariant", fieldMap?.emptyFieldVariant);

    if(varValue)
    {
      valueMap[fieldGridMap] = gridMap;
      valueMap[fieldFieldMap] = fieldMap;
      valueMap[fieldVariableMappingFromFormId] = formFormId;
      valueMap[fieldVariableMappingFromGridId] = fromGridId;
      valueMap[fieldVariableMappingToFormId] = toFormId;
      valueMap[fieldVariableMappingToGridId] = toGridId;
      valueMap[fieldVariableMappingFromPluginId] = fromPluginId;
      valueMap[fieldVariableMappingToPluginId] = toPluginId;
    }
  }

  function defnToDto(valueMap: FieldValues)
  {
    const fromFormId = fnFieldValueToRawValue("pickFormId", valueMap[fieldVariableMappingFromFormId]);
    const toFormId = fnFieldValueToRawValue("pickFormId", valueMap[fieldVariableMappingToFormId]);

    const toGridId = fnFieldValueToRawValue("pickGridId", valueMap[fieldVariableMappingToGridId]);
    const fromGridId = fnFieldValueToRawValue("pickGridId", valueMap[fieldVariableMappingFromGridId]);

    const fromPluginId = fnFieldValueToRawValue("pickPluginId", valueMap[fieldVariableMappingFromPluginId]);
    const toPluginId = fnFieldValueToRawValue("pickPluginId", valueMap[fieldVariableMappingToPluginId]);

    const sectionToGrid = !fromGridId && toGridId;
    const gridToSection = fromGridId && !toGridId;
    const gridToGrid = fromGridId && toGridId;

    const gridMap = fnFieldValueToRawValue("studioGridMappingTree",
      valueMap[fieldGridMap]
    ) as StudioDtoMappingGridMap | undefined;
    const fieldMap = fnFieldValueToRawValue("studioFieldMappingTree",
      valueMap[fieldFieldMap]
    ) as StudioDtoMappingFieldMapBase | undefined;

    const copyFieldKeys = toFormId
      ? {
        fromKey: fromFormId
          ? fromPluginId
            ? fnFieldValueToRawValue("pickPluginFieldId", valueMap[fieldVariableMappingFromKey])
            : fnFieldValueToRawValue("pickFieldId", valueMap[fieldVariableMappingFromKey])
          : undefined,
        toKey: toPluginId
          ? fnFieldValueToRawValue("pickPluginFieldId", valueMap[fieldVariableMappingToKey])
          : fnFieldValueToRawValue("pickFieldId", valueMap[fieldVariableMappingToKey]),
        ...sectionToGrid && {
          insertVariant: fnFieldValueToRawValue("enumInsertVariant", valueMap[fieldFieldMappingVariantInsert])
        },
        updateVariant: fnFieldValueToRawValue("enumUpdateVariant", valueMap[fieldFieldMappingVariantUpdate]),
        emptyFieldVariant: fnFieldValueToRawValue("enumEmptyFieldVariant", valueMap[fieldFieldMappingVariantEmpty])
      } as StudioDtoMappingFieldMap
      : {};

    const _fieldMap = {
      ...fieldMap ?? {},
      ...(!gridToGrid && toFormId) && copyFieldKeys
    } as StudioDtoMappingFieldMap;

    const filteredFieldMap = {
      ..._fieldMap,
      keys: [..._fieldMap?.keys ?? []],
      map: {
        ..._fieldMap?.map ?? {}
      }
    } as StudioDtoMappingFieldMap;

    let newGridMap: StudioDtoMappingGridMap | undefined = gridMap;
    let newFieldMap: StudioDtoMappingFieldMap | undefined = filteredFieldMap;

    if((sectionToGrid || gridToSection) && newGridMap)
    {
      newGridMap = undefined;
    }
    else if(gridToGrid)
    {
      const firstKey = newGridMap?.keys[0] ?? nextMetaIdMapping();
      const firstGridMap = newGridMap?.map[firstKey];

      const copyGridKeys = {
        fromGridKey: fromPluginId
          ? fnFieldValueToRawValue("pickPluginFieldId", valueMap[fieldVariableMappingFromGridKey] ?? "$RowId")
          : fnFieldValueToRawValue("pickFieldId", valueMap[fieldVariableMappingFromGridKey] ?? "$RowId"),
        toGridKey: toPluginId
          ? fnFieldValueToRawValue("pickPluginFieldId", valueMap[fieldVariableMappingToGridKey])
          : fnFieldValueToRawValue("pickFieldId", valueMap[fieldVariableMappingToGridKey]),
        insertVariant: fnFieldValueToRawValue("enumInsertVariant",
          valueMap[fieldGridMappingVariantInsert] ?? "insertForced"
        ),
        updateVariant: fnFieldValueToRawValue("enumUpdateVariant",
          valueMap[fieldGridMappingVariantUpdate] ?? "updateForced"
        ),
        removeVariant: fnFieldValueToRawValue("enumRemoveVariant",
          valueMap[fieldGridMappingVariantRemove] ?? "removeForced"
        ),
        emptyFieldVariant: fnFieldValueToRawValue("enumEmptyFieldVariant",
          valueMap[fieldGridMappingVariantEmpty] ?? "overrideEmptyField"
        )
      } as StudioDtoMappingGrid;

      newGridMap = {
        keys: [firstKey],
        map: {
          [firstKey]: {
            ...firstGridMap,
            ...copyGridKeys,
            fieldMappingMap: fieldMap,
            metaId: firstKey,
            fromGridId: fromGridId,
            toGridId: toGridId
          } as StudioDtoMappingGrid
        }
      };

      newFieldMap = undefined;
    }

    return {
      value: {
        fromFormId: fromFormId,
        fromGridId: fromGridId,
        toFormId: toFormId,
        toGridId: toGridId,
        fromPluginId: fromPluginId,
        toPluginId: toPluginId,
        gridMappingMap: newGridMap,
        fieldMappingMap: newFieldMap
      }
    } as StudioVarMapping;
  }

  return {
    getVarComp,
    dtoToDefn,
    defnToDto
  };
}

function getDefnGridCopy(
  fromPluginId?: MetaIdPlugin,
  fromFormId?: MetaIdForm,
  fromGridId?: MetaIdField,
  toPluginId?: MetaIdPlugin,
  toFormId?: MetaIdForm,
  toGridId?: MetaIdField
)
{
  const includeOptionMap = arrayToMapOfOption(EnumArrayDefnFields, true);
  const gridToGrid = fromGridId && toGridId;

  if(!gridToGrid)
  {
    return {};
  }

  return {

    [fieldMappingCopyGridKeysLabel]: {
      type: "label",
      metaId: fieldMappingCopyGridKeysLabel,
      name: fieldMappingCopyGridKeysLabel,
      label: "Keys",
      disabled: true
    } as DefnFieldLabel,

    ...fromPluginId
      ? {
        [fieldVariableMappingFromGridKey]: {
          type: "pickPluginFieldId",
          metaId: fieldVariableMappingFromGridKey,
          name: fieldVariableMappingFromGridKey,
          label: "From grid key",
          formId: fromFormId,
          pluginId: fromPluginId,
          compositeIdSet: fromGridId ? [fromGridId] : undefined,
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldVariableMappingFromGridKey]: {
          type: "pickFieldId",
          metaId: fieldVariableMappingFromGridKey,
          name: fieldVariableMappingFromGridKey,
          label: "From grid key",
          formId: fromFormId,
          compositeIdSet: fromGridId ? [fromGridId] : undefined,
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickFieldId
      },

    ...toPluginId
      ? {
        [fieldVariableMappingToGridKey]: {
          type: "pickPluginFieldId",
          metaId: fieldVariableMappingToGridKey,
          name: fieldVariableMappingToGridKey,
          label: "To grid key",
          formId: toFormId,
          pluginId: toPluginId,
          compositeIdSet: toGridId ? [toGridId] : undefined,
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldVariableMappingToGridKey]: {
          type: "pickFieldId",
          metaId: fieldVariableMappingToGridKey,
          name: fieldVariableMappingToGridKey,
          label: "To grid key",
          formId: toFormId,
          compositeIdSet: toGridId ? [toGridId] : undefined,
          required: true,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickFieldId
      },

    ...getFieldGap(fieldGap2),

    [fieldGridMappingVariantInsert]: {
      type: "enumInsertVariant",
      metaId: fieldGridMappingVariantInsert,
      name: fieldGridMappingVariantInsert,
      label: "Insert variant",
      required: true
    } as DefnFieldPickEnum,

    [fieldGridMappingVariantUpdate]: {
      type: "enumUpdateVariant",
      metaId: fieldGridMappingVariantUpdate,
      name: fieldGridMappingVariantUpdate,
      label: "Update variant",
      required: true
    } as DefnFieldPickEnum,

    [fieldGridMappingVariantRemove]: {
      type: "enumRemoveVariant",
      metaId: fieldGridMappingVariantRemove,
      name: fieldGridMappingVariantRemove,
      label: "Remove variant",
      required: true
    } as DefnFieldPickEnum,

    [fieldGridMappingVariantEmpty]: {
      type: "enumEmptyFieldVariant",
      metaId: fieldGridMappingVariantEmpty,
      name: fieldGridMappingVariantEmpty,
      label: "Empty field variant",
      required: true
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap3, "thick")
  };
}

function getDefnFieldCopy(
  fromPluginId?: MetaIdPlugin,
  fromFormId?: MetaIdForm,
  fromGridId?: MetaIdField,
  toPluginId?: MetaIdPlugin,
  toFormId?: MetaIdForm,
  toGridId?: MetaIdField
)
{
  const includeOptionMap = arrayToMapOfOption(EnumArrayDefnFields, true);

  const sectionToGrid = !fromGridId && toGridId;
  const gridToGrid = fromGridId && toGridId;

  if(gridToGrid || !toFormId)
  {
    return {};
  }

  return {
    [fieldMappingCopyFieldKeysLabel]: {
      type: "label",
      metaId: fieldMappingCopyFieldKeysLabel,
      name: fieldMappingCopyFieldKeysLabel,
      label: "Keys",
      disabled: true
    } as DefnFieldLabel,

    ...fromPluginId
      ? {
        [fieldVariableMappingFromKey]: {
          type: "pickPluginFieldId",
          metaId: fieldVariableMappingFromKey,
          name: fieldVariableMappingFromKey,
          label: "From key",
          formId: fromFormId,
          disabled: !fromFormId,
          pluginId: fromPluginId,
          compositeIdSet: fromGridId ? [fromGridId] : undefined,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldVariableMappingFromKey]: {
          type: "pickFieldId",
          metaId: fieldVariableMappingFromKey,
          name: fieldVariableMappingFromKey,
          label: "From key",
          formId: fromFormId,
          disabled: !fromFormId,
          compositeIdSet: fromGridId ? [fromGridId] : undefined,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickFieldId
      },

    ...toPluginId
      ? {
        [fieldVariableMappingToKey]: {
          type: "pickPluginFieldId",
          metaId: fieldVariableMappingToKey,
          name: fieldVariableMappingToKey,
          label: "To key",
          formId: toFormId,
          pluginId: toPluginId,
          compositeIdSet: toGridId ? [toGridId] : undefined,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldVariableMappingToKey]: {
          type: "pickFieldId",
          metaId: fieldVariableMappingToKey,
          name: fieldVariableMappingToKey,
          label: "To key",
          formId: toFormId,
          compositeIdSet: toGridId ? [toGridId] : undefined,
          includeOptionMap: includeOptionMap
        } as DefnStudioPickFieldId
      },

    ...getFieldGap(fieldGap4),

    ...sectionToGrid && {
      [fieldFieldMappingVariantInsert]: {
        type: "enumInsertVariant",
        metaId: fieldFieldMappingVariantInsert,
        name: fieldFieldMappingVariantInsert,
        label: "Insert variant"
      } as DefnFieldPickEnum,

      [fieldGridMappingVariantRemove]: {
        type: "enumRemoveVariant",
        metaId: fieldGridMappingVariantRemove,
        name: fieldGridMappingVariantRemove,
        label: "Remove variant"
      } as DefnFieldPickEnum
    },

    [fieldFieldMappingVariantUpdate]: {
      type: "enumUpdateVariant",
      metaId: fieldFieldMappingVariantUpdate,
      name: fieldFieldMappingVariantUpdate,
      label: "Update variant"
    } as DefnFieldPickEnum,

    [fieldFieldMappingVariantEmpty]: {
      type: "enumEmptyFieldVariant",
      metaId: fieldFieldMappingVariantEmpty,
      name: fieldFieldMappingVariantEmpty,
      label: "Empty field variant"
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap5)
  };
}


import {isEmpty} from "lodash";
import {StudioEntReportMap} from "../../../api/meta/base/dto/StudioEntReportMap";
import {StudioModuleSelection} from "../../../api/meta/base/dto/StudioModuleSelection";
import {MetaIdReport} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {IResolvedIOFormNames} from "../../../base/plus/StudioPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntReports extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entReportList;
  }

  loadReportList(
    entId: EntId,
    listName: string,
    getReportIOFormNames: (reportId: MetaIdReport) => IResolvedIOFormNames,
    reportMap?: StudioEntReportMap,
    readOnly?: boolean
  )
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    reportMap && reportMap.keys?.forEach((metaIdReport) =>
    {
      if(!reportMap)
      {
        return;
      }
      const report = reportMap.map[metaIdReport];
      const reportFilter: StudioModuleSelection | undefined = report.details.modules;

      if(Srvc.studio.ent.filterListByTag(entId, reportFilter))
      {
        uiItemIds.push(metaIdReport);
      }

      let secondaryText = "";

      const {
        inputFormName,
        outputFormName
      } = getReportIOFormNames(metaIdReport);

      if(inputFormName)
      {
        if(outputFormName)
        {
          secondaryText = `${inputFormName} -> ${outputFormName}`;
        }
        else
        {
          secondaryText = inputFormName;
        }
      }
      else if(outputFormName)
      {
        secondaryText = outputFormName;
      }

      uiItemsById[metaIdReport] = {
        type: "ps",
        hideMenu: readOnly,
        primary: {
          text: report.details.label
            ? report.details.label
            : report.details.name,
          caption: {
            text: toLabel(report.kind),
            ignoreSelection: true
          }
        },
        secondary: {
          text: !isEmpty(secondaryText) ? secondaryText : "No form",
          color: "textDisabled"
        },
        ignoreSelectionCaption: true,
        userField: {
          reportType: report.kind
        }
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }
}

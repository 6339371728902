import {DtoChatMessageOffset} from "../../../../api/home/base/dto/DtoChatMessageOffset";
import {DtoMessagePayloadCamera} from "../../../../api/home/base/dto/DtoMessagePayloadCamera";
import {DtoMessagePayloadDocument} from "../../../../api/home/base/dto/DtoMessagePayloadDocument";
import {DtoMessagePayloadGroup} from "../../../../api/home/base/dto/DtoMessagePayloadGroup";
import {DtoMessagePayloadImage} from "../../../../api/home/base/dto/DtoMessagePayloadImage";
import {DtoMessagePayloadLocation} from "../../../../api/home/base/dto/DtoMessagePayloadLocation";
import {DtoMessagePayloadUser} from "../../../../api/home/base/dto/DtoMessagePayloadUser";
import {DtoMessagePayloadVideo} from "../../../../api/home/base/dto/DtoMessagePayloadVideo";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {MsgMessageSend} from "../../../../api/home/main/msg/MsgMessageSend";
import {RpcMain} from "../../../../api/home/main/RpcMain";
import {SigGroupMessageCandidateList} from "../../../../api/home/main/sig/SigGroupMessageCandidateList";
import {SigUserMessageCandidateList} from "../../../../api/home/main/sig/SigUserMessageCandidateList";
import {WsocMain} from "../../../../api/home/main/WsocMain";
import {nextMessageId} from "../../../../api/meta/base/ApiPlus";
import {ENT_ID_GLOBAL} from "../../../../api/meta/base/ApiPlus";
import {nextMediaIdDocument} from "../../../../api/meta/base/ApiPlus";
import {nextMediaIdImage} from "../../../../api/meta/base/ApiPlus";
import {nextMediaIdVideo} from "../../../../api/meta/base/ApiPlus";
import {MediaId} from "../../../../api/meta/base/Types";
import {ChatId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {STR_MAX_FILE_UPLOAD_SIZE_LIMIT} from "../../../../base/plus/ConstantsPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../../base/plus/MediaPlus";
import {uploadMedia} from "../../../../base/plus/MediaPlus";
import {getPrimaryColorOfImage} from "../../../../base/plus/MediaPlus";
import {getBlurredImage} from "../../../../base/plus/MediaPlus";
import {textUser} from "../../../../base/plus/SrvcPlus";
import {getLabel} from "../../../../base/plus/StringPlus";
import {getFileType} from "../../../../base/plus/SysPlus";
import {getFileSizeInMB} from "../../../../base/plus/SysPlus";
import {getFileExt} from "../../../../base/plus/SysPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {listSetPickType} from "../../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../../base/slices/list/SliceListSharedActions";
import {IListData} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../../base/types/list/TypesListAPSA";
import {IListItemGroup} from "../../../../base/types/list/TypesListGroup";
import {IListGroupsById} from "../../../../base/types/list/TypesListGroup";
import {toComboId} from "../../../../base/types/TypesComboId";
import {FILE_UPLOAD_LIMIT_MAX} from "../../../../base/types/TypesGlobal";
import {ISize} from "../../../../base/types/TypesGlobal";
import {FILE_MB_SIZE_LIMIT_MAX} from "../../../../base/types/TypesGlobal";
import {TypeUserFieldSendUserCandidate} from "../../../../base/types/TypesHome";
import {TypeUserFieldSendGroupCandidate} from "../../../../base/types/TypesHome";
import {IMediaFileCaption} from "../../../../base/types/TypesHome";
import {logError} from "../../../../base/util/AppLog";
import {Srvc} from "../../../Srvc";

export default class SrvcHomeMainFooterSpeedDial extends ISrvc
{

  async uploadCameraImage(
    entId: EntId,
    chatId: ChatId,
    blob: Blob,
    size: ISize,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const mediaId = nextMediaIdImage("jpeg");
    const mediaIdBlurImageId = nextMediaIdImage("jpeg");

    const file = new File([blob],
      mediaId,
      {
        type: "jpeg",
        lastModified: Date.now()
      }
    );
    const blurredImage = await getBlurredImage(blob as File);
    const blurImageFile = new File([blurredImage],
      mediaIdBlurImageId,
      {
        type: "jpeg",
        lastModified: Date.now()
      }
    );
    const primaryColor = await getPrimaryColorOfImage(blob as File);

    await Promise.all([
      this.uploadMedia(entId, mediaId, file),
      this.uploadMedia(entId, mediaIdBlurImageId, blurImageFile)
    ]);

    this.wsocMessageSendCamera(
      entId,
      chatId,
      {
        mediaIdImage: mediaId,
        mediaIdBlurImage: mediaIdBlurImageId,
        text: " ",
        fileSize: file.size as number,
        height: size.height,
        width: size.width,
        primaryColor,
        messageType: "camera"
      } as DtoMessagePayloadCamera,
      chatMessageOffset,
      replyPayload
    );
  }

  async uploadMediaCaption(
    entId: EntId,
    chatId: ChatId,
    captionFiles: IMediaFileCaption[]
  )
  {
    if(!captionFiles)
    {
      return;
    }
    const fileLength = Math.min(captionFiles.length, FILE_UPLOAD_LIMIT_MAX);

    const filteredCaptionFiles = captionFiles.filter((_, index) => index < fileLength);

    for(const filteredCaptionFile of filteredCaptionFiles)
    {
      const file = filteredCaptionFile.file;
      const type = getFileType(file);
      if(type === "image")
      {
        if(filteredCaptionFile)
        {
          this.uploadImage(entId, chatId, filteredCaptionFile).catch();
        }
      }
      else if(type === "video")
      {
        if(filteredCaptionFile)
        {
          this.uploadVideo(entId, chatId, filteredCaptionFile).catch();
        }
      }
    }
  }

  async uploadImage(
    entId: EntId,
    chatId: ChatId,
    compressedImage: IMediaFileCaption,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    if(compressedImage.file)
    {
      const mediaIdImageId = nextMediaIdImage(getFileExt(compressedImage.file));
      const blurredImage = await getBlurredImage(compressedImage.file);
      const mediaIdBlurImageId = nextMediaIdImage(getFileExt(blurredImage as File));
      const blurImageFile = new File([blurredImage],
        mediaIdBlurImageId,
        {
          type: "jpeg",
          lastModified: Date.now()
        }
      );
      const primaryColor = await getPrimaryColorOfImage(compressedImage.file);

      await Promise.all([
        this.uploadMedia(entId, mediaIdImageId, compressedImage.file),
        this.uploadMedia(entId, mediaIdBlurImageId, blurImageFile)
      ]);

      let text = compressedImage?.caption;
      if(compressedImage?.caption === "")
      {
        text = " ";
      }

      this.wsocMessageSendImage(
        entId,
        chatId,
        {
          mediaIdImage: mediaIdImageId,
          mediaIdBlurImage: mediaIdBlurImageId,
          fileSize: compressedImage.file.size as number,
          height: compressedImage?.height,
          width: compressedImage?.width,
          mentionMap: compressedImage.mentionMap,
          text: text,
          primaryColor,
          messageType: "image"
        } as DtoMessagePayloadImage,
        chatMessageOffset,
        replyPayload
      );
      return;
    }
  };

  async uploadVideo(
    entId: EntId,
    chatId: ChatId,
    videoMetaData: IMediaFileCaption,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    // max video size should be 18mb
    if(getFileSizeInMB(videoMetaData?.file) > FILE_MB_SIZE_LIMIT_MAX)
    {
      Srvc.app.toast.showErrorToast(STR_MAX_FILE_UPLOAD_SIZE_LIMIT);
      return;
    }
    else
    {
      const mediaId = nextMediaIdVideo(getFileExt(videoMetaData?.file));
      const mediaThumbnailId = nextMediaIdImage("jpeg");
      const mediaIdBlurImageId = nextMediaIdImage("jpeg");
      const blurredThumbnail = await getBlurredImage(videoMetaData?.videoThumbnail as File);
      const primaryColor = await getPrimaryColorOfImage(videoMetaData?.videoThumbnail as File);

      const blurImageFile = new File([blurredThumbnail],
        mediaIdBlurImageId,
        {
          type: "jpeg",
          lastModified: Date.now()
        }
      );

      await Promise.all([
        this.uploadMedia(entId, mediaId, videoMetaData?.file),
        this.uploadMedia(entId, mediaThumbnailId, videoMetaData?.videoThumbnail as File),
        this.uploadMedia(entId, mediaIdBlurImageId, blurImageFile)
      ]);

      let text = videoMetaData?.caption;
      if(videoMetaData?.caption === "")
      {
        text = " ";
      }

      this.wsocMessageSendVideo(
        entId,
        chatId,
        {
          mediaIdVideo: mediaId,
          mediaIdBlurImage: mediaIdBlurImageId,
          mediaId: mediaThumbnailId,
          fileSize: videoMetaData?.file.size as number,
          height: videoMetaData?.height,
          width: videoMetaData?.width,
          durationMs: videoMetaData?.videoDuration as number * 1000,
          fileName: videoMetaData?.file.name,
          mentionMap: videoMetaData.mentionMap,
          text: text,
          primaryColor,
          messageType: "video"
        } as DtoMessagePayloadVideo,
        chatMessageOffset,
        replyPayload
      );
    }
  };

  uploadDocument(
    entId: EntId,
    chatId: ChatId,
    file: File,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    if(getFileSizeInMB(file) > FILE_MB_SIZE_LIMIT_MAX)
    {
      Srvc.app.toast.showErrorToast(STR_MAX_FILE_UPLOAD_SIZE_LIMIT);
      return;
    }
    const mediaId = nextMediaIdDocument(getFileExt(file));

    uploadMedia(
      entId,
      mediaId,
      file,
      (mediaId) =>
      {
        this.wsocMessageSendDocument(
          entId,
          chatId,
          {
            mediaIdDocument: mediaId,
            fileSize: file.size as number,
            fileExt: getFileExt(file),
            fileName: file.name,
            messageType: "document"
          } as DtoMessagePayloadDocument,
          chatMessageOffset,
          replyPayload
        );
      },
      (error?: string) =>
      {
        logError("uploadDocument", error);
      }
    );
  }

  uploadMedia(entId: EntId, mediaId: MediaId, file: File)
  {
    return new Promise((resolve, reject) =>
    {
      uploadMedia(
        entId,
        mediaId,
        file,
        resolve,
        (error?: string) =>
        {
          logError("uploadMedia", error);
          reject();
        }
      );
    });
  }

  rpcContactsGet()
  {
    const selectList: SelectList = state => state.home.main.userList;

    RpcMain.userMessageCandidateListGet(envSig => listRecursiveCalls<SigUserMessageCandidateList>(
      envSig,
      selectList,
      this.rpcContactsGet,
      this.doLoadContacts.bind(this)
    ));
  }

  rpcGroupsGet()
  {
    const selectList: SelectList = state => state.home.main.entGroupList;

    RpcMain.groupMessageCandidateListGet(ENT_ID_GLOBAL, envSig => listRecursiveCalls<SigGroupMessageCandidateList>(
      envSig,
      selectList,
      this.rpcGroupsGet,
      this.doLoadGroups.bind(this)
    ));
  }

  // region wsoc

  wsocMessageSendCamera(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadCamera,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {

    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: payload,
      replyPayload: replyPayload
    };
    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  wsocMessageSendContact(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadUser,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: {
        ...payload,
        messageType: "user"
      } as DtoMessagePayloadUser,
      replyPayload: replyPayload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  wsocMessageSendGroup(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadGroup,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: {
        ...payload,
        messageType: "group"
      } as DtoMessagePayloadGroup,
      replyPayload: replyPayload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  wsocMessageSendLocation(
    entId: EntId,
    chatId: ChatId,
    file: any,
    payload: DtoMessagePayloadLocation,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {

    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: payload,
      replyPayload: replyPayload
    };

    this.uploadMedia(entId, payload.mediaIdImage, file).catch();

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  private wsocMessageSendImage(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadImage,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: payload,
      replyPayload: replyPayload
    };
    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  private wsocMessageSendVideo(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadVideo,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {
    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: payload,
      replyPayload: replyPayload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  private wsocMessageSendDocument(
    entId: EntId,
    chatId: ChatId,
    payload: DtoMessagePayloadDocument,
    chatMessageOffset?: DtoChatMessageOffset,
    replyPayload?: DtoMessageReplyPayload
  )
  {

    const msg: MsgMessageSend = {
      messageId: nextMessageId(),
      chatId: chatId,
      chatMessageOffset: chatMessageOffset,
      payload: payload,
      replyPayload: replyPayload
    };

    WsocMain.messageSend(entId, msg, envSig =>
    {
      Srvc.app.toast.showErrorToast(envSig);
    });
  }

  // endregion
  private doLoadContacts(listName: string, sig: SigUserMessageCandidateList): void
  {
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;
    const contactsMap = sig.candidateMap;
    const contactKeys = Object.keys(contactsMap);
    if(contactKeys.length)
    {
      contactKeys.forEach((prefix) =>
      {
        const candidateArray = contactsMap[prefix];
        const groupItemIds = [] as TypeListItemId[];
        candidateArray.forEach(candidate =>
        {
          const itemId = toComboId(candidate.entId, candidate.entUserId);
          groupItemIds.push(itemId);
          uiItemsById[itemId] = {
            type: "aps",
            avatarLeft: {
              icon: "user",
              src: getImageThumbnail(candidate.mediaIdAvatar)
            },
            primary: {
              text: textUser(candidate)
            },
            secondary: {
              text: candidate?.handle
            },
            userField: {
              user: candidate
            } as TypeUserFieldSendUserCandidate,
            hideMenu: true

          } as IListItemAPSA;
        });

        uiItemsById[prefix] = {
          type: "listGroup",
          header: {
            text: prefix,
            expand: true,
            mt: gapQuarter,
            mb: gapQuarter
          },
          hideMenu: true
        } as IListItemGroup;
        uiGroupsById[prefix] = {
          itemIds: groupItemIds
        };

      });
    }

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    } as IListData));

    dispatchList(listName, listSetPickType("pickMany"));
  }

  private doLoadGroups(listName: string, sig: SigGroupMessageCandidateList): void
  {
    const uiItemsById = {} as IListItemsById;
    const uiGroupsById = {} as IListGroupsById;
    const groupsMap = sig.candidateMap;
    const groupKeys = Object.keys(groupsMap);

    if(groupKeys.length)
    {
      groupKeys.forEach((prefix) =>
      {
        const groupArray = groupsMap[prefix];
        const groupItemIds = [] as TypeListItemId[];
        groupArray.forEach(group =>
        {
          const itemId = group.groupId as TypeListItemId;
          groupItemIds.push(itemId);
          uiItemsById[itemId] = {
            type: "aps",
            avatarLeft: {
              icon: "group",
              src: getImageThumbnail(group.mediaIdAvatar)
            },
            primary: {
              text: getLabel(group)
            },
            secondary: {
              text: group.about
            },
            userField: {
              group: group
            } as TypeUserFieldSendGroupCandidate,
            hideMenu: true
          } as IListItemAPSA;
        });

        uiItemsById[prefix] = {
          type: "listGroup",
          header: {
            text: prefix,
            expand: true,
            mt: gapQuarter,
            mb: gapQuarter
          },
          hideMenu: true
        } as IListItemGroup;
        uiGroupsById[prefix] = {
          itemIds: groupItemIds

        };
      });
    }

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    } as IListData));

    dispatchList(listName, listSetPickType("pickMany"));
  }
}

// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

export const AllEditableType = [
  "bool",
  "date",
  "decimal",
  "image",
  "number",
  "paragraph",
  "text",
  "currency",
  "textSize",
  "icon",
  "language",
  "pinShape",
  "lineStroke",
  "month",
  "quarter",
  "timeZone",
  "pickText",
  "pickTree",
  "pickUser",
  "pickGridRow",
  "pickRole",
  "chipSetDate",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "chipSetTime",
  "setOfUser",
  "setOfText",
  "setOfRole",
  "audio",
  "chipSet",
  "color",
  "camera",
  "hyperlink",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "location",
  "geoPoint",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "symbol",
  "video",
  "voice",
  "document",
  "identifier",
  "scanCode",
  "rowId",
  "hyperlinkRow",
  "logCounter",
  "logDecimal",
  "logNumber"
];

export const AllExcludeRefFieldTypes = [
  "bool",
  "date",
  "decimal",
  "image",
  "label",
  "number",
  "paragraph",
  "text",
  "currency",
  "textSize",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "pickTree",
  "pickUser",
  "pickRole",
  "pickGridRow",
  "pinShape",
  "lineStroke",
  "month",
  "quarter",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "chipSetTime",
  "setOfRole",
  "setOfUser",
  "setOfText",
  "chipSet",
  "color",
  "hyperlink",
  "audio",
  "camera",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "location",
  "geoPoint",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "video",
  "voice",
  "rowId",
  "hyperlinkRow",
  "symbol",
  "button",
  "divider",
  "document",
  "html",
  "identifier",
  "info",
  "scanCode",
  "grid",
  "section",
  "logNumber",
  "logDecimal",
  "logCounter",
  "showCode",
  "userId",
  "dynamic",
  "error"
];

export const AllFieldTypes = [
  "bool",
  "date",
  "decimal",
  "image",
  "label",
  "number",
  "paragraph",
  "text",
  "currency",
  "textSize",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "pickTree",
  "pickUser",
  "pickGridRow",
  "pinShape",
  "pickRole",
  "setOfRole",
  "lineStroke",
  "month",
  "quarter",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "chipSetTime",
  "setOfUser",
  "setOfText",
  "chipSet",
  "color",
  "hyperlink",
  "audio",
  "camera",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "location",
  "geoPoint",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "video",
  "voice",
  "rowId",
  "symbol",
  "button",
  "divider",
  "document",
  "html",
  "identifier",
  "info",
  "ref",
  "scanCode",
  "grid",
  "section",
  "logNumber",
  "logDecimal",
  "logCounter",
  "showCode",
  "userId",
  "dynamic",
  "hyperlinkRow",
  "paymentStatus"
];

export const ArgBinderContextFieldType = [
  "bool",
  "date",
  "decimal",
  "number",
  "paragraph",
  "text",
  "icon",
  "pickText",
  "pickUser",
  "pickRole",
  "color",
  "hyperlink",
  "counter",
  "dateTime",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "slider",
  "html",
  "identifier",
  "info",
  "month",
  "userId",
  "scanCode",
  "paymentStatus",
  "setOfRole"
];

export const ArgBinderValidConstantScriptType = [
  "bool",
  "date",
  "number",
  "paragraph",
  "text",
  "color",
  "handle",
  "mobileNumber",
  "currency",
  "textSize",
  "icon",
  "language",
  "lineStroke",
  "month",
  "pinShape",
  "quarter",
  "timeZone",
  "identifier",
  "info",
  "symbol"
];

export const ArgBinderValidConstantType = [
  "bool",
  "date",
  "number",
  "text",
  "color",
  "handle",
  "mobileNumber",
  "currency",
  "textSize",
  "language",
  "lineStroke",
  "month",
  "pinShape",
  "quarter",
  "timeZone",
  "symbol",
  "decimal",
  "hyperlink",
  "dateTime",
  "time",
  "duration",
  "email",
  "rating",
  "chipSet",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "chipSetTime",
  "scanCode",
  "paymentStatus",
  "pickRole"
];

export const ArgBinderValidContext = [
  "ent",
  "caller",
  "callerSetting",
  "form",
  "row"
];

export const ArgBinderValidContextRow = [
  "createdBy",
  "createdOn",
  "updatedBy",
  "updatedOn",
  "id",
  "order"
];

export const ArgBinderValueVarIdBaseFieldType = [
  "bool",
  "date",
  "decimal",
  "number",
  "paragraph",
  "text",
  "icon",
  "pickText",
  "pickUser",
  "pickRole",
  "color",
  "hyperlink",
  "counter",
  "dateTime",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "slider",
  "html",
  "identifier",
  "info",
  "month",
  "userId",
  "scanCode",
  "paymentStatus",
  "chipSet",
  "chipSetDeviceType",
  "chipSetDeviceSize",
  "chipSetDay",
  "chipSetTime",
  "chipSetDate",
  "chipSetDateTime",
  "setOfRole"
];

export const AssignedFormula = [
  "bool",
  "date",
  "decimal",
  "number",
  "paragraph",
  "text",
  "color",
  "pinShape",
  "lineStroke",
  "dateTime",
  "dateRange",
  "dateTimeRange",
  "error",
  "userId",
  "pickUser",
  "pickText",
  "pickTree",
  "counter",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "logCounter",
  "logDecimal",
  "logNumber",
  "paymentStatus"
];

export const AutomationLockStepKeyFieldType = [
  "text",
  "pickText",
  "pickUser",
  "email",
  "handle",
  "mobileNumber",
  "rowId",
  "userId",
  "hyperlinkRow"
];

export const AutomationMediaType = [
  "document",
  "camera",
  "image",
  "video"
];

export const BoolType = [
  "bool"
];

export const ButtonType = [
  "button"
];

export const CalendarDateFieldType = [
  "date",
  "dateRange",
  "dateTime",
  "dateTimeRange"
];

export const CalendarTitleFieldType = [
  "decimal",
  "number",
  "paragraph",
  "text",
  "timeZone",
  "pickText",
  "pickTree",
  "setOfText",
  "chipSet",
  "hyperlink",
  "counter",
  "email",
  "handle",
  "location",
  "geoPoint",
  "mobileNumber",
  "rating",
  "time",
  "identifier",
  "scanCode",
  "symbol",
  "date",
  "dateTime",
  "dateRange",
  "dateTimeRange",
  "chipSetDate",
  "chipSetDateTime",
  "setOfUser",
  "pickUser",
  "userId"
];

export const CardFilterAdvanceSet = [
  "text",
  "number",
  "counter",
  "rating",
  "decimal",
  "date",
  "dateTime",
  "dateRange",
  "dateTimeRange",
  "bool",
  "pickText"
];

export const CardFilterCategorySet = [
  "ref"
];

export const CardLineTextField = [
  "bool",
  "date",
  "decimal",
  "number",
  "text",
  "paragraph",
  "chipSet",
  "color",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "hyperlink",
  "location",
  "geoPoint",
  "mobileNumber",
  "rating",
  "slider",
  "time",
  "currency",
  "textSize",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "pickTree",
  "pickUser",
  "pickGridRow",
  "chipSetDay",
  "chipSetDateTime",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "chipSetTime",
  "symbol",
  "button",
  "document",
  "html",
  "identifier",
  "info",
  "image",
  "camera",
  "signature",
  "setOfText",
  "setOfLanguageKeys",
  "pinShape",
  "lineStroke",
  "month",
  "quarter",
  "label",
  "logCounter",
  "logDecimal",
  "logNumber",
  "showCode",
  "userId",
  "dynamic",
  "pickReportRow",
  "rowId",
  "hyperlinkRow",
  "error"
];

export const CenterAlignGridCellType = [
  "date",
  "dateFormat",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "chipSet",
  "chipSetDate",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "chipSetTime",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pinShape",
  "lineStroke",
  "month",
  "quarter",
  "textSize",
  "pickTree",
  "pickGridRow"
];

export const ChatLabelFieldType = [
  "bool",
  "date",
  "decimal",
  "label",
  "number",
  "text",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "hyperlink",
  "mobileNumber",
  "rating",
  "slider",
  "time",
  "currency",
  "language",
  "month",
  "quarter",
  "timeZone",
  "pickText",
  "pickTree",
  "pickRole",
  "pickUser",
  "symbol",
  "userId",
  "identifier"
];

export const ChipSetDateFieldType = [
  "chipSetDate"
];

export const ChipSetDateTimeFieldType = [
  "chipSetDateTime"
];

export const ChipSetDayFieldType = [
  "chipSetDay"
];

export const ChipSetTimeFieldType = [
  "chipSetTime"
];

export const ClickableFieldTypes = [
  "button"
];

export const Color = [
  "enumThemeColor",
  "color"
];

export const ConditionComparableType = [
  "date",
  "decimal",
  "logDecimal",
  "number",
  "logNumber",
  "counter",
  "logCounter",
  "dateTime",
  "rating",
  "paragraph",
  "text",
  "icon",
  "hyperlink",
  "identifier",
  "info",
  "showCode",
  "password",
  "bool",
  "pickText",
  "pickTree",
  "scanCode"
];

export const ConditionDerivedFieldType = [
  "bool",
  "color",
  "counter",
  "date",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "decimal",
  "duration",
  "email",
  "handle",
  "hyperlink",
  "mobileNumber",
  "number",
  "rating",
  "text",
  "time",
  "currency",
  "pickText",
  "pickTree",
  "language",
  "timeZone",
  "enumDeviceType",
  "enumDeviceSize",
  "symbol",
  "logNumber",
  "logDecimal",
  "logCounter",
  "pickUser",
  "userId",
  "rowId",
  "hyperlinkRow",
  "identifier",
  "error",
  "scanCode",
  "pickRole"
];

export const ConditionFieldType = [
  "bool",
  "color",
  "counter",
  "date",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "decimal",
  "duration",
  "email",
  "handle",
  "hyperlink",
  "mobileNumber",
  "number",
  "rating",
  "text",
  "time",
  "currency",
  "pickText",
  "pickTree",
  "language",
  "timeZone",
  "enumDeviceType",
  "enumDeviceSize",
  "symbol",
  "logNumber",
  "logDecimal",
  "logCounter",
  "pickUser",
  "userId",
  "rowId",
  "hyperlinkRow",
  "identifier",
  "error",
  "scanCode",
  "pickRole",
  "setOfRole"
];

export const Currency = [
  "currency"
];

export const DateFieldType = [
  "date",
  "dateTime"
];

export const DateMappingTypeSet = [
  "date",
  "dateTime"
];

export const DateRangeMappingTypeSet = [
  "dateRange",
  "dateTimeRange"
];

export const DecimalMappingTypeSet = [
  "decimal",
  "logDecimal",
  "rating"
];

export const DecimalType = [
  "decimal",
  "logDecimal"
];

export const DefnLayoutSpreadsheet = [
  "card",
  "list"
];

export const DerivedFields = [
  "text",
  "email",
  "hyperlink",
  "handle",
  "mobileNumber",
  "identifier",
  "paragraph",
  "html",
  "info",
  "symbol",
  "showCode",
  "icon",
  "currency",
  "language",
  "timeZone",
  "bool",
  "date",
  "dateTime",
  "number",
  "counter",
  "decimal",
  "rating",
  "slider",
  "pickText",
  "pickTree",
  "scanCode",
  "paymentStatus"
];

export const DerivedFromUserField = [
  "text",
  "identifier"
];

export const DeviceSize = [
  "chipSetDeviceSize"
];

export const DeviceType = [
  "chipSetDeviceType"
];

export const DisplayFieldSet = [
  "bool",
  "number",
  "decimal",
  "text",
  "counter",
  "email",
  "handle",
  "mobileNumber",
  "rating"
];

export const DividerType = [
  "divider"
];

export const DurationType = [
  "duration"
];

export const DynamicTypeRule = [
  "bool",
  "date",
  "dateTime",
  "decimal",
  "number",
  "text",
  "pickText"
];

export const EmailHandleType = [
  "email",
  "handle"
];

export const EmailType = [
  "email"
];

export const EmptyFieldVariant = [
  "ignoreEmptyField",
  "overrideEmptyField"
];

export const EntUserIdMappingTypeSet = [
  "pickUser",
  "userId"
];

export const ErrorFieldType = [
  "error"
];

export const FormatTextType = [
  "text",
  "paragraph"
];

export const GeoPointType = [
  "geoPoint",
  "location"
];

export const GridDisplayType = [
  "bool",
  "currency",
  "counter",
  "color",
  "date",
  "dateTime",
  "decimal",
  "email",
  "textSize",
  "hyperlink",
  "handle",
  "identifier",
  "label",
  "language",
  "lineStroke",
  "location",
  "geoPoint",
  "logCounter",
  "logDecimal",
  "logNumber",
  "mobileNumber",
  "month",
  "number",
  "pinShape",
  "pickText",
  "pickTree",
  "pickUser",
  "quarter",
  "rating",
  "slider",
  "symbol",
  "time",
  "timeZone",
  "text"
];

export const GroupBy = [
  "pickText",
  "pickUser",
  "userId",
  "$CreatedBy",
  "$UpdatedBy"
];

export const HandleType = [
  "handle",
  "email",
  "mobileNumber"
];

export const HtmlType = [
  "html"
];

export const HyperlinkType = [
  "hyperlink"
];

export const Icon = [
  "icon"
];

export const IconPosition = [
  "start",
  "end",
  "spaceBetween"
];

export const InsertVariant = [
  "insertForced",
  "doNotInsert"
];

export const JustifyText = [
  "start",
  "end",
  "center",
  "justify"
];

export const KanbanType = [
  "pickText"
];

export const LabelPlacement = [
  "start",
  "end",
  "center",
  "spaceBetween"
];

export const Language = [
  "language"
];

export const LayoutFormContentItem = [
  "audio",
  "bool",
  "button",
  "camera",
  "chipSet",
  "color",
  "counter",
  "date",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "decimal",
  "section",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "document",
  "duration",
  "email",
  "grid",
  "handle",
  "html",
  "hyperlink",
  "identifier",
  "image",
  "info",
  "label",
  "location",
  "geoPoint",
  "mobileNumber",
  "number",
  "paragraph",
  "rating",
  "rowId",
  "hyperlinkRow",
  "symbol",
  "text",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "setOfText",
  "chipSetTime",
  "signature",
  "slider",
  "time",
  "video",
  "voice",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "pickTree",
  "pickUser",
  "pinShape",
  "lineStroke",
  "month",
  "quarter",
  "textSize",
  "showCode",
  "userId",
  "dynamic"
];

export const Location = [
  "location"
];

export const LoggedFieldType = [
  "number",
  "decimal",
  "counter"
];

export const LogNumberArgType = [
  "number",
  "counter",
  "decimal",
  "logNumber",
  "logCounter",
  "logDecimal"
];

export const LogNumberType = [
  "logNumber",
  "logCounter",
  "logDecimal"
];

export const LongType = [
  "number",
  "logNumber",
  "counter",
  "logCounter"
];

export const MediaFieldType = [
  "image",
  "camera",
  "audio",
  "voice",
  "video",
  "html",
  "hyperlink"
];

export const MobileNumberHandleType = [
  "mobileNumber",
  "handle"
];

export const MobileNumberType = [
  "mobileNumber"
];

export const MonthType = [
  "month"
];

export const MultiSelectFieldType = [
  "pickText",
  "pickUser",
  "pickRole"
];

export const NumberMappingTypeSet = [
  "number",
  "logNumber",
  "counter",
  "logCounter"
];

export const NumberType = [
  "number",
  "decimal"
];

export const OnlyColor = [
  "color"
];

export const OnlyDateFieldType = [
  "date"
];

export const OnlyDateTimeFieldType = [
  "dateTime"
];

export const OnlyPaymentStatus = [
  "paymentStatus"
];

export const OnlySetOfTextType = [
  "setOfText"
];

export const ParagraphType = [
  "paragraph"
];

export const PaymentLinkStepAmountType = [
  "decimal",
  "number"
];

export const PaymentLinkStepCurrencyType = [
  "currency"
];

export const PaymentLinkStepDescriptionType = [
  "text",
  "paragraph",
  "identifier",
  "mobileNumber",
  "email",
  "handle"
];

export const PaymentLinkStepDescValidCallerContext = [
  "userId",
  "entUserId",
  "handle",
  "email",
  "mobileNumber",
  "nickName"
];

export const PaymentLinkStepDescValidCallerSettingContext = [
  "decimal",
  "text",
  "number",
  "pickOne"
];

export const PaymentLinkStepDescValidContext = [
  "ent",
  "caller",
  "callerSetting",
  "row"
];

export const PaymentLinkStepDescValidEntContext = [
  "name",
  "id",
  "about"
];

export const PaymentLinkStepDescValidRowContext = [
  "id",
  "order",
  "createdBy",
  "createdOn",
  "updatedBy",
  "updatedOn",
  "parentId"
];

export const PaymentLinkStepLinkExpiryDurationType = [
  "duration"
];

export const PaymentLinkStepReferenceIdFieldType = [
  "text",
  "paragraph",
  "identifier",
  "mobileNumber",
  "email",
  "handle",
  "rowId"
];

export const PaymentLinkStepSpreadsheetRowIdFieldType = [
  "rowId"
];

export const PickRoleFieldType = [
  "pickRole"
];

export const PickTextFieldType = [
  "pickText"
];

export const PickTextType = [
  "pickText",
  "setOfText"
];

export const PickTreeFieldType = [
  "pickTree"
];

export const PickUserFieldType = [
  "pickUser"
];

export const PinType = [
  "pinShape"
];

export const PluginApiMethodType = [
  "get",
  "post",
  "put",
  "patch",
  "delete",
  "webhook"
];

export const Position = [
  "start",
  "end",
  "center"
];

export const QuarterType = [
  "quarter"
];

export const Queryable = [
  "bool",
  "date",
  "decimal",
  "number",
  "text",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "pickUser",
  "pickGridRow",
  "pickTree",
  "pickRole",
  "color",
  "hyperlink",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "slider",
  "time",
  "identifier",
  "rowId",
  "hyperlinkRow",
  "scanCode",
  "symbol",
  "userId",
  "logCounter",
  "logDecimal",
  "logNumber"
];

export const RefUserFieldType = [
  "refUser"
];

export const RemoveVariant = [
  "removeForced",
  "doNotRemove"
];

export const ReportFieldType = [
  "pickReportRow",
  "refReport"
];

export const RightAlignGridCellType = [
  "number",
  "logNumber",
  "decimal",
  "logDecimal",
  "counter",
  "logCounter"
];

export const RowIdType = [
  "rowId"
];

export const Searchable = [
  "decimal",
  "number",
  "paragraph",
  "text",
  "currency",
  "language",
  "timeZone",
  "pickText",
  "pickTree",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "setOfLanguageKeys",
  "setOfText",
  "chipSet",
  "color",
  "hyperlink",
  "counter",
  "email",
  "handle",
  "location",
  "mobileNumber",
  "rating",
  "time",
  "document",
  "identifier",
  "scanCode",
  "symbol",
  "date",
  "dateTime",
  "dateRange",
  "dateTimeRange",
  "chipSetDate",
  "chipSetDateTime",
  "setOfUser",
  "pickUser",
  "userId"
];

export const SenderUserFieldType = [
  "pickUser",
  "setOfUser",
  "userId"
];

export const SetOfRoleFieldType = [
  "setOfRole"
];

export const SetOfUserFieldType = [
  "setOfUser"
];

export const Shape = [
  "pinShape"
];

export const Sortable = [
  "bool",
  "date",
  "decimal",
  "number",
  "text",
  "color",
  "hyperlink",
  "counter",
  "dateTime",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "slider",
  "time",
  "symbol",
  "identifier"
];

export const SortBy = [
  "number",
  "decimal",
  "text",
  "rating",
  "date",
  "time",
  "dateTime",
  "slider",
  "counter",
  "email",
  "handle",
  "mobileNumber",
  "identifier"
];

export const Stroke = [
  "lineStroke"
];

export const StudioCompAdvanceType = [
  "audio",
  "camera",
  "color",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "geoPoint",
  "handle",
  "hyperlink",
  "location",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "video",
  "voice"
];

export const StudioCompBasicType = [
  "bool",
  "date",
  "decimal",
  "image",
  "label",
  "number",
  "paragraph",
  "text"
];

export const StudioCompChipSetType = [
  "chipSet",
  "chipSetDate",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "chipSetTime"
];

export const StudioCompDeprecatedType = [
  "system"
];

export const StudioCompDerivedOptionTypes = [
  "currency",
  "language",
  "timeZone",
  "icon",
  "pickText",
  "pickTree"
];

export const StudioCompEnum = [
  "currency",
  "textSize",
  "icon",
  "language",
  "lineStroke",
  "month",
  "pinShape",
  "quarter",
  "timeZone",
  "paymentStatus"
];

export const StudioCompPickerType = [
  "pickGridRow",
  "pickReportRow",
  "pickRole",
  "pickText",
  "pickTree",
  "pickUser"
];

export const StudioCompProType = [
  "button",
  "divider",
  "document",
  "dynamic",
  "error",
  "html",
  "identifier",
  "info",
  "propertyMap",
  "ref",
  "rowId",
  "hyperlinkRow",
  "refReport",
  "refUser",
  "scanCode",
  "symbol",
  "userId",
  "showCode"
];

export const StudioPluginValidCompType = [
  "bool",
  "date",
  "decimal",
  "image",
  "number",
  "paragraph",
  "text",
  "audio",
  "camera",
  "color",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "hyperlink",
  "location",
  "geoPoint",
  "mobileNumber",
  "rating",
  "signature",
  "slider",
  "time",
  "video",
  "voice",
  "pickGridRow",
  "pickText",
  "pickTree",
  "pickUser",
  "chipSet",
  "chipSetDateTime",
  "chipSetDay",
  "chipSetDeviceSize",
  "chipSetDeviceType",
  "chipSetTime",
  "currency",
  "textSize",
  "icon",
  "language",
  "lineStroke",
  "month",
  "pinShape",
  "quarter",
  "timeZone",
  "document",
  "error",
  "html",
  "rowId",
  "scanCode",
  "showCode",
  "symbol",
  "userId",
  "pickRole",
  "setOfRole"
];

export const SwitchPosition = [
  "top",
  "bottom",
  "start",
  "end"
];

export const SymbolType = [
  "symbol"
];

export const SystemRoleSet = [
  "$Public",
  "$Manager",
  "$GrandManager",
  "$AllManagers",
  "$Assistants",
  "$AllAssistants",
  "$Self"
];

export const TextMappingTypeSet = [
  "text",
  "hyperlink",
  "icon",
  "identifier",
  "info",
  "symbol",
  "scanCode"
];

export const TextSize = [
  "textSize"
];

export const TextType = [
  "text"
];

export const ThemeColorWithoutShades = [
  "black",
  "error",
  "info",
  "primary",
  "secondary",
  "success",
  "transparent",
  "warning",
  "white"
];

export const ThemeColorWithShades = [
  "amber",
  "blue",
  "cyan",
  "deepOrange",
  "deepPurple",
  "green",
  "grey",
  "indigo",
  "lightBlue",
  "lightGreen",
  "lime",
  "orange",
  "pink",
  "purple",
  "red",
  "teal",
  "yellow"
];

export const TimeType = [
  "time"
];

export const TimeZoneType = [
  "timeZone"
];

export const ToolTip = [
  "date",
  "decimal",
  "label",
  "number",
  "text",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "hyperlink",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "time",
  "identifier",
  "symbol",
  "userId",
  "pickUser"
];

export const Unique = [
  "bool",
  "date",
  "decimal",
  "number",
  "text",
  "currency",
  "icon",
  "language",
  "timeZone",
  "pickText",
  "pickUser",
  "pickGridRow",
  "pickTree",
  "pickRole",
  "color",
  "hyperlink",
  "counter",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "duration",
  "email",
  "handle",
  "mobileNumber",
  "rating",
  "slider",
  "time",
  "identifier",
  "rowId",
  "hyperlinkRow",
  "scanCode",
  "symbol",
  "userId",
  "logCounter",
  "logDecimal",
  "logNumber"
];

export const UpdateVariant = [
  "updateForced",
  "doNotUpdate"
];

export const UserField = [
  "pickUser",
  "userId"
];

export const UserFieldType = [
  "userId",
  "pickUser",
  "setOfUser"
];

export const VisibilityType = [
  "bool",
  "color",
  "counter",
  "date",
  "dateRange",
  "dateTime",
  "dateTimeRange",
  "decimal",
  "duration",
  "email",
  "handle",
  "hyperlink",
  "identifier",
  "mobileNumber",
  "number",
  "rating",
  "text",
  "time",
  "currency",
  "pickText",
  "language",
  "timeZone",
  "enumDeviceType",
  "enumDeviceSize",
  "symbol",
  "logNumber",
  "logDecimal",
  "logCounter",
  "pickUser",
  "userId",
  "pickTree",
  "error",
  "pickRole"
];

export const WatermarkPosition = [
  "center",
  "diagonal"
];

export const XAxisFieldType = [
  "text",
  "number",
  "decimal",
  "counter",
  "logNumber",
  "logDecimal",
  "logCounter",
  "bool",
  "date",
  "dateTime",
  "rating",
  "slider",
  "time",
  "pickText",
  "currency",
  "icon",
  "language",
  "month",
  "quarter",
  "pickUser",
  "email",
  "mobileNumber",
  "userId"
];

export const XyChartLayoutFieldType = [
  "bool",
  "date",
  "decimal",
  "number",
  "text",
  "currency",
  "language",
  "timeZone",
  "pickText",
  "pickUser",
  "month",
  "quarter",
  "dateTime",
  "email",
  "mobileNumber",
  "time",
  "userId"
];

export const YAxisFieldType = [
  "bool",
  "number",
  "decimal",
  "counter",
  "logNumber",
  "logDecimal",
  "logCounter"
];

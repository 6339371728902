import {Typography} from "@mui/material";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {isEmpty} from "lodash";
import React from "react";
import {useEffect} from "react";
import {useMemo} from "react";
import {useState} from "react";
import {SigUserAvatar} from "../../../../../api/home/drawer/sig/SigUserAvatar";
import {DefnComp} from "../../../../../api/meta/base/dto/DefnComp";
import {DefnField} from "../../../../../api/meta/base/dto/DefnField";
import {DefnFieldError} from "../../../../../api/meta/base/dto/DefnFieldError";
import {DefnFieldParagraph} from "../../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickText} from "../../../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldSetOfText} from "../../../../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../../../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnForm} from "../../../../../api/meta/base/dto/DefnForm";
import {FieldSetOfOptionId} from "../../../../../api/meta/base/dto/FieldSetOfOptionId";
import {FieldValueEntUserId} from "../../../../../api/meta/base/dto/FieldValueEntUserId";
import {FieldValueError} from "../../../../../api/meta/base/dto/FieldValueError";
import {FieldValueOptionId} from "../../../../../api/meta/base/dto/FieldValueOptionId";
import {EntUserId} from "../../../../../api/meta/base/Types";
import {EnumDefnErrorSeverity} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {fnGetBubbleFormFieldText} from "../../../../../base/plus/BubblePlus";
import {fnIsHiddenBubbleFormField} from "../../../../../base/plus/BubblePlus";
import {DEFAULT_PARAGRAPH_LINE_COUNT} from "../../../../../base/plus/ConstantsPlus";
import {getPrefixSuffixText} from "../../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {getDefnDtoColorToCssColor} from "../../../../../base/plus/FormPlus";
import {getLabel} from "../../../../../base/plus/StringPlus";
import {px} from "../../../../../base/plus/StringPlus";
import {TypeTextColor} from "../../../../../base/types/TypesGlobal";
import {RootState} from "../../../../../Store";
import BoxColor from "../../../box/BoxColor";
import LayoutFlexCol from "../../../layout/LayoutFlexCol";
import RawHighlighter from "../../../raw/RawHighlighter";
import RawMarkDown from "../../../raw/RawMarkDown";
import BubbleFieldSetOfUser from "./bubbleFields/BubbleFieldSetOfUser";
import BubbleFieldUser from "./bubbleFields/BubbleFieldUser";

const variant: Variant = "body2";

export function BubbleRawFormField(props: {
  defnForm: DefnForm,
  compKey: MetaIdField,
  valueMap?: Record<MetaIdField, any>;
  updatedKeySet?: MetaIdField[];
  hiddenFieldsType?: string[];
  searchWords?: string[],
  maxWidth?: number,
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const [color, setColor] = useState<TypeTextColor | undefined>(undefined);
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const fieldId = props.compKey;
  const field = props.defnForm.compMap[fieldId] as DefnField;
  const label = getLabel(field);
  const valueMap = props.valueMap;

  const isHidden = useMemo(() =>
  {
    return fnIsHiddenBubbleFormField(field, valueMap, props.hiddenFieldsType);
  }, [field, fieldId, props.hiddenFieldsType, valueMap]);

  const text = useMemo(() =>
  {
    if((field.type === "refUser" || field.type === "userId" || field.type === "pickUser")
      && (valueMap?.[fieldId] as FieldValueEntUserId)?.value)
    {
      // special case for pickUser because we get displayField with pubSub
      return (valueMap?.[fieldId] as FieldValueEntUserId)?.displayField || " ";
    }
    const formFieldValue = fnGetBubbleFormFieldText(field, valueMap);

    if(formFieldValue && !isEmpty(formFieldValue))
    {
      return formFieldValue;
    }

  }, [field, fieldId, valueMap]);

  useEffect(() =>
  {
    props.updatedKeySet?.includes(fieldId)
      ? setColor("success")
      : setColor(undefined);

  }, [fieldId, props.updatedKeySet]);

  if(isHidden || !text)
  {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "row",
        flexShrink: 0,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%"
      }}
    >
      <RawHighlighter
        variant={variant}
        value={label}
        color={color ?? "primary"}
        noWrap={false}
        isUserSelect={true}
      />
      <Typography
        variant={variant}
        sx={{
          mr: px(gapHalf)
        }}
      >
        {":"}
      </Typography>

      <BubbleFieldText
        field={field}
        searchWords={props.searchWords}
        valueMap={props.valueMap}
        selectUserAvatar={props.selectUserAvatar}
        text={text}
      />

    </Box>
  );
}

function getLineCount(DefnFieldParagraph: DefnFieldParagraph, valueMap?: Record<MetaIdField, any>)
{
  if(valueMap)
  {
    const fieldLineCount = (valueMap && DefnFieldParagraph.lineCountFieldId && fnFieldValueToRawValue("number",
      valueMap[DefnFieldParagraph.lineCountFieldId]
    ));

    return (
      DefnFieldParagraph.lineCount
      ?? DefnFieldParagraph.lineCountVar
      ?? fieldLineCount
      ?? DEFAULT_PARAGRAPH_LINE_COUNT);
  }
  else
  {
    return undefined;
  }
}

function BubbleFieldText(props: {
  field: DefnField,
  text: string,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
  selectUserAvatar?: (state: RootState, entUserId: EntUserId) => SigUserAvatar | undefined
})
{
  const field = props.field;
  const text = props.text;
  const valueMap = props.valueMap;

  const fieldId = field.metaId;
  const isParagraph = field.type === "paragraph";

  const DefnFieldParagraph = (field as DefnFieldParagraph);

  const lineCount = Number(getLineCount(DefnFieldParagraph, valueMap));

  switch(field.type)
  {
    case "info":
    case "paragraph":
      return <RawMarkDown
        value={text}
        variant={variant}
        color={"textSecondary"}
        includeColor={!isParagraph}
        includeNewLine={true}
        maxNumberOfLine={isParagraph ? lineCount : DEFAULT_PARAGRAPH_LINE_COUNT}
        breakWords={true}
      />;
    case "pickText":
      return <BubbleFieldPickTextText {...props} text={text} />;
    case "setOfText":
      return <BubbleFieldSetOfTextText {...props} />;
    case "setOfUser":
      return <BubbleFieldSetOfUser
        {...props}
        variant={variant}
        field={field as DefnFieldSetOfUser}
      />;
    case "pickUser":
    case "userId":
    case "refUser":
      return <BubbleFieldUser
        {...props}
        variant={variant}
        fieldValue={valueMap?.[fieldId] as FieldValueEntUserId}
      />;
    case "error":
      return <BubbleFieldErrorText {...props} text={text} />;
    default:
      return (
        <>
          <FieldColorBox
            comp={field}
            value={text}
          />
          <RawHighlighter
            variant={variant}
            value={text}
            color={"textSecondary"}
            searchWords={props.searchWords}
            isUserSelect={true}
          />
        </>
      );
  }
}

function BubbleFieldPickTextText(props: {
  field: DefnFieldPickText,
  text: string,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const field = props.field;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;

  const pickTextColor = useMemo(() =>
  {
    const value = valueMap?.[fieldId] as FieldValueOptionId | undefined;
    const optionId = value?.optionId;
    const dtoColor = optionId ? field.optionMap?.map[optionId]?.color : undefined;

    return getDefnDtoColorToCssColor(dtoColor);

  }, [field, valueMap?.[fieldId]]);

  return <RawHighlighter
    variant={variant}
    value={props.text}
    color={pickTextColor || "textSecondary"}
    searchWords={props.searchWords}
    isUserSelect={true}
  />;
}

function BubbleFieldErrorText(props: {
  field: DefnFieldError,
  text: string,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const theme = useTheme();
  const field = props.field;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;
  const value = valueMap?.[fieldId] as FieldValueError | undefined;
  const severity = value?.severity;

  function getErrorTextColor(severity?: EnumDefnErrorSeverity)
  {
    if(severity === "error")
    {
      return theme.common.colorWithShade("error", "s500");
    }
    else if(severity === "suggestion")
    {
      return theme.common.colorWithShade("lightBlue", "s500");
    }
    else if(severity === "warning")
    {
      return theme.common.colorWithShade("orange", "s500");
    }
    else
    {
      return theme.common.color("textSecondary");
    }
  }

  return <RawHighlighter
    variant={variant}
    value={props.text}
    color={getErrorTextColor(severity)}
    searchWords={props.searchWords}
    isUserSelect={true}
    maxNumberOfLine={5}
    breakWords={true}
  />;
}

function BubbleFieldSetOfTextText(props: {
  field: DefnFieldSetOfText,
  searchWords?: string[],
  valueMap?: Record<MetaIdField, any>;
})
{
  const theme = useTheme();
  const field = props.field;
  const fieldId = field.metaId;
  const valueMap = props.valueMap;

  const valueSet = useMemo(() =>
  {
    const value = valueMap?.[fieldId] as FieldSetOfOptionId | undefined;
    return value?.valueSet;

  }, [fieldId, valueMap]);

  return valueSet?.map((optionId, index) =>
  {
    const option = optionId ? field.optionMap?.map[optionId] : undefined;
    const dtoColor = option?.color;

    const pickTextColor = getDefnDtoColorToCssColor(dtoColor);

    const value = valueMap?.[fieldId] as FieldSetOfOptionId | undefined;

    const text = getPrefixSuffixText(field, option?.value || value?.displaySet?.[index]);

    return <>
      <RawHighlighter
        variant={variant}
        value={text}
        color={pickTextColor || "textSecondary"}
        searchWords={props.searchWords}
        isUserSelect={true}
      />
      {
        (valueSet?.length > index + 1)
        && <Typography
          variant={variant}
          mr={px(theme.common.gapQuarter)}
          children={", "}
        />
      }
    </>;
  });
}

function FieldColorBox(props: {
  value?: string
  comp: DefnComp,
})
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  if(props.comp.type === "color")
  {
    return (
      <LayoutFlexCol
        pt={px(3)}
      >
        <BoxColor
          pr={gapHalf}
          bgcolor={props.value}
          mr={gapHalf}
          size={px(14)}
        />
      </LayoutFlexCol>
    );
  }
  return null;
}

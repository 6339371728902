import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {SigCaller} from "../../../api/core/user/sig/SigCaller";
import {WsocUser} from "../../../api/core/user/WsocUser";
import {isNotChangedSignal} from "../../../api/nucleus/base/Protocol";
import ISrvc from "../../../base/ISrvc";
import {Delta} from "../../../base/plus/JsPlus";
import {loadApiBrFilter} from "../../../cache/apiBr/SliceCacheApiBrFilter";
import {clearEntDelta} from "../../../cache/app/caller/SliceCacheCaller";
import {setCaller} from "../../../cache/app/caller/SliceCacheCaller";
import {clearGroupCallerDelta} from "../../../cache/app/group/SliceCacheGroup";
import {setGroupCaller} from "../../../cache/app/group/SliceCacheGroup";
import {loadProductionFilter} from "../../../cache/controlPanel/filter/SliceCacheControlPanelFilter";
import {loadHomeFilter} from "../../../cache/home/drawer/filter/SliceCacheHomeDrawerFilter";
import {loadStoreFilter} from "../../../cache/store/filter/SliceCacheStoreFilter";
import {loadStudioFilter} from "../../../cache/studio/filter/SliceCacheStudioFilter";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcBoot extends ISrvc
{
  private readonly subId = "SrvcCacheApp";

  wsocCallerGet()
  {
    const rootState = store.getState();
    const version = rootState.cache.app.caller.callerVersion;
    const msg = {} as MsgVersion;
    if(version)
    {
      msg.version = version;
    }

    WsocUser.callerGet(msg, (envSig) =>
    {
      const sig = envSig.sig;
      if(sig)
      {
        // Once the user changes the language, call "callerEntGet" again to get the updated data based on language.
        const rootState = store.getState();
        const currentLanguage = rootState.cache.app.caller.callerLanguage;
        if(currentLanguage !== undefined && sig.languageKey !== currentLanguage)
        {
          Object.keys(rootState.cache.app.callerEnt.callerEntMap).forEach(entId =>
          {
            Srvc.cache.app.callerEnt.wsocCallerEntGet(entId);
          });
        }

        this.boot(sig, false);
      }
      else if(isNotChangedSignal(envSig))
      {
        return;
      }
      else
      {
        Srvc.signOut();
        Srvc.app.toast.showErrorToast("Could not initialize caller");
      }
    });
  }

  boot(sigCaller: SigCaller, firstBoot: boolean, cbSuccess?: () => void)
  {
    return store.dispatch((dispatch, getState) =>
    {
      const callerVersion = sigCaller.version;
      if(getState().cache.app.caller.callerVersion !== callerVersion)
      {
        const entIdNotAdminSet = Object.keys(sigCaller.entUserIdMap);

        Promise
        .all([
          dispatch(loadHomeFilter({
            callerIdHash: sigCaller.userIdHash,
            entIdAdminSet: Object.keys(sigCaller.entAdminIdMap),
            entIdNotAdminSet: entIdNotAdminSet
          })),
          dispatch(loadStudioFilter({
            callerIdHash: sigCaller.userIdHash,
            entIdAdminSet: Object.keys(sigCaller.entAdminIdMap),
            entIdNotAdminSet: entIdNotAdminSet,
            entIdPluginSet: Object.keys(sigCaller.pluginAdminIdMap)
          })),
          dispatch(loadProductionFilter({
            callerIdHash: sigCaller.userIdHash,
            entIdAdminSet: Object.keys(sigCaller.entAdminIdMap),
            entIdNotAdminSet: entIdNotAdminSet,
            entIdPluginSet: Object.keys(sigCaller.pluginAdminIdMap)
          })),
          dispatch(loadStoreFilter({
            callerIdHash: sigCaller.userIdHash
          })),
          dispatch(loadApiBrFilter({
            callerIdHash: sigCaller.userIdHash,
            entIdAdminSet: Object.keys(sigCaller.entAdminIdMap),
            entIdNotAdminSet: entIdNotAdminSet,
            entIdPluginSet: Object.keys(sigCaller.pluginAdminIdMap),
            storeItemIdAdminSet: Object.keys(sigCaller.storeItemAdminIdMap)
          })),
          dispatch(setGroupCaller(sigCaller)),
          dispatch(setCaller(sigCaller))
        ])
        .then(() => this.doSubscribe(firstBoot, cbSuccess));
      }
    });
  }

  // subscribe should be separate thunk, do not inline; the cache needs to be fresh
  private doSubscribe(firstBoot: boolean, cbSuccess?: () => void)
  {
    const rootState = store.getState();
    const caller = rootState.cache.app.caller;
    const groupDelta = rootState.cache.app.group.callerDelta as Delta;
    const entIdUserSetDelta = caller.entIdUserSetDelta as Delta;

    Srvc.app.pubsub.caller.bootPubSub(this.subId,
      firstBoot,
      groupDelta,
      entIdUserSetDelta,
      () =>
      {
        store.dispatch((dispatch) =>
        {
          Promise
          .allSettled([
            dispatch(clearGroupCallerDelta()),
            dispatch(clearEntDelta())
          ]).then(() =>
          {
            cbSuccess && cbSuccess();
          });
        });
      }
    );
  }
}

import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {nextRowId} from "../../../api/meta/base/ApiPlus";
import {DefnComp} from "../../../api/meta/base/dto/DefnComp";
import {DefnDtoLayoutCardItem} from "../../../api/meta/base/dto/DefnDtoLayoutCardItem";
import {DefnDtoLayoutOverlaySpreadsheet} from "../../../api/meta/base/dto/DefnDtoLayoutOverlaySpreadsheet";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldFormList} from "../../../api/meta/base/dto/DefnFieldFormList";
import {DefnFieldFormListItem} from "../../../api/meta/base/dto/DefnFieldFormListItem";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnLayoutCard} from "../../../api/meta/base/dto/DefnLayoutCard";
import {DefnLayoutGrid} from "../../../api/meta/base/dto/DefnLayoutGrid";
import {DefnLayoutGridMap} from "../../../api/meta/base/dto/DefnLayoutGridMap";
import {DefnSection} from "../../../api/meta/base/dto/DefnSection";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {EntId} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {EnumDefnFields} from "../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {ensureInitValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {getFormFieldValueAsText} from "../../../base/plus/FieldValuePlus";
import {createDefaultDefnForm} from "../../../base/plus/FormPlus";
import {flipRecord} from "../../../base/plus/JsPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {SelectList} from "../../../base/plus/ListPlus";
import {getSystemFieldComp} from "../../../base/plus/StudioFormPlus";
import {isSystemField} from "../../../base/plus/StudioFormPlus";
import {getEmptyKeysAndMap} from "../../../base/plus/StudioPlus";
import {listSetPick} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemForm} from "../../../base/types/list/TypeListForm";
import {IListPickItems} from "../../../base/types/list/TypesList";
import {DefnFormUi} from "../../../base/types/TypesForm";
import {ICallerEnt} from "../../../cache/app/callerEnt/TypesCacheCallerEnt";
import {mergeLayouts} from "../../../nucleus/form/viewer/base/FormViewerPlus";
import {loopLayoutItemLine} from "../../../nucleus/form/viewer/base/FormViewerPlus";
import {fieldListItemVal} from "../../../nucleus/form/viewer/raw/FieldRawList";
import {FieldValueFormList} from "../../../nucleus/form/viewer/uiOnly/FieldFormList";
import {FieldValueFormListItem} from "../../../nucleus/form/viewer/uiOnly/FieldFormList";
import {ISsBrItemUserField} from "../../../nucleus/ssBr/TypesSsBr";
import {fnGetEditableFieldIdSet} from "../../../routes/app/refBr/base/UiRefBrPlus";
import {fnGetLayoutItemListOrCard} from "../../../routes/app/refBr/base/UiRefBrPlus";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

const fieldSection = "fieldSection";
const fieldListItem = "fieldListItem";
const fieldFormList = "fieldFormList";

export default abstract class SrvcRefBr extends ISrvc
{

  private primaryKeyDivider = "<>";
  private flipCopyFieldMap = {} as Record<MetaIdField, MetaIdField>;
  private keyFieldIdSet = undefined as MetaIdField[] | undefined;
  private currentGridValues = getEmptyKeysAndMap() as FieldValueGrid;
  private currentSectionValue = undefined as FormValue | undefined;

  private sourceToTargetRowIdMap = new Map<RowId, RowId>();
  private targetToSourceRowIdMap = new Map<RowId, RowId>();
  private primaryKeyToTargetRowIdMap = new Map<string, RowId>();
  private targetToPrimaryKeyMap = new Map<RowId, string>();
  private multiPickedRowFieldValueMap = new Map<RowId, FieldValueFormListItem>();

  protected constructor(readonly selectList: SelectList)
  {
    super();
  }

  onMount(
    copyFieldMap?: Record<MetaIdField, MetaIdField>,
    fieldValueGrid?: FieldValueGrid,
    keyFieldIdSet?: MetaIdField[],
    formValue?: FormValue)
  {
    if(copyFieldMap)
    {
      this.flipCopyFieldMap = flipRecord(copyFieldMap);
    }
    this.keyFieldIdSet = keyFieldIdSet;
    this.currentGridValues = fieldValueGrid || getEmptyKeysAndMap() as FieldValueGrid;
    this.currentSectionValue = formValue;
  }

  onUnMount()
  {
    this.flipCopyFieldMap = {};
    this.keyFieldIdSet = undefined;
    this.currentGridValues = getEmptyKeysAndMap() as FieldValueGrid;
    this.currentSectionValue = undefined;

    this.sourceToTargetRowIdMap.clear();
    this.targetToSourceRowIdMap.clear();
    this.primaryKeyToTargetRowIdMap.clear();
    this.targetToPrimaryKeyMap.clear();
    this.multiPickedRowFieldValueMap.clear();
  }

  calcFieldSectionValue(
    fieldValue?: FieldValueFormListItem,
    defnForm?: DefnForm,
    editableFieldIdSet?: MetaIdField[])
  {
    let rowValueMap = {} as FieldValues;
    const fieldDtoGridRow = (fieldValue?.userField as ISsBrItemUserField)?.fieldDtoGridRow;

    if(fieldDtoGridRow)
    {
      rowValueMap = this.calcNonEditableFieldGridValue(fieldDtoGridRow);

      if(editableFieldIdSet?.length && fieldValue)
      {
        const rowValueMapEditable = this.calcEditableFieldGridValue(fieldValue, editableFieldIdSet, defnForm);
        rowValueMap = {
          ...rowValueMap,
          ...rowValueMapEditable
        };
      }
      return rowValueMap;
    }
  }

  calcFieldGridValue(
    fieldValue: FieldValueFormListItem,
    defnForm?: DefnForm,
    sourceDefnForm?: DefnForm,
    editableFieldIdSet?: MetaIdField[])
  {
    const sourceRowId = fieldValue.rowId;
    const fieldDtoGridRow = (fieldValue.userField as ISsBrItemUserField)?.fieldDtoGridRow;
    const rowValueMapEditable = this.calcEditableFieldGridValue(fieldValue, editableFieldIdSet, defnForm);
    const rowId = this.getTargetGridRowId(sourceRowId, fieldDtoGridRow, sourceDefnForm);

    if(editableFieldIdSet?.length)
    {
      if(isEmpty(rowValueMapEditable))
      {
        this.deleteCurrentGridValues(rowId);
        this.setPick(sourceRowId, false);
        return;
      }
      this.setPick(sourceRowId, true);
    }

    const rowValueMapNonEditable = this.calcNonEditableFieldGridValue(fieldDtoGridRow);

    const rowValueMap = {
      ...rowValueMapNonEditable,
      ...rowValueMapEditable
    } as FieldValues;

    const gridRow = this.getCurrentGridValueRow(rowId);

    const newGridRow = {
      ...gridRow,
      rowId: rowId,
      valueMap: rowValueMap
    };

    this.insertCurrentGridValues(rowId, newGridRow);

  }

  calcFieldValues(
    defnForm: DefnForm,
    layout: DefnLayoutGrid,
    sourceDefnForm?: DefnForm,
    overlayLayout?: DefnDtoLayoutOverlaySpreadsheet,
    rowList?: Partial<SigSpreadsheetRow>[], // 'Partial' hack! to support reportRef where value is FieldValueGrid
    cbInsertUserNameForFieldUser?: (fieldId: MetaIdField, valueMap: FieldValues) => void
  ): FieldValueFormList | null
  {
    if(!rowList || !defnForm || !layout)
    {
      return null;
    }

    return rowList.reduce((previousValue, row) =>
    {
      const formValue = row?.formValue;
      if(formValue)
      {
        const rowId = formValue?.rowId;
        previousValue.keys.push(rowId);
        if(formValue && sourceDefnForm)
        {
          this.ensurePrimaryKey(defnForm, formValue, sourceDefnForm);
        }
        if(formValue.valueMap)
        {

        }
        previousValue.map[rowId] = this.fnGetRefBrDisplayFormValue(rowId,
          defnForm,
          layout,
          overlayLayout,
          formValue,
          this.getRowValueMap(rowId),
          row?.version,
          cbInsertUserNameForFieldUser
        );
      }

      return previousValue;
    }, {
      map: {} as Record<RowId, FieldValueFormListItem>,
      keys: [] as RowId[]
    } as FieldValueFormList);

  }

  calcPickedItems(
    pickItemIds?: IListPickItems,
    defnForm?: DefnForm,
    sourceDefnForm?: DefnForm,
    editableFieldIdSet?: MetaIdField[])
  {
    if(pickItemIds)
    {
      const rootState = store.getState();
      const itemsById = this.selectList(rootState).itemsById;

      this.clearCurrentGridValue();
      Object.keys(pickItemIds).forEach(key =>
      {
        const item = itemsById[key] as IListItemForm;
        const itemFieldValue = this.multiPickedRowFieldValueMap.get(key);
        if(itemFieldValue)
        {
          this.calcFieldGridValue(itemFieldValue,
            defnForm,
            sourceDefnForm,
            editableFieldIdSet
          );
        }
        else if(item)
        {
          const fieldValue = item?.userField?.[fieldListItemVal] as FieldValueFormListItem;
          if(fieldValue)
          {
            this.multiPickedRowFieldValueMap.set(key, fieldValue);
            this.calcFieldGridValue(fieldValue,
              defnForm,
              sourceDefnForm,
              editableFieldIdSet
            );
          }
        }
      });
    }

    if(pickItemIds === undefined && this.getCurrentGridValue().keys.length > 0)
    {
      this.ensurePickedRow();
    }
  }

  getCurrentGridValue(): FieldValueGrid
  {
    return this.currentGridValues;
  }

  insertEntUserNameInFormField(
    entId: EntId,
    defnForm: DefnForm,
    avatarMap: Record<EntUserId, SigUserAvatar>,
    callerEnt: ICallerEnt)
  {
    return (fieldId: MetaIdField, valueMap: FieldValues) =>
    {
      const comp = defnForm.compMap[fieldId] as DefnField;
      if(comp)
      {
        Srvc.app.form.formViewer.insertEntUserNameInValueMap(entId, comp, valueMap, avatarMap, callerEnt);
      }
    };
  }

  //region utils
  hasKeyFieldIdSet()
  {
    return Boolean(this.keyFieldIdSet?.length);
  };

  fnGetRefBrListFieldName()
  {
    return fieldFormList;
  }

  fnGetLayoutFilterViewType(layout?: DefnLayoutCard)
  {
    return layout?.filter?.kind ?? "tree";
  }

  fnGetLayoutFilterSearch(layout?: DefnLayoutCard)
  {
    return layout?.filter?.showSearchBar ?? false;
  }

  fnGetSwitchLayoutMap(
    layoutMap?: DefnLayoutGridMap,
    layout?: DefnLayoutGrid): Record<MetaIdLayoutGrid, DefnLayoutGrid> | undefined
  {
    const allowToSwitchLayoutMap = {} as Record<MetaIdLayoutGrid, DefnLayoutGrid>;
    layout?.allowToSwitchLayoutIdSet?.forEach(layoutId =>
    {
      const layout = layoutMap?.map?.[layoutId];
      if(layout?.kind === "card" || layout?.kind === "list")
      {
        allowToSwitchLayoutMap[layoutId] = layout;
      }
    });

    return allowToSwitchLayoutMap;
  }

  fnGetPickType(isGrid: boolean, overlayLayout?: DefnDtoLayoutOverlaySpreadsheet)
  {
    const isSinglePick = Boolean(!isGrid);
    let isMultiPickWithEditable: boolean = false;

    if(this.fnCheckLayoutHasFields(overlayLayout))
    {
      isMultiPickWithEditable = true;
    }

    return {
      isSinglePick: isSinglePick,
      isMultiPick: !isSinglePick,
      isMultiPickWithEditable: isMultiPickWithEditable
    };
  }

  fnGetDisplayForm(
    sourceForm: DefnForm,
    targetForm: DefnForm,
    layout: DefnLayoutGrid,
    overrideLayout?: DefnDtoLayoutOverlaySpreadsheet,
    isGrid?: boolean,
    enableSelection?: boolean): DefnForm
  {
    const fieldFormList = this.fnGetRefBrListFieldName();
    const layoutItem = fnGetLayoutItemListOrCard(layout);
    const overrideLayoutItem = overrideLayout?.item;

    const isCard = Boolean(layout.kind === "card");

    const mergeLayout = layoutItem && overrideLayoutItem
      ? mergeLayouts(layoutItem, overrideLayoutItem)
      : layoutItem as DefnDtoLayoutCardItem;

    const editableFieldIdSet = overrideLayout
      ? fnGetEditableFieldIdSet(overrideLayout, targetForm)
      : undefined;

    const compMap = {...sourceForm.compMap} as Record<MetaIdField, DefnComp>;

    // editable
    editableFieldIdSet?.forEach(fieldId => compMap[fieldId] = targetForm.compMap[fieldId]);

    // media
    const overrideMediaFieldIdSet = overrideLayout?.item?.mediaFieldIdSet;
    if(overrideMediaFieldIdSet?.length)
    {
      overrideMediaFieldIdSet.forEach(fieldId => compMap[fieldId] = targetForm.compMap[fieldId]);
      editableFieldIdSet?.push(...overrideMediaFieldIdSet);
    }

    // defn
    const defnFormUi = createDefaultDefnForm({
      ...compMap,
      [fieldSection]: {
        type: "section",
        metaId: fieldSection,
        name: fieldSection,
        fieldIdSet: [fieldListItem],
        overflowHidden: true,
        flexGrow: true,
        pl: 0,
        pr: 0,
        pt: 0,
        pb: 0
      } as DefnSection,
      [fieldListItem]: {
        type: "formListItem",
        metaId: fieldListItem,
        layout: mergeLayout,
        editableFieldIdSet: editableFieldIdSet,
        isCard: isCard,
        name: fieldListItem
      } as DefnFieldFormListItem,

      [fieldFormList]: {
        type: "formList",
        metaId: fieldFormList,
        name: fieldFormList,
        showAsCardLayout: isCard,
        hideMenu: true,
        isPickMany: Boolean(isGrid && !overrideLayoutItem),
        ignoreSelection: enableSelection
          ? !enableSelection
          : Boolean(isGrid && overrideLayoutItem),
        displaySectionId: fieldSection,
        bgColorVar: {
          value: "white"
        }
      } as DefnFieldFormList
    }) as DefnFormUi;

    return {
      ...defnFormUi,
      displayCompositeId: fieldFormList,
      theme: {
        ...defnFormUi.theme,
        fieldSize: "small",
        colSpacing: 0,
        rowSpacing: 0
      }
    } as DefnFormUi;
  }

  fnInsertGridRowInInputFormValue(
    formValue?: FormValue,
    gridId?: MetaIdGrid,
    gridRow?: FieldDtoGridRow
  )
  {
    return Srvc.app.form.formViewer.fnInsertGridRowInInputFormValue(formValue, gridId, gridRow);
  }

  fnCheckLayoutHasFields(layout?: DefnDtoLayoutOverlaySpreadsheet)
  {
    let isLayoutSpreadSheetFields: boolean = false;

    if(layout)
    {
      const layoutItem = layout.item;

      if(layoutItem)
      {
        loopLayoutItemLine(layoutItem, (payload) =>
        {
          if(isLayoutSpreadSheetFields)
          {
            return;
          }

          if(payload.segment.lineFieldIdSet?.length)
          {
            isLayoutSpreadSheetFields = true;
          }
        });
      }
    }

    return isLayoutSpreadSheetFields;
  }

  //endregion

  protected fnGetRefBrDisplayFormValue(
    rowId: RowId,
    defnForm: DefnForm,
    layout: DefnLayoutGrid,
    overlay?: DefnDtoLayoutOverlaySpreadsheet,
    fieldDtoGridRow?: FieldDtoGridRow,
    oldFieldValue?: FieldValues,
    version?: string,
    cbInsertUserNameForFieldUser?: (fieldId: MetaIdField, valueMap: FieldValues) => void
  )
  {
    const overrideLayoutItem = overlay?.item;

    const valueMap = {
      ...fieldDtoGridRow?.valueMap
    } as FieldValues;

    if(overrideLayoutItem)
    {
      const compMap = defnForm.compMap;

      loopLayoutItemLine(overrideLayoutItem, (payload) =>
      {
        const segment = payload.segment;
        const fieldId = segment?.lineFieldIdSet?.[0];
        if(fieldId)
        {
          const comp = compMap ? compMap[fieldId] : undefined;
          if(comp)
          {
            if(oldFieldValue)
            {
              valueMap[fieldId] = oldFieldValue[fieldId] || null;
            }
            else if(defnForm)
            {
              valueMap[fieldId] = ensureInitValue(defnForm, comp as DefnField, valueMap, "null");
            }
          }
        }
      });
    }

    if((layout.kind === "card" || layout.kind === "list") && (layout as DefnLayoutCard).item)
    {
      const item = (layout as DefnLayoutCard).item;
      loopLayoutItemLine(item, (payload) =>
      {
        payload.segment.lineFieldIdSet?.forEach(fieldId =>
        {
          cbInsertUserNameForFieldUser?.(fieldId, valueMap);
        });
      });
    }

    return {
      valueMap: valueMap,
      version: version,
      uiVersion: layout?.metaId,
      userField: {
        fieldDtoGridRow: fieldDtoGridRow
      },
      rowId: rowId
    } as FieldValueFormListItem;
  }

  protected getRowValueMap(sourceRowId: RowId): FieldValues | undefined
  {
    const targetRowId = this.sourceToTargetRowIdMap.get(sourceRowId);
    if(targetRowId && this.currentSectionValue?.rowId === targetRowId)
    {
      return this.currentSectionValue?.valueMap;
    }
    return targetRowId ? this.currentGridValues.map[targetRowId]?.valueMap : undefined;
  }

  protected ensurePrimaryKey(
    defnForm: DefnForm,
    sourceFormValue: FormValue,
    sourceDefnForm: DefnForm)
  {
    if(sourceFormValue)
    {
      const sourceRowId = sourceFormValue.rowId;
      const targetRowId = this.sourceToTargetRowIdMap.get(sourceRowId);
      if(!targetRowId)
      {
        if(this.currentSectionValue)
        {
          if(this.targetToSourceRowIdMap.has(this.currentSectionValue.rowId))
          {
            return;
          }
          this.compareSourceAndTargetPrimaryKeys(this.currentSectionValue, defnForm, sourceFormValue, sourceDefnForm);
        }
        this.currentGridValues.keys.forEach(rowId =>
        {
          if(this.targetToSourceRowIdMap.has(rowId))
          {
            return;
          }
          const gridRow = this.currentGridValues.map[rowId];
          if(gridRow)
          {
            this.compareSourceAndTargetPrimaryKeys(gridRow, defnForm, sourceFormValue, sourceDefnForm);
          }
        });
      }
    }
  }

  private clearCurrentGridValue()
  {
    this.currentGridValues = getEmptyKeysAndMap() as FieldValueGrid;
  }

  private ensurePickedRow()
  {
    this.currentGridValues.keys.forEach(rowId =>
    {
      const sourceRowId = this.targetToSourceRowIdMap.get(rowId);
      if(sourceRowId)
      {
        this.setPick(sourceRowId, true);
      }
    });
  }

  private getCurrentGridValueRow(rowId: RowId): FieldDtoGridRow | undefined
  {
    return this.currentGridValues.map[rowId];
  }

  private compareSourceAndTargetPrimaryKeys(
    targetFormValue: FormValue | FieldDtoGridRow,
    defnForm: DefnForm,
    sourceFormValue: FormValue,
    sourceDefnForm: DefnForm)
  {
    const targetRowId = targetFormValue.rowId;
    const sourceRowId = sourceFormValue.rowId;
    const targetPrimaryValues = this.keyFieldIdSet?.map(fieldId =>
    {
      const targetFieldId = this.flipCopyFieldMap[fieldId];

      const comp = defnForm.compMap?.[targetFieldId];
      if(comp)
      {
        return getFormFieldValueAsText(comp, targetFormValue?.valueMap?.[targetFieldId]) || "";
      }
      return "";
    });

    const targetPrimaryKey = this.joinPrimaryFieldValues(targetPrimaryValues);
    const primaryKey = this.getPrimaryKeyValue(sourceFormValue, sourceDefnForm?.compMap);

    if(primaryKey && targetPrimaryKey === primaryKey)
    {
      this.setPick(sourceRowId, true);
      if(!this.primaryKeyToTargetRowIdMap.has(primaryKey))
      {
        this.primaryKeyToTargetRowIdMap.set(primaryKey, targetRowId);
        this.targetToPrimaryKeyMap.set(targetRowId, primaryKey);
        if(sourceRowId)
        {
          this.sourceToTargetRowIdMap.set(sourceRowId, targetRowId);
          this.targetToSourceRowIdMap.set(targetRowId, sourceRowId);
        }
        this.setPick(sourceRowId, true);
      }
    }
  }

  private insertCurrentGridValues(rowId: RowId, values: FieldDtoGridRow)
  {
    if(this.currentGridValues.map[rowId])
    {
      this.currentGridValues.map[rowId] = values;
    }
    else
    {
      this.currentGridValues.map[rowId] = values;
      this.currentGridValues.keys.push(rowId);
    }
  }

  private setPick(itemId: RowId, pickValue: boolean)
  {
    const rootState = store.getState();
    const listName = this.selectList(rootState).listName;
    dispatchList(listName, listSetPick({
      itemId: itemId,
      pickValue: pickValue
    }));
  }

  private getTargetGridRowIdWithPrimaryKey(primaryKey: string, rowId: RowId, sourceRowId: RowId)
  {
    if(!this.primaryKeyToTargetRowIdMap.has(primaryKey))
    {
      this.primaryKeyToTargetRowIdMap.set(primaryKey, rowId);
      this.targetToPrimaryKeyMap.set(rowId, primaryKey);
      if(sourceRowId)
      {
        this.sourceToTargetRowIdMap.set(sourceRowId, rowId);
        this.targetToSourceRowIdMap.set(rowId, sourceRowId);
      }
    }
    else
    {
      const targetRowId = this.primaryKeyToTargetRowIdMap.get(primaryKey) as RowId;
      if(this.targetToSourceRowIdMap.get(targetRowId) !== sourceRowId)
      {
        const invalidSourceRowId = this.targetToSourceRowIdMap.get(targetRowId) as RowId;
        this.sourceToTargetRowIdMap.delete(invalidSourceRowId);
        this.setPick(invalidSourceRowId, false);
        this.sourceToTargetRowIdMap.set(sourceRowId, targetRowId);
        this.targetToSourceRowIdMap.set(targetRowId, sourceRowId);
      }
      return targetRowId;
    }
    return rowId;
  }

  private getTargetGridRowId(
    sourceRowId: RowId,
    fieldDtoGridRow?: FieldDtoGridRow,
    sourceDefnForm?: DefnForm)
  {
    let rowId = nextRowId();
    if(this.keyFieldIdSet?.length)
    {
      const primaryKey = this.getPrimaryKeyValue(fieldDtoGridRow, sourceDefnForm?.compMap);
      if(primaryKey)
      {
        rowId = this.getTargetGridRowIdWithPrimaryKey(primaryKey, rowId, sourceRowId);
      }
    }
    else
    {
      if(!this.sourceToTargetRowIdMap.has(sourceRowId))
      {
        this.sourceToTargetRowIdMap.set(sourceRowId, rowId);
        this.targetToSourceRowIdMap.set(rowId, sourceRowId);
      }
      else
      {
        rowId = this.sourceToTargetRowIdMap.get(sourceRowId) as RowId;
      }
    }

    return rowId;
  }

  private getPrimaryKeyValue(fieldDtoGridRow?: FieldDtoGridRow, compMap?: Record<MetaIdField, DefnComp>)
  {
    const values = this.keyFieldIdSet?.map(fieldId =>
    {
      if(fieldDtoGridRow && compMap)
      {
        return this.resolvePrimaryKeyValue(fieldId, compMap, fieldDtoGridRow);
      }
      return "";
    });

    return this.joinPrimaryFieldValues(values);
  }

  private resolvePrimaryKeyValue(
    fieldId: MetaIdField,
    compMap: Record<MetaIdField, DefnComp>,
    fieldDtoGridRow: FieldDtoGridRow)
  {
    if(isSystemField(fieldId))
    {
      const fieldValue = Srvc.app.form.formViewer.fnSystemFieldToFieldValue(fieldId as EnumDefnFields,
        fieldDtoGridRow as FormValueRaw
      );

      const comp = getSystemFieldComp(fieldId as EnumDefnFields);
      return getFormFieldValueAsText(comp, fieldValue) || "";
    }
    else
    {
      const comp = compMap?.[fieldId];
      if(comp)
      {
        return getFormFieldValueAsText(comp, fieldDtoGridRow?.valueMap?.[fieldId]) || "";
      }
      return "";
    }
  }

  private joinPrimaryFieldValues(values?: string[])
  {
    return values?.filter(Boolean).join(this.primaryKeyDivider);
  }

  private deleteCurrentGridValues(rowId: RowId)
  {
    this.currentGridValues.keys = this.currentGridValues.keys.filter(key => key !== rowId);
    delete this.currentGridValues.map[rowId];
  }

  private calcNonEditableFieldGridValue(fieldDtoGridRow?: FieldDtoGridRow)
  {
    const rowValueMap = {} as FieldValues;
    if(fieldDtoGridRow)
    {
      Srvc.app.form.formViewer.fnInsertRefFieldInValueMap(rowValueMap, fieldDtoGridRow, this.flipCopyFieldMap);
      Srvc.app.form.formViewer.fnInsertRefSystemFieldInValueMap(rowValueMap, fieldDtoGridRow, this.flipCopyFieldMap);
    }
    return rowValueMap;
  }

  private calcEditableFieldGridValue(
    fieldValue: FieldValueFormListItem,
    editableFieldIdSet?: MetaIdField[],
    defnForm?: DefnForm)
  {
    return editableFieldIdSet?.reduce((acc, fieldId) =>
    {
      const comp = defnForm?.compMap ? defnForm.compMap[fieldId] : undefined;
      if(comp)
      {
        const value = fnFieldValueToRawValue(comp.type, fieldValue.valueMap[fieldId]);
        if(value)
        {
          acc[fieldId] = fieldValue.valueMap[fieldId];
        }
      }
      return acc;
    }, {} as FieldValues);
  }
}


//region calc schema
import {PhoneNumberUtil} from "google-libphonenumber";
import {isNumber} from "lodash";
import {toNumber} from "lodash";
import {isEmpty} from "lodash";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {InferType} from "yup";
import {Schema} from "yup";
import * as Yup from "yup";
import {DefnBuildDate} from "../../api/meta/base/dto/DefnBuildDate";
import {DefnBuildDateTime} from "../../api/meta/base/dto/DefnBuildDateTime";
import {DefnFieldAudio} from "../../api/meta/base/dto/DefnFieldAudio";
import {DefnFieldButton} from "../../api/meta/base/dto/DefnFieldButton";
import {DefnFieldCamera} from "../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldChipSetDate} from "../../api/meta/base/dto/DefnFieldChipSetDate";
import {DefnFieldCounter} from "../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldDate} from "../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateRange} from "../../api/meta/base/dto/DefnFieldDateRange";
import {DefnFieldDateTime} from "../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldDateTimeRange} from "../../api/meta/base/dto/DefnFieldDateTimeRange";
import {DefnFieldDecimal} from "../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldDivider} from "../../api/meta/base/dto/DefnFieldDivider";
import {DefnFieldDocument} from "../../api/meta/base/dto/DefnFieldDocument";
import {DefnFieldDuration} from "../../api/meta/base/dto/DefnFieldDuration";
import {DefnFieldEditable} from "../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldEmail} from "../../api/meta/base/dto/DefnFieldEmail";
import {DefnFieldHandle} from "../../api/meta/base/dto/DefnFieldHandle";
import {DefnFieldImage} from "../../api/meta/base/dto/DefnFieldImage";
import {DefnFieldInfo} from "../../api/meta/base/dto/DefnFieldInfo";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldLocation} from "../../api/meta/base/dto/DefnFieldLocation";
import {DefnFieldLogCounter} from "../../api/meta/base/dto/DefnFieldLogCounter";
import {DefnFieldMobileNumber} from "../../api/meta/base/dto/DefnFieldMobileNumber";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldOtp} from "../../api/meta/base/dto/DefnFieldOtp";
import {DefnFieldParagraph} from "../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPassword} from "../../api/meta/base/dto/DefnFieldPassword";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldPickGridRow} from "../../api/meta/base/dto/DefnFieldPickGridRow";
import {DefnFieldPickReportRow} from "../../api/meta/base/dto/DefnFieldPickReportRow";
import {DefnFieldPickRole} from "../../api/meta/base/dto/DefnFieldPickRole";
import {DefnFieldPickText} from "../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldPickTree} from "../../api/meta/base/dto/DefnFieldPickTree";
import {DefnFieldPickUser} from "../../api/meta/base/dto/DefnFieldPickUser";
import {DefnFieldRating} from "../../api/meta/base/dto/DefnFieldRating";
import {DefnFieldSetOfRole} from "../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSetOfText} from "../../api/meta/base/dto/DefnFieldSetOfText";
import {DefnFieldSetOfUser} from "../../api/meta/base/dto/DefnFieldSetOfUser";
import {DefnFieldShowCode} from "../../api/meta/base/dto/DefnFieldShowCode";
import {DefnFieldSignature} from "../../api/meta/base/dto/DefnFieldSignature";
import {DefnFieldSlider} from "../../api/meta/base/dto/DefnFieldSlider";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnFieldTime} from "../../api/meta/base/dto/DefnFieldTime";
import {DefnFieldVideo} from "../../api/meta/base/dto/DefnFieldVideo";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../api/meta/base/dto/DefnGrid";
import {DefnStudioBuildColor} from "../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioCodeEditor} from "../../api/meta/base/dto/DefnStudioCodeEditor";
import {DefnStudioCompArray} from "../../api/meta/base/dto/DefnStudioCompArray";
import {DefnStudioVarIdParagraphEditor} from "../../api/meta/base/dto/DefnStudioVarIdParagraphEditor";
import {FieldDtoDuration} from "../../api/meta/base/dto/FieldDtoDuration";
import {FieldDtoLocation} from "../../api/meta/base/dto/FieldDtoLocation";
import {FieldSetOfString} from "../../api/meta/base/dto/FieldSetOfString";
import {FieldValueCamera} from "../../api/meta/base/dto/FieldValueCamera";
import {FieldValueDocument} from "../../api/meta/base/dto/FieldValueDocument";
import {FieldValueDuration} from "../../api/meta/base/dto/FieldValueDuration";
import {FieldValueEmail} from "../../api/meta/base/dto/FieldValueEmail";
import {FieldValueImage} from "../../api/meta/base/dto/FieldValueImage";
import {FieldValueLocation} from "../../api/meta/base/dto/FieldValueLocation";
import {FieldValueText} from "../../api/meta/base/dto/FieldValueText";
import {FieldValueVideo} from "../../api/meta/base/dto/FieldValueVideo";
import {StudioValueCodeJavascript} from "../../api/meta/base/dto/StudioValueCodeJavascript";
import {AnyTime} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {DefnFieldUi} from "../types/TypesForm";
import {defnDtoTextToString} from "./ArgBinderPlus";
import {MAX_CHARACTER_COUNT_SYMBOL} from "./ConstantsPlus";
import {resolveTimeAsString} from "./DatePlus";
import {timeToDate} from "./DatePlus";
import {dateToLocalString} from "./DatePlus";
import {getBuildDateDefaultValue} from "./FieldValuePlus";
import {fnFieldValueToRawValue} from "./FieldValuePlus";
import {getTimeAsString} from "./FormPlus";
import {mbToBytes} from "./MathPlus";
import {getDurationInSeconds} from "./MathPlus";

const internalError = "InternalError";

const aadhaarRegex = "^[2-9][0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$";
const gstRegex = "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$";
const panRegex = "^[A-Z]{5}[0-9]{4}[A-Z]{1}$";

export function calcSchemaField(defnForm: DefnForm, defnComp: DefnFieldUi): Schema | InferType<Schema> | undefined
{
  const type = defnComp.type as EnumDefnCompType;

  switch(type)
  {
    case "bool":
      return calcSchemaBool(defnComp as DefnFieldSwitch);
    case "handle":
      return calcSchemaHandle(defnComp as DefnFieldHandle);
    case "paragraph":
    case "text":
    case "icon":
    case "info":
    case "identifier":
      return calcSchemaText(defnComp as DefnFieldText);
    case "password":
      return calcSchemaPassword(defnComp as DefnFieldPassword);
    case "symbol":
      return calcSchemaSymbol(defnComp as DefnFieldText);
    case "hyperlink":
      return calcSchemaHyperlink(defnComp as DefnFieldEditable);
    case "location":
      return calcSchemaLocation(defnComp as DefnFieldLocation);
    case "decimal":
    case "number":
    case "counter":
    case "logNumber":
    case "logDecimal":
    case "logCounter":
    case "rating":
      return calcSchemaNumber(defnComp as DefnFieldNumber, type);
    case "email":
      return calcSchemaEmail(defnComp as DefnFieldEmail);
    case "mobileNumber":
      return calcSchemaMobileNumber(defnComp as DefnFieldMobileNumber);
    case "date":
      return calcSchemaDate(defnComp as DefnFieldDate);
    case "time":
      return calcSchemaTime(defnComp as DefnFieldTime);
    case "studioCodeEditor":
      return calcSchemaCodeEditor(defnComp as DefnStudioCodeEditor);
    case "chipSet":
      return calcSchemaChipSet(defnComp as DefnFieldPickText);
    case "dateTime":
      return calcSchemaDateTime(defnComp as DefnFieldDateTime);
    case "dateRange":
    case "dateTimeRange":
      return calcSchemaDateRange(defnComp as DefnFieldDateRange | DefnFieldDateTimeRange);
    case "camera":
      return calcSchemaCamera(defnComp as DefnFieldCamera);
    case "color":
      return calcSchemaColor(defnComp as DefnFieldEditable);
    case "signature":
      return calcSchemaSignature(defnComp as DefnFieldSignature);
    case "document":
      return calcSchemaDocument(defnComp as DefnFieldDocument);
    case "duration":
      return calcSchemaDuration(defnComp as DefnFieldDuration);
    case "chipSetDateTime":
    case "chipSetDay":
    case "chipSetDeviceSize":
    case "chipSetDeviceType":
    case "setOfLanguageKeys":
    case "chipSetTime":
      return calcSchemaSetOfText(defnComp);
    case "chipSetDate":
      return calcSchemaSetOfDate(defnComp);
    case "otp":
      return calcSchemaOtp(defnComp as DefnFieldOtp);
    case "setOfUser":
      return calcSchemaSetOfUser(defnComp as DefnFieldSetOfUser);
    case "slider":
      return calcSchemaSlider(defnComp as DefnFieldSlider);
    case "image":
      return calcSchemaImage(defnComp as DefnFieldImage);
    case "grid":
      return calcSchemaGrid(defnComp as DefnGrid);
    case "html":
    case "ref":
    case "rowId":
    case "scanCode":
    case "section":
      break;
    case "audio":
      return calcSchemaAudio(defnComp as DefnFieldAudio);
    case "video":
      return calcSchemaVideo(defnComp as DefnFieldVideo);
    case "studioBuildColor":
      return calcSchemaBuildColor(defnComp as DefnStudioBuildColor);
    case "button":
    case "studioBuildAllModules":
    case "studioBuildArgBinder":
    case "studioSetOfDate":
    case "studioBuildDate":
    case "studioBuildDateTime":
    case "studioBuildPermissionMatrix":
    case "studioBuildTree":
    case "studioBuildUserSetting":
    case "studioBuildActionPermission":
    case "studioMapOfCondition":
    case "studioMapOfFormula":
    case "studioMapOfFuncArg":
    case "studioMapOfJarFile":
    case "studioMapOfLayoutGrid":
    case "studioFieldMappingTree":
    case "studioMapOfText":
    case "studioMapOfVisibilityAction":
    case "studioMapOfVisibilityCondition":
    case "studioMapOfDynamicCondition":
    case "studioVarIdTextEditor":
    case "tab":
    case "voice":
    case "divider":
    case "label":
    case "wizard":
      return undefined;
    case "setOfRole":
      return calcSchemaSetOfRole(defnComp);
    case "pickVarId":
      return calcSchemaPickEnum(defnComp);
    case "studioVarIdParagraphEditor":
      return calcSchemaPickVarIdParagraph(defnComp as DefnStudioVarIdParagraphEditor);
    case "studioCompArray":
      return calcSchemaFieldArray(defnForm, defnComp as DefnStudioCompArray);
    case "studioSetOfFieldId":
    case "studioSetOfActionId":
    case "studioSetOfAdminDoNotOption":
    case "studioSetOfDocFileExt":
    case "studioSetOfFieldRefId":
    case "studioSetOfModule":
    case "studioSetOfFormId":
    case "studioSetOfGridId":
    case "studioSetOfLayoutGridId":
    case "studioSetOfGroupId":
    case "studioSetOfNumber":
    case "studioSetOfPluginSecurityAccess":
    case "studioSetOfRowAuditTrail":
    case "studioSetOfSectionId":
    case "studioSetOfSpreadsheetId":
    case "studioSetOfLayoutFormContentId":
    case "studioSetOfVarId":
      return calcSchemaIdSet(defnComp);

    default:
      return validatePickerValue(type, defnComp);
  }
}

function validatePickerValue(type: EnumDefnCompType, defn: DefnFieldEditable)
{
  switch(type)
  {
    case "enumAdminDoNotOptionPlugin":
    case "enumArgBinder":
    case "enumArgBinderContext":
    case "enumAudioFormat":
    case "enumAutomationSource":
    case "enumCodeEditorLanguage":
    case "enumConditionOperator":
    case "enumConjunction":
    case "enumDataPartitionPeriod":
    case "enumDate":
    case "enumDay":
    case "enumDeployVar":
    case "enumDeviceSize":
    case "enumDeviceType":
    case "enumDocFileExt":
    case "enumSyncMode":
    case "enumDriveStatus":
    case "enumDurationUnit":
    case "enumEntLockBehavior":
    case "enumEntLockReason":
    case "enumFuncArgs":
    case "enumFrequencyKind":
    case "enumKindAction":
    case "enumKindAutomation":
    case "enumKindButton":
    case "enumKindFormComposite":
    case "enumKindHyperlink":
    case "enumKindImport":
    case "enumKindRating":
    case "enumKindReport":
    case "enumKindScheduledEvent":
    case "enumKindSpreadsheetEvent":
    case "enumKindAutomationStep":
    case "enumKindTranslation":
    case "enumLayoutCardFilterKind":
    case "enumMapPinShape":
    case "enumPaymentMethod":
    case "enumPaymentPlan":
    case "enumPermission":
    case "enumPluginApiMethod":
    case "enumPluginResources":
    case "enumPluginSecurityAccess":
    case "enumRowAuditTrail":
    case "enumSetupKind":
    case "enumThemeButtonSize":
    case "enumThemeButtonVariant":
    case "enumThemeColor":
    case "enumTableLayoutStyle":
    case "enumThemeColorShade":
    case "enumThemeDirection":
    case "enumThemeDividerThickness":
    case "enumThemeFieldMargin":
    case "enumThemeFieldSize":
    case "enumThemeFieldVariant":
    case "enumThemeFormVariant":
    case "enumThemeImageCorner":
    case "enumThemePickVariant":
    case "enumPlacement":
    case "enumThemeSectionVariant":
    case "enumThemeStroke":
    case "enumThemeTabVariant":
    case "enumEditorLayoutRenderingMode":
    case "enumUserSettingOptions":
    case "enumUserSettingValue":
    case "enumVideoFormat":
    case "enumVisibilityAction":
    case "enumVisibilityOperator":
    case "enumDynamicOperator":
    case "enumKindDeeplink":
    case "enumDeeplinkConstraints":
    case "enumPluginMode":
    case "pickActionId":
    case "currency":
    case "pickFieldId":
    case "pickCompId":
    case "pickFormId":
    case "pickGridId":
    case "pickLayoutGridId":
    case "pickImportPluginApiId":
    case "pickImportPluginId":
    case "language":
    case "pickPluginBundleId":
    case "pickPluginId":
    case "pickReportId":
    case "pickSectionId":
    case "pickSpreadsheetId":
    case "timeZone":
    case "pickVarId":
    case "textSize":
    case "month":
    case "quarter":
    case "pinShape":
    case "lineStroke":
    case "dateFormat":
    case "enumSetOfUserKind":
    case "enumUserContext":
    case "enumDriveSheetLayoutFor":
    case "pickOption":
    case "paymentStatus":
      return calcSchemaPickEnum(defn);
    case "pickTree":
      return calcSchemaPickTree(defn as DefnFieldPickTree);
    case "pickUser":
      return calcSchemaPickUser(defn);
    case "pickRole":
      return calcSchemaPickRole(defn);
    case "pickText":
      return calcSchemaPickText(defn);
    case "setOfText":
      return calcSchemaSetOfText(defn);
    case "pickGridRow":
      return calcSchemaPickGridRow(defn as DefnFieldPickGridRow);
    case "pickReportRow":
      return calcSchemaPickReportRow(defn as DefnFieldPickReportRow);
    default:
      return undefined;
  }
}

function calcSchemaPickTree(defn: DefnFieldPickTree)
{
  let compSchema = Yup.object({
    optionId: Yup.string(),
    value: Yup.string().optional()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {

      if(fnResolveFieldPropRequired(defn, context.parent))
      {
        return Boolean(value?.optionId);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaColor(defn: DefnFieldEditable)
{
  let compSchema = Yup.object().nullable();

  if(defn.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) => Boolean(value)
    });
  }
  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaPickUser(defn: DefnFieldPickUser)
{
  let compSchema = Yup.object({
    value: Yup.string(),
    displayField: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defn, context.parent))
      {
        return Boolean(value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaPickRole(defn: DefnFieldPickRole)
{
  let compSchema = Yup.object({
    value: Yup.string(),
    displayValue: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defn, context.parent))
      {
        return Boolean(value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaSetOfUser(defn: DefnFieldSetOfUser)
{
  let compSchema = Yup.object({
    valueSet: Yup.array().of(Yup.string()),
    displayField: Yup.array().of(Yup.string())
  }).nullable();

  if(defn.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) => Boolean(value)
    });
  }
  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaGrid(defn: DefnGrid)
{
  let compSchema = Yup.object({
    keys: Yup.array().of(Yup.string())
  }).nullable();

  const minRowsVar = defn.minRowsVar;
  const maxRowsVar = defn.maxRowsVar;

  if(minRowsVar)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: `Rows can't be less than ${minRowsVar}`,
      test: (value) =>
      {
        return Boolean(value?.keys && value.keys.length >= minRowsVar);
      }
    });
  }

  if(maxRowsVar)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: `Rows can't be more than ${maxRowsVar}`,
      test: (value) =>
      {
        return Boolean(value?.keys && value.keys.length <= maxRowsVar);
      }
    });
  }

  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaImage(defn: DefnFieldImage)
{
  let compSchema = Yup.object({
    value: Yup.object()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      const fieldValue = value as FieldValueImage;
      const imageSize = fieldValue && fieldValue.value && fieldValue.value.size;

      const MaxFileSize = fnResolveFieldPropMaxSize(defn, context.parent);
      const MaxFileSizeMb = isNumber(MaxFileSize)
        ? mbToBytes(MaxFileSize)
        : undefined;

      if(fnResolveFieldPropRequired(defn, context.parent))
      {
        return Boolean(value?.value);
      }
      if(!value)
      {
        return true;
      }
      if(value && MaxFileSizeMb)
      {
        const currentValueMaxSize = isNumber(imageSize) ? imageSize : undefined;
        if(isNumber(currentValueMaxSize) && isNumber(MaxFileSizeMb))
        {
          if(currentValueMaxSize > MaxFileSizeMb)
          {
            return context.createError({message: `File size must be less than ${MaxFileSize} MB`});
          }
        }
        else
        {
          return true;
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaVideo(defn: DefnFieldVideo)
{
  let compSchema = Yup.object({
    value: Yup.object()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      const fieldValue = value as unknown as FieldValueVideo;
      const videoSize = fieldValue && fieldValue.size;

      const MaxFileSize = fnResolveFieldPropMaxSize(defn, context.parent);
      const MaxFileSizeMb = isNumber(MaxFileSize)
        ? mbToBytes(MaxFileSize)
        : undefined;

      if(fnResolveFieldPropRequired(defn, context.parent))
      {
        return Boolean(value?.value);
      }
      if(!value)
      {
        return true;
      }
      if(value && MaxFileSizeMb)
      {
        const currentValueMaxSize = isNumber(videoSize) ? videoSize : undefined;
        if(isNumber(currentValueMaxSize) && isNumber(MaxFileSizeMb))
        {
          if(currentValueMaxSize > MaxFileSizeMb)
          {
            return context.createError({message: `File size must be less than ${MaxFileSize} MB`});
          }
        }
        else
        {
          return true;
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaSlider(defn: DefnFieldSlider)
{
  let compSchema = Yup.object({
    minValue: Yup.number(),
    maxValue: Yup.number()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defn, context.parent))
      {
        return Boolean(value);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const min = fnResolveFieldPropMinSlider(defn, context.parent) as number;

      if(value?.minValue && value.minValue < min)
      {
        return context.createError({message: `Value can't be less than ${min}`});
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const min = fnResolveFieldPropMinSlider(defn, context.parent) as number;
      const max = fnResolveFieldPropMaxSlider(defn, context.parent) as number;

      if(value?.maxValue && value.maxValue > max)
      {
        return context.createError({message: `Value can't be greater than ${max}`});
      }
      else if(value?.maxValue && value.maxValue < min)
      {
        return context.createError({message: `Value can't be less than ${min}`});
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaCamera(defnCamera: DefnFieldCamera)
{
  let compSchema = Yup.object({
    captureLocation: getSchemaLocation().optional(),
    captureTime: Yup.string().optional(),
    captureUser: getSchemaFieldValueEntUserId().optional(),
    value: Yup.object()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {

      const fieldValue = value as FieldValueCamera;
      const CameraSize = fieldValue && fieldValue.value && fieldValue.value.size;

      const MaxFileSize = fnResolveFieldPropMaxSize(defnCamera, context.parent);
      const MaxFileSizeMb = isNumber(MaxFileSize)
        ? mbToBytes(MaxFileSize)
        : undefined;
      const currentValueMaxSize = isNumber(CameraSize) ? CameraSize : undefined;

      if(fnResolveFieldPropRequired(defnCamera, context.parent))
      {
        return Boolean(value?.value);
      }
      if(!value)
      {
        return true;
      }
      if(value && MaxFileSizeMb)
      {
        if(isNumber(currentValueMaxSize) && isNumber(MaxFileSizeMb))
        {
          if(currentValueMaxSize < MaxFileSizeMb)
          {
            return context.createError({message: `File size must be less than ${MaxFileSize} MB`});
          }
        }
        else
        {
          return true;
        }
      }

      return true;
    }
  });
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaAudio(defnAudio: DefnFieldAudio)
{
  let compSchema = Yup.object({
    durationMs: Yup.number(),
    fileSize: Yup.number(),
    fileName: Yup.string(),
    mediaIdAudio: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnAudio, context.parent))
      {
        return Boolean(value?.fileName && value?.mediaIdAudio);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const MaxFileSize = fnResolveFieldPropMaxSize(defnAudio, context.parent);
      const MaxFileSizeMb = isNumber(MaxFileSize)
        ? mbToBytes(MaxFileSize)
        : undefined;

      if(!value)
      {
        return true;
      }
      if(value && MaxFileSizeMb)
      {
        const currentValueMaxSize = isNumber(value.fileSize) ? value.fileSize : undefined;
        if(isNumber(currentValueMaxSize) && isNumber(MaxFileSizeMb))
        {
          if(currentValueMaxSize > MaxFileSizeMb)
          {
            return context.createError({message: `File size must be less than ${MaxFileSize} MB`});
          }
        }
        else
        {
          return true;
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaCodeEditor(defn: DefnStudioCodeEditor)
{
  let compSchema = Yup.object().shape({
    value: Yup.string(),
    metaId: Yup.string(),
    compiledValue: Yup.mixed<any>().default(undefined)
  }).transform(value =>
  {
    if(!value
      || isEmpty(value)
      || isEmpty((value as StudioValueCodeJavascript).value)
      || isEmpty((value as StudioValueCodeJavascript).metaId))
    {
      return undefined;
    }

    return value;
  });

  if(defn.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) =>
      {
        return Boolean(value
          && (value as StudioValueCodeJavascript)?.value
          && !isEmpty((value as StudioValueCodeJavascript).value)
          && (value as StudioValueCodeJavascript)?.metaId);
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaPickEnum(defnPickEnum: DefnFieldPickEnum)
{
  let compSchema = Yup.string().nullable();

  if(defnPickEnum.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) =>
      {
        return Boolean(value);
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaPickVarIdParagraph(defn: DefnStudioVarIdParagraphEditor)
{
  let compSchema = Yup.object().nullable();

  if(defn.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) =>
      {
        return Boolean(value);
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaPickText(defnPickText: DefnFieldPickText)
{
  let compSchema = Yup.object({
    optionId: Yup.string(),
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnPickText, context.parent))
      {
        return Boolean(value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaPickReportRow(defnReportRow: DefnFieldPickReportRow)
{
  let compSchema = Yup.object({
    value: Yup.string(),
    displayField: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnReportRow, context.parent))
      {
        return Boolean(value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaPickGridRow(defnGridRow: DefnFieldPickGridRow)
{
  let compSchema = Yup.object({
    value: Yup.string(),
    displayField: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnGridRow, context.parent))
      {
        return Boolean(value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaSetOfText(defnSetOfText: DefnFieldSetOfText)
{
  let compSchema = Yup.object({
    valueSet: Yup.array(),
    displaySet: Yup.array()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnSetOfText, context.parent))
      {
        return Boolean(value?.valueSet && value.valueSet.length > 0);
      }
      else
      {
        return true;
      }
    }
  });
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaSetOfDate(defnSetOfText: DefnFieldChipSetDate)
{
  let compSchema = Yup.object({
    valueSet: Yup.array()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnSetOfText, context.parent))
      {
        return Boolean(value?.valueSet && value.valueSet.length > 0);
      }
      else
      {
        return true;
      }
    }
  });
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaBuildColor(defnBuildColor: DefnStudioBuildColor)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnBuildColor, context.parent))
      {
        return Boolean(value?.value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaHandle(defnHandle: DefnFieldHandle)
{
  return Yup.lazy((value) =>
  {
    if(value)
    {
      let str = value.value?.trim() || "";
      if((str.startsWith("+") || Number.parseInt(str) >= 0) && !str.includes("@"))
      {
        return calcSchemaMobileNumber(defnHandle as DefnFieldMobileNumber);
      }
    }

    return calcSchemaEmail(defnHandle as DefnFieldEmail);
  });
}

function calcSchemaBool(defnBool: DefnFieldSwitch)
{
  let compSchema = Yup.object({
    value: Yup.boolean(),
    captureLocation: getSchemaLocation().optional(),
    captureTime: Yup.string().optional(),
    captureUser: getSchemaFieldValueEntUserId().optional()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnBool, context.parent))
      {
        return Boolean(value?.value !== undefined);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    message: `Invalid value`,
    test: () =>
    {
      // if(defnBool.captureTime && !value.captureTime)
      // {
      //   return ctx.createError({
      //     message: "Time is required"
      //   });
      // }
      //
      // if(defnBool.captureLocation && !isEmpty(value.captureLocation) && !value.captureLocation)
      // {
      //   return ctx.createError({
      //     message: "Location is required"
      //   });
      // }
      //
      // if(defnBool.captureUser && !value.captureUser)
      // {
      //   return ctx.createError({
      //     message: "User value is required"
      //   });
      // }

      return true;
    }
  });
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function getSchemaLocation()
{
  let dtoLocationSchema = Yup.object({
    address: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    dateTime: Yup.string(),
    entUserId: Yup.string(),
    geoPoint: Yup.string()
  }).transform(_value =>
  {
    const value = _value as FieldDtoLocation;

    if(!value
      || isEmpty(value)
      || !value.geoPoint
      || isEmpty(value.geoPoint)
      || !value.address
      || isEmpty(value.address)
      || !value.city
      || isEmpty(value.city)
      || !value.country
      || isEmpty(value.country))
    {
      return undefined;
    }

    return value;
  });

  return Yup.object({
    value: dtoLocationSchema
  }).transform(_value =>
  {
    const value = _value as FieldValueLocation;

    if(!value
      || isEmpty(value)
      || !value.value
      || isEmpty(value.value))
    {
      return undefined;
    }

    return value;
  });
}

function getSchemaFieldValueEntUserId()
{
  return Yup.object().shape({
    value: Yup.string().nullable(),
    displayField: Yup.string().nullable()
  });
}

function calcSchemaMobileNumber(defnMobileNumber: DefnFieldMobileNumber)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnMobileNumber, context.parent))
      {
        return Boolean(value?.value);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "Must start with +",
    test: (value) => !value?.value || value.value.trim().startsWith("+")
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value) =>
    {
      if(value?.value)
      {
        let str = value.value.toString().trim();
        return /^\+[0-9]+$/.test(str);

      }
      return true;
    },
    message: "Should contain only numbers"
  });

  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  compSchema = compSchema.test({
    name: internalError,
    message: "Invalid phone number",
    test: (value) =>
    {
      if(!value?.value)
      {
        return true;
      }

      const trimmedValue = value.value.trim();

      try
      {
        const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(trimmedValue);
        return phoneNumberUtil.isValidNumber(parsedPhoneNumber);
      }
      catch(error)
      {
        return false;
      }
    }
  });

  if(defnMobileNumber.invalidCountryCodeSetVar)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Invalid country code",
      test: (value) =>
      {
        if(!value?.value)
        {
          return true;
        }

        const trimmedValue = value.value.trim();
        try
        {
          const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(trimmedValue);
          const rawInput = parsedPhoneNumber.getRawInput();

          return !(rawInput && defnMobileNumber.invalidCountryCodeSetVar?.some(code => rawInput.startsWith(code)));
        }
        catch(error)
        {
          return false;
        }
      }
    });
  }

  if(defnMobileNumber.validCountryCodeSetVar)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Invalid country code",
      test: (value) =>
      {
        if(!value?.value)
        {
          return true;
        }

        const trimmedValue = value.value.trim();
        try
        {
          const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(trimmedValue);
          if(!phoneNumberUtil.isValidNumber(parsedPhoneNumber))
          {
            return false;
          }

          const countryCode = parsedPhoneNumber.getCountryCode();
          return defnMobileNumber.validCountryCodeSetVar?.includes(`+${countryCode}`);
        }
        catch(error)
        {
          return false;
        }
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaEmail(defnEmail: DefnFieldEmail)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).transform(value =>
  {
    if(!value || isEmpty(value) || isEmpty((value as FieldValueEmail).value))
    {
      return undefined;
    }

    return value;
  });

  //TODO: Add email validation
  const validDomainVarSet = defnEmail?.validDomainSetVar;
  const invalidDomainVarSet = defnEmail?.invalidDomainSetVar;

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnEmail, context.parent))
      {
        return Boolean(value?.value);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "Must be a valid email",
    test: (value) =>
    {
      if(value.value)
      {
        return Yup.string().email().isValidSync(value.value);
      }
      return true;
    }
  });

  if(validDomainVarSet)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Must be a valid domain",
      test: (value) =>
      {
        if(isEmpty(value.value))
        {
          return true;
        }
        const email = value.value?.split("@");
        const domain = email ? email[1] : undefined;
        return !!(domain && validDomainVarSet.includes(domain));
      }
    });
  }

  if(invalidDomainVarSet)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Must be a valid domain",
      test: (value) =>
      {
        if(isEmpty(value.value))
        {
          return true;
        }
        const email = value.value?.split("@");
        const domain = email ? email[1] : undefined;
        return !(domain && invalidDomainVarSet.includes(domain));
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaDate(defnDate: DefnFieldDate)
{
  let compSchema = Yup.object().shape({
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnDate, context.parent))
      {
        return Boolean(value?.value);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const min = getBuildDateDefaultValue(fnResolveFieldPropMinDate(defnDate, context.parent));
      if(!value)
      {
        return true;
      }
      if(value && min)
      {
        const minDate = new Date(min);
        const currentValue = new Date(value.value as string);
        if(currentValue < minDate)
        {
          return context.createError({message: `The date must be after ${dateToLocalString(min)}`});
        }
        else
        {
          return true;
        }
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const max = getBuildDateDefaultValue(fnResolveFieldPropMaxDate(defnDate, context.parent));
      if(!value)
      {
        return true;
      }
      if(value && max)
      {
        const maxDate = new Date(max);
        const currentValue = new Date(value.value as string);
        if(currentValue > maxDate)
        {
          return context.createError({message: `The date must be before ${dateToLocalString(max)}`});
        }
        else
        {
          return true;
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid date");

  return compSchema.nullable();
}

function calcSchemaDateTime(defnDateTime: DefnFieldDateTime)
{
  let compSchema = Yup.object().shape({
    value: Yup.string()
  }).transform(value =>
  {
    if(!value || isEmpty(value) || value === "")
    {
      return undefined;
    }
    return value;
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnDateTime, context.parent))
      {
        return Boolean(value?.value);
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema.nullable();
}

function calcSchemaDateRange(defnDateRange: DefnFieldDateTimeRange | DefnFieldDateRange)
{
  let compSchema = Yup.object().shape({
    from: Yup.string(),
    to: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnDateRange, context.parent))
      {
        return Boolean(value?.to && value?.from);
      }
      return true;
    }
  });

  if(!defnDateRange.allowSingleDate)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Both values must be set",
      test: (value) =>
      {
        return !(value?.from !== undefined && value?.to === undefined);
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");

  return compSchema.nullable();
}

function calcSchemaTime(defnTime: DefnFieldTime)
{
  let compSchema = Yup.string().nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnTime, context.parent))
      {
        return Boolean(value);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      if(!value)
      {
        return true;
      }
      const minTime = fnResolveFieldPropMinTime(defnTime, context.parent) as AnyTime;
      if(value && minTime)
      {
        const minDate = timeToDate(minTime);
        const currentDate = timeToDate(value);
        if(currentDate < minDate)
        {
          return context.createError({message: `The time must be after ${getTimeAsString(minTime)}`});
        }
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const maxTime = fnResolveFieldPropMaxTime(defnTime, context.parent) as AnyTime;
      if(!value)
      {
        return true;
      }

      if(value && maxTime)
      {
        const maxDate = timeToDate(maxTime);
        const currentDate = timeToDate(value);
        if(currentDate > maxDate)
        {
          return context.createError({message: `The time must be before ${getTimeAsString(maxTime)}`});
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaChipSet(defnChipSet: DefnFieldEditable)
{
  let compSchema = Yup.object({
    valueSet: Yup.array()
  }).transform(_value =>
  {
    const value = _value as FieldSetOfString;

    if(!value
      || isEmpty(value)
      || !value.valueSet
      || value.valueSet.length === 0)
    {
      return undefined;
    }

    return value;
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnChipSet, context.parent))
      {
        return Boolean(value.valueSet && value.valueSet?.length > 0);
      }
      else
      {
        return true;
      }
    }
  });
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaText(defnText: DefnFieldText)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnText, context.parent))
      {
        return Boolean(value?.value);
      }

      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const minCharCount = fnResolveFieldPropMinChar(defnText, context.parent);
      const maxCharCount = fnResolveFieldPropMaxChar(defnText, context.parent);

      if(value && value.value)
      {
        const charCount = value.value.length;
        if(minCharCount !== undefined && !Boolean(charCount >= minCharCount))
        {
          return context.createError({message: `Must be at least ${minCharCount} characters`});
        }
        if(maxCharCount !== undefined && charCount > maxCharCount)
        {
          return context.createError({message: `Must be at most ${maxCharCount} characters`});
        }
      }

      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      if(value && value.value)
      {
        const validationPattern = defnText.validationPattern;
        const validationPatternValue = validationPattern?.value;
        const customValue = validationPattern?.customValue;

        if(validationPattern && (validationPatternValue || customValue))
        {
          let regex: RegExp | undefined;
          let errorMsg: string | undefined;

          if(validationPatternValue)
          {
            switch(validationPatternValue)
            {
              case "aadhaar":
                regex = new RegExp(aadhaarRegex);
                errorMsg = "Invalid Aadhaar";
                break;
              case "gstin":
                regex = new RegExp(gstRegex);
                errorMsg = "Invalid GST";
                break;
              case "pan":
                regex = new RegExp(panRegex);
                errorMsg = "Invalid PAN";
                break;
            }
          }
          else if(customValue && !isEmpty(customValue))
          {
            regex = new RegExp(customValue);
            errorMsg = "Invalid value";
          }

          if(regex && !regex.test(value.value))
          {
            return context.createError({
              message: errorMsg
            });
          }
        }
      }

      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaPassword(defnText: DefnFieldPassword)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnText, context.parent))
      {
        return Boolean(value?.value);
      }

      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const minCharCount = fnResolveFieldPropMinChar(defnText, context.parent);
      const maxCharCount = fnResolveFieldPropMaxChar(defnText, context.parent);

      if(value && value.value)
      {
        const charCount = value.value.length;
        if(minCharCount !== undefined && !Boolean(charCount >= minCharCount))
        {
          return context.createError({message: `Must be at least ${minCharCount} characters`});
        }
        if(maxCharCount !== undefined && charCount > maxCharCount)
        {
          return context.createError({message: `Must be at most ${maxCharCount} characters`});
        }

        const upperCase = /[A-Z]/g;
        const lowerCase = /[a-z]/g;
        const numbers = /[0-9]/g;
        if(!value.value.match(upperCase))
        {
          return context.createError({message: `Password must contain at least one uppercase letter.`});
        }
        if(!value.value.match(lowerCase))
        {
          return context.createError({message: `Password must contain at least one lowercase letter.`});
        }
        if(!value.value.match(numbers))
        {
          return context.createError({message: `Password must contain at least one digit.`});
        }
      }

      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaOtp(defnText: DefnFieldOtp)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "",
    test: (value) =>
    {
      if(value?.value && value.value?.length >= (defnText?.otpDigitSize || 4))
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaSymbol(defnSymbol: DefnFieldText)
{
  let compSchema = Yup.string().nullable().transform(value =>
  {
    if(!value || isEmpty(value))
    {
      return undefined;
    }
    return fnFieldValueToRawValue("symbol", value);
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnSymbol, context.parent))
      {
        return Boolean(value);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const minCharCount = fnResolveFieldPropMinChar(defnSymbol, context.parent);
      const maxCharCount = fnResolveFieldPropMaxChar(defnSymbol, context.parent);
      if(value)
      {
        const charCount = value.length;

        if(minCharCount !== undefined && !Boolean(charCount > minCharCount))
        {
          return context.createError({message: `Must be at least ${minCharCount} characters`});
        }

        if(maxCharCount !== undefined && (charCount > maxCharCount || charCount > MAX_CHARACTER_COUNT_SYMBOL))
        {
          return context.createError({
            message: `Must be at most ${Math.min(maxCharCount,
              MAX_CHARACTER_COUNT_SYMBOL
            )} characters`
          });
        }
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "First character cannot be a digit",
    test: (value) =>
    {
      if(value && value.length)
      {
        const firstChar = value.charAt(0);
        return isNaN(parseInt(firstChar));
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "White spaces are not allowed",
    test: (value) =>
    {
      if(value)
      {
        return !value.includes(" ");
      }
      return true;
    }
  });

  const containsOnlyASCII = (str: string) =>
  {
    for(let i = 0; i < str.length; i++)
    {
      const code = str.charCodeAt(i);
      if(code > 127)
      {
        return false;
      }
    }
    return true;
  };

  compSchema = compSchema.test(
    internalError,
    "String must contain only ASCII characters",
    (value) =>
    {
      if(!value)
      {
        return true;
      }

      return containsOnlyASCII(value);
    }
  );

  compSchema = compSchema.test({
    name: internalError,
    message: "Special characters are not allowed",
    test: (value) =>
    {
      if(value)
      {
        const specialCharacters = "!@#$%^&*()_+-=[]{};':\"\\|,.<>/?`~";
        for(let i = 0; i < value.length; i++)
        {
          if(specialCharacters.includes(value.charAt(i)))
          {
            return false;
          }
        }
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    message: `Must be at most ${MAX_CHARACTER_COUNT_SYMBOL} characters`,
    test: (value) =>
    {
      return !(value && value.length > MAX_CHARACTER_COUNT_SYMBOL);
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaHyperlink(defnHyperlink: DefnFieldEditable)
{
  let compSchema = Yup.object({
    value: Yup.string()
  }).transform(value =>
  {
    if(!value || isEmpty(value) || isEmpty((value as FieldValueText).value))
    {
      return undefined;
    }

    return value;
  });

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnHyperlink, context.parent))
      {
        return Boolean(value?.value);
      }
      return true;
    }
  });
  compSchema = compSchema.test({
    name: internalError,
    test: (value) =>
    {
      if(value.value)
      {
        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
        return urlRegex.test(value.value);
      }
      return true;
    },
    message: "Not valid url"
  });
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaNumber(
  defnNumber: DefnFieldNumber | DefnFieldDecimal | DefnFieldCounter | DefnFieldRating,
  type: EnumDefnCompType)
{
  let compSchema = Yup.object({
    value: Yup.number()
  }).nullable();

  compSchema = compSchema.test({
    name: "internalError",
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnNumber, context.parent))
      {
        return type === "counter" ? Boolean(value?.value) : Boolean(value?.value !== undefined);
      }
      return true;
    }
  });

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      if(value?.value === undefined || value?.value === null)
      {
        return true;
      }
      const min = fnResolveFieldPropMin(defnNumber, context.parent);
      const max = fnResolveFieldPropMax(defnNumber, context.parent);
      if(value && value.value)
      {
        const fieldValue = value.value;
        if(min !== undefined && toNumber(fieldValue) < min)
        {
          return context.createError({message: `Must be greater than ${min}`});
        }
        if(max !== undefined && toNumber(fieldValue) > max)
        {
          return context.createError({message: `Must be less than equals to ${max}`});
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaDocument(defnDocument: DefnFieldDocument)
{
  let compSchema = Yup.object({
    value: Yup.object()
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      const fieldValue = value as FieldValueDocument;
      const documentSize = fieldValue && fieldValue.value && fieldValue.value.fileSize;

      const MaxFileSize = fnResolveFieldPropMaxSize(defnDocument, context.parent);
      const MaxFileSizeMb = isNumber(MaxFileSize)
        ? mbToBytes(MaxFileSize)
        : undefined;

      if(fnResolveFieldPropRequired(defnDocument, context.parent))
      {
        return Boolean(value?.value);
      }
      if(!value)
      {
        return true;
      }
      if(value && MaxFileSizeMb)
      {
        const currentValueMaxSize = isNumber(documentSize) ? documentSize : undefined;
        if(isNumber(currentValueMaxSize) && isNumber(MaxFileSizeMb))
        {
          if(currentValueMaxSize > MaxFileSizeMb)
          {
            return context.createError({message: `File size must be less than ${MaxFileSize} MB`});
          }
        }
        else
        {
          return true;
        }
      }
      return true;
    }
  });

  compSchema = compSchema.typeError("Invalid value");
  return compSchema;
}

function calcSchemaDuration(defnDuration: DefnFieldDuration)
{
  let compSchema = Yup.object({
    value: Yup.object().when([], (value: any, schema: Yup.ObjectSchema<any>) =>
    {
      return value && value.value
        ? schema.shape({
          unit: Yup.string(),
          value: Yup.string()
        })
        : schema;
    })
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnDuration, context.parent))
      {
        return Boolean(value && value.value && (value as FieldValueDuration).value.value
          && (value as FieldValueDuration).value.unit);
      }
      return true;
    }
  });
  compSchema = compSchema.typeError("Invalid value");

  compSchema = compSchema.test({
    name: internalError,
    test: (value, context) =>
    {
      const minDuration = fnResolveFieldPropMinDuration(defnDuration, context.parent);
      const maxDuration = fnResolveFieldPropMaxDuration(defnDuration, context.parent);

      const fieldValue = value?.value as FieldDtoDuration;

      if(minDuration && fieldValue && (getDurationInSeconds(fieldValue) < getDurationInSeconds(minDuration)))
      {
        return context.createError({message: `Duration must be greater than ${minDuration.value} ${minDuration.unit}`});
      }
      if(maxDuration && fieldValue && (getDurationInSeconds(fieldValue) > getDurationInSeconds(maxDuration)))
      {
        return context.createError({message: `Duration must be less than ${maxDuration.value} ${maxDuration.unit}`});
      }

      return true;
    }
  });

  return compSchema;
}

function calcSchemaLocation(defnLocation: DefnFieldLocation)
{
  let compSchema = Yup.object({
    value: Yup.object().when([], (value: any, schema: Yup.ObjectSchema<any>) =>
    {
      return value && value.value
        ? schema.shape({
          address: Yup.string(),
          city: Yup.string(),
          country: Yup.string(),
          dateTime: Yup.string(),
          entUserId: Yup.string(),
          geoPoint: Yup.string()
        })
        : schema;
    })
  }).nullable();

  compSchema = compSchema.test({
    name: internalError,
    message: "Required",
    test: (value, context) =>
    {
      if(fnResolveFieldPropRequired(defnLocation, context.parent))
      {
        return Boolean(value && value.value);
      }
      else
      {
        return true;
      }
    }
  });

  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaSignature(defnSignature: DefnFieldSignature)
{
  let compSchema = Yup.object({
    signature: Yup.string()
  }).nullable();

  if(defnSignature.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) =>
      {
        return Boolean(value && value.signature);
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaSetOfRole(defnRole: DefnFieldSetOfRole)
{
  let compSchema = Yup.object({
    valueSet: Yup.array().of(Yup.string()),
    displaySet: Yup.array().of(Yup.string())
  }).nullable();

  if(defnRole.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test: (value) =>
      {
        return Boolean(!isEmpty(value));
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

function calcSchemaFieldArray(defnForm: DefnForm, defn: DefnStudioCompArray)
{
  const schemaMap = {} as Record<MetaIdGrid, Schema | InferType<Schema> | undefined>;
  defn.fieldIdSet && defn.fieldIdSet.forEach((fieldId) =>
  {
    const comp = defnForm.compMap[fieldId];
    if(comp)
    {
      schemaMap[fieldId] = calcSchemaField(defnForm, comp as DefnFieldUi);
    }
  });
  return Yup.array().of(
    Yup.object().shape(schemaMap)).nullable();
}

function calcSchemaIdSet(defn: DefnFieldEditable)
{
  let compSchema = Yup.array().nullable();

  if(defn.required)
  {
    compSchema = compSchema.test({
      name: internalError,
      message: "Required",
      test(value)
      {
        return Boolean(value && value.length > 0);
      }
    });
  }
  compSchema = compSchema.typeError("Invalid value");

  return compSchema;
}

export function fnResolveFieldPropIcon(defn: DefnFieldEditable, valueMap?: FieldValues)
{
  const icon = defn.icon;
  const iconVar = defn.iconVar;

  if(icon)
  {
    return icon;
  }
  else if(iconVar)
  {
    return iconVar;
  }
}

export function fnResolveFieldPropMinDateTime(
  defn: DefnFieldDateTime,
  valueMap?: FieldValues): DefnBuildDateTime | undefined
{
  const minDate = defn.min;
  const minDateVar = defn.minVar;
  const minDateField = defn.minFieldId;

  if(minDate)
  {
    return minDate;
  }
  else if(minDateVar)
  {
    return minDateVar;
  }
  else if(minDateField)
  {
    return valueMap
      ? {
        customValue: fnFieldValueToRawValue("dateTime", valueMap[minDateField])
      } as DefnBuildDateTime
      : undefined;
  }
}

export function fnResolveFieldPropMinDuration(
  defn: DefnFieldDuration,
  valueMap?: FieldValues)
{
  const minValue = defn.min;
  const minValueVar = defn.minVar;
  const minValueFieldId = defn.minFieldId;

  if(minValue)
  {
    return minValue;
  }
  else if(minValueVar)
  {
    return minValueVar;
  }
  else if(minValueFieldId)
  {
    const fieldValue = valueMap && valueMap[minValueFieldId];
    return valueMap
      ? {
        value: (fieldValue as FieldValueDuration).value.value,
        unit: (fieldValue as FieldValueDuration).value.unit
      } as FieldDtoDuration
      : undefined;
  }

}

export function fnResolveFieldPropMaxDuration(
  defn: DefnFieldDuration,
  valueMap?: FieldValues)
{
  const maxValue = defn.max;
  const maxValueVar = defn.maxVar;
  const maxValueFieldId = defn.maxFieldId;

  if(maxValue)
  {
    return maxValue;
  }
  else if(maxValueVar)
  {
    return maxValueVar;
  }
  else if(maxValueFieldId)
  {
    const fieldValue = valueMap && valueMap[maxValueFieldId];
    return valueMap
      ? {
        value: (fieldValue as FieldValueDuration).value.value,
        unit: (fieldValue as FieldValueDuration).value.unit
      } as FieldDtoDuration
      : undefined;
  }

}

export function fnResolveFieldPropMaxDateTime(
  defn: DefnFieldDateTime,
  valueMap?: FieldValues): DefnBuildDateTime | undefined
{
  const maxDate = defn.max;
  const maxDateVar = defn.maxVar;
  const maxDateField = defn.maxFieldId;

  if(maxDate)
  {
    return maxDate;
  }
  else if(maxDateVar)
  {
    return maxDateVar;
  }
  else if(maxDateField)
  {
    return valueMap
      ? {
        customValue: fnFieldValueToRawValue("dateTime", valueMap[maxDateField])
      } as DefnBuildDateTime
      : undefined;
  }
}

export function fnResolveFieldPropMinDate(defn: DefnFieldDate, valueMap?: FieldValues)
{
  const minDate = defn.min;
  const minDateVar = defn.minVar;
  const minDateField = defn.minFieldId;

  if(minDate)
  {
    return minDate;
  }
  else if(minDateVar)
  {
    return minDateVar;
  }
  else if(minDateField)
  {
    return valueMap
      ? {
        customValue: fnFieldValueToRawValue("date", valueMap[minDateField])
      } as DefnBuildDate
      : undefined;
  }
}

export function fnResolveFieldPropMaxDate(defn: DefnFieldDate, valueMap?: FieldValues)
{
  const maxDate = defn.max;
  const maxDateVar = defn.maxVar;
  const maxDateField = defn.maxFieldId;

  if(maxDate)
  {
    return maxDate;
  }
  else if(maxDateVar)
  {
    return maxDateVar;
  }
  else if(maxDateField)
  {
    return valueMap
      ? {
        customValue: fnFieldValueToRawValue("date", valueMap[maxDateField])
      } as DefnBuildDate
      : undefined;
  }
}

export function fnResolveFieldPropBold(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const bold = defn.bold;
  const boldVar = defn.boldVar;
  const boldFieldId = defn.boldFieldId;

  if(bold)
  {
    return bold;
  }
  else if(boldVar)
  {
    return boldVar;
  }
  else if(boldFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[boldFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropMinSlider(defn: DefnFieldSlider, valueMap?: FieldValues)
{
  const min = defn.min;
  const minVar = defn.minVar;
  const minFieldId = defn.minFieldId;

  if(min)
  {
    return min;
  }
  else if(minVar)
  {
    return minVar;
  }
  else if(minFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[minFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropStepCounter(defn: DefnFieldCounter, valueMap?: FieldValues)
{
  const step = defn.step;
  const stepVar = defn.stepVar;
  const stepFieldId = defn.stepFieldId;

  if(step)
  {
    return step;
  }
  else if(stepVar)
  {
    return stepVar;
  }
  else if(stepFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[stepFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropMaxTime(defn: DefnFieldTime, valueMap?: FieldValues)
{
  const max = defn.max;
  const maxVar = defn.maxVar;
  const maxFieldId = defn.maxFieldId;

  if(max)
  {
    return resolveTimeAsString(max);
  }
  else if(maxVar)
  {
    return resolveTimeAsString(maxVar);
  }
  else if(maxFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("time", valueMap[maxFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropMinTime(defn: DefnFieldTime, valueMap?: FieldValues)
{
  const min = defn.min;
  const minVar = defn.minVar;
  const minFieldId = defn.minFieldId;

  if(min)
  {
    return resolveTimeAsString(min);
  }
  else if(minVar)
  {
    return resolveTimeAsString(minVar);
  }
  else if(minFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("time", valueMap[minFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropDefaultValueShowCode(defn: DefnFieldShowCode, valueMap?: FieldValues)
{
  const defaultValue = defn.defaultValue;
  const defaultVar = defn.defaultVar?.value?.join();
  const defaultFieldId = defn.defaultFieldId;

  if(defaultValue)
  {
    return defaultValue;
  }
  else if(defaultVar)
  {
    return defaultVar;
  }
  else if(defaultFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("text", valueMap[defaultFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropDefaultValue(defn: DefnFieldSlider, valueMap?: FieldValues)
{
  const defaultValue = defn.defaultValue;
  const defaultVar = defn.defaultVar;
  const defaultFieldId = defn.defaultFieldId;

  if(defaultValue)
  {
    return defaultValue;
  }
  else if(defaultVar)
  {
    return defaultVar;
  }
  else if(defaultFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[defaultFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropColor(defn: DefnFieldDivider, valueMap?: FieldValues)
{
  const color = defn.color;
  const colorVar = defn.colorVar;

  if(color)
  {
    return color;
  }
  else if(colorVar)
  {
    return colorVar;
  }
}

export function fnResolveFieldPropJustifyText(defn: DefnFieldInfo, valueMap?: FieldValues)
{
  const justifyText = defn.justifyText;
  const justifyTextVar = defn.justifyTextVar;

  if(justifyText)
  {
    return justifyText;
  }
  else if(justifyTextVar)
  {
    return justifyTextVar;
  }
}

export function fnResolveFieldPropDisableElevation(defn: DefnFieldButton, valueMap?: FieldValues)
{
  const disableElevation = defn.disabledElevation;
  const disableElevationVar = defn.disableElevationVar;
  const disableElevationFieldId = defn.disableElevationFieldId;

  if(disableElevation)
  {
    return disableElevation;
  }
  else if(disableElevationVar)
  {
    return disableElevationVar;
  }
  else if(disableElevationFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[disableElevationFieldId]) as boolean | undefined
      : undefined;
  }
}

export function fnResolveFieldPropShowAsDropdown(defn: DefnFieldPickUser, valueMap?: FieldValues)
{
  const showAsDropdown = defn.showAsDropdown;
  const showAsDropdownVar = defn.showAsDropdownVar;
  const showAsDropdownFieldId = defn.showAsDropdownFieldId;

  if(showAsDropdown)
  {
    return showAsDropdown;
  }
  else if(showAsDropdownVar)
  {
    return showAsDropdownVar;
  }
  else if(showAsDropdownFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[showAsDropdownFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropShowSeconds(defn: DefnFieldTime, valueMap?: FieldValues)
{
  const showSecond = defn.showSecond;
  const showSecondVar = defn.showSecondVar;
  const showSecondFieldId = defn.showSecondFieldId;

  if(showSecond)
  {
    return showSecond;
  }
  else if(showSecondVar)
  {
    return showSecondVar;
  }
  else if(showSecondFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[showSecondFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropStepSlider(defn: DefnFieldSlider, valueMap?: FieldValues)
{
  const step = defn.step;
  const stepVar = defn.stepVar;
  const stepFieldId = defn.stepFieldId;

  if(step)
  {
    return step;
  }
  else if(stepVar)
  {
    return stepVar;
  }
  else if(stepFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[stepFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropMaxSlider(defn: DefnFieldSlider, valueMap?: FieldValues)
{
  const max = defn.max;
  const maxVar = defn.maxVar;
  const maxFieldId = defn.maxFieldId;

  if(max)
  {
    return max;
  }
  else if(maxVar)
  {
    return maxVar;
  }
  else if(maxFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[maxFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropOpacity(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const opacity = defn.opacity;
  const opacityVar = defn.opacityVar;
  const opacityFieldId = defn.opacityFieldId;

  if(opacity)
  {
    return opacity;
  }
  else if(opacityVar)
  {
    return opacityVar;
  }
  else if(opacityFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("decimal", valueMap[opacityFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropTextSize(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const font = defn.textSize;
  const fontVar = defn.textSizeVar;
  const fontFieldId = defn.textSizeFieldId;

  if(font)
  {
    return font;
  }
  else if(fontVar)
  {
    return fontVar;
  }
  else if(fontFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("textSize", valueMap[fontFieldId]) as string | undefined
      : undefined;
  }
}

export function fnResolveFieldPropUnderline(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const underline = defn.underline;
  const underlineVar = defn.underlineVar;
  const underlineFieldId = defn.underlineFieldId;

  if(underline)
  {
    return underline;
  }
  else if(underlineVar)
  {
    return underlineVar;
  }
  else if(underlineFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[underlineFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropStrikeThrough(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const strikeThrough = defn.strikeThrough;
  const strikeThroughVar = defn.strikeThroughVar;
  const strikeThroughFieldId = defn.strikeThroughFieldId;

  if(strikeThrough)
  {
    return strikeThrough;
  }
  else if(strikeThroughVar)
  {
    return strikeThroughVar;
  }
  else if(strikeThroughFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[strikeThroughFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropItalic(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const italic = defn.italic;
  const italicVar = defn.italicVar;
  const italicFieldId = defn.italicFieldId;

  if(italic)
  {
    return italic;
  }
  else if(italicVar)
  {
    return italicVar;
  }
  else if(italicFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[italicFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropMaxSize(defn: DefnFieldAudio, valueMap?: FieldValues)
{
  const maxSize = defn.maxSize;
  const maxSizeVar = defn.maxSizeVar;
  const maxSizeFieldId = defn.maxSizeFieldId;

  if(maxSize)
  {
    return maxSize;
  }
  else if(maxSizeVar)
  {
    return maxSizeVar;
  }
  else if(maxSizeFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[maxSizeFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropLineCount(defn: DefnFieldParagraph, valueMap?: FieldValues)
{
  const lineCount = defn.lineCount;
  const lineCountVar = defn.lineCountVar;
  const lineCountFieldId = defn.lineCountFieldId;

  if(lineCount)
  {
    return lineCount;
  }
  else if(lineCountVar)
  {
    return lineCountVar;
  }
  else if(lineCountFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[lineCountFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropTextPattern(defn: DefnFieldLabel, valueMap?: FieldValues)
{
  const textPattern = defn.textPattern;
  const textPatternVar = defnDtoTextToString(defn.textPatternVar);
  const textPatternFieldId = defn.textPatternFieldId;

  if(textPattern)
  {
    return textPattern;
  }
  else if(textPatternVar)
  {
    return textPatternVar;
  }
  else if(textPatternFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[textPatternFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropShowSize(defn: DefnFieldImage, valueMap?: FieldValues)
{
  const showSize = defn.showSize;
  const showSizeVar = defn.showSizeVar;
  const showSizeFieldId = defn.showSizeFieldId;

  if(showSize)
  {
    return showSize;
  }
  else if(showSizeVar)
  {
    return showSizeVar;
  }
  else if(showSizeFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[showSizeFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropShowPreview(defn: DefnFieldImage, valueMap?: FieldValues)
{
  const showPreview = defn.showPreview;
  const showPreviewVar = defn.showPreviewVar;
  const showPreviewFieldId = defn.showPreviewFieldId;

  if(showPreview)
  {
    return showPreview;
  }
  else if(showPreviewVar)
  {
    return showPreviewVar;
  }
  else if(showPreviewFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[showPreviewFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropShowLabel(defn: DefnFieldImage, valueMap?: FieldValues)
{
  const showLabel = defn.showLabel;
  const showLabelVar = defn.showLabelVar;
  const showLabelFieldId = defn.showLabelFieldId;

  if(showLabel)
  {
    return showLabel;
  }
  else if(showLabelVar)
  {
    return showLabelVar;
  }
  else if(showLabelFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("bool", valueMap[showLabelFieldId])
      : undefined;
  }
}

export function fnResolveFieldPropDigitsAfterPeriod(defn: DefnFieldDecimal, valueMap?: FieldValues): number | undefined
{
  const numberOfDigitsAfterPeriod = defn.numberOfDigitsAfterPeriod;
  const numberOfDigitsAfterPeriodVar = defn.numberOfDigitsAfterPeriodVar;
  const numberOfDigitsAfterPeriodFieldId = defn.numberOfDigitsAfterPeriodFieldId;

  if(numberOfDigitsAfterPeriod)
  {
    return numberOfDigitsAfterPeriod;
  }
  else if(numberOfDigitsAfterPeriodVar !== undefined)
  {
    return numberOfDigitsAfterPeriodVar;
  }
  else if(numberOfDigitsAfterPeriodFieldId)
  {
    return valueMap
      ? fnFieldValueToRawValue("number", valueMap[numberOfDigitsAfterPeriodFieldId]) as number
      : Infinity;
  }
  return undefined;
}

export function fnResolveFieldPropShowAsCheckbox(defn: DefnFieldSwitch, valueMap?: FieldValues)
{
  const showAsCheckbox = defn.showAsCheckbox;
  const showAsCheckboxVar = defn.showAsCheckboxVar;
  const showAsCheckboxFieldId = defn.showAsCheckboxFieldId;

  if(showAsCheckbox)
  {
    return showAsCheckbox;
  }
  else if(showAsCheckboxVar !== undefined)
  {
    return showAsCheckboxVar;
  }
  else if(showAsCheckboxFieldId)
  {
    return Boolean(valueMap && fnFieldValueToRawValue("bool", valueMap[showAsCheckboxFieldId]));
  }
  return false;
}

export function fnResolveFieldPropRequired(defn: DefnFieldEditable, valueMap?: FieldValues)
{
  const required = defn.required;
  const requiredVar = defn.requiredVar;
  const requiredFieldId = defn.requiredFieldId;

  if(required)
  {
    return required;
  }
  else if(requiredVar !== undefined)
  {
    return requiredVar;
  }
  else if(requiredFieldId)
  {
    return Boolean(valueMap && fnFieldValueToRawValue("bool", valueMap[requiredFieldId]));
  }
  return false;
}

export function fnResolveFieldPropDisabled(defn: DefnFieldEditable, valueMap?: FieldValues)
{
  const disabled = defn.disabled;
  const disabledVar = defn.disabledVar;
  const disabledFieldId = defn.disabledFieldId;

  if(disabled)
  {
    return disabled;
  }
  else if(disabledVar !== undefined)
  {
    return disabledVar;
  }
  else if(disabledFieldId)
  {
    return Boolean(fnFieldValueToRawValue("bool", valueMap?.[disabledFieldId]));
  }
  return false;
}

export function fnResolveFieldPropMinChar(defn: DefnFieldText | DefnFieldParagraph, valueMap?: FieldValues)
{
  const minCharCount = defn.minCharCount;
  const minCharCountVar = defn.minCharCountVar;
  const minCharCountFieldId = defn.minCharCountFieldId;

  if(minCharCount !== undefined)
  {
    return minCharCount;
  }
  else if(minCharCountVar !== undefined)
  {
    return minCharCountVar;
  }
  else if(minCharCountFieldId)
  {
    return fnFieldValueToRawValue("number", valueMap?.[minCharCountFieldId]) as number | undefined;
  }
  return undefined;
}

export function fnResolveFieldPropMaxChar(defn: DefnFieldText | DefnFieldParagraph, valueMap?: FieldValues)
{
  const maxCharCount = defn.maxCharCount;
  const maxCharCountVar = defn.maxCharCountVar;
  const maxCharCountFieldId = defn.maxCharCountFieldId;

  if(maxCharCount !== undefined)
  {
    return maxCharCount;
  }
  else if(maxCharCountVar !== undefined)
  {
    return maxCharCountVar;
  }
  else if(maxCharCountFieldId)
  {
    return fnFieldValueToRawValue("number", valueMap?.[maxCharCountFieldId]) as number | undefined;
  }
  return undefined;
}

export function fnResolveFieldPropMin(
  defn: DefnFieldNumber | DefnFieldCounter | DefnFieldDecimal | DefnFieldLogCounter,
  valueMap?: FieldValues)
{
  const min = defn.min;
  const minVar = defn.minVar;
  const minFieldId = defn.minFieldId;

  if(min !== undefined)
  {
    return min;
  }
  else if(minVar !== undefined)
  {
    return minVar;
  }
  else if(minFieldId)
  {
    return fnFieldValueToRawValue("number", valueMap?.[minFieldId]) as number | undefined;
  }
  return undefined;
}

export function fnResolveFieldPropMax(
  defn: DefnFieldNumber | DefnFieldCounter | DefnFieldDecimal,
  valueMap?: FieldValues)
{
  const max = defn.max;
  const maxVar = defn.maxVar;
  const maxFieldId = defn.maxFieldId;

  if(max !== undefined)
  {
    return max;
  }
  else if(maxVar !== undefined)
  {
    return maxVar;
  }
  else if(maxFieldId)
  {
    return fnFieldValueToRawValue("number", valueMap?.[maxFieldId]) as number | undefined;
  }

  return undefined;
}

export function fnResolveFieldPropHelperText(defn: DefnFieldEditable, valueMap?: FieldValues)
{
  const helperText = defn.helperText;
  const helperTextVar = defnDtoTextToString(defn.helperTextVar);
  const helperTextFieldId = defn.helperTextFieldId;

  if(helperText)
  {
    return helperText;
  }
  else if(helperTextVar)
  {
    return helperTextVar;
  }
  else if(helperTextFieldId)
  {
    return fnFieldValueToRawValue("text", valueMap?.[helperTextFieldId]) as string | undefined;
  }
  return undefined;
}

export function fnResolveFieldPropPlaceholder(defn: DefnFieldEditable, valueMap?: FieldValues)
{
  const placeHolder = defn.placeHolder;
  const placeHolderVar = defnDtoTextToString(defn.placeHolderVar);
  const placeHolderFieldId = defn.placeHolderFieldId;

  if(placeHolder)
  {
    return placeHolder;
  }
  else if(placeHolderVar)
  {
    return placeHolderVar;
  }
  else if(placeHolderFieldId)
  {
    return fnFieldValueToRawValue("text", valueMap?.[placeHolderFieldId]) as string | undefined;
  }
  return undefined;
}

import {IRowNode} from "ag-grid-community";
import {EditableCallbackParams} from "ag-grid-community";
import {RowDataUpdatedEvent} from "ag-grid-community";
import {BodyScrollEndEvent} from "ag-grid-community";
import {ColDef} from "ag-grid-community";
import {ColGroupDef} from "ag-grid-community";
import {ICellRendererParams} from "ag-grid-community";
import {RowStyle} from "ag-grid-community";
import {RowClassParams} from "ag-grid-community";
import {FirstDataRenderedEvent} from "ag-grid-community";
import {ValueGetterParams} from "ag-grid-community";
import {ValueSetterParams} from "ag-grid-community";
import {GridApi} from "ag-grid-community";
import {SortChangedEvent} from "ag-grid-community/dist/types/core/events";
import {FilterModifiedEvent} from "ag-grid-community/dist/types/core/events";
import {PaginationChangedEvent} from "ag-grid-community/dist/types/core/events";
import {ITooltipParams} from "ag-grid-community/dist/types/core/rendering/tooltipComponent";
import {replace} from "lodash";
import {cloneDeep} from "lodash";
import {toNumber} from "lodash";
import {isNumber} from "lodash";
import {useCallback} from "react";
import {useEffect} from "react";
import {useRef} from "react";
import {CSSProperties} from "react";
import {isGridId} from "../../../api/meta/base/ApiPlus";
import {nextRowId} from "../../../api/meta/base/ApiPlus";
import {isFieldId} from "../../../api/meta/base/ApiPlus";
import {DefnComp} from "../../../api/meta/base/dto/DefnComp";
import {DefnDtoTableHeader} from "../../../api/meta/base/dto/DefnDtoTableHeader";
import {DefnDtoTableStyle} from "../../../api/meta/base/dto/DefnDtoTableStyle";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldCamera} from "../../../api/meta/base/dto/DefnFieldCamera";
import {DefnFieldColor} from "../../../api/meta/base/dto/DefnFieldColor";
import {DefnFieldEditable} from "../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldHyperlinkRow} from "../../../api/meta/base/dto/DefnFieldHyperlinkRow";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickText} from "../../../api/meta/base/dto/DefnFieldPickText";
import {DefnFieldScanCode} from "../../../api/meta/base/dto/DefnFieldScanCode";
import {DefnFieldSignature} from "../../../api/meta/base/dto/DefnFieldSignature";
import {DefnFieldSwitch} from "../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldVoice} from "../../../api/meta/base/dto/DefnFieldVoice";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {DefnGrid} from "../../../api/meta/base/dto/DefnGrid";
import {DefnLayoutGridXYChart} from "../../../api/meta/base/dto/DefnLayoutGridXYChart";
import {DefnMapOfTableStyle} from "../../../api/meta/base/dto/DefnMapOfTableStyle";
import {DefnStudioMapOfTableFooter} from "../../../api/meta/base/dto/DefnStudioMapOfTableFooter";
import {DefnStudioMapOfTableHeader} from "../../../api/meta/base/dto/DefnStudioMapOfTableHeader";
import {FieldDtoGridRow} from "../../../api/meta/base/dto/FieldDtoGridRow";
import {FieldValueCamera} from "../../../api/meta/base/dto/FieldValueCamera";
import {FieldValueColor} from "../../../api/meta/base/dto/FieldValueColor";
import {FieldValueDate} from "../../../api/meta/base/dto/FieldValueDate";
import {FieldValueGrid} from "../../../api/meta/base/dto/FieldValueGrid";
import {FieldValueNumber} from "../../../api/meta/base/dto/FieldValueNumber";
import {FieldValueOptionId} from "../../../api/meta/base/dto/FieldValueOptionId";
import {FieldValueScanCode} from "../../../api/meta/base/dto/FieldValueScanCode";
import {FieldValueSignature} from "../../../api/meta/base/dto/FieldValueSignature";
import {FieldValueSwitch} from "../../../api/meta/base/dto/FieldValueSwitch";
import {FieldValueVoice} from "../../../api/meta/base/dto/FieldValueVoice";
import {FormValue} from "../../../api/meta/base/dto/FormValue";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {CenterAlignGridCellType} from "../../../api/meta/base/StudioSetsFieldType";
import {RightAlignGridCellType} from "../../../api/meta/base/StudioSetsFieldType";
import {MetaIdTableStyle} from "../../../api/meta/base/Types";
import {EnumDefnTextSize} from "../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../api/meta/base/Types";
import {EnumDefnPermission} from "../../../api/meta/base/Types";
import {MetaIdRole} from "../../../api/meta/base/Types";
import {MetaIdFooter} from "../../../api/meta/base/Types";
import {MetaIdHeader} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {EnumDefnFields} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {parseDateStrToDate} from "../../../base/plus/DatePlus";
import {extractDate} from "../../../base/plus/DatePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {getFormFieldValueAsText} from "../../../base/plus/FieldValuePlus";
import {getResolvedPermissionMatrix} from "../../../base/plus/FormPlus";
import {loopDefnForm} from "../../../base/plus/FormPlus";
import {getDefnDtoColorToCssColor} from "../../../base/plus/FormPlus";
import {isNumericField} from "../../../base/plus/FormPlus";
import {fnResolveFieldPropMax} from "../../../base/plus/FormYupPlus";
import {fnResolveFieldPropMin} from "../../../base/plus/FormYupPlus";
import {loopKeysMap} from "../../../base/plus/JsPlus";
import {calcDeltaArray} from "../../../base/plus/JsPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {px} from "../../../base/plus/StringPlus";
import {getCombinedString} from "../../../base/plus/StringPlus";
import {getSystemFieldComp} from "../../../base/plus/StudioFormPlus";
import {isSystemField} from "../../../base/plus/StudioFormPlus";
import {isGenericId} from "../../../base/plus/SysPlus";
import {nextGenericId} from "../../../base/plus/SysPlus";
import {gapHalf} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {CssColor} from "../../../base/plus/ThemePlus";
import {IDataGridCellStyle} from "../../../base/types/TypeDataGrid";
import {IDataGridRowData} from "../../../base/types/TypeDataGrid";
import {IGridBinderAll} from "../../../base/types/TypeDataGrid";
import {IDataGridCellRendererParams} from "../../../base/types/TypeDataGrid";
import {CbOnClickDataGrid} from "../../../base/types/TypeDataGrid";
import {IDataGridFooterCell} from "../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../../base/types/TypeDataGrid";
import {TypeColDef} from "../../../base/types/TypeDataGrid";
import {IDataGridDataLayout} from "../../../base/types/TypeDataGrid";
import {isComboId} from "../../../base/types/TypesComboId";
import {toComboId} from "../../../base/types/TypesComboId";
import helperTextData from "../../atom/assets/PlaceholderTextHome.json";
import {getCompLabel} from "../../form/viewer/base/FormViewerPlus";
import {validateCondition} from "../../form/viewer/base/FormVisibilityPlus";
import GridCellActions from "./cell/GridCellActions";
import GridCellBase from "./cell/GridCellBase";
import GridCellFactory from "./cell/GridCellFactory";
import {GridCellHeaderGroup} from "./cell/GridCellHeader";
import {GridCellHeader} from "./cell/GridCellHeader";
import GridCellPrimaryCell from "./cell/GridCellPrimaryCell";
import DataGridTooltip from "./DataGridTooltip";

type TypeColumnSizeMap = Record<string, "auto" | "flex" | number | IColumnSizeFlex>;
export type TypeCapturedValues = "captureLocation" | "captureTime" | "captureUser" | "value";
type TypeDefnCapturedField =
  | DefnFieldSwitch | DefnFieldVoice | DefnFieldCamera | DefnFieldScanCode | DefnFieldSignature;
export type TypeCapturedFieldValue =
  | FieldValueSwitch | FieldValueVoice | FieldValueCamera | FieldValueScanCode | FieldValueSignature;

interface IColumnSizeFlex
{
  flex: number;
  minWidth: number;
}

export interface IDataGridExternalSx
{
  hideHeaderColumnSeparator?: boolean,
  hidePaginationBgColor?: boolean,
}

const dataGridHiddenFieldIdSet = [
  "grid",
  "ref"
] as EnumDefnCompType[];
const expiredMsg = helperTextData.formDataVisibilityExpired.title;

export const dataGridPaginationPageSize = 100;
export const dataGridTooltipShowDelay = 1000;
export const dataGridMinColWidth = 80;
export const dataGridRowHeight = 32;
export const dataGridRowBuffer = 50;
const dataGridActionIconWidth = 40;

// region reserved column id
const GRID_COL_ID_PREFIX = "DATA_GRID_COL_ID";
const GRID_COL_ID_SEP = "_";
const GRID_COL_ID_ACTION_SUFFIX = "ACTION";
const GRID_COL_ID_INDEX_SUFFIX = "INDEX";
const GRID_COL_ID_COMMENT_SUFFIX = "COMMENT";
const GRID_COL_ID_TIMER_SUFFIX = "TIMER";

export const GRID_COL_ID_ACTION_LABEL = "Action";
export const GRID_COL_ID_INDEX_LABEL = "Index";
export const GRID_COL_ID_COMMENT_LABEL = "Comments";
export const GRID_COL_ID_EXPIRY_LABEL = "Expiry";
export const GRID_COL_ID_PRIMARY_SUFFIX = "PRIMARY";

export const GRID_COL_ID_ACTION = getCombinedString([GRID_COL_ID_PREFIX, GRID_COL_ID_ACTION_SUFFIX], GRID_COL_ID_SEP);
export const GRID_COL_ID_INDEX = getCombinedString([GRID_COL_ID_PREFIX, GRID_COL_ID_INDEX_SUFFIX], GRID_COL_ID_SEP);
export const GRID_COL_ID_COMMENT = getCombinedString([GRID_COL_ID_PREFIX, GRID_COL_ID_COMMENT_SUFFIX], GRID_COL_ID_SEP);
export const GRID_COL_ID_EXPIRY = getCombinedString([GRID_COL_ID_PREFIX, GRID_COL_ID_TIMER_SUFFIX], GRID_COL_ID_SEP);
export const GRID_COL_ID_PRIMARY = getCombinedString([GRID_COL_ID_PREFIX, GRID_COL_ID_PRIMARY_SUFFIX], GRID_COL_ID_SEP);

export const GRID_FONT_VARIANT: EnumDefnTextSize = "body2";

const EDITABLE_FIELDS = [
  "bool",
  "decimal",
  "number",
  "rating",
  "slider",
  "text",
  "email",
  "paragraph",
  "mobileNumber",
  "handle",
  "counter",
  "symbol"
];

function getGridReservedColIdSuffix(colId: string)
{
  const index = colId.lastIndexOf(GRID_COL_ID_SEP);
  return colId.substring(index + 1);
}

export function isGridReservedColId(colId: string)
{
  const index = colId.lastIndexOf(GRID_COL_ID_SEP);
  const prefix = colId.substring(0, index);
  return prefix ? prefix === GRID_COL_ID_PREFIX : false;
}

export function isGridColAction(colId: string)
{
  return colId ? getGridReservedColIdSuffix(colId) === GRID_COL_ID_ACTION_SUFFIX : false;
}

export function isGridColPrimary(colId: string)
{
  return colId ? getGridReservedColIdSuffix(colId) === GRID_COL_ID_PRIMARY_SUFFIX : false;
}

export function isGridColIndex(colId: string)
{
  return colId ? getGridReservedColIdSuffix(colId) === GRID_COL_ID_INDEX_SUFFIX : false;
}

export function isGridColComments(colId: string)
{
  return colId ? getGridReservedColIdSuffix(colId) === GRID_COL_ID_COMMENT_SUFFIX : false;
}

export function isGridColExpiry(colId: string)
{
  return colId ? getGridReservedColIdSuffix(colId) === GRID_COL_ID_TIMER_SUFFIX : false;
}

// endregion

//region valueGetter

export function fnDataGridTooltipValueGetter(params: ITooltipParams<IDataGridCell>)
{
  const text = params.valueFormatted || params.value || "";
  const toolTipFieldId = (params.context as AgGridContext)?.layout?.toolTipFieldId;
  if(toolTipFieldId)
  {
    const defnForm = (params.context as AgGridContext)?.defnForm;
    const field = defnForm?.compMap[toolTipFieldId] as DefnField;
    const value = params.data?.valueMap[toolTipFieldId];
    return ((field && value)
      ? getFormFieldValueAsText(field, value)
      : undefined) || text;
  }
  return text;
}

export function fnDataGridValueGetter(params: ValueGetterParams<IDataGridCell>)
{
  const colId = params.colDef.colId;
  const layout = (params.context as AgGridContext).layout;

  if(params.data?.expired)
  {
    const showCompIdSet = layout?.showCompIdSet;
    if(colId && showCompIdSet?.[0] === colId)
    {
      return expiredMsg;
    }
    return "";
  }
  if(!colId || params.data?.isFooterRow)
  {
    return "";
  }
  if(isSystemField(colId as EnumDefnFields))
  {
    const field = getSystemFieldComp(colId as EnumDefnFields);
    switch(colId as EnumDefnFields)
    {
      case "$RowId":
        return params.data?.rowId;
      case "$RowOrder":
        return params.data?.rowOrder;
      case "$CreatedOn":
        const createdOn = params.data?.createdOn;
        return createdOn
          ? getFormFieldValueAsText(field, {
            value: createdOn
          } as FieldValueDate)
          : undefined;
      case "$UpdatedOn":
        const updatedOn = params.data?.updatedOn;
        return updatedOn
          ? getFormFieldValueAsText(field, {
            value: updatedOn
          } as FieldValueDate)
          : undefined;
    }
  }
  else if(isGridId(colId) && layout?.sparklineLayoutMap?.[colId])
  {
    return fnDataGridValueGetterSparkline(params);
  }

  const defnForm = (params.context as AgGridContext)?.defnForm;
  const field = defnForm?.compMap[colId] as DefnField;
  const value = params.data?.valueMap[colId];
  return getFormFieldValueAsText(field, value);
}

export function fnDataGridValueGetterSparkline(params: ValueGetterParams<IDataGridCell>)
{
  const colId = params.colDef.colId;
  if(!colId)
  {
    return;
  }

  const defnFormCompMap = (params.context as AgGridContext)?.defnForm?.compMap;
  const colGridLayouts = (defnFormCompMap?.[colId] as DefnGrid)?.layoutGridMap?.map;
  const sparklineLayoutMapId = (params.context as AgGridContext).layout?.sparklineLayoutMap?.[colId];
  const sparklineLayout = sparklineLayoutMapId
    ? colGridLayouts?.[sparklineLayoutMapId] as DefnLayoutGridXYChart
    : undefined;
  const values = params.data?.valueMap[colId] as FieldValueGrid;
  if(sparklineLayout && values)
  {
    const xAxisField = sparklineLayout.xAxis;
    const yAxis = sparklineLayout.yAxisMap?.keys.at(0);
    const yAxisField = yAxis ? sparklineLayout.yAxisMap?.map[yAxis]?.fieldId : undefined;

    const dataSets = [] as [string, number][];
    if(xAxisField && yAxisField)
    {
      loopKeysMap(values, (_: RowId, gridValue: FieldDtoGridRow) =>
      {
        const xField = defnFormCompMap?.[xAxisField] as DefnField;
        const yField = defnFormCompMap?.[yAxisField] as DefnField;

        const xFieldValue = gridValue.valueMap?.[xAxisField];
        const yFieldValue = gridValue.valueMap?.[yAxisField];
        const xValueText = getFormFieldValueAsText(xField, xFieldValue);
        const yValueRaw = fnFieldValueToRawValue(yField.type, yFieldValue);
        if(xValueText && yValueRaw !== undefined)
        {
          dataSets.push([xValueText, toNumber(yValueRaw)]);
        }
      });
    }
    return dataSets;
  }
}

export function fnDataGridFilterValueGetter(params: ValueGetterParams<IDataGridCell>)
{
  const colId = params.colDef.colId;
  if(colId && params.data)
  {
    const defnForm = (params.context as AgGridContext)?.defnForm;
    const defnField = defnForm?.compMap[colId] as DefnField;
    const fieldValue = params?.data.valueMap?.[colId];
    const filterType = params.colDef?.filter;
    const text = getFormFieldValueAsText(defnField, fieldValue);

    if(filterType === "agNumberColumnFilter" && isNumericField(defnField.type))
    {
      const fieldValue = params?.data.valueMap?.[colId] as FieldValueNumber | undefined;
      return fieldValue?.value ?? null;
    }
    else if(filterType === "agDateColumnFilter" && isDateField(defnField.type))
    {
      const fieldValue = params?.data.valueMap?.[colId] as FieldValueDate | undefined;
      return fieldValue?.value ?? null;
    }
    else
    {
      return text;
    }
  }
  return params;
}

export function fnDataGridValueSetter(params: ValueSetterParams<IDataGridCell>)
{
  const rowNode = params.api.getRowNode(params.data.rowId);
  const colId = params.colDef.colId;
  const defnForm = (params.context as AgGridContext).defnForm;
  const field = colId ? defnForm?.compMap[colId] as DefnField : undefined;
  const data = rowNode?.data;
  let newValue = params.newValue;
  if(data && field && colId && newValue)
  {
    switch(field.type)
    {
      case "decimal":
      case "number":
      case "counter":
      case "slider":
        newValue = parseFloat(newValue);
        const min = fnResolveFieldPropMin(field, params.data.valueMap);
        const max = fnResolveFieldPropMax(field, params.data.valueMap);
        if(newValue)
        {
          if(min !== undefined && toNumber(newValue) < min)
          {
            newValue = undefined;
          }
          if(max !== undefined && toNumber(newValue) > max)
          {
            newValue = undefined;
          }
        }
        break;
      case "rating":
        newValue = parseFloat(newValue);
        if(newValue <= 0 && newValue >= 5)
        {
          newValue = undefined;
        }
        break;
      case "bool":
        newValue = newValue.toString().toLowerCase();
        newValue = newValue === "true" || newValue === "yes";
        break;
    }

    if(newValue === undefined)
    {
      return;
    }

    const newValueMap = {
      ...data,
      valueMap: {
        ...data.valueMap,
        [colId]: fnRawValueToFieldValue(field.type, newValue)
      }
    } as FormValueRaw;

    rowNode?.setData(newValueMap);
  }
}

export function fnDataGridColEditable(params: EditableCallbackParams<IDataGridCell, any>)
{
  const colId = params.colDef.colId;
  if(colId)
  {
    const defnForm = (params.context as AgGridContext)?.defnForm;
    const defnField = defnForm?.compMap[colId] as DefnField;

    if(EDITABLE_FIELDS.includes(defnField?.type))
    {
      const fieldPermission = (params.context as AgGridContext)?.layout?.fieldPermissions?.[colId];
      const valueMap = params.data?.valueMap;

      switch(fieldPermission)
      {
        case "hide":
        case "read":
        case "invisible":
        case "writeOnInsert":
          return false;
        case "writeOnce":
          return !(valueMap && valueMap[colId]);
        case "write":
          return true;
        default:
          return false;
      }
    }
  }

  return false;
}

export function fnDataGridColCellEditor(compType: EnumDefnCompType)
{
  switch(compType)
  {
    case "decimal":
    case "number":
    case "counter":
    case "slider":
    case "rating":
      return "agNumberCellEditor";
    default:
      return "agTextCellEditor";
  }
}

//  endregion

// region calc column defs

export function getDefaultColumnDef<SR1, SR2, SR3, SR4, SR5, SR6>(
  initialFlex?: number,
  showHeaderToolPanel?: boolean,
  cbOnClickDataGrid?: CbOnClickDataGrid,
  gridBinder?: IGridBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  editable?: boolean
)
{
  return {
    cellRenderer: GridCellBase,
    ...!editable && {
      tooltipComponent: DataGridTooltip,
      tooltipValueGetter: fnDataGridTooltipValueGetter,
      ...showHeaderToolPanel && {
        filterValueGetter: fnDataGridFilterValueGetter
      }
    },
    valueGetter: fnDataGridValueGetter,
    valueSetter: editable
      ? fnDataGridValueSetter
      : undefined,
    cellRendererParams: {
      cbOnClickDataGrid: cbOnClickDataGrid,
      gridBinder: gridBinder
    } as IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>,
    minWidth: dataGridMinColWidth,
    initialFlex: initialFlex,
    filter: "agMultiColumnFilter",
    ...!showHeaderToolPanel &&
    {
      suppressMenu: true,
      resizable: false,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      suppressHeaderFilterButton: true,
      suppressFloatingFilterButton: true,
      sortable: false
    },
    colSpan: params =>
    {
      const colId = params.colDef.colId;
      const layout = (params.context as AgGridContext).layout;
      const showCompIdSet = layout?.showCompIdSet;
      if(colId && params.data?.expired)
      {
        if(isGridColPrimary(colId) || isGridColAction(colId) || isGridColIndex(colId) || isGridColExpiry(colId))
        {
          return 1;
        }
        if(showCompIdSet?.[0] === colId)
        {
          return showCompIdSet?.length;
        }
        return 0;
      }
      if(params.data && colId)
      {
        if(params.data?.isFooterRow)
        {
          return params.data.footerValueMap?.[colId]?.span || 1;
        }
      }
      return 1;
    },
    rowDrag: false,
    editable: editable ? fnDataGridColEditable : false
  } as TypeColDef;
}

function dateComparator(
  date1?: string,
  date2?: string,
  _nodeA?: IRowNode<IDataGridCell>,
  _nodeB?: IRowNode<IDataGridCell>,
  isDescending?: boolean
): number
{
  if(!date1 && !date2)
  {
    return 0;
  }
  if(!date1)
  {
    return isDescending ? -1 : 1;
  }
  if(!date2)
  {
    return isDescending ? 1 : -1;
  }

  // Parse the dates
  const _date1 = parseDateStrToDate(date1);
  const _date2 = parseDateStrToDate(date2);

  if(!_date1 || !_date2)
  {
    return 0;
  }
  else if(_date1 > _date2)
  {
    return 1;
  }
  else if(_date1 < _date2)
  {
    return -1;
  }
  else
  {
    return 0;
  }
}

function numberComparator(
  num1: string,
  num2: string,
  _nodeA?: IRowNode<IDataGridCell>,
  _nodeB?: IRowNode<IDataGridCell>,
  isDescending?: boolean
)
{
  const regexRemoveStr = /\D/g;
  const _num1 = toNumber(replace(num1, regexRemoveStr, ""));
  const _num2 = toNumber(replace(num2, regexRemoveStr, ""));
  if(!_num1 && !_num2)
  {
    return 0;
  }
  if(!_num1)
  {
    return isDescending ? -1 : 1;
  }
  if(!_num2)
  {
    return isDescending ? 1 : -1;
  }
  if(_num1 > _num2)
  {
    return 1;
  }
  else if(_num1 < _num2)
  {
    return -1;
  }
  else
  {
    return 0;
  }
}

function dateComparatorFilter(filterLocalDateAtMidnight: Date, cellValue?: string)
{
  if(!cellValue || !filterLocalDateAtMidnight)
  {
    return 0;
  }
  const dateStrISO = filterLocalDateAtMidnight.toISOString();
  let date1 = extractDate(dateStrISO)?.getTime();
  let date2 = extractDate(cellValue)?.getTime();
  if(!date1 || !date2)
  {
    return 0;
  }
  if(date1 < date2)
  {
    return 1;
  }
  else if(date1 > date2)
  {
    return -1;
  }
  return 0;
}

const getColumnSizeInPxOrEm = (columnSize: string) =>
{
  const withInNumber = parseFloat(columnSize.slice(0, -2));
  if(isNumber(withInNumber) && !isNaN(withInNumber))
  {
    if(columnSize.slice(-2) === "px")
    {
      return withInNumber;
    }
    else if(columnSize.slice(-2) === "em")
    {
      return withInNumber * 16; // one em is 16px.
    }
  }
  return dataGridMinColWidth;
};

function getGridColumnSizeMap(showFieldIdSet?: MetaIdField[], columnSizeSet?: string[])
{
  if(!columnSizeSet || !showFieldIdSet)
  {
    return undefined;
  }
  const columnSizeMap = {} as TypeColumnSizeMap;
  showFieldIdSet.forEach((fieldId, index) =>
  {
    const columnSize = columnSizeSet[index] || columnSizeSet.at(-1);
    if(columnSize)
    {
      if(columnSize === "AutoSize")
      {
        columnSizeMap[fieldId] = "auto";
      }
      else if(columnSize === "Flex")
      {
        columnSizeMap[fieldId] = "flex";
      }
      else if(columnSize.startsWith("FlexMin_"))
      {
        const columnSizeFlex = columnSize.split("_")[1];
        columnSizeMap[fieldId] = {
          flex: 1,
          minWidth: getColumnSizeInPxOrEm(columnSizeFlex)
        };
      }
      else
      {
        columnSizeMap[fieldId] = getColumnSizeInPxOrEm(columnSize);
      }
    }
  });

  return columnSizeMap;
}

function getGridColumnAlignmentMap(showFieldIdSet?: MetaIdField[], columnAlignmentSet?: string[])
{
  if(!columnAlignmentSet || !showFieldIdSet)
  {
    return undefined;
  }
  const columnAlignmentMap = {} as Record<string, string>;
  showFieldIdSet.forEach((fieldId, index) =>
  {
    const columnAlignment = columnAlignmentSet[index] || columnAlignmentSet.at(-1);
    if(columnAlignment)
    {
      columnAlignmentMap[fieldId] = columnAlignment;
    }
  });

  return columnAlignmentMap;
}

function calcHeaderIds(
  fieldIdSet?: MetaIdField[],
  header?: DefnStudioMapOfTableHeader)
{
  let headerIdSet = [] as {
    id: MetaIdHeader | MetaIdField;
    fieldIdSet?: MetaIdField[];
  }[];
  const headerFieldIdMap = {} as Record<MetaIdField, MetaIdHeader>;
  const headerIndexMap = {} as Record<string, number>;
  header?.keys.forEach(headerId =>
  {
    header?.map[headerId]?.fieldIdSet.forEach(fieldId =>
    {
      headerFieldIdMap[fieldId] = headerId;
    });
  });
  let childrenCount = 0;
  fieldIdSet?.forEach((fieldId, index) =>
  {
    const previousFieldId = fieldIdSet[index - 1];
    const previousHeaderId = previousFieldId ? headerFieldIdMap[previousFieldId] : undefined;

    const headerId = headerFieldIdMap[fieldId];
    if(headerId)
    {
      if(previousHeaderId !== headerId)
      {
        headerIndexMap[headerId] = (index - childrenCount);
        headerIdSet.push({
          id: headerId,
          fieldIdSet: [fieldId]
        });
      }
      else
      {
        headerIdSet[headerIndexMap[headerId]].fieldIdSet?.push(fieldId);
        childrenCount++;
      }
    }
    else
    {
      headerIdSet.push({
        id: fieldId
      });
    }
  });

  return headerIdSet;
}

function calcFooterIds(
  gridFieldIdSet?: MetaIdField[],
  footer?: DefnStudioMapOfTableFooter)
{
  let footerIdSet = [] as {id: MetaIdField | string, colId?: MetaIdField, footerId?: MetaIdFooter, span: number}[];
  const newFooterIdSet = [] as typeof footerIdSet;
  if(footer)
  {
    const footerFieldIdMap = {} as Record<MetaIdField, MetaIdFooter>;
    footer?.keys.forEach(footerId =>
    {
      footer?.map[footerId]?.fieldIdSet.forEach(fieldId =>
      {
        footerFieldIdMap[fieldId] = footerId;
      });
    });

    gridFieldIdSet?.forEach((fieldId, index) =>
    {
      if(footerFieldIdMap[fieldId])
      {
        const footerId = footerFieldIdMap[fieldId];
        const dtoFooter = footer?.map[footerId];
        if(dtoFooter?.displayFieldId)
        {
          footerIdSet.push({
            id: dtoFooter?.displayFieldId,
            footerId: footerId,
            colId: fieldId,
            span: 1
          });
          return;
        }
      }
      footerIdSet.push({
        id: nextGenericId(),
        colId: fieldId,
        span: 1
      });
    });

    let index = 0;
    footerIdSet.forEach((value, i) =>
    {
      if(i > 0)
      {
        if(isGenericId(value.id) && isGenericId(footerIdSet[i - 1].id))
        {
          newFooterIdSet[index].span += 1;
        }
        else if(isFieldId(value.id) && isFieldId(footerIdSet[i - 1].id) && footerIdSet[i - 1].id === value.id)
        {
          newFooterIdSet[index].span += 1;
        }
        else
        {
          index++;
          newFooterIdSet[index] = value;
        }
      }
      else
      {
        newFooterIdSet[index] = value;
      }

    });
  }
  return newFooterIdSet;
}

function fnFlatColumnDefs(columnDefs: TypeColDef[])
{
  const cols = [] as ColDef<IDataGridCell>[];
  columnDefs.forEach((columnDef: ColDef<IDataGridCell>) =>
  {
    if((columnDef as ColGroupDef<IDataGridCell>)?.children)
    {
      const columnDefGroup = columnDef as ColGroupDef<IDataGridCell>;
      cols.push(...fnFlatColumnDefs(columnDefGroup.children));
    }
    else
    {
      if(columnDef.colId)
      {
        cols.push(columnDef);
      }
    }
  });

  return cols;
}

export function convertGridRowToAgGridFooterRows(
  columnDefs: TypeColDef[],
  layout: IDataGridDataLayout): IDataGridCell
{
  const footer = layout.footer;

  const columnIds = fnFlatColumnDefs(columnDefs).map(value => value.colId as MetaIdField);
  columnIds.shift();
  const footerMap = calcFooterIds(columnIds, footer);
  const _footerValueMap = {} as Record<MetaIdField, IDataGridFooterCell>;
  footerMap.forEach((value) =>
  {
    if(value.colId)
    {
      const footerId = value.footerId;
      const dtoFooter = footerId ? footer?.map[footerId] : undefined;

      _footerValueMap[value.colId] = {
        dtoFooter: dtoFooter,
        span: value.span
      };
    }
  });

  return {
    rowId: nextRowId(),
    isFooterRow: true,
    footerValueMap: _footerValueMap,
    valueMap: {} as Record<string, any>
  } as IDataGridCell;
}

function fnPrependGenericAgGridColDef(
  columnDefs: TypeColDef[],
  colActionIconCount?: number,
  showCheckboxes?: boolean,
  colActionName?: string,
  colIndexName?: string)
{
  const width = calcActionCellWidth(colActionIconCount);

  columnDefs.push(
    {
      cellRenderer: GridCellPrimaryCell,
      colId: GRID_COL_ID_PRIMARY,
      minWidth: 1,
      maxWidth: 1,
      width: 1,
      initialWidth: 1,
      headerClass: "ag-hidden-header-cell",
      cellClass: "ag-hidden-cell",
      flex: 0,
      sortable: false,
      editable: false,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      pinned: "left"
    });

  if(colActionName)
  {
    columnDefs.push({
      colId: GRID_COL_ID_ACTION,
      headerName: colActionName,
      headerTooltip: colActionName,
      sortable: false,
      editable: false,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      suppressHeaderFilterButton: true,
      suppressFloatingFilterButton: true,
      suppressFillHandle: true,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      tooltipComponent: undefined,

      width: width,
      minWidth: width,
      maxWidth: width,
      initialWidth: width,
      tooltipValueGetter: params => "",
      cellRenderer: GridCellActions,
      ...showCheckboxes && {
        headerCheckboxSelection: true,
        checkboxSelection: params =>
        {
          return (params.context as AgGridContext)?.isPickMany;
        }
      },
      rowDrag: false
    });
  }

  if(colIndexName)
  {
    columnDefs.push({
      colId: GRID_COL_ID_INDEX,
      flex: 0,
      headerName: colIndexName,
      headerTooltip: colIndexName,
      type: "numericColumn",
      cellStyle: {textAlign: "right"},
      sortable: true,
      valueGetter: (params) =>
      {
        if(!params.node?.group)
        {
          return (params.node?.childIndex || 0) + 1;
        }
      }
    });
  }
}

function fnAppendGenericAgGridColDef(columnDefs: TypeColDef[], colCommentName?: string, colExpiryName?: string)
{
  if(colExpiryName)
  {
    columnDefs.push({
      flex: undefined,
      colId: GRID_COL_ID_EXPIRY,
      headerName: colExpiryName,
      headerTooltip: colExpiryName
    });
  }
  if(colCommentName)
  {
    columnDefs.push({
      flex: undefined,
      colId: GRID_COL_ID_COMMENT,
      headerName: colCommentName,
      cellStyle: {textAlign: "right"},
      headerTooltip: colCommentName
    });
  }

}

export function fnInsertAgGridColDef(
  defnForm: DefnForm,
  layout: IDataGridDataLayout,
  showCheckboxes?: boolean,
  colActionIconCount?: number,
  colIndexName?: string,
  colActionName?: string,
  colCommentName?: string,
  colExpiryName?: string)
{
  const columnDefs = [] as TypeColDef[];
  fnPrependGenericAgGridColDef(columnDefs,
    colActionIconCount,
    showCheckboxes,
    colActionName,
    colIndexName
  );
  const showCompIdSet = layout.showCompIdSet;
  const headers = calcHeaderIds(showCompIdSet, layout.header);

  const columnSizeMap = getGridColumnSizeMap(showCompIdSet, layout.columnSizeSet);
  const columnAlignmentMap = getGridColumnAlignmentMap(showCompIdSet, layout?.columnAlignmentArray);

  headers.forEach((header, index) =>
  {
    if(isFieldId(header.id) || isSystemField(header.id))
    {
      const fieldId = header.id;
      const field = defnForm.compMap[fieldId] || getSystemFieldComp(fieldId as EnumDefnFields);
      if(field.type !== "hyperlinkRow" || (field as DefnFieldHyperlinkRow).displayTextVar?.value?.length)
      {
        if(isCapturedField(field))
        {
          const columnGroup = getColGroupForCapturedField(field as TypeDefnCapturedField,
            defnForm,
            columnSizeMap,
            columnAlignmentMap
          );
          columnDefs.push(columnGroup);
        }
        else
        {
          const column = getColumnDef(fieldId, defnForm, columnSizeMap, columnAlignmentMap);
          columnDefs.push(column);
        }
      }
    }
    else if(isGridId(header.id) && layout.sparklineLayoutMap?.[header.id])
    {
      const colDef = getColumnDefSparkline(header.id, defnForm, layout, columnSizeMap);
      colDef && columnDefs.push(colDef);
    }
    else
    {
      const _header = layout.header?.map[header.id];
      if(_header)
      {
        const columnGroup = getColumnGroupDef(getCombinedString([header.id, index.toString()]), _header);
        header.fieldIdSet?.forEach((fieldId) =>
        {
          const column = getColumnDef(fieldId, defnForm, columnSizeMap, columnAlignmentMap);
          columnGroup.children.push(column);
        });
        columnDefs.push(columnGroup);
      }
    }
  });

  fnAppendGenericAgGridColDef(
    columnDefs,
    layout.showCommentCount ? colCommentName : undefined,
    colExpiryName
  );
  // const last = columnDefs.at(-1);
  // if((last as ColGroupDef)?.children)
  // {
  //   const lastChild = (last as ColGroupDef).children.at(-1);
  //   if((lastChild as ColDef)?.colId)
  //   {
  //     (lastChild as ColDef).flex = 1;
  //   }
  // }
  // else if((last as ColDef)?.colId)
  // {
  //   (last as ColDef).flex = 1;
  // }

  return columnDefs;
}

function isCapturedField(field: DefnComp)
{
  const capturedFieldTypes = [
    "voice",
    "bool",
    "camera",
    "scanCode",
    "signature"
  ] as EnumDefnCompType[];

  if(capturedFieldTypes.includes(field.type))
  {
    const _field = field as TypeDefnCapturedField;
    return Boolean(_field.captureLocation || _field.captureTime || _field.captureUser);
  }

  return false;
}

function getColGroupForCapturedField(
  field: TypeDefnCapturedField,
  defnForm: DefnForm,
  columnSizeMap?: TypeColumnSizeMap,
  columnAlignmentMap?: Record<string, string>
)
{
  const fieldId = field.metaId;
  const fieldIdSet = ["value"] as TypeCapturedValues[];
  if(field.captureLocation)
  {
    fieldIdSet.push("captureLocation");
  }
  if(field.captureTime)
  {
    fieldIdSet.push("captureTime");
  }
  if(field.captureUser)
  {
    fieldIdSet.push("captureUser");
  }

  const groupId = toComboId(fieldId, "group");
  const columnGroup = getColumnGroupDef(groupId, {
    displayText: getFieldLabelText(field as DefnFieldEditable),
    fieldIdSet: fieldIdSet,
    metaId: groupId
  });

  fieldIdSet.forEach(capturedField =>
  {
    const colDef = getColumnDef(fieldId, defnForm, columnSizeMap, columnAlignmentMap);
    columnGroup.children.push({
      ...colDef,
      colId: capturedField === "value" ? fieldId : toComboId(fieldId, capturedField),
      headerName: capturedField === "captureLocation"
        ? "Location"
        : capturedField === "captureTime"
          ? "Time"
          : capturedField === "captureUser"
            ? "User"
            : toLabel(capturedField),
      cellRenderer: (props: any) => GridCellFactory({
        params: props,
        cellType: capturedField === "captureUser"
          ? "userId"
          : capturedField === "captureLocation"
            ? "location"
            : capturedField === "captureTime"
              ? "time"
              : undefined
      })
    });
  });

  return columnGroup;
}

function getColumnDefSparkline(
  colId: MetaIdGrid,
  defnForm: DefnForm,
  layout: IDataGridDataLayout,
  columnSizeMap?: TypeColumnSizeMap
): TypeColDef | undefined
{
  const fieldId = colId;
  const defnField = defnForm.compMap[fieldId] as DefnGrid;
  const columnSize = columnSizeMap ? columnSizeMap[fieldId] : undefined;
  const flex = (columnSize === "flex" ? 1 : undefined) || (columnSize as IColumnSizeFlex)?.flex;
  const width = (typeof columnSize === "number") ? columnSize : undefined;
  const label = getFieldLabelText(defnField as DefnFieldEditable);

  const colGridLayouts = (defnForm.compMap?.[colId] as DefnGrid)?.layoutGridMap?.map;
  const sparklineLayoutMapId = layout?.sparklineLayoutMap?.[colId];
  const sparklineLayout = sparklineLayoutMapId
    ? colGridLayouts?.[sparklineLayoutMapId] as DefnLayoutGridXYChart
    : undefined;

  if(sparklineLayout)
  {
    const yAxisSwimlaneId = sparklineLayout.yAxisMap?.keys.at(0);
    const yAxisSwimlane = yAxisSwimlaneId ? sparklineLayout.yAxisMap?.map[yAxisSwimlaneId] : undefined;
    const sparklineColor = yAxisSwimlane?.color?.value
      ? theme.common.colorWithShade(yAxisSwimlane.color.value, yAxisSwimlane.color.shade) || theme.common.color("black")
      : undefined;

    return {
      colId: fieldId,
      headerName: label,
      initialFlex: flex,
      flex: flex,
      ...width && {width: width},
      cellRenderer: "agSparklineCellRenderer",
      cellRendererParams: {
        sparklineOptions: {
          type: sparklineLayout.kind === "xyChartBarGraph" ? "bar" : "line",
          fill: sparklineColor,
          line: {
            stroke: sparklineColor
          }
        }
      }
    };
  }
}

export function getColumnDef(
  fieldId: MetaIdField,
  defnForm: DefnForm,
  columnSizeMap?: TypeColumnSizeMap,
  columnAlignmentMap?: Record<string, string>)
{
  const defnField = isSystemField(fieldId)
    ? getSystemFieldComp(fieldId as EnumDefnFields)
    : defnForm.compMap[fieldId] as DefnField;
  const columnSize = columnSizeMap ? columnSizeMap[fieldId] : undefined;
  const flex = (columnSize === "flex" ? 1 : undefined) || (columnSize as IColumnSizeFlex)?.flex;
  const width = (typeof columnSize === "number") ? columnSize : undefined;
  const minWidth = (columnSize as IColumnSizeFlex)?.minWidth;
  const textAlign = columnAlignmentMap
    ? columnAlignmentMap[fieldId]
    : getCellTextAlign(defnField.type);

  const label = getFieldLabelText(defnField as DefnFieldEditable);

  return {
    colId: defnField.metaId,
    headerName: label,
    headerTooltip: label,
    headerComponent: GridCellHeader,

    //style
    initialFlex: flex,
    flex: flex,
    ...width && {width: width},
    ...minWidth && {minWidth: minWidth},
    hide: dataGridHiddenFieldIdSet.includes(defnField.type) || defnField.hidden,
    cellStyle: cellClassParams =>
    {
      const context = (cellClassParams.context as AgGridContext);
      const style = getResolvedCellStyle(
        defnField.metaId,
        context.layout?.styleMap,
        context.colIdToStyleIdMap,
        context.defnForm,
        cellClassParams.data as IDataGridCell);

      return {
        textAlign: textAlign,
        backgroundColor: style?.bgcolor
      };
    },

    // filters
    ...(isNumericField(defnField.type)) && {
      filter: "agNumberColumnFilter",
      filterParams: {
        includeBlanksInEquals: false,
        includeBlanksInLessThan: false,
        includeBlanksInGreaterThan: false,
        includeBlanksInRange: false
      }
    },
    ...(isDateField(defnField.type)) && {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: dateComparatorFilter
      },
      comparator: dateComparator
    },
    ...(isNumberField(defnField.type)) && {
      comparator: numberComparator
    },
    cellEditor: fnDataGridColCellEditor(defnField.type)
  } as ColDef<IDataGridCell>;
}

function getColumnGroupDef(id: string, header: DefnDtoTableHeader)
{
  return {
    tooltipComponent: DataGridTooltip,
    groupId: id,
    marryChildren: true,
    headerGroupComponent: GridCellHeaderGroup,
    headerName: header?.displayText,
    headerTooltip: header?.displayText,
    children: [] as TypeColDef[]
  } as ColGroupDef<IDataGridCell>;
}

// endregion

export function onFirstDataRendered(event: FirstDataRenderedEvent<IDataGridCell, AgGridContext>)
{
  const layout = event.context.layout;
  const showCompIdSet = layout?.showCompIdSet;
  const columnSizeSet = layout?.columnSizeSet;
  if(columnSizeSet && showCompIdSet)
  {
    if(columnSizeSet.length === 1)
    {
      if(columnSizeSet[0] === "AutoSize")
      {
        event.api.autoSizeAllColumns(false);
      }
    }
    else
    {
      const autoSizeColIds = [
        GRID_COL_ID_INDEX,
        GRID_COL_ID_COMMENT,
        GRID_COL_ID_EXPIRY
      ] as string[];
      columnSizeSet.forEach((size, index) =>
      {
        if(size === "AutoSize")
        {
          autoSizeColIds.push(showCompIdSet[index]);
        }
      });
      if(autoSizeColIds.length)
      {
        event.api.autoSizeColumns(autoSizeColIds, false);
      }
    }
  }
}

function isDateField(type: EnumDefnCompType)
{
  return type === "date" || type === "dateTime";
}

function isNumberField(type: EnumDefnCompType)
{
  switch(type)
  {
    case "number":
    case "decimal":
    case "counter":
    case "logNumber":
    case "logDecimal":
    case "logCounter":
      return true;
    default:
      return false;
  }
}

function getFieldLabelText(field: DefnFieldEditable)
{
  if(field.type === "label")
  {
    return (field as DefnFieldLabel).textPatternVar?.value?.join("") || getCompLabel(field);
  }
  return getCompLabel(field);
}

function getCellTextAlign(cellType: EnumDefnCompType): CSSProperties["textAlign"]
{
  if(RightAlignGridCellType.includes(cellType))
  {
    return "right";
  }
  else if(CenterAlignGridCellType.includes(cellType))
  {
    return "center";
  }
  return "left";
}

export function getCellTextColor(params: ICellRendererParams<IDataGridCell, AgGridContext>): CssColor
{
  if(params.data?.expired)
  {
    return theme.common.color("textDisabled");
  }
  const colId = params.colDef?.colId;
  const defnForm = (params.context as AgGridContext)?.defnForm;
  const field = (colId ? defnForm?.compMap[colId] : undefined) as DefnField | undefined;
  if(colId && field?.type === "pickText")
  {
    const value = params.data?.valueMap[colId] as FieldValueOptionId | undefined;
    const optionId = value?.optionId;
    const color = optionId ? (field as DefnFieldPickText).optionMap?.map[optionId]?.color : undefined;
    return getDefnDtoColorToCssColor(color);
  }
}

export function calcActionCellWidth(iconsCount?: number)
{
  let width = (theme.common.gapStd * 2);
  if(iconsCount)
  {
    width += dataGridActionIconWidth * iconsCount;
  }
  else
  {
    width += 1 * dataGridActionIconWidth;
  }
  return width;
}

export function getRowStyle(params: RowClassParams<IDataGridCell>): RowStyle
{
  const bgColorFieldId = (params.context as AgGridContext)?.layout?.bgColorFieldId;
  const defnForm = (params.context as AgGridContext)?.defnForm;
  const field = bgColorFieldId ? defnForm?.compMap[bgColorFieldId] as DefnFieldColor : undefined;
  const value = bgColorFieldId ? params?.data?.valueMap[bgColorFieldId] as FieldValueColor : undefined;
  const bgColor = (bgColorFieldId && field)
    ? getFormFieldValueAsText(field, value) as string
    : undefined;

  return {
    backgroundColor: bgColor || "transparent"
  } as RowStyle;
}

export function getDataGridSx(
  hideRowSeparator?: boolean,
  externalSx?: IDataGridExternalSx)
{

  return {
    height: "100%",
    width: "100%",
    "& .ag-root-wrapper": {
      "--ag-row-border-width": "1px",
      "--ag-border-color": theme.common.borderColor,
      "--ag-header-column-separator-color": "#e0e0e0",
      "--ag-header-column-separator-display": "block",
      "--ag-header-column-separator-height": "100%",
      "--ag-header-column-separator-width": "1px",
      "--ag-row-hover-color": theme.common.bgcolorHover,
      "--ag-toggle-button-on-background-color": theme.common.color("primary"),
      "--ag-checkbox-checked-color": theme.common.color("primary"),
      "--ag-checkbox-indeterminate-color": theme.common.color("primary"),
      "--ag-cell-horizontal-padding": px(theme.common.gapStd),
      "& .ag-hidden-cell": {
        padding: "0 !important",
        margin: "0 !important",
        display: "none !important",
        border: "none !important"
      },
      "& .ag-hidden-header-cell": {
        padding: "0 !important",
        margin: "0 !important",
        display: "none !important",
        border: "none !important"
      },
      "& .ag-cell": {
        ...!hideRowSeparator && {
          borderRightColor: theme.common.borderColor,
          "&:last-child": {
            borderRightColor: "none !important"
          }
        },
        bgcolor: "transparent"
      },
      "& .ag-paging-panel": {
        height: px(theme.common.heightFooter + 2),// 2px for border
        bgcolor: theme.common.bgcolorActionBar,
        paddingRight: px(50),
        ...externalSx?.hidePaginationBgColor && {
          bgcolor: "transparent"
        }
      },
      "& .ag-header-cell": {
        padding: "0 !important"
      },
      "& .ag-header-cell-comp-wrapper": {
        height: "100%"
      },
      "& .ag-header-group-cell, .ag-header-cell ": {
        padding: "0 !important",
        borderRightColor: theme.common.borderColor,
        "&:last-child": {
          borderRightColor: "none !important"
        },
        ...theme.typography.caption,
        bgcolor: theme.common.bgcolorSidePane,
        justifyContent: "center",
        color: theme.common.color("textPrimary"),
        "& .ag-header-cell-label, .ag-header-group-cell-label": {
          justifyContent: "center"
        }
      },
      ...externalSx?.hideHeaderColumnSeparator && {
        "--ag-header-column-separator-width": "0px"
      }
    },
    "& .ag-cell-inline-editing": {
      height: "100% !important",
      padding: `${gapHalf} !important`,
      paddingTop: "0 !important",
      paddingBottom: "0 !important"
    },
    "& .ag-cell-inline-editing input": {
      height: "100% !important",
      padding: "0 !important",
      border: "0 !important"
    }
  };
}

export function rowMaster(dataItem: IDataGridCell, masterDetailGridIdSe?: RowId[])
{
  if(!masterDetailGridIdSe || masterDetailGridIdSe?.length === 0)
  {
    return false;
  }
  return masterDetailGridIdSe?.some((gridId) =>
  {
    const keys = (dataItem.valueMap[gridId] as FieldValueGrid | undefined)?.keys;
    return (keys?.length && keys.length > 0);
  });
}

export function useDataGridSubscription(props: {
  initRows: IDataGridRowData,
  onRowsSubscribe?: (rowIds: RowId[]) => void,
  onRowsUnsubscribe?: (rowIds: RowId[]) => void,
})
{
  const onRowsUnsubscribe = props.onRowsUnsubscribe;
  const onRowsSubscribe = props.onRowsSubscribe;
  const initRows = props.initRows;
  const previousSubscription = useRef<RowId[]>(Object.keys(initRows.map));
  const subscription = useRef<Set<RowId>>(new Set<RowId>(previousSubscription.current));
  const previousDisplayRowCount = useRef(initRows.keys.length);

  const calcSubscription = useCallback((api: GridApi<IDataGridCell>) =>
  {
    if(!onRowsSubscribe || !onRowsUnsubscribe)
    {
      return;
    }
    const renderedNodes = api.getRenderedNodes();
    const renderedRowIdList = renderedNodes.map(value => value.data?.rowId as RowId);
    const delta = calcDeltaArray(previousSubscription.current, renderedRowIdList);
    const unsubscribeRows = delta.deletes;
    const subscribeRows = delta.inserts;
    const _previousSubscription = new Set([...previousSubscription.current, ...subscribeRows]);

    delta.deletes.forEach(rowId =>
    {
      _previousSubscription.delete(rowId);
    });
    previousSubscription.current = [..._previousSubscription];

    if(subscribeRows.length > 0)
    {
      subscription.current = new Set([...subscription.current, ...subscribeRows]);
      onRowsSubscribe(subscribeRows);
    }
    if(unsubscribeRows.length > 0)
    {
      onRowsUnsubscribe(unsubscribeRows);
    }
  }, [onRowsSubscribe, onRowsUnsubscribe]);

  const onBodyScrollEnd = useCallback((event: BodyScrollEndEvent<IDataGridCell>) =>
  {
    if(event.direction === "vertical")
    {
      calcSubscription(event.api);
    }
  }, [calcSubscription]);

  const onRowDataUpdated = useCallback((event: RowDataUpdatedEvent<IDataGridCell>) =>
  {
    const displayedRowCount = event.api.getDisplayedRowCount();
    if(previousDisplayRowCount.current !== displayedRowCount)
    {
      calcSubscription(event.api);
      previousDisplayRowCount.current = displayedRowCount;
    }
  }, [calcSubscription]);

  const onPaginationChanged = useCallback((event: PaginationChangedEvent<IDataGridCell>) =>
  {
    if(event.newPage || event.newPageSize)
    {
      calcSubscription(event.api);
    }
  }, [calcSubscription]);

  const onFilterChanged = useCallback((event: FilterModifiedEvent<IDataGridCell>) =>
  {
    calcSubscription(event.api);
  }, [calcSubscription]);

  const onSortChanged = useCallback((event: SortChangedEvent<IDataGridCell>) =>
  {
    calcSubscription(event.api);
  }, [calcSubscription]);

  useEffect(() =>
  {
    return () =>
    {
      if(onRowsUnsubscribe)
      {
        onRowsUnsubscribe([...subscription.current]);
      }
    };
  }, []);

  return {
    onBodyScrollEnd,      // on scroll stop
    onRowDataUpdated,     // on add or remove rows
    onPaginationChanged,  // on page change
    onFilterChanged,      // on filter change
    onSortChanged,        // on sort change
    calcSubscription
  };
}

export function getFieldPermissions(defnForm: DefnForm, roleIdSet: MetaIdRole[])
{
  const fieldPermissionMap = {} as Record<MetaIdField, EnumDefnPermission>;
  const cloneForm = cloneDeep(defnForm);
  loopDefnForm(cloneForm, (parent, comp) =>
  {
    fieldPermissionMap[comp.metaId] = getResolvedPermissionMatrix(cloneForm, parent, comp, roleIdSet);
  });

  return fieldPermissionMap;
}

export function getColIdToStyleIdMap(colDefs: TypeColDef[], styleMap: DefnMapOfTableStyle)
{
  const colIdToStyleIdMap = {} as Record<MetaIdField, MetaIdTableStyle>;
  const fieldIdToStyleIdMap = {} as Record<MetaIdField, MetaIdTableStyle>;
  let defaultStyleId: MetaIdTableStyle;

  styleMap.keys?.forEach(styleId =>
  {
    const style = styleMap.map[styleId];
    if(style.fieldLayoutOn === "column")
    {
      const fieldIdSet = style.fieldIdSet;
      if(fieldIdSet)
      {
        fieldIdSet.forEach(fieldId =>
        {
          fieldIdToStyleIdMap[fieldId] = styleId;
        });
      }
      else
      {
        defaultStyleId = styleId;
      }
    }
  });

  colDefs.forEach((colDef: ColDef) =>
  {
    const colId = colDef.colId;
    if(colId)
    {
      colIdToStyleIdMap[colId] = fieldIdToStyleIdMap[colId] || defaultStyleId;
    }

    // colGroup children case
    if((colDef as ColGroupDef).groupId)
    {
      (colDef as ColGroupDef).children?.forEach((childColDef: ColDef) =>
      {
        if(childColDef.colId)
        {
          const colId = isComboId(childColDef.colId)
            ? childColDef.colId.split("<>").at(0)
            : childColDef.colId;
          if(colId)
          {
            colIdToStyleIdMap[colId] = fieldIdToStyleIdMap[colId] || defaultStyleId;
          }
        }
      });
    }
  });

  return colIdToStyleIdMap;
}

export function getResolvedCellStyle(
  colId?: MetaIdField,
  styleMap?: DefnMapOfTableStyle,
  colIdToStyleIdMap?: Record<MetaIdField, MetaIdTableStyle>,
  defnForm?: DefnForm,
  formValue?: FormValue
)
{
  const _colId = colId
    ? (isComboId(colId) ? colId.split("<>").at(0) : colId)
    : undefined;
  const styleId = _colId ? colIdToStyleIdMap?.[_colId] : undefined;
  const style = styleId ? styleMap?.map[styleId] : undefined;

  if(style?.conditionVar)
  {
    if(defnForm && validateCondition(style.conditionVar, defnForm, formValue))
    {
      return style ? resolveTableStyle(style) : undefined;
    }
    return;
  }

  return style ? resolveTableStyle(style) : undefined;
}

export function resolveTableStyle(style: DefnDtoTableStyle)
{
  const bgColor = style.bgColor?.value
    ? theme.common.colorWithShade(style.bgColor.value, style.bgColor?.shade)
    : undefined;
  const textColor = style.textColor?.value
    ? theme.common.colorWithShade(style.textColor.value, style.textColor?.shade)
    : undefined;
  const fontSize = style.fontSize;

  const textStyle = style.textStyleSet;
  const isBold = textStyle?.includes("bold");
  const isStrikeThrough = textStyle?.includes("strikeout");
  const isItalic = textStyle?.includes("italic");
  const isUnderlined = textStyle?.includes("underlined");
  const textDecoration = `${isStrikeThrough ? "line-through" : ""} ${isUnderlined ? "underline" : ""}`;

  return {
    isBold: isBold,
    isItalic: isItalic,
    textDecoration: textDecoration || undefined,
    color: textColor,
    bgcolor: bgColor,
    variant: fontSize
  } as IDataGridCellStyle;
}

import {Typography} from "@mui/material";
import {cloneDeep} from "lodash";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {useRef} from "react";
import {useCallback} from "react";
import {useFormContext} from "react-hook-form";
import {DefnFieldFormList} from "../../../../api/meta/base/dto/DefnFieldFormList";
import {DefnFieldFormListItem} from "../../../../api/meta/base/dto/DefnFieldFormListItem";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {RowId} from "../../../../api/meta/base/Types";
import {dtoToFieldFormListItemsKey} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {dispatchList} from "../../../../base/plus/ListPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {random} from "../../../../base/plus/StringPlus";
import {isProd} from "../../../../base/plus/SysPlus";
import theme from "../../../../base/plus/ThemePlus";
import {listSetPick} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetLayoutType} from "../../../../base/slices/list/SliceListSharedActions";
import {listSetPickType} from "../../../../base/slices/list/SliceListSharedActions";
import {IListItemForm} from "../../../../base/types/list/TypeListForm";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {IListItemsById} from "../../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../../base/types/list/TypesList";
import {IListData} from "../../../../base/types/list/TypesList";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {useAppSelector} from "../../../app/AppHooks";
import {useFormCtx} from "../base/CtxForm";
import IFormSectionCtx from "../base/CtxFormSection";
import {ctxFormSection} from "../base/CtxFormSection";
import {FieldValueFormListItem} from "../uiOnly/FieldFormList";
import {FieldValueFormList} from "../uiOnly/FieldFormList";
import {fieldListLoaded} from "./FieldRawList";
import {IFieldRawListRef} from "./FieldRawList";
import {fieldListItemVal} from "./FieldRawList";
import FieldRawList from "./FieldRawList";

export default function FieldRawFormList<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defn: DefnFieldFormList
  defnForm: DefnFormUi,
  fieldValue?: FieldValueFormList,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  onChange?: (value?: FieldValueFormList | null) => void
})
{
  const defn = props.defn;
  const fieldId = getFieldKey(defn);
  const formCtx = useFormCtx();
  const selectList = formCtx.getSelectList(fieldId);

  return selectList
    ? <RealFieldRawFormList
      {...props}
      selectList={selectList}
    />
    : !isProd()
      ? <Typography color={"red"}>Field List error: provide a sliceList path in formProps</Typography>
      : null;

}

function RealFieldRawFormList<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defn: DefnFieldFormList
  defnForm: DefnFormUi,
  selectList: SelectList,
  fieldValue?: FieldValueFormList,
  listBinder?: IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>
  onChange?: (value?: FieldValueFormList | null) => void
})
{
  const defn = props.defn;
  const defnForm = props.defnForm;
  const showAsCardLayout = defn.showAsCardLayout;
  const pickMany = defn.isPickMany;

  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();
  const formSectionCtx = useRef({} as IFormSectionCtx);
  const cbRefFieldRaw = useRef({} as IFieldRawListRef<RowId>);
  const onClickForm = formCtx.getOnClick();
  formSectionCtx.current.getParent = () => defn;
  formSectionCtx.current.getDefnForm = () => props.defnForm;
  formSectionCtx.current.getDefn = (key) => defnForm.compMap[key] as DefnFieldUi;
  const fieldId = getFieldKey(defn);
  const selectList = props.selectList;

  const listName = useAppSelector(state => selectList(state).listName);
  const loaded = useAppSelector(state => selectList(state).userField?.[fieldListLoaded]);
  const listLayoutType = useAppSelector(state => selectList(state).listLayoutType);
  const pickItemIds = useAppSelector(state => selectList(state).pickItemIds);
  const fieldValue = props.fieldValue?.keys || [];
  const [valueVersion, setValueVersion] = useState("");

  const onChange = (value: RowId[]) =>
  {
    // props.onChange && props.onChange({
    //   map: {},
    //   ...props.fieldValue,
    //   keys: value
    // });
  };

  const updateListItem = useCallback((itemId: TypeListItemId, value: FieldValueFormListItem) =>
  {
    const newItem = doLoadListItem(itemId, value, defn);
    if(newItem)
    {
      dispatchList(listName, listSetItem({
        itemId: itemId,
        newItem: newItem
      }));
    }
  }, [defn, listName, selectList]);

  const updateFieldItem = useCallback((itemId: string, value: FieldValueFormListItem) =>
  {
    const fieldItemId = `${defn.metaId}[map][${itemId}]`;
    hookFormCtx.setValue(fieldItemId, value, {
      shouldValidate: false,
      shouldDirty: false,
      shouldTouch: false
    });
    Object.entries(value.valueMap).forEach(([key, val]) =>
    {
      const fieldItemId = dtoToFieldFormListItemsKey(defn, value.rowId, key);
      hookFormCtx.setValue(fieldItemId, val, {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false
      });
    });
  }, [defn, hookFormCtx, updateListItem]);

  const updateItem = useCallback((itemId: string, value?: FieldValueFormListItem, replace?: boolean) =>
  {
    if(value && replace)
    {
      updateFieldItem(itemId, value);
      updateListItem(itemId, value);
    }
  }, [updateFieldItem, updateListItem]);

  const updateItems = useCallback((items?: FieldValueFormList | null) =>
  {
    hookFormCtx.setValue(fieldId, items, {
      shouldValidate: false,
      shouldDirty: false,
      shouldTouch: false
    });
    setValueVersion(random(5));
  }, [fieldId, hookFormCtx]);

  const appendItems = useCallback((items?: FieldValueFormList | null) =>
  {
    const values: FieldValueFormList = hookFormCtx.getValues()[fieldId]
      || {
        keys: [],
        map: {}
      };

    values.keys = [...new Set([...values.keys, ...(items?.keys || [])])];
    items?.keys.forEach(key =>
    {
      values.map[key] = items?.map[key];
    });
    hookFormCtx.setValue(fieldId, values);
    setValueVersion(random(5));
  }, [fieldId, hookFormCtx]);

  const doLoad = useCallback((selectList: SelectList, fieldValues: RowId[]): IListData =>
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;
    fieldValues.forEach((rowId, index) =>
    {
      const fieldValue = props.fieldValue?.map[rowId];
      if(!uiItemsById[rowId])
      {
        if(rowId && !uiItemsById[rowId])
        {
          if(fieldValue)
          {
            const value = cloneDeep(fieldValue);
            const newItem = doLoadListItem(rowId, value, defn);

            if(newItem)
            {
              uiItemIds.push(rowId);
              uiItemsById[rowId] = newItem;
            }
          }
        }
      }
    });

    const layoutDefnFieldId = defn.displaySectionId
      ? (defnForm.compMap[defn.displaySectionId] as DefnSection)?.fieldIdSet?.at(0)
      : undefined;
    const layoutItemDefn = layoutDefnFieldId
      ? defnForm.compMap[layoutDefnFieldId] as DefnFieldFormListItem | undefined
      : undefined;

    return {
      itemIds: uiItemIds,
      itemsById: uiItemsById,
      layout: layoutItemDefn?.layout,
      defnForm: {
        ...defnForm,
        _compArray: undefined,
        _defnTheme: undefined,
        _init: undefined,
        _schema: undefined
      } as DefnFormUi
    };
  }, [defnForm, props.fieldValue?.map, defn]);

  useEffect(() =>
  {
    dispatchList(listName, listSetPickType(pickMany ? "pickMany" : undefined));
    pickItemIds && Object.keys(pickItemIds).forEach(itemId =>
    {
      dispatchList(listName, listSetPick({
        itemId: itemId,
        pickValue: true
      }));
    });
  }, [pickMany]);

  useEffect(() =>
  {
    if(listName)
    {
      let listSetLayoutTypeFlag = false;
      if(showAsCardLayout && (!listLayoutType || listLayoutType === "list"))
      {
        dispatchList(listName, listSetLayoutType({
          listLayoutType: "card"
        }));
        listSetLayoutTypeFlag = true;
      }
      else if(!showAsCardLayout && listLayoutType && listLayoutType === "card")
      {
        dispatchList(listName, listSetLayoutType({
          listLayoutType: "list"
        }));
        listSetLayoutTypeFlag = true;
      }

      if(listSetLayoutTypeFlag && loaded)
      {
        cbRefFieldRaw.current.refresh();

      }
    }
  }, [listLayoutType, showAsCardLayout, listName, loaded]);

  useEffect(() =>
  {
    formCtx.setFieldFormListApis(fieldId, {
      updateItem: updateItem,
      updateItems: updateItems,
      appendItems: appendItems,
      refresh: () => cbRefFieldRaw.current.refresh()
    });
  }, [fieldId, formCtx, updateItem, updateItems]);

  useEffect(() =>
  {
    if(loaded)
    {
      cbRefFieldRaw.current.refresh();
    }
  }, [valueVersion]);

  return (
    <ctxFormSection.Provider value={formSectionCtx.current}>
      <FieldRawList
        fieldId={fieldId}
        fieldValue={fieldValue}
        onChange={onChange}
        doLoad={doLoad}
        disableSpotMenu={true}
        onClickList={onClickForm}
        listBinder={props.listBinder}
        cbRef={cbRefFieldRaw.current}
        disableAutoReload={true}
      />
    </ctxFormSection.Provider>);

}

function doLoadListItem(
  id: string,
  value: FieldValueFormListItem,
  defn: DefnFieldFormList,
  resetVersion?: boolean)
{
  const fieldId = getFieldKey(defn);
  return {
    type: "form",
    id: id,
    rowId: value.rowId,
    valueMap: value.valueMap,
    defnFieldFormListId: fieldId,
    pickedBgColor: theme.common.bgcolorGreenLight,
    ignoreSelection: defn.ignoreSelection,
    hideMenu: defn.hideMenu,
    userField: !resetVersion
      ? {
        [fieldListItemVal]: value
      }
      : undefined
  } as IListItemForm;
}

